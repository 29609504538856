import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FiChevronLeft, FiImage, FiSave } from 'react-icons/fi';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  useFileCreateFromTemplateMutation,
  useFilesInvalidateQuery,
} from 'hooks/api/files';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Spinner from '@scalero-inc/forno-spinner';

import FormikEffect from 'components/formik-effect';
import TemplatesPreviewIframe from 'components/templates-preview-iframe';
import HtmlToReact from 'components/html-to-react';

import { useTemplatesQuery } from 'hooks/api/templates';
import { generateHtml } from 'utils';
import schema from './schema';
import TemplatesGrid from './templates-grid';

import styles from './style.module.css';

const defaultInitialValues = {
  name: '',
};

function TemplatesFileCreateForm({
  onSuccess,
  onSubmit,
  onError,
  onChange,
  className,
  initialValues,
  team,
  project,
}) {
  const customClassName = classnames(
    'template-form',
    className,
    styles['template-form']
  );

  const { data: templatesData, isLoading } = useTemplatesQuery({
    teamId: team,
    projectId: project,
  });

  const templatesList = React.useMemo(() => {
    return Array.isArray(templatesData) ? templatesData : [];
  }, [templatesData]);

  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const templateComponentsData = React.useMemo(() => {
    return selectedTemplate?.template_components;
  }, [selectedTemplate]);

  const [flattenedContent, setFlattenedContent] = React.useState([]);

  React.useEffect(() => {
    if (templateComponentsData) {
      const contentArray = templateComponentsData.reduce(
        (acc, templateComponent) => {
          return [...acc, ...templateComponent.component.content.blockArray];
        },
        []
      );
      setFlattenedContent(contentArray);
    }
  }, [templateComponentsData]);

  const navigate = useNavigate();

  const invalidate = useFilesInvalidateQuery();

  const mutation = useFileCreateFromTemplateMutation({
    teamId: team,
    projectId: project,
    templateId: selectedTemplate?.id,
    config: {
      onSuccess: (data) => {
        invalidate();
        onSuccess(data);
      },
      onError: (error) => {
        onError(error);
      },
    },
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSubmit(values);
    mutation.mutate(values);
    setSubmitting(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {({ isSubmitting, values }) => {
        const canSubmit = !!values.name && selectedTemplate;
        return (
          <Form className={customClassName}>
            <FormikEffect onChange={onChange} />
            <div className={styles['form-container']}>
              <div className={styles['field-wrapper']}>
                <label htmlFor="name">File Name</label>
                <Field id="name" name="name">
                  {({ field, meta }) => (
                    <Input
                      {...field}
                      theme="light"
                      className={styles.field}
                      placeholder="Name of your File"
                      warningText={
                        meta.touched && meta.error ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </div>

              {isLoading ? (
                <Spinner />
              ) : (
                <TemplatesGrid
                  templatesList={templatesList}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                  team={team}
                  project={project}
                />
              )}

              <div className={styles['template-form-footer']}>
                <Button
                  onClick={goBack}
                  icon={FiChevronLeft}
                  hierarchy="tertiary"
                  theme="light"
                  size="m"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || !canSubmit}
                  loading={isSubmitting}
                  icon={FiSave}
                  size="m"
                >
                  Save File
                </Button>
              </div>
            </div>

            <div className={styles['preview-container']}>
              <p>Template Preview</p>
              <div className={styles['template-node-preview-container']}>
                {selectedTemplate && templateComponentsData ? (
                  <TemplatesPreviewIframe disableLinks isStatic>
                    <HtmlToReact html={generateHtml(flattenedContent)} />
                  </TemplatesPreviewIframe>
                ) : (
                  <div className={styles['preview-placeholder-icon']}>
                    <FiImage size={50} />
                    <p className="font-paragraph-l-sb">Template Preview</p>
                  </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

TemplatesFileCreateForm.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.shape({
    url: PropTypes.string,
  }),
  team: PropTypes.string,
  project: PropTypes.string,
};

TemplatesFileCreateForm.defaultProps = {
  initialValues: defaultInitialValues,
  onError: () => {},
  onSubmit: () => {},
  onSuccess: () => {},
  onChange: () => {},
  className: null,
  team: null,
  project: null,
};

export default TemplatesFileCreateForm;
