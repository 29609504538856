/**
 * Returns the children nodes of a page
 * @param {Object} data Response from the figma node query of a figma page
 * @param {string} pageId Id of the page containing the frames
 */
export function getNodesFromPage(data, pageId) {
  const { nodes = {} } = data || {};
  const page = nodes[pageId] || {};
  const { document: doc = {} } = page;
  const { children = [] } = doc;
  return children;
}

/**
 * Returns all frames inside a Figma page
 * @param {Object} data Response from the figma node query of a figma page
 * @param {string} pageId Id of the page containing the frames
 */
export function getFramesFromFile(data, pageId) {
  const children = getNodesFromPage(data, pageId);
  return children.filter((node) => node.type === 'FRAME' || 'COMPONENT');
}
