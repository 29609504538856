export const CURRENT_PROFILE_STORAGE_KEY = 'currentProfile';
export const GENERIC_JSON = {
  tagName: 'mjml',
  attributes: {},
  children: [
    {
      tagName: 'mj-head',
      children: [
        {
          tagName: 'mj-breakpoint',
          attributes: { width: '480px' },
        },
        {
          tagName: 'mj-attributes',
          children: [
            {
              tagName: 'mj-all',
              attributes: { padding: 0 },
            },
          ],
        },
        {
          tagName: 'mj-style',
          content:
            '@media only screen and (max-width: 720px) {\n    .wrapper > table > tbody > tr > td {\n        padding-left: 0px !important;\n        padding-right: 0px !important;\n    }\n\n    .section > table > tbody > tr > td {\n        padding-left: 20px !important;\n        padding-right: 20px !important;\n    }\n\n    .column > table > tbody > tr > td {\n        padding-left: 0px !important;\n        padding-right: 0px !important;\n    }\n\n    .padB40 {\n        padding-bottom: 40px !important;\n    }\n\n    .image {\n        padding-left: 0px !important;\n        padding-right: 0px !important;\n    }\n\n    .image > table {\n        text-align: center !important;\n        margin: 0 auto !important;\n    }\n\n    .image > table > tbody > tr > td > a > img {\n        height: auto !important;\n    }\n\n    .divider {\n        padding-left: 0px !important;\n        padding-right: 0px !important;\n    }\n\n    .button > table {\n        width: 100% !important;\n    }\n\n    .button > table > tbody > tr > td > a {\n        width: 80% !important;\n    }\n}',
          attributes: {},
        },
      ],
    },
    {
      tagName: 'mj-body',
      attributes: {
        width: '720px',
      },
      children: [],
    },
  ],
};

export const REBASE_COLUMN_ORIGIN_CANNOLI = 'cannoli';
export const REBASE_COLUMN_ORIGIN_FIGMA = 'figma';
export const REBASE_COLUMN_ORIGIN_NEW_VERSION = 'newVersion';
