import { useState, useCallback } from 'react';
import Button from '@scalero-inc/forno-button';
import { useEditor } from 'pages/editor/editor-context';
import FallbackStacks from 'pages/dashboard/dashboard-resources/fallback-stacks';
import MissingFontMessage from 'components/missing-font-message';
import FontsDataProvider from 'containers/fonts-data-provider';
import InputFont from 'components/dynamic-input/input-font';
import {
  getFontsFromComponents,
  getMjmlComponentsByAttribute,
} from 'utils/mjml/util';

import styles from './styles.module.css';

function FontEditorPanel() {
  const { nodesWithIds = [], missingFonts, teamId } = useEditor();
  const mjmlComponents = getMjmlComponentsByAttribute(
    nodesWithIds,
    'font-family'
  );
  const fontsInFile = getFontsFromComponents(mjmlComponents);
  const fonts = fontsInFile.reduce(
    (accumulator, font) => {
      const missingFont = missingFonts.find(
        (fontVariant) =>
          !fontVariant.uploaded_url &&
          !fontVariant.url &&
          fontVariant.family === font.family &&
          fontVariant.variant === font.weight
      );

      if (missingFont) {
        accumulator.missing.push({
          id: missingFont.id,
          ...font,
        });
      } else {
        accumulator.valid.push(font);
      }

      return accumulator;
    },
    {
      valid: [],
      missing: [],
    }
  );

  const [isFallbacksOpen, setIsFallbackOpen] = useState(false);
  const openFallbacksWindow = () => {
    setIsFallbackOpen(true);
  };
  const closeFallbacksWindow = useCallback(() => {
    setIsFallbackOpen(false);
  }, []);

  return (
    <div data-theme="dark" className={styles['editor-panel-container']}>
      <section>
        <FontsDataProvider>
          {(
            error,
            isLoading,
            googleFontOptions,
            customFontOptions,
            webSafeFontOptions,
            fontStackOptions
          ) => {
            if (error) {
              return (
                <div className={styles['error-message']}>
                  Can not load your fonts, try again later.
                </div>
              );
            }

            return (
              <>
                <div className={styles['fonts-message']}>
                  Fonts found in your file, you can replace them with the
                  dropdown menu.
                </div>

                {fonts.valid.map((font, fontIndex) => {
                  return (
                    <InputFont
                      key={`${font.family}_${fontIndex}`}
                      isError={!!error}
                      isLoading={isLoading}
                      googleFonts={googleFontOptions}
                      customFonts={customFontOptions}
                      webSafeFonts={webSafeFontOptions}
                      fontStacks={fontStackOptions}
                      defaultFontFamily={font}
                    />
                  );
                })}

                {fonts.missing.length !== 0 && (
                  <div className={styles['missing-fonts']}>
                    <MissingFontMessage multipleFonts />

                    {fonts.missing.map((missingFont, missingFontIndex) => {
                      return (
                        <div key={`missingFont_${missingFontIndex}`}>
                          <InputFont
                            isError={!!error}
                            isLoading={isLoading}
                            googleFonts={googleFontOptions}
                            customFonts={customFontOptions}
                            webSafeFonts={webSafeFontOptions}
                            fontStacks={fontStackOptions}
                            isMissingFont
                            defaultFontFamily={missingFont}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            );
          }}
        </FontsDataProvider>
      </section>

      <Button
        size="s"
        theme="dark"
        hierarchy="secondary"
        onClick={() => openFallbacksWindow()}
      >
        Manage your fallback stacks
      </Button>

      {isFallbacksOpen && (
        <FallbackStacks
          type="modal"
          theme="dark"
          close={closeFallbacksWindow}
          teamId={teamId}
        />
      )}
    </div>
  );
}

FontEditorPanel.propTypes = {};

FontEditorPanel.defaultProps = {};

export default FontEditorPanel;
