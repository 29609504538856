import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFolder } from 'react-icons/fi';

import Card from '@scalero-inc/forno-card';

import Time from 'components/time';

import ProjectActionsMenu from '../project-actions-menu';

function ProjectCard({
  name,
  id,
  preview,
  last_updated_at: updatedAt,
  blocked,
}) {
  const customClassName = classnames('forno-card-wrapper');
  const { teamId } = useParams();
  const url = `projects/${id}/files`;

  return (
    <Card
      className={customClassName}
      name={name}
      url={url}
      preview={preview}
      actions={
        <ProjectActionsMenu
          name={name}
          id={id}
          team={+teamId}
          size="l"
          disabled={blocked}
        />
      }
      placeholderIcon={<FiFolder />}
      data-disabled={blocked}
    >
      <p>
        Last updated: <Time>{updatedAt}</Time>
      </p>
    </Card>
  );
}

ProjectCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  preview: PropTypes.string,
  last_updated_at: PropTypes.string,
  team: PropTypes.shape({
    id: PropTypes.number,
  }),
  blocked: PropTypes.bool,
};

ProjectCard.defaultProps = {
  last_updated_at: new Date().toISOString(),
  preview: null,
  team: null,
  blocked: null,
};

export default ProjectCard;
