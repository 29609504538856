import Spinner from '@scalero-inc/forno-spinner';

import { useImagesQuery } from 'hooks/api/figma/image';

import { useEditor } from '../../editor-context';

import styles from './style.module.css';

function EditorPreviewImage() {
  const { figmaId, frame, doc } = useEditor();
  const { data, isLoading, error } = useImagesQuery({
    file: figmaId,
    nodes: [frame],
  });
  const {
    absoluteBoundingBox: { width },
  } = doc;

  if (isLoading) return <Spinner />;
  if (error) return <pre>{error}</pre>;

  const { images } = data;
  const preview = images[frame];

  return (
    <div className={styles['image-wrapper']}>
      <img
        src={preview}
        alt={styles.preview}
        style={{
          maxWidth: `${width}px`,
        }}
      />
    </div>
  );
}

export default EditorPreviewImage;
