import CompareSlider from 'components/compare-slider';

import EditorPreviewIframe from '../editor-preview-iframe';
import EditorPreviewImage from '../editor-preview-image';

import { useEditor } from '../../editor-context';

function EditorPreviewCompare() {
  const { doc } = useEditor();
  const {
    absoluteBoundingBox: { height, width },
  } = doc;
  return (
    <CompareSlider
      aspectRatio={width / height}
      itemOne={<EditorPreviewIframe />}
      itemTwo={<EditorPreviewImage />}
    />
  );
}

export default EditorPreviewCompare;
