import PropTypes from 'prop-types';
import Input from '@scalero-inc/forno-input';
import ButtonGroup from '@scalero-inc/forno-button-group';
import { useState } from 'react';
import { parseAttributeValue } from 'utils/mjml/util';
import { MJML_CONTENT_TAGS } from 'utils/mjml';

import styles from './styles.module.css';

function InputSize({
  label,
  field,
  placeholder,
  tagName,
  attribName,
  handleChange,
}) {
  const attribValue = parseAttributeValue(field.value);
  const tag = MJML_CONTENT_TAGS[tagName];
  const units =
    tag && tag?.units && tag.units[attribName] ? tag.units[attribName] : [];

  const defaultUnit = units?.length === 1 ? units[0] : null;
  const [unit, setunit] = useState(attribValue.unit || defaultUnit);

  const onValueChange = (value, unitSelected) => {
    setunit(unitSelected);

    const attribUnit = unitSelected || '';
    const newValue = value ? value + attribUnit : '';
    handleChange(newValue);
  };

  const onUnitChange = (event) => {
    const newUnit = event[0];
    const fieldValue = parseAttributeValue(field.value);
    onValueChange(fieldValue.value, newUnit);
  };

  return (
    <div className={styles['input-size-container']}>
      <div>
        <label htmlFor={label}>{label}</label>
      </div>

      <Input
        value={attribValue.value}
        placeholder={placeholder}
        size="s"
        theme="dark"
        variant="subtle"
        disabled={unit === 'auto'}
        onChange={(event) => {
          const { value } = event.target;
          const newValue = value.replace(/[^0-9]/, '');
          onValueChange(newValue, unit);
        }}
        className={styles['input-size']}
      />

      <ButtonGroup
        size="s"
        type="radio"
        theme="dark"
        variant="subtle"
        initialValue={[unit]}
        onChange={onUnitChange}
        className="cannoli-button-group"
      >
        {units?.map((unitValue) => {
          return (
            <ButtonGroup.Button value={unitValue} key={unitValue}>
              {unitValue}
            </ButtonGroup.Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
}

InputSize.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tagName: PropTypes.string.isRequired,
  attribName: PropTypes.string.isRequired,
  field: PropTypes.shape({ value: PropTypes.string }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

InputSize.defaultProps = {
  label: '',
  placeholder: '',
};

export default InputSize;
