import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';

import {
  useFilesPaginatedInvalidateQuery,
  useFileUpdateMutation,
} from 'hooks/api/files';
import FileProjectDropdown from 'containers/file-project-dropdown';

import schema from './schema';
import styles from './style.module.css';

function FileEditModal({ id, name, isOpen, close }) {
  const { teamId, projectId } = useParams();
  const invalidateFiles = useFilesPaginatedInvalidateQuery();
  const { mutate, isLoading } = useFileUpdateMutation({
    fileId: id,
    config: {
      onSuccess: () => {
        close();
        invalidateFiles();
        toast.success('File updated successfully');
      },
      onError: () => {
        toast.error('Error updating file');
      },
    },
  });

  const handleNameChange = useCallback(
    (values) => {
      mutate({ name: values.name });
    },
    [mutate]
  );

  const handleProjectChange = useCallback(
    (e) => {
      if (e.value) {
        mutate({ project: e.value });
      } else {
        mutate({ project: null, team: null });
      }
    },
    [mutate]
  );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      theme="light"
      aria-label="edit file modal"
    >
      <Modal.Header title={`Update ${name}`} onDismiss={close} icon={FiEdit} />
      <div className={styles['file-update-modal-form']}>
        <Formik
          initialValues={{ name }}
          validationSchema={schema}
          onSubmit={handleNameChange}
        >
          {({ isValid }) => (
            <Form className={styles['form-wrapper']}>
              <Field id="name" name="name">
                {({ field }) => (
                  <Input
                    id="name"
                    size="s"
                    label="File Name"
                    placeholder="File Name"
                    theme="light"
                    {...field}
                  />
                )}
              </Field>
              <Button
                theme="light"
                type="submit"
                loading={isLoading}
                disabled={!isValid || isLoading}
              >
                Update Name
              </Button>
            </Form>
          )}
        </Formik>
        <div className={styles['field-wrapper']}>
          <label htmlFor="team">File Project</label>
          <FileProjectDropdown
            teamId={teamId}
            projectId={projectId}
            onProjectChange={handleProjectChange}
          />
        </div>
      </div>
    </Modal>
  );
}

FileEditModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

FileEditModal.defaultProps = {
  isOpen: false,
};

export default FileEditModal;
