import React from 'react';
import { Link } from 'react-router-dom';
import { BsCreditCard } from 'react-icons/bs';

import Button from '@scalero-inc/forno-button';

import PublicPage from 'containers/public-page';

import styles from './styles.module.css';

function CheckoutCancel() {
  return (
    <PublicPage>
      <section className={styles.section}>
        <BsCreditCard size={100} />
        <h2>Payment Cancelled</h2>

        <div className={styles.description}>
          <p className="paragraph-r">
            Need help? If you need to clarify something, please{' '}
            <a href="mailto:hello@cannoli.app" target="_blank" rel="noreferrer">
              contact us
            </a>
            .
          </p>
          <div className={styles.styledAnchor}>
            <Link to="/policies/services">
              <Button hierarchy="tertiary" size="s">
                Services Policies
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </PublicPage>
  );
}

export default CheckoutCancel;
