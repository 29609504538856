import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'context/fetch';

import { subscriptionQuery } from './queries';
import {
  teamAndSubscriptionCreateMutation,
  teamSubscriptionCreateMutation,
  proSubscriptionCreateMutation,
  starterSubscriptionCreateMutation,
  subscriptionUpdatePaymentMethod,
  updatePersonalSubscription,
  cancelSubscription,
  completeSubscription,
  updateTeamSubscriptionMutation,
  collectInvoiceMutation,
  freeProTrialSubscriptionCreateMutation,
} from './mutations';

import { SUBSCRIPTION_QUERY_KEY } from '../constants';

export function useSubscriptionQuery(props = {}) {
  const { subscriptionId, key = SUBSCRIPTION_QUERY_KEY, config = {} } = props;
  const { client } = useFetch();
  return useQuery(
    [key, subscriptionId],
    () => subscriptionQuery({ client, subscriptionId }),
    config
  );
}

export function useTeamAndSubscriptionCreateMutation(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => teamAndSubscriptionCreateMutation({ client, values }),
    config
  );
}

export function useTeamSubscriptionCreateMutation(props = {}) {
  const { config = {}, team, seats } = props;
  const { client } = useFetch();
  return useMutation(
    () => teamSubscriptionCreateMutation({ client, team, seats }),
    config
  );
}

export function useProSubscriptionCreateMutation(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useMutation(() => proSubscriptionCreateMutation({ client }), config);
}

export function useStarterSubscriptionCreateMutation(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useMutation(
    () => starterSubscriptionCreateMutation({ client }),
    config
  );
}

export function useFreeProTrialSubscriptionCreateMutation(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useMutation(
    () => freeProTrialSubscriptionCreateMutation({ client }),
    config
  );
}

export function useSubsciptionUpdatePaymentMethod(props = {}) {
  const { config = {}, subscriptionId } = props;
  const { client } = useFetch();
  return useMutation(
    () => subscriptionUpdatePaymentMethod({ client, subscriptionId }),
    config
  );
}

export function useUpdatePersonalSubscription(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useMutation(() => updatePersonalSubscription({ client }), config);
}

export function useCancelSubscription(props = {}) {
  const { config = {}, subscriptionId } = props;
  const { client } = useFetch();
  return useMutation(
    () => cancelSubscription({ client, subscriptionId }),
    config
  );
}

export function useCompleteSubscription(props = {}) {
  const { config = {}, subscriptionId } = props;
  const { client } = useFetch();
  return useMutation(
    () => completeSubscription({ client, subscriptionId }),
    config
  );
}

export function useUpdateTeamSubscriptionMutation(props = {}) {
  const { config = {}, subscriptionId } = props;
  const { client } = useFetch();
  return useMutation(
    (seats) =>
      updateTeamSubscriptionMutation({ client, subscriptionId, seats }),
    config
  );
}

export function useSubscriptionInvalidateQuery(props = {}) {
  const { key = SUBSCRIPTION_QUERY_KEY, subscriptionId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key, subscriptionId]);
  };
}

export function useCollectInvoiceMutation(props = {}) {
  const { config = {}, subscriptionId } = props;
  const { client } = useFetch();
  return useMutation(
    (invoiceId) =>
      collectInvoiceMutation({ client, subscriptionId, invoiceId }),
    config
  );
}
