import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import DeleteStackModal from 'containers/delete-stack-modal';
import FontStackModal from 'containers/font-stack-modal';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import FontsTable from 'containers/fonts-table';

import styles from './styles.module.css';

function FallbackStacks({ type, close, theme, teamId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [fontSelected, setFontSelected] = useState({
    family: '',
    variants: [],
  });

  const [isStacksTableOpen, setStacksTableOpen] = useState(true);
  const [isStackDeleteOpen, setStackDeleteOpen] = useState(false);
  const openStackDeleteWindow = useCallback((font) => {
    setFontSelected(font);
    setStacksTableOpen(false);
    setStackDeleteOpen(true);
  }, []);
  const closeStackDeleteWindow = useCallback(() => {
    setStacksTableOpen(true);
    setStackDeleteOpen(false);
  }, []);

  const [isFontStackOpen, setFontStackOpen] = useState(false);
  const closeFontStackWindow = useCallback(() => {
    setStacksTableOpen(true);
    setFontStackOpen(false);
  }, []);

  const addGoogleFontStack = useCallback((font) => {
    setFontSelected(font);
    setIsEditing(false);
    setStacksTableOpen(false);
    setFontStackOpen(true);
  }, []);

  const editFontStack = useCallback((fontToEdit) => {
    setIsEditing(true);
    setFontSelected(fontToEdit);
    setStacksTableOpen(false);
    setFontStackOpen(true);
  }, []);

  return (
    <>
      {type === 'table' ? (
        <FontsTable
          teamId={teamId}
          theme={theme}
          onDeleteFont={openStackDeleteWindow}
          onAddGoogleFont={addGoogleFontStack}
          onEditStack={editFontStack}
        />
      ) : (
        <Modal
          theme={theme}
          isOpen={isStacksTableOpen}
          onDismiss={close}
          aria-label="regular modal"
          className={styles['fallback-stacks-modal']}
          data-modal-type="fallback-stacks-admin"
        >
          <Modal.Header onDismiss={close} title="Fallback stacks" />
          <Modal.Content>
            <FontsTable
              teamId={teamId}
              theme={theme}
              onDeleteFont={openStackDeleteWindow}
              onAddGoogleFont={addGoogleFontStack}
              onEditStack={editFontStack}
            />
          </Modal.Content>
          <Modal.Footer>
            <Button onClick={close} theme={theme} hierarchy="tertiary">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <DeleteStackModal
        theme={theme}
        isOpen={isStackDeleteOpen}
        close={closeStackDeleteWindow}
        stack={fontSelected}
        teamId={teamId}
      />

      <FontStackModal
        theme={theme}
        isOpen={isFontStackOpen}
        close={closeFontStackWindow}
        isEditing={isEditing}
        font={fontSelected}
        teamId={teamId}
      />
    </>
  );
}

FallbackStacks.propTypes = {
  type: PropTypes.oneOf(['modal', 'table']),
  close: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark']),
  teamId: PropTypes.number,
};

FallbackStacks.defaultProps = {
  type: 'table',
  close: () => {},
  theme: 'light',
  teamId: null,
};

export default FallbackStacks;
