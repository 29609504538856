export async function userFontStackCreateMutation({ client, values }) {
  const { data } = await client.post(`user/font_stack/`, {
    ...values,
  });

  return data;
}

export async function teamFontStackCreateMutation({ client, teamId, values }) {
  const { data } = await client.post(`teams/${teamId}/font_stack/`, {
    ...values,
  });

  return data;
}

export async function userFontStackUpdateMutation({
  client,
  fontStackId,
  values,
}) {
  const { data } = await client.patch(`user/font_stack/${fontStackId}/`, {
    ...values,
  });

  return data;
}

export async function teamFontStackUpdateMutation({
  client,
  teamId,
  fontStackId,
  values,
}) {
  const { data } = await client.patch(
    `teams/${teamId}/font_stack/${fontStackId}/`,
    {
      ...values,
    }
  );

  return data;
}

export async function userFontStackDeleteMutation({ client, fontStackId }) {
  const { data } = await client.delete(`user/font_stack/${fontStackId}/`);

  return data;
}

export async function teamFontStackDeleteMutation({
  client,
  teamId,
  fontStackId,
}) {
  const { data } = await client.delete(
    `teams/${teamId}/font_stack/${fontStackId}/`
  );

  return data;
}

export async function fontVariantUodateMutation({
  client,
  fontVariantId,
  values,
}) {
  const { data } = await client.patch(`fonts/variants/${fontVariantId}/`, {
    ...values,
  });

  return data;
}
