import PropTypes from 'prop-types';
import classnames from 'classnames';
import Split from 'react-split-grid';

import ResizeGutter from 'components/resize-gutter';

import { EditorPanelLeft } from 'containers/editor-panel-left';
import EditorPanelRight from 'containers/editor-panel-right';
import EditorPreview from '../editor-preview';

import { useEditorView, useEditorViewDispatch } from '../editor-view-context';

import styles from './style.module.css';

function VisualEditorContent({ className, editorView, feature, setFeature }) {
  const { viewColumns } = useEditorView();
  const { setViewColumns } = useEditorViewDispatch();

  const customClassName = classnames(
    styles['editor-content-container'],
    className
  );

  const handleDrag = (direction, track, gridTemplateStyle) => {
    setViewColumns(gridTemplateStyle);
  };

  const handleDragEnd = () => {
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <div className={styles['editor-content']}>
      <Split
        minSize={300}
        dragInterval={10}
        snapOffset={30}
        cursor="col-resize"
        gridTemplateRows="1fr"
        gridTemplateColumns={viewColumns}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        render={({ getGridProps, getGutterProps }) => {
          return (
            <div className={customClassName} {...getGridProps()}>
              <EditorPanelLeft
                editorView={editorView}
                feature={feature}
                setFeature={setFeature}
              />
              <ResizeGutter {...getGutterProps('column', 1)} />
              <EditorPreview />
              <ResizeGutter {...getGutterProps('column', 3)} />
              <EditorPanelRight feature={feature} />
            </div>
          );
        }}
      />
    </div>
  );
}

VisualEditorContent.propTypes = {
  className: PropTypes.string,
  editorView: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
  setFeature: PropTypes.func.isRequired,
};

VisualEditorContent.defaultProps = {
  className: null,
};

export default VisualEditorContent;
