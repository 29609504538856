import React from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

import LoadingFullPage from 'components/loading-full-page';
import { useFileBackendProcessMutation } from 'hooks/api/files';

function FileProcess() {
  const navigate = useNavigate();
  const { fileId } = useParams();
  const { mutate } = useFileBackendProcessMutation({
    fileId,
    config: {
      onSuccess: ({ successfullyProcessed }) => {
        navigate(`/editor/${fileId}`);
        if (successfullyProcessed) {
          toast.success('File processed succesfully');
        } else {
          toast.error('File could not be processed');
        }
      },
      onError: () => {
        toast.error('An unexpected error occurred, try again later');
      },
    },
  });

  React.useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <LoadingFullPage>
      <h1>Processing your file</h1>
    </LoadingFullPage>
  );
}
export default FileProcess;
