export async function paginatedComponentsQuery({ client, queryOrder }) {
  const { data } = await client.get(`components/?ordering=${queryOrder}`);
  return data;
}

export async function componentsQuery({ client, teamId }) {
  const { data } = await client.get(
    teamId ? `teams/${teamId}/components/` : `user/components/`
  );
  return data;
}

export async function componentQuery({ client, componentId }) {
  const { data } = await client.get(`user/components/${componentId}/`);
  return data;
}

export async function userComponentsQuery({ client }) {
  const { data } = await client.get(`user/components/`);
  return data;
}

export async function teamComponentsQuery({ client, teamId }) {
  const { data } = await client.get(`teams/${teamId}/components/`);
  return data;
}

export async function teamComponentQuery({ client, teamId, componentId }) {
  const { data } = await client.get(
    `teams/${teamId}/components/${componentId}`
  );
  return data;
}

export async function userComponentsPaginatedQuery({
  client,
  queryOrder,
  queryPage,
  pageSize,
}) {
  const queryPageSize = pageSize ? `&page_size=${pageSize}` : '';
  const { data } = await client.get(
    `user/components_paginated/?ordering=${queryOrder}${queryPageSize}&page=${queryPage}`
  );
  return data;
}

export async function teamComponentsPaginatedQuery({
  client,
  queryOrder,
  queryPage,
  pageSize,
  teamId,
}) {
  const queryPageSize = pageSize ? `&page_size=${pageSize}` : '';
  const { data } = await client.get(
    `teams/${teamId}/components_paginated/?ordering=${queryOrder}${queryPageSize}&page=${queryPage}`
  );
  return data;
}
