import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as BaseLogo } from 'svg/cannoli-white-logo.svg';
import { ReactComponent as BaseLogoCollapsed } from 'svg/cannoli-white-logo-short.svg';

import styles from './style.module.css';

function Logo({ collapsed }) {
  return (
    <NavLink to="/" className={styles['header-logo-container']}>
      {collapsed ? (
        <BaseLogoCollapsed className={styles.logo} />
      ) : (
        <BaseLogo className={styles.logo} />
      )}
    </NavLink>
  );
}

Logo.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default Logo;
