import {
  getPaddingByClosestChild,
  getPaddingBySiblings,
  getWidth,
} from '../../bounding-box';
import { tagsDicts } from '../dictionaries';

/**
 *
 * @param {*} childrenColumns - Children components in a section
 *
 * Helps to adjust the width on the last column, comparing tag Name with
 * 'mj-column' to ensure we are just doing adjustments based on mj-column
 * components, a different tag Name can be an error message (it is still
 * a children)
 *
 * @returns - Children components
 */
export function getColumnsAdjusted(childrenColumns) {
  const columnsAdjusted = childrenColumns;
  const lastColumnIndex = columnsAdjusted.length - 1;
  let lastColumnWidth = 100;

  for (let colIndex = 0; colIndex < lastColumnIndex; colIndex += 1) {
    lastColumnWidth -=
      columnsAdjusted[colIndex].tagName === 'mj-column'
        ? columnsAdjusted[colIndex].attributes.width.replace('%', '')
        : 0;
  }

  if (columnsAdjusted[lastColumnIndex].tagName === 'mj-column') {
    columnsAdjusted[lastColumnIndex].attributes.width = `${lastColumnWidth}%`;
  }

  return columnsAdjusted;
}

export function prepareChildren(children, node) {
  const isGroup = tagsDicts
    .find((element) => element.tag === 'mj-group')
    .dict.some((element) => node.name.includes(element));
  const childrenColumns =
    children && children.length >= 2 ? getColumnsAdjusted(children) : children;
  const childrenComponents = isGroup
    ? [{ tagName: 'mj-group', children: childrenColumns }]
    : childrenColumns;

  return childrenComponents;
}

export function getVerticalSpacing(parent, node) {
  const padding = getPaddingBySiblings(parent, node.id);

  if (
    !parent.children ||
    // The current section is the last
    parent.children[parent.children.length - 1].id === node.id ||
    // There is no spacing
    padding.paddingBottom === 0
  ) {
    return [];
  }

  // Creates a new section for spacing
  const defaultSpacer = {
    tagName: 'mj-spacer',
    attributes: {
      height: `${padding.paddingBottom}px`,
    },
  };
  const column = {
    tagName: 'mj-column',
    children: [defaultSpacer],
  };

  return [
    {
      tagName: 'mj-section',
      children: [column],
    },
  ];
}

export function getCssClass(children, sectionNode) {
  if (
    children &&
    // There is only one column
    children.length === 1 &&
    children[0].tagName !== 'mj-raw' &&
    // Full column width
    children[0].attributes.width === '100%' &&
    // There is only one image (hero image)
    children[0].children &&
    children[0].children.length === 1 &&
    children[0].children[0].tagName === 'mj-image' &&
    children[0].children[0].attributes.width === `${getWidth(sectionNode)}px`
  ) {
    return null;
  }

  return { cssClass: 'section' };
}

export function getSectionWidthPx(parent) {
  const section = { name: 'mj-section', children: [parent] };
  const sectionPadding = getPaddingByClosestChild(section, parent.id);
  const sectionWidthPx =
    getWidth(parent) - sectionPadding.paddingRight - sectionPadding.paddingLeft;

  return sectionWidthPx;
}
