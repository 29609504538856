import React from 'react';
import useAnimationFrame from 'hooks/utils/use-animation-frame';

import { getElementCoords } from './utils';

// TODO: Probably using the animation frame for this could bring up performance issues
// ideally we would use the intersect observer but that brought a bunch of bugs because
// it seems there are discrepancies between browsers and iframes.
// https://github.com/w3c/IntersectionObserver/issues/372
function useDANGEROUSDimensions(props = {}) {
  const { element, document } = props;
  const [value, set] = React.useState({});

  useAnimationFrame(() => {
    set(getElementCoords(element, document));
  });

  return value;
}

export default useDANGEROUSDimensions;
