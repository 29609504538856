import { useQuery } from 'react-query';
import { useFetch } from 'context/fetch';

import {
  IMAGE_QUERY_KEY,
  FILE_IMAGES_QUERY_KEY,
} from 'hooks/api/figma/constants';
import { imagesQuery, fileImagesQuery } from './queries';

export function useImagesQuery(props) {
  const { file, nodes, config = {}, key = IMAGE_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery(
    [key, file, nodes],
    () => imagesQuery({ client, file, nodes }),
    config
  );
}

export function useFileImagesQuery(props) {
  const { file, config = {}, key = FILE_IMAGES_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery([key, file], () => fileImagesQuery({ client, file }), config);
}
