import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import { useUpdatePersonalSubscription } from 'hooks/api/subscriptions';
import { useUserInvalidateQuery } from 'hooks/api/users';

import { dollarUSFormat } from 'utils/currency';

function SubscriptionStarterToProModal({ isOpen, close }) {
  const navigate = useNavigate();
  const invalidateUser = useUserInvalidateQuery();
  const { mutate: changeStarterToPro, isLoading } =
    useUpdatePersonalSubscription({
      config: {
        onSuccess: (data) => {
          if (data.checkout) {
            window.location.replace(data.checkout);
          } else {
            toast.success('Updated to Pro plan successfully');
            invalidateUser();
            navigate('/dashboard/settings/billing');
          }
        },
        onError: () => {
          toast.error(
            'There was an error updating your subscription. Please try again later.'
          );
        },
      },
    });

  const handleClick = () => {
    changeStarterToPro();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="upgrade subscription from starter to pro modal"
    >
      <Modal.Header title="Upgrade from Starter to Pro" onDismiss={close} />
      <p>
        By upgrading your subscription you will have access to all Pro features
        right away. Accept and pay?
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <h3>Due today:</h3>
            </td>
            <td>
              <h3>{dollarUSFormat.format(15)} USD</h3>
            </td>
          </tr>
        </tbody>
      </table>
      <Modal.Footer>
        <Button hierarchy="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button onClick={handleClick} loading={isLoading} disabled={isLoading}>
          Pay now
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SubscriptionStarterToProModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SubscriptionStarterToProModal;
