import { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import ButtonGroup from '@scalero-inc/forno-button-group';
import Dropdown from '@scalero-inc/forno-dropdown';
import Spinner from '@scalero-inc/forno-spinner';

import { useUserQuery } from 'hooks/api/users';
import { useFilesPaginatedQuery } from 'hooks/api/files';

import Pagination from 'components/pagination';
import FileNewButton from 'containers/file-new-button';
import FilesContainer from 'containers/files-container';

import {
  canAccessTeamFiles,
  canCreateEditDeleteTeamFile,
} from 'utils/permissions';

import { sortingOptions, viewOptions } from './constants';
import styles from './style.module.css';

function DashboardProjectFiles() {
  const { teamId, projectId } = useParams();
  const [view, setView] = useState('grid');
  const customClassname = classNames(styles['dashboard-personal-files']);
  const { isLoading: isUserLoading, data: user } = useUserQuery();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalFiles, setTotalFiles] = useState(0);
  const defaultOrder = sortingOptions[0];
  const [order, setOrder] = useState(defaultOrder.value);
  const queryOrder =
    order === defaultOrder.value ? '-created_at' : 'created_at';
  const {
    isLoading: isFileLoading,
    isFetching: isFileFetching,
    data: files,
  } = useFilesPaginatedQuery({
    queryOrder,
    queryPage: page,
    pageSize,
    teamId,
    projectId,
  });

  useEffect(() => {
    if (files) setTotalFiles(files.count);
  }, [files]);

  const onPageChange = useCallback(
    (pageNumber) => {
      setPage(pageNumber);
    },
    [setPage]
  );
  const onPageSizeChange = useCallback(
    (size) => {
      setPageSize(size);
      setPage(1);
    },
    [setPageSize, setPage]
  );

  if (isUserLoading) return <Spinner />;

  const handleChange = (e) => {
    const newView = e[0];
    setView(newView);
  };

  const fileCreationBlocked = !canCreateEditDeleteTeamFile({ user, teamId });
  const fileAccessBlocked = !canAccessTeamFiles({ user, teamId });

  return (
    <div className={customClassname}>
      <div className={styles['dashboard-personal-files-actions']}>
        <FileNewButton
          teamId={teamId}
          projectId={projectId}
          disabled={fileCreationBlocked}
        />
        <div className={styles['dashboard-personal-files-right-actions']}>
          <div className={styles['dashboard-personal-files-action-wrapper']}>
            <span>Sort By:</span>
            <Dropdown
              size="s"
              theme="light"
              options={sortingOptions}
              defaultValue={defaultOrder}
              className={styles['dashboard-personal-files-sort-dropdown']}
              onChange={(e) => {
                setOrder(e.value);
              }}
              value={order}
              look="text"
            />
          </div>
          <div className={styles['dashboard-personal-files-action-wrapper']}>
            <span>View: </span>
            <ButtonGroup
              type="radio"
              theme="light"
              options={viewOptions}
              onChange={handleChange}
              initialValue={[view]}
              className={styles['dashboard-personal-files-view-buttons']}
            >
              {viewOptions.map(({ value, icon: Icon }) => {
                return (
                  <ButtonGroup.Button key={value} value={value}>
                    <Icon />
                  </ButtonGroup.Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
      </div>

      <div className={styles['dashboard-files-container']}>
        <FilesContainer
          view={view}
          files={files?.results || []}
          page={page}
          setPage={onPageChange}
          isBlocked={fileAccessBlocked}
          isLoading={isFileLoading || isFileFetching}
        />
      </div>

      <Pagination
        total={totalFiles}
        current={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
}

DashboardProjectFiles.propTypes = {};

DashboardProjectFiles.defaultProps = {};

export default DashboardProjectFiles;
