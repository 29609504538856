import PricingCardsPrivate from 'containers/pricing-cards-private';
import { FiAlertTriangle } from 'react-icons/fi';

import { useUserQuery } from 'hooks/api/users';

import { getFreeTrialEndDate, isVIP } from 'utils/permissions';

import styles from './style.module.css';

function PersonalBillingPricing() {
  const { data: user } = useUserQuery();

  const freeTrialEndDate = getFreeTrialEndDate({ user });
  return (
    <div className={styles['personal-billing-pricing']}>
      {isVIP({ user }) ? (
        <div className={styles['personal-billing-pricing-message']}>
          <div className={styles['personal-billing-pricing-title']}>
            <FiAlertTriangle />
            <h2>Select a plan</h2>
          </div>
          <p>
            Starting <strong>{freeTrialEndDate}, 2022</strong> you will need to
            be subscribed to continue using Cannoli. Select a plan so you
            don&apos;t lose access to your files.
          </p>
        </div>
      ) : (
        <p>Select a plan to regain access to your files</p>
      )}
      <PricingCardsPrivate type="create" />
    </div>
  );
}

export default PersonalBillingPricing;
