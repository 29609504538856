import PropTypes from 'prop-types';

import styles from './style.module.css';

function ImageCard({ title, description, src, alt, link }) {
  return (
    <a href={link} className={styles.card}>
      <img src={src} alt={alt} className={styles['card-image']} />
      <div className={styles['card-text-container']}>
        <h3>{title}</h3>
        <p className="paragraph-r">{description}</p>
      </div>
    </a>
  );
}

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.string,
};

ImageCard.defaultProps = {
  link: '',
};

export default ImageCard;
