import PolicyContainer from 'containers/policy-container';

function PrivacyPolicyText() {
  return (
    <PolicyContainer>
      <section id="privacy-policy-for-websites">
        <h3>Privacy Policy for Websites</h3>
        <p>
          The generic use and disclosure of personal information that is
          collected from the individuals online, through websites.
        </p>
      </section>

      <section id="scalero-privacy-policy">
        <h3>Scalero Privacy Policy</h3>
        <p>Last updated: 08 11 2022</p>
      </section>

      <section id="introduction">
        <h3>1.0 Introduction</h3>
        <p>
          Scalero is a technology company headquartered in California, which
          focuses on providing services to companies in need of lifecycle
          marketing services and software. For more information about our
          (services, please refer to our website:{' '}
          <a href="https://scalero.io/">https://scalero.io/</a>.
        </p>
        <p>
          This Privacy Policy is applicable to Scalero (“we,” “our,” or “us”) as
          related to our services, which collectively include:
        </p>
        <ul>
          <li>
            the use of <a href="https://scalero.io/">https://scalero.io/</a>{' '}
            (“website”)
          </li>
          <li>
            the use of the application website{' '}
            <a href="https://cannoli.app/">https://cannoli.app/</a>
            (“application”)
          </li>
          <li>
            the use of the application website{' '}
            <a href="https://usetiramisu.com/">https://usetiramisu.com/</a>
            (“application”)
          </li>
          <li>social media messages and marketing campaigns and</li>
          <li>the use of our products and services.</li>
        </ul>
        <p>
          This Privacy Policy sets out the essential details relating to your
          personal data relationships with Scalero as:
        </p>
        <ul>
          <li>A services client</li>
          <li>A prospective client</li>
          <li>A website visitor</li>
          <li>An end user of the application (“end user”)</li>
          <li>A job applicant and</li>
          <li>Partners</li>
        </ul>
        <p>
          Clients contract the use of our application and give access to their
          employees and other third parties, as solely decided by them, by
          creating users who access the application with their email address and
          credentials. The clients&apos; administrators grant end users roles,
          which result in different permissions and access rights to the
          information held in the Client account.
        </p>
      </section>
      <section id="personal-information-we-collect">
        <h3>2.0 Personal Information We Collect</h3>
        <h5>2.1 Information You Choose to Provide to Us</h5>
        <h6>WHEN</h6>
        <p>We may ask you to provide personal information when:</p>
        <ul>
          <li>
            You use the website to download articles, data sheets or eBooks.
          </li>
          <li> You request a free trial or demo.</li>
          <li>You refer a friend to us.</li>
          <li>
            You connect with us directly via phone calls or video conferencing
            platforms.
          </li>
          <li>
            We or Client Solutions Administrators grant you access to the
            application.
          </li>
          <li>
            You or Client Solutions Administrators upload or enter personal
            information into the applications or services&apos; project
            management tools.
          </li>
          <li>You participate in a marketing/sales promotion.</li>
          <li>You attend trade events and other industry networking events.</li>
          <li>You register or attend a webinar or other event.</li>
          <li>You participate in programs we may offer from time to time.</li>
          <li>You participate in chats.</li>
          <li>You pay for our services.</li>
        </ul>
        <p>
          If you choose to provide us with a third-party&apos;s personal
          information (the person&apos;s name, email and company) when taking
          part in our referral program, you represent that you have the
          third-party&apos;s permission to do so.
        </p>
        <h6>WHAT</h6>
        <p>
          We collect personal information that may include first and last name,
          business email address, phone number, company name and/or any messages
          you submit to the website.
        </p>
        <p>
          As an end user of the application, we collect your name, business
          email address and any comments you make in the application.
        </p>
        <p>
          In addition, we may collect data uploaded by you, your employer or
          other users of the application that may be required to use Scalero
          services. We expect all users to follow their organization&apos;s
          privacy policy and any applicable regulatory requirements when
          uploading, accessing and using personal information into our
          application. The data uploaded may include personal information like:
        </p>
        <ul>
          <li>Employee names, email addresses and contractual agreements.</li>
          <li>Profile pictures Company logos and other branded assets.</li>
          <li>
            Vendor names, email addresses, contractual agreements or other
            personal data necessary for Scalero services.
          </li>
          <li>
            Customer names and email addresses used to provide services within
            Scalero&apos;s platform.
          </li>
          <li>
            As a job applicant, we may also collect your resume and cover
            letter.
          </li>
        </ul>
        <h5>2.2 Information We Collect Automatically</h5>
        <h6>WHEN</h6>
        <p>
          We collect information about your visits to the website and the
          application when you land on any of our web pages through cookies and
          similar tracking technology.
        </p>
        <h6>WHAT</h6>
        <p>The information collected includes:</p>
        <ul>
          <li>Access times.</li>
          <li>The pages you view.</li>
          <li>The links you click on.</li>
          <li>The search terms you enter.</li>
          <li>actions you take in connection with any of the visited pages.</li>
          <li>
            your device information such as IP address, location, browser type
            and language.
          </li>
          <li>
            the Uniform Resource Locator (URL) of the website that referred you
            to our website and
          </li>
          <li>
            the URL you browse away from our pages if you click on an external
            link
          </li>
          <p>
            We may also collect information when you open email messages from us
            or click on links within those email messages.
          </p>
        </ul>
        <h5>2.3 Information We May Collect From Third Parties</h5>
        <h6>WHEN</h6>
        <p>
          We may combine the information we collect from your direct
          interactions with us with information obtained through other
          third-party sources, such as Google and Clearbit.
        </p>
        <h6>WHAT</h6>
        <p>
          The personal information collected includes company name, age range
          and location range.
        </p>
      </section>
      <section id="how-we-use-personal-infromation">
        <h3>3.0 How We Use Personal Information</h3>
        <p>We use your personal information to:</p>
        <ul>
          <li>
            Deliver the contracted services and allow full use of the
            application functionality as purchased by the clients.
          </li>
          <li>
            Deliver training and support to our application end users and/or
            carry out the transactions you have requested.
          </li>
          <li>
            To communicate with you directly through emails, calls, chats, video
            conferencing.
          </li>
          <li>Process payments for application subscriptions.</li>
          <li>
            Send communications to you about:
            <ul>
              <li>New application features and upgrades.</li>
              <li>Our services and offerings.</li>
              <li>Event announcements.</li>
              <li>Product notices and changes to our terms and policies.</li>
              <li>
                Particular programs in which you have chosen to participate.
              </li>
              <li>Promotional offers and surveys.</li>
              <li>Scheduling demos and managing free trials.</li>
            </ul>
          </li>
          <li>
            Advertise and market our products and services, including delivering
            interest-based advertisements on this website and other sites or
            content syndication platforms and websites.
          </li>
          <li>
            Carry out market research to understand how to improve our services
            and their delivery.
          </li>
          <li>Create and manage marketing campaigns.</li>
          <li>Generate sales leads and increase our market share.</li>
          <li>
            Analyze user clicks and usage of the applications and website to
            improve user experience and maximize usage of our services.
          </li>
          <li>
            Manage our website and application to maintain and deliver the
            contracted functionality and services.
          </li>
          <li>
            Enforce our website and applications terms and/or separate contracts
            (if applicable) with you.
          </li>
          <li>Prevent fraud and other prohibited or illegal activities.</li>
          <li>
            Protect the security or integrity of the website, applications, our
            business or services.
          </li>
          <li>
            Or otherwise, as disclosed to you at the point of collection or as
            required or permitted by law.
          </li>
        </ul>
        <p>
          Please note that sometimes we may record the video conferencing call
          in which you participate to analyze and improve our staff&apos;s
          communication skills. If we do so, we will be announcing it at the
          beginning of the conference call and in the meeting invite, and we
          will be providing a link to our Privacy Policy in the meeting invites
          and on the registration page.
        </p>
        <p>We do not sell your information to any third party.</p>
      </section>
      <section id="how-we-share-personal-information">
        <h3>4.0 How We Share Personal Information</h3>
        <h5>Our Application and Services</h5>
        <p>
          If you are an end user of our application, your personal information
          may be viewed by other users with access to the application.
        </p>
        <h5 id="service-providers">Service Providers</h5>
        <p>
          We use third parties to help us provide our services. They will have
          access to your information as collected by the website or the
          application, as reasonably necessary to perform the contracted tasks
          on our behalf. We sign contractual agreements to obligate them to
          protect the personal information, only use it to deliver the
          contracted services to us, prohibit them from selling it and not
          disclose it without our knowledge and permission.
        </p>
        <table>
          <thead>
            <tr>
              <th>Service Provider</th>
              <th>Business Purpose</th>
              <th>Infrmation Collected by the Service Provider</th>
              <th>Data Location</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Clearbit</td>
              <td>
                Provide information of website and applications visitors in
                order to identify and contact potential clients and end users
              </td>
              <td>Pageviews</td>
            </tr>
            <tr>
              <td>Customer.io</td>
              <td>Communication with users and clients</td>
              <td>
                End user&apos;s email, website and applications behavior and
                navigation data
              </td>
            </tr>
            <tr>
              <td>Mode</td>
              <td>Tool for analytics, to help us analyze our business</td>
              <td>
                End user&apos;s names, emails, company names, website and
                application behavior
              </td>
            </tr>
            <tr>
              <td>Amazon Web Services</td>
              <td>Cloud infrastructure</td>
              <td>
                End user&apos;s names, emails, company names, website and
                application behavior
              </td>
            </tr>
            <tr>
              <td>Stripe</td>
              <td>Payment collection</td>
              <td>
                First name, last name, credit and debit card data, email of
                paying end user
              </td>
            </tr>
            <tr>
              <td>Heap</td>
              <td>Website and applications analytics</td>
              <td>
                End user&apos;s email, hits, sessions and all relative to
                behavior and navigation data
              </td>
            </tr>
            <tr>
              <td>Google Analytics</td>
              <td>Website and applications analytics</td>
              <td>
                Hits, sessions and all relative to behavior and navigation data.
                End user&apos;s age estimate, gender estimate and interests
                estimate
              </td>
            </tr>
            <tr>
              <td>Retool</td>
              <td>
                Display application usage and assign employees to review data
              </td>
              <td>End user&apos;s name, email and application behavior</td>
            </tr>
          </tbody>
        </table>
        <h5>Legal Disclosures</h5>
        <p>
          It is possible that we may need to disclose personal information when
          required by law, subpoena or other legal processes as identified in
          the applicable legislation.
        </p>
        <p>
          We attempt to notify our clients about legal demands for their
          personal data when appropriate in our judgment unless prohibited by
          law or court order or when the request is an emergency.
        </p>
        <h5>Change in Control</h5>
        <p>
          We can also share your personal data as part of a sale, merger, change
          in control or in preparation for any of these events.
        </p>
        <p>
          Any other entity which buys us or part of our business will have the
          right to continue to use your data, but only in the manner set out in
          this Privacy Policy unless you agree otherwise.
        </p>
      </section>
      <section id="how-we-secure-personal-information">
        <h3>5.0 How We Secure Personal Information</h3>
        <p>
          We are committed to protecting the security of all of the personal
          information we collect and use.
        </p>
        <p>
          We use a variety of physical, administrative and technical safeguards
          designed to help protect it from unauthorized access, use and
          disclosure. We have implemented best-practice standards and controls
          in compliance with internationally recognized security frameworks. We
          use encryption technologies to protect data at rest and in transit
        </p>
      </section>
      <section id="your-rights">
        <h3>6.0 Your Rights</h3>
        <p>
          We provide the same suite of services to all of our clients and end
          users worldwide.
        </p>
        <p>
          We offer the following rights to all individuals regardless of their
          location or applicable privacy regulations.
        </p>
        <p>For personal information we have about you, you can:</p>
        <ul>
          <li>Access your personal information or request a copy</li>
        </ul>
        <p>
          You have the right to obtain information about what personal
          information we process about you or to obtain a copy of your personal
          information.
        </p>
        <p>
          If you have provided personal information to us, you may contact us to
          obtain an outline of what information we have about you or a copy of
          the information
        </p>
        <p>
          If you are an end user of the application, you can log in to see the
          personal information in the account or approach your employer for more
          information.
        </p>
        <ul>
          <li>
            You have the right to be notified of what personal information we
            collect about you and how we use it, disclose it and protect it.
          </li>
        </ul>
        <p>
          This Privacy Policy describes what personal information we collect and
          our privacy practices. We may also have additional privacy notices and
          statements available to you at the point of providing information to
          us directly.
        </p>
        <ul>
          <li>Change or correct your personal information.</li>
        </ul>
        <p>
          You have the right to update/correct your personal information or ask
          us to do it on your behalf.
        </p>
        <p>
          You can edit your information through the user account in the
          application or ask us to change or correct it by contacting us at{' '}
          <a href="mailto:scalero@scalero.io">scalero@scalero.io</a>.
        </p>
        <ul>
          <li>Delete or erase your personal information.</li>
        </ul>
        <p>
          You have the right to request the deletion of your personal
          information at any time. We will communicate back to you within
          reasonable timelines the result of your request. We may not be able to
          delete or erase your personal information, but we will inform you of
          these reasons and any further actions available to you.
        </p>
        <ul>
          <li>Object to the processing of your personal information.</li>
        </ul>
        <p>
          You have the right to object to our processing of your personal
          information for direct marketing purposes. This means that we will
          stop using your personal information for these purposes.
        </p>
        <ul>
          <li>
            Ask us to restrict the processing of your personal information.
          </li>
        </ul>
        <p>
          You may have the right to ask us to limit the way that we use your
          personal information.
        </p>
        <ul>
          <li>Export your personal data.</li>
        </ul>
        <p>
          You have the right to request that we export to you in a
          machine-readable format all of the personal information we have about
          you.
        </p>
        <p>
          We do not process personal information through the use of automated
          means.
        </p>
        <p>
          If you would like to exercise any of the rights described above,
          please contact us at{' '}
          <a href="mailto:scalero@scalero.io">scalero@scalero.io</a>.
        </p>
        <p>
          You also have the right to lodge a complaint with the local
          organizations in charge of enforcing the privacy legislation
          applicable in your territory.
        </p>
      </section>
      <section id="how-long-we-keep-your-personal-information">
        <h3>7.0 How Long We Keep Your Personal Information</h3>
        <p>
          We retain information as long as it is necessary to provide the
          services to you and our clients, subject to any legal obligations to
          further retain such information.
        </p>
        <p>
          We may also retain information to comply with the law, prevent fraud,
          collect fees, resolve disputes, troubleshoot problems, assist with
          investigations, enforce our Terms of Service and take other actions
          permitted by law.
        </p>
        <p>
          The information we retain will be handled following this Privacy
          Policy.
        </p>
        <p>
          Information connected to you that is no longer necessary and relevant
          to provide our services may be deidentified or aggregated with other
          non-personal data. This information may provide insights that are
          commercially valuable to Scalero, such as statistics of the use of the
          services.
        </p>
      </section>
      <section id="other-important-information">
        <h3>8.0 Other Important Information</h3>
        <p>
          We process data in locations California, New York, Mexico City and
          Copenhagen and rely on legally-provided mechanisms to lawfully
          transfer data across borders, such as contracts incorporating data
          protection and sharing obligations.
        </p>
        <p>
          We will only collect and process your personal data where we have a
          lawful reason for its collection.
        </p>
        <p>
          When you visit our website and provide us with your personal
          information, we collect and use it with your consent.
        </p>
        <p>
          As an application end user, you consent to our collection of your
          personal information when you log in for the first time. However, your
          employer has control of the account and may upload and share
          additional personal information. Your employer&apos;s responsibility
          is to ensure that collecting, using and sharing the personal
          information uploaded to the application complies with all applicable
          legislation.
        </p>
        <p>
          Where we rely on your consent to process personal data, you have the
          right to withdraw or decline your consent at any time. If you have any
          questions about the lawful bases upon which we collect and use your
          personal data, please contact us at{' '}
          <a href="mailto:scalero@scalero.io">scalero@scalero.io</a>.
        </p>
        <h5>How to select your communications preferences</h5>
        <p>
          You may choose to receive or not receive marketing communications from
          us. Please click the “Unsubscribe” link in the email we sent you to
          stop receiving marketing communications.
        </p>
        <p>
          You may choose which information we collect automatically from your
          device by controlling cookie settings on your browser.
        </p>
        <p>
          Even if you opt-out of receiving marketing communications, we may
          still communicate with you regarding security and privacy issues,
          servicing your account, fulfilling your requests, or administering any
          promotion or any program in which you may have elected to participate.
        </p>
      </section>
      <section id="contact-information">
        <h3>9.0 Contact Information</h3>
        <p>
          You may contact us to exercise any of your rights or ask for more
          information about your personal information and our privacy practices
          by contacting us at{' '}
          <a href="mailto:scalero@scalero.io">scalero@scalero.io</a>.
        </p>
      </section>
      <section id="appendix">
        <h3>Appendix</h3>
      </section>
      <section id="for-individuals-in-europe">
        <h4>
          A.1 For Individuals Based in the European Union (EU), European
          Economic Area (EEA) and Switzerland
        </h4>
        <p>
          If you are based in one of these jurisdictions, Scalero is the
          controller of your personal data collected in the following instances:
        </p>
        <ul>
          <li>
            When you visit our website{' '}
            <a href="https://scalero.io">https://scalero.io</a>
          </li>
          <li>
            When you use any of our applications,{' '}
            <a href="https://cannoli.app">https://cannoli.app</a> and{' '}
            <a href="https://usetiramisu.com">https://usetiramisu.com</a>
          </li>
          <li>
            When we process your personal data for sales and marketing or
            project management purposes
          </li>
        </ul>
        <p>
          Scalero is a processor of all personal data processed on the website
          or applications, on behalf of our clients. We only process the
          personal data under their direction. Please contact your employer or
          the organization that granted you access to the application for
          details on their privacy practices.
        </p>
        <p>
          We only process personal data if we have a lawful basis for doing so.
          The lawful bases applicable to our processing as controller are:
        </p>
        <ul>
          <li>
            Consent: We will ask for your express and informed consent every
            time we collect your personal data on this legal basis.
          </li>
          <li>
            Contractual basis: We process the personal data as necessary to
            fulfill our contractual terms with you or our clients.
          </li>
          <li>
            Legitimate interest: We process the names, contact details, job
            titles, companies of our existing and prospective clients for our
            marketing purposes, including market research and sales leads
            generation.
          </li>
        </ul>
        <p>You have the following rights under the GDPR:</p>
        <ul>
          <li>
            Be informed about the collection and use of your personal data
          </li>
          <li>Access your personal data</li>
          <li>Correct errors in your personal data</li>
          <li>Erase your personal data</li>
          <li>
            Object to the processing of your personal data.
            <ul>
              <li>
                This right is also available to individuals whose personal data
                is processed by us for direct marketing purposes. If you object
                to the processing of your personal data for direct marketing
                purposes, we shall stop processing within 30 days of receipt of
                your request.
              </li>
            </ul>
          </li>
          <li>Export your personal data</li>
          <li>
            Restrict our processing of your personal data for specific reasons,
            including any of the purposes supported by the legitimate interest
            legal bases (see the section above).
          </li>
        </ul>
        <p>
          We process personal data in California, New York, Mexico City and
          Copenhagen and share it with our service providers in their respective
          jurisdictions. We use standard contractual clauses as the data
          transfer mechanism of transferring EU data to countries subject to
          data transfer requirements.{' '}
          <a href="#service-providers">
            See the table of our service providers here
          </a>
          . (link to the table above)
        </p>
        <p>
          You may contact us at{' '}
          <a href="scalero@scalero.io">scalero@scalero.io</a>.
        </p>
        <p>
          You may also lodge a complaint with your local supervisory authority,
          EU Data Protection Authorities (DPAs) or Swiss Federal Data Protection
          and Information Commissioner (FDPIC). See their contact details here{' '}
          <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">
            National Data Protection Authorities
          </a>
          .
        </p>
      </section>
      <section id="for-individuals-in-california">
        <h4>A.2 For Individuals Based in California</h4>
        <p>
          This section provides additional specific information for consumers
          based in California as required by the California Consumer Privacy Act
          of 2018 (“CCPA”).
        </p>
        <h5>A.2.1 Collection and Use of Personal Information</h5>
        <p>
          In the last 12 months, we have collected the following categories of
          personal information:
        </p>
        <ul>
          <li>
            Identifiers, such as your name,, email address,, telephone number or
            other similar identifiers
          </li>
          <li>
            California Customer Records (Cal. Civ. Code § 1798.80(e)), such as
            username and password, company name, job title, business email
            address and department
          </li>
          <li>
            Internet/network information, such as your browsing history, log and
            analytics data, information about the device(s) used to access the
            services and information regarding your interaction with our
            websites or services and other usage data
          </li>
          <li>
            Geolocation data, such as information about your location (at
            country and city level) collected from your IP address
          </li>
          <li>URL and navigation data</li>
          <li>Demographics data such as gender, age and interests</li>
          <li>
            Sensory Information, the content, audio and video recordings of
            conference calls between you and us that we record where permitted
            by you and/or the law
          </li>
          <li>
            Profession/employment information that you send to us when applying
            for a position included in your CV and cover letter
          </li>
          <li>
            Other personal information, such as personal information you provide
            to us in relation to a survey, comment, question, request, article
            download or inquiry and any other information you upload to our
            application
          </li>
          <li>
            <p>
              We collect personal information directly from you, from your
              browser or device when you visit our websites, from third parties
              that you permit to share your information or from third parties
              that share public information about you, as stated above.
            </p>
            <p>
              See the section above, “How We Use Personal Information,” to
              understand how we use the personal information collected from
              California consumers.
            </p>
          </li>
        </ul>
        <h5>A.2.2 Recipients of Personal Information</h5>
        <p>
          We share personal information with third parties for business
          purposes. The categories of third parties to whom we disclose your
          personal information may include: (i) our service providers and
          advisors, (ii) marketing and strategic partners; (iii) ad networks and
          advertising partners; (iv) analytics providers; and (v) social
          networks.
        </p>
        <p>
          Please see the “How We Share Information” section of the Privacy
          Policy above for more information.
        </p>
        <h5>A.2.3 California Privacy Rights</h5>
        <p>
          As a California resident, you may be able to exercise the following
          rights in relation to the personal information about you that we have
          collected (subject to certain limitations at law):
        </p>
        <ul>
          <li>
            The right to know any or all of the following information relating
            to your personal information that we have collected and disclosed in
            the last 12 months (upon verification of your identity):
          </li>
          <ul>
            <li>
              The specific pieces of personal information we have collected
              about you
            </li>
            <li>
              The categories of personal information we have collected about you
            </li>
            <li>The categories of sources of the personal information</li>
            <li>
              The categories of personal information that we have disclosed to
              third parties for a business purpose, and the categories of
              recipients to whom this information was disclosed
            </li>
            <li>
              The categories of personal information we have sold and the
              categories of third parties to whom the information was sold, and
            </li>
            <li>
              The business or commercial purposes for collecting or selling the
              personal information.
            </li>
          </ul>
          <li>
            The right to request deletion of personal information we have
            collected from you, subject to certain exceptions.
          </li>
          <li>
            The right to opt-out of personal information sales to third parties
            now or in the future. However, we do not sell your personal
            information.
          </li>
        </ul>
        <p>
          You also have the right to be free of discrimination for exercising
          these rights.
        </p>
        <p>
          Please note that if exercising these rights limits our ability to
          process personal information (such as a deletion request), we may no
          longer be able to provide you with our products and services or engage
          with you in the same manner.
        </p>
        <h5>A.2.4 How to Exercise Your California Consumer Rights</h5>
        <p>
          To exercise your right to know and/or your right to deletion, please
          submit a request by contacting us at{' '}
          <a href="mailto:scalero@scalero.io">scalero@scalero.io</a>.
        </p>
        <p>
          We will need to verify your identity before processing your request
        </p>
        <p>
          In order to verify your identity, we will generally require sufficient
          information from you so that we can match it to the information we
          maintain about you in our systems. Sometimes we may need additional
          personal information from you to be able to identify you. We will
          notify you.
        </p>
        <p>
          We may decline a request to exercise the right to know and/or right to
          deletion, particularly where we cannot verify your identity or locate
          your information in our systems or as permitted by law.
        </p>
        <p>
          You may choose to designate an authorized agent to make a request
          under the CCPA on your behalf. No information will be disclosed until
          the authorized agent&apos;s authority has been reviewed and verified.
          Once an authorized agent has submitted a request, we may require
          additional information (i.e., written authorization from you) to
          confirm the authorized agent&apos;s authority.
        </p>
        <p>
          If you are an employee/former employee of a Scalero client that uses
          our application and services, please direct your requests and/or
          questions directly to your employer/former employer.
        </p>
        <p>
          If you are a third party (auditor, business associate, etc.), who was
          given access to the Scalero applications by a Scalero client, please
          direct your requests and/or questions directly to the Scalero client
          that gave you access.
        </p>
        <h5>Minors Under Age 16</h5>
        <p>
          Our application and services are intended for business use, and we do
          not expect them to be of any interest to minors. We do not
          intentionally collect any personal information of consumers below the
          age of 16. We do not sell the personal information of California
          consumers.
        </p>
      </section>
    </PolicyContainer>
  );
}

export default PrivacyPolicyText;
