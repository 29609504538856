import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import Spinner from '@scalero-inc/forno-spinner';
import Button from '@scalero-inc/forno-button';
import { useFontVariantsQuery } from 'hooks/api/fonts';
import { FiAlertTriangle, FiCheck, FiEdit2, FiPlus } from 'react-icons/fi';
import CustomFontModal from 'containers/custom-font-modal';

import styles from './styles.module.css';

function CustomFonts({ teamId, theme }) {
  const [fontSelected, setFontSelected] = useState();
  const [isCustomFontOpen, setIsCustomFontOpen] = useState(false);
  const openCustomFontWindow = useCallback(() => {
    setIsCustomFontOpen(true);
  }, []);
  const closeCustomFontWindow = useCallback(() => {
    setIsCustomFontOpen(false);
  }, []);

  const { data, isLoading, isFetching, isError } = useFontVariantsQuery({
    teamId,
    config: {
      refetchOnWindowFocus: false,
    },
  });

  const editFont = (customFont) => {
    const font = {
      id: customFont.id,
      family: customFont.family,
      weight: customFont.variant,
      url: customFont.url,
    };
    setFontSelected(font);
    openCustomFontWindow();
  };

  return (
    <div className={styles['dashboard-fonts-container']}>
      <table className={styles['fonts-table']}>
        <tbody>
          <tr>
            <th />
            <th>Font family</th>
            <th>Weights</th>
            <th className={styles.center}>URL</th>
            <th className={styles.center}>Add / Edit</th>
          </tr>

          {isLoading ||
            (isFetching && (
              <tr>
                <td className={styles.center} colSpan={5}>
                  <Spinner />
                </td>
              </tr>
            ))}

          {!isLoading && !isFetching && isError && (
            <tr>
              <td className={styles.center} colSpan={5}>
                Something went wrong, try again later.
              </td>
            </tr>
          )}

          {!isLoading &&
            !isFetching &&
            data?.results &&
            data?.results.length === 0 && (
              <tr>
                <td className={styles.center} colSpan={5}>
                  You don&rsquo;t have any font added yet.
                </td>
              </tr>
            )}

          {!isLoading &&
            !isFetching &&
            !isError &&
            data?.results &&
            data?.results.map((font, fontIndex) => {
              return (
                <tr key={`font_${fontIndex}`}>
                  <th>
                    {font.url ? (
                      <FiCheck size={16} />
                    ) : (
                      <FiAlertTriangle size={16} />
                    )}
                  </th>
                  <td>{font.family}</td>
                  <td>{font.variant}</td>
                  <td className={styles['font-url']}>{font.url}</td>
                  <td className={styles['center-button']}>
                    <Button
                      theme={theme}
                      hierarchy="tertiary"
                      size="s"
                      icon={font.url ? FiEdit2 : FiPlus}
                      onClick={() => editFont(font)}
                    >
                      {font.url ? 'Edit' : 'Add'} font
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {fontSelected && (
        <CustomFontModal
          theme={theme}
          isOpen={isCustomFontOpen}
          close={closeCustomFontWindow}
          font={fontSelected}
          teamId={teamId}
          isEditing={!!fontSelected.url}
        />
      )}
    </div>
  );
}

CustomFonts.propTypes = {
  teamId: PropTypes.number,
  theme: PropTypes.oneOf(['light', 'dark']),
};

CustomFonts.defaultProps = {
  teamId: null,
  theme: 'light',
};

export default CustomFonts;
