import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Menu, MenuLink, MenuList, MenuButton } from '@reach/menu-button';
import IconButton from '@scalero-inc/forno-icon-button';
import { useNavigate } from 'react-router-dom';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FiCopy, FiEdit, FiFilePlus, FiLink, FiTrash } from 'react-icons/fi';
import { toast } from 'react-toastify';
import FileFromTemplateModal from 'containers/file-from-template-modal';
import TemplateDeleteModal from 'containers/template-delete-modal';
import copy from 'copy-to-clipboard';
import {
  useTemplateComponentCreateMutation,
  useTemplateCreateMutation,
  useTemplatesInvalidateQuery,
} from 'hooks/api/templates';

import styles from './styles.module.css';

function TemplateCardMenu({
  id,
  name,
  description,
  templateComponents,
  url,
  teamId,
  projectId,
  numTemplates,
  page,
  setPage,
  isEditable,
}) {
  const navigate = useNavigate();
  const editTemplate = () => {
    navigate(url);
  };
  const copyLink = () => {
    copy(`${window.location.origin}${url}`);
  };

  const invalidateTemplates = useTemplatesInvalidateQuery();
  const componentTemplatesMutation = useTemplateComponentCreateMutation({
    teamId,
    projectId,
  });
  const saveComponentTemplates = (templateId) => {
    templateComponents.forEach((block, index) => {
      componentTemplatesMutation.mutate(
        {
          templateId,
          position: index,
          component: block.component.id,
        },
        {
          onSuccess: () => {
            invalidateTemplates();
            toast.success('Template duplicated successfully');
          },
          onError: () => {
            toast.error('Error duplicating template');
          },
        }
      );
    });
  };
  const templatesMutation = useTemplateCreateMutation({
    teamId,
    projectId,
    config: {
      onSuccess: (data) => {
        saveComponentTemplates(data.id);
      },
      onError: () => {
        toast.error('Error duplicating template');
      },
    },
  });
  const duplicateTemplate = useCallback(() => {
    templatesMutation.mutate({ name: `Copy of ${name}`, description });
  }, [description, name, templatesMutation]);

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const openCreateWindow = useCallback(
    () => setCreateIsOpen(true),
    [setCreateIsOpen]
  );
  const closeCreateWindow = useCallback(
    () => setCreateIsOpen(false),
    [setCreateIsOpen]
  );

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const openDeleteWindow = useCallback(
    () => setDeleteIsOpen(true),
    [setDeleteIsOpen]
  );
  const closeDeleteWindow = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );

  return (
    <div>
      <TemplateDeleteModal
        id={id}
        name={name}
        teamId={teamId}
        projectId={projectId}
        numTemplates={numTemplates}
        page={page}
        setPage={setPage}
        onDismiss={closeDeleteWindow}
        isOpen={deleteIsOpen}
      />
      <FileFromTemplateModal
        team={teamId}
        project={projectId}
        template={id}
        isOpen={createIsOpen}
        onDismiss={closeCreateWindow}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          theme="light"
          size="l"
          hierarchy="tertiary"
          className={styles['template-card-menu-button']}
        >
          <BiDotsVerticalRounded />
        </MenuButton>
        <MenuList className={styles['template-card-menu-list']}>
          {isEditable && (
            <MenuLink
              className={styles['template-card-menu-item']}
              onSelect={duplicateTemplate}
              disabled={
                templatesMutation.isLoading ||
                componentTemplatesMutation.isLoading
              }
            >
              <FiCopy />
              Duplicate
            </MenuLink>
          )}
          <MenuLink
            className={styles['template-card-menu-item']}
            onSelect={copyLink}
          >
            <FiLink />
            Copy link
          </MenuLink>
          {isEditable && (
            <>
              <MenuLink
                className={styles['template-card-menu-item']}
                onSelect={openCreateWindow}
              >
                <FiFilePlus />
                Create Cannoli file
              </MenuLink>
              <MenuLink
                className={styles['template-card-menu-item']}
                onSelect={editTemplate}
              >
                <FiEdit />
                Edit
              </MenuLink>
              <MenuLink
                className={styles['template-card-menu-item']}
                onSelect={openDeleteWindow}
              >
                <FiTrash />
                Delete
              </MenuLink>
            </>
          )}
        </MenuList>
      </Menu>
    </div>
  );
}

TemplateCardMenu.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  templateComponents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  url: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  numTemplates: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

TemplateCardMenu.defaultProps = {
  description: '',
  teamId: null,
  projectId: null,
  isEditable: false,
};

export default TemplateCardMenu;
