import { useEffect, useRef, useCallback } from 'react';

export default function useOutsideClick({ callback }) {
  const ref = useRef(null);

  const handleClick = useCallback(
    (event) => {
      if (!ref.current.contains(event.target)) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return ref;
}
