import PropTypes from 'prop-types';
import Dropdown from '@scalero-inc/forno-dropdown';
import Spinner from '@scalero-inc/forno-spinner';
import { useFilesByTeamProjectQuery } from 'hooks/api/files';

function FileProjectDropdown({ teamId, projectId, onProjectChange }) {
  const projectFilesQuery = useFilesByTeamProjectQuery();
  const { isLoading, isError, data: projectsData } = projectFilesQuery;

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <div>Something went wrong, try again later.</div>;
  }

  const getProjectOptions = (files) => {
    const projects = files.teams.map((teamItem) => ({
      label: teamItem.name,
      id: teamItem.id,
      options: teamItem.projects.map((projectItem) => ({
        label: projectItem.name,
        value: projectItem.id,
      })),
    }));
    if (teamId) {
      projects.push({
        label: 'Remove from project',
        value: null,
      });
    }
    return projects;
  };

  const projects = projectsData ? getProjectOptions(projectsData) : [];
  const teamObject = teamId
    ? projects.filter((team) => team.id === +teamId)[0]
    : null;
  const projectObject = projectId
    ? teamObject.options?.filter((option) => option.value === +projectId)[0]
    : null;

  return (
    <Dropdown
      id="team"
      size="s"
      theme="light"
      options={projects}
      onChange={onProjectChange}
      defaultValue={projectObject}
    />
  );
}

FileProjectDropdown.propTypes = {
  teamId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  onProjectChange: PropTypes.func.isRequired,
};

FileProjectDropdown.defaultProps = {};

export default FileProjectDropdown;
