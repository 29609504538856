import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FiExternalLink, FiPlus } from 'react-icons/fi';
import { useState, useCallback } from 'react';

import Button from '@scalero-inc/forno-button';
import IconButton from '@scalero-inc/forno-icon-button';

import { useUserQuery } from 'hooks/api/users';

import IntegrationAddModal from 'containers/integration-add-modal';
import IntegrationUpdateModal from 'containers/integration-update-modal';
import IntegrationRemoveModal from 'containers/integration-remove-modal';
import {
  hasActiveProAccount,
  hasActiveTeamAccount,
  isAllAccess,
} from 'utils/permissions';

import styles from './style.module.css';
import IntegrationMenu from './integration-menu';

function IntegrationBlock({
  image: Image,
  description,
  name,
  esp,
  docsLink,
  link,
  apiKey,
  id,
  modalImg: ModalImage,
}) {
  const customClassname = classNames(styles['integration-block']);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const openAdd = useCallback(() => setAddIsOpen(true), [setAddIsOpen]);
  const closeAdd = useCallback(() => setAddIsOpen(false), [setAddIsOpen]);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const openEdit = useCallback(() => setEditIsOpen(true), [setEditIsOpen]);
  const closeEdit = useCallback(() => setEditIsOpen(false), [setEditIsOpen]);
  const [removeIsOpen, setRemoveIsOpen] = useState(false);
  const openRemove = useCallback(
    () => setRemoveIsOpen(true),
    [setRemoveIsOpen]
  );
  const closeRemove = useCallback(
    () => setRemoveIsOpen(false),
    [setRemoveIsOpen]
  );

  const { data: user } = useUserQuery();

  const permission = user
    ? isAllAccess({ user }) ||
      hasActiveTeamAccount({ user }) ||
      hasActiveProAccount({ user })
    : false;

  const connected = !!apiKey;

  return (
    <article className={customClassname}>
      <div className={styles['integration-block-main']}>
        {Image ? (
          <div className={styles['image-container']}>
            <Image className={styles['logo-image']} />
          </div>
        ) : null}
        <div className={styles['integration-block-information']}>
          <div className={styles['integration-block-header']}>
            <h5>{name}</h5>
            <a rel="noreferrer noopener" target="_blank" href={link}>
              <IconButton hierarchy="tertiary">
                <FiExternalLink />
              </IconButton>
            </a>
            {connected || esp === 'Figma' ? (
              <span className={styles['connected-tag']}>Connected</span>
            ) : null}
          </div>
          <p>{description}</p>
        </div>
      </div>
      {connected || esp === 'Figma' ? (
        <>
          <IntegrationUpdateModal
            name={name}
            esp={esp}
            docsLink={docsLink}
            isOpen={editIsOpen}
            close={closeEdit}
            apiKey={apiKey || ''}
            id={id}
          />
          <IntegrationRemoveModal
            name={name}
            esp={esp}
            docsLink={docsLink}
            isOpen={removeIsOpen}
            close={closeRemove}
            id={id}
            img={ModalImage}
          />
          {esp !== 'Figma' ? (
            <>
              <IntegrationMenu
                func={openEdit}
                name={name}
                url={docsLink}
                disabled={!connected || !permission}
              />
              <Button
                onClick={connected ? openRemove : openAdd}
                disabled={!permission}
                hierarchy={connected ? 'primary' : 'tertiary'}
                status={connected ? 'danger' : 'normal'}
                icon={connected ? null : FiPlus}
                size="s"
              >
                <p>{connected ? 'Revoke access' : 'Connect'}</p>
              </Button>
            </>
          ) : null}
        </>
      ) : (
        <>
          <IntegrationAddModal
            name={name}
            esp={esp}
            docsLink={docsLink}
            isOpen={addIsOpen}
            close={closeAdd}
            img={ModalImage}
          />
          <IntegrationMenu
            func={openEdit}
            name={name}
            url={docsLink}
            disabled={!connected || !permission}
          />
          <Button
            onClick={connected ? openRemove : openAdd}
            disabled={!permission}
            hierarchy={connected ? 'primary' : 'tertiary'}
            status={connected ? 'danger' : 'normal'}
            icon={connected ? null : FiPlus}
            size="s"
          >
            <p>{connected ? 'Revoke access' : 'Connect'}</p>
          </Button>
        </>
      )}
    </article>
  );
}

IntegrationBlock.propTypes = {
  image: PropTypes.shape({}),
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  esp: PropTypes.string.isRequired,
  docsLink: PropTypes.string,
  link: PropTypes.string,
  apiKey: PropTypes.string,
  id: PropTypes.number,
  modalImg: PropTypes.shape({}),
};

IntegrationBlock.defaultProps = {
  image: null,
  description: 'Description not available',
  docsLink: null,
  link: null,
  apiKey: null,
  id: null,
  modalImg: null,
};

export default IntegrationBlock;
