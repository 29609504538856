import PropTypes from 'prop-types';

import styles from './styles.module.css';

function InputTextarea({ label, placeholder, field, handleChange }) {
  return (
    <div className={styles['input-textarea-container']}>
      <label htmlFor={label}>{label}</label>

      <div className={styles['input-textarea']}>
        <textarea
          {...field}
          rows="6"
          placeholder={placeholder}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
        />
      </div>
    </div>
  );
}

InputTextarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({ value: PropTypes.string }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

InputTextarea.defaultProps = {
  label: '',
  placeholder: '',
};

export default InputTextarea;
