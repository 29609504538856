import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { ErrorBoundary } from 'react-error-boundary';
import HtmlToReactErrorFallback from './html-to-react-error-fallback';
import { DEFAULT_PARSE_OPTIONS } from './constants';

const Parse = ({ html, options }) => {
  return parse(html, options);
};

function getFallback(errors, html) {
  return <HtmlToReactErrorFallback {...errors} html={html} />;
}

function HtmlToReact({ html, options, children }) {
  return (
    <ErrorBoundary
      fallbackRender={(errors) => getFallback(errors, html)}
      resetKeys={[html]}
    >
      {children ? (
        children({ parsedHtml: Parse({ html, options }) })
      ) : (
        <Parse html={html || ''} options={options} />
      )}
    </ErrorBoundary>
  );
}

HtmlToReact.propTypes = {
  html: PropTypes.string,
  options: PropTypes.shape({}),
  children: PropTypes.func,
};

HtmlToReact.defaultProps = {
  html: null,
  options: DEFAULT_PARSE_OPTIONS,
  children: null,
};

export default HtmlToReact;
