import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './style.module.css';

function CompareSliderDivider({ className, isFocused }) {
  const customClassName = classnames(
    'compare-slider-divider',
    styles['compare-slider-divider'],
    className,
    {}
  );
  return <span className={customClassName} data-focused={isFocused} />;
}

CompareSliderDivider.propTypes = {
  className: PropTypes.string,
  isFocused: PropTypes.bool,
};

CompareSliderDivider.defaultProps = {
  className: null,
  isFocused: false,
};

export default CompareSliderDivider;
