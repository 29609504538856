import propTypes from 'prop-types';
import EditorPanelLayoutContent from 'components/editor-panel-layout-content';
import BlocksContainer from 'components/blocks-container';
import FontEditorPanel from 'containers/font-editor-panel';
import { useEditor } from 'pages/editor/editor-context';
import { EditorPanelLeftContextProvider } from './editor-panel-context';

import styles from './styles.module.css';

export function EditorPanelLeft({ feature, setFeature }) {
  const { fileId } = useEditor();
  const isEditingFile = !!fileId;
  const titles = {
    layout: 'Layers',
    blocks: 'Blocks library',
    fonts: 'Fonts settings',
  };

  return (
    <EditorPanelLeftContextProvider>
      <section data-theme="dark" className={styles['editor-panel-container']}>
        <header className={styles['editor-panel-header']}>
          {titles[feature]}
        </header>
        {feature === 'layout' && (
          <EditorPanelLayoutContent setFeature={setFeature} />
        )}
        {isEditingFile && feature === 'blocks' && <BlocksContainer />}
        {feature === 'fonts' && <FontEditorPanel />}
      </section>
    </EditorPanelLeftContextProvider>
  );
}

EditorPanelLeft.propTypes = {
  feature: propTypes.string.isRequired,
  setFeature: propTypes.func.isRequired,
};

EditorPanelLeft.defaultProps = {};
