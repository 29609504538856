import { tagNameRegex, socialIconNameRegex } from './parser/dictionaries';

export function getName({ name }, imageType) {
  const regex = imageType === 'IMAGE' ? tagNameRegex : socialIconNameRegex;
  const imageName = name
    .toUpperCase()
    .replace(regex, '')
    .replace(/[-|_]/g, ' ')
    .trim();

  if (imageName === '') {
    return { name: imageType };
  }

  return { name: imageName };
}

export function getSource(fills, images, nodeImages, rest) {
  const { id } = rest;
  const [first] = fills;
  const { imageRef } = first || {};
  const url = nodeImages[id] ? nodeImages[id] : images[imageRef];

  return {
    src: url,
  };
}

export function getBackgroundImage(fills, images) {
  const [first] = fills;
  const { imageRef } = first || {};
  const url = images[imageRef];

  if (!url) {
    return null;
  }

  return {
    backgroundUrl: url,
    backgroundRepeat: 'no-repeat',
    backgroundSize: first.scaleMode === 'FILL' ? 'cover' : 'contain',
  };
}
