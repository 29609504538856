import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { FiTrash2 } from 'react-icons/fi';

import Avatar from '@scalero-inc/forno-avatar';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Spinner from '@scalero-inc/forno-spinner';

import { useUserQuery } from 'hooks/api/users';

import FileUpload from 'components/file-upload';

import TeamDeleteModal from 'containers/team-delete-modal';

import {
  useTeamUpdateMutation,
  useTeamLogoUpdate,
  useTeamInvalidateQuery,
} from 'hooks/api/teams';
import useDebounce from 'hooks/utils/use-debounce';

import { canEditDeleteTeam } from 'utils/permissions';

import styles from './style.module.css';

function DashboardTeamInfo({ team }) {
  const [teamName, setTeamName] = useState(team.name);
  const [isOpen, setIsOpen] = useState();
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { data: user } = useUserQuery();

  const blocked = !canEditDeleteTeam({ user, teamId: team.id });

  const invalidate = useTeamInvalidateQuery({ teamId: team.id });

  const { mutate, isLoading } = useTeamUpdateMutation({
    config: {
      onSuccess: () => {
        toast.success('Team name updated successfully');
        close();
        invalidate();
      },
      onError: () => {
        toast.error(
          `Error updating team name of "${team.name}", try again later`
        );
      },
    },
    teamId: team.id,
  });

  const { mutate: logoMutate } = useTeamLogoUpdate({
    config: {
      onSuccess: () => {
        invalidate();
        toast.success('Team logo updated successfully');
      },
      onError: () => {
        toast.error(`Error updating team's logo, try again later`);
      },
    },
    teamId: team.id,
  });

  const updateState = (e) => {
    mutate({ name: e.target.value });
  };

  const debouncedOnChange = useDebounce(updateState, 500);

  return (
    <div className={styles['dashboard-team-information']}>
      <h2>Team Information</h2>
      <section className={styles['dashboard-team-details']}>
        <div className={styles['team-name-container']}>
          <Input
            placeholder="Team Name"
            className={styles['input-field']}
            size="s"
            label="Team Name"
            value={teamName}
            disabled={blocked}
            onChange={(e) => {
              setTeamName(e.target.value);
              debouncedOnChange(e);
            }}
          />
          {isLoading ? <Spinner /> : null}
        </div>
        <div className={styles['field-wrapper']}>
          <label htmlFor="logo" className={styles.label}>
            Team Icon File
          </label>
          <div className={styles['avatar-container']}>
            <Avatar
              className={styles['forno-avatar-wrapper']}
              size="4"
              firstName={team.name}
              lastName=" "
              src={team.logo}
            />
            {blocked ? null : (
              <div className={styles['file-upload-buttons']}>
                <FileUpload
                  id="logo"
                  onFile={logoMutate}
                  hierarchy="tertiary"
                  size="s"
                  withIcon={false}
                >
                  Upload new image
                </FileUpload>
                <p>.png and .jpg files accepted</p>
              </div>
            )}
          </div>
        </div>
      </section>
      <hr className={styles.divider} />
      <section className={styles['dashboard-team-delete']}>
        <TeamDeleteModal
          isOpen={isOpen}
          theme="light"
          team={team}
          close={close}
        />
        <Button
          icon={FiTrash2}
          status="danger"
          onClick={open}
          disabled={blocked}
        >
          Delete Team
        </Button>
      </section>
    </div>
  );
}

DashboardTeamInfo.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    logo: PropTypes.string,
    permission: PropTypes.string,
  }),
};

DashboardTeamInfo.defaultProps = {
  team: {
    id: 0,
    name: 'name',
    logo: 'logo',
    permission: 'R',
  },
};

export default DashboardTeamInfo;
