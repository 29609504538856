import React from 'react';
import propTypes from 'prop-types';
import { useEditor } from 'pages/editor/editor-context';
import TagEditorPanel from 'containers/tag-editor-panel';

import styles from './styles.module.css';

export default function EditorPanelRight({ feature }) {
  const { tagSelected } = useEditor();

  return (
    <section data-theme="dark" className={styles['editor-panel-container']}>
      <div className={styles['editor-panel-content']}>
        <TagEditorPanel tagId={tagSelected.id} feature={feature} />
      </div>
    </section>
  );
}

EditorPanelRight.propTypes = {
  feature: propTypes.string.isRequired,
};

EditorPanelRight.defaultProps = {};
