import Tabs from '@scalero-inc/forno-tab';
import { useParams, useNavigate } from 'react-router-dom';
import PublicPage from 'containers/public-page';
import PrivacyPolicyText from './privacy-policy-text';
import ServicePolicyText from './service-policy-text';

import { contents } from './data';
import TableOfContents from './table-of-contents';
import styles from './style.module.css';

const POLICIES = {
  SERVICES: 'services',
  PRIVACY: 'privacy',
};

function PrivacyPolicy() {
  const { policy } = useParams();
  const navigate = useNavigate();
  const defaultPolicy = policy || POLICIES.PRIVACY;
  const onChangePolicy = (policyKey) => {
    navigate(`/policies/${policyKey}`);
  };

  return (
    <PublicPage displayFooter={false} withMenu={false}>
      <div className={styles['privacy-policy']}>
        <div className={styles['privacy-policy-title']}>
          <h1>Cannoli Policies</h1>
        </div>

        <div className={styles['privacy-policy-container']}>
          <Tabs
            id="menu-tabs"
            defaultSelectedKey={defaultPolicy}
            onSelectionChange={(key) => onChangePolicy(key)}
          >
            <Tabs.Item key={POLICIES.PRIVACY} title="Privacy Policy">
              <div className={styles['privacy-policy-content']}>
                <TableOfContents content={contents.privacyPolicy} />
                <PrivacyPolicyText />
              </div>
            </Tabs.Item>
            <Tabs.Item key={POLICIES.SERVICES} title="Services Policies">
              <div className={styles['privacy-policy-content']}>
                <TableOfContents content={contents.servicesPolicy} />
                <ServicePolicyText />
              </div>
            </Tabs.Item>
          </Tabs>
        </div>
      </div>
    </PublicPage>
  );
}

export default PrivacyPolicy;
