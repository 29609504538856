import PropTypes from 'prop-types';

import BillingInformationSection from './billing-information-section';
import BillingHistory from './billing-history';

function BillingDetail({ information, subscriptionId, status, isAdmin }) {
  if (!isAdmin)
    return (
      <p>
        You don&apos;t have the necessary permissions to see the billing
        information. Please contact your team admin to get access.
      </p>
    );

  return (
    <>
      <BillingInformationSection
        status={status}
        interval={information.billing_interval}
        paymentMethod={information.payment_method_brand}
        paymentMethodDigits={information.payment_method_last4}
        email={information.billing_email}
        subscriptionId={subscriptionId}
      />
      <BillingHistory
        invoices={information.invoice_list}
        subscriptionId={subscriptionId}
      />
    </>
  );
}

BillingDetail.propTypes = {
  information: PropTypes.shape({
    billing_interval: PropTypes.string,
    payment_method_brand: PropTypes.string,
    payment_method_last4: PropTypes.string,
    billing_email: PropTypes.string,
    invoice_list: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  subscriptionId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  status: PropTypes.shape({}).isRequired,
};

export default BillingDetail;
