import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.module.css';

function HighlightBody({ parsedHtml }) {
  const wrapMjBody = (element) => {
    if (!React.isValidElement(element)) {
      return element;
    }

    const isBody =
      element.props &&
      element.props['data-id'] &&
      element.props['data-id'].includes('mj-body');
    if (isBody) {
      return (
        <div className={styles['highlight-body']}>
          <span className={styles['highlight-body-tag']}>mj-body</span>
          <div>{element}</div>
        </div>
      );
    }

    // Keep the element without changes
    return React.cloneElement(
      element,
      {},
      React.Children.map(element.props.children, (childElement) =>
        wrapMjBody(childElement)
      )
    );
  };

  const wrappedHtml = React.Children.map(parsedHtml, (element) =>
    wrapMjBody(element)
  );

  return wrappedHtml;
}

HighlightBody.propTypes = {
  parsedHtml: PropTypes.shape({}),
};

HighlightBody.defaultProps = {
  parsedHtml: null,
};

export default HighlightBody;
