import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';
import { CgTrash } from 'react-icons/cg';
import {
  useTemplateDeleteMutation,
  useTemplatesInvalidateQuery,
} from 'hooks/api/templates';

function TemplateDeleteModal({
  id,
  name,
  teamId,
  projectId,
  numTemplates,
  page,
  setPage,
  isOpen,
  onDismiss,
}) {
  const invalidateTemplates = useTemplatesInvalidateQuery();

  const config = {
    onSuccess: () => {
      onDismiss();
      // In case the template is the last of the page, go back one page
      if (page !== 1 && numTemplates === 1) {
        setPage(page - 1);
      } else {
        invalidateTemplates();
      }
      toast.success('Template deleted successfully');
    },
    onError: () => {
      toast.error('Error deleting template');
    },
  };

  const deleteMutation = useTemplateDeleteMutation({
    teamId,
    projectId,
    config,
  });

  const deleteTemplate = () => {
    deleteMutation.mutate(id);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      status="danger"
      aria-label="delete template warning"
      theme="light"
      dangerouslyBypassFocusLock
    >
      <Modal.Header
        title={`Delete ${name}`}
        onDismiss={onDismiss}
        icon={CgTrash}
      />
      <p>
        Are you sure you want to delete the template <strong>{name}</strong>?
      </p>
      <p>
        All files created and associated to this template will be deleted, this
        action <strong>cannot</strong> be undone.
      </p>
      <Modal.Footer>
        <Button theme="light" hierarchy="tertiary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          status="danger"
          loading={deleteMutation.isLoading}
          disabled={deleteMutation.isLoading}
          onClick={() => deleteTemplate()}
        >
          <CgTrash />
          <span>Delete</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

TemplateDeleteModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  numTemplates: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

TemplateDeleteModal.defaultProps = {
  teamId: null,
  projectId: null,
  isOpen: false,
};

export default TemplateDeleteModal;
