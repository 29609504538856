import { getRightSpacingBySibling, getWidth } from '../bounding-box';
import { getName, getSource } from '../image';
import messages from '../messages';

function MjSocialElement({
  attributes,
  parent,
  fills,
  images,
  nodeImages,
  children,
  ...rest
}) {
  const name = getName(rest, 'ICON');
  const image = getSource(fills, images, nodeImages, rest);
  const iconSize = { iconSize: `${getWidth(rest)}px` };
  const spacingBetweenIcons = getRightSpacingBySibling(parent, rest.id);
  const padding =
    spacingBetweenIcons > 0
      ? { padding: `0px ${spacingBetweenIcons}px 0px 0px` }
      : null;
  const errorList = [messages.warning(messages.MSG.URL_LINK, rest)];

  return [
    {
      tagName: 'mj-social-element',
      errors: errorList,
      children: [],
      attributes: {
        ...attributes,
        ...name,
        href: '#',
        ...(image && image),
        ...iconSize,
        ...(padding && padding),
      },
    },
  ];
}
export default MjSocialElement;
