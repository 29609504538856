import { getWidth } from '../bounding-box';
import { getFillColor } from '../color';
import messages from '../messages';

function getErrors(width, background, node) {
  const errors = [];

  if (width < 550 || width > 650) {
    errors.push(messages.warning(messages.MSG.EMAIL_WIDTH, node));
  }

  if (!background) {
    errors.push(messages.warning(messages.MSG.BACKGROUND_COLOR, node));
  }

  return errors;
}

function MjBody({ attributes, children, fills, ...rest }) {
  const background = getFillColor({ fills });
  const width = getWidth(rest);
  const errorList = getErrors(background, width, rest);

  return [
    {
      tagName: 'mj-body',
      errors: errorList,
      children,
      attributes: {
        width: `${width}px`,
        ...attributes,
        ...(background && background),
      },
    },
  ];
}

export default MjBody;
