import { FiBook, FiFigma } from 'react-icons/fi';

import emailKitThumbnail from 'images/email-kit-thumbnail.png';
import cannoliBitesThumbnail from 'images/cannoli-bites-thumbnail.png';

import CompactCard from 'components/compact-card';

import styles from './style.module.css';

function Resources() {
  return (
    <div className={styles['resources-page-content']}>
      <p>New to Cannoli? Try our foundational resources</p>
      <div className={styles.grid}>
        <CompactCard
          title="Figma Plugin"
          description="Skip the documentation and learn on the go. Just install it in your Figma workspace and start creating Cannoli-ready designs right away."
          icon={FiFigma}
          href="https://www.figma.com/community/plugin/1053770884164943496/Cannoli---Figma-to-email-code%2C-including-HTML-and-MJML"
        />
        <CompactCard
          title="Starting File"
          description="Learn Cannoli basics with this figma file."
          href="https://www.figma.com/community/file/1042861645873665842"
          image={emailKitThumbnail}
        />
        <CompactCard
          title="Video Tutorials"
          description="Short, to the point videos made to kickstart your email creation journey."
          image={cannoliBitesThumbnail}
          href="https://www.youtube.com/channel/UCnSTDEAE6MRSPzSJ-y85Pyg"
        />
        <CompactCard
          title="Documentation"
          description="In-depth explanation of all of Cannoli’s features to help you get the most out of our app."
          icon={FiBook}
          href="https://scalero.notion.site/Cannoli-Documentation-f80cb2baac8a41eea40949d549465fe4"
        />
      </div>
    </div>
  );
}

export default Resources;
