import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';

import { AuthProvider } from 'context/auth';
import { FetchProvider } from 'context/fetch';

import NotFound from 'pages/not-found';

import Spinner from '@scalero-inc/forno-spinner';

import SEO from 'components/seo';
import HeapTracker from 'components/heap-tracker';
import RB2B from 'components/rb-2-b';

import Auth from './auth';

const { REACT_APP_VERSION, REACT_APP_API_URL } = process.env;
const { REACT_APP_ENV } = process.env;
const { REACT_APP_GIT_PR_NUMBER, REACT_APP_GIT_SHA } = process.env;

console.info('Cannoli env', REACT_APP_ENV);
console.info('Cannoli app version: ', REACT_APP_VERSION);
console.info('Cannoli API endpoint: ', REACT_APP_API_URL);
if (REACT_APP_GIT_SHA) {
  console.info('Cannoli git sha: ', REACT_APP_GIT_SHA);
  console.info('Cannoli git PR number: ', REACT_APP_GIT_PR_NUMBER);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { heap } = window;

  return (
    <Suspense fallback={<Spinner />}>
      <HelmetProvider>
        <Router>
          <ErrorBoundary FallbackComponent={NotFound}>
            <AuthProvider>
              <FetchProvider>
                <QueryClientProvider client={queryClient}>
                  <SEO />
                  <RB2B />
                  <HeapTracker heap={heap} />
                  <Auth />
                  <ToastContainer position="top-right" />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </FetchProvider>
            </AuthProvider>
          </ErrorBoundary>
        </Router>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
