import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@scalero-inc/forno-card';
import Time from 'components/time';
import { FiBox } from 'react-icons/fi';
import CardPreviewIframe from 'components/card-preview-iframe';
import HtmlToReact from 'components/html-to-react';
import { generateHtml } from 'utils';
import ComponentCardMenu from './component-card-menu';

import styles from './styles.module.css';

function ComponentCard({
  id,
  teamId,
  file,
  name,
  updated_at: updatedAt,
  virtual_mj_head: head,
  content,
  numComponents,
  page,
  setPage,
  disabled,
  isEditable,
}) {
  const team = teamId || 'personal';
  const url = `/team/${team}/block/${id}/`;
  const customClassName = classnames(
    'forno-card-wrapper',
    styles['block-card']
  );

  return (
    <Card
      className={customClassName}
      url={url}
      actions={
        <ComponentCardMenu
          id={id}
          name={name}
          content={content}
          file={file}
          teamId={teamId}
          isEditable={isEditable}
          numComponents={numComponents}
          page={page}
          setPage={setPage}
        />
      }
      placeholderIcon={<FiBox />}
      data-disabled={disabled}
    >
      <div>
        <CardPreviewIframe>
          <HtmlToReact html={generateHtml(content.blockArray, head)} />
        </CardPreviewIframe>
      </div>
      <h3>{name}</h3>
      <p>
        Last updated: <Time>{updatedAt}</Time>
      </p>
    </Card>
  );
}

ComponentCard.propTypes = {
  id: PropTypes.number.isRequired,
  teamId: PropTypes.string,
  file: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  virtual_mj_head: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  content: PropTypes.shape({
    blockArray: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  numComponents: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
};

ComponentCard.defaultProps = {
  teamId: null,
  updated_at: new Date().toISOString(),
  disabled: null,
  isEditable: false,
};

export default ComponentCard;
