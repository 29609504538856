import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useField } from 'formik';

import { useFileQuery } from 'hooks/api/figma/file';

import Spinner from '@scalero-inc/forno-spinner';
import Dropdown from '@scalero-inc/forno-dropdown';

import { getPagesFromFile } from './utils';

import styles from './style.module.css';

function PageSelectInput(props) {
  const { className, fileId, ...rest } = props;
  const [field, meta, helpers] = useField(rest);
  const { data, isLoading, error } = useFileQuery({
    file: fileId,
  });

  const customClassName = classnames(
    'page-select-input',
    styles['page-select-input'],
    className
  );

  if (error) return null;

  const pages = getPagesFromFile(data).map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  return (
    <div className={customClassName}>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
      <label htmlFor={rest.name}>Page</label>
      {isLoading ? (
        <Spinner />
      ) : (
        <Dropdown
          {...field}
          id={rest.name}
          theme="light"
          size="s"
          className={styles['page-select-input-dropdown']}
          options={pages}
          onChange={(e) => {
            helpers.setValue(e.value);
          }}
        />
      )}
    </div>
  );
}

PageSelectInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
};

PageSelectInput.defaultProps = {
  className: null,
  initialValue: null,
  onChange: () => {},
};

export default PageSelectInput;
