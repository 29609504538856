import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { useAuth } from 'context/auth';

import Public from './public';
import Private from './private';

const { REACT_APP_ENV } = process.env;

function Auth() {
  const { user } = useAuth();
  useEffect(() => {
    if (REACT_APP_ENV === 'prod' || REACT_APP_ENV === 'dev') {
      if (user) {
        Sentry.setUser({
          username: user?.user.username,
          email: user?.user.email,
        });
      } else {
        Sentry.setUser(null);
      }
    }
  }, [user]);

  if (user) return <Private />;
  return <Public />;
}

export default Auth;
