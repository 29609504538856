/* eslint-disable no-param-reassign */
export const replaceFontInChildren = (children, newFont) => {
  Array.from(children).forEach((child) => {
    if (child.nodeName.toLowerCase() !== 'br') {
      child.style.fontFamily = newFont;
    }

    if (child.children) {
      replaceFontInChildren(child.children, newFont);
    }
  });
};

export const replaceRGBColors = (content) => {
  const regexRgb = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/gi;
  const newContent = content.replace(regexRgb, (match, r, g, b) => {
    const hexValue =
      parseInt(r, 10).toString(16).padStart(2, '0') +
      parseInt(g, 10).toString(16).padStart(2, '0') +
      parseInt(b, 10).toString(16).padStart(2, '0');

    return `#${hexValue}`;
  });

  return newContent;
};

export const replaceHtmlEntities = (content) => {
  const newContent = content.replaceAll('&quot;', `'`);

  return newContent;
};
