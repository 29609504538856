import PropTypes from 'prop-types';

import NavItem from './nav-item';

import styles from './style.module.css';

function Navbar({ items }) {
  return (
    <ul className={styles['navbar-items']}>
      {items.map((item) => (
        <NavItem key={item.label} label={item.label} linkTo={item.linkTo} />
      ))}
    </ul>
  );
}

Navbar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Navbar;
