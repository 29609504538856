import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';

import FileEditorDebug from 'containers/file-editor-debug';
import { PageOnboarding } from 'components/layout';

import styles from './style.module.css';

const initialValues = {
  url: 'https://www.figma.com/file/NSlWNBymdBDDkVLOTqMZdp/Cannoli-Community-File?node-id=189%3A2319',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getFileInfo(value) {
  const url = new URL(value);
  const params = new URLSearchParams(url.search);
  const nodeId = params.get('node-id');
  const fileId = url.pathname.split('/')[2];
  return { id: fileId, node: nodeId };
}

function ParseTest() {
  const navigate = useNavigate();
  const query = useQuery();
  const file = query.get('file');

  if (file) {
    return <FileEditorDebug {...getFileInfo(file)} />;
  }

  const onSubmit = (values) => {
    navigate(`/parse-test?file=${values.url}`);
  };

  return (
    <PageOnboarding className={styles['parse-test-page-form']}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <label htmlFor="url">Figma Link</label>
          <ErrorMessage name="url" component="div" />
          <Field id="url" name="url" type="url">
            {({ field }) => (
              <Input
                {...field}
                theme="dark"
                className={styles.field}
                placeholder="Paste your figma link here"
              />
            )}
          </Field>
          <Button type="submit">Parse Figma File</Button>
        </Form>
      </Formik>
    </PageOnboarding>
  );
}

export default ParseTest;
