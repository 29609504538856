import Button from '@scalero-inc/forno-button';

import styles from './styles.module.css';

function SuggestFeatureSection() {
  return (
    <section className={styles['suggest-feature-section']}>
      <p className="paragraph-l">Want to suggest a feature?</p>
      <Button
        href="mailto:hello@cannoli.app"
        target="_blank"
        rel="noreferrer"
        hierarchy="secondary"
        size="l"
      >
        Get in contact
      </Button>
    </section>
  );
}

export default SuggestFeatureSection;
