import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

import { plans } from 'containers/dashboard-billing/constants';
import { PageOnboarding } from 'components/layout';
import LoadingFullPage from 'components/loading-full-page';

import { useUserQuery } from 'hooks/api/users';
import { useFreeProTrialSubscriptionCreateMutation } from 'hooks/api/subscriptions';
import { USER_SUBSCRIPTION_TIER } from 'hooks/api/users/constants';

import styles from './style.module.css';

function CreateAccount() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const onError = () => {
    toast.error(
      'There was an error creating your subscription. Please try again later.'
    );
    navigate('/dashboard/settings/billing');
  };
  const { data: user, isUserLoading } = useUserQuery({
    config: {
      onError,
    },
  });
  const { mutate: startFreeProTrialSub } =
    useFreeProTrialSubscriptionCreateMutation({
      config: {
        onSuccess: () => {
          toast.success('Your free trial has started!');
          navigate(`/dashboard/personal/files?payment=${plans.proTrial.slug}`);
        },
        onError,
      },
    });

  useEffect(() => {
    if (!isUserLoading && user) {
      const { is_elegible_for_trial: isElegibleForTrial, subscriptions } = user;
      const subscriptionTier = subscriptions?.tier;

      if (
        subscriptionTier === USER_SUBSCRIPTION_TIER.FREE &&
        type === plans.proTrial.slug &&
        isElegibleForTrial
      ) {
        startFreeProTrialSub();
      } else {
        navigate('/dashboard');
      }
    }
  }, [isUserLoading, navigate, startFreeProTrialSub, type, user]);

  return (
    <PageOnboarding className={styles['create-account-page']}>
      <LoadingFullPage>
        <h2>Creating Account</h2>
      </LoadingFullPage>
    </PageOnboarding>
  );
}

export default CreateAccount;
