import PropTypes from 'prop-types';

import { Menu, MenuButton, MenuLink, MenuList } from '@reach/menu-button';
import { FiEdit, FiSettings } from 'react-icons/fi';

import IconButton from '@scalero-inc/forno-icon-button';

import styles from './style.module.css';

export default function IntegrationMenu({ name, url, func, disabled }) {
  return (
    <div className={styles['integration-menu']}>
      <Menu>
        <MenuButton as={IconButton} disabled={disabled} hierarchy="tertiary">
          <FiSettings />
        </MenuButton>
        <MenuList className={styles['menu-popover']}>
          <MenuLink as="a" href={url} target="_blank" rel="noreferrer">
            <FiSettings />
            Go to {name} settings
          </MenuLink>
          <MenuLink as="a" onClick={func}>
            <FiEdit />
            Edit integration
          </MenuLink>
        </MenuList>
      </Menu>
    </div>
  );
}

IntegrationMenu.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  func: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

IntegrationMenu.defaultProps = {
  disabled: false,
};
