import PropTypes from 'prop-types';
import Tooltip from '@scalero-inc/forno-tooltip';

import { useFileQuery } from 'hooks/api/files';
import { useComponentQuery, useTeamComponentQuery } from 'hooks/api/components';
import Time from 'components/time';

import styles from './style.module.css';

function EditorSaveIndicator({ teamId, fileId, componentId }) {
  const isTeamComponent = teamId !== null;
  const isEditingFile = !!fileId;

  const { data: fileData, isRefetching: isFileRefetching } = useFileQuery({
    fileId,
    key: 'FILE_SAVE_INDICATOR',
    config: {
      enabled: isEditingFile,
    },
  });
  const { data: userComponentData, isRefetching: isUserComponentRefetching } =
    useComponentQuery({
      componentId,
      key: 'USER_COMPONENT_SAVE_INDICATOR',
      config: {
        enabled: !isEditingFile && !isTeamComponent,
      },
    });
  const { data: teamComponentData, isRefetching: isTeamComponentRefetching } =
    useTeamComponentQuery({
      teamId,
      componentId,
      key: 'TEAM_COMPONENT_SAVE_INDICATOR',
      config: {
        enabled: !isEditingFile && isTeamComponent,
      },
    });
  const componentData = isTeamComponent ? teamComponentData : userComponentData;
  const isComponentRefetching = isTeamComponent
    ? isTeamComponentRefetching
    : isUserComponentRefetching;

  const data = isEditingFile ? fileData : componentData;
  const isRefetching = isEditingFile ? isFileRefetching : isComponentRefetching;
  const { updated_at: updatedAt = null } = data || {};

  if (!updatedAt) return null;

  return (
    <Tooltip
      theme="dark"
      content={
        <p className="font-paragraph-s-sb">
          Last saved at <Time formatStr="P p">{updatedAt}</Time>{' '}
        </p>
      }
    >
      <div className={styles['indicator-wrapper']}>
        {isRefetching ? (
          <span className={`${styles.indicator}`} data-status="fetching" />
        ) : (
          <span className={`${styles.indicator}`} data-status="success" />
        )}
      </div>
    </Tooltip>
  );
}

EditorSaveIndicator.propTypes = {
  teamId: PropTypes.number,
  fileId: PropTypes.string,
  componentId: PropTypes.string,
};

EditorSaveIndicator.defaultProps = {
  teamId: null,
  fileId: null,
  componentId: null,
};

export default EditorSaveIndicator;
