import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState } from 'react';
import { ReactComponent as AddIcon } from 'svg/icons/add.svg';
import ErrorMessage from '../error-message';

import styles from './styles.module.css';

export const POSITION_TYPE = {
  BEFORE: 'before',
  AFTER: 'after',
};

function InsertSection({ isValid, tagId, parentTagId, positionType, action }) {
  const [isDragging, setIsDragging] = useState(false);
  const highlight = isValid
    ? styles['highlight-success']
    : styles['highlight-error'];
  const customClassName = classNames(
    styles['button-container'],
    isDragging ? highlight : ''
  );

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    if (isValid) {
      action(positionType, tagId);
    }
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  return (
    <div
      onDrop={(e) => onDrop(e)}
      onDragOver={(e) => onDragOver(e)}
      onDragLeave={(e) => onDragLeave(e)}
    >
      <div data-theme="dark" className={customClassName}>
        <AddIcon />
        <span>
          {parentTagId.includes('mj-body')
            ? 'Add a new mj-wrapper or mj-section'
            : 'Add new mj-section'}
        </span>
      </div>

      {!isValid && isDragging && (
        <ErrorMessage content="mj-wrappers inside mj-wrappers are not allowed" />
      )}
    </div>
  );
}

InsertSection.propTypes = {
  isValid: PropTypes.bool,
  tagId: PropTypes.string,
  parentTagId: PropTypes.string.isRequired,
  positionType: PropTypes.oneOf([POSITION_TYPE.BEFORE, POSITION_TYPE.AFTER]),
  action: PropTypes.func,
};

InsertSection.defaultProps = {
  isValid: false,
  tagId: null,
  positionType: POSITION_TYPE.BEFORE,
  action: () => {},
};

export default InsertSection;
