import PropTypes from 'prop-types';
import classnames from 'classnames';
import Lottie from 'react-lottie-player';

import animation from './animation.json';

import styles from './style.module.css';

const classPrefix = 'loading-full-page';

function LoadingFullPage({ className, options, children }) {
  const customClassName = classnames(
    classPrefix,
    styles[classPrefix],
    className,
    {}
  );
  return (
    <section className={customClassName}>
      <Lottie {...options} animationData={animation} loop play />
      {children}
    </section>
  );
}

LoadingFullPage.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({}),
  children: PropTypes.node,
};

LoadingFullPage.defaultProps = {
  className: null,
  options: null,
  children: null,
};

export default LoadingFullPage;
