import { FiFigma } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';

import HeaderPublic from 'components/header-public';

import { useAuth } from 'context/auth';

import styles from './style.module.css';

export default function Login() {
  const { loginWithFigma } = useAuth();

  return (
    <>
      <HeaderPublic withMenu={false} />
      <main className={styles['login-page']}>
        <div className={styles['login-page-content-container']}>
          <h1>Responsive emails from Figma files made easy</h1>
          <Button icon={FiFigma} onClick={loginWithFigma} size="m">
            Log in with Figma
          </Button>
          <p className={styles['login-disclaimer']}>
            By logging in you agree to our{' '}
            <a href="/policies/privacy">Privacy Policy</a>
          </p>
        </div>
      </main>
    </>
  );
}
