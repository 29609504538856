import React from 'react';
import { useParams } from 'react-router-dom';

import FallbackStacks from 'pages/dashboard/dashboard-resources/fallback-stacks';

import styles from './styles.module.css';

function DashboardFallbackStacks() {
  const { teamId } = useParams();
  const teamIdentifier = teamId ? Number(teamId) : null;

  return (
    <div className={styles['dashboard-resources']}>
      <FallbackStacks teamId={teamIdentifier} />
    </div>
  );
}

export default DashboardFallbackStacks;
