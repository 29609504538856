import { FiTrash2 } from 'react-icons/fi';
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Spinner from '@scalero-inc/forno-spinner';

import ProjectDeleteModal from 'containers/project-delete-modal';

import {
  useProjectInvalidateQuery,
  useProjectUpdateMutation,
  useTeamProjectsInvalidateQuery,
} from 'hooks/api/projects';
import { useUserQuery } from 'hooks/api/users';
import { useTeamQuery } from 'hooks/api/teams';
import useDebounce from 'hooks/utils/use-debounce';

import { canCreateEditDeleteProject } from 'utils/permissions';

import styles from './style.module.css';

function DashboardProjectInfo({ project, teamId }) {
  const [projectName, setProjectName] = useState(project.name);
  const { isLoading: isTeamLoading, data: team } = useTeamQuery({ teamId });
  const invalidateProject = useProjectInvalidateQuery();
  const invalidateProjects = useTeamProjectsInvalidateQuery();

  const [isOpen, setIsOpen] = useState();
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { data: user } = useUserQuery();

  const { mutate, isLoading } = useProjectUpdateMutation({
    team: teamId,
    project: project.id,
    config: {
      onSuccess: () => {
        toast.success('Updated project successfully');
        invalidateProject();
        invalidateProjects();
      },
      onError: () => {
        toast.error('Failed to update project');
      },
    },
  });

  const updateState = (e) => {
    mutate({ name: e.target.value });
  };
  const debouncedOnChange = useDebounce(updateState, 500);

  const blocked = !canCreateEditDeleteProject({ user, teamId });

  if (isTeamLoading) return <Spinner />;

  return (
    <div>
      <div className={styles['dashboard-project-information']}>
        <h2>Project Information</h2>
        <section className={styles['dashboard-project-details']}>
          <div className={styles['project-team-dropdown-container']}>
            <span>Project Team</span>
            <span>{team?.name}</span>
          </div>
          <div className={styles['project-name-container']}>
            <Input
              id="name"
              size="s"
              label="Project Name"
              placeholder="Project Name"
              theme="light"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                debouncedOnChange(e);
              }}
              disabled={blocked}
            />
            {isLoading ? <Spinner /> : null}
          </div>
        </section>
        <hr className={styles.divider} />
        <section className={styles['dashboard-project-delete']}>
          <ProjectDeleteModal
            onDismiss={close}
            isOpen={isOpen}
            id={project.id}
            name={project.name}
            team={teamId}
          />
          <Button
            icon={FiTrash2}
            status="danger"
            onClick={open}
            disabled={blocked}
          >
            Delete Project
          </Button>
        </section>
      </div>
    </div>
  );
}

DashboardProjectInfo.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  teamId: PropTypes.number.isRequired,
};

export default DashboardProjectInfo;
