import { useEditor } from 'pages/editor/editor-context';

function FontFamilyDataProvider({ value, mjmlNodeRef, children }) {
  const { missingFonts } = useEditor();

  const fontStack = value.split(',');
  const mainFont = fontStack[0] ?? '';
  const stack = fontStack.map((font) => font.trim());
  const fontWeight = mjmlNodeRef.attributes['font-weight'];
  const missingFont = missingFonts.find(
    (font) =>
      font.family === mainFont &&
      font.variant === fontWeight &&
      !font.uploaded_url &&
      !font.url
  );

  const defaultFont = {
    id: missingFont?.id,
    family: mainFont,
    weight: fontWeight,
    fallback: stack.slice(1, stack.length),
  };

  return children({ defaultFont, isMissingFont: !!missingFont });
}

export default FontFamilyDataProvider;
