import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { FiPlus } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';

import { addIdsToJson } from 'utils/mjml/util';
import {
  canEditOnProFeatures,
  hasAccessToProFeatures,
} from 'utils/permissions';
import { useUserQuery } from 'hooks/api/users';
import { useEditor } from 'pages/editor/editor-context';
import { useParams } from 'react-router-dom';

import StrictModeDroppable from 'components/strict-mode-droppable';
import DraggableChildrenTag from './draggable-children-tag';
import { EditorPanelLeftContext } from '../../containers/editor-panel-left/editor-panel-context';

import styles from './styles.module.css';

export default function EditorPanelLayoutContent({ setFeature }) {
  const {
    setBodyChildren,
    bodyIndex,
    bodyChildren,
    setJsonState,
    jsonState,
    setBodyChildrenWithIds,
    tagSelected,
    findParents,
    updateMjmlState,
  } = useContext(EditorPanelLeftContext);
  const [selectedId, setSelectedId] = useState(tagSelected);
  const parents = new Set();
  const { data: user } = useUserQuery();
  const { blockId } = useParams();
  const { teamId } = useEditor();
  /**
   * We remove the item from the array of children of the body tag, and then we
   * insert it back in the array at the new index, then update the mjml and the
   * state of the bodyChildren
   * @param result - The result of the drag and drop action.
   * @returns The result of the drag and drop.
   */
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newBodyChildren = [...bodyChildren];
    const newJson = JSON.parse(JSON.stringify(jsonState));

    const [reorderedItem] = newBodyChildren.splice(result.source.index, 1);

    newBodyChildren.splice(result.destination.index, 0, reorderedItem);
    newJson.children[bodyIndex].children = newBodyChildren;

    setJsonState(newJson);
    setBodyChildren(newBodyChildren);
    setBodyChildrenWithIds(addIdsToJson(newJson.children[bodyIndex]).children);

    updateMjmlState(newJson, reorderedItem.id);
    setSelectedId(reorderedItem.id);
  };

  useEffect(() => {
    setSelectedId(tagSelected.id);
  }, [tagSelected]);

  findParents(jsonState?.children[bodyIndex], selectedId, parents);

  const canEdit = canEditOnProFeatures(user, teamId);
  const isBlocked = !hasAccessToProFeatures({ user });

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <section data-theme="dark" className={styles['editor-panel-container']}>
          <StrictModeDroppable droppableId="layout-children">
            {(provided) => (
              <div
                className={styles['editor-panel-content']}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {bodyChildren?.map((section, sectionsIndex) => {
                  return (
                    <DraggableChildrenTag
                      index={sectionsIndex}
                      key={sectionsIndex}
                      section={section}
                      parents={parents}
                      selectedId={selectedId}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </section>
      </DragDropContext>
      <div className={styles['add-block-container']}>
        {!blockId && (
          <Button
            icon={FiPlus}
            size="s"
            theme="dark"
            hierarchy="secondary"
            onClick={() => setFeature('blocks')}
            disabled={!canEdit || isBlocked}
          >
            Add block
          </Button>
        )}
      </div>
    </>
  );
}

EditorPanelLayoutContent.propTypes = {
  setFeature: PropTypes.func.isRequired,
};
