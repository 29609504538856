import React from 'react';
import PropTypes from 'prop-types';
import {
  FiChevronRight,
  FiChevronUp,
  FiHelpCircle,
  FiLogOut,
  FiSettings,
} from 'react-icons/fi';
import { BsCreditCard, BsInfoSquare } from 'react-icons/bs';
import { VscExtensions, VscOrganization } from 'react-icons/vsc';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'react-headless-accordion';
import classNames from 'classnames';

import SidebarLabelItem from 'components/sidebar-label-item';

import { useAuth } from 'context/auth';
import { CURRENT_PROFILE_STORAGE_KEY } from 'utils/constants';

import styles from './styles.module.css';

function SidebarBottom({ isCollapsed }) {
  const { currentProfile, logout } = useAuth();
  const handleLogout = () => {
    logout();
    localStorage.removeItem(CURRENT_PROFILE_STORAGE_KEY);
  };
  const customClassName = classNames(styles['accordion-body'], {
    [styles.collapsed]: isCollapsed,
  });

  return (
    <div className={styles['dashboard-sidebar-bottom']}>
      <div className={styles['user-actions-container']}>
        <Accordion>
          <AccordionItem isActive={isCollapsed}>
            {({ open }) => (
              <>
                <AccordionBody className={customClassName}>
                  <SidebarLabelItem
                    isCollapsed={isCollapsed}
                    title="Info"
                    icon={<BsInfoSquare size={16} />}
                    url={
                      currentProfile?.projects
                        ? `/dashboard/teams/${currentProfile?.id}/info`
                        : '/dashboard/settings/profile'
                    }
                    tooltipConfig={
                      isCollapsed
                        ? {
                            key: 'account-info',
                            content: (
                              <p className="font-paragraph-s-sb">
                                Account info
                              </p>
                            ),
                            placement: 'right',
                            theme: 'dark',
                          }
                        : null
                    }
                  />
                  {currentProfile?.projects ? (
                    <SidebarLabelItem
                      isCollapsed={isCollapsed}
                      title="Team members"
                      icon={<VscOrganization size={16} />}
                      url={`/dashboard/teams/${currentProfile?.id}/members`}
                    />
                  ) : (
                    <SidebarLabelItem
                      isCollapsed={isCollapsed}
                      title="Integrations"
                      icon={<VscExtensions size={16} />}
                      url="/dashboard/settings/integrations"
                      tooltipConfig={
                        isCollapsed
                          ? {
                              key: 'integrations',
                              content: (
                                <p className="font-paragraph-s-sb">
                                  Account integrations
                                </p>
                              ),
                              theme: 'dark',
                              placement: 'right',
                            }
                          : null
                      }
                    />
                  )}
                  <SidebarLabelItem
                    isCollapsed={isCollapsed}
                    title="Billing"
                    icon={<BsCreditCard size={16} />}
                    url={
                      currentProfile?.projects
                        ? `/dashboard/teams/${currentProfile?.id}/billing`
                        : '/dashboard/settings/billing'
                    }
                    tooltipConfig={
                      isCollapsed
                        ? {
                            key: 'billing-settings',
                            content: (
                              <p className="font-paragraph-s-sb">
                                Billing settings
                              </p>
                            ),
                            theme: 'dark',
                            placement: 'right',
                          }
                        : null
                    }
                  />
                  <SidebarLabelItem
                    isCollapsed={isCollapsed}
                    title="Logout"
                    icon={<FiLogOut size={16} />}
                    action={handleLogout}
                    tooltipConfig={
                      isCollapsed
                        ? {
                            key: 'logout',
                            content: (
                              <p className="font-paragraph-s-sb">Logout</p>
                            ),
                            theme: 'dark',
                            placement: 'right',
                          }
                        : null
                    }
                  />
                </AccordionBody>
                <AccordionHeader className={styles['accordion-header']}>
                  <SidebarLabelItem
                    isCollapsed={isCollapsed}
                    icon={<FiSettings size={16} />}
                    title="Settings"
                    tooltipConfig={
                      isCollapsed
                        ? {
                            key: 'settings',
                            content: (
                              <p className="font-paragraph-s-sb">
                                Show/hide settings options
                              </p>
                            ),
                            placement: 'right',
                            theme: 'dark',
                          }
                        : null
                    }
                  />
                  {open && !isCollapsed && (
                    <FiChevronUp className={styles['accordion-header-svg']} />
                  )}
                  {!open && !isCollapsed && (
                    <FiChevronRight
                      className={styles['accordion-header-svg']}
                    />
                  )}
                </AccordionHeader>
              </>
            )}
          </AccordionItem>
        </Accordion>
        <div className={styles['help-center-container']}>
          <SidebarLabelItem
            isCollapsed={isCollapsed}
            icon={<FiHelpCircle size={16} />}
            title="Help Center"
            url="/dashboard/help-center"
            tooltipConfig={
              isCollapsed
                ? {
                    key: 'help-center',
                    content: <p className="font-paragraph-s-sb">Help center</p>,
                    placement: 'right',
                    theme: 'dark',
                  }
                : null
            }
          />
        </div>
      </div>
    </div>
  );
}

SidebarBottom.propTypes = {
  isCollapsed: PropTypes.bool,
  user: PropTypes.shape({}),
};

SidebarBottom.defaultProps = {
  isCollapsed: false,
  user: {},
};

export default SidebarBottom;
