import { getFillColor } from '../color';
import { getPadding } from '../bounding-box';
import { getBorder } from '../border';
import { getErrors } from './auto-layout';
import { getBackgroundImage } from '../image';

function MjWrapper({
  attributes,
  parent,
  children,
  fills,
  images,
  tagName,
  ...rest
}) {
  const background = getFillColor({ fills });
  const backgroundImage = getBackgroundImage(fills, images);
  const padding = getPadding(parent, rest, tagName);
  const border = getBorder({ ...rest });
  const cssClass = { cssClass: 'wrapper' };
  const errorList = getErrors(rest);

  return [
    {
      tagName: 'mj-wrapper',
      errors: errorList,
      children,
      attributes: {
        ...attributes,
        ...border,
        ...(padding && padding),
        ...(cssClass && cssClass),
        ...(background && background),
        ...(backgroundImage && backgroundImage),
      },
    },
  ];
}

export default MjWrapper;
