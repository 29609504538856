import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from '@scalero-inc/forno-dropdown';
import ButtonGroup from '@scalero-inc/forno-button-group';
import Spinner from '@scalero-inc/forno-spinner';

import FileNewButton from 'containers/file-new-button';
import FilesContainer from 'containers/files-container';

import { useUserQuery } from 'hooks/api/users';
import { useFilesPaginatedQuery } from 'hooks/api/files';
import { canCreatePersonalFile, canViewPersonalFiles } from 'utils/permissions';
import Pagination from 'components/pagination';

import { CURRENT_PROFILE_STORAGE_KEY } from 'utils/constants';
import { viewOptions, sortingOptions } from './constants';

import styles from './style.module.css';

function DashboardPersonalFiles() {
  const [view, setView] = useState('grid');
  const customClassname = classNames(styles['dashboard-personal-files']);
  const { isLoading: isUserLoading, data: user } = useUserQuery();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalFiles, setTotalFiles] = useState(0);
  const { setOrder, order } = useOutletContext();
  const queryOrder = order === 'newest' ? '-created_at' : 'created_at';
  const {
    isLoading: isFileLoading,
    isFetching: isFileFetching,
    data: files,
  } = useFilesPaginatedQuery({ queryOrder, queryPage: page, pageSize });

  const navigate = useNavigate();

  useEffect(() => {
    if (files) setTotalFiles(files.count);
  }, [files]);

  useEffect(() => {
    const storedTeam = localStorage.getItem(CURRENT_PROFILE_STORAGE_KEY);
    const parsedTeam = storedTeam ? JSON.parse(storedTeam) : null;
    if (storedTeam && parsedTeam?.id) {
      navigate(`/dashboard/teams/${parsedTeam.id}/projects`);
    }
  }, [navigate]);

  const onPageChange = useCallback(
    (pageNumber) => {
      setPage(pageNumber);
    },
    [setPage]
  );
  const onPageSizeChange = useCallback(
    (size) => {
      setPageSize(size);
      setPage(1);
    },
    [setPageSize, setPage]
  );

  if (isUserLoading) return <Spinner />;

  const fileCreateBlocked = !canCreatePersonalFile({ user });
  const fileViewBlocked = !canViewPersonalFiles({ user });

  const handleChange = (e) => {
    const newView = e[0];
    setView(newView);
  };

  return (
    <div className={customClassname}>
      <div className={styles['dashboard-personal-files-actions']}>
        <FileNewButton disabled={fileCreateBlocked} />
        <div className={styles['dashboard-personal-files-right-actions']}>
          <div className={styles['dashboard-personal-files-action-wrapper']}>
            <span>Sort By:</span>
            <Dropdown
              size="s"
              theme="light"
              options={sortingOptions}
              defaultValue={sortingOptions.find(
                (option) => option.value === order
              )}
              className={styles['dashboard-personal-files-sort-dropdown']}
              onChange={(e) => {
                setOrder(e.value);
              }}
              value={order}
              look="text"
            />
          </div>
          <div className={styles['dashboard-personal-files-action-wrapper']}>
            <span>View: </span>
            <ButtonGroup
              type="radio"
              theme="light"
              options={viewOptions}
              onChange={handleChange}
              initialValue={[view]}
              className={styles['dashboard-personal-files-view-buttons']}
            >
              {viewOptions.map(({ value, icon: Icon }) => {
                return (
                  <ButtonGroup.Button key={value} value={value}>
                    <Icon />
                  </ButtonGroup.Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
      </div>

      <div className={styles['dashboard-personal-files-container']}>
        <FilesContainer
          view={view}
          files={files?.results || []}
          page={page}
          setPage={onPageChange}
          isBlocked={fileViewBlocked}
          isLoading={isFileLoading || isFileFetching}
        />
      </div>

      <Pagination
        total={totalFiles}
        current={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
}

export default DashboardPersonalFiles;
