import { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@scalero-inc/forno-button';
import {
  useTeamComponentsQuery,
  useUserComponentsQuery,
} from 'hooks/api/components';

import { EditorPanelLeftContext } from 'containers/editor-panel-left/editor-panel-context';

import BlockItem from 'components/block-item';
import LoadingPlaceholder from 'components/loading-placeholder';

import styles from './styles.module.css';

export default function AddBlockToSections({ setCreateIsOpen }) {
  const { teamId } = useContext(EditorPanelLeftContext);
  const teamBlocks = useTeamComponentsQuery({
    teamId,
    config: {
      enabled: teamId !== null,
      reFetchOnWindowFocus: false,
    },
  });
  const userBlocks = useUserComponentsQuery({
    config: {
      enabled: teamId === null,
      reFetchOnWindowFocus: false,
    },
  });

  const { handleAddBlock } = useContext(EditorPanelLeftContext);

  if (teamBlocks.isLoading || userBlocks.isLoading)
    return <LoadingPlaceholder size="l" theme="dark" hierarchy="secondary" />;

  return (
    <div>
      <Button
        hierarchy="secondary"
        theme="dark"
        onClick={() => setCreateIsOpen(true)}
      >
        New block
      </Button>
      <div className={styles['blocks-grid']}>
        {teamBlocks.data !== null && teamBlocks.data !== undefined
          ? teamBlocks.data.map((block, index) => (
              <BlockItem
                key={`${block.name}_${index}`}
                block={block}
                teamId={teamId}
                onClick={() => handleAddBlock(block)}
                theme="dark"
              />
            ))
          : null}
        {userBlocks.data !== null && userBlocks.data !== undefined
          ? userBlocks.data.map((block, index) => (
              <BlockItem
                key={`${block.name}_${index}`}
                block={block}
                teamId={teamId}
                onClick={() => handleAddBlock(block)}
                theme="dark"
              />
            ))
          : null}
      </div>
    </div>
  );
}

AddBlockToSections.propTypes = {
  setCreateIsOpen: PropTypes.func.isRequired,
};
