import PropTypes from 'prop-types';
import { FiSave } from 'react-icons/fi';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import classnames from 'classnames';

import Spinner from '@scalero-inc/forno-spinner';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';

import {
  useUserQuery,
  useUserEditMutation,
  useUserInvalidateQuery,
} from 'hooks/api/users';

import schema from './schema';

import styles from './style.module.css';

function UserForm({ className, onSuccess, onError }) {
  const userQuery = useUserQuery();
  const invalidate = useUserInvalidateQuery();
  const mutation = useUserEditMutation({
    config: {
      onSuccess: (data) => {
        onSuccess(data);
        invalidate();
      },
      onError: (data) => {
        onError(data);
      },
    },
  });
  const customClassName = classnames(
    'user-form',
    className,
    styles['user-form']
  );

  if (userQuery.isLoading) return <Spinner />;

  const initialValues = {
    firstName: userQuery.data.first_name || '',
    lastName: userQuery.data.last_name || '',
    email: userQuery.data.email || '',
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    mutation.mutate(
      {
        username: userQuery.data.username,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
      },
      { onSettled: () => setSubmitting(false) }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting }) => {
        return (
          <Form className={customClassName}>
            <div className={styles['user-form-wrapper']}>
              <Field id="firstName" name="firstName" placeholder="First name">
                {({ field }) => (
                  <Input
                    {...field}
                    className={styles.field}
                    theme="light"
                    label="Name"
                    size="s"
                  />
                )}
              </Field>
              <ErrorMessage name="firstName" component="div" />

              <Field id="lastName" name="lastName" placeholder="Last name">
                {({ field }) => (
                  <Input
                    {...field}
                    className={styles.field}
                    theme="light"
                    label="Last Name"
                    size="s"
                  />
                )}
              </Field>
              <ErrorMessage name="lastName" component="div" />

              <Field
                id="email"
                type="emal"
                name="email"
                placeholder="my@email.com"
              >
                {({ field }) => (
                  <Input
                    {...field}
                    className={styles.field}
                    theme="light"
                    label="Email"
                    size="s"
                  />
                )}
              </Field>
              <ErrorMessage name="email" component="div" />
            </div>

            <hr />

            <footer className={styles['user-form-footer']}>
              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                icon={FiSave}
              >
                Save
              </Button>
            </footer>
          </Form>
        );
      }}
    </Formik>
  );
}

UserForm.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

UserForm.defaultProps = {
  className: null,
  onSuccess: () => {},
  onError: () => {},
};

export default UserForm;
