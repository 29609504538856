import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { plans } from 'containers/dashboard-billing/constants';
import SubscriptionTeamCreateModal from 'containers/subscription-team-create-modal';

import PricingCardBase from '../pricing-card-base';

function PricingCardTeam({ activePlan, teamId, type, seats }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const Icon = plans.team.icon;
  let message;
  if (type === 'update') message = 'Change to team';
  if (type === 'create') message = 'Choose team';
  if (activePlan) message = 'Current Plan';

  const cta = (
    <>
      <Icon /> {message}
    </>
  );

  // Depending on where the card renders, the button actions will be different:
  // if user is looking to create a subscription from the team view, we will just need to validate with a modal
  // if they are looking from any other place, they will need to create a team from scratch
  const handleClick = () => {
    if (teamId) {
      open();
    } else {
      navigate('/new-team');
    }
  };

  return (
    <>
      {teamId ? (
        <SubscriptionTeamCreateModal
          teamId={+teamId}
          close={close}
          isOpen={isOpen}
          seats={seats}
        />
      ) : null}
      <PricingCardBase
        plan={plans.team}
        activePlan={activePlan}
        highlight="Full featured collaboration workflow"
        cta={cta}
        onClick={handleClick}
      />
    </>
  );
}

PricingCardTeam.propTypes = {
  activePlan: PropTypes.bool,
  teamId: PropTypes.number,
  type: PropTypes.string.isRequired,
  seats: PropTypes.number,
};

PricingCardTeam.defaultProps = {
  activePlan: false,
  teamId: null,
  seats: null,
};

export default PricingCardTeam;
