import PropTypes from 'prop-types';
import { Form, Formik, useFormik } from 'formik';
import { FiEdit } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Modal from '@scalero-inc/forno-modal';
import Input from '@scalero-inc/forno-input';
import Button from '@scalero-inc/forno-button';

import { useFilesByTeamProjectInvalidateQuery } from 'hooks/api/files';
import {
  useProjectUpdateMutation,
  useTeamProjectsInvalidateQuery,
} from 'hooks/api/projects';

import styles from './style.module.css';

function ProjectEditModal({ close, isOpen, id, name, team }) {
  const invalidateFilesTeamProject = useFilesByTeamProjectInvalidateQuery();
  const invalidateProjects = useTeamProjectsInvalidateQuery();
  const mutation = useProjectUpdateMutation({
    team,
    project: id,
    config: {
      onSuccess: () => {
        toast.success('Updated project successfully');
        close();
        invalidateFilesTeamProject();
        invalidateProjects();
      },
      onError: () => {
        toast.error('Failed to update project');
      },
    },
  });

  const handleUpdateProject = (values) => {
    mutation.mutate({ name: values.name, team: values.team });
  };

  const formik = useFormik({
    initialValues: { name },
    onSubmit: handleUpdateProject,
  });

  return (
    <Modal
      isOpen={isOpen}
      theme="light"
      aria-label="edit project modal"
      onDismiss={close}
    >
      <Modal.Header
        title={`Edit project "${name}"`}
        onDismiss={close}
        icon={FiEdit}
      />
      <Formik>
        <Form className={styles['form-wrapper']} onSubmit={formik.handleSubmit}>
          <Input
            id="name"
            size="s"
            label="Project Name"
            placeholder="Project Name"
            theme="light"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <Button theme="light" type="submit">
            Update Project
          </Button>
        </Form>
      </Formik>
    </Modal>
  );
}

ProjectEditModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  team: PropTypes.number.isRequired,
};

ProjectEditModal.defaultProps = {
  isOpen: false,
};

export default ProjectEditModal;
