import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Spinner from '@scalero-inc/forno-spinner';
import { formatBytes } from 'utils';

import style from './style.module.css';

function ImagePreview({ id, src }) {
  const imgRef = useRef();
  const [resolution, setResolution] = useState(null);
  const [extension, setExtension] = useState(null);

  const onLoadImage = () => {
    const { naturalWidth, naturalHeight } = imgRef.current;
    setResolution(`${naturalWidth}x${naturalHeight}px`);
    setExtension(src.substring(src.lastIndexOf('.') + 1));
  };
  const onErrorImage = () => {
    setResolution('');
    setExtension('');
  };

  const { isLoading, data, error } = useQuery(`${id}_${src}`, () => {
    return fetch(src);
  });

  const getImageSize = () => {
    if (error) {
      return 'Not available';
    }

    const { headers } = data;
    const size = headers.get('content-length');
    const imgSize = formatBytes(size);

    return imgSize;
  };

  return (
    <div className={style['image-preview-container']}>
      <div>
        <img
          ref={imgRef}
          src={src}
          alt="Preview"
          onLoad={onLoadImage}
          onError={onErrorImage}
        />
      </div>
      <div>
        {isLoading || resolution === null || extension === null ? (
          <Spinner />
        ) : (
          <div className={style['image-details-container']}>
            {resolution && extension ? (
              <>
                <div>
                  Image size:{' '}
                  <span className={style['image-size']}>{getImageSize()}</span>
                </div>
                <div>Resolution: {resolution}</div>
                <div>Image type: .{extension}</div>
              </>
            ) : (
              <div>No preview available</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

ImagePreview.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
};

ImagePreview.defaultProps = {
  src: '',
};

export default ImagePreview;
