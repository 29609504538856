import { Menu, MenuLink, MenuList, MenuButton } from '@reach/menu-button';
import PropTypes from 'prop-types';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FiCopy, FiEdit, FiLink, FiTrash } from 'react-icons/fi';
import { useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';

import IconButton from '@scalero-inc/forno-icon-button';

import FileDeleteModal from 'containers/file-delete-modal';
import FileEditModal from 'containers/file-edit-modal';

import {
  useFileDuplicateMutation,
  useFilesPaginatedInvalidateQuery,
} from 'hooks/api/files';
import { useUserQuery } from 'hooks/api/users';

import {
  canCreateEditDeleteTeamFile,
  canEditPersonalFiles,
} from 'utils/permissions';

import styles from './style.module.css';

function FileCardMenu({ id, name, numFiles, page, setPage }) {
  const { data: user } = useUserQuery();
  const [editIsOpen, setEditIsOpen] = useState();
  const [deleteIsOpen, setDeleteIsOpen] = useState();
  const openEdit = useCallback(() => setEditIsOpen(true), [setEditIsOpen]);
  const closeEdit = useCallback(() => setEditIsOpen(false), [setEditIsOpen]);
  const openDelete = useCallback(
    () => setDeleteIsOpen(true),
    [setDeleteIsOpen]
  );
  const closeDelete = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );

  const { teamId } = useParams();

  const canEditFile = teamId
    ? canCreateEditDeleteTeamFile({ user, teamId })
    : canEditPersonalFiles({ user });

  const invalidateFiles = useFilesPaginatedInvalidateQuery();

  const { mutate, isLoading } = useFileDuplicateMutation({
    config: {
      onSuccess: () => {
        invalidateFiles();
      },
    },
  });

  const duplicateFile = () => {
    mutate(id);
  };

  const copyLink = () => {
    copy(`${window.location.origin}/editor/${id}`);
  };

  return (
    <div className={styles['file-card-menu']}>
      {editIsOpen && (
        <FileEditModal
          id={id}
          name={name}
          close={closeEdit}
          isOpen={editIsOpen}
        />
      )}
      <FileDeleteModal
        id={id}
        name={name}
        numFiles={numFiles}
        page={page}
        setPage={setPage}
        onDismiss={closeDelete}
        isOpen={deleteIsOpen}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          theme="light"
          size="l"
          hierarchy="tertiary"
          className={styles['file-card-menu-button']}
        >
          <BiDotsVerticalRounded />
        </MenuButton>
        <MenuList className={styles['file-card-menu-list']}>
          {canEditFile ? (
            <MenuLink
              className={styles['file-card-menu-item']}
              onSelect={duplicateFile}
              disabled={isLoading}
            >
              <FiCopy />
              Duplicate
            </MenuLink>
          ) : null}
          <MenuLink
            className={styles['file-card-menu-item']}
            onSelect={copyLink}
          >
            <FiLink />
            Copy link
          </MenuLink>
          {canEditFile ? (
            <>
              <MenuLink
                className={styles['file-card-menu-item']}
                onSelect={openEdit}
              >
                <FiEdit />
                Edit
              </MenuLink>
              <MenuLink
                className={styles['file-card-menu-item']}
                onSelect={openDelete}
              >
                <FiTrash />
                Delete
              </MenuLink>
            </>
          ) : null}
        </MenuList>
      </Menu>
    </div>
  );
}

FileCardMenu.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  numFiles: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default FileCardMenu;
