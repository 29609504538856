import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import IconButton from '@scalero-inc/forno-icon-button';
import TemplateDeleteModal from 'containers/template-delete-modal';
import Time from 'components/time';
import BlockPreviewIframe from 'components/block-preview-iframe/block-preview-iframe';
import HtmlToReact from 'components/html-to-react';
import { getHtmlTemplate } from 'utils';

import styles from './styles.module.css';

function TemplateRow({
  id,
  name,
  template_components: templateComponents,
  teamId,
  projectId,
  updated_at: updatedAt,
  created_at: createdAt,
  numTemplates,
  page,
  setPage,
  disabled,
}) {
  const customClassName = classnames(
    'template-row',
    styles['template-row'],
    {}
  );
  const url =
    teamId && projectId
      ? `/dashboard/teams/${teamId}/projects/${projectId}/templates/${id}`
      : `/dashboard/personal/templates/${id}`;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url);
  };

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const openDeleteWindow = useCallback(
    (e) => {
      e.stopPropagation();
      setDeleteIsOpen(true);
    },
    [setDeleteIsOpen]
  );
  const closeDeleteWindow = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );
  const blocked = false;

  return (
    <tr
      className={customClassName}
      data-disabled={disabled}
      onClick={handleClick}
    >
      <td className={styles['template-preview-cell']} valign="middle">
        <div className={styles['template-preview']}>
          <BlockPreviewIframe disableLinks isStatic>
            <HtmlToReact html={getHtmlTemplate(templateComponents)} />
          </BlockPreviewIframe>
        </div>
      </td>
      <td className={styles['template-name-cell']}>{name}</td>
      <td className={styles['template-date-updated-cell']}>
        {updatedAt ? <Time>{updatedAt}</Time> : 'NA'}
      </td>
      <td className={styles['template-created-at-cell']}>
        {createdAt ? <Time>{createdAt}</Time> : 'NA'}
      </td>
      <td className={styles['template-delete-cell']}>
        <IconButton
          onClick={openDeleteWindow}
          disabled={blocked}
          status="danger"
        >
          <FiTrash2 />
        </IconButton>
        <TemplateDeleteModal
          id={id}
          name={name}
          teamId={teamId}
          projectId={projectId}
          numTemplates={numTemplates}
          page={page}
          setPage={setPage}
          onDismiss={closeDeleteWindow}
          isOpen={deleteIsOpen}
        />
      </td>
    </tr>
  );
}

TemplateRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  template_components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  updated_at: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  numTemplates: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TemplateRow.defaultProps = {
  teamId: null,
  projectId: null,
  updated_at: new Date().toISOString(),
  disabled: null,
};

export default TemplateRow;
