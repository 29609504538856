import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import classNames from 'classnames';

import IconButton from '@scalero-inc/forno-icon-button';

import styles from './style.module.css';

function FileCreationLimitAlert({ close, className }) {
  const customClassName = classNames(
    styles['file-creation-limit-alert'],
    className
  );
  return (
    <div className={customClassName}>
      <div className={styles['file-creation-limit-alert-content']}>
        You have reached your monthly file creation limit. Upgrade your plan to
        create more files.
        <Link
          to="/choose-plan"
          className="forno-button font-block-s-sb"
          data-size="s"
          data-hierarchy="tertiary"
          data-theme="light"
        >
          See plans
        </Link>
      </div>
      <IconButton onClick={close} hierarchy="tertiary">
        <FiX />
      </IconButton>
    </div>
  );
}

FileCreationLimitAlert.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FileCreationLimitAlert.defaultProps = {
  className: null,
};

export default FileCreationLimitAlert;
