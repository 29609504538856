import PropTypes from 'prop-types';
import Button from '@scalero-inc/forno-button';
import { toast } from 'react-toastify';

import {
  useCollectInvoiceMutation,
  useSubscriptionInvalidateQuery,
} from 'hooks/api/subscriptions';
import { dollarUSFormat } from 'utils/currency';

import styles from './style.module.css';

function BillingHistory({ subscriptionId, invoices }) {
  const invalidate = useSubscriptionInvalidateQuery({ subscriptionId });
  const mutation = useCollectInvoiceMutation({
    config: {
      onSuccess: () => {
        toast.success('Payment successfull!');
        invalidate();
      },
      onError: (e) => {
        const message = e.response.data?.error;
        if (message === 'Cannot charge a customer that has no active card') {
          toast.error(`Couldn't process your payment, update your payment method
          and try again`);
        } else {
          toast.error(`Something went wrong, please try again.`);
        }
      },
    },
    subscriptionId,
  });

  return (
    <section className={styles['billing-history-section']}>
      <h4>Invoice history</h4>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Invoice</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => {
            const parsedDate = new Date(invoice.created);
            const date = parsedDate.toLocaleDateString('en-US');
            const amount = dollarUSFormat.format(invoice.amount_paid / 100);

            // Invoice status options, they come from Stripe.
            // The `draft` status indicates that an invoice is mutable.
            // The `open` status indicates that the invoice has been finalized, is no longer mutable, and is ready for payment.
            // The `paid` status indicates that the invoice has been paid in full.
            // The `void` status indicates that the invoice is no longer valid.
            // The `uncollectible` status indicates that the invoice is very unlikely to be paid, and could be considered a bad debt.

            return (
              <tr key={invoice.invoice_id}>
                <td>{date}</td>
                <td className={styles['status-cell']}>{invoice.status}</td>
                <td>{amount}</td>
                <td className={styles['invoice-cell']}>
                  {invoice.status === 'paid' ? (
                    <a
                      href={invoice.hosted_invoice_url}
                      className="forno-button"
                      data-size="s"
                      data-hierarchy="tertiary"
                      data-theme="light"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      Go to invoice
                    </a>
                  ) : null}
                  {invoice.status === 'open' ? (
                    <Button
                      hierarchy="tertiary"
                      size="xs"
                      onClick={() => mutation.mutate(invoice.invoice_id)}
                    >
                      Pay invoice
                    </Button>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
}

BillingHistory.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string,
      amount_paid: PropTypes.number,
      status: PropTypes.string,
      hosted_invoice_url: PropTypes.string,
    })
  ),
};

BillingHistory.defaultProps = {
  invoices: [],
};

export default BillingHistory;
