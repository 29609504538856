import Spinner from '@scalero-inc/forno-spinner';

import IntegrationBlock from 'containers/integration-block';

import { useEspsQuery } from 'hooks/api/esps';

import { INTEGRATIONS, FIGMA_INTEGRATION } from './data';
import styles from './style.module.css';

function Integrations() {
  const { data: esps, isLoading } = useEspsQuery();

  if (isLoading) return <Spinner />;

  return (
    <div className={styles['integrations-page']}>
      <h2>Integrations</h2>
      <div className={styles['integrations-container']}>
        {INTEGRATIONS.map((item) => {
          const currentEsp =
            esps.length > 0
              ? esps.filter((esp) => esp.email_service_provider === item.esp)[0]
              : {};
          return (
            <IntegrationBlock
              key={item.esp}
              image={item.image}
              name={item.name}
              description={item.description}
              esp={item.esp}
              docsLink={item.docsLink}
              link={item.link}
              apiKey={currentEsp?.key}
              id={currentEsp?.id}
              modalImg={item.modalImg}
            />
          );
        })}
        <IntegrationBlock
          image={FIGMA_INTEGRATION.image}
          name={FIGMA_INTEGRATION.name}
          description={FIGMA_INTEGRATION.description}
          esp={FIGMA_INTEGRATION.esp}
          docsLink={FIGMA_INTEGRATION.docsLink}
          link={FIGMA_INTEGRATION.link}
          apiKey={FIGMA_INTEGRATION.key}
          id={FIGMA_INTEGRATION.id}
          modalImg={FIGMA_INTEGRATION.modalImg}
        />
      </div>
    </div>
  );
}

export default Integrations;
