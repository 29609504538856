import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ButtonGroup from '@scalero-inc/forno-button-group';
import Tooltip from '@scalero-inc/forno-tooltip';

import {
  codeEditorDevicesOptions,
  visualEditorDevicesOptions,
} from '../../editor-view-context/constants';
import {
  useEditorView,
  useEditorViewDispatch,
} from '../../editor-view-context';

import styles from './style.module.css';

function getSizeLabel(value) {
  return `${Math.floor(parseInt(value, 10))}px`;
}

function EditorHeaderDevicesButtons({ className, editorView }) {
  const customClassName = classnames(
    'editor-header-devices-buttons',
    styles['editor-header-devices-buttons'],
    className,
    {}
  );
  const { viewPreviewSize } = useEditorView();
  const { setViewColumns } = useEditorViewDispatch();

  const onViewChange = (value) => {
    setViewColumns(value[0]);
  };

  const sizeLabel = getSizeLabel(viewPreviewSize);

  const tooltipClassName = 'font-paragraph-s-sb';

  const viewDevicesOptions = useMemo(() => {
    if (editorView === 'code') {
      return codeEditorDevicesOptions;
    }
    return visualEditorDevicesOptions;
  }, [editorView]);

  return (
    <div className={customClassName}>
      <ButtonGroup
        size="s"
        type="radio"
        theme="dark"
        variant="subtle"
        onChange={onViewChange}
      >
        {viewDevicesOptions.map((item) => {
          const Icon = item.icon;
          return (
            <Tooltip
              key={item.value}
              theme="dark"
              content={<p className={tooltipClassName}>{item.tooltipText}</p>}
            >
              <ButtonGroup.Button
                value={item.value}
                className="cannoli-button-group-item"
              >
                <Icon />
              </ButtonGroup.Button>
            </Tooltip>
          );
        })}
      </ButtonGroup>
      <div className={styles['size-indicator-wrapper']}>
        <p className="font-block-s-sb">W:</p>
        <div className={styles['size-indicator']}>
          <Tooltip
            theme="dark"
            placement="bottom-end"
            content={<p className={tooltipClassName}>Current preview width</p>}
          >
            <p className="font-block-s">{sizeLabel}</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

EditorHeaderDevicesButtons.propTypes = {
  className: PropTypes.string,
  editorView: PropTypes.string.isRequired,
};

EditorHeaderDevicesButtons.defaultProps = {
  className: null,
};

export default EditorHeaderDevicesButtons;
