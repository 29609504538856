import ImageCard from 'components/image-card';

import { resourceCards } from './data';
import styles from './style.module.css';

function ResourcesSection() {
  return (
    <section id="resources" className={styles['resources-section']}>
      <div className={styles['resources-container']}>
        <h2>Resources</h2>
        <p className="paragraph-l">
          Need help getting started? We’ve got many resources to help you.
        </p>
        <div className={styles.grid}>
          {resourceCards.map((card) => {
            return (
              <ImageCard
                key={card.id}
                src={card.image.src}
                alt={card.image.alt}
                {...card}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ResourcesSection;
