import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';

import PricingCardsPrivate from 'containers/pricing-cards-private';

import { getFreeTrialEndDate, isVIP } from 'utils/permissions';

import { useUserQuery } from 'hooks/api/users';

import styles from './style.module.css';

function TeamBillingPricing({ teamId }) {
  const { data: user } = useUserQuery();
  const freeTrialEndDate = getFreeTrialEndDate({ user });
  return (
    <div className={styles['team-billing-pricing']}>
      {isVIP({ user }) ? (
        <div className={styles['team-billing-pricing-message']}>
          <div className={styles['team-billing-pricing-title']}>
            <FiAlertTriangle />
            <h2>Subscribe to Cannoli</h2>
          </div>
          <p>
            Starting <strong>{freeTrialEndDate}, 2022</strong> you will need to
            be subscribed to continue using Cannoli. Select a plan so you and
            all team members don&apos;t lose access to files and projects.
          </p>
        </div>
      ) : (
        <p>Select a plan to regain access to your files and projects</p>
      )}
      <PricingCardsPrivate type="create" teamId={teamId} />
    </div>
  );
}

TeamBillingPricing.propTypes = {
  teamId: PropTypes.number,
};

TeamBillingPricing.defaultProps = {
  teamId: null,
};

export default TeamBillingPricing;
