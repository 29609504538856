import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';

import { useUserQuery, useUserInvalidateQuery } from 'hooks/api/users';

import { dollarUSFormat } from 'utils/currency';

import {
  useSubscriptionInvalidateQuery,
  useUpdateTeamSubscriptionMutation,
} from 'hooks/api/subscriptions';
import { useTeamMembersQuery } from 'hooks/api/teams';

import styles from './style.module.css';
import SubscriptionUpdateSeatsMessages from './subscription-update-seats-messages';
import {
  TEAM_BASE_PRICE,
  TEAM_BASE_SEATS,
  TEAM_SEAT_PRICE,
  MILLISECONDS_IN_DAY,
} from './constants';

function SubscriptionUpdateSeatsModal({
  isOpen,
  close,
  subscriptionId,
  onSuccess,
  onClickCTA,
}) {
  const params = useParams();
  const { teamId } = params;
  const { data: user } = useUserQuery();

  const { data: teamMembers, membersLoading } = useTeamMembersQuery({ teamId });

  const invalidateUser = useUserInvalidateQuery();
  const invalidateSubscription = useSubscriptionInvalidateQuery({
    subscriptionId,
  });

  const { mutate, isLoading } = useUpdateTeamSubscriptionMutation({
    subscriptionId,
    config: {
      onSuccess: () => {
        invalidateUser();
        invalidateSubscription();
        close();
        toast.success('Team seats added successfully');
        onSuccess();
      },
      onError: () =>
        toast.error(
          'There was a problem updating your team plan. Please contact support.'
        ),
    },
  });

  const subscription = user.team_subscriptions.find(
    (sub) => sub.team === +teamId
  );
  const { seats: currentSeats } = subscription;
  const [newSeats, setNewSeats] = useState(currentSeats);

  if (membersLoading) return '...loading';

  const today = new Date();
  const periodStart = new Date(subscription.current_period_start);
  const periodEnd = new Date(subscription.current_period_end);

  const daysToBilling = (periodEnd - today) / MILLISECONDS_IN_DAY;
  const periodLength = (periodEnd - periodStart) / MILLISECONDS_IN_DAY;

  const currentBill =
    (currentSeats - TEAM_BASE_SEATS) * TEAM_SEAT_PRICE + TEAM_BASE_PRICE;
  const newBill =
    (+newSeats - TEAM_BASE_SEATS) * TEAM_SEAT_PRICE + TEAM_BASE_PRICE;
  const extra =
    ((+newSeats - +currentSeats) * TEAM_SEAT_PRICE * daysToBilling) /
    periodLength;

  const updateSeats = () => {
    const seats = +newSeats;
    mutate(seats);
  };

  return (
    <Modal
      status="danger"
      onDismiss={close}
      aria-label="add more seats to your team modal"
      isOpen={isOpen}
    >
      <Modal.Header
        title="Billing Update"
        onDismiss={close}
        icon={FiAlertTriangle}
      />
      <div className={styles['add-seats-container']}>
        <p>You currently have {currentSeats} seats in your team. Update to</p>
        <Input
          type="number"
          className={styles['add-seats-input']}
          value={newSeats}
          onChange={(e) => setNewSeats(e.target.value)}
          min={2}
        />
        <p>seats.</p>
      </div>
      <table>
        <tbody>
          <tr>
            <td>
              <h3>Due today:</h3>
            </td>
            <td>
              <h3>{dollarUSFormat.format(0)} USD</h3>
            </td>
          </tr>
          <tr>
            <td>Current monthly bill</td>
            <td>{dollarUSFormat.format(currentBill)} USD</td>
          </tr>
          <tr>
            <td>Updated monthly bill</td>
            <td>{dollarUSFormat.format(newBill)} USD</td>
          </tr>
        </tbody>
      </table>
      <SubscriptionUpdateSeatsMessages
        newSeats={+newSeats}
        currentSeats={currentSeats}
        teamId={+teamId}
        extra={extra}
        memberCount={teamMembers?.length}
        onClickCTA={onClickCTA}
      />
      <Modal.Footer>
        <Button onClick={close} hierarchy="tertiary">
          Cancel
        </Button>
        <Button
          disabled={
            newSeats === currentSeats ||
            (newSeats <= currentSeats && teamMembers?.length > newSeats) ||
            isLoading
          }
          loading={isLoading}
          onClick={updateSeats}
        >
          Accept billing update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SubscriptionUpdateSeatsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  subscriptionId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  onClickCTA: PropTypes.func,
};

SubscriptionUpdateSeatsModal.defaultProps = {
  onSuccess: () => {},
  onClickCTA: () => {},
};

export default SubscriptionUpdateSeatsModal;
