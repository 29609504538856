import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

function FormikEffect({ onChange }) {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return null;
}

FormikEffect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FormikEffect;
