import PropTypes from 'prop-types';
import classnames from 'classnames';

import ButtonGroup from '@scalero-inc/forno-button-group';
import Tooltip from '@scalero-inc/forno-tooltip';

import ReadOnlyHeaderDevicesButtons from './read-only-header-devices-buttons';
import ReadOnlyHeaderLayoutButtons from './read-only-header-layout-buttons';

import styles from './styles.module.css';
import {
  useReadOnlyView,
  useReadOnlyViewDispatch,
} from '../read-only-view-context';
import { viewCodeOptions } from '../read-only-view-context/constants';
import { useReadOnly } from '../read-only-context';

function ReadOnlyHeader({ className }) {
  const customClassName = classnames(
    'editor-header',
    styles['editor-header'],
    className,
    {}
  );

  const { name } = useReadOnly();

  const { viewCode } = useReadOnlyView();
  const { setViewCode } = useReadOnlyViewDispatch();

  const onCodeViewChange = (value) => {
    setViewCode(value[0]);
  };

  return (
    <header className={customClassName} data-theme="dark">
      <div className={`${styles.wrapper}`}>
        <section className={styles['right-wrapper']}>
          <div className={styles['options-wrapper']}>
            <Tooltip
              theme="dark"
              content={
                <p className="font-paragraph-s-sb">
                  Change the code you’re viewing, you can edit MJML but can only
                  view HTML.
                </p>
              }
            >
              <ButtonGroup
                size="s"
                type="radio"
                theme="dark"
                variant="subtle"
                onChange={onCodeViewChange}
                initialValue={[viewCode]}
                className="cannoli-button-group"
              >
                {viewCodeOptions.map((item) => {
                  return (
                    <ButtonGroup.Button
                      value={item.value}
                      key={item.value}
                      disabled={item.value === 'option2'}
                      className="cannoli-button-group-item"
                    >
                      {item.label}
                    </ButtonGroup.Button>
                  );
                })}
              </ButtonGroup>
            </Tooltip>
          </div>
        </section>
        <p className="font-paragraph-m-sb">{name}</p>
        <section className={styles['left-wrapper']}>
          <ReadOnlyHeaderLayoutButtons />
          <ReadOnlyHeaderDevicesButtons />
        </section>
      </div>
    </header>
  );
}

ReadOnlyHeader.propTypes = {
  className: PropTypes.string,
};

ReadOnlyHeader.defaultProps = {
  className: null,
};

export default ReadOnlyHeader;
