import { safeFonts } from './parser/dictionaries';

function isSafeFont({ fontFamily }) {
  return safeFonts.some((font) => fontFamily.toLowerCase().includes(font));
}

export function reduceFonts(acc, curr) {
  const { children = [], tagName, style } = curr;
  // Doesn't add the font if it's an mj-image
  const fonts = tagName === 'mj-image' ? [] : children.reduce(reduceFonts, []);

  // Doesn't add the font if it's a safe font
  if (style && !isSafeFont(style)) {
    const { fontFamily, fontWeight } = style;
    return [
      ...acc,
      ...fonts,
      {
        name: fontFamily,
        weight: fontWeight,
      },
    ];
  }
  return [...acc, ...fonts];
}

export function reduceFontWeights(acc, curr) {
  const { name, weight } = curr;
  if (acc[name]) {
    acc[name] = [...new Set([weight, ...acc[name]])];
  } else {
    acc[name] = [weight];
  }
  return acc;
}

export function sortFontWeights(uniqueFonts) {
  const fonts = {};
  Object.keys(uniqueFonts).forEach((key) => {
    fonts[key] = uniqueFonts[key].sort((a, b) => {
      return a - b;
    });
  });

  return fonts;
}

export function extractFontsFromNodes(nodes) {
  const fonts = nodes.reduce(reduceFonts, []);
  const unique = sortFontWeights(fonts.reduce(reduceFontWeights, {}));
  return unique;
}
