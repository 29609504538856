import { viewColumnsOptions } from 'pages/editor/editor-header/editor-header-layout-buttons';
import {
  useEditorView,
  useEditorViewDispatch,
} from 'pages/editor/editor-view-context';
import { viewWithPanel } from 'pages/editor/editor-view-context/constants';

export default function usePanel() {
  const {
    viewPanel: { name, title },
  } = useEditorView();
  const { setViewPanel, setViewColumns } = useEditorViewDispatch();
  const initialView = viewColumnsOptions[1].value;

  const open = (panelName, panelTitle) => {
    setViewPanel({ name: panelName, title: panelTitle }); // Display panel with the proper option
    setViewColumns(viewWithPanel);
  };

  const close = () => {
    setViewPanel({ name: '', title: '' }); // Hide panel
    setViewColumns(initialView);
  };

  const toggle = (panelName, panelTitle) => {
    if (name === panelName) {
      close();
    } else {
      open(panelName, panelTitle);
    }
  };

  const isOpen = name !== '';

  return { name, title, isOpen, open, close, toggle };
}
