import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const serverDateFormat = 'yyyy-MM-dd';
export const localizedTimeAndDate = 'p - PP';
export const localizedDateWithTime = 'P p';

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function Dates(date, formatStr = 'PP') {
  return format(date, formatStr, {
    locale: enUS,
  });
}
