import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFile } from 'react-icons/fi';

import Time from 'components/time';

import Card from '@scalero-inc/forno-card';
import FileCardMenu from './file-card-menu';

import styles from './style.module.css';

function FileCard({
  name,
  id,
  preview,
  updated_at: updatedAt,
  team,
  numFiles,
  page,
  setPage,
  disabled,
}) {
  const customClassName = classnames(
    'forno-card-wrapper',
    styles['file-card'],
    {}
  );
  const url = `/editor/${id}`;

  return (
    <Card
      className={customClassName}
      name={name}
      preview={preview}
      url={url}
      actions={
        <FileCardMenu
          id={id}
          name={name}
          team={team?.id}
          numFiles={numFiles}
          page={page}
          setPage={setPage}
        />
      }
      placeholderIcon={<FiFile />}
      data-disabled={disabled}
    >
      <p>
        Last updated: <Time>{updatedAt}</Time>
      </p>
    </Card>
  );
}

FileCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  preview: PropTypes.string,
  updated_at: PropTypes.string,
  team: PropTypes.shape({
    id: PropTypes.number,
  }),
  numFiles: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FileCard.defaultProps = {
  updated_at: new Date().toISOString(),
  preview: null,
  team: null,
  disabled: null,
};

export default FileCard;
