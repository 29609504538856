import PropTypes from 'prop-types';
import { useField } from 'formik';
import Dropdown from '@scalero-inc/forno-dropdown';
import { FiX } from 'react-icons/fi';

import styles from './styles.module.css';

function getOrdinal(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;
  const suffix =
    suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
  return number + suffix;
}
function FallbackDropdown({ id, index, fontOptions, isRemovable, onRemove }) {
  const [field, , helpers] = useField(`${id}.stack.${index}.family`);
  const fallbackNum = getOrdinal(index + 1);
  const fallbackLabel = `${fallbackNum} fallback`;

  return (
    <div className={styles['stack-item']}>
      <div className={styles['stack-item-header']}>
        <span>{fallbackLabel}</span>
        {isRemovable && <FiX size={18} onClick={() => onRemove()} />}
      </div>

      <Dropdown
        {...field}
        size="s"
        key={`fallback_${index}_${field.value}`}
        options={fontOptions}
        defaultValue={{
          label: field.value,
          value: field.value,
        }}
        onChange={(event) => {
          const fontName = event.value;
          helpers.setValue(fontName);
        }}
      />
    </div>
  );
}

FallbackDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fontOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
};

FallbackDropdown.defaultProps = {
  isRemovable: true,
  onRemove: () => {},
};

export default FallbackDropdown;
