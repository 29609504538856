import {
  USER_SUBSCRIPTION_STATUS,
  USER_SUBSCRIPTION_TIER,
} from 'hooks/api/users/constants';

// GENERAL PERMISSIONS
export function hasNoSubscriptions({ user }) {
  const noPersonalSubscription = !user.subscriptions;
  const noTeamSubscriptions = user?.team_subscriptions.length === 0;
  return noPersonalSubscription && noTeamSubscriptions;
}

export function isScalero({ user }) {
  return user?.email.search('@scalero.io') > 0;
}

export function isAllAccess({ user }) {
  // All-access users are those in Scalero or in our special user list
  return user?.is_vip;
}

export function isVIP({ user }) {
  return isAllAccess({ user }) && !isScalero({ user });
}

export function hasActivePersonalAccount({ user }) {
  const hasActiveProPermission =
    user.subscriptions?.tier === USER_SUBSCRIPTION_TIER.PRO &&
    user.subscriptions?.status === USER_SUBSCRIPTION_STATUS.ACTIVE;
  const hasActiveStarterPermission =
    user.subscriptions?.tier === USER_SUBSCRIPTION_TIER.FREE ||
    user.subscription == null;

  return hasActiveProPermission || hasActiveStarterPermission;
}

export function hasActiveProAccount({ user }) {
  return user?.is_pro_user;
}

export function hasActiveTeamAccount({ user }) {
  // has a team with an active subscription, no matter the team
  return user?.is_team_user;
}

export function userTeamPermissions({ user, teamId }) {
  const team = user?.teams?.find((currentTeam) => currentTeam.id === +teamId);
  const userPermission = team?.permission;
  return userPermission;
}

export function teamIsActive({ user, teamId }) {
  const teamSubscription = user?.team_subscriptions?.find(
    (currentSub) => currentSub.team === +teamId
  );
  const teamSubStatus = teamSubscription?.status;
  const isActive = teamSubStatus === USER_SUBSCRIPTION_STATUS.ACTIVE;
  return isActive;
}

export function hasTeamEditPermissions({ user, teamId }) {
  const userPermission = userTeamPermissions({ user, teamId });
  const allAccess =
    isAllAccess({ user }) && userPermission && userPermission !== 'R';
  const teamActive =
    teamIsActive({ user, teamId }) && userPermission && userPermission !== 'R';
  return allAccess || teamActive;
}

export function hasTeamAdminPermissions({ user, teamId }) {
  const isAdmin = userTeamPermissions({ user, teamId }) === 'A';
  const allAccess = isAllAccess({ user }) && isAdmin;
  const teamActive = teamIsActive({ user, teamId }) && isAdmin;
  return allAccess || teamActive;
}

// INDIVIDUAL ACTIONS
export function canCreatePersonalFile({ user }) {
  const allAccess = isAllAccess({ user });
  const canCreateMoreFiles =
    (hasActivePersonalAccount({ user }) && user.can_create_more_files) ||
    hasActiveTeamAccount({ user });

  return allAccess || canCreateMoreFiles;
}

export function canViewPersonalFiles({ user }) {
  const allAccess = isAllAccess({ user });
  const canViewPersonalFileList =
    hasActivePersonalAccount({ user }) || hasActiveTeamAccount({ user });

  return allAccess || canViewPersonalFileList;
}

export function canEditPersonalFiles({ user }) {
  const allAccess = isAllAccess({ user });
  const canEditFiles =
    hasActivePersonalAccount({ user }) || hasActiveTeamAccount({ user });

  return allAccess || canEditFiles;
}

// team files
export function canCreateEditDeleteTeamFile({ user, teamId }) {
  return hasTeamEditPermissions({ user, teamId });
}

export function canAccessTeamFiles({ user, teamId }) {
  const userPermission = userTeamPermissions({ user, teamId });
  const allAccess = isAllAccess({ user }) && userPermission;
  const canViewTeamFileList = hasActiveTeamAccount({ user }) && userPermission;

  return allAccess || canViewTeamFileList;
}

// team subscriptions
export function canCreateEditDeleteTeamSubscription({ user, teamId }) {
  return userTeamPermissions({ user, teamId }) === 'A';
}

// team projects
export function canCreateEditDeleteProject({ user, teamId }) {
  return hasTeamEditPermissions({ user, teamId });
}

// team info
export function canEditDeleteTeam({ user, teamId }) {
  return hasTeamAdminPermissions({ user, teamId });
}

// team members
export function canAddEditDeleteTeamMembers({ user, teamId }) {
  return hasTeamAdminPermissions({ user, teamId });
}

// free trial dates
export function getFreeTrialEndDate({ user }) {
  const date =
    isAllAccess({ user }) && !isScalero({ user }) ? 'November 30' : '';
  return date;
}

// templates
export const hasAccessToProFeatures = ({ user }) => {
  return (
    isAllAccess({ user }) ||
    hasActiveProAccount({ user }) ||
    hasActiveTeamAccount({ user })
  );
};

export const canEditOnProFeatures = (user, teamId) => {
  if (teamId !== null) {
    return hasTeamEditPermissions({ user, teamId });
  }

  return hasAccessToProFeatures({ user });
};
