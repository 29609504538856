export const CANNOLI_SERVICES = {
  WD: {
    id: 'WD',
    price: 75,
    name: 'Email coding',
    description:
      'All of our emails are optimized and ready to go. When making an order you will receive:',
    features: [
      'Responsive email code.',
      'HTML file.',
      'HTML and MJML code inside Cannoli.',
      {
        feature: 'Email On Acid email previews.',
        detail: 'Tests on 40+ devices and email clients',
      },
      'An optimized Figma file for further Cannoli usage.',
      {
        feature: 'Validation for links and images.',
        detail: 'Alt text and valid URLs',
      },
      'Built in AI generated font stacks.',
      'Dark mode asset optimization.',
      'Outlook 120 DPI optimization.',
    ],
    time: 'Each order is delivered within two (2) business days. ',
    cta: {
      label: 'Order now',
      url: 'checkout/',
    },
    policy: {
      label: 'Take a look at our policies',
      url: '/policies/services',
    },
  },
  WOD: {
    id: 'WOD',
    price: 150,
    name: 'Email design and coding',
    description: 'Our expert email designers will provide:',
    features: [
      '1 design concept, following all the current standards and email design best practices.',
      'Unlimited design revisions within one (1) business day.',
      'Figma file with your final design.',
    ],
    time: 'Each order is delivered within three (3) business days. ',
    cta: {
      label: 'Order now',
      url: 'checkout/',
    },
    policy: {
      label: 'Take a look at our policies',
      url: '/policies/services',
    },
  },
};
