import React, { useState, useMemo } from 'react';
import { FiFilePlus, FiFileText } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';

import LoadingFullPage from 'components/loading-full-page';
import TemplateForm from 'containers/template-form';

import { useUserQuery } from 'hooks/api/users';
import { useTemplateQuery } from 'hooks/api/templates';
import { hasAccessToProFeatures } from 'utils/permissions';

import styles from './style.module.css';

function DashboardTemplateEditor() {
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted] = useState(false);
  const [, setValues] = useState();
  const { teamId, projectId, templateId } = useParams();
  const { data: user } = useUserQuery();

  const blocked = !hasAccessToProFeatures({ user });

  const mode =
    templateId !== undefined && templateId !== null ? 'edit' : 'create';

  const { data: templateQueryData } = useTemplateQuery({
    teamId,
    projectId,
    templateId,
    config: {
      enabled: !blocked && mode === 'edit',
    },
  });

  const initialValues = useMemo(() => {
    if (mode === 'edit' && templateQueryData) {
      const { name, description } = templateQueryData;
      return { name, description };
    }
    return { name: '', description: '' };
  }, [mode, templateQueryData]);

  if (isSubmitted)
    return (
      <LoadingFullPage>
        <h1>
          {mode === 'create'
            ? 'Creating your template...'
            : 'Submitting your changes...'}
        </h1>
      </LoadingFullPage>
    );

  const onChange = (e) => {
    setValues(e);
  };

  const onSubmit = () => {
    setSubmitted(true);
  };

  const onSuccess = () => {
    if (projectId) {
      navigate(`/dashboard/teams/${teamId}/projects/${projectId}/templates/`);
      return;
    }
    navigate(`/dashboard/personal/templates/`);
  };

  return (
    <div className={styles['dashboard-template-form']}>
      <div className={styles['dashboard-template-form-header']}>
        {mode === 'create' ? <FiFilePlus /> : <FiFileText />}
        <p>{mode === 'create' ? 'New Template' : 'Edit Template'}</p>
      </div>
      <div className={styles['dashboard-template-form-content']}>
        {blocked ? (
          <p>
            Check your subscription status and permissions to access this
            feature.
          </p>
        ) : (
          <TemplateForm
            onChange={onChange}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            team={teamId}
            project={projectId}
            template={mode === 'edit' ? templateId : undefined}
            initialValues={initialValues}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardTemplateEditor;
