import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import Button from '@scalero-inc/forno-button';

import TeamSubscriptionUpdateBreakdown from 'components/team-subscription-update-breakdown';

import styles from './style.module.css';

function SubscriptionUpdateSeatsMessages({
  currentSeats,
  newSeats,
  teamId,
  extra,
  memberCount,
  onClickCTA,
}) {
  const membersUrl = `/dashboard/teams/${teamId}/members`;
  const navigate = useNavigate();
  const [showBreakdown, setShowBreakdown] = useState(false);

  const toggleShowBreakdown = () => {
    setShowBreakdown((prevState) => !prevState);
  };

  // if user is removing seats but they still have members using them, then
  // show a CTA to go remove team members first
  if (newSeats < currentSeats && memberCount > newSeats) {
    return (
      <div>
        <p>To remove seats, please remove members from your team first</p>
        <Button
          onClick={() => {
            onClickCTA();
            navigate(membersUrl);
          }}
        >
          Go to team members
        </Button>
      </div>
    );
  }

  // if user is adding more seats, then
  // show them a legend with the extra amount that will be charged
  if (newSeats > currentSeats) {
    return (
      <div className={styles['add-seats-legend']}>
        Note: when this period ends you will have to cover the difference of
        seats added before it ended. This amount is subject to change, depending
        on the moment of your update.{' '}
        <button
          onClick={toggleShowBreakdown}
          type="button"
          className={styles['button-link']}
        >
          {showBreakdown ? 'Hide.' : 'See approximate.'}
        </button>
        {showBreakdown ? (
          <TeamSubscriptionUpdateBreakdown extra={extra} />
        ) : null}
      </div>
    );
  }
}

SubscriptionUpdateSeatsMessages.propTypes = {
  newSeats: PropTypes.number.isRequired,
  currentSeats: PropTypes.number.isRequired,
  teamId: PropTypes.number.isRequired,
  extra: PropTypes.number.isRequired,
  memberCount: PropTypes.number,
  onClickCTA: PropTypes.func,
};

SubscriptionUpdateSeatsMessages.defaultProps = {
  memberCount: null,
  onClickCTA: () => {},
};

export default SubscriptionUpdateSeatsMessages;
