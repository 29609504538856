import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ComparisonSlider as Base } from 'react-comparison-slider';

import CompareSliderHandle from './compare-slider-handle';
import CompareSliderDivider from './compare-slider-divider';

import styles from './style.module.css';

function CompareSlider({ className, ...rest }) {
  const customClassName = classnames(
    'compare-slider',
    styles['compare-slider'],
    className,
    {}
  );

  return (
    <Base
      className={customClassName}
      defaultValue={100}
      orientation="horizontal"
      handleBefore={<CompareSliderDivider />}
      handleAfter={<CompareSliderDivider />}
      handle={CompareSliderHandle}
      {...rest}
    />
  );
}

CompareSlider.propTypes = {
  className: PropTypes.string,
};

CompareSlider.defaultProps = {
  className: null,
};

export default CompareSlider;
