import PropTypes from 'prop-types';

import Avatar from '@scalero-inc/forno-avatar';

import styles from './style.module.css';

function UserAvatar({ user, withName, size, ...rest }) {
  const { first_name: firstName, last_name: lastName, avatar } = user;
  return (
    <div className={styles['user-avatar-container']}>
      <Avatar
        className={styles['forno-avatar-wrapper']}
        firstName={firstName}
        lastName={lastName}
        src={avatar}
        size={size}
        {...rest}
      />
      {withName ? (
        <p className={styles['user-name']}>
          {firstName} {lastName}
        </p>
      ) : null}
    </div>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  withName: PropTypes.bool,
  size: PropTypes.string,
};

UserAvatar.defaultProps = {
  withName: false,
  user: {
    first_name: '',
    last_name: '',
    avatar: '',
  },
  size: 'm',
};

export default UserAvatar;
