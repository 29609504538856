import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import CardGrid from 'containers/card-grid';
import ProjectNewButton from 'containers/project-new-button';

import { canCreateEditDeleteProject } from 'utils/permissions';
import { useUserQuery } from 'hooks/api/users';

import styles from './style.module.css';

function DashboardTeamProjects({ projects, subscriptionStatus }) {
  const { teamId } = useParams();
  const { data: user } = useUserQuery();

  const projectActionsBlocked = !canCreateEditDeleteProject({ user, teamId });

  return (
    <div className={styles['dashboard-team-projects']}>
      <ProjectNewButton
        teamId={+teamId}
        subscriptionStatus={subscriptionStatus}
        blocked={projectActionsBlocked}
      />
      {projects.length > 0 ? (
        <CardGrid
          content={projects}
          type="projects"
          blocked={projectActionsBlocked}
        />
      ) : (
        <p>No projects to display</p>
      )}
    </div>
  );
}

DashboardTeamProjects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  subscriptionStatus: PropTypes.string,
};

DashboardTeamProjects.defaultProps = {
  projects: [],
  subscriptionStatus: null,
};

export default DashboardTeamProjects;
