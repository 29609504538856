import React from 'react';
import PropTypes from 'prop-types';
import { FiCheck, FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import HtmlToReact from 'components/html-to-react';
import BlockPreviewIframe from 'components/block-preview-iframe/block-preview-iframe';

import { generateHtml } from 'utils';

import styles from './styles.module.css';

function BlockItem({ block, teamId, onClick, type, theme }) {
  const navigate = useNavigate();

  const editBlock = (blockId) => {
    const team = teamId || 'personal';
    navigate(`/team/${team}/block/${blockId}`);
  };

  const [selected, setSelected] = React.useState(false);

  return (
    <div className={styles['block-item-container']} data-theme={theme}>
      <div
        className={styles['block-item']}
        data-selected={selected}
        onClick={
          type === 'select'
            ? () => {
                setSelected(!selected);
                onClick();
              }
            : () => onClick()
        }
        role="button"
        tabIndex={0}
      >
        <div className={styles['block-item-iframe']}>
          <BlockPreviewIframe disableLinks isStatic>
            <HtmlToReact
              html={generateHtml(
                block.content.blockArray,
                block.virtual_mj_head
              )}
            />
          </BlockPreviewIframe>
        </div>
        <div className={styles['block-item-detail']}>
          <p className={styles['block-name-label']}>{block.name}</p>
          {type === 'editable' && (
            <FiEdit size={16} onClick={() => editBlock(block.id)} />
          )}
          {type === 'select' && (
            <div className={styles['block-checkbox-container']}>
              <input
                type="checkbox"
                value={selected}
                className={styles['block-checkbox']}
              />
              <FiCheck className={styles['block-check']} size={10} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

BlockItem.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    virtual_mj_head: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    content: PropTypes.shape({
      blockArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
  teamId: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  theme: PropTypes.string,
};

BlockItem.defaultProps = {
  teamId: null,
  onClick: () => {},
  type: 'editable',
  theme: 'light',
};

export default BlockItem;
