import React from 'react';
import styles from './styles.module.css';

function HelpInfo() {
  return (
    <div className={styles['help-info-container']}>
      <p className="font-font-paragraph-s-sb">
        Why do you need blocks to build a template?
      </p>
      <p className="font-paragraph-s">
        Cannoli builds templates using blocks previously saved in your library.
        To create a new block, sync a Figma file with Cannoli and save a layer
        or group of layers as a new block.
      </p>
    </div>
  );
}

export default HelpInfo;
