import classNames from 'classnames';
import { FiPlusCircle } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';

import HeaderPrivate from 'containers/header-private';

import { useTeamAndSubscriptionCreateMutation } from 'hooks/api/subscriptions';

import NewTeamForm from './new-team-form';
import schema from './schema';
import styles from './style.module.css';

function NewTeam() {
  const customClassname = classNames(styles['new-team-page']);

  const { mutate } = useTeamAndSubscriptionCreateMutation({
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      onError: () => toast.error('Error creating new team'),
    },
  });

  const handleSubmit = (values) => {
    mutate(values);
  };
  return (
    <>
      <HeaderPrivate />
      <main className={customClassname}>
        <h2>New Team</h2>
        <Formik
          initialValues={{ name: '', seats: 0 }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <NewTeamForm />
              <div className={styles['new-team-page-footer']}>
                <hr />
                <Button
                  type="submit"
                  icon={FiPlusCircle}
                  disabled={!isValid || isSubmitting}
                >
                  Create Team
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </main>
    </>
  );
}

export default NewTeam;
