import React from 'react';
import PropTypes from 'prop-types';
import TemplateCard from 'containers/template-card';

import styles from './style.module.css';

function TemplatesGrid({
  templatesList,
  selectedTemplate,
  setSelectedTemplate,
  team,
  project,
}) {
  const memoizedTemplatesGrid = React.useMemo(
    () => (
      <div className={styles['templates-grid-container']}>
        <p className="font-block-m">Select a Template</p>
        <div className={styles['templates-grid']}>
          {templatesList?.map((template) => (
            <div
              key={template.id}
              className={styles['template-card-wrapper']}
              onClick={() => setSelectedTemplate(template)}
              tabIndex={0}
              role="button"
              data-selected={selectedTemplate?.id === template.id}
            >
              <TemplateCard
                {...template}
                teamId={team}
                projectId={project}
                onlyView
              />
            </div>
          ))}
        </div>
      </div>
    ),
    [templatesList, selectedTemplate, setSelectedTemplate, team, project]
  );

  return memoizedTemplatesGrid;
}

TemplatesGrid.propTypes = {
  templatesList: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTemplate: PropTypes.shape({
    id: PropTypes.string,
  }),
  setSelectedTemplate: PropTypes.func,
  team: PropTypes.string,
  project: PropTypes.string,
};

TemplatesGrid.defaultProps = {
  templatesList: [],
  selectedTemplate: null,
  setSelectedTemplate: () => {},
  team: null,
  project: null,
};

export default TemplatesGrid;
