import PropTypes from 'prop-types';
import Input from '@scalero-inc/forno-input';
import { FiMaximize } from 'react-icons/fi';
import { useState } from 'react';
import { parseAttributeValue } from 'utils/mjml/util';
import Dropdown from '@scalero-inc/forno-dropdown';

import { ReactComponent as IconTopSide } from 'svg/icons/top.svg';
import { ReactComponent as IconRightSide } from 'svg/icons/right.svg';
import { ReactComponent as IconBottomSide } from 'svg/icons/bottom.svg';
import { ReactComponent as IconLeftSide } from 'svg/icons/left.svg';

import { ReactComponent as IconTopRightCorner } from 'svg/icons/top-right-corner.svg';
import { ReactComponent as IconTopLeftCorner } from 'svg/icons/top-left-corner.svg';
import { ReactComponent as IconBottomRightCorner } from 'svg/icons/bottom-right-corner.svg';
import { ReactComponent as IconBottomLeftCorner } from 'svg/icons/bottom-left-corner.svg';

import InputIncremental from '../input-incremental';

import styles from './style.module.css';

const getShorthandPositions = (value) => {
  const individualProps = value.split(' ');
  let top;
  let right;
  let bottom;
  let left;

  if (individualProps.length === 2) {
    [top, right] = individualProps;
    bottom = top;
    left = right;
  }

  if (individualProps.length === 3) {
    [top, right, bottom] = individualProps;
    left = right;
  }

  if (individualProps.length === 4) {
    [top, right, bottom, left] = individualProps;
  }

  return {
    top,
    right,
    bottom,
    left,
  };
};

function InputShorthand({
  label,
  placeholder,
  attribName,
  defaultValue,
  attributes,
  type,
  options,
  handleChange,
}) {
  const shorthand =
    type === 'size' ? parseAttributeValue(defaultValue) : defaultValue;
  const attribValue = shorthand.value || defaultValue;
  const positions =
    type === 'size' ? getShorthandPositions(defaultValue) : defaultValue;

  const top = attributes.top || positions.top;
  const right = attributes.right || positions.right;
  const bottom = attributes.bottom || positions.bottom;
  const left = attributes.left || positions.left;

  const topAttrib = type === 'size' ? parseAttributeValue(top).value : top;
  const rightAttrib =
    type === 'size' ? parseAttributeValue(right).value : right;
  const bottomAttrib =
    type === 'size' ? parseAttributeValue(bottom).value : bottom;
  const leftAttrib = type === 'size' ? parseAttributeValue(left).value : left;

  const [sides, setSides] = useState({
    top: topAttrib,
    right: rightAttrib,
    bottom: bottomAttrib,
    left: leftAttrib,
  });

  const hasIndividualProps =
    sides.top || sides.right || sides.bottom || sides.left;
  const [isShorthand, setIsShorthand] = useState(!hasIndividualProps);

  const Icon = {};
  if (attribName === 'border-radius') {
    Icon.Top = IconTopLeftCorner;
    Icon.Right = IconTopRightCorner;
    Icon.Bottom = IconBottomRightCorner;
    Icon.Left = IconBottomLeftCorner;
  } else {
    Icon.Top = IconTopSide;
    Icon.Right = IconRightSide;
    Icon.Bottom = IconBottomSide;
    Icon.Left = IconLeftSide;
  }
  const unit = type === 'size' ? 'px' : '';

  const toggleIndividualProps = () => {
    setSides({
      top: '',
      right: '',
      bottom: '',
      left: '',
    });
    const defaultAttribValue = type === 'size' ? `0${unit}` : '';
    if (isShorthand) {
      handleChange(defaultAttribValue);
    } else {
      handleChange(
        `${defaultAttribValue} ${defaultAttribValue} ${defaultAttribValue} ${defaultAttribValue}`
      );
    }
    setIsShorthand((value) => !value);
  };

  const onSideChange = (newValue, side) => {
    sides[side] = newValue;
    setSides(sides);

    const defaultAttribValue = type === 'size' ? `0` : '-';
    const newAttribValue = `${sides.top || defaultAttribValue}${unit} ${
      sides.right || defaultAttribValue
    }${unit} ${sides.bottom || defaultAttribValue}${unit} ${
      sides.left || defaultAttribValue
    }${unit}`;
    handleChange(newAttribValue);
  };

  return (
    <div className={styles['input-container']}>
      <div className={styles['input-shorthand-container']}>
        <div>
          <label htmlFor={attribName}>{label}</label>
        </div>

        {type === 'text' && (
          <Input
            value={isShorthand ? attribValue : ''}
            placeholder={placeholder}
            size="s"
            theme="dark"
            variant="subtle"
            disabled={!isShorthand}
            onChange={(event) => {
              const newValue = event.target.value;
              handleChange(newValue);
            }}
            className={styles['input-size']}
          />
        )}

        {type === 'size' && (
          <InputIncremental
            key={`${attribName}_${isShorthand}`}
            defaultValue={defaultValue}
            placeholder={placeholder}
            handleChange={(value) => {
              handleChange(value ? `${value}px` : '');
            }}
            disabled={!isShorthand}
          />
        )}

        {type === 'list' && (
          <Dropdown
            key={`${attribName}_${isShorthand}`}
            size="s"
            look="text"
            options={options}
            disabled={!isShorthand}
            onChange={(event) => {
              const newValue = event.value;
              handleChange(newValue);
            }}
            value={attribValue}
            defaultValue={
              attribValue
                ? { label: attribValue, value: attribValue }
                : { label: 'none', value: 'none' }
            }
          />
        )}

        <span>{unit}</span>
        <FiMaximize
          className={styles.clickable}
          onClick={() => toggleIndividualProps()}
        />
      </div>

      {!isShorthand && (
        <div className={styles['input-individual-container']}>
          <div>
            <div className={styles['input-individual']}>
              <Icon.Top />

              {type === 'list' ? (
                <Dropdown
                  size="s"
                  options={options}
                  onChange={(event) => {
                    const newValue = event.value;
                    onSideChange(newValue, 'top');
                  }}
                  value={attribValue}
                  defaultValue={
                    sides.top
                      ? { label: sides.top, value: sides.top }
                      : { label: 'none', value: 'none' }
                  }
                />
              ) : (
                <Input
                  value={sides.top}
                  placeholder={placeholder}
                  size="s"
                  theme="dark"
                  variant="subtle"
                  onChange={(event) => {
                    const newValue = event.target.value;
                    onSideChange(newValue, 'top');
                  }}
                  className={styles['input-size']}
                />
              )}
              <span>{unit}</span>
            </div>
            <div className={styles['input-individual']}>
              <Icon.Left />

              {type === 'list' ? (
                <Dropdown
                  size="s"
                  options={options}
                  onChange={(event) => {
                    const newValue = event.value;
                    onSideChange(newValue, 'left');
                  }}
                  value={attribValue}
                  defaultValue={
                    sides.left
                      ? { label: sides.left, value: sides.left }
                      : { label: 'none', value: 'none' }
                  }
                />
              ) : (
                <Input
                  value={sides.left}
                  placeholder={placeholder}
                  size="s"
                  theme="dark"
                  variant="subtle"
                  onChange={(event) => {
                    const newValue = event.target.value;
                    onSideChange(newValue, 'left');
                  }}
                  className={styles['input-size']}
                />
              )}
              <span>{unit}</span>
            </div>
          </div>

          <div>
            <div className={styles['input-individual']}>
              <Icon.Right />

              {type === 'list' ? (
                <Dropdown
                  size="s"
                  options={options}
                  onChange={(event) => {
                    const newValue = event.value;
                    onSideChange(newValue, 'right');
                  }}
                  value={attribValue}
                  defaultValue={
                    sides.right
                      ? { label: sides.right, value: sides.right }
                      : { label: 'none', value: 'none' }
                  }
                />
              ) : (
                <Input
                  value={sides.right}
                  placeholder={placeholder}
                  size="s"
                  theme="dark"
                  variant="subtle"
                  onChange={(event) => {
                    const newValue = event.target.value;
                    onSideChange(newValue, 'right');
                  }}
                  className={styles['input-size']}
                />
              )}

              <span>{unit}</span>
            </div>
            <div className={styles['input-individual']}>
              <Icon.Bottom />

              {type === 'list' ? (
                <Dropdown
                  size="s"
                  options={options}
                  onChange={(event) => {
                    const newValue = event.value;
                    onSideChange(newValue, 'bottom');
                  }}
                  value={attribValue}
                  defaultValue={
                    sides.bottom
                      ? { label: sides.bottom, value: sides.bottom }
                      : { label: 'none', value: 'none' }
                  }
                />
              ) : (
                <Input
                  value={sides.bottom}
                  placeholder={placeholder}
                  size="s"
                  theme="dark"
                  variant="subtle"
                  onChange={(event) => {
                    const newValue = event.target.value;
                    onSideChange(newValue, 'bottom');
                  }}
                  className={styles['input-size']}
                />
              )}

              <span>{unit}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

InputShorthand.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  attribName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  attributes: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  handleChange: PropTypes.func.isRequired,
};

InputShorthand.defaultProps = {
  label: '',
  placeholder: '',
  defaultValue: '',
  type: '',
  options: [],
};

export default InputShorthand;
