import { FiPlus } from 'react-icons/fi';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@scalero-inc/forno-button';

import ProjectCreateModal from 'containers/project-create-modal';

function ProjectNewButton({ teamId, blocked }) {
  const [isOpen, setIsOpen] = useState();
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <div>
      <ProjectCreateModal close={close} isOpen={isOpen} team={teamId} />
      <Button icon={FiPlus} onClick={() => open()} disabled={blocked}>
        New Project
      </Button>
    </div>
  );
}

ProjectNewButton.propTypes = {
  teamId: PropTypes.number.isRequired,
  blocked: PropTypes.bool.isRequired,
};

export default ProjectNewButton;
