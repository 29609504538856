import decamelizeKeys from 'decamelize-keys';
import { getSimpleColor } from './color';
import messages from './messages';

export function getTextStyles(
  {
    fontFamily = null,
    fontSize = null,
    lineHeightPx = null,
    fontWeight = null,
    letterSpacing = null,
    textDecoration = null,
    textCase = null,
    fills = null,
  },
  text = ''
) {
  const styles = {};
  const errors = [];
  const node = { id: '-', name: `mj-text (${text.substring(0, 40)}...)` };

  if (fontFamily) {
    styles.fontFamily = fontFamily;
  }
  if (fontSize) {
    styles.fontSize = `${Math.round(fontSize)}px`;
  }
  if (fontSize && lineHeightPx) {
    if (fontSize > lineHeightPx) {
      styles.lineHeight = `${Math.round(fontSize)}px`;
      errors.push(messages.info(messages.MSG.LINE_HEIGHT_FIXED, node));
    } else {
      styles.lineHeight = `${Math.round(lineHeightPx)}px`;
    }
  }
  if (fontWeight) {
    styles.fontWeight = fontWeight;
  }
  if (letterSpacing) {
    styles.letterSpacing = `${letterSpacing}px`;
  }
  if (textDecoration) {
    styles.textDecoration = textDecoration.toLowerCase();
  }
  if (textCase) {
    switch (textCase) {
      case 'UPPER':
        styles.textTransform = 'uppercase';
        break;
      case 'LOWER':
        styles.textTransform = 'lowercase';
        break;
      default:
        styles.textTransform = 'capitalize';
    }
  }
  if (fills) {
    styles.color = getSimpleColor(fills);
  }

  return {
    textStyles: styles,
    errorList: errors,
  };
}

/**
 *
 * @param {*} characterStyleOverrides - Array with style number per character
 * @returns - Array with style number and range of characters (startIndex, endIndex)
 */
export function getRangesFromStyleType(characterStyleOverrides) {
  const styleList = [];
  let styleNumber = 0;

  characterStyleOverrides.forEach((characterStyle, index) => {
    // Adds the first style
    if (styleList.length === 0) {
      styleList.push({
        styleType: characterStyle,
        startIndex: index,
        endIndex: index,
      });
    }

    // Adds a new style
    if (styleList[styleNumber].styleType !== characterStyle) {
      styleNumber += 1;
      styleList.push({
        styleType: characterStyle,
        startIndex: index,
        endIndex: index,
      });
    } else {
      // Update the endIndex of the current style
      styleList[styleNumber].endIndex = index;
    }
  });

  return styleList;
}

export function getTextContentWithStyles(
  characters,
  styleOverrideTable,
  characterStyleOverrides
) {
  if (characterStyleOverrides.length === 0) return characters;

  let styledText = '';
  const rangeStyles = getRangesFromStyleType(characterStyleOverrides);

  rangeStyles.forEach((style) => {
    const text = characters.substring(style.startIndex, style.endIndex + 1);
    const styleOverride = styleOverrideTable[style.styleType];
    const { textStyles } = getTextStyles(styleOverride || {}, text);
    let inlineTextStyles = '';

    // Getting inline styles
    if (styleOverride) {
      inlineTextStyles = JSON.stringify(decamelizeKeys(textStyles, '-'))
        .replace(/[{""}]/g, '')
        .replace(/[,]/g, '; ');
    }

    styledText += `<span style="${inlineTextStyles}">${text}</span>`;
  });

  // Rest of the text
  const lastIndexWithStyles = rangeStyles[rangeStyles.length - 1].endIndex;
  if (lastIndexWithStyles < characters.length) {
    styledText += characters.substring(lastIndexWithStyles + 1);
  }

  return styledText;
}
