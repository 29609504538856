import PropTypes from 'prop-types';

import Spinner from '@scalero-inc/forno-spinner';

import { useTeamMembersQuery } from 'hooks/api/teams';

import PricingCardTeam from '../pricing-card-team';

function PricingCardTeamWrapper({ teamId, type }) {
  const { data: members, isLoading } = useTeamMembersQuery({ teamId });

  if (isLoading) return <Spinner />;

  return <PricingCardTeam type={type} teamId={teamId} seats={members.length} />;
}

PricingCardTeamWrapper.propTypes = {
  teamId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default PricingCardTeamWrapper;
