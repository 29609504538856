import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import HeaderPrivate from 'containers/header-private';
import SEO from 'components/seo';

import { FiHome } from 'react-icons/fi';

import styles from './style.module.css';

function NotFound({ resetErrorBoundary }) {
  const { pathname } = useLocation();
  const originalPathname = React.useRef(pathname);

  React.useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary();
    }
  }, [pathname, resetErrorBoundary]);

  return (
    <div className={styles['not-found-page']}>
      <SEO prefix="Error" name="404 Not found" />
      <HeaderPrivate />
      <div className={styles['not-found-main']}>
        <div className={styles['not-found-intro']}>
          <p>You found the source of our power!</p>
          <p>But you should not be here!</p>
        </div>
        <div className={styles['not-found-content']}>
          <img src="/404-Error-message.gif" alt="page not found animation" />
          <div className={styles['not-found-message']}>
            <p>404 error</p>
            <p>Not Found</p>
          </div>
          <Link
            to="/"
            className="forno-button font-block-m-sb"
            data-size="m"
            data-hierarchy="primary"
            data-theme="light"
          >
            <FiHome />
            Go to dashboard
          </Link>
        </div>
        <div className={styles['not-found-help']}>
          <p>Is this happening a lot? &nbsp;</p>
          <a href="mailto:hello@cannoli.app">Let us help</a>
        </div>
      </div>
    </div>
  );
}

NotFound.propTypes = {
  resetErrorBoundary: PropTypes.func,
};

NotFound.defaultProps = {
  resetErrorBoundary: () => {},
};

export default NotFound;
