import decamelizeKeys from 'decamelize-keys';

import MjBody from './mj-body';
import MjWrapper from './mj-wrapper';
import MjSection from './mj-section';
import MjColumn from './mj-column';
import MjImage from './mj-image';
import MjText from './mj-text';
import MjButton from './mj-button';
import MjDivider from './mj-divider';
import MjSocial from './mj-social';
import MjSocialElement from './mj-social-element';
import ScErrorMessage from './sc-error-message';
import { tagsDicts } from './dictionaries';
import MjSpacer from './mj-spacer';
import MjGroup from './mj-group';

export function process(node = {}, images = {}, nodeImages = {}) {
  const { tagName } = node;

  const props = {
    ...node,
    images,
    nodeImages,
    attributes: {},
    ...(node.children && {
      children: node.children
        .map((child) => process(child, images, nodeImages))
        .filter(Boolean)
        .flat(),
    }),
  };

  switch (tagName) {
    case 'mj-body':
      return MjBody(props);
    case 'mj-wrapper':
      return MjWrapper(props);
    case 'mj-section':
      return MjSection(props);
    case 'mj-group':
      return MjGroup(props);
    case 'mj-column':
      return MjColumn(props);
    case 'mj-social-element':
      return MjSocialElement(props);
    case 'mj-social':
      return MjSocial(props);
    case 'mj-image':
      return MjImage(props);
    case 'mj-text':
      return MjText(props);
    case 'mj-divider':
      return MjDivider(props);
    case 'mj-button':
      return MjButton(props);
    case 'mj-spacer':
      return MjSpacer(props);
    default:
      console.warn(`Layer with name: ${node.name} not supported`);
      return ScErrorMessage(props);
  }
}

export function getNodeTag(node) {
  const name = node.name.toLowerCase();

  return tagsDicts.find((item) => {
    if (item.check && item.check(node)) {
      return item;
    }

    return item.dict.some((element) => name.includes(element));
  })?.tag;
}

export function preProcess(node = {}, parent = null) {
  const tagName = !parent ? 'mj-body' : getNodeTag(node);
  const { children, ...rest } = node;
  const preparedChildren = children
    ? children.map((child) => preProcess(child, node))
    : null;

  return {
    ...rest,
    tagName,
    parent,
    ...(preparedChildren && { children: preparedChildren }),
  };
}

export function postProcess(node = {}) {
  const { children, attributes = {}, ...rest } = node;
  const preparedAttributes = decamelizeKeys(attributes, '-');
  const preparedChildren = children
    ? children.map((child) => postProcess(child))
    : null;
  return {
    ...rest,
    ...{ attributes: preparedAttributes },
    ...(preparedChildren && { children: preparedChildren }),
  };
}

export function extractParseMessages(node) {
  const { children = [], errors = [] } = node;
  const arr = children.reduce((acc, curr) => {
    return [...acc, ...extractParseMessages(curr)];
  }, []);
  return [...errors, ...arr];
}
