const { REACT_APP_FIGMA_API_URL } = process.env;
export async function imagesQuery({ client, file, nodes }) {
  const url = new URL(`${REACT_APP_FIGMA_API_URL}/images/${file}/`);
  url.searchParams.append('ids', nodes.join(','));
  const { data } = await client.post(`figma/query/`, {
    url,
  });

  return data;
}

export async function fileImagesQuery({ client, file }) {
  const { data } = await client.post(`figma/query/`, {
    url: `${REACT_APP_FIGMA_API_URL}/files/${file}/images`,
  });
  return data;
}
