import { process, preProcess, postProcess } from './parser';
import MjHead from './parser/mj-head';

function figmaToMjml(nodes, images, nodeImages) {
  const prepared = preProcess(nodes[0]);
  const parsed = process(prepared, images, nodeImages)[0] || {
    tagName: 'mj-body',
  };
  const processed = postProcess(parsed);

  return {
    tagName: 'mjml',
    attributes: {},
    children: [MjHead(nodes, processed), processed],
  };
}

export default figmaToMjml;
