import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { FiLogIn } from 'react-icons/fi';
import Button from '@scalero-inc/forno-button';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import Input from '@scalero-inc/forno-input';

import Alert from 'components/alert';
import { useAuth } from 'context/auth';

import schema from './schema';
import styles from './styles.module.css';

const initialValues = {
  username: '',
  password: '',
};

function getErrorMessage(error) {
  const { request, response } = error;
  if (response) {
    const message = response.data;
    const { status } = response;
    return {
      message,
      status,
    };
  }
  if (request) {
    // request sent but no response received
    return {
      message: 'server time out',
      status: 503,
    };
  }
  // Something happened in setting up the request that triggered an Error
  return { message: 'opps! something went wrong while setting up request' };
}

function LoginWithPasswordForm({ className }) {
  const { loginWithPassword } = useAuth();
  const navigate = useNavigate();
  const customClassName = classnames(
    'login-with-password-form',
    styles['login-with-password-form'],
    className,
    {}
  );
  const mutation = useMutation(loginWithPassword, {
    onSuccess: () => {
      navigate('/');
    },
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await mutation.mutate(values);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <Form className={customClassName}>
        <h1>Welcome to Cannoli</h1>
        {mutation.isError ? (
          <Alert title="Login failed">
            <pre>
              {JSON.stringify(getErrorMessage(mutation.error), null, 2)}
            </pre>
          </Alert>
        ) : null}
        <Field id="username" name="username" placeholder="GoodUser123">
          {({ field }) => (
            <Input
              {...field}
              className={styles.field}
              theme="dark"
              label="Username"
              size="s"
            />
          )}
        </Field>
        <ErrorMessage name="username" component="div" />

        <Field id="password" name="password" placeholder="***">
          {({ field }) => (
            <Input
              {...field}
              className={styles.field}
              theme="dark"
              type="password"
              label="Password"
              size="s"
            />
          )}
        </Field>
        <ErrorMessage name="password" component="div" />

        <footer className={styles['login-with-password-form-footer']}>
          <Button
            type="submit"
            disabled={mutation.isLoading}
            loading={mutation.isLoading}
            icon={FiLogIn}
          >
            Login
          </Button>
        </footer>
      </Form>
    </Formik>
  );
}

LoginWithPasswordForm.propTypes = {
  className: PropTypes.string,
};

LoginWithPasswordForm.defaultProps = {
  className: null,
};

export default LoginWithPasswordForm;
