import PropTypes from 'prop-types';

import styles from './style.module.css';

export default function FeatureBlock({ image, number, alt, title, children }) {
  return (
    <div className={styles['feature-block']}>
      {image && <img width={128} height={128} src={image} alt={alt} />}
      <div className={styles['feature-block-content']}>
        {number && <span>{number}</span>}
        {title && <h3>{title}</h3>}
        {children && <p className="paragraph-sl">{children}</p>}
      </div>
    </div>
  );
}

FeatureBlock.propTypes = {
  image: PropTypes.string,
  number: PropTypes.number,
  alt: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

FeatureBlock.defaultProps = {
  image: null,
  number: null,
  alt: null,
  title: null,
  children: null,
};
