import PropTypes from 'prop-types';
import { useMemo } from 'react';
import IconButton from '@scalero-inc/forno-icon-button';
import Button from '@scalero-inc/forno-button';
import Spinner from '@scalero-inc/forno-spinner';
import {
  useTeamFontStacksQuery,
  useUserFontStacksQuery,
} from 'hooks/api/fonts';
import { FiTrash2 } from 'react-icons/fi';

import styles from './styles.module.css';

function CustomFontsTable({
  customFonts,
  onAddFallback,
  onDeleteFont,
  teamId,
  theme,
}) {
  const {
    data: userFonts,
    isLoading: isUserFontsLoading,
    isFetching: isUserFontsFetching,
    isError: isUserFontsError,
  } = useUserFontStacksQuery({
    config: {
      enabled: !teamId,
    },
  });

  const {
    data: teamFonts,
    isLoading: isTeamFontsLoading,
    isFetching: isTeamFontsFetching,
    isError: isTeamFontsError,
  } = useTeamFontStacksQuery({
    teamId,
    config: {
      enabled: !!teamId,
    },
  });

  const data = teamId ? teamFonts : userFonts;
  const isLoading = teamId ? isTeamFontsLoading : isUserFontsLoading;
  const isFetching = teamId ? isTeamFontsFetching : isUserFontsFetching;
  const isError = teamId ? isTeamFontsError : isUserFontsError;
  const customFontsList = useMemo(() => {
    if (!data?.results) {
      return customFonts;
    }

    return customFonts.map((font) => {
      const fallback = data?.results.filter(
        (stack) => stack.font_family === font.family
      );
      return { ...font, fallback };
    });
  }, [data?.results, customFonts]);

  if (isLoading || isFetching) {
    <Spinner />;
  }

  if (isError) {
    return (
      <div className={styles['error-message']}>
        Can not load your font stacks, try again later
      </div>
    );
  }

  return (
    <table className={styles['fonts-table']}>
      <tbody>
        <tr>
          <th>Font family</th>
          <th>Weight</th>
          <th>Fallback name</th>
          <th>Fallback stack</th>
          <th />
        </tr>

        {customFontsList.length === 0 && (
          <tr>
            <td colSpan={5} className={styles.center}>
              There are no custom fonts loaded in your file.
            </td>
          </tr>
        )}
        {customFontsList.map((font, fontIndex) => {
          return (
            <tr key={`custom_font_${fontIndex}`}>
              <td>{font.family}</td>
              <td>{font.weight}</td>
              <td>
                {!font?.fallback || font.fallback.length === 0 ? (
                  '- -'
                ) : (
                  <>
                    {font.fallback.map((fallback, fallbackIndex) => (
                      <div key={`fallback_${fontIndex}_${fallbackIndex}`}>
                        {fallback.name}
                      </div>
                    ))}
                  </>
                )}
              </td>
              <td>
                {!font?.fallback || font.fallback.length === 0 ? (
                  <Button
                    size="s"
                    theme={theme}
                    hierarchy="secondary"
                    onClick={() => onAddFallback(font)}
                  >
                    Add fallback
                  </Button>
                ) : (
                  <>
                    {font.fallback.map((fallback, fallbackIndex) => (
                      <div key={`fallback_${fontIndex}_${fallbackIndex}`}>
                        {fallback.stack
                          .map((element) => element.family)
                          .join(', ')}
                      </div>
                    ))}
                  </>
                )}
              </td>
              <td>
                <IconButton
                  theme={theme}
                  hierarchy="tertiary"
                  onClick={() => {
                    onDeleteFont(font);
                  }}
                >
                  <FiTrash2 />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

CustomFontsTable.propTypes = {
  customFonts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddFallback: PropTypes.func.isRequired,
  onDeleteFont: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  theme: PropTypes.oneOf(['light', 'dark']),
};

CustomFontsTable.defaultProps = {
  teamId: null,
  theme: 'light',
};

export default CustomFontsTable;
