import PropTypes from 'prop-types';
import { useState } from 'react';
import Tabs from '@scalero-inc/forno-tab';
import Button from '@scalero-inc/forno-button';
import { FiChevronDown, FiChevronUp, FiPlus } from 'react-icons/fi';
import useOutsideClick from 'hooks/utils/use-outside-click';
import { autoUpdate, useFloating, shift } from '@floating-ui/react-dom';
import DropdownOption from './dropdown-option';

import styles from './styles.module.css';

function FontsDropdownMenu({ defaultValue, tabs, addItem, options, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const closeDropdown = () => setIsOpen(false);
  const dropdownRef = useOutsideClick({ callback: closeDropdown });
  const toogleMenu = () => {
    setIsOpen((prevValue) => !prevValue);
  };
  const { x, y, reference, floating, strategy } = useFloating({
    middleware: [shift({ padding: 8 })],
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
  });
  const contentPosition = {
    position: strategy,
    top: y !== null || y !== undefined ? y : 0,
    left: x !== null || x !== undefined ? x : 0,
  };

  return (
    <div ref={dropdownRef} className={styles['dropdown-menu-container']}>
      <div
        ref={reference}
        role="button"
        tabIndex="0"
        className={styles['dropdown-menu-toggle']}
        onClick={toogleMenu}
      >
        <span>{defaultValue.label}</span>
        {isOpen ? <FiChevronUp /> : <FiChevronDown />}
      </div>

      {isOpen && (
        <div
          ref={floating}
          data-theme="dark"
          className={styles['dropdown-menu-content']}
          style={contentPosition}
        >
          {tabs.length > 0 && (
            <Tabs theme="dark" id="dropdown-menu-tabs">
              {tabs.map((tab, tabIndex) => {
                return (
                  <Tabs.Item key={`tab_${tabIndex}`} title={tab.name}>
                    <div className={styles['options-container']}>
                      {tab.options.length === 0 && (
                        <div className={styles['no-options']}>
                          No options available
                        </div>
                      )}

                      {tab.options.map((option, optionIndex) => {
                        return (
                          <DropdownOption
                            key={`font_option_${optionIndex}`}
                            label={option.label}
                            value={option.value}
                            selectedValue={defaultValue.value}
                            onSelect={(selectedValue) => {
                              onChange(selectedValue);
                              closeDropdown();
                            }}
                          />
                        );
                      })}
                    </div>

                    {tab.addItem && (
                      <Button
                        hierarchy="secondary"
                        size="s"
                        theme="dark"
                        icon={FiPlus}
                        onClick={tab.addItem.callback}
                      >
                        {tab.addItem.label}
                      </Button>
                    )}
                  </Tabs.Item>
                );
              })}
            </Tabs>
          )}

          {options.length > 0 && (
            <div className={styles['options-container']}>
              {options.map((option, optionIndex) => {
                return (
                  <DropdownOption
                    key={`fallback_option_${optionIndex}`}
                    label={option.label}
                    value={option.value}
                    selectedValue={defaultValue.value}
                    onSelect={(selectedValue) => {
                      onChange(selectedValue);
                      closeDropdown();
                    }}
                  />
                );
              })}
            </div>
          )}

          {addItem && (
            <Button
              hierarchy="secondary"
              size="s"
              theme="dark"
              icon={FiPlus}
              onClick={addItem.callback}
            >
              {addItem.label}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

FontsDropdownMenu.propTypes = {
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape(),
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.shape({})),
      addItem: PropTypes.shape({
        label: PropTypes.string,
        callback: PropTypes.func,
      }),
    })
  ),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  addItem: PropTypes.shape({
    label: PropTypes.string,
    callback: PropTypes.func,
  }),
  onChange: PropTypes.func,
};

FontsDropdownMenu.defaultProps = {
  defaultValue: PropTypes.shape({ label: '', value: {} }),
  tabs: [],
  options: [],
  addItem: null,
  onChange: () => {},
};

export default FontsDropdownMenu;
