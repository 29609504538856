import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';

import IconButton from '@scalero-inc/forno-icon-button';

import styles from './styles.module.css';

function DraggableBlock({ block, index, deleteBlock }) {
  return (
    <Draggable draggableId={`${block.id} ${index}`} index={index}>
      {(provided) => (
        <div
          key={block.id}
          className={styles['block-item']}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <p className="font-block-s-sb">{block.name}</p>
          <div className={styles['icons-container']}>
            <IconButton onClick={deleteBlock} hierarchy="tertiary" size="s">
              <FiTrash />
            </IconButton>
            <MdDragIndicator size={16} className={styles['drag-indicator']} />
          </div>
        </div>
      )}
    </Draggable>
  );
}

DraggableBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  deleteBlock: PropTypes.func.isRequired,
};

export default DraggableBlock;
