import PropTypes from 'prop-types';
import classnames from 'classnames';

import HeaderPrivate from 'containers/header-private';

import styles from './style.module.css';

export default function PageOnboarding({ className, children, ...props }) {
  const customClassName = classnames(
    'page-onboarding',
    className,
    styles['page-onboarding']
  );

  return (
    <>
      <HeaderPrivate />
      <main {...props} className={customClassName}>
        {children}
      </main>
    </>
  );
}

PageOnboarding.propTypes = {
  css: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.node,
};

PageOnboarding.defaultProps = {
  css: null,
  className: null,
  children: null,
};
