export async function componentCreateMutation({ client, values, team }) {
  const { data } = await client.post(`components/create`, {
    team,
    ...values,
  });
  return data;
}

export async function createComponentMutation({ client, values }) {
  const { data } = await client.post(`user/components/`, {
    name: values.name,
    content: values.content,
    file: values.file,
    ...values,
  });
  return data;
}

export async function updateComponentMutation({ client, componentId, values }) {
  const { data } = await client.patch(
    `user/components/${componentId}/`,
    values
  );
  return data;
}

export async function updateTeamComponentMutation({
  client,
  teamId,
  componentId,
  values,
}) {
  const { data } = await client.patch(
    `teams/${teamId}/components/${componentId}/`,
    values
  );
  return data;
}

export async function createTeamComponentMutation({ client, teamId, values }) {
  const { data } = await client.post(`teams/${teamId}/components/`, {
    name: values.name,
    content: values.content,
    file: values.file,
    ...values,
  });
  return data;
}

export async function deleteUserComponentMutation({ client, componentId }) {
  const { data } = await client.delete(`user/components/${componentId}/`);
  return data;
}

export async function deleteTeamComponentMutation({
  client,
  teamId,
  componentId,
}) {
  const { data } = await client.delete(
    `teams/${teamId}/components/${componentId}/`
  );
  return data;
}
