import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './style.module.css';

function Tag({ className, children, status }) {
  const customClassName = classnames('tag', styles.tag, className, {});
  return (
    <span className={customClassName} data-status={status}>
      {children}
    </span>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.string,
  className: PropTypes.string,
};

Tag.defaultProps = {
  className: null,
  status: null,
};

export default Tag;
