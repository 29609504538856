import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import { useCreateComponentMutationMock } from 'hooks/api/components';

import {
  MOCK_COMPONENT_DATA,
  MOCK_COMPONENT_TAGS,
  MOCK_COMPONENT_NAME,
} from 'mocks/data/components';

// TODO: This is a placeholder component to showcase the usage of the API hooks
function ComponentCreateButton() {
  const { isLoading, mutate } = useCreateComponentMutationMock({
    team: null,
    config: {
      onSuccess: (data) => {
        toast.success(`Component ${data.name} Created`);
      },
      onError: () => {
        toast.error('Failed to create component');
      },
    },
  });

  return (
    <Button
      loading={isLoading}
      disabled={isLoading}
      onClick={() => {
        mutate({
          name: MOCK_COMPONENT_NAME,
          code: MOCK_COMPONENT_DATA,
          tags: MOCK_COMPONENT_TAGS,
        });
      }}
    >
      {!isLoading ? 'Create new component' : 'Saving...'}
    </Button>
  );
}

export default ComponentCreateButton;
