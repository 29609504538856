import React from 'react';
import PropTypes from 'prop-types';
import { HiOutlinePuzzle } from 'react-icons/hi';
import { FiPlus } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';

import LoadingPlaceholder from 'components/loading-placeholder';
import BlockItem from 'components/block-item';

import { useComponentsQuery } from 'hooks/api/components';

import styles from './styles.module.css';

function TemplatesModal({ isOpen, close, theme, setBlocks, blocks }) {
  const { teamId } = useParams();
  const { data: queryBlocksData, isLoading } = useComponentsQuery({
    teamId,
  });

  const [selectedBlocks, setSelectedBlocks] = React.useState([]);

  const handleToggleSelect = React.useCallback(
    (block) => {
      setSelectedBlocks((prevSelectedBlocks) => {
        if (prevSelectedBlocks.includes(block.id)) {
          return prevSelectedBlocks.filter((id) => id !== block.id);
        }
        return [...prevSelectedBlocks, block.id];
      });
    },
    [setSelectedBlocks]
  );

  const addBlocksToTemplate = (queryBlockIds) => {
    let nextPosition =
      Math.max(...blocks.map((block) => block.position), -1) + 1;

    const newBlocks = queryBlockIds
      .map((queryBlockId) => {
        const queryBlock = queryBlocksData.find((qb) => qb.id === queryBlockId);
        if (!queryBlock) return null;

        const newBlock = {
          ...queryBlock,
          templateComponentId: null,
          position: nextPosition,
        };

        nextPosition += 1;

        return newBlock;
      })
      .filter((block) => block !== null);

    setBlocks((prevBlocks) => [...prevBlocks, ...newBlocks]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="edit team modal"
      theme={theme}
    >
      <Modal.Header
        title="Available blocks"
        onDismiss={close}
        icon={HiOutlinePuzzle}
      />
      <section className={styles['blocks-modal-container']}>
        {isLoading && (
          <LoadingPlaceholder size="l" theme="dark" hierarchy="secondary" />
        )}
        {queryBlocksData?.map((block) => (
          <BlockItem
            key={block.id}
            block={block}
            teamId={teamId}
            type="select"
            onClick={() => handleToggleSelect(block)}
          />
        ))}
      </section>
      <Modal.Footer>
        <Button hierarchy="tertiary" theme={theme} onClick={close}>
          Cancel
        </Button>
        <Button
          hierarchy="primary"
          theme={theme}
          onClick={() => {
            addBlocksToTemplate(selectedBlocks);
            setSelectedBlocks([]);
            close();
          }}
          icon={FiPlus}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

TemplatesModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  theme: PropTypes.string,
  setBlocks: PropTypes.func.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      position: PropTypes.number.isRequired,
    })
  ).isRequired,
};

TemplatesModal.defaultProps = {
  isOpen: false,
  theme: 'light',
};

export default TemplatesModal;
