import { ReactComponent as DiaCoLogo } from 'svg/brands/dia-co-logo.svg';
import { ReactComponent as TheFarmersDogLogo } from 'svg/brands/the-farmers-dog-logo.svg';
import { ReactComponent as ResyLogo } from 'svg/brands/resy-logo.svg';

import styles from './style.module.css';

function BrandsSection() {
  return (
    <section className={styles['brands-section']}>
      <h2>Trusted by the world’s fastest growing brands</h2>
      <div className={styles['brands-container']}>
        <DiaCoLogo className={styles['brand-logo']} />
        <TheFarmersDogLogo className={styles['brand-logo']} />
        <ResyLogo className={styles['brand-logo']} />
      </div>
    </section>
  );
}

export default BrandsSection;
