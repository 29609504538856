import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';
import { Field, Form, Formik } from 'formik';
import {
  useUserComponentsPaginatedInvalidateQuery,
  useTeamComponentsPaginatedInvalidateQuery,
  useUpdateComponentMutation,
  useUpdateTeamComponentMutation,
} from 'hooks/api/components';

import schema from './schema';
import styles from './styles.module.css';

function ComponentEditModal({ id, name, teamId, isOpen, close }) {
  const invalidateUserComponents = useUserComponentsPaginatedInvalidateQuery();
  const invalidateTeamComponents = useTeamComponentsPaginatedInvalidateQuery({
    teamId,
  });
  const invalidateComponents = teamId
    ? invalidateTeamComponents
    : invalidateUserComponents;

  const config = {
    onSuccess: () => {
      close();
      invalidateComponents();
      toast.success('Block updated successfully');
    },
    onError: () => {
      toast.error('Error updating block');
    },
  };
  const userUpdateMutation = useUpdateComponentMutation({
    componentId: id,
    config,
  });
  const teamUpdateMutation = useUpdateTeamComponentMutation({
    componentId: id,
    teamId,
    config,
  });
  const updateMutation = teamId ? teamUpdateMutation : userUpdateMutation;

  const handleNameChange = useCallback(
    (values) => {
      updateMutation.mutate({ name: values.name });
    },
    [updateMutation]
  );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      theme="light"
      aria-label="edit block modal"
    >
      <Modal.Header title={`Update ${name}`} onDismiss={close} icon={FiEdit} />
      <div>
        <Formik
          initialValues={{ name }}
          validationSchema={schema}
          onSubmit={handleNameChange}
        >
          {({ isValid }) => (
            <Form className={styles['form-wrapper']}>
              <Field id="name" name="name">
                {({ field }) => (
                  <Input
                    id="name"
                    size="s"
                    label="Block Name"
                    placeholder="Block Name"
                    theme="light"
                    {...field}
                  />
                )}
              </Field>
              <div>
                <Button
                  theme="light"
                  type="submit"
                  loading={updateMutation.isLoading}
                  disabled={!isValid || updateMutation.isLoading}
                >
                  Update Name
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

ComponentEditModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ComponentEditModal.defaultProps = {
  isOpen: false,
  teamId: null,
};

export default ComponentEditModal;
