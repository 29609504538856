import classNames from 'classnames';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import { IoWarningOutline } from 'react-icons/io5';

import { useEspRemoveMutation, useEspsInvalidateQuery } from 'hooks/api/esps';

import { ReactComponent as CannoliModalLogo } from 'svg/integrations/modal-cannoli-logo.svg';

import { FiCheck, FiX } from 'react-icons/fi';
import styles from './style.module.css';

function IntegrationRemoveModal({ isOpen, close, name, id, img: Image }) {
  const customClassname = classNames(styles['integration-remove-modal']);
  const invalidate = useEspsInvalidateQuery();

  const { mutate, isLoading } = useEspRemoveMutation({
    id,
    config: {
      onSuccess: () => {
        toast.success('ESP removed successfully');
        close();
        invalidate();
      },
      onError: () => {
        toast.error('Error removing ESP');
      },
    },
  });

  const onSubmit = () => {
    mutate();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="remove integration modal"
      theme="light"
      dangerouslyBypassFocusLock
      className={customClassname}
      status="danger"
    >
      <Modal.Header
        title={`Remove ${name} integration`}
        onDismiss={close}
        icon={IoWarningOutline}
        status="danger"
      />
      <div className={styles['integration-remove-modal-content']}>
        <div className={styles['integration-image-container']}>
          <CannoliModalLogo className={styles['logo-image']} />
          <FiX className={styles['modal-icon']} />
          <Image className={styles['logo-image']} />
        </div>
      </div>
      <strong>You will lose access to the following features:</strong>
      <ul className={styles['integration-remove-modal-content-list']}>
        <li>
          <div className={styles['integration-list-item']}>
            <FiCheck className={styles['integration-list-icon']} />
            <p>Export email templates into your Klaviyo account.</p>
          </div>
        </li>
      </ul>
      <Modal.Footer>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
          icon={IoWarningOutline}
          status="danger"
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

IntegrationRemoveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.number,
  img: PropTypes.shape({}),
};

IntegrationRemoveModal.defaultProps = {
  name: '',
  id: null,
  img: null,
};

export default IntegrationRemoveModal;
