export const contents = {
  privacyPolicy: [
    { title: 'Privacy Policy for Websites', id: 'privacy-policy-for-websites' },
    {
      title: 'Scalero Privacy Policy',
      id: 'scalero-privacy-policy',
      items: [
        { title: 'Introduction', id: 'introduction' },
        {
          title: 'Personal Information We Collect',
          id: 'personal-information-we-collect',
        },
        {
          title: 'How We Use Personal Information',
          id: 'how-we-use-personal-infromation',
        },
        {
          title: 'How We Share Personal Information',
          id: 'how-we-share-personal-information',
        },
        {
          title: 'How We Secure Personal Information',
          id: 'how-we-secure-personal-information',
        },
        {
          title: 'Your Rights',
          id: 'your-rights',
        },
        {
          title: 'How Long We Keep Your Personal Information',
          id: 'how-long-we-keep-your-personal-information',
        },
        {
          title: 'Other Important Information',
          id: 'other-important-information',
        },
        {
          title: 'Contact Information',
          id: 'contact-information',
        },
      ],
    },
    {
      title: 'Appendix',
      id: 'appendix',
      items: [
        {
          title:
            'A.1 For Individuals Based in the European Union (EU), European Economic Area (EEA) and Switzerland',
          id: 'for-individuals-in-europe',
        },
        {
          title: 'A.2 For Individuals Based in California',
          id: 'for-individuals-in-california',
        },
      ],
    },
  ],
  servicesPolicy: [
    {
      title: 'Services Policies',
      id: 'services-policies',
      items: [
        { title: 'Services Overview', id: 'services-overview' },
        { title: 'Placing Orders', id: 'placing-orders' },
        { title: 'Scope of Service', id: 'scope-of-service' },
        { title: 'Limitations', id: 'limitations' },
        { title: 'Pricing', id: 'pricing' },
        { title: 'Payments', id: 'payments' },
        { title: 'Turnaround Times', id: 'turnaround-times' },
        { title: 'Cancellation and Refunds', id: 'cancellation-and-refunds' },
        {
          title: 'Confidentiality and Security',
          id: 'confidentiality-and-security',
        },
        { title: 'Termination of Service', id: 'termination-of-service' },
        { title: 'Policy Changes', id: 'policy-changes' },
        { title: 'Contact Information', id: 'contact-information' },
      ],
    },
  ],
};
