import React from 'react';

import PublicPage from 'containers/public-page';

import ExpertlyCodedTemplates from './expertly-coded-templates-section';
import PricingAndFeatures from './pricing-and-features-section';
import HowItWorks from './how-it-works-section';
import LookingForMore from './looking-for-more-section';
import WeAreHereSection from './we-are-here-section';

function CannoliServices() {
  return (
    <PublicPage>
      <ExpertlyCodedTemplates />
      <PricingAndFeatures />
      <HowItWorks />
      <LookingForMore />
      <WeAreHereSection />
    </PublicPage>
  );
}

export default CannoliServices;
