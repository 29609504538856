import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import { useUpdatePersonalSubscription } from 'hooks/api/subscriptions';
import { useUserInvalidateQuery } from 'hooks/api/users';

import { dollarUSFormat } from 'utils/currency';

function SubscriptionProToStarterModal({ isOpen, close }) {
  const navigate = useNavigate();
  const invalidateUser = useUserInvalidateQuery();
  const { mutate: changeProToStarter, isLoading } =
    useUpdatePersonalSubscription({
      config: {
        onSuccess: () => {
          toast.success('Updated to Starter plan successfully');
          invalidateUser();
          navigate('/dashboard/settings/billing');
        },
        onError: () => {
          toast.error(
            'There was an error updating your subscription. Please try again later.'
          );
        },
      },
    });

  const handleClick = () => {
    changeProToStarter();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="downgrade subscription from Pro to Starter modal"
      status="danger"
    >
      <Modal.Header title="Downgrade from Pro to Starter" onDismiss={close} />
      <p>
        By downgrading to Starter you will lose access to all Pro features when
        your billing period ends. Are you sure you want to downgrade?
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <h3>Due at the end of period:</h3>
            </td>
            <td>
              <h3>{dollarUSFormat.format(0)} USD</h3>
            </td>
          </tr>
        </tbody>
      </table>
      <Modal.Footer>
        <Button hierarchy="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={handleClick}
          loading={isLoading}
          disabled={isLoading}
          status="danger"
        >
          Downgrade
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SubscriptionProToStarterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SubscriptionProToStarterModal;
