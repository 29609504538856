import PropTypes from 'prop-types';
import useDebounce from 'hooks/utils/use-debounce';
import MjmlEditor from 'components/mjml-editor';
import CodeEditor from 'components/code-editor';
import UpdateFilePreview from 'containers/update-file-preview';

import { useReadOnly, useReadOnlyDispatch } from '../read-only-context';
import { useReadOnlyView } from '../read-only-view-context';

import styles from './style.module.css';

function ReadOnlyCode({ visible }) {
  const {
    fileId,
    componentId,
    mjml,
    html,
    errors = [],
    tagSelected,
  } = useReadOnly();
  const isEditingFile = !!fileId;
  const { teamId } = useReadOnly();
  const { setTagSelected, editor } = useReadOnlyDispatch(
    teamId,
    fileId,
    componentId
  );
  const { viewCode } = useReadOnlyView();

  const updateState = (code) => {
    editor.save(code);
  };
  const debouncedOnChange = useDebounce(updateState, 300);

  return (
    <>
      {isEditingFile && <UpdateFilePreview />}
      <section
        className={styles['editor-code-container']}
        data-visible={visible}
      >
        {viewCode === 'mjml' ? (
          <MjmlEditor
            name="main-editor-mjml"
            setTagSelected={setTagSelected}
            tagSelected={tagSelected}
            value={mjml}
            onChange={debouncedOnChange}
            errors={errors}
            readOnly
          />
        ) : null}
        {viewCode === 'html' ? (
          <CodeEditor
            name="main-editor-html"
            value={html}
            mode="html"
            readOnly
          />
        ) : null}
      </section>
    </>
  );
}

ReadOnlyCode.propTypes = {
  visible: PropTypes.bool,
};

ReadOnlyCode.defaultProps = {
  visible: false,
};

export default ReadOnlyCode;
