import { getPercentage } from 'lib/figma-to-mjml/utils';
import { getRightSpacingBySibling } from '../../bounding-box';

export function getHorizontalSpacing(sectionWidthPx, parent, node) {
  const spacingBetweenCols = getRightSpacingBySibling(parent, node.id);

  if (
    !parent.children ||
    // The current column is the last
    parent.children[parent.children.length - 1].id === node.id ||
    // There is no spacing
    spacingBetweenCols === 0
  ) {
    return [];
  }

  // Creates a new column for spacing
  const columnWidthPer = getPercentage(sectionWidthPx, spacingBetweenCols);
  const defaultSpacer = {
    tagName: 'mj-spacer',
    attributes: {
      height: '0px',
    },
  };

  return [
    {
      tagName: 'mj-column',
      attributes: {
        width: `${columnWidthPer}%`,
        'css-class': 'padB40',
      },
      children: [defaultSpacer],
    },
  ];
}
