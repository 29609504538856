import Spinner from '@scalero-inc/forno-spinner';
import styles from './styles.module.css';

export default function LoadingPlaceholder(props) {
  return (
    <div className={styles['loading-placeholder']}>
      <Spinner {...props} />
    </div>
  );
}
