/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Frame, { FrameContextConsumer } from 'react-frame-component';

import HeapTracker from 'components/heap-tracker';

/* eslint-disable import/no-unresolved */
import typoTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.typography.css';
import colorTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.colors.css';
import colorTokensDark from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/dark-theme/design-tokens.dark-colors.css';
import spacingTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.spacing.css';
import borderTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.border.css';
/* eslint-enable import/no-unresolved */

import styles from './style.module.css';

function EditorPreviewIframe({ children, disableLinks }) {
  function DisableAnchorTags({ document }) {
    const anchors = document.querySelectorAll('a');

    anchors.forEach((item) => {
      item.removeAttribute('href');
    });

    return null;
  }

  DisableAnchorTags.propTypes = {
    document: PropTypes.shape({
      querySelectorAll: PropTypes.func,
    }).isRequired,
  };

  return (
    <Frame
      className={styles['iframe-wrapper']}
      name="editor preview"
      initialContent={`
<!DOCTYPE html><html><head>
<script type="text/javascript">
      (window.heap = window.heap || []),
        (heap.load = function (e, t) {
          (window.heap.appid = e), (window.heap.config = t = t || {});
          var r = document.createElement('script');
          (r.type = 'text/javascript'),
            (r.async = !0),
            (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(r, a);
          for (
            var n = function (e) {
                return function () {
                  heap.push(
                    [e].concat(Array.prototype.slice.call(arguments, 0))
                  );
                };
              },
              p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty',
              ],
              o = 0;
            o < p.length;
            o++
          )
            heap[p[o]] = n(p[o]);
        });
</script>
</head><body><div></div></body></html>
`}
    >
      <style>{typoTokens}</style>
      <style>{colorTokens}</style>
      <style>{colorTokensDark}</style>
      <style>{spacingTokens}</style>
      <style>{borderTokens}</style>
      <FrameContextConsumer>
        {({ window, document }) => {
          return (
            <>
              {React.Children.map(children, (child) => {
                if (child) {
                  return React.cloneElement(child, {
                    window,
                    document,
                  });
                }
                return null;
              })}
              {disableLinks ? <DisableAnchorTags document={document} /> : null}
              <HeapTracker heap={window.heap} />
            </>
          );
        }}
      </FrameContextConsumer>
    </Frame>
  );
}

EditorPreviewIframe.propTypes = {
  children: PropTypes.node,
  disableLinks: PropTypes.bool,
};

EditorPreviewIframe.defaultProps = {
  children: null,
  disableLinks: true,
};

export default EditorPreviewIframe;
