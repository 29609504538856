import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const { REACT_APP_VERSION } = process.env;

const DEFAULT_PREFIX = 'Cannoli';
const DEFAULT_TITLE = `${DEFAULT_PREFIX} ${REACT_APP_VERSION}`;

function getTitle({ name, prefix, showPrefix }) {
  if (!showPrefix && !name) return DEFAULT_TITLE;
  if (!showPrefix) return name;
  if (!name) return prefix;
  return `${prefix} | ${name}`;
}

function SEO({ prefix, name, showPrefix }) {
  const title = getTitle({ prefix, name, showPrefix });
  return <Helmet title={title} />;
}

SEO.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  showPrefix: PropTypes.bool,
};

SEO.defaultProps = {
  name: null,
  prefix: DEFAULT_PREFIX,
  showPrefix: true,
};

export default SEO;
