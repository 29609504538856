import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';

function PublicRedirect() {
  const location = useLocation();
  return (
    <Navigate
      replace
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
}

export default PublicRedirect;
