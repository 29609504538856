export const COLUMN_ATTRIBUTES = [
  'width',
  'padding',
  'padding-top',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'background-color',
  'inner-background-color',
  'border',
  'border-bottom',
  'border-left',
  'border-right',
  'border-top',
  'border-radius',
  'inner-border',
  'inner-border-bottom',
  'inner-border-left',
  'inner-border-right',
  'inner-border-top',
  'inner-border-radius',
  'vertical-align',
  'css-class',
];
