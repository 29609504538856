import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';

import Tag from 'components/tag';
import Time from 'components/time';

import BillingPlanCta from './billing-plan-cta';
import styles from './style.module.css';
import BillingPlanSeats from './billing-plan-seats';
import { plans } from '../constants';

function BillingPlanSection({
  plan,
  status,
  subscriptionId,
  nextBilling,
  information,
  isAdmin,
  hasValidTrial,
  trialDaysLeft,
}) {
  const Icon = plan.icon;
  let tag;
  if (information.cancel_at_period_end) {
    tag = (
      <Tag status="danger">
        <FiAlertTriangle />
        <strong>Canceled</strong>
      </Tag>
    );
  } else if (plan.name === 'Starter') {
    tag = (
      <Tag status="success">
        <strong>Active</strong>
      </Tag>
    );
  } else {
    tag = <Tag status={status.status}>{status.label}</Tag>;
  }

  const currentPlan =
    hasValidTrial && plan.name === plans.starter.name ? plans.proTrial : plan;

  return (
    <section className={styles['billing-plan']}>
      <div className={styles['billing-plan-content']}>
        <div className={styles['billing-plan-current']}>
          <p>Your current plan</p>
          <div className={styles['billing-plan-current-title']}>
            <h2>
              <Icon />
              {currentPlan.name}
            </h2>
            {tag}
          </div>
          {hasValidTrial && plan.name === plans.starter.name && (
            <div className={styles['billing-plan-trial']}>
              <h3>Free trial</h3> ({trialDaysLeft} days left)
            </div>
          )}
          {/* won't show billing period if plan is starter or if plan is pending */}
          {currentPlan.name === plans.starter.name ||
          status.label === 'Pending' ||
          hasValidTrial ? null : (
            <div className={styles['billing-plan-current-info']}>
              {information.cancel_at_period_end ? (
                <p>
                  Your {currentPlan.name} plan will end on{' '}
                  <Time>{nextBilling}</Time>.
                </p>
              ) : (
                <>
                  <p>
                    Next payment: <Time>{nextBilling}</Time>
                  </p>
                  {currentPlan.name === 'Team' ? (
                    <p>
                      Team seats: <BillingPlanSeats teamId={information.team} />
                    </p>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
        <div className={styles['billing-features']}>
          <p>Features</p>
          <div className={styles['billing-features-list']}>
            {currentPlan.features.map((feature) => (
              <div key={feature.label}>
                <h4>{feature.plan}</h4>
                <p>{feature.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isAdmin && (
        <BillingPlanCta
          plan={plan}
          subscriptionId={subscriptionId}
          status={status}
          subscriptionCanceled={information.cancel_at_period_end}
          hasValidTrial={hasValidTrial}
        />
      )}
    </section>
  );
}

BillingPlanSection.propTypes = {
  status: PropTypes.shape({
    status: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.func,
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  subscriptionId: PropTypes.number.isRequired,
  nextBilling: PropTypes.string,
  information: PropTypes.shape({
    billing_interval: PropTypes.string,
    payment_method_brand: PropTypes.string,
    payment_method_last4: PropTypes.string,
    billing_email: PropTypes.string,
    cancel_at_period_end: PropTypes.bool,
    invoice_list: PropTypes.arrayOf(PropTypes.shape({})),
    team: PropTypes.number,
  }).isRequired,
  hasValidTrial: PropTypes.bool,
  trialDaysLeft: PropTypes.number,
};
BillingPlanSection.defaultProps = {
  nextBilling: null,
  hasValidTrial: false,
  trialDaysLeft: 0,
};
export default BillingPlanSection;
