import { useParams, Navigate, Route, Routes } from 'react-router-dom';
import { useCallback, useState } from 'react';

import Spinner from '@scalero-inc/forno-spinner';

import { useUserQuery } from 'hooks/api/users';
import { useProjectQuery } from 'hooks/api/projects';
import { USER_SUBSCRIPTION_STATUS } from 'hooks/api/users/constants';

import DashboardProjectFiles from './dashboard-project-files';
import DashboardProjectInfo from './dashboard-project-info';
import DashboardImportFile from '../dashboard-import-file';
import styles from './style.module.css';
import SubscriptionAlertBanner from '../subscription-alert-banner';
import DashboardTemplates from '../dashboard-templates';
import DashboardTemplateEditor from '../dashboard-template-editor';

function DashboardProject() {
  const { teamId, projectId } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { isLoading: isUserLoading, data: user } = useUserQuery();
  const { isLoading: isProjectLoading, data: project } = useProjectQuery({
    teamId,
    projectId,
  });

  if (isUserLoading || isProjectLoading) return <Spinner />;

  if (!project) return <p>Project not found</p>;

  const subscription = user.team_subscriptions.find(
    (currentSub) => currentSub.team === +teamId
  );
  const subscriptionStatus = subscription?.status || null;
  const showBanner =
    (isOpen && subscription?.status === USER_SUBSCRIPTION_STATUS.PENDING) ||
    (isOpen && subscription?.status === USER_SUBSCRIPTION_STATUS.OVERDUE);

  return (
    <div className={styles['dashboard-project']}>
      {showBanner ? (
        <SubscriptionAlertBanner
          className={styles['dashboard-banner']}
          status={subscription?.status}
          subscriptionId={subscription?.id}
          close={close}
        />
      ) : null}
      <header className={styles['dashboard-header']}>
        <h2>{project?.name}</h2>
      </header>
      <Routes>
        <Route path="files/import" element={<DashboardImportFile />} />
        <Route path="templates/create" element={<DashboardTemplateEditor />} />
        <Route
          path="files"
          element={
            <DashboardProjectFiles subscriptionStatus={subscriptionStatus} />
          }
        />
        <Route
          path="templates"
          element={<DashboardTemplates teamId={teamId} projectId={projectId} />}
        />
        <Route
          path="info"
          element={<DashboardProjectInfo project={project} teamId={+teamId} />}
        />
        <Route path="" element={<Navigate to="files" />} />
      </Routes>
    </div>
  );
}

export default DashboardProject;
