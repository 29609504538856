function MjGroup({ children, attributes }) {
  return [
    {
      tagName: 'mj-group',
      children,
      attributes: {
        ...attributes,
      },
    },
  ];
}

export default MjGroup;
