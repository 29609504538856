import PropTypes from 'prop-types';

import { useUserQuery } from 'hooks/api/users';

import { canCreateEditDeleteTeamSubscription } from 'utils/permissions';
import { USER_SUBSCRIPTION_TIER } from 'hooks/api/users/constants';

import styles from './style.module.css';
import PricingCardStarter from './pricing-card-starter';
import PricingCardPro from './pricing-card-pro';
import PricingCardTeam from './pricing-card-team';
import PricingCardTeamWrapper from './pricing-card-team-wrapper';
import PricingCardProTrial from './pricing-card-pro-trial';

function PricingCardsPrivate({ teamId, type }) {
  const { data: user, isLoading } = useUserQuery();

  if (isLoading) return <p>Loading...</p>;

  const blocked = !canCreateEditDeleteTeamSubscription({ user, teamId });
  const {
    has_valid_trial: hasValidTrial,
    is_elegible_for_trial: isElegibleForTrial,
  } = user;

  if (teamId && blocked) {
    return (
      <div>
        You don&apos;t have the necessary permissions to create a team
        subscription. Please contact a team Admin to gain access.{' '}
      </div>
    );
  }

  if (teamId && type === 'create') {
    return (
      <div className={styles['pricing-card-standalone']}>
        <PricingCardTeamWrapper teamId={teamId} type={type} />
      </div>
    );
  }

  return (
    <div className={styles['pricing-cards']}>
      <PricingCardTeam
        activePlan={user.subscriptions?.tier === USER_SUBSCRIPTION_TIER.TEAM}
        type={type}
      />

      {(isElegibleForTrial &&
        user.subscriptions?.tier === USER_SUBSCRIPTION_TIER.FREE) ||
      hasValidTrial ? (
        <PricingCardProTrial
          activePlan={hasValidTrial}
          trialDaysLeft={user?.trial_days_left}
          type={type}
          teamId={teamId}
        />
      ) : (
        <PricingCardPro
          activePlan={user.subscriptions?.tier === USER_SUBSCRIPTION_TIER.PRO}
          type={type}
          teamId={teamId}
        />
      )}

      <PricingCardStarter
        activePlan={user.subscriptions?.tier === USER_SUBSCRIPTION_TIER.FREE}
        type={type}
        teamId={teamId}
        hasValidTrial={hasValidTrial}
      />
    </div>
  );
}

PricingCardsPrivate.propTypes = {
  teamId: PropTypes.number,
  type: PropTypes.string.isRequired,
};

PricingCardsPrivate.defaultProps = {
  teamId: null,
};

export default PricingCardsPrivate;
