import json2mjml from 'json2mjml';

import figmaToMjml from 'lib/figma-to-mjml';
import { extractParseMessages } from 'lib/figma-to-mjml/parser';
import {
  fileWaitForFileBackendProcessedQuery,
  fileWaitForFileProcessedQuery,
  fileQuery,
} from './queries';

export async function fileUpdateMutation({ client, fileId, values }) {
  const { data } = await client.patch(`figma/files/${fileId}/`, values);
  return data;
}

export async function fileDeleteMutation({ client, fileId }) {
  const { data } = await client.delete(`figma/files/${fileId}/`);
  return data;
}

export async function fileGenerateMjml({
  client,
  fileId,
  images,
  nodeImages,
  doc,
}) {
  const mjmlJson = figmaToMjml([doc], images[0], nodeImages[0]);
  const errors = extractParseMessages(mjmlJson);
  const mjml = json2mjml(mjmlJson);
  const data = await fileUpdateMutation({
    client,
    fileId,
    values: {
      mjml,
      errors,
    },
  });

  return data;
}

export async function fileRefreshMutation({ client, fileId }) {
  const res = await client.post(`figma/files/${fileId}/refresh/`);
  return res;
}

export async function fileProcessMutation({ client, fileId }) {
  const res = await fileWaitForFileProcessedQuery({ client, fileId });
  const { node, images, node_images: nodeImages } = res;
  const doc = res.data.nodes[node].document;

  const data = await fileGenerateMjml({
    client,
    fileId,
    images,
    nodeImages,
    doc,
  });
  return data;
}

export async function fileCreateMutation({ client, values, team, project }) {
  const { data } = await client.post(`figma/files/`, {
    team,
    project,
    ...values,
  });
  return data;
}

export async function fileCreateFromTemplateMutation({
  client,
  templateId,
  teamId,
  projectId,
  values,
}) {
  const { data } = await client.post(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}/create_file/`
      : `templates/${templateId}/create_file/`,
    {
      ...values,
    }
  );
  return data;
}

export async function updatePreviewMutation({ client, fileId, html }) {
  const data = { html };
  const res = await client.put(
    `figma/files/${fileId}/update_preview_from_html/`,
    data
  );
  return res;
}

export async function fileBackendProcessMutation({ client, fileId }) {
  const res = await fileWaitForFileBackendProcessedQuery({ client, fileId });
  try {
    const {
      parsed_mjml: parsedMjml,
      successfully_processed: successfullyProcessed,
    } = res;
    const mjml = parsedMjml;
    const data = await fileUpdateMutation({
      client,
      fileId,
      values: {
        mjml,
      },
    });
    data.successfullyProcessed = successfullyProcessed || false;
    return data;
  } catch (error) {
    return error;
  }
}

export async function fileDuplicateMutation({ client, fileId }) {
  const file = await fileQuery({ client, fileId });

  const { name, node, url, team, project } = file;
  const projectId = project?.id || null;
  const newName = `Copy of ${name}`;
  const values = { name: newName, node, url, team, project: projectId };

  const createResponse = await fileCreateMutation({ client, values });

  const processResponse = await fileBackendProcessMutation({
    client,
    fileId: createResponse.id,
  });

  return processResponse;
}

export async function fileExportToEspMutation({ client, espId, fileId, name }) {
  const payload = { name };
  const res = await client.post(
    `figma/files/${fileId}/export/${espId}/`,
    payload
  );
  return res;
}

export async function fileSendTestEmailMutation({ client, fileId, email }) {
  const payload = { recipient: email };
  const res = await client.post(`figma/files/${fileId}/send_mail/`, payload);
  return res;
}

export async function fileShareHashCreateMutation({ client, fileId }) {
  const { data } = await client.post(`figma/files/${fileId}/share_hash/`);
  return data;
}

export async function fileShareHashDeleteMutation({ client, fileId }) {
  const { data } = await client.delete(`figma/files/${fileId}/share_hash/`);
  return data;
}

export async function uploadImageMutation({ client, fileId, file }) {
  const { name } = file || {};
  const bodyFormData = new FormData();
  bodyFormData.append('file', fileId);
  bodyFormData.append('image', file || '');
  return client.post('/images/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': `attachment; filename=${name}`,
    },
  });
}
