import { useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { plans } from 'containers/dashboard-billing/constants';
import PaymentStatusModal from 'containers/payment-status-modal';
import AccountStatusModal from 'containers/account-status-modal';

function PaymentStatus() {
  const [searchParams] = useSearchParams();
  const paymentParam = searchParams.get('payment');
  const teamParam = searchParams.get('team');

  // Payment status modal
  const [isOpen, setIsOpen] = useState(
    paymentParam === 'successful' || paymentParam === 'canceled'
  );
  const [payment] = useState(paymentParam);
  const [team] = useState(teamParam);
  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // Account status modal
  const [accountIsOpen, setAccountIsOpen] = useState(
    payment === plans.proTrial.slug
  );
  const closeAccountModal = useCallback(() => {
    setAccountIsOpen(false);
  }, [setAccountIsOpen]);

  return (
    <>
      <PaymentStatusModal
        isOpen={isOpen}
        close={close}
        team={team}
        status={payment}
      />
      <AccountStatusModal isOpen={accountIsOpen} close={closeAccountModal} />
    </>
  );
}

export default PaymentStatus;
