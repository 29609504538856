/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Frame, { FrameContextConsumer } from 'react-frame-component';

import HeapTracker from 'components/heap-tracker';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import typoTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.typography.css';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import colorTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.colors.css';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import colorTokensDark from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/dark-theme/design-tokens.dark-colors.css';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import spacingTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.spacing.css';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import borderTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.border.css';

import styles from './styles.module.css';

function CardPreviewIframe({ children }) {
  function DisableAnchorTags({ document }) {
    const anchors = document.querySelectorAll('a');

    anchors.forEach((item) => {
      item.removeAttribute('href');
    });

    return null;
  }

  DisableAnchorTags.propTypes = {
    document: PropTypes.shape({
      querySelectorAll: PropTypes.func,
    }).isRequired,
  };

  return (
    <div className={styles['card-iframe-wrapper']}>
      <Frame
        className={styles['card-iframe']}
        name="editor preview"
        initialContent={`
<!DOCTYPE html><html><head>
<script type="text/javascript">
    (window.heap = window.heap || []),
      (heap.load = function (e, t) {
        (window.heap.appid = e), (window.heap.config = t = t || {});
        var r = document.createElement('script');
        (r.type = 'text/javascript'),
          (r.async = !0),
          (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(r, a);
        for (
          var n = function (e) {
              return function () {
                heap.push(
                  [e].concat(Array.prototype.slice.call(arguments, 0))
                );
              };
            },
            p = [
              'addEventProperties',
              'addUserProperties',
              'clearEventProperties',
              'identify',
              'resetIdentity',
              'removeEventProperty',
              'setEventProperties',
              'track',
              'unsetEventProperty',
            ],
            o = 0;
          o < p.length;
          o++
        )
          heap[p[o]] = n(p[o]);
      });
</script>
</head><body><div></div></body></html>
`}
      >
        <style>{typoTokens}</style>
        <style>{colorTokens}</style>
        <style>{colorTokensDark}</style>
        <style>{spacingTokens}</style>
        <style>{borderTokens}</style>
        <FrameContextConsumer>
          {({ window, document }) => {
            const contentContainer = document.createElement('div');
            while (document.body.firstChild) {
              contentContainer.appendChild(document.body.firstChild);
            }
            document.body.appendChild(contentContainer);

            document.body.style.overflow = 'hidden';
            document.body.style.msOverflowStyle = 'none'; // IE 10+
            document.body.style.scrollbarWidth = 'none'; // Firefox
            document.body.style.scrollbarHeight = 'none'; // Firefox
            document.body.style.transformOrigin = '0 0';
            document.body.style.transform = 'scale(0.5)';

            return (
              <>
                {React.Children.map(children, (child) => {
                  if (child) {
                    return React.cloneElement(child, {
                      window,
                      document,
                    });
                  }
                  return null;
                })}
                <DisableAnchorTags document={document} />
                <HeapTracker window={window} />
              </>
            );
          }}
        </FrameContextConsumer>
      </Frame>
    </div>
  );
}

CardPreviewIframe.propTypes = {
  children: PropTypes.node,
};

CardPreviewIframe.defaultProps = {
  children: null,
};

export default CardPreviewIframe;
