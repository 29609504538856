import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';

import { useUserQuery } from 'hooks/api/users';

import {
  useTeamMemberAddMutation,
  useTeamMembersInvalidateQuery,
} from 'hooks/api/teams';

import { canAddEditDeleteTeamMembers } from 'utils/permissions';

import schema from './schema';
import styles from './style.module.css';

function TeamMemberForm({ teamId, onSuccess }) {
  const { data: user } = useUserQuery();
  const blocked = !canAddEditDeleteTeamMembers({ user, teamId });

  const invalidate = useTeamMembersInvalidateQuery({ teamId });

  const { mutate, isLoading } = useTeamMemberAddMutation({
    teamId,
    config: {
      onSuccess: (data) => {
        toast.success(`User "${data.email}" added to team succesfully`);
        invalidate();
        onSuccess();
      },
      onError: () => {
        toast.error(
          `Error: check if the user has an account or if they have already been added.`
        );
      },
    },
  });

  const handleSubmit = (values) => {
    mutate({ email: values.email });
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className={styles['form-wrapper']}>
          <div className={styles['field-wrapper']}>
            <Field id="email" name="email">
              {({ field }) => (
                <Input
                  {...field}
                  theme="light"
                  placeholder="enter user's email"
                  size="s"
                  type="email"
                  className={styles['input-field']}
                  disabled={blocked}
                />
              )}
            </Field>
          </div>
          <Button
            type="submit"
            loading={isLoading}
            disabled={!isValid || isLoading || blocked}
          >
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
}

TeamMemberForm.propTypes = {
  teamId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
};

TeamMemberForm.defaultProps = {
  onSuccess: () => {},
};

export default TeamMemberForm;
