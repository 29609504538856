import React from 'react';
import { useLocation } from 'react-router-dom';

import PricingCardBase from 'containers/pricing-cards-private/pricing-card-base';
import { plans } from 'containers/dashboard-billing/constants';
import { useAuth } from 'context/auth';

import styles from './styles.module.css';

function PricingSection() {
  const location = useLocation();
  const { user } = useAuth();
  const getPlanURL = (plan) => {
    if (user) {
      return '/dashboard/settings/billing';
    }

    return `/login?create-account=${plan}`;
  };

  React.useEffect(() => {
    if (location.hash === '#pricing') {
      const pricing = document.getElementById('app-pricing');
      if (pricing) pricing.scrollIntoView();
    } else {
      const root = document.getElementById('root');
      if (root) root.scrollIntoView();
    }

    return () => {};
  }, [location]);

  return (
    <section id="app-pricing" className={styles['pricing-section']}>
      <div className={styles['pricing-container']}>
        <h2>Pricing</h2>
        <p className="paragraph-l">
          Whether you work by yourself or in a team, there’s a way Cannoli can
          help accelerate your email workflow.
        </p>

        <div className={styles['pricing-cards-container']}>
          <PricingCardBase
            plan={plans.team}
            activePlan={false}
            highlight="Full featured collaboration workflow"
            cta="Choose Team"
            href={getPlanURL(plans.team.slug)}
          />

          <div className={styles['solo-creators-container']}>
            <p className="paragraph-r">For solo email creators</p>

            <div className={styles['solo-creators-plans']}>
              <PricingCardBase
                plan={plans.proTrial}
                activePlan={false}
                cta="Choose Pro"
                href={getPlanURL(plans.proTrial.slug)}
              />
              <PricingCardBase
                plan={plans.starter}
                activePlan={false}
                cta="Choose Starter"
                hierarchy="secondary"
                href={getPlanURL(plans.starter.slug)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;
