export const FILES_QUERY_KEY = 'files';
export const FILES_PAGINATED_QUERY_KEY = 'files-paginated';
export const FILE_QUERY_KEY = 'file';
export const FILES_PROCESSED_QUERY_KEY = 'files-processed';
export const USER_QUERY_KEY = 'user';
export const TEAMS_QUERY_KEY = 'teams';
export const TEAM_MEMBERS_QUERY_KEY = 'team-members';
export const TEAM_PROJECTS_QUERY_KEY = 'team-projects';
export const FILES_BY_TEAM_PROJECT_QUERY_KEY = 'files-team-project';
export const PROJECT_QUERY_KEY = 'project';
export const TEAM_QUERY_KEY = 'team';
export const ESPS_QUERY_KEY = 'esps';
export const SUBSCRIPTION_QUERY_KEY = 'subscription';
export const FILE_SHARE_HASH_QUERY_KEY = 'file-share-hash';
export const COMPONENTS_QUERY_KEY = 'components';
export const COMPONENT_QUERY_KEY = 'component';
export const TEAM_COMPONENT_QUERY_KEY = 'team-component';
export const USER_COMPONENTS_PAGINATED_QUERY_KEY = 'user-components-paginated';
export const TEAM_COMPONENTS_PAGINATED_QUERY_KEY = 'team-components-paginated';
export const FONTS_QUERY_KEY = 'fonts';
export const USER_FONT_STACKS_KEY = 'user-font-stacks';
export const TEAM_FONT_STACKS_KEY = 'team-font-stacks';
export const FONT_VARIANTS_KEY = 'font-variants';
export const WEB_SAFE_FONTS_KEY = 'web-safe-fonts';
export const TEMPLATES_QUERY_KEY = 'templates';
export const TEMPLATE_COMPONENTS_QUERY_KEY = 'template-components';
export const READ_ONLY_FILE_KEY = 'read-only-file';
export const REGEN_COMPARE_QUERY_KEY = 'regen-compare';
