import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Spinner from '@scalero-inc/forno-spinner';

import { useTeamQuery } from 'hooks/api/teams';
import { useUserQuery } from 'hooks/api/users';
import { USER_SUBSCRIPTION_STATUS } from 'hooks/api/users/constants';
import { useTeamProjectsQuery } from 'hooks/api/projects';

import styles from './style.module.css';
import DashboardTeamMembers from './dashboard-team-members';
import DashboardTeamProjects from './dashboard-team-projects';
import DashboardTeamInfo from './dashboard-team-info';
import DashboardTeamBilling from './dashboard-team-billing';
import SubscriptionAlertBanner from '../subscription-alert-banner';
import DashboardComponents from '../dashboard-components';
import DashboardFallbackStacks from '../dashboard-resources/dashboard-fallback-stacks';
import DashboardCustomFonts from '../dashboard-resources/dashboard-custom-fonts';
import DashboardTemplateEditor from '../dashboard-template-editor';

function DashboardTeam() {
  const [isOpen, setIsOpen] = useState(true);
  const { teamId } = useParams();
  const userQuery = useUserQuery();
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const projectsQuery = useTeamProjectsQuery({ team: teamId });
  const teamQuery = useTeamQuery({ teamId });
  const navigate = useNavigate();
  useEffect(() => {
    if (!teamId || teamId === 'null') {
      navigate('/dashboard/personal/files');
    }
  }, [navigate, teamId]);

  if (projectsQuery.isLoading || userQuery.isLoading || teamQuery.isLoading)
    return <Spinner />;

  if (!projectsQuery.data) return <p>Team not found</p>;

  const subscription = userQuery.data.team_subscriptions.find(
    (currentSub) => currentSub.team === +teamId
  );

  const showBanner =
    (isOpen && subscription?.status === USER_SUBSCRIPTION_STATUS.PENDING) ||
    (isOpen && subscription?.status === USER_SUBSCRIPTION_STATUS.OVERDUE);

  return (
    <>
      {showBanner ? (
        <SubscriptionAlertBanner
          className={styles['dashboard-banner']}
          status={subscription?.status}
          subscriptionId={subscription?.id}
          close={close}
        />
      ) : null}
      <header className={styles['dashboard-header']}>
        <h2>{teamQuery.data.name}</h2>
      </header>
      <Routes>
        <Route
          path="projects"
          element={
            <DashboardTeamProjects
              projects={projectsQuery.data}
              subscriptionStatus={subscription?.status || null}
            />
          }
        />
        <Route path="templates/create" element={<DashboardTemplateEditor />} />
        <Route
          path="blocks"
          element={<DashboardComponents teamId={teamId} />}
        />
        <Route
          path="info"
          element={<DashboardTeamInfo team={teamQuery.data} />}
        />
        <Route
          path="members"
          element={
            <DashboardTeamMembers
              teamId={teamQuery.data.id}
              user={userQuery.data}
              userLoading={userQuery.isLoading}
            />
          }
        />
        <Route path="billing" element={<DashboardTeamBilling />} />
        <Route path="" element={<Navigate to="projects" />} />
        <Route path="resources/" element={<DashboardFallbackStacks />} />
        <Route
          path="resources/font-stacks"
          element={<DashboardFallbackStacks />}
        />
        <Route
          path="resources/custom-fonts"
          element={<DashboardCustomFonts />}
        />
      </Routes>
    </>
  );
}

export default DashboardTeam;
