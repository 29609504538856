import PropTypes from 'prop-types';
import classnames from 'classnames';
import Split from 'react-split-grid';

import ResizeGutter from 'components/resize-gutter';

import EditorCode from '../editor-code';
import EditorPreview from '../editor-preview';

import { useEditorView, useEditorViewDispatch } from '../editor-view-context';

import styles from './style.module.css';

function CodeEditorContent({ className }) {
  const { viewColumns } = useEditorView();
  const { setViewColumns } = useEditorViewDispatch();

  const customClassName = classnames(
    styles['editor-content-container'],
    className
  );

  const handleDrag = (direction, track, gridTemplateStyle) => {
    setViewColumns(gridTemplateStyle);
  };

  const handleDragEnd = () => {
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <div className={styles['editor-content']}>
      <Split
        minSize={0}
        dragInterval={10}
        snapOffset={30}
        cursor="col-resize"
        gridTemplateRows="1fr"
        gridTemplateColumns={viewColumns}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        render={({ getGridProps, getGutterProps }) => {
          return (
            <div className={customClassName} {...getGridProps()}>
              <EditorCode />
              <ResizeGutter {...getGutterProps('column', 1)} />
              <EditorPreview />
            </div>
          );
        }}
      />
    </div>
  );
}

CodeEditorContent.propTypes = {
  className: PropTypes.string,
};

CodeEditorContent.defaultProps = {
  className: null,
};

export default CodeEditorContent;
