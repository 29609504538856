import Input from '@scalero-inc/forno-input';
import PropTypes from 'prop-types';
import { FiExternalLink, FiXCircle } from 'react-icons/fi';
import IconButton from '@scalero-inc/forno-icon-button';
import { toast } from 'react-toastify';
import FileUpload from 'components/file-upload';
import { useUploadImageMutation } from 'hooks/api/files';
import { useEditor } from 'pages/editor/editor-context';

import styles from './styles.module.css';

function InputUrl({
  label,
  placeholder,
  field,
  isValid,
  handleChange,
  attribute,
}) {
  const { fileId, sourceFileId } = useEditor();
  const { mutate, isLoading } = useUploadImageMutation({
    fileId: fileId || sourceFileId,
    config: {
      onSuccess: ({ data }) => {
        const { image } = data;
        handleChange(image);
        toast.success('Image uploaded successfully');
      },
      onError: () => {
        toast.error('Unexpected error uploading image');
      },
    },
  });

  const urlValue = field.value.trim();
  const isUrlAvailable = isValid && urlValue !== '' && urlValue !== '#';
  const openUrl = (url) => {
    if (isUrlAvailable) {
      window.open(url, '_blank');
    }
  };

  const clearButton = () => {
    return <FiXCircle onClick={() => handleChange('')} />;
  };

  return (
    <>
      <div className={styles['input-url-container']}>
        <label htmlFor={label}>{label}</label>

        <div className={styles['input-url']}>
          <div>
            <Input
              {...field}
              placeholder={placeholder}
              size="s"
              theme="dark"
              variant="subtle"
              onChange={(event) => {
                handleChange(event.target.value);
              }}
              sufix={clearButton}
              warningText={!isValid ? 'Invalid value' : null}
            />
          </div>

          <div>
            <IconButton
              hierarchy="tertiary"
              theme="dark"
              onClick={() => openUrl(field.value)}
              disabled={!isUrlAvailable}
            >
              <FiExternalLink />
            </IconButton>
          </div>
        </div>
      </div>

      {attribute === 'src' && (
        <FileUpload
          className={styles['upload-button']}
          onFile={mutate}
          accept="image/png, image/gif, image/jpeg"
          disabled={isLoading}
          withIcon
          hierarchy="tertiary"
          theme="dark"
          size="s"
          isLoading={isLoading}
        >
          Upload and replace
        </FileUpload>
      )}
    </>
  );
}

InputUrl.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({ value: PropTypes.string }).isRequired,
  isValid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
};

InputUrl.defaultProps = {
  label: '',
  placeholder: '',
};

export default InputUrl;
