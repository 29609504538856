import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';

import { useTeamDeleteMutation } from 'hooks/api/teams';
import { useUserInvalidateQuery } from 'hooks/api/users';

function TeamDeleteModal({ isOpen, close, team, theme }) {
  const invalidate = useUserInvalidateQuery();
  const navigate = useNavigate();

  const { mutate } = useTeamDeleteMutation({
    teamId: team.id,
    config: {
      onSuccess: () => {
        toast.success('Deleted team successfully');
        invalidate();
        navigate('/dashboard');
        close();
      },
      onError: () => {
        toast.error('There was an error deleting your team');
      },
    },
  });

  const handleDelete = () => {
    mutate();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="edit team modal"
      theme={theme}
      status="danger"
    >
      <Modal.Header title={`Delete team "${team.name}"`} onDismiss={close} />
      <p>
        Are you sure you want to delete your team? This action{' '}
        <strong>cannot</strong> be undone.
      </p>
      <Modal.Footer>
        <Button onClick={close} theme={theme} hierarchy="tertiary">
          Cancel
        </Button>
        <Button onClick={handleDelete} theme={theme} status="danger">
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

TeamDeleteModal.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

TeamDeleteModal.defaultProps = {
  isOpen: false,
  theme: 'light',
};

export default TeamDeleteModal;
