import { Field, ErrorMessage } from 'formik';

import Input from '@scalero-inc/forno-input';

import styles from './style.module.css';

function NewTeamForm() {
  return (
    <div className={styles['new-team-form']}>
      <div className={styles['new-team-form-field']}>
        <Field id="name" name="name">
          {({ field }) => <Input {...field} size="s" label="Team name" />}
        </Field>
        <ErrorMessage
          name="name"
          render={(msg) => (
            <div className={styles['new-team-form-error']}>{msg}</div>
          )}
        />
      </div>
      <div className={styles['new-team-form-field']}>
        <Field id="seats" name="seats">
          {({ field }) => (
            <Input {...field} type="number" label="Number of seats" size="s" />
          )}
        </Field>
        <ErrorMessage
          name="seats"
          render={(msg) => (
            <div className={styles['new-team-form-error']}>{msg}</div>
          )}
        />
      </div>
    </div>
  );
}

export default NewTeamForm;
