import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFolder } from 'react-icons/fi';

import Time from 'components/time';

import styles from './style.module.css';

function ProjectRow({ name, id, dateUpdated, preview }) {
  const url = `projects/${id}`;
  const customClassName = classnames('file-row', styles['file-row']);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url);
  };

  return (
    <tr className={customClassName} onClick={handleClick}>
      <td className={styles['file-preview-cell']}>
        {preview ? (
          <img src={preview} alt={name} />
        ) : (
          <div className={styles['row-placeholder-image']}>
            <FiFolder />
          </div>
        )}
      </td>
      <td className={styles['file-name-cell']}>{name}</td>
      <td className={styles['file-date-updated-cell']}>
        {dateUpdated ? <Time>{dateUpdated}</Time> : 'NA'}
      </td>
    </tr>
  );
}

ProjectRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string,
  preview: PropTypes.string,
  team: PropTypes.shape({
    id: PropTypes.number,
  }),
};

ProjectRow.defaultProps = {
  dateUpdated: new Date().toISOString(),
  preview: null,
  team: null,
};

export default ProjectRow;
