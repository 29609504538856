import PropTypes from 'prop-types';
import classnames from 'classnames';

import { parseISO } from 'date-fns';
import formatDate from 'utils/dates';

import styles from './style.module.css';

function Time({ className, children, formatStr }) {
  const date = parseISO(children);
  const formatted = formatDate(date, formatStr);
  const customClassName = classnames('time', styles.time, className, {});
  return <time className={customClassName}>{formatted}</time>;
}
Time.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  formatStr: PropTypes.string,
};

Time.defaultProps = {
  formatStr: undefined,
  className: null,
};

export default Time;
