import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { plans } from 'containers/dashboard-billing/constants';
import { useUserInvalidateQuery } from 'hooks/api/users';
import { useFreeProTrialSubscriptionCreateMutation } from 'hooks/api/subscriptions';
import PricingCardBase from '../pricing-card-base';

function PricingCardProTrial({ activePlan, type, trialDaysLeft }) {
  const navigate = useNavigate();
  const Icon = plans.proTrial.icon;
  let message;
  if (type === 'update') message = 'Start your free trial';
  if (type === 'create') message = 'Start your free trial';
  if (activePlan) message = 'Current Plan';

  const cta = (
    <>
      <Icon /> {message}
    </>
  );

  const invalidateUser = useUserInvalidateQuery();
  const freeProTrialSub = useFreeProTrialSubscriptionCreateMutation({
    config: {
      onSuccess: () => {
        invalidateUser();
        toast.success('Your free trial has started!');
        navigate('/dashboard/settings/billing');
      },
      onError: () =>
        toast.error(
          'There was an error creating your subscription. Please try again later.'
        ),
    },
  });

  const handleClick = () => {
    freeProTrialSub.mutate();
  };

  return (
    <PricingCardBase
      plan={plans.proTrial}
      activePlan={activePlan}
      cta={cta}
      onClick={handleClick}
      ctaLook="outline"
      hasValidTrial={activePlan}
      trialDaysLeft={trialDaysLeft}
    />
  );
}

PricingCardProTrial.propTypes = {
  activePlan: PropTypes.bool,
  type: PropTypes.string.isRequired,
  trialDaysLeft: PropTypes.number,
};

PricingCardProTrial.defaultProps = {
  activePlan: false,
  trialDaysLeft: 0,
};

export default PricingCardProTrial;
