import { getBackgroundImage } from 'lib/figma-to-mjml/image';
import { getFillColor } from '../../color';
import { getBorderRadiusAttributes, getBorder } from '../../border';
import { getPadding } from '../../bounding-box';
import { getErrors } from '../auto-layout';
import { getCssClass, getVerticalSpacing, prepareChildren } from './utils';

function MjSection({
  attributes,
  parent,
  children,
  fills,
  rectangleCornerRadii,
  tagName,
  images,
  ...rest
}) {
  const childrenComponents = prepareChildren(children, rest);
  const background = getFillColor({ fills });
  const backgroundImage = getBackgroundImage(fills, images);
  const padding = getPadding(parent, rest, tagName);
  const border = getBorder({ ...rest });
  const cssClass = getCssClass(children, rest);
  const verticalSpacing = getVerticalSpacing(parent, rest);
  const errorList = getErrors(rest);

  return [
    {
      tagName: 'mj-section',
      errors: errorList,
      children: childrenComponents,
      attributes: {
        ...attributes,
        ...(cssClass && cssClass),
        ...border,
        ...(padding && padding),
        ...(background && background),
        ...(backgroundImage && backgroundImage),
        ...getBorderRadiusAttributes({ rectangleCornerRadii }),
      },
    },
    ...verticalSpacing,
  ];
}

export default MjSection;
