import 'ace-builds/src-noconflict/theme-monokai';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-xml';

import 'ace-builds/src-noconflict/keybinding-vscode';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/ext-language_tools';

// https://github.com/securingsincity/react-ace/issues/725
const ace = require('ace-builds/src-noconflict/ace');

ace.config.set(
  'basePath',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/'
);
ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-javascript.js'
);
ace.config.setModuleUrl(
  'ace/mode/html_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-html.js'
);
ace.config.setModuleUrl(
  'ace/mode/json_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-json.js'
);
