import { Splide, SplideSlide } from '@splidejs/react-splide';
import Button from '@scalero-inc/forno-button';
import { useNavigate } from 'react-router-dom';

import { steps } from './data';
import WorkflowStep from './workflow-step';

import '@splidejs/splide/dist/css/splide.min.css';
import styles from './styles.module.css';

function WorkflowSection() {
  const navigate = useNavigate();

  return (
    <section className={styles['cannoli-workflow-section']}>
      <div className={styles['cannoli-workflow-container']}>
        <h2>Cannoli workflow</h2>

        <div className={styles['workflow-grid-desktop']}>
          {steps.map((step, index) => {
            const { name, description, image, link } = step;
            return (
              <WorkflowStep
                key={`step_desktop_${index}`}
                step={index + 1}
                name={name}
                description={description}
                image={image}
                link={link}
              >
                {description}
              </WorkflowStep>
            );
          })}
        </div>

        <div className={styles['workflow-grid-mobile']}>
          <Splide
            options={{
              arrows: false,
            }}
          >
            {steps.map((step, index) => {
              const { name, description, image, link } = step;

              return (
                <SplideSlide key={`step_mobile_${index}`}>
                  <WorkflowStep
                    key={`step_mobile_${index}`}
                    step={index + 1}
                    name={name}
                    description={description}
                    image={image}
                    link={link}
                  >
                    {description}
                  </WorkflowStep>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>

        <p className="paragraph-l">
          You can skip these steps and we can do it for you.
        </p>

        <Button
          hierarchy="secondary"
          size="l"
          onClick={() => {
            navigate(`/services`);
          }}
        >
          Explore Cannoli Services
        </Button>
      </div>
    </section>
  );
}

export default WorkflowSection;
