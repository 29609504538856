import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';

import { useUserQuery } from 'hooks/api/users';
import { useSubsciptionUpdatePaymentMethod } from 'hooks/api/subscriptions';

import { canCreateEditDeleteTeamSubscription } from 'utils/permissions';

import { FiAlertTriangle } from 'react-icons/fi';
import styles from './style.module.css';

function OverduePaymentAlert({ subscriptionId }) {
  const { mutate } = useSubsciptionUpdatePaymentMethod({
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      onError: () =>
        toast.error(
          'There was an error redirecting you to the payment details. Try again later'
        ),
    },
    subscriptionId,
  });
  const { data: user } = useUserQuery();
  const teamSubscription = user.team_subscriptions?.find(
    (sub) => sub.id === subscriptionId
  );
  const teamId = teamSubscription?.team;

  const blocked = teamSubscription
    ? !canCreateEditDeleteTeamSubscription({ user, teamId })
    : false;

  return (
    <div className={styles['subscription-alert-banner-content']}>
      <FiAlertTriangle />
      <p>Update your payment method to access all features</p>
      <Button
        size="s"
        hierarchy="tertiary"
        onClick={() => mutate()}
        disabled={blocked}
      >
        Update now
      </Button>
    </div>
  );
}

OverduePaymentAlert.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
};

export default OverduePaymentAlert;
