import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState } from 'react';
import { getTagInfoFromId } from 'utils/mjml/util';
import ErrorMessage from '../error-message';

import styles from './styles.module.css';

function ReplaceSection({ children, isValid, tagId, action }) {
  const [isDragging, setIsDragging] = useState(false);
  const { tagName } = getTagInfoFromId(tagId);
  const overlayClassName = classNames(
    styles.overlay,
    isValid ? styles.success : styles.error
  );

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    if (isValid) {
      action(tagId);
    }
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  return (
    <div onDragOver={onDragOver} onDrop={onDrop} onDragLeave={onDragLeave}>
      <div className={styles.container}>
        {isDragging && (
          <div
            data-theme={isValid ? 'dark' : 'light'}
            className={overlayClassName}
          >
            <span>{tagName}</span>
          </div>
        )}
        {children}
      </div>
      {!isValid && isDragging && (
        <ErrorMessage content="mj-wrappers inside mj-wrappers are not allowed" />
      )}
    </div>
  );
}

ReplaceSection.propTypes = {
  children: PropTypes.node,
  isValid: PropTypes.bool,
  tagId: PropTypes.string.isRequired,
  action: PropTypes.func,
};

ReplaceSection.defaultProps = {
  children: null,
  isValid: false,
  action: () => {},
};

export default ReplaceSection;
