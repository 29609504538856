import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTemplateQuery,
  useTemplatesInvalidateQuery,
  useTemplateCreateMutation,
  useTemplateUpdateMutation,
  useSaveTemplateComponentsMutation,
} from 'hooks/api/templates';

export const useTemplates = ({
  team,
  project,
  template,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const [blocks, setBlocks] = React.useState([]);
  const [submitBlocks, setSubmitBlocks] = React.useState({
    blocksToCreate: [],
    blocksToUpdate: [],
    blocksToDelete: [],
  });
  const [isOpen, setIsOpen] = React.useState();

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const navigate = useNavigate();

  const invalidateTemplates = useTemplatesInvalidateQuery();

  const { data: templateData } = useTemplateQuery({
    teamId: team,
    projectId: project,
    templateId: template,
    config: {
      enabled: !!template,
    },
  });

  const templateComponentsData = React.useMemo(() => {
    return templateData?.template_components;
  }, [templateData]);

  const templateComponentsMutationConfig = {
    onSuccess: (data) => {
      invalidateTemplates();
      onSuccess(data);
    },
    onError: (data) => {
      onError(data);
    },
  };

  const templateComponentsMutation = useSaveTemplateComponentsMutation({
    teamId: team,
    projectId: project,
    templateId: template,
  });

  const onSuccessTemplate = (templateDataToSubmit) => {
    const templateId = templateDataToSubmit.id;

    const { blocksToCreate, blocksToUpdate, blocksToDelete } = submitBlocks;
    const blocksTransaction = [];
    blocksToDelete.forEach((block) => {
      blocksTransaction.push({
        values: {
          templateComponentId: block.id,
        },
        transaction: 'delete',
      });
    });
    blocksToUpdate.forEach((block) => {
      blocksTransaction.push({
        values: {
          templateComponentId: block.templateComponentId,
          position: block.position,
        },
        transaction: 'update',
      });
    });
    blocksToCreate.forEach((block) => {
      blocksTransaction.push({
        values: {
          templateId,
          position: block.position,
          component: block.id,
        },
        transaction: 'create',
      });
    });

    if (blocksTransaction.length === 0) {
      templateComponentsMutationConfig.onSuccess(templateDataToSubmit);
    }

    blocksTransaction.forEach((block) => {
      templateComponentsMutation.mutate(
        block,
        templateComponentsMutationConfig
      );
    });
  };

  const templatesMutationConfig = {
    onSuccess: (data) => {
      onSuccessTemplate(data);
    },
    onError: (data) => {
      onError(data);
    },
  };

  const templatesCreateMutation = useTemplateCreateMutation({
    teamId: team,
    projectId: project,
    config: templatesMutationConfig,
  });

  const templatesUpdateMutation = useTemplateUpdateMutation({
    teamId: team,
    projectId: project,
    templateId: template,
    config: templatesMutationConfig,
  });

  React.useEffect(() => {
    if (templateComponentsData) {
      const newBlocks = templateComponentsData
        .map((templateComponent) => {
          const queryBlock = templateComponent.component;

          if (!queryBlock) return null;

          return {
            ...queryBlock,
            templateComponentId: templateComponent.id,
            position: templateComponent.position,
          };
        })
        .filter((block) => block !== null);

      setBlocks(newBlocks);
    }
  }, [templateComponentsData]);

  React.useEffect(() => {
    const blocksToCreate =
      blocks.filter((block) => block.templateComponentId === null) || [];

    const blocksToUpdate = blocks.filter(
      (block) =>
        block.templateComponentId !== null &&
        templateComponentsData &&
        templateComponentsData.find(
          (component) =>
            component.id === block.templateComponentId &&
            component.position !== block.position
        )
    );

    const currentTemplateComponents = templateComponentsData || [];

    const currentBlocksIds = new Set(
      blocks.map((block) => block.templateComponentId)
    );
    const blocksToDelete =
      currentTemplateComponents.filter(
        (component) => !currentBlocksIds.has(component.id)
      ) || [];

    setSubmitBlocks({
      blocksToCreate,
      blocksToUpdate,
      blocksToDelete,
    });
  }, [blocks, templateComponentsData]);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSubmit(values);

    if (template !== null && template !== undefined) {
      templatesUpdateMutation.mutate({
        name: values.name,
        description: values.description,
      });
    } else {
      templatesCreateMutation.mutate({
        name: values.name,
        description: values.description,
      });
    }

    setSubmitting(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    blocks,
    setBlocks,
    submitBlocks,
    setSubmitBlocks,
    handleSubmit,
    goBack,
    templateData,
    isOpen,
    setIsOpen,
    open,
    close,
  };
};
