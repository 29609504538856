import React from 'react';

import HeaderPublic from 'components/header-public';

import { useParams } from 'react-router-dom';
import { useReadOnlyFileQuery } from 'hooks/api/cannoli-services';

import LoadingFullPage from 'components/loading-full-page';
import ReadOnlyHeader from './read-only-header';

import { ReadOnlyProvider } from './read-only-context';
import ReadOnlyContent from './read-only-content';

import styles from './styles.module.css';

function ReadOnlyView() {
  const { fileId } = useParams();
  const { data: file, isLoading } = useReadOnlyFileQuery({
    id: fileId,
  });

  const getData = React.useCallback(() => {
    if (file) {
      const { mjml, name } = file;
      return {
        mjml,
        name,
      };
    }
    return null;
  }, [file]);

  const data = React.useMemo(() => {
    return getData();
  }, [getData]);

  if (isLoading || !file) return <LoadingFullPage />;

  return (
    <ReadOnlyProvider {...data}>
      <div className={styles['editor-container']}>
        <div className={styles['editor-page-container']}>
          <HeaderPublic />

          <ReadOnlyHeader />

          <div className={styles['editor-page-content']}>
            <ReadOnlyContent />
          </div>
        </div>
      </div>
    </ReadOnlyProvider>
  );
}

ReadOnlyView.propTypes = {};

ReadOnlyView.defaultProps = {};

export default ReadOnlyView;
