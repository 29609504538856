import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFetch } from 'context/fetch';

import {
  TEAM_PROJECTS_QUERY_KEY,
  PROJECT_QUERY_KEY,
} from 'hooks/api/constants';

import {
  projectCreateMutation,
  projectUpdateMutation,
  projectDeleteMutation,
} from './mutations';
import { teamProjectsQuery, projectQuery } from './queries';

export function useProjectCreateMutation(props = {}) {
  const { config, team } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => projectCreateMutation({ client, values, team }),
    config
  );
}

export function useTeamProjectsQuery(props = {}) {
  const { config = {}, team, key = TEAM_PROJECTS_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery(
    [key, team],
    () => teamProjectsQuery({ client, team }),
    config
  );
}

export function useTeamProjectsInvalidateQuery(props = {}) {
  const { key = TEAM_PROJECTS_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useProjectUpdateMutation(props = {}) {
  const { config, team, project } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => projectUpdateMutation({ client, team, project, values }),
    config
  );
}

export function useProjectDeleteMutation(props = {}) {
  const { config, team, project } = props;
  const { client } = useFetch();
  return useMutation(
    () => projectDeleteMutation({ client, team, project }),
    config
  );
}

export function useProjectQuery(props = {}) {
  const { key = PROJECT_QUERY_KEY, config, projectId, teamId } = props;
  const { client } = useFetch();
  return useQuery(
    [key, teamId, projectId],
    () => projectQuery({ client, teamId, projectId }),
    config
  );
}

export function useProjectInvalidateQuery(props = {}) {
  const { key = PROJECT_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}
