import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FiXCircle } from 'react-icons/fi';

import IconButton from '@scalero-inc/forno-icon-button';

import { USER_SUBSCRIPTION_STATUS } from 'hooks/api/users/constants';

import PendingPaymentAlert from './pending-payment-alert';
import OverduePaymentAlert from './overdue-payment-alert';
import styles from './style.module.css';

function SubscriptionAlertBanner({ className, status, subscriptionId, close }) {
  const customClassName = classNames(
    styles['subscription-alert-banner'],
    className
  );

  return (
    <div className={customClassName}>
      {status === USER_SUBSCRIPTION_STATUS.PENDING && (
        <PendingPaymentAlert subscriptionId={subscriptionId} />
      )}
      {status === USER_SUBSCRIPTION_STATUS.OVERDUE && (
        <OverduePaymentAlert subscriptionId={subscriptionId} />
      )}
      <IconButton hierarchy="tertiary" onClick={() => close()}>
        <FiXCircle />
      </IconButton>
    </div>
  );
}

SubscriptionAlertBanner.propTypes = {
  className: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  subscriptionId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};

export default SubscriptionAlertBanner;
