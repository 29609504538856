export const MJML_CANNOLI = 'mjmlCannoli';
export const MJML_FIGMA = 'mjmlFigma';
export const MJML_EMPTY = 'mjmlEmpty';

export const EMPTY_MJML_CODE = `<mjml>
<mj-head>
  <mj-attributes>
    <mj-all padding="0"></mj-all>
  </mj-attributes>
</mj-head>
<mj-body background-color="#ffffff" width="720px">
</mj-body>
</mjml>
`;

export const EXAMPLE_MJML_1 = `<mjml>
  <mj-head>
    <mj-preview>
      fixed_7716 v2
    </mj-preview>
    <mj-breakpoint width="480px"></mj-breakpoint>
    <mj-attributes>
      <mj-all padding="0"></mj-all>
    </mj-attributes>
    <mj-style>
      @media only screen and (max-width: 800px) {
        .wrapper > table > tbody > tr > td {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .section > table > tbody > tr > td {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

        .column > table > tbody > tr > td {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .padB40 {
            padding-bottom: 40px !important;
        }

        .image {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .image > table {
            text-align: center !important;
            margin: 0 auto !important;
        }

        .image > table > tbody > tr > td > a > img {
            height: auto !important;
        }

        .divider {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .button > table {
            width: 100% !important;
        }

        .button > table > tbody > tr > td > a {
            width: 80% !important;
        }
    }
    </mj-style>
    <mj-style inline="inline">
      .column > table { border-collapse: separate; }
    </mj-style>
    <mj-font href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap" name="DM Sans"></mj-font>
    <mj-font href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@700&display=swap" name="Public Sans"></mj-font>
  </mj-head>
  <mj-body background-color="#f5f5fe" width="800px">
    <mj-wrapper border-radius="0px" css-class="wrapper" padding="32px 100px 32px 100px">
      <mj-section border-radius="0px" css-class="section" name="mj-section_group" padding="0px 326px 0px 0px">
        <mj-group>
          <mj-column border-radius="0px" css-class="column" vertical-align="middle" width="32.48%">
            <mj-image align="left" alt="Image" css-class="image" height="76px" href="#" src="https://media.cannoli.app/media/figma/f5fa729f846b909c61d37e2e514cac57.png" width="89px">

            </mj-image>
          </mj-column>
          <mj-column css-class="padB40" width="8.76%">
            <mj-spacer height="0px"></mj-spacer>
          </mj-column>
          <mj-column border-radius="0px" css-class="column" padding="0px 0px 0px 0px" vertical-align="middle" width="58.76%">
            <mj-image align="left" alt="Image" css-class="image" height="42px" href="#" padding="17px 0px 17px 0px" src="https://media.cannoli.app/media/figma/f134697da2656265a444981629b59b70.png" width="161px">

            </mj-image>
          </mj-column>
        </mj-group>
      </mj-section>
      <mj-section>
        <mj-column>
          <mj-spacer height="24px"></mj-spacer>
        </mj-column>
      </mj-section>
      <mj-section background-color="#ffffff" border-radius="4px" border="1px solid #e3e3fd" css-class="section" name="mj-section_group" padding="23px 23px 23px 23px">
        <mj-group>
          <mj-column border-radius="0px" css-class="column" vertical-align="middle" width="33.03%">
            <mj-image align="center" alt="Image" css-class="image" height="164px" href="#" src="https://media.cannoli.app/media/figma/87a9fb52da9801e31df1e95568d9c960.png" width="183px">

            </mj-image>
          </mj-column>
          <mj-column css-class="padB40" width="4.7%">
            <mj-spacer height="0px"></mj-spacer>
          </mj-column>
          <mj-column border-radius="0px" css-class="column" vertical-align="middle" width="62.27%">
            <mj-text align="left" color="#161616" css-class="text" font-family="DM Sans, Times, Georgia, serif" font-size="14px" font-weight="400" letter-spacing="0px" line-height="24px" padding="0px 0px 16px 0px">
              Bonjour, [Prénom] [Nom],
<br/>Votre compte n’a pas pu être validé pour la raison suivante : 
<br/>
            </mj-text>
            <mj-image align="center" alt="Image" css-class="image" height="64px" href="#" src="https://media.cannoli.app/media/figma/2d2eb91178fec9283e6e06b21c1c5136.png" width="345px">

            </mj-image>
          </mj-column>
        </mj-group>
      </mj-section>
      <mj-section>
        <mj-column>
          <mj-spacer height="24px"></mj-spacer>
        </mj-column>
      </mj-section>
      <mj-section background-color="#ffffff" border-radius="4px" border="1px solid #e3e3fd" css-class="section" name="mj-section_group" padding="23px 23px 23px 23px">
        <mj-group>
          <mj-column border-radius="0px" css-class="column" vertical-align="middle" width="99.64%">
            <mj-text align="left" color="#242424" css-class="text" font-family="DM Sans, Times, Georgia, serif" font-size="14px" font-weight="400" letter-spacing="0px" line-height="24px" padding="0px 0px 24px 0px">
              Vous pouvez contacter notre support pour plus d’information en communiquant l’email utilisé lors de la création de votre compte.
<br/>
            </mj-text>
            <mj-image align="center" alt="Image" css-class="image" height="32px" href="#" padding="0px 0px 0px 0px" src="https://media.cannoli.app/media/figma/1ee6561d19596e1ef9929aa0de5340e2.png" width="256px">

            </mj-image>
          </mj-column>
        </mj-group>
      </mj-section>
    </mj-wrapper>
  </mj-body>
</mjml>`;
export const EXAMPLE_MJML_2 = `<mjml>
<mj-head>
  <mj-preview>
    Meetlalo test
  </mj-preview>
  <mj-breakpoint width="480px"></mj-breakpoint>
  <mj-attributes>
    <mj-all padding="0"></mj-all>
  </mj-attributes>
  <mj-style>
    @media only screen and (max-width: 600px) {
      .wrapper > table > tbody > tr > td {
          padding-left: 0px !important;
          padding-right: 0px !important;
      }

      .section > table > tbody > tr > td {
          padding-left: 20px !important;
          padding-right: 20px !important;
      }

      .column > table > tbody > tr > td {
          padding-left: 0px !important;
          padding-right: 0px !important;
      }

      .padB40 {
          padding-bottom: 40px !important;
      }

      .image {
          padding-left: 0px !important;
          padding-right: 0px !important;
      }

      .image > table {
          text-align: center !important;
          margin: 0 auto !important;
      }

      .divider {
          padding-left: 0px !important;
          padding-right: 0px !important;
      }

      .button > table {
          width: 100% !important;
      }

      .button > table > tbody > tr > td > a {
          width: 80% !important;
      }
  }
  </mj-style>
  <mj-style inline="inline">
    .column > table { border-collapse: separate; }
  </mj-style>
  <mj-font href="https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap" name="Inter"></mj-font>
</mj-head>
<mj-body width="600px">
  <mj-wrapper background-color="#ecf3ff" border-radius="0px" css-class="wrapper" padding="32px 35px 32px 35px">
    <mj-section border-radius="0px" name="mj-section_group">
      <mj-group>
        <mj-column background-color="#ffffff" border-radius="0px" border="1.5px solid #3057a7" css-class="column" padding="0px 0px 0px 0px" vertical-align="middle" width="100%">
          <mj-image align="center" alt="Image" css-class="image" href="#" padding="30px 0px 30px 0px" src="https://media.cannoli.app/media/figma/768ec686126ab53695abdc65569aba2e.png" width="530px">

          </mj-image>
        </mj-column>
      </mj-group>
    </mj-section>
    <mj-section background-color="#f6f8fb" border-radius="0px" css-class="section" name="mj-section_group">
      <mj-group>
        <mj-column border-left="1.5px solid #3057a7" border-radius="0px" border-right="1.5px solid #3057a7" css-class="column" padding="0px 0px 0px 0px" vertical-align="middle" width="100%">
          <mj-text align="center" color="#3057a7" css-class="text" font-family="Inter, Times, Georgia, serif" font-size="12px" font-weight="600" letter-spacing="0.5px" line-height="16px" padding="30px 0px 30px 0px" text-transform="uppercase">
            Check out your new rewards:
<br/>
          </mj-text>
        </mj-column>
      </mj-group>
    </mj-section>
    <mj-section background-color="#f6f8fb" border-bottom="1.5px solid #3057a7" border-left="1.5px solid #3057a7" border-radius="0px" border-right="1.5px solid #3057a7" css-class="section" name="mj-section_group" padding="0px 44px 30px 44px">
      <mj-group>
        <mj-column border-radius="0px" css-class="column" padding="0px 0px 0px 0px" vertical-align="top" width="8.37%">
          <mj-image align="center" alt="Star 1" css-class="image" href="#" name="mj-image Star 1" padding="0px 0px 7px 0px" src="https://media.cannoli.app/media/figma/cbc3d6ff00577703a9a38dc20ccaea14.png" width="37px">

          </mj-image>
          <mj-image align="center" alt="Star 2" css-class="image" href="#" name="mj-image Star 2" padding="0px 0px 7px 0px" src="https://media.cannoli.app/media/figma/e821e749047ff9f9238167661e7273df.png" width="37px">

          </mj-image>
          <mj-image align="center" alt="Star 3" css-class="image" href="#" name="mj-image Star 3" padding="0px 0px 7px 0px" src="https://media.cannoli.app/media/figma/efed086bc5c48527628b263602bca679.png" width="37px">

          </mj-image>
        </mj-column>
        <mj-column css-class="padB40" width="2.72%">
          <mj-spacer height="0px"></mj-spacer>
        </mj-column>
        <mj-column border-radius="0px" css-class="column" padding="0px 25px 0px 0px" vertical-align="middle" width="88.91%">
          <mj-text align="left" color="#0f234c" css-class="text" font-family="Arial, Helvetica, Verdana, sans-serif" font-size="18px" font-weight="400" letter-spacing="-0.5px" line-height="44px" padding="0px 0px 0px 0px">
            <span style="line-height: 44px">You’ve earned </span><span style="font-family: Arial, Helvetica, Verdana, sans-serif; line-height: 44px; font-weight: 700">1,000</span><span style="line-height: 44px"> points for unlocking this tier!
</span><br/><span style="font-family: Arial, Helvetica, Verdana, sans-serif; line-height: 44px; font-weight: 700">10</span><span style="line-height: 44px"> points for every </span><span style="font-family: Arial, Helvetica, Verdana, sans-serif; line-height: 44px; font-weight: 700">$1</span><span style="line-height: 44px"> spent 
</span><br/><span style="font-family: Arial, Helvetica, Verdana, sans-serif; font-size: 18px; line-height: 44px; font-weight: 400; letter-spacing: -0.5px">Free shipping on orders o</span><span style="line-height: 44px">ver </span><span style="font-family: Arial, Helvetica, Verdana, sans-serif; font-size: 18px; line-height: 44px; font-weight: 700; letter-spacing: -0.5px">$100</span><span style="line-height: 44px">!</span>
<br/>
          </mj-text>
        </mj-column>
      </mj-group>
    </mj-section>
  </mj-wrapper>
</mj-body>
</mjml>`;
