import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'context/fetch';

import {
  COMPONENTS_QUERY_KEY,
  COMPONENT_QUERY_KEY,
  TEAM_COMPONENT_QUERY_KEY,
  USER_COMPONENTS_PAGINATED_QUERY_KEY,
  TEAM_COMPONENTS_PAGINATED_QUERY_KEY,
} from 'hooks/api/constants';

import {
  componentsQuery,
  componentQuery,
  userComponentsQuery,
  teamComponentsQuery,
  teamComponentQuery,
  userComponentsPaginatedQuery,
  teamComponentsPaginatedQuery,
  paginatedComponentsQuery,
} from './queries';
import {
  componentCreateMutation,
  createComponentMutation,
  createTeamComponentMutation,
  deleteTeamComponentMutation,
  deleteUserComponentMutation,
  updateComponentMutation,
  updateTeamComponentMutation,
} from './mutations';

export function useComponentsQuery(props = {}) {
  const { config = {}, key = COMPONENTS_QUERY_KEY, teamId } = props;
  const { client } = useFetch();
  return useQuery([key], () => componentsQuery({ client, teamId }), config);
}

export function usePaginatedComponentsQuery(props = {}) {
  const { config = {}, key = COMPONENTS_QUERY_KEY, queryOrder } = props;
  const { client } = useFetch();
  return useQuery(
    [key, queryOrder],
    () => paginatedComponentsQuery({ client, queryOrder }),
    config
  );
}

export function useComponentQuery(props = {}) {
  const { componentId, key = COMPONENT_QUERY_KEY, config = {} } = props;
  const { client } = useFetch();
  return useQuery(
    [key, componentId],
    () => componentQuery({ client, componentId }),
    config
  );
}

export function useInvalidateComponentQuery(props = {}) {
  const { key = COMPONENT_QUERY_KEY, componentId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key, componentId]);
  };
}

export function useCreateComponentMutationMock(props = {}) {
  const { config, team } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => componentCreateMutation({ client, values, team }),
    config
  );
}

export function useUserComponentsQuery(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useQuery(
    ['userComponents'],
    () => userComponentsQuery({ client }),
    config
  );
}

export function useTeamComponentQuery(props = {}) {
  const {
    config = {},
    key = TEAM_COMPONENT_QUERY_KEY,
    teamId,
    componentId,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, teamId, componentId],
    () => teamComponentQuery({ client, teamId, componentId }),
    config
  );
}

export function useInvalidateTeamComponentQuery(props = {}) {
  const { key = TEAM_COMPONENT_QUERY_KEY, teamId, componentId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key, teamId, componentId]);
  };
}

export function useTeamComponentsQuery(props = {}) {
  const { config = {}, teamId } = props;
  const { client } = useFetch();
  return useQuery(
    ['teamComponents', teamId],
    () => teamComponentsQuery({ client, teamId }),
    config
  );
}

export function useCreateComponentMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => createComponentMutation({ client, values }),
    config
  );
}

export function useUpdateComponentMutation(props = {}) {
  const { config, componentId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => updateComponentMutation({ client, componentId, values }),
    config
  );
}

export function useUpdateTeamComponentMutation(props = {}) {
  const { config, teamId, componentId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) =>
      updateTeamComponentMutation({ client, teamId, componentId, values }),
    config
  );
}

export function useCreateTeamComponentMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => createTeamComponentMutation({ client, teamId, values }),
    config
  );
}

export function useUserComponentsInvalidateQuery() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(['userComponents']);
}

export function useTeamComponentsInvalidateQuery(props = {}) {
  const { teamId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['teamComponents', teamId]);
  };
}

export function useUserComponentDeleteMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation(
    (componentId) => deleteUserComponentMutation({ client, componentId }),
    config
  );
}

export function useTeamComponentDeleteMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(
    (componentId) =>
      deleteTeamComponentMutation({ client, teamId, componentId }),
    config
  );
}

export function useUserComponentsPaginatedQuery(props = {}) {
  const {
    config = {},
    key = USER_COMPONENTS_PAGINATED_QUERY_KEY,
    queryOrder,
    queryPage,
    pageSize,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, queryOrder, queryPage, pageSize],
    () =>
      userComponentsPaginatedQuery({
        client,
        queryOrder,
        queryPage,
        pageSize,
      }),
    config
  );
}

export function useUserComponentsPaginatedInvalidateQuery(props = {}) {
  const { key = USER_COMPONENTS_PAGINATED_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useTeamComponentsPaginatedQuery(props = {}) {
  const {
    config = {},
    key = TEAM_COMPONENTS_PAGINATED_QUERY_KEY,
    queryOrder,
    queryPage,
    pageSize,
    teamId,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, teamId, queryOrder, queryPage, pageSize],
    () =>
      teamComponentsPaginatedQuery({
        client,
        queryOrder,
        queryPage,
        pageSize,
        teamId,
      }),
    config
  );
}

export function useTeamComponentsPaginatedInvalidateQuery(props = {}) {
  const { key = TEAM_COMPONENTS_PAGINATED_QUERY_KEY, teamId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key, teamId]);
  };
}
