import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';

import { useSubsciptionUpdatePaymentMethod } from 'hooks/api/subscriptions';

import styles from './style.module.css';

function getInterval(period) {
  if (period === 'month') return 'Monthly';
  if (period === 'year') return 'Annual';
  return 'No data';
}

function BillingInformationSection({
  status,
  interval,
  paymentMethod,
  paymentMethodDigits,
  email,
  subscriptionId,
}) {
  const { mutate } = useSubsciptionUpdatePaymentMethod({
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      onError: () =>
        toast.error(
          'There was an error redirecting you to the payment details. Try again later'
        ),
    },
    subscriptionId,
  });

  return (
    <section className={styles['billing-information']}>
      <table>
        <thead>
          <tr>
            <th className={styles['title-column-head']} />
            <th className={styles['status-column-head']} />
            <th className={styles['cta-column-head']} />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles['title-cell']}>Payment Status</td>
            <td className={styles['status-cell']}>{status.label}</td>
            <td className={styles['cta-cell']} />
          </tr>
          <tr>
            <td className={styles['title-cell']}>Billing Interval</td>
            <td className={styles['status-cell']}>{getInterval(interval)}</td>
            <td className={styles['cta-cell']} />
          </tr>
          <tr>
            <td className={styles['title-cell']}>Payment Method</td>
            <td className={styles['status-cell']}>
              {paymentMethod} {paymentMethodDigits}
            </td>
            <td className={styles['cta-cell']}>
              <Button
                className={styles['row-cta']}
                hierarchy="tertiary"
                size="xs"
                onClick={() => mutate()}
              >
                Update
              </Button>
            </td>
          </tr>
          <tr>
            <td className={styles['title-cell']}>Payment Email</td>
            <td className={styles['status-cell']}>{email}</td>
            <td className={styles['cta-cell']} />
          </tr>
        </tbody>
      </table>
    </section>
  );
}

BillingInformationSection.propTypes = {
  status: PropTypes.shape({
    status: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  interval: PropTypes.string,
  paymentMethod: PropTypes.string,
  paymentMethodDigits: PropTypes.string,
  email: PropTypes.string,
  subscriptionId: PropTypes.number.isRequired,
};

BillingInformationSection.defaultProps = {
  interval: 'Not available',
  paymentMethod: 'Not available',
  paymentMethodDigits: 'Not available',
  email: 'Not available',
};

export default BillingInformationSection;
