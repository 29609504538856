import { useCallback, useState } from 'react';
import { FiBell } from 'react-icons/fi';

import Alert from 'components/alert';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Dropdown from '@scalero-inc/forno-dropdown';
import Modal from '@scalero-inc/forno-modal';

import RadioButtonGroup from 'components/radio-button-group';

import PricingCardBase from 'containers/pricing-cards-private/pricing-card-base';

import { useAuth } from 'context/auth';

import styles from './style.module.css';

const options1 = [
  { value: 'default', label: 'Dropdown' },
  { value: 'new-value-1', label: 'New Value 1' },
  { value: 'new-value-2', label: 'New Value 2' },
  { value: 'new-value-3', label: 'New Value 3' },
];

const optionGroups = [
  {
    label: 'Team 1',
    options: [
      { label: 'option 1', value: 'value1' },
      { label: 'option 2', value: 'value2' },
    ],
  },
  {
    label: 'Team 2',
    options: [
      { label: 'option 3', value: 'value3' },
      { label: 'option 4', value: 'value4' },
    ],
  },
];

const optionsRadio = [
  { label: 'option 1', value: 'option1' },
  { label: 'option 2', value: 'option2' },
  { label: 'option 3', value: 'option3' },
  { label: 'option 4', value: 'option4' },
];

const plan = {
  name: 'Test',
  description: 'Test description',
  features: [
    { plan: 'Unlimited', label: 'unlimited file ingestions' },
    { plan: 'Unlimited', label: 'charged per editor' },
    { plan: 'Unlimited', label: 'Image hosting at 2x' },
    { plan: 'Unlimited', label: 'Send test emails' },
    { plan: 'Unlimited', label: 'Projects and collaboration' },
  ],
  icon: FiBell,
  pricing: '$25',
  pricingLegend: 'This is the pricing legend',
};

const cardCta = (
  <>
    <FiBell /> Try it now!
  </>
);

function Design() {
  const [isOpen, setIsOpen] = useState();
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { logout } = useAuth();
  return (
    <div className={styles.app}>
      <section className="button-sample">
        <article>
          <Button>Button</Button>
          <Button disabled>Button</Button>
          <Button size="s">Button</Button>
        </article>
        <article>
          <Button hierarchy="secondary">Button</Button>
          <Button hierarchy="secondary" disabled>
            Button
          </Button>
          <Button hierarchy="secondary" size="s">
            Button
          </Button>
        </article>
        <article>
          <Button hierarchy="tertiary">Button</Button>
          <Button hierarchy="tertiary" disabled>
            Button
          </Button>
          <Button hierarchy="tertiary" size="s">
            Button
          </Button>
        </article>
        <article>
          <Button status="danger">Button</Button>
          <Button status="danger" disabled>
            Button
          </Button>
          <Button status="danger" size="s">
            Button
          </Button>
        </article>
      </section>

      <hr />

      <section className="dropdown-sample">
        <article>
          <Dropdown options={options1} />
          <Dropdown options={options1} size="s" onChange={() => {}} />
        </article>
        <article>
          <Dropdown options={options1} disabled />
          <Dropdown options={options1} size="s" disabled />
        </article>
        <article>
          <Dropdown options={options1} hierarchy="secondary" />
          <Dropdown options={options1} hierarchy="secondary" disabled />
          <Dropdown options={options1} hierarchy="secondary" size="s" />
        </article>
        <article>
          <Dropdown options={optionGroups} hierarchy="secondary" size="s" />
        </article>
      </section>

      <hr />

      <section className="modal-sample">
        <article>
          <Button onClick={open}>Open modal</Button>
          <Modal
            isOpen={isOpen}
            onDismiss={close}
            aria-label="regular modal"
            theme="dark"
          >
            <Modal.Header
              title="Modal Title"
              onDismiss={close}
              icon={FiBell}
              theme="dark"
            />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
              ipsum scelerisque quis mauris mi lectus. Elit quis hendrerit arcu,
              tempor volutpat risus, risus. Quis enim amet, tellus eu orci, eu
              aliquam. Enim sed viverra hendrerit ornare vitae nunc parturient.
              Mi sit auctor.
            </p>
            <Modal.Footer>
              <Button hierarchy="tertiary" onClick={close} theme="dark">
                Cancel
              </Button>
              <Button hierarchy="primary" onClick={close} theme="dark">
                Accept
              </Button>
            </Modal.Footer>
          </Modal>
        </article>
      </section>

      <hr />

      <section>
        <article>
          <Alert title="Notification Message">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            quasi praesentium debitis a iusto libero cupiditate, similique
            quaerat reprehenderit et voluptatibus. Eius ducimus ut dignissimos
            dolores quibusdam nobis minima omnis.
          </Alert>
        </article>
        <article>
          <Alert title="Alert Message" hierarchy="alert">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            quasi praesentium debitis a iusto libero cupiditate, similique
            quaerat reprehenderit et voluptatibus. Eius ducimus ut dignissimos
            dolores quibusdam nobis minima omnis.
          </Alert>
        </article>
        <article>
          <Alert title="Danger Message" hierarchy="danger">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            quasi praesentium debitis a iusto libero cupiditate, similique
            quaerat reprehenderit et voluptatibus. Eius ducimus ut dignissimos
            dolores quibusdam nobis minima omnis.
          </Alert>
        </article>
        <article>
          <Alert title="Success Message" hierarchy="success">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
            quasi praesentium debitis a iusto libero cupiditate, similique
            quaerat reprehenderit et voluptatibus. Eius ducimus ut dignissimos
            dolores quibusdam nobis minima omnis.
          </Alert>
        </article>
      </section>

      <hr />

      <section>
        <article>
          <Input
            placeholder="Placeholder input text"
            label="Input with label"
            size="s"
          />
        </article>
        <article>
          <Input
            placeholder="Placeholder disabled input text"
            label="Input with label disabled"
            disabled
            size="s"
          />
        </article>
        <article>
          <Input placeholder="Placeholder input text" size="s" />
        </article>
        <article>
          <Input
            placeholder="Placeholder disabled input text"
            disabled
            size="s"
          />
        </article>
      </section>

      <hr />

      <section>
        <article>
          <RadioButtonGroup
            name="test-options"
            options={optionsRadio}
            defaultValueIndex={0}
            onChange={() => {}}
          />
        </article>
        <article>
          <RadioButtonGroup
            name="test-options"
            options={optionsRadio}
            defaultValueIndex={0}
            onChange={() => {}}
            disabled
          />
        </article>
      </section>

      <section>
        <PricingCardBase plan={plan} cta={cardCta} />
        <PricingCardBase
          plan={plan}
          cta="Try it!"
          activePlan
          highlight="This is the best plan for you"
        />
      </section>

      <button type="button" onClick={logout}>
        logout
      </button>

      <div className="whitespace" style={{ height: '30px' }} />
    </div>
  );
}

export default Design;
