import React, {
  useContext,
  createContext,
  useReducer,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import mjml2html from 'mjml-browser';
import { useFileInvalidateQuery, useFileUpdateMutation } from 'hooks/api/files';
import {
  useInvalidateComponentQuery,
  useInvalidateTeamComponentQuery,
  useUpdateComponentMutation,
  useUpdateTeamComponentMutation,
} from 'hooks/api/components';
import { EditorViewProvider } from '../editor-view-context';

import { getStateFromMjml } from './functions';

const EditorStateContext = createContext();
const EditorDispatchContext = createContext();

const SET_MJML = 'SET_MJML';
const SET_TAG_SELECTED = 'SET_TAG_SELECTED';
const SET_MISSING_FONTS = 'SET_MISSING_FONTS';
const SET_CODE_IS_VALID = 'SET_CODE_IS_VALID';

function editorReducer(state, action) {
  switch (action.type) {
    case SET_MJML:
      return {
        ...state,
        ...getStateFromMjml(action.payload.mjml),
      };
    case SET_TAG_SELECTED:
      return {
        ...state,
        ...{ tagSelected: action.payload.tagSelected },
      };
    case SET_CODE_IS_VALID:
      return {
        ...state,
        ...{ codeIsValid: action.payload.codeIsValid },
      };
    case SET_MISSING_FONTS:
      return {
        ...state,
        ...{ missingFonts: action.payload.missingFonts },
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function EditorProvider({
  templateId,
  teamId,
  fileId,
  componentId,
  sourceFileId,
  figmaId,
  url,
  node,
  mjml,
  frame,
  name,
  missingFonts,
  children,
  tagSelected,
  codeIsValid,
  isCopy,
}) {
  const mjmlState = useMemo(() => {
    return getStateFromMjml(mjml);
  }, [mjml]);

  const [state, dispatch] = useReducer(editorReducer, {
    templateId,
    teamId,
    fileId,
    componentId,
    sourceFileId,
    figmaId,
    url,
    node,
    frame,
    name,
    missingFonts,
    ...mjmlState,
    tagSelected,
    codeIsValid,
    isCopy,
  });

  return (
    <EditorStateContext.Provider value={state}>
      <EditorDispatchContext.Provider value={dispatch}>
        <EditorViewProvider>{children}</EditorViewProvider>
      </EditorDispatchContext.Provider>
    </EditorStateContext.Provider>
  );
}

EditorProvider.propTypes = {
  templateId: PropTypes.number,
  teamId: PropTypes.number,
  fileId: PropTypes.string,
  componentId: PropTypes.string,
  sourceFileId: PropTypes.string,
  figmaId: PropTypes.string,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  node: PropTypes.string,
  mjml: PropTypes.string,
  frame: PropTypes.string,
  missingFonts: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node,
  tagSelected: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.string,
  }),
  codeIsValid: PropTypes.bool,
  isCopy: PropTypes.bool,
};

EditorProvider.defaultProps = {
  templateId: null,
  teamId: null,
  fileId: null,
  componentId: null,
  sourceFileId: null,
  figmaId: null,
  url: null,
  node: null,
  mjml: '',
  frame: null,
  missingFonts: [],
  children: null,
  tagSelected: { id: '', from: '' },
  codeIsValid: true,
  isCopy: false,
};

function useEditor() {
  const context = useContext(EditorStateContext);

  if (context === undefined) {
    throw new Error('Editor State must be used within a EditorProvider');
  }

  return context;
}

function useEditorDispatch(teamId, fileId, componentId) {
  const dispatch = useContext(EditorDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useEditorDispatch must be used within a EditorProvider');
  }

  const setMjml = (value) => {
    dispatch({ type: SET_MJML, payload: { mjml: value } });
  };

  const setTagSelected = (value) => {
    dispatch({ type: SET_TAG_SELECTED, payload: { tagSelected: value } });
  };

  const setCodeIsValid = useCallback(
    (value) => {
      dispatch({ type: SET_CODE_IS_VALID, payload: { codeIsValid: value } });
    },
    [dispatch]
  );

  const setMissingFonts = (value) => {
    dispatch({ type: SET_MISSING_FONTS, payload: { missingFonts: value } });
  };

  const invalidateFile = useFileInvalidateQuery({
    key: 'FILE_SAVE_INDICATOR',
    fileId,
  });
  const fileMutation = useFileUpdateMutation({
    fileId,
    config: {
      onSuccess: () => {
        invalidateFile();
      },
    },
  });

  const invalidateUserComponent = useInvalidateComponentQuery({
    key: 'USER_COMPONENT_SAVE_INDICATOR',
    componentId,
  });
  const userComponentMutation = useUpdateComponentMutation({
    componentId,
    config: {
      onSuccess: () => {
        invalidateUserComponent();
      },
    },
  });

  const invalidateTeamComponent = useInvalidateTeamComponentQuery({
    key: 'TEAM_COMPONENT_SAVE_INDICATOR',
    teamId,
    componentId,
  });
  const teamComponentMutation = useUpdateTeamComponentMutation({
    teamId,
    componentId,
    config: {
      onSuccess: () => {
        invalidateTeamComponent();
      },
    },
  });

  const componentMutation = teamId
    ? teamComponentMutation
    : userComponentMutation;
  const mutation = fileId ? fileMutation : componentMutation;
  const getData = (mjml) => {
    const { json, html } = mjml2html(mjml);

    if (fileId) {
      return { mjml, html };
    }

    const mjmlChildren = json.children || [];
    const body = mjmlChildren.find((child) => child.tagName === 'mj-body') || {
      children: [],
    };

    return {
      content: { blockArray: body.children },
    };
  };

  const save = (mjml) => {
    mutation.mutate(getData(mjml));
    return setMjml(mjml);
  };

  const editor = {
    save,
    isLoading: mutation.isLoading,
  };

  return {
    dispatch,
    setMjml,
    setTagSelected,
    setCodeIsValid,
    setMissingFonts,
    editor,
  };
}

export { EditorProvider, useEditor, useEditorDispatch };
