import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { plans } from 'containers/dashboard-billing/constants';
import { PageOnboarding } from 'components/layout';
import LoadingFullPage from 'components/loading-full-page';

import { useAuth } from 'context/auth';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function FigmaCallback() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  React.useEffect(() => {
    const doLogin = async () => {
      await login({ code, stateCode: state });

      // state = 1 : Login from Cannoli
      // state pro, starter, team : account creation
      if (state === '1') {
        navigate(`/welcome?to=${from.pathname}`);
      } else if (state === plans.starter.slug) {
        navigate(`/welcome?type=${plans.starter.slug}`);
      } else if (state === plans.proTrial.slug) {
        navigate(`/welcome?type=${plans.proTrial.slug}`);
      } else if (state === plans.team.slug) {
        navigate('/new-team');
      } else {
        navigate('/paired-account');
      }
    };
    doLogin();
  }, [code, state, login, navigate, from]);

  return (
    <PageOnboarding className="figma-login">
      <LoadingFullPage>
        <h2>Signing in</h2>
      </LoadingFullPage>
    </PageOnboarding>
  );
}

export default FigmaCallback;
