import { Outlet } from 'react-router-dom';
import { useCallback, useState } from 'react';

import NoSubscriptionsAlert from 'containers/no-subscriptions-alert';
import FileCreationLimitAlert from 'containers/file-creation-limit-alert';

import { useUserQuery } from 'hooks/api/users';
import {
  USER_SUBSCRIPTION_STATUS,
  USER_SUBSCRIPTION_TIER,
} from 'hooks/api/users/constants';

import { canCreatePersonalFile, isVIP } from 'utils/permissions';

import styles from './style.module.css';
import SubscriptionAlertBanner from '../subscription-alert-banner';

function DashboardPersonal() {
  const [order, setOrder] = useState('newest');
  const { data: user } = useUserQuery();
  const [isOpen, setIsOpen] = useState(true);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const [noSubIsOpen, setNoSubIsOpen] = useState(true);
  const closeNoSub = useCallback(() => setNoSubIsOpen(false), [setNoSubIsOpen]);

  const [noMoreFilesIsOpen, setNoMoreFilesIsOpen] = useState(true);
  const closeNoMoreFiles = useCallback(
    () => setNoMoreFilesIsOpen(false),
    [setNoMoreFilesIsOpen]
  );

  const subscription = user?.subscriptions;
  const teamSubscriptions = user?.team_subscriptions;

  const showBanner =
    isOpen &&
    subscription?.tier === USER_SUBSCRIPTION_TIER.PRO &&
    subscription?.status !== USER_SUBSCRIPTION_STATUS.ACTIVE &&
    !user?.has_valid_trial;

  const showNoSubsBanner =
    noSubIsOpen &&
    subscription === null &&
    teamSubscriptions.length === 0 &&
    isVIP({ user });

  const showNoMoreFilesBanner =
    noMoreFilesIsOpen && !canCreatePersonalFile({ user });

  return (
    <div className={styles['dashboard-personal']}>
      {showBanner ? (
        <SubscriptionAlertBanner
          className={styles['dashboard-banner']}
          status={subscription?.status}
          subscriptionId={subscription?.id}
          close={close}
        />
      ) : null}
      {showNoSubsBanner ? (
        <NoSubscriptionsAlert
          close={closeNoSub}
          className={styles['dashboard-banner']}
        />
      ) : null}
      {showNoMoreFilesBanner ? (
        <FileCreationLimitAlert
          close={closeNoMoreFiles}
          className={styles['dashboard-banner']}
        />
      ) : null}
      <header className={styles['dashboard-header']}>
        <h2>Personal</h2>
      </header>
      <Outlet
        context={{
          order,
          setOrder,
        }}
      />
    </div>
  );
}

export default DashboardPersonal;
