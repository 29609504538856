import * as yup from 'yup';
import PropTypes from 'prop-types';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import FallbackStackDesigner from 'components/fallback-stack-designer';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
  useFontVariantsInvalidateQuery,
  useFontVariantUpdateMutation,
  useTeamFontStackCreateMutation,
  useUserFontStackCreateMutation,
} from 'hooks/api/fonts';
import { WEB_SAFE_FONTS } from 'components/fallback-stack-designer/utils';
import { validateFontAccesibility, validateFontFormat } from './utils';

import styles from './styles.module.css';

function CustomFontModal({
  theme,
  isOpen,
  close,
  font,
  teamId,
  isEditing,
  onLoadFont,
}) {
  const action = isEditing
    ? {
        present: 'Update',
        past: 'Updated',
      }
    : {
        present: 'Add',
        past: 'Added',
      };

  const initialValues = isEditing
    ? { url: font.url, fallback: font.fallback }
    : {
        url: '',
        fallback: {
          name: `${font.family} stack`,
          stack: [{ family: WEB_SAFE_FONTS[0].family }],
        },
      };
  const schema = isEditing
    ? yup.object().shape({
        url: yup.string().url().required('Font link is required').trim(),
      })
    : yup.object().shape({
        url: yup.string().url().required('Font link is required').trim(),
        fallback: yup.object().shape({
          name: yup.string().required('Stack name is required').trim(),
        }),
      });

  const invalidateVariants = useFontVariantsInvalidateQuery();
  const updateVariantMutation = useFontVariantUpdateMutation({
    fontVariantId: font.id,
  });

  const userCreateMutation = useUserFontStackCreateMutation();
  const teamCreateMutation = useTeamFontStackCreateMutation({ teamId });
  const createStackMutation = teamId ? teamCreateMutation : userCreateMutation;

  const validateFont = async ({ url }) => {
    if (!validateFontFormat(url)) {
      return { url: 'Font link not valid' };
    }

    return validateFontAccesibility(url);
  };

  const saveFont = async ({ url, fallback }) => {
    try {
      const { font_id: fontId } = await updateVariantMutation.mutateAsync({
        url,
      });

      if (!isEditing) {
        await createStackMutation.mutateAsync({
          font: fontId,
          name: fallback.name,
          stack: fallback.stack,
        });
      }

      invalidateVariants();
      toast.success(`Font ${action.past.toLowerCase()} successfully`);
      onLoadFont({
        family: font.family,
        weight: font.weight,
        url,
        stack: fallback.stack,
      });
      close();
    } catch (error) {
      toast.error(
        `Failed to ${action.present.toLowerCase()} the font, try again later`
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="regular modal"
      theme={theme}
    >
      <Modal.Header onDismiss={close} title={`${action.present} Custom Font`} />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={saveFont}
        validate={validateFont}
      >
        {({ isValid, dirty, values }) => (
          <Form>
            <Modal.Content>
              <div className={styles['custom-font-container']}>
                <div className={styles['custom-font']}>
                  <div>
                    <span>Font Name</span>
                    <strong>{font.family}</strong>
                  </div>
                  <div>
                    <span>Weight</span>
                    <strong>{font.weight}</strong>
                  </div>

                  <Field id="url" name="url">
                    {({ field, meta }) => (
                      <Input
                        id="url"
                        size="s"
                        label="Font link"
                        placeholder="Font link"
                        warningText={meta.error}
                        theme={theme}
                        {...field}
                      />
                    )}
                  </Field>
                </div>

                {!isEditing && (
                  <FallbackStackDesigner
                    id="fallback"
                    fallback={values.fallback}
                    theme={theme}
                  />
                )}
              </div>
            </Modal.Content>
            <Modal.Footer>
              <Button theme={theme} hierarchy="tertiary" onClick={close}>
                Cancel
              </Button>
              <Button
                theme={theme}
                type="submit"
                hierarchy="primary"
                loading={
                  updateVariantMutation.isLoading ||
                  createStackMutation.isLoading
                }
                disabled={
                  !(isValid && dirty) ||
                  updateVariantMutation.isLoading ||
                  createStackMutation.isLoading
                }
              >
                {action.present} font
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

CustomFontModal.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  font: PropTypes.shape({
    family: PropTypes.string,
    weight: PropTypes.string,
    fallback: PropTypes.shape({
      name: PropTypes.string,
      stack: PropTypes.arrayOf(PropTypes.shape({ family: PropTypes.string })),
    }),
    url: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  teamId: PropTypes.number,
  isEditing: PropTypes.bool,
  onLoadFont: PropTypes.func,
};

CustomFontModal.defaultProps = {
  theme: 'light',
  teamId: null,
  isEditing: false,
  onLoadFont: () => {},
};

export default CustomFontModal;
