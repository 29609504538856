import PropTypes from 'prop-types';
import CardGrid from 'containers/card-grid';
import FileTable from 'containers/file-table';
import Spinner from '@scalero-inc/forno-spinner';

function FilesContainer({
  type,
  teamId,
  projectId,
  view,
  files,
  page,
  setPage,
  isBlocked,
  isEditable,
  isLoading,
}) {
  if (isLoading) return <Spinner />;

  if (files.length === 0) {
    return (
      <>
        <p>No {type} to display.</p>
        {type === 'blocks' && (
          <p>
            Cannoli builds templates using blocks previously saved in your
            library. To create a new block, sync a Figma file with Cannoli and
            save a layer or group of layers as a new block.
          </p>
        )}
      </>
    );
  }

  if (view === 'grid') {
    return (
      <CardGrid
        content={files}
        type={type}
        teamId={teamId}
        projectId={projectId}
        page={page}
        setPage={setPage}
        blocked={isBlocked}
        isEditable={isEditable}
      />
    );
  }

  return (
    <FileTable
      content={files}
      type={type}
      teamId={teamId}
      projectId={projectId}
      page={page}
      setPage={setPage}
      blocked={isBlocked}
    />
  );
}

FilesContainer.propTypes = {
  type: PropTypes.oneOf(['files', 'projects', 'blocks', 'templates']),
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  view: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number,
  setPage: PropTypes.func,
  isBlocked: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

FilesContainer.defaultProps = {
  type: 'files',
  teamId: null,
  projectId: null,
  files: [],
  page: 1,
  setPage: () => {},
  isEditable: false,
};

export default FilesContainer;
