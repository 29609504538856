import PropTypes from 'prop-types';
import Tooltip from '@scalero-inc/forno-tooltip';
import { FiAlertTriangle, FiHelpCircle } from 'react-icons/fi';

import styles from './styles.module.css';

function MissingFontMessage({ multipleFonts }) {
  const fontLabel = multipleFonts ? 'fonts' : 'font';

  return (
    <div className={styles['missing-fonts-headline']}>
      <FiAlertTriangle />
      <span>Missing {fontLabel}:</span>

      <Tooltip
        placement="top"
        theme="dark"
        status="warning"
        content={
          <p className="font-paragraph-s-sb">
            Replace the following {fontLabel} or add the link to view and use it
            in your email.
          </p>
        }
      >
        <FiHelpCircle />
      </Tooltip>
    </div>
  );
}

MissingFontMessage.propTypes = {
  multipleFonts: PropTypes.bool,
};

MissingFontMessage.defaultProps = {
  multipleFonts: false,
};

export default MissingFontMessage;
