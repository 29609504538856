import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFetch } from 'context/fetch';

import {
  espCreateMutation,
  espRemoveMutation,
  espUpdateMutation,
} from './mutations';
import { espsQuery } from './queries';
import { ESPS_QUERY_KEY } from '../constants';

export function useEspCreateMutation(props = {}) {
  const { config, esp } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => espCreateMutation({ client, esp, values }),
    config
  );
}

export function useEspsQuery(props = {}) {
  const { config = {}, key = ESPS_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery([key], () => espsQuery({ client }), config);
}

export function useEspUpdateMutation(props = {}) {
  const { config, id } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => espUpdateMutation({ client, id, values }),
    config
  );
}

export function useEspsInvalidateQuery(props = {}) {
  const { key = ESPS_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useEspRemoveMutation(props = {}) {
  const { config, id } = props;
  const { client } = useFetch();
  return useMutation(() => espRemoveMutation({ client, id }), config);
}
