import mjml2html from 'mjml-browser';
import {
  getContentNodesWithIds,
  getMjHeadIndex,
  processIds,
  removeOutputIds,
} from 'utils/mjml/util';

export function convertMjmlErrorsToEditor(errors) {
  return errors
    .filter((error) => error.message !== 'Attribute id is illegal')
    .map((error) => {
      return {
        row: error.line,
        column: 0,
        text: `[${error.tagName}] ${error.message}`,
        type: 'error',
      };
    });
}

export function getStateFromMjml(value) {
  try {
    if (!value) {
      return null;
    }

    const { html, errors, json } = mjml2html(value);
    const mjHeadIndex = getMjHeadIndex(json);
    const mjHead = json?.children[mjHeadIndex];
    const jsonWithIds = processIds(json); // JSON object with new ids (mj-html-attributes)
    let { html: htmlWithIds } = mjml2html(jsonWithIds);
    htmlWithIds = removeOutputIds(htmlWithIds); // remove old ids (uuid), inside html
    const nodesWithIds = getContentNodesWithIds(json);

    return {
      mjml: value,
      json,
      jsonWithIds,
      html,
      htmlWithIds,
      nodesWithIds,
      mjHead,
      errors: convertMjmlErrorsToEditor(errors),
    };
  } catch (e) {
    console.error('Error getting state from MJML', e);
    return null;
  }
}

const getIdFromAttributes = (attributes) => {
  return attributes && attributes['css-class']
    ? attributes['css-class'].split(' ')[0]
    : null;
};

export const modifyNodeInJson = ({
  json,
  targetId,
  newNode,
  position = null,
}) => {
  const currentId = getIdFromAttributes(json.attributes);

  if (currentId === targetId) {
    if (position !== null) {
      const children = json.children || [];
      const beforePosition = children.slice(0, position);
      const afterPosition = children.slice(position);

      return {
        ...json,
        children: [...beforePosition, newNode, ...afterPosition],
      };
    }
    return {
      ...newNode,
      attributes: {
        ...newNode.attributes,
        ...json.attributes,
      },
    };
  }

  if (!json.children) return json;

  const updatedChildren = json.children.map((child) =>
    modifyNodeInJson({
      json: child,
      targetId,
      newNode,
      position,
    })
  );

  return {
    ...json,
    children: updatedChildren,
  };
};
