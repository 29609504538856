import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import Spinner from '@scalero-inc/forno-spinner';
import Dropdown from '@scalero-inc/forno-dropdown';
import { useEditor, useEditorDispatch } from 'pages/editor/editor-context';
import CustomFontDeleteModal from 'containers/custom-font-delete-modal';
import CustomFontsTable from 'containers/custom-fonts-table';
import FallbackFontModal from 'containers/fallback-font-modal';
import CustomFontModal from 'containers/custom-font-modal';
import { useFontVariantsQuery } from 'hooks/api/fonts';
import { FiAlertTriangle, FiCheck, FiPlus } from 'react-icons/fi';
import { getCustomFonts } from 'utils/mjml/util';
import json2mjml from 'json2mjml';

import styles from './styles.module.css';

function CustomFontsAdminModal({ teamId, close, theme }) {
  const { fileId, componentId, json, mjHead } = useEditor();
  const { editor } = useEditorDispatch(teamId, fileId, componentId);
  const customFonts = getCustomFonts(mjHead.children || []);
  const [fontToAdd, setFontToAdd] = useState(null);
  const [fontToEdit, setFontToEdit] = useState(null);
  const [fontToDelete, setFontToDelete] = useState(null);
  const [isFontsTableOpen, setIsFontsTableOpen] = useState(true);

  const { data, isError, isLoading } = useFontVariantsQuery({
    teamId,
    config: {
      refetchOnWindowFocus: false,
    },
  });

  const saveChanges = useCallback(() => {
    const mjml = json2mjml(json);
    editor.save(mjml);
  }, [editor, json]);

  const buildCustomFontOptions = useCallback((results) => {
    return results.map((font) => {
      return {
        label: (
          <div className={styles['font-option']}>
            {font.url ? <FiCheck size={16} /> : <FiAlertTriangle size={16} />}{' '}
            <span>
              {font.family} ({font.variant})
            </span>
          </div>
        ),
        value: {
          id: font.id,
          family: font.family,
          weight: font.variant,
          url: font.url,
        },
      };
    });
  }, []);
  const fontOptions = buildCustomFontOptions(data?.results || []);
  const defaultFontOption = fontToAdd
    ? fontOptions.find((font) => fontToAdd.family === font.value.family)
    : null;

  const [isNewFontOpen, setIsNewFontOpen] = useState(false);
  const openNewFontWindow = useCallback(() => {
    setIsNewFontOpen(true);
    setIsFontsTableOpen(false);
  }, []);
  const closeNewFontWindow = useCallback(() => {
    setIsNewFontOpen(false);
    setIsFontsTableOpen(true);
  }, []);
  const addFontToFile = ({ family, weight, url }) => {
    const customFontImport = {
      tagName: 'mj-style',
      content: `@font-face {
        font-family: '${family}';
        font-weight: ${weight};
        font-display: swap;
        src: url(${url})
      }`,
    };
    mjHead.children.push(customFontImport);
    saveChanges();
    setFontToAdd(null);
  };
  const onAddFont = () => {
    if (fontToAdd.url) {
      addFontToFile(fontToAdd);
    } else {
      openNewFontWindow();
    }
  };

  const [isAddFallbackOpen, setIsAddFallbackOpen] = useState(false);
  const openFallbackWindow = useCallback(
    (baseFont) => {
      const fontVariant = (data?.results || []).find(
        (font) =>
          font.family === baseFont.family && font.variant === baseFont.weight
      );
      const font = { ...baseFont, baseFontId: fontVariant?.font_id };
      setFontToEdit(font);
      setIsAddFallbackOpen(true);
      setIsFontsTableOpen(false);
    },
    [data?.results]
  );
  const closeFallbackWindow = useCallback(() => {
    setIsAddFallbackOpen(false);
    setIsFontsTableOpen(true);
  }, []);

  const [isDeleteFontOpen, setIsDeleteFontOpen] = useState(false);
  const openDeleteFontWindow = useCallback((font) => {
    setFontToDelete({
      family: font.family,
      index: font.index,
    });
    setIsFontsTableOpen(false);
    setIsDeleteFontOpen(true);
  }, []);
  const closeDeleteFontWindow = useCallback(() => {
    setFontToDelete(false);
    setIsFontsTableOpen(true);
  }, []);
  const deleteFontFromFile = useCallback(
    (fontIndex) => {
      mjHead.children = mjHead.children.filter(
        (element, index) => index !== fontIndex
      );
      saveChanges();
      closeDeleteFontWindow();
    },
    [closeDeleteFontWindow, mjHead, saveChanges]
  );

  return (
    <>
      <Modal
        theme={theme}
        isOpen={isFontsTableOpen}
        onDismiss={close}
        aria-label="custom fonts administration"
        data-modal-type="custom-fonts-admin"
      >
        <Modal.Header onDismiss={close} title="Custom fonts" />
        <Modal.Content>
          <div className={styles['custom-fonts-container']}>
            <div className={styles['add-font']}>
              {isLoading && <Spinner />}

              {!isLoading && isError && (
                <span>Something went wrong, try again later.</span>
              )}

              {!isLoading && !isError && (
                <Dropdown
                  key={fontToAdd?.family}
                  className={styles['dropdown-fonts']}
                  size="s"
                  options={fontOptions}
                  defaultValue={defaultFontOption}
                  onChange={(event) => {
                    const newValue = event.value;
                    setFontToAdd(newValue);
                  }}
                />
              )}
              <Button
                className={styles['add-button']}
                hierarchy="secondary"
                size="s"
                theme={theme}
                icon={FiPlus}
                disabled={!fontToAdd || editor.isLoading}
                onClick={() => onAddFont()}
                loading={editor.isLoading}
              >
                Add font
              </Button>
            </div>

            <CustomFontsTable
              theme={theme}
              customFonts={customFonts}
              onDeleteFont={openDeleteFontWindow}
              onAddFallback={openFallbackWindow}
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button onClick={close} theme={theme} hierarchy="tertiary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {isNewFontOpen && fontToAdd && (
        <CustomFontModal
          theme={theme}
          isOpen={isNewFontOpen}
          close={closeNewFontWindow}
          font={fontToAdd}
          teamId={teamId}
          isEditing={false}
          onLoadFont={addFontToFile}
        />
      )}

      {fontToDelete && (
        <CustomFontDeleteModal
          font={fontToDelete}
          isOpen={isDeleteFontOpen}
          close={closeDeleteFontWindow}
          onDelete={deleteFontFromFile}
          theme={theme}
        />
      )}

      {fontToEdit && (
        <FallbackFontModal
          isOpen={isAddFallbackOpen}
          close={closeFallbackWindow}
          font={fontToEdit}
          teamId={teamId}
        />
      )}
    </>
  );
}

CustomFontsAdminModal.propTypes = {
  teamId: PropTypes.number,
  close: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
};

CustomFontsAdminModal.defaultProps = {
  teamId: null,
  theme: 'light',
};

export default CustomFontsAdminModal;
