import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Avatar from '@scalero-inc/forno-avatar';
import Spinner from '@scalero-inc/forno-spinner';

import {
  useTeamMemberDelete,
  useTeamMembersInvalidateQuery,
} from 'hooks/api/teams';
import { useUserQuery } from 'hooks/api/users';

import { canAddEditDeleteTeamMembers } from 'utils/permissions';

import TeamMembersPermissions from './team-members-permissions';

import styles from './style.module.css';

function TeamMembersTable({ teamId, members }) {
  const { data: user } = useUserQuery();
  const blocked = !canAddEditDeleteTeamMembers({ user, teamId });
  const invalidate = useTeamMembersInvalidateQuery({ teamId });

  const { mutate, isLoading } = useTeamMemberDelete({
    config: {
      onSuccess: () => {
        toast.success('Team member removed successfully');
        invalidate();
      },
      onError: () => {
        toast.error(`Error removing user from team, try again later`);
      },
    },
  });

  const handleClick = (memberId) => {
    const id = `${teamId}-${memberId}`;
    mutate(id);
  };

  return (
    <table className={styles['team-members-table']}>
      <thead>
        <tr>
          <th>name</th>
          <th>email</th>
          <th>role</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {members.map((member) => (
          <tr key={member.id} className={styles['team-members-table-row']}>
            <td className={styles['user-name-cell']}>
              <div className={styles['user-name-wrapper']}>
                <Avatar
                  size="m"
                  firstName={member.first_name}
                  lastName={member.last_name}
                  src={member.avatar}
                />
                {`${member.first_name} ${member.last_name}`}
              </div>
            </td>
            <td className={styles['user-email-cell']}>{member.email}</td>
            <td className={styles['permissions-cell']}>
              <TeamMembersPermissions
                permission={member.permission}
                teamId={teamId}
                memberId={member.id}
                blocked={blocked}
              />
            </td>
            <td className={styles['actions-cell']}>
              {isLoading ? (
                <Spinner />
              ) : (
                <button
                  type="button"
                  className={styles['remove-member-button']}
                  onClick={() => handleClick(member.id)}
                  disabled={blocked || member.permission === 'Admin'}
                >
                  <FiX />
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

TeamMembersTable.propTypes = {
  teamId: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      permission: PropTypes.string,
    })
  ),
};

TeamMembersTable.defaultProps = {
  members: [],
};

export default TeamMembersTable;
