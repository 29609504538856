import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCallback, useState } from 'react';

import { plans } from 'containers/dashboard-billing/constants';
import SubscriptionProToStarterModal from 'containers/subscription-pro-to-starter-modal';
import SubscriptionCancelModal from 'containers/subscription-cancel-modal';

import { useStarterSubscriptionCreateMutation } from 'hooks/api/subscriptions';
import { useUserInvalidateQuery } from 'hooks/api/users';

import PricingCardBase from '../pricing-card-base';

function PricingCardStarter({ activePlan, type, teamId, hasValidTrial }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const [cancelIsOpen, setCancelIsOpen] = useState(false);
  const openCancel = useCallback(
    () => setCancelIsOpen(true),
    [setCancelIsOpen]
  );
  const closeCancel = useCallback(
    () => setCancelIsOpen(false),
    [setCancelIsOpen]
  );

  const invalidateUser = useUserInvalidateQuery();

  const Icon = plans.starter.icon;

  let message;
  if (type === 'update' || hasValidTrial) {
    message = 'Change to starter';
  } else if (type === 'create') {
    message = 'Choose starter';
  } else if (activePlan) {
    message = 'Current Plan';
  }

  const cta = (
    <>
      <Icon /> {message}
    </>
  );

  const { mutate: createStarterSub } = useStarterSubscriptionCreateMutation({
    config: {
      onSuccess: () => {
        invalidateUser();
        navigate('/dashboard/personal/files/?payment=starter_success');
      },
      onError: () =>
        toast.error(
          'There was an error creating your subscription. Please try again later.'
        ),
    },
  });

  // Depending on where the card renders, the button actions will be different:
  // you can either update or create a subscription, and if there is a team associated,
  // we make a point of directing them to that specific plan
  const handleClick = () => {
    if (type === 'update' && teamId) {
      openCancel();
    } else if (type === 'update') {
      open();
    } else if (type === 'create') {
      createStarterSub();
    } else {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <SubscriptionProToStarterModal isOpen={isOpen} close={close} />
      <SubscriptionCancelModal
        isOpen={cancelIsOpen}
        close={closeCancel}
        planName="Team"
      />
      <PricingCardBase
        plan={plans.starter}
        activePlan={activePlan}
        cta={cta}
        onClick={handleClick}
        ctaLook="outline"
        hasValidTrial={hasValidTrial}
      />
    </>
  );
}

PricingCardStarter.propTypes = {
  activePlan: PropTypes.bool,
  type: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  hasValidTrial: PropTypes.bool,
};

PricingCardStarter.defaultProps = {
  activePlan: false,
  teamId: null,
  hasValidTrial: false,
};

export default PricingCardStarter;
