import React from 'react';
import PropTypes from 'prop-types';
import { TbChecklist } from 'react-icons/tb';

import Button from '@scalero-inc/forno-button';

import { CheckboxField } from '../checkout-form/utils';

import styles from './styles.module.css';

function OrderSummaryCard({
  isDisabled,
  loading,
  numberOfTasks,
  formik,
  viewOnly,
  unitPrice,
}) {
  const subTotal = unitPrice * numberOfTasks;
  const total = subTotal;

  const formatPrices = (price) => {
    return price.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className={styles['order-summary']}>
      <div className={styles['order-summary-header']}>
        <TbChecklist size={22} />
        <h3>Order Summary</h3>
      </div>

      {viewOnly && (
        <div className={styles['order-summary-tag']}>
          <h4>Order is being reviewed...</h4>
        </div>
      )}

      <section className={styles['order-summary-pricing-details']}>
        <div className={`${styles['order-summary-details']} ${styles.bold}`}>
          <h4 className="paragraph-sl">Emails ({numberOfTasks})</h4>
          <h4 className="paragraph-sl">${formatPrices(unitPrice)}</h4>
        </div>

        <div className={`${styles['order-summary-details']} ${styles.bold}`}>
          <h4 className="paragraph-sl">TOTAL AMOUNT (USD)</h4>
          <h4 className="paragraph-sl">${formatPrices(total)}</h4>
        </div>
        <p className={`paragraph-s ${styles.bold}`}>
          Want to apply a discount coupon? You can add it on the next screen.
        </p>
      </section>

      {!viewOnly && formik && (
        <>
          <section className={styles['order-summary-disclaimer']}>
            <CheckboxField
              name="isTermsAccepted"
              label={
                <span className={styles['order-summary-policies-anchors']}>
                  I accept{' '}
                  <a href="/policies" target="_blank">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="/policies/services" target="_blank">
                    Services policies
                  </a>
                  .
                </span>
              }
              formik={formik}
            />
            <CheckboxField
              name="wants_newsletter"
              label={
                <span className={styles['order-summary-newsletter']}>
                  Sign up for future marketing emails.
                </span>
              }
              formik={formik}
            />
          </section>

          <Button
            onClick={formik.submitForm}
            size="m"
            disabled={isDisabled}
            loading={loading}
          >
            Proceed to Purchase
          </Button>
        </>
      )}
    </div>
  );
}

OrderSummaryCard.propTypes = {
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  numberOfTasks: PropTypes.number,
  formik: PropTypes.shape({
    submitForm: PropTypes.func,
  }),
  viewOnly: PropTypes.bool,
  unitPrice: PropTypes.number.isRequired,
};

OrderSummaryCard.defaultProps = {
  isDisabled: false,
  loading: false,
  numberOfTasks: 0,
  viewOnly: false,
  formik: {
    submitForm: () => {},
  },
};

export default OrderSummaryCard;
