export const SOCIAL_ELEMENT_ATTRIBUTES = [
  'src',
  'href',
  'align',
  'alt',
  'background-color',
  'color',
  'css-class',
  'font-family',
  'font-size',
  'font-style',
  'font-weight',
  'icon-height',
  'icon-size',
  'line-height',
  'name',
  'padding',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'padding-top',
  'border-radius',
  'icon-padding',
  'text-padding',
  'sizes',
  'srcset',
  'rel',
  'target',
  'title',
  'text-decoration',
  'vertical-align',
];
