import React from 'react';

import PropTypes from 'prop-types';

import InputWithButton from 'components/input-with-button';

import styles from './style.module.css';

function PreFooterCta({ title, desc }) {
  return (
    <section className={styles['cta-section']}>
      <div className={styles['cta-wrapper']}>
        <h2>{title}</h2>
        <p className="paragraph-l">{desc}</p>
        <InputWithButton placeholder="Email Address" buttonLabel="Subscribe" />
      </div>
    </section>
  );
}

PreFooterCta.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

PreFooterCta.defaultProps = {
  title: "Don't miss any of our upcoming features",
  desc: 'Make sure to sign up for our newsletter for our full roadmap, notifications, updates and more.',
};

export default PreFooterCta;
