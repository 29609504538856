import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@scalero-inc/forno-button';

import styles from './styles.module.css';

function HowItWorksSection() {
  return (
    <section className={styles['cannoli-services-section-one-column-bg-20']}>
      <h2>How it works</h2>
      {/* 1st Card */}
      <div className={styles['cannoli-services-section-card-styled']}>
        <span
          className={styles['cannoli-services-section-title-domine-number']}
        >
          <p className={styles.cannoliTitleNumber}>1</p>
        </span>
        <h3>Place your order</h3>
        <div className={styles.cardContent}>
          <p className="paragraph-sl">
            Make sure to add all relevant information for coding. What we need
            to code your emails:
          </p>
          <ul className={styles['cannoli-services-section-list-flex']}>
            <li>Figma file URLs with view access.</li>
            <li>
              All hosted font URLs (unless they are web safe or Google fonts).
            </li>
          </ul>
        </div>
      </div>
      {/* 2nd Card */}
      <div className={styles['cannoli-services-section-card-styled']}>
        <span
          className={styles['cannoli-services-section-title-domine-number']}
        >
          <p className={styles.cannoliTitleNumber}>2</p>
        </span>
        <h3>Complete your payment</h3>
        <div className={styles.cardContent}>
          <p className="paragraph-sl">
            We process all our payments through Stripe.
          </p>
        </div>
      </div>
      {/* 3rd Card */}
      <div className={styles['cannoli-services-section-card-styled']}>
        <span
          className={styles['cannoli-services-section-title-domine-number']}
        >
          <p className={styles.cannoliTitleNumber}>3</p>
        </span>
        <h3>Sit back and relax</h3>
        <div className={styles.cardContent}>
          <p className="paragraph-sl">
            You’ll receive your completed project within <b>2 business days</b>.
            We may contact you via email if any vital information is missing.
            See what we need to code your emails{' '}
            <a href="/policies/services" target="_blank">
              here
            </a>
            .
          </p>
          <ul className={styles['cannoli-services-section-list-flex']}>
            <li>
              Your code will be delivered in HTML file along with Email On Acid
              email previews.
            </li>
            <li>You’ll also be able to access the code through Cannoli.</li>
          </ul>
        </div>
      </div>

      <div className={styles['cannoli-services-anchor-button']}>
        <Link to="checkout/">
          <Button size="l">Order now</Button>
        </Link>
      </div>
    </section>
  );
}

export default HowItWorksSection;
