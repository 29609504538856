import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as CannoliLogo } from 'svg/cannoli-white-logo.svg';

import styles from './style.module.css';

function HeaderPrivate({ children, className, withLogo }) {
  const customClassName = classnames(
    'header-private',
    styles['header-private'],
    'wrapper',
    className,
    {}
  );
  return (
    <header className={customClassName}>
      {withLogo ? (
        <Link to="/" className={styles['header-private-logo-wrapper']}>
          <CannoliLogo className={styles['header-private-cannoli-logo']} />
        </Link>
      ) : null}
      {children}
    </header>
  );
}

HeaderPrivate.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  withLogo: PropTypes.bool,
};

HeaderPrivate.defaultProps = {
  children: null,
  className: null,
  withLogo: true,
};

export default HeaderPrivate;
