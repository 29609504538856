import useDimensions from 'react-cool-dimensions';

import HtmlToReact from 'components/html-to-react';
import { useEditorView, useEditorViewDispatch } from '../editor-view-context';

import EditorPreviewIframe from './editor-preview-iframe';
import EditorPreviewImage from './editor-preview-image';
import EditorPreviewCompare from './editor-preview-compare';

import { useEditor, useEditorDispatch } from '../editor-context';
import ScrollToNodeById from './scroll-to-node-by-id';
import HighlightNodeById from './highlight-node-by-id';

import styles from './style.module.css';
import ClickToNode from './click-to-node';

function EditorPreview() {
  const { htmlWithIds, tagSelected } = useEditor();
  const { setTagSelected } = useEditorDispatch();
  const { viewPreview } = useEditorView();
  const { setViewPreviewSize } = useEditorViewDispatch();
  const { observe } = useDimensions({
    onResize: ({ width }) => {
      setViewPreviewSize(width);
    },
  });
  return (
    <section className={styles['preview-wrapper']} ref={observe}>
      {viewPreview === 'iframe' ? (
        <EditorPreviewIframe>
          <ClickToNode setNodeId={setTagSelected} />
          <ScrollToNodeById id={tagSelected.id} />
          <HighlightNodeById id={tagSelected.id} />
          <HtmlToReact html={htmlWithIds} />
        </EditorPreviewIframe>
      ) : null}
      {viewPreview === 'image' ? <EditorPreviewImage /> : null}
      {viewPreview === 'compare' ? <EditorPreviewCompare /> : null}
    </section>
  );
}

EditorPreview.propTypes = {};

EditorPreview.defaultProps = {};

export default EditorPreview;
