import { useQuery } from 'react-query';
import { useFetch } from 'context/fetch';

import { NODES_QUERY_KEY } from 'hooks/api/figma/constants';
import { nodesQuery } from './queries';

export function useNodesQuery(props = {}) {
  const { file, nodes, config = {}, key = NODES_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery(
    [key, file, nodes],
    () => nodesQuery({ client, file, nodes }),
    config
  );
}
