import { useState } from 'react';
import classNames from 'classnames';
import { Outlet, useParams } from 'react-router-dom';

import { useUserQuery } from 'hooks/api/users';

import PaymentStatus from 'containers/payment-status';
import SEO from 'components/seo';

import DashboardSidebar from './dashboard-sidebar';
import styles from './style.module.css';

function Dashboard() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { teamId } = useParams();

  const customClassName = classNames(styles['dashboard-page'], {
    [styles.collapsed]: isCollapsed,
  });

  const userQuery = useUserQuery();

  const team = userQuery.data?.teams?.find((t) => t.id === Number(teamId));

  if (userQuery.isLoading) return 'Loading...';

  return (
    <main className={customClassName}>
      <SEO name="Dashboard" />
      <DashboardSidebar
        isLoading={userQuery.isLoading}
        teams={userQuery.data?.teams}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        user={userQuery.data}
        team={team}
      />
      <PaymentStatus />
      <div className={styles['dashboard-main']}>
        <div className={styles['dashboard-content']}>
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
