import PropTypes from 'prop-types';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import { toast } from 'react-toastify';
import { FiTrash2 } from 'react-icons/fi';
import {
  useTeamFontStackDeleteMutation,
  useTeamFontStacksInvalidateQuery,
  useUserFontStackDeleteMutation,
  useUserFontStacksInvalidateQuery,
} from 'hooks/api/fonts';

function DeleteStackModal({ theme, close, isOpen, stack, teamId }) {
  const userFontsInvalidate = useUserFontStacksInvalidateQuery();
  const teamFontsInvalidate = useTeamFontStacksInvalidateQuery();
  const fontsInvalidate = teamId ? teamFontsInvalidate : userFontsInvalidate;

  const config = {
    onSuccess: () => {
      close();
      fontsInvalidate();
      toast.success('Fallback stack deleted successfully');
    },
    onError: () => {
      close();
      toast.error('Error deleting fallback stack, try again later');
    },
  };

  const userStackDeleteMutation = useUserFontStackDeleteMutation({
    config,
  });
  const teamStackDeleteMutation = useTeamFontStackDeleteMutation({
    teamId,
    config,
  });
  const deleteMutation = teamId
    ? teamStackDeleteMutation
    : userStackDeleteMutation;

  const deleteStack = () => {
    deleteMutation.mutate(stack.id);
  };

  return (
    <Modal
      theme={theme}
      isOpen={isOpen}
      onDismiss={close}
      status="danger"
      aria-label="delete stack warning"
    >
      <Modal.Header
        onDismiss={close}
        title="Delete fallback stack"
        icon={FiTrash2}
      />
      <p>
        Are you sure you want to delete the stack <strong>{stack.name}</strong>?
      </p>
      <p>
        This action <strong>cannot</strong> be undone.
      </p>
      <Modal.Footer>
        <Button theme={theme} hierarchy="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button
          theme={theme}
          hierarchy="primary"
          icon={FiTrash2}
          status="danger"
          onClick={() => deleteStack()}
          disabled={deleteMutation.isLoading}
          loading={deleteMutation.isLoading}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DeleteStackModal.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  stack: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  teamId: PropTypes.number,
};

DeleteStackModal.defaultProps = {
  theme: 'light',
  teamId: null,
};

export default DeleteStackModal;
