export const IMAGE_ATTRIBUTES = [
  'src',
  'href',
  'align',
  'alt',
  'width',
  'height',
  'container-background-color',
  'css-class',
  'fluid-on-mobile',
  'name',
  'padding',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'padding-top',
  'border',
  'border-top',
  'border-bottom',
  'border-left',
  'border-right',
  'border-radius',
  'rel',
  'sizes',
  'srcset',
  'target',
  'title',
  'usemap',
];
