import { getPercentage } from 'lib/figma-to-mjml/utils';
import { getFillColor } from '../../color';
import { getBorderRadiusAttributes, getBorder } from '../../border';
import { getWidth, getPadding } from '../../bounding-box';
import { getHorizontalSpacing } from './utils';
import { getSectionWidthPx } from '../mj-section/utils';
import { getAlignment, getErrors } from '../auto-layout';

function MjColumn({
  attributes,
  children,
  fills,
  parent,
  rectangleCornerRadii,
  tagName,
  ...rest
}) {
  const sectionWidthPx = getSectionWidthPx(parent);
  const columnWidthPx = getWidth(rest);
  const columnWidthPer = getPercentage(sectionWidthPx, columnWidthPx);
  const background = getFillColor({ fills });
  const align = getAlignment(rest);
  const border = getBorder({ ...rest });
  const cssClass = { 'css-class': 'column' };
  const horizontalSpacing = getHorizontalSpacing(sectionWidthPx, parent, rest);
  const padding = getPadding(parent, rest, tagName);
  const errorList = getErrors(rest);

  return [
    {
      tagName: 'mj-column',
      errors: errorList,
      children,
      attributes: {
        width: `${columnWidthPer}%`,
        ...attributes,
        ...cssClass,
        ...border,
        ...(padding && padding),
        ...(background && background),
        ...(align && align),
        ...getBorderRadiusAttributes({ rectangleCornerRadii }),
      },
    },
    ...horizontalSpacing,
  ];
}

export default MjColumn;
