import React from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';

import FileProcess from 'pages/file-process';
import Design from 'pages/design';
import Editor from 'pages/editor';
import Dashboard from 'pages/dashboard';
import ParseTest from 'pages/parse-test';
import Settings from 'pages/settings';

import DashboardPersonal from 'pages/dashboard/dashboard-personal';
import DashboardPersonalFiles from 'pages/dashboard/dashboard-personal/dashboard-personal-files';
import DashboardPersonalComponents from 'pages/dashboard/dashboard-personal/dashboard-personal-components';
import DashboardPersonalComponentDetail from 'pages/dashboard/dashboard-personal/dashboard-personal-component-detail';
import DashboardImportFile from 'pages/dashboard/dashboard-import-file';
import DashboardProject from 'pages/dashboard/dashboard-project';
import DashboardComponents from 'pages/dashboard/dashboard-components';
import DashboardTemplates from 'pages/dashboard/dashboard-templates';

import DashboardTeam from 'pages/dashboard/dashboard-team';
import NewTeam from 'pages/new-team';
import ChoosePlan from 'pages/choose-plan';
import ChangePlan from 'pages/change-plan';
import CreateAccount from 'pages/create-account';
import Welcome from 'pages/welcome';
import NotFound from 'pages/not-found';
import PrivacyPolicy from 'pages/privacy-policy';
import HelpCenter from 'pages/help-center';
import { PairedAccount } from 'pages/plugin';
import DashboardFallbackStacks from 'pages/dashboard/dashboard-resources/dashboard-fallback-stacks';
import DashboardCustomFonts from 'pages/dashboard/dashboard-resources/dashboard-custom-fonts';
import DashboardTemplateEditor from 'pages/dashboard/dashboard-template-editor';
import CannoliServices from 'pages/cannoli-services';
import Checkout from 'pages/cannoli-services/checkout';
import CheckoutSuccess from 'pages/cannoli-services/checkout/checkout-success';
import CheckoutCancel from 'pages/cannoli-services/checkout/checkout-cancel';
import ReadOnlyView from 'pages/cannoli-services/read-only-view';
import CannoliApp from 'pages/cannoli-app';

function Private() {
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('team_id');

  return (
    <Routes>
      <Route path="/" exact element={<Navigate to="/dashboard" />} />
      <Route path="/policies" element={<PrivacyPolicy />} />
      <Route path="/policies/:policy" element={<PrivacyPolicy />} />
      <Route path="/platform" element={<CannoliApp />} />
      <Route path="/services" element={<CannoliServices />} />
      <Route path="/services/files/:fileId" element={<ReadOnlyView />} />
      <Route path="/services/checkout" element={<Checkout />} />
      <Route path="/services/checkout/:orderType" element={<Checkout />} />
      <Route path="/services/checkout/success" element={<CheckoutSuccess />} />
      <Route path="/services/checkout/cancel" element={<CheckoutCancel />} />
      <Route path="/design" element={<Design />} />
      <Route path="/new-team" element={<NewTeam />} />
      <Route path="/process/:fileId" element={<FileProcess />} />
      <Route path="/editor/:fileId" element={<Editor />} />
      <Route path="/team/:teamId/block/:blockId" element={<Editor />} />
      <Route path="/parse-test" element={<ParseTest />} />
      <Route path="/paired-account" element={<PairedAccount />} />
      <Route path="/choose-plan" element={<ChoosePlan />} />
      <Route path="/change-plan" element={<ChangePlan />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/dashboard/" element={<Dashboard />}>
        <Route path="personal/" element={<DashboardPersonal />}>
          <Route path="templates/*" element={<DashboardTemplates />} />
          <Route path="blocks" element={<DashboardComponents />} />
          <Route path="resources/" element={<DashboardFallbackStacks />} />
          <Route
            path="resources/font-stacks"
            element={<DashboardFallbackStacks />}
          />
          <Route
            path="resources/custom-fonts"
            element={<DashboardCustomFonts />}
          />
          <Route path="files/import" element={<DashboardImportFile />} />
          <Route path="files" element={<DashboardPersonalFiles />} />
          <Route path="components" element={<DashboardPersonalComponents />} />
          <Route
            path="components/:componentId"
            element={<DashboardPersonalComponentDetail />}
          />
          <Route
            path="templates/:templateId"
            element={<DashboardTemplateEditor />}
          />
          <Route
            path="templates/create"
            element={<DashboardTemplateEditor />}
          />
          <Route path="" element={<Navigate to="files" />} />
        </Route>
        <Route
          path="teams/:teamId/projects/:projectId/templates/create"
          element={<DashboardTemplateEditor />}
        />
        <Route
          path="teams/:teamId/projects/:projectId/templates/:templateId"
          element={<DashboardTemplateEditor />}
        />
        <Route
          path="teams/:teamId/projects/:projectId/*"
          element={<DashboardProject />}
        />
        <Route path="teams/:teamId/*" element={<DashboardTeam />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="help-center/*" element={<HelpCenter />} />
        <Route path="" element={<Navigate to="personal" />} />
      </Route>
      <Route
        path="success.html"
        element={
          <Navigate
            to={`/dashboard/personal/files?payment=successful&team=${teamId}`}
          />
        }
      />
      <Route
        path="cancel.html"
        element={
          <Navigate
            to={`/dashboard/personal/files?payment=canceled&team=${teamId}`}
          />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Private;
