import { Navigate, Route, Routes } from 'react-router-dom';
import { useState, useCallback } from 'react';

import { useUserQuery } from 'hooks/api/users';
import {
  USER_SUBSCRIPTION_STATUS,
  USER_SUBSCRIPTION_TIER,
} from 'hooks/api/users/constants';

import SubscriptionAlertBanner from 'pages/dashboard/subscription-alert-banner';

import Billing from './billing';
import Integrations from './integrations';
import Profile from './profile';
import styles from './style.module.css';

function Settings() {
  const [isOpen, setIsOpen] = useState(true);
  const userQuery = useUserQuery();
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const subscription = userQuery.data?.subscriptions;

  const showBanner =
    !userQuery.data?.has_valid_trial &&
    ((isOpen &&
      subscription?.tier === USER_SUBSCRIPTION_TIER.PRO &&
      subscription?.status === USER_SUBSCRIPTION_STATUS.PENDING) ||
      (isOpen &&
        subscription?.tier === USER_SUBSCRIPTION_TIER.PRO &&
        subscription?.status === USER_SUBSCRIPTION_STATUS.OVERDUE));

  return (
    <div className={styles['dashboard-settings']}>
      {showBanner ? (
        <SubscriptionAlertBanner
          className={styles['dashboard-banner']}
          status={subscription?.status}
          subscriptionId={subscription?.id}
          close={close}
        />
      ) : null}
      <div className={styles['settings-content-wrapper']}>
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="billing" element={<Billing />} />
          <Route path="" element={<Navigate to="profile" />} />
        </Routes>
      </div>
    </div>
  );
}

export default Settings;
