export const USER_SUBSCRIPTION_STATUS = {
  ACTIVE: 'A',
  OVERDUE: 'O',
  PENDING: 'P',
  CANCELED: 'C',
};

export const USER_SUBSCRIPTION_TIER = {
  FREE: 'F',
  PRO: 'P',
  TEAM: 'T',
};
