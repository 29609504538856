import messages from '../messages';

function getErrors(tagname, node) {
  const errors = [];

  if (tagname === 'mj-hero' || tagname === 'mj-table') {
    errors.push(messages.error(messages.MSG.NOT_SUPPORTED_YET, node));
  } else {
    // TODO: Add suggestions
    errors.push(messages.error(messages.MSG.UNABLE_TO_CONVERT, node));
  }

  return errors;
}

function ScErrorMessage({ tagname, ...rest }) {
  const errorList = getErrors(tagname, rest);
  const message = `
    <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="width:100%;">
      <tr>
        <td align="center" bgcolor="#ff4640" style="font-family: sans-serif; font-size: 14px; line-height: 16px; color: #ffffff; padding: 6px;">
          Unable to convert the element with name: "${rest.name}"
        </td>
      </tr>
    </table>`;

  return [
    {
      tagName: 'mj-raw',
      errors: errorList,
      children: [],
      content: message,
    },
  ];
}

export default ScErrorMessage;
