import React, { createContext } from 'react';

import axios from 'axios';
import { useAuth } from './auth';

const FetchContext = createContext();
const { Provider } = FetchContext;

function FetchProvider(props) {
  const { user, renewToken } = useAuth();

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      return {
        ...config,
        headers: { Authorization: `Bearer ${user.access_token}` },
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (!error.response) {
        return Promise.reject(error);
      }

      if (
        error.response.status === 403 &&
        error.response.statusText === 'Forbidden'
      ) {
        const { data } = await renewToken();
        originalRequest.headers.Authorization = `JWT ${data.access_token}`;
        return authAxios(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        client: authAxios,
        publicClient: publicAxios,
      }}
      {...props}
    />
  );
}

const useFetch = () => React.useContext(FetchContext);

export { FetchProvider, useFetch };
