export const buildFontWeights = (fonts) => {
  if (fonts.length === 1 && fonts[0].variant === 'regular') {
    return '';
  }

  const fontVariants = fonts.reduce(
    (accumulator, element) => {
      const { variant } = element;
      if (variant.includes('italic')) {
        const italicVariant =
          variant === 'italic' ? '400' : variant.replace('italic', '');
        accumulator.italic.push(italicVariant);
      } else {
        const normalVariant = variant === 'regular' ? '400' : variant;
        accumulator.normal.push(normalVariant);
      }
      return accumulator;
    },
    {
      normal: [],
      italic: [],
    }
  );

  if (fontVariants.italic.length === 0) {
    const normalWeights = fontVariants.normal
      .sort((a, b) => {
        return a - b;
      })
      .join(';');
    return `:wght@${normalWeights}`;
  }

  const normalWeights = fontVariants.normal
    .sort((a, b) => {
      return a - b;
    })
    .map((element) => `0,${element}`)
    .join(';');
  const italicWeights = fontVariants.italic
    .sort((a, b) => {
      return a - b;
    })
    .map((element) => `1,${element}`)
    .join(';');

  return `:ital,wght@${normalWeights};${italicWeights}`;
};
