export async function teamCreateMutation({ client, values }) {
  const { name } = values || {};
  const bodyFormData = new FormData();
  bodyFormData.append('name', name || '');
  const { data } = await client.post(`teams/`, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function teamMemberAddMutation({ client, teamId, values }) {
  const { data } = await client.post(`teams/${teamId}/members/`, values);
  return data;
}

export async function teamMemberDeleteMutation({ client, teamId, memberId }) {
  const { data } = await client.delete(`teams/${teamId}/members/${memberId}/`);
  return data;
}

export async function teamMemberUpdateMutation({
  client,
  teamId,
  memberId,
  values,
}) {
  const { data } = await client.patch(
    `teams/${teamId}/members/${memberId}/`,
    values
  );
  return data;
}

export async function teamUpdateMutation({ client, teamId, values }) {
  const { name } = values;
  const bodyFormData = new FormData();
  bodyFormData.append('name', name || '');
  const { data } = await client.patch(`teams/${teamId}/`, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function teamUpdateLogoMutation({ client, teamId, file }) {
  const { name } = file || {};
  const bodyFormData = new FormData();
  bodyFormData.append('logo', file || '');
  return client.patch(`/teams/${teamId}`, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': `attachment; filename=${name}`,
    },
  });
}

export async function teamDeleteMutation({ client, teamId }) {
  const { data } = await client.delete(`teams/${teamId}/`);
  return data;
}
