import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';

import StrictModeDroppable from 'components/strict-mode-droppable';

import styles from './styles.module.css';
import DraggableBlock from './draggable-block';

function TemplateBuilder({ blocks, setBlocks }) {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newBlocks = [...blocks];
    const [reorderedItem] = newBlocks.splice(result.source.index, 1);

    newBlocks.splice(result.destination.index, 0, reorderedItem);

    const updatedBlocks = newBlocks.map((block, index) => ({
      ...block,
      position: index,
    }));

    setBlocks(updatedBlocks);
  };

  const deleteBlock = (index) => {
    const newBlocks = [...blocks];
    newBlocks.splice(index, 1);

    const updatedBlocks = newBlocks.map((block, i) => ({
      ...block,
      position: i,
    }));

    setBlocks(updatedBlocks);
  };

  return (
    <section className={styles['template-builder-container']}>
      <div className={styles['template-builder-section']}>
        <p>Rearrange blocks as needed.</p>
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="template-builder">
            {(provided) => (
              <div
                className={styles['template-builder']}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {blocks.map((block, index) => (
                  <DraggableBlock
                    key={`${block.id} ${index}`}
                    block={block}
                    index={index}
                    deleteBlock={() => deleteBlock(index)}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </section>
  );
}

TemplateBuilder.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setBlocks: PropTypes.func.isRequired,
};

export default TemplateBuilder;
