import { getSimpleColor } from './color';

export function getBorderRadiusAttributes({ rectangleCornerRadii }) {
  if (!rectangleCornerRadii) return {};
  const [top, right, bottom, left] = rectangleCornerRadii;
  return {
    borderRadius: `${Math.round(top)}px ${Math.round(right)}px ${Math.round(
      bottom
    )}px ${Math.round(left)}px`,
  };
}

export function getBorderRadiusAbsolute(element) {
  if (element.cornerRadius) {
    return { borderRadius: `${element.cornerRadius}px` };
  }
  return { borderRadius: '0px' };
}

export function getBorderAttributes({ strokes, strokeWeight, strokeDashes }) {
  const borderColor = getSimpleColor(strokes);
  return {
    borderColor,
    borderStyle: strokeDashes ? 'dashed' : 'solid',
    borderWidth: `${strokeWeight}px`,
  };
}

export function getBorder({ strokes, strokeWeight, strokeDashes }) {
  const borderStyle = strokeDashes ? 'dashed' : 'solid';
  const borderWidth = `${strokeWeight}px`;
  const borderColor = getSimpleColor(strokes);

  if (borderColor) {
    return { border: `${borderStyle} ${borderWidth} ${borderColor}` };
  }

  return '';
}
