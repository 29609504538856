import { teamCreateMutation } from '../teams/mutations';

export async function teamAndSubscriptionCreateMutation({ client, values }) {
  const { name, seats } = values;

  const team = await teamCreateMutation({ client, values: { name } });

  const { data } = await client.post(
    `subscriptions/create_team_subscription/`,
    { team: team.id, seats }
  );
  return data;
}

export async function teamSubscriptionCreateMutation({ client, team, seats }) {
  const { data } = await client.post(
    `subscriptions/create_team_subscription/`,
    { team, seats }
  );
  return data;
}

export async function proSubscriptionCreateMutation({ client }) {
  const { data } = await client.post(`subscriptions/create_user_subscription/`);
  return data;
}

export async function starterSubscriptionCreateMutation({ client }) {
  const { data } = await client.post(
    `subscriptions/create_user_free_subscription/`
  );
  return data;
}

export async function freeProTrialSubscriptionCreateMutation({ client }) {
  const { data } = await client.post(
    `subscriptions/create_user_trial_subscription/`
  );
  return data;
}

export async function subscriptionUpdatePaymentMethod({
  client,
  subscriptionId,
}) {
  const { data } = await client.post(
    `subscriptions/${subscriptionId}/update_default_payment/`
  );
  return data;
}

export async function updatePersonalSubscription({ client }) {
  const { data } = await client.post(`subscriptions/update_user_subscription/`);
  return data;
}

export async function cancelSubscription({ client, subscriptionId }) {
  const { data } = await client.post(
    `subscriptions/${subscriptionId}/cancel_subscription/`
  );
  return data;
}

export async function completeSubscription({ client, subscriptionId }) {
  const { data } = await client.get(
    `subscriptions/${subscriptionId}/complete_subscription_payment/`
  );
  return data;
}

export async function updateTeamSubscriptionMutation({
  client,
  subscriptionId,
  seats,
}) {
  const { data } = await client.post(
    `subscriptions/${subscriptionId}/update_team_subscription/`,
    { seats }
  );
  return data;
}

export async function collectInvoiceMutation({
  client,
  subscriptionId,
  invoiceId,
}) {
  const { data } = await client.post(
    `subscriptions/${subscriptionId}/collect_invoice/`,
    { invoice_id: invoiceId }
  );
  return data;
}
