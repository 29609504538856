import { domToReact } from 'html-react-parser';

export const DEFAULT_PARSE_OPTIONS = {
  trim: false,
  replace: (domNode) => {
    if (domNode.type === 'tag') {
      switch (domNode.name) {
        case 'html':
        case 'body':
        case 'head':
          return <>{domToReact(domNode.children, DEFAULT_PARSE_OPTIONS)}</>;
        default:
          return domNode;
      }
    }

    return domNode;
  },
};
