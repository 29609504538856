import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import Modal from '@scalero-inc/forno-modal';
import Input from '@scalero-inc/forno-input';
import Button from '@scalero-inc/forno-button';

import {
  useProjectCreateMutation,
  useTeamProjectsInvalidateQuery,
} from 'hooks/api/projects';

import { useFilesByTeamProjectInvalidateQuery } from 'hooks/api/files';

import styles from './style.module.css';

function ProjectCreateModal({ close, isOpen, team }) {
  const invalidateProjects = useTeamProjectsInvalidateQuery();
  const invalidateFilesTeamProject = useFilesByTeamProjectInvalidateQuery();
  const mutation = useProjectCreateMutation({
    team,
    config: {
      onSuccess: () => {
        toast.success('Created project successfully');
        invalidateProjects();
        invalidateFilesTeamProject();
        close();
      },
      onError: () => {
        toast.error('Failed to create project');
      },
    },
  });

  const handleCreateProject = (values) => {
    mutation.mutate({ name: values.name });
  };

  return (
    <Modal
      isOpen={isOpen}
      theme="light"
      aria-label="create project modal"
      onDismiss={close}
    >
      <Modal.Header title="Create new project" onDismiss={close} />
      <Formik initialValues={{ name: '' }} onSubmit={handleCreateProject}>
        {() => (
          <Form className={styles['form-container']}>
            <Field id="name" name="name">
              {({ field }) => (
                <Input
                  id="name"
                  size="s"
                  label="Project Name"
                  placeholder="Project Name"
                  theme="light"
                  {...field}
                />
              )}
            </Field>
            <Button theme="light" type="submit">
              Create Project
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

ProjectCreateModal.propTypes = {
  team: PropTypes.number,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ProjectCreateModal.defaultProps = {
  isOpen: false,
  team: null,
};

export default ProjectCreateModal;
