import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VisuallyHidden from '@reach/visually-hidden';
import { FiUploadCloud } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';

function FileUpload({
  children,
  className,
  onFile,
  accept,
  theme,
  hierarchy,
  look,
  size,
  withIcon,
  showInput,
  isLoading,
  ...field
}) {
  const customClassName = classNames('upload-button', className);
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    onFile(fileUploaded);
  };

  return (
    <>
      <Button
        className={customClassName}
        icon={withIcon ? FiUploadCloud : null}
        onClick={handleClick}
        hierarchy={hierarchy}
        theme={theme}
        size={size}
        loading={isLoading}
      >
        {children}
      </Button>
      {showInput ? (
        <input
          type="file"
          accept={accept}
          ref={hiddenFileInput}
          onChange={handleChange}
          {...field}
        />
      ) : (
        <VisuallyHidden>
          <input
            type="file"
            accept={accept}
            ref={hiddenFileInput}
            onChange={handleChange}
            {...field}
          />
        </VisuallyHidden>
      )}
    </>
  );
}

FileUpload.propTypes = {
  onFile: PropTypes.func.isRequired,
  accept: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.string,
  hierarchy: PropTypes.string,
  look: PropTypes.string,
  size: PropTypes.string,
  showInput: PropTypes.bool,
  withIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
};

FileUpload.defaultProps = {
  accept: undefined,
  children: null,
  className: null,
  theme: 'light',
  hierarchy: 'primary',
  look: null,
  size: null,
  showInput: false,
  withIcon: true,
  isLoading: false,
};

export default FileUpload;
