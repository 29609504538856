import { Navigate, Route, Routes } from 'react-router-dom';

import Navbar from 'components/navbar';

import Resources from './resources';
import styles from './style.module.css';

const items = [{ label: 'Resources', linkTo: 'resources' }];

function HelpCenter() {
  return (
    <div className={styles['dashboard-settings']}>
      <header className={styles['dashboard-header']}>
        <h2>Help Center</h2>
        <Navbar items={items} />
      </header>
      <div className={styles['settings-content-wrapper']}>
        <Routes>
          <Route index path="resources" element={<Resources />} />
          <Route path="" element={<Navigate to="resources" />} />
        </Routes>
      </div>
    </div>
  );
}

export default HelpCenter;
