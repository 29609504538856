import Input from '@scalero-inc/forno-input';
import PropTypes from 'prop-types';
import { FiXCircle } from 'react-icons/fi';

import styles from './styles.module.css';

function InputText({ label, placeholder, field, handleChange }) {
  const clearButton = () => {
    return <FiXCircle onClick={() => handleChange('')} />;
  };

  return (
    <div className={styles['input-text-container']}>
      <label htmlFor={label}>{label}</label>

      <Input
        {...field}
        placeholder={placeholder}
        size="s"
        theme="dark"
        variant="subtle"
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        sufix={clearButton}
      />
    </div>
  );
}

InputText.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape({ value: PropTypes.string }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

InputText.defaultProps = {
  label: '',
  placeholder: '',
};

export default InputText;
