import React from 'react';
import { useParams } from 'react-router-dom';

import CustomFonts from 'pages/dashboard/dashboard-resources/custom-fonts';

import styles from './styles.module.css';

function DashboardCustomFonts() {
  const { teamId } = useParams();
  const teamIdentifier = teamId ? Number(teamId) : null;

  return (
    <div className={styles['dashboard-resources']}>
      <CustomFonts teamId={teamIdentifier} />
    </div>
  );
}

export default DashboardCustomFonts;
