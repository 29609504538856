import PropTypes from 'prop-types';

import styles from './style.module.css';

function TableOfContents({ content }) {
  return (
    <aside className={styles['table-of-contents']}>
      <ul>
        {content.map((section) => (
          <li key={section.id} className="paragraph-r">
            <a href={`#${section.id}`}>{section.title}</a>
            {section.items ? (
              <ul>
                {section.items.map((item) => (
                  <li key={item.id} className="paragraph-r">
                    <a href={`#${item.id}`}>{item.title}</a>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </aside>
  );
}

TableOfContents.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
};

TableOfContents.defaultProps = {
  content: [],
};

export default TableOfContents;
