import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFile, FiTrash2 } from 'react-icons/fi';

import IconButton from '@scalero-inc/forno-icon-button';

import { useUserQuery } from 'hooks/api/users';

import Time from 'components/time';

import FileDeleteModal from 'containers/file-delete-modal';

import {
  canCreateEditDeleteTeamFile,
  canEditPersonalFiles,
} from 'utils/permissions';

import styles from './style.module.css';

function FileRow({
  name,
  id,
  updated_at: updatedAt,
  created_at: createdAt,
  preview,
  numFiles,
  page,
  setPage,
  disabled,
}) {
  const url = `/editor/${id}`;
  const [isOpen, setIsOpen] = useState();
  const customClassName = classnames('file-row', styles['file-row'], {});
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const { data: user } = useUserQuery();

  const navigate = useNavigate();

  const { teamId } = useParams();

  const blocked = teamId
    ? !canCreateEditDeleteTeamFile({ user, teamId })
    : !canEditPersonalFiles({ user });

  const handleClick = () => {
    navigate(url);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    open();
  };

  return (
    <tr
      className={customClassName}
      data-disabled={disabled}
      onClick={handleClick}
    >
      <td className={styles['file-preview-cell']}>
        {preview ? (
          <img src={preview} alt={name} />
        ) : (
          <div className={styles['row-placeholder-image']}>
            <FiFile />
          </div>
        )}
      </td>
      <td className={styles['file-name-cell']}>{name}</td>
      <td className={styles['file-date-updated-cell']}>
        {updatedAt ? <Time>{updatedAt}</Time> : 'NA'}
      </td>
      <td className={styles['file-created-at-cell']}>
        {createdAt ? <Time>{createdAt}</Time> : 'NA'}
      </td>
      <td className={styles['file-delete-cell']}>
        <IconButton onClick={handleDelete} disabled={blocked} status="danger">
          <FiTrash2 />
        </IconButton>
        <FileDeleteModal
          isOpen={isOpen}
          id={id}
          name={name}
          size="s"
          onDismiss={close}
          numFiles={numFiles}
          page={page}
          setPage={setPage}
        />
      </td>
    </tr>
  );
}

FileRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  preview: PropTypes.string,
  numFiles: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FileRow.defaultProps = {
  updated_at: new Date().toISOString(),
  preview: null,
  disabled: null,
};

export default FileRow;
