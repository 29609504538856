import React, { useMemo, useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { plans } from 'containers/dashboard-billing/constants';
import { CURRENT_PROFILE_STORAGE_KEY } from 'utils/constants';
import { omitNull, isTokenExpired } from './utils';

const AuthContext = React.createContext();
const USER_STORAGE_KEY = 'CANNOLI_FIGMA_USER';
const CLIENT_STATE_KEY = 'CLIENT_STATE_KEY';

const API_URL = process.env.REACT_APP_API_URL;

const HOST = window.location.host;
const PROTOCOL = window.location.protocol;
const REDIRECT_URI = `${PROTOCOL}//${HOST}/oauth/callback`;
const PLUGIN_CLIENT_ID = process.env.REACT_APP_PLUGIN_CLIENT_ID;
const PLUGIN_REDIRECT_URI = process.env.REACT_APP_PLUGIN_REDIRECT;

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER':
      return { ...state, user: null, loading: true };
    case 'SET_USER':
      return { ...state, user: action.payload.user, loading: false };
    case 'REMOVE_USER':
      localStorage.removeItem(USER_STORAGE_KEY);
      return { ...state, user: null, loading: false };
    default:
      throw new Error('Action not supported');
  }
};

const initialState = {
  user: null,
  loading: true,
};

function AuthProvider(props) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams] = useSearchParams();

  function handleUser(res) {
    if (!res.data) {
      console.error('Response is null');
      return;
    }

    const storedUser = localStorage.getItem(USER_STORAGE_KEY);
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const user = { ...omitNull(parsedUser), ...omitNull(res.data) };

    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    dispatch({ type: 'SET_USER', payload: { user } });
  }

  function handlePluginAuthorization(loginData, stateCode) {
    // If stateCode is equals to "1", the login does not come from Cannoli Plugin
    if (
      stateCode === '1' ||
      stateCode === plans.team.slug ||
      stateCode === plans.pro.slug ||
      stateCode === plans.proTrial.slug ||
      stateCode === plans.starter.slug
    ) {
      return;
    }

    const authorizationData = {
      allow: true,
      redirect_uri: PLUGIN_REDIRECT_URI,
      scope: 'read write groups',
      client_id: PLUGIN_CLIENT_ID,
      state: stateCode,
      response_type: 'code',
    };
    const headersData = {
      Authorization: `Bearer ${loginData.access_token}`,
      'Content-Type': 'application/json',
    };
    axios
      .post(`${API_URL}/o/authorize/`, authorizationData, {
        headers: headersData,
      })
      .catch((error) => {
        console.error('Error with login from plugin', error);
      });
  }

  const verifyLoginFromPlugin = useCallback(
    (loginData) => {
      const keyPair = searchParams.get('key_pair');
      const currentPath = window.location.pathname;

      // If there is key_pair param, the login comes from Cannoli Plugin
      if (keyPair && currentPath.includes('connect-account')) {
        handlePluginAuthorization(loginData, keyPair);
        navigate('/paired-account');
      }
    },
    [navigate, searchParams]
  );

  const loginWithFigma = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_URL}/login`, {
        params: { redirect_uri: REDIRECT_URI },
      });

      // If there is key_pair param, the login comes from Cannoli Plugin
      const keyPair = searchParams.get('key_pair');
      // If the user is creating a new account, accountCreation will be defined
      const accountCreation = searchParams.get('create-account');

      let loginUrl;
      if (keyPair) {
        loginUrl = data.login_url.replace('state=1', `state=${keyPair}`);
      } else if (accountCreation) {
        loginUrl = data.login_url.replace(
          'state=1',
          `state=${accountCreation}`
        );
      } else {
        loginUrl = data.login_url;
      }

      window.location.href = loginUrl;
    } catch (e) {
      console.error(e);
    }
  }, [searchParams]);

  const loginWithPassword = useCallback(async ({ username, password }) => {
    const res = await axios.post(`${API_URL}/user/login/`, {
      username,
      password,
    });
    handleUser(res);
  }, []);

  const renewToken = useCallback(() => {
    const storedUser = localStorage.getItem(USER_STORAGE_KEY);
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const user = { ...omitNull(parsedUser) };

    if (!storedUser)
      throw new Error('Tried to refresh the token but there is no user saved');

    return axios
      .post(`${API_URL}/refresh/`, { refresh_token: user.refresh_token })
      .then((result) => {
        handleUser(result);
        verifyLoginFromPlugin(result);
      })
      .catch((error) => {
        dispatch({ type: 'REMOVE_USER' });
        return Promise.reject(error);
      });
  }, [verifyLoginFromPlugin]);

  const validateUser = useCallback(() => {
    let storedUser = localStorage.getItem(USER_STORAGE_KEY);

    try {
      JSON.parse(storedUser);
    } catch (e) {
      storedUser = null;
      dispatch({ type: 'REMOVE_USER' });
      console.warn('Failed parsing user token');
    }

    const user = storedUser ? JSON.parse(storedUser) : null;

    if (!user) {
      dispatch({ type: 'REMOVE_USER' });
      return null;
    }

    const isAccessTokenExpired = isTokenExpired(user.access_token);
    const isRefreshTokenExpired = isTokenExpired(user.refresh_token);

    if (isAccessTokenExpired && isRefreshTokenExpired) {
      console.info('Access token and refresh token are expired');
      dispatch({ type: 'REMOVE_USER' });
      return null;
    }

    dispatch({ type: 'FETCH_USER' });

    if (!isAccessTokenExpired) {
      dispatch({ type: 'SET_USER', payload: { user } });
      verifyLoginFromPlugin(user);
      console.info('Valid Access token');
      return null;
    }

    console.info('Access token expired refresh token still valid');
    return renewToken();
  }, [renewToken, verifyLoginFromPlugin]);

  useEffect(() => {
    validateUser();
  }, [validateUser]);

  const login = useCallback(({ code, stateCode }) => {
    return axios
      .get(`${API_URL}/login`, {
        params: {
          redirect_uri: REDIRECT_URI,
          code,
          state: stateCode,
        },
      })
      .then((result) => {
        handleUser(result);
        handlePluginAuthorization(result.data, stateCode);
      })
      .catch((error) => Promise.reject(error));
  }, []);

  const logout = useCallback(() => {
    dispatch({ type: 'REMOVE_USER' });
    navigate('/');
  }, [navigate]);

  const [currentProfile, setCurrentProfile] = React.useState(null);

  // get current profile from local storage
  useEffect(() => {
    const storedProfile = localStorage.getItem(CURRENT_PROFILE_STORAGE_KEY);
    const parsedProfile = storedProfile ? JSON.parse(storedProfile) : null;
    setCurrentProfile(parsedProfile);
  }, []);

  const value = useMemo(() => {
    if (state.loading) {
      return null;
    }
    return {
      user: state.user,
      login,
      loginWithFigma,
      loginWithPassword,
      logout,
      renewToken,
      currentProfile,
      setCurrentProfile,
    };
  }, [
    state.user,
    state.loading,
    renewToken,
    logout,
    login,
    loginWithFigma,
    loginWithPassword,
    currentProfile,
  ]);

  if (state.loading) {
    return null;
  }

  return <AuthContext.Provider value={value} {...props} />;
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth, CLIENT_STATE_KEY };
