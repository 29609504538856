import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiCheck } from 'react-icons/fi';

import styles from './style.module.css';

export default function RadioButton({
  className,
  checked,
  value,
  disabled,
  label,
  ...rest
}) {
  const isChecked = checked;
  const customClassName = classnames(
    'radio-button-container',
    styles['radio-button-container'],
    {
      [styles['-disabled']]: disabled,
    }
  );

  const checkmarkClassName = classnames(styles['radio-button-checkmark'], {
    [styles['-checked']]: isChecked,
  });

  return (
    <label className={customClassName} htmlFor={value}>
      <span className={checkmarkClassName}>
        <FiCheck />
      </span>
      <input type="radio" value={value} disabled={disabled} {...rest} />
      <span className={styles['radio-button-label']}>{label}</span>
    </label>
  );
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

RadioButton.defaultProps = {
  className: null,
  disabled: false,
  checked: false,
};
