import { FiGrid, FiList } from 'react-icons/fi';

export const sortingOptions = [
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
];

export const viewOptions = [
  {
    icon: FiGrid,
    value: 'grid',
  },
  {
    icon: FiList,
    value: 'list',
  },
];
