import { getPadding } from '../bounding-box';
import { getAlignment } from '../utils';

function MjSocial({ attributes, parent, children, tagName, ...rest }) {
  const cssClass = { 'css-class': 'social' };
  const alignment = getAlignment(parent);
  const padding = getPadding(parent, rest, tagName);

  return [
    {
      tagName: 'mj-social',
      children,
      errors: [],
      attributes: {
        ...attributes,
        ...cssClass,
        align: alignment,
        ...(padding && padding),
      },
    },
  ];
}
export default MjSocial;
