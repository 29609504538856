import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Logo from 'components/logo';

import { useParams } from 'react-router-dom';
import SidebarBottom from 'containers/sidebar-bottom';
import SidebarLabelItem from 'components/sidebar-label-item';

import { FiLayers } from 'react-icons/fi';
import { MdCompare } from 'react-icons/md';
import { HiOutlinePuzzle } from 'react-icons/hi';
import { BsFileEarmarkFont } from 'react-icons/bs';

import { useUserQuery } from 'hooks/api/users';
import {
  canEditOnProFeatures,
  hasAccessToProFeatures,
} from 'utils/permissions';

import { useEditor } from '../editor-context';

import styles from './style.module.css';

const sidebarOptions = [
  {
    icon: <FiLayers size={16} />,
    title: 'Layers',
    name: 'layout',
  },
  {
    icon: <HiOutlinePuzzle size={16} />,
    title: 'Blocks library',
    name: 'blocks',
  },
  {
    icon: <BsFileEarmarkFont size={16} />,
    title: 'Fonts settings',
    name: 'fonts',
  },
  {
    icon: <MdCompare size={16} />,
    title: 'Regenerate & Compare',
    name: 'rebase',
  },
];

function EditorSidebar({
  isCollapsed,
  feature,
  setFeature,
  editorView,
  setEditorView,
}) {
  const customClassName = classNames(styles['editor-sidebar'], {
    [styles.collapsed]: isCollapsed,
  });

  const { teamId, templateId, isCopy } = useEditor();
  const { data: user } = useUserQuery();

  const canEdit = canEditOnProFeatures(user, teamId);
  const isBlocked = !hasAccessToProFeatures({ user }) && !canEdit;

  const { blockId } = useParams();

  React.useEffect(() => {
    if (blockId) {
      setFeature('layout');
    }
  }, [blockId, setFeature]);

  const generateTooltipContent = (option) => {
    if (option.name === 'rebase') {
      if (!!templateId || !!blockId) {
        return 'File Rebase (Only available for files)';
      }
      return option.title;
    }

    if (editorView === 'visual' || editorView === 'rebase') {
      if (option.name === 'blocks' && isBlocked) {
        return `${option.title} (Pro)`;
      }
      return option.title;
    }

    return 'Switch to visual to access this feature';
  };

  const shouldShowOption = (option) => {
    if (option.name === 'blocks' && !!blockId) return false;
    if (option.name === 'fonts' && !!blockId) return false;
    if (option.name === 'rebase' && (!!templateId || !!isCopy || !!blockId))
      return false;
    return true;
  };

  return (
    <aside className={customClassName}>
      <div className={styles['editor-sidebar-top']}>
        <header className={styles['editor-sidebar-header']}>
          <Logo collapsed={isCollapsed} />
        </header>
        <div>
          {sidebarOptions.filter(shouldShowOption).map((option) => (
            <SidebarLabelItem
              key={option.name}
              icon={option.icon}
              title={option.title}
              action={() => {
                if (option.name === 'rebase') {
                  setEditorView('rebase');
                } else {
                  setEditorView('visual');
                  setFeature(option.name);
                }
              }}
              isCollapsed={isCollapsed}
              isSelected={
                (feature === option.name && editorView === 'visual') ||
                editorView === option.name
              }
              disabled={
                (isBlocked && option.name === 'blocks') ||
                (editorView === 'code' && option.name !== 'rebase')
              }
              tooltipConfig={{
                key: option.name,
                content: (
                  <p key={option.title} className="font-paragraph-s-sb">
                    {generateTooltipContent(option)}
                  </p>
                ),
                placement: 'right',
                theme: 'dark',
                status:
                  option.name === 'blocks' && isBlocked ? 'warning' : 'default',
              }}
            />
          ))}
        </div>
      </div>

      <SidebarBottom isCollapsed={isCollapsed} />
    </aside>
  );
}

EditorSidebar.propTypes = {
  isCollapsed: PropTypes.bool,
  setFeature: PropTypes.func.isRequired,
  feature: PropTypes.string.isRequired,
  editorView: PropTypes.string.isRequired,
  setEditorView: PropTypes.func,
};

EditorSidebar.defaultProps = {
  isCollapsed: true,
  setEditorView: () => {},
};

export default EditorSidebar;
