import React from 'react';
import Spinner from '@scalero-inc/forno-spinner';
import propTypes from 'prop-types';

import HtmlToReact from 'components/html-to-react';
import { STATUS } from 'components/overlay-node';
import { REBASE_COLUMN_ORIGIN_NEW_VERSION } from 'utils/constants';

import RebasePreviewIframe from './rebase-preview-iframe';
import SelectNode, { EVENT_TYPES } from './select-node';
import HighlightNodeById from './highlight-node-by-id';
import AddDragAndDropContainers from './add-drag-and-drop-containers';
import HighlightBody from './highlight-body';

import styles from './style.module.css';

function RebasePreview({
  htmlWithIds,
  setSelectedElement,
  selectedElement,
  origin,
  replaceElement,
  insertNewElement,
  deleteElement,
  isLoading,
}) {
  if (!htmlWithIds || isLoading)
    return (
      <div className={styles['message-container']}>
        <span>
          <Spinner size="l" hierarchy="secondary" />
        </span>
      </div>
    );

  return (
    <section className={styles['preview-wrapper']}>
      {origin === REBASE_COLUMN_ORIGIN_NEW_VERSION ? (
        <RebasePreviewIframe disableLinks>
          <SelectNode
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            origin={origin}
          />
          <HighlightNodeById
            id={selectedElement[origin]?.id}
            origin={origin}
            action={deleteElement}
            status={STATUS.ERROR}
          />
          <HtmlToReact html={htmlWithIds}>
            {({ parsedHtml }) => {
              return (
                <AddDragAndDropContainers
                  parsedHtml={parsedHtml}
                  selectedElement={selectedElement}
                  setSelectedElement={setSelectedElement}
                  onInsertElement={insertNewElement}
                  onReplaceElement={replaceElement}
                />
              );
            }}
          </HtmlToReact>
        </RebasePreviewIframe>
      ) : (
        <RebasePreviewIframe disableLinks>
          <SelectNode
            selectedElement={selectedElement}
            setSelectedElement={setSelectedElement}
            origin={origin}
            eventType={EVENT_TYPES.MOUSE_OVER}
          />
          <HighlightNodeById
            id={selectedElement[origin]?.id}
            origin={origin}
            action={replaceElement}
            status={
              selectedElement[origin]?.isValid === undefined ||
              selectedElement[origin]?.isValid
                ? STATUS.SUCCESS
                : STATUS.ERROR
            }
          />
          <HtmlToReact html={htmlWithIds}>
            {({ parsedHtml }) => {
              return <HighlightBody parsedHtml={parsedHtml} />;
            }}
          </HtmlToReact>
        </RebasePreviewIframe>
      )}
    </section>
  );
}

RebasePreview.propTypes = {
  htmlWithIds: propTypes.string.isRequired,
  selectedElement: propTypes.shape({}).isRequired,
  setSelectedElement: propTypes.func.isRequired,
  origin: propTypes.string.isRequired,
  replaceElement: propTypes.func,
  insertNewElement: propTypes.func,
  deleteElement: propTypes.func,
  isLoading: propTypes.bool,
};

RebasePreview.defaultProps = {
  replaceElement: () => {},
  insertNewElement: () => {},
  deleteElement: () => {},
  isLoading: false,
};

export default RebasePreview;
