import React from 'react';

import Button from '@scalero-inc/forno-button';

import unionImg from 'images/home/Union.png';

import styles from './styles.module.css';

function WeAreHereSection() {
  return (
    <section className={styles.twoColumnSection}>
      <div className={styles.columnImage}>
        <img src={unionImg} alt="Union" className={styles.image} />
      </div>

      <div className={styles.columnContent}>
        <h2>Still on the fence?</h2>
        <p className="paragraph-l">
          Write to{' '}
          <a className={styles.link} href="mailto:hello@cannoli.app">
            hello@cannoli.app
          </a>{' '}
          or schedule a call. We’ll be happy to answer any questions.
        </p>
        <a
          href="https://calendly.com/daniela-acosta-b/lets-meet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button hierarchy="secondary" size="l">
            Schedule a call
          </Button>
        </a>
      </div>
    </section>
  );
}

export default WeAreHereSection;
