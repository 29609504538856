import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import {
  useCancelSubscription,
  useSubscriptionInvalidateQuery,
} from 'hooks/api/subscriptions';
import { useUserInvalidateQuery } from 'hooks/api/users';

function SubscriptionCancelModal({ isOpen, close, subscriptionId, planName }) {
  const invalidateUser = useUserInvalidateQuery();
  const invalidateSubscription = useSubscriptionInvalidateQuery({
    subscriptionId,
  });
  const { mutate: cancelSubscription } = useCancelSubscription({
    subscriptionId,
    config: {
      onSuccess: () => {
        invalidateUser();
        invalidateSubscription();
        close();
        toast.success('Subscription canceled');
      },
      onError: () =>
        toast.error(
          'There was an error canceling your subscription. Please contact support.'
        ),
    },
  });

  const message =
    planName === 'Pro' ? (
      <p>
        Are you sure you want to cancel your subscription? You will lose access
        to all Pro features when your billing period ends.
      </p>
    ) : (
      <p>
        Are you sure you want to cancel your subscription?{' '}
        <strong>You and all team members will lose access</strong> to all your
        projects, files and Team features when your billing period ends.
      </p>
    );

  return (
    <Modal
      onDismiss={close}
      isOpen={isOpen}
      aria-label="cancel subscription modal"
      status="danger"
    >
      <Modal.Header title="Cancel subscription" onDismiss={close} />
      {message}
      <Modal.Footer>
        <Button onClick={close} hierarchy="tertiary">
          Go back
        </Button>
        <Button status="danger" onClick={() => cancelSubscription()}>
          Cancel subscription
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SubscriptionCancelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  subscriptionId: PropTypes.number,
  planName: PropTypes.string.isRequired,
};

SubscriptionCancelModal.defaultProps = {
  subscriptionId: null,
};

export default SubscriptionCancelModal;
