import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import classNames from 'classnames';

import IconButton from '@scalero-inc/forno-icon-button';

import { useUserQuery } from 'hooks/api/users';

import { getFreeTrialEndDate } from 'utils/permissions';

import styles from './style.module.css';

function NoSubscriptionsAlert({ close, className }) {
  const customClassName = classNames(
    styles['no-subscriptions-alert'],
    className
  );
  const { data: user } = useUserQuery();
  const freeTrialEndDate = getFreeTrialEndDate({ user });

  return (
    <div className={customClassName}>
      <div className={styles['no-subscriptions-alert-content']}>
        Cannoli is completely free to use right now. Subscribe before{' '}
        {freeTrialEndDate}, 2022 to continue enjoying all of its features.
        <Link
          to="/choose-plan"
          className="forno-button font-block-s-sb"
          data-size="s"
          data-hierarchy="tertiary"
          data-theme="light"
        >
          See plans
        </Link>
      </div>
      <IconButton onClick={close} hierarchy="tertiary">
        <FiX />
      </IconButton>
    </div>
  );
}

NoSubscriptionsAlert.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string,
};

NoSubscriptionsAlert.defaultProps = {
  className: null,
};

export default NoSubscriptionsAlert;
