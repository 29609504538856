import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@scalero-inc/forno-button';

import { plans } from 'containers/dashboard-billing/constants';

import cannoliAppImg from 'images/home/cannoli-app-asset.png';
import cannoliServicesImg from 'images/home/cannoli-services-asset.png';

import styles from './styles.module.css';

function OurOffering() {
  return (
    <section className={styles.container}>
      <h2>Our offerings</h2>

      <div className={styles.columns}>
        <div className={styles.card}>
          <img
            src={cannoliAppImg}
            alt="Descripción alternativa de la imagen"
            className={styles.cardImage}
          />

          <div className={styles.cardContent}>
            <div>
              <h3>Cannoli App</h3>
              <p className="paragraph-r">
                Go from Figma design to email code in seconds. Do any last mile
                edits in Cannoli’s easy-to-use editor, and export your finalized
                code directly into your email service provider.
              </p>
            </div>

            <div className={styles.cardButtons}>
              <Link to={`/login?create-account=${plans.proTrial.slug}`}>
                <Button hierarchy="primary">Start for free</Button>
              </Link>
              <Link to="/platform#pricing">
                <Button hierarchy="secondary">See pricing</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <img
            src={cannoliServicesImg}
            alt="Descripción alternativa de la imagen"
            className={styles.cardImage}
          />

          <div className={styles.cardContent}>
            <div>
              <h3>Cannoli Services</h3>
              <p className="paragraph-r">
                Our team of email experts will handle the whole process of
                designing, coding and polishing your email for you. Just provide
                your Figma design (or let us create it for you), and we’ll
                handle the rest, including quality assurance.
              </p>
            </div>

            <div className={styles.cardButtons}>
              <Link to="/services">
                <Button hierarchy="primary">Learn more</Button>
              </Link>
              <Link to="/services#services-pricing">
                <Button hierarchy="secondary">See pricing</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurOffering;
