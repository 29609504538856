import { FiPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@scalero-inc/forno-button';

import styles from './style.module.css';

function FileNewButton({ disabled }) {
  const navigate = useNavigate();
  const url = 'import';

  const handleClick = () => {
    navigate(url);
  };

  return (
    <div className={styles['file-new-button']}>
      <Button onClick={handleClick} icon={FiPlus} disabled={disabled}>
        New File
      </Button>
    </div>
  );
}

FileNewButton.propTypes = {
  disabled: PropTypes.bool,
};

FileNewButton.defaultProps = {
  disabled: null,
};

export default FileNewButton;
