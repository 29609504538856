import React from 'react';
import Button from '@scalero-inc/forno-button';
import { Link, useLocation } from 'react-router-dom';
import { BsMegaphone } from 'react-icons/bs';

import { CANNOLI_SERVICES } from '../data';
import styles from './styles.module.css';

function PricingAndFeaturesSection() {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === '#services-pricing') {
      const pricing = document.getElementById('services-pricing');
      if (pricing) pricing.scrollIntoView();
    } else {
      const root = document.getElementById('root');
      if (root) root.scrollIntoView();
    }

    return () => {};
  }, [location]);

  return (
    <>
      <div className={styles.divider} />
      <section className={styles['services-section']} id="services-pricing">
        <h1>Pricing and Features</h1>
        <div className={styles['pricing-section']}>
          <div className={styles['cards-container']}>
            {Object.keys(CANNOLI_SERVICES).map((serviceKey) => {
              const service = CANNOLI_SERVICES[serviceKey];
              return (
                <div key={service.id} className={styles.card}>
                  <h4>{service.name}</h4>
                  <h2>${service.price} USD / email</h2>
                  <h4 className={styles['align-left']}>
                    {service.description}
                  </h4>
                  <div>
                    <ul className="paragraph-r">
                      {service.features.map((element, index) => {
                        if (typeof element === 'object') {
                          return (
                            <li key={`${serviceKey}_${index}`}>
                              {element.feature}
                              <br />
                              <span>{element.detail}</span>
                            </li>
                          );
                        }

                        return (
                          <li key={`${serviceKey}_${index}`}>{element}</li>
                        );
                      })}
                    </ul>
                  </div>
                  <h4>{service.time}</h4>
                  <Link to={`${service.cta.url}${service.id}`}>
                    <Button size="s">{service.cta.label}</Button>
                  </Link>
                  <Link
                    to={service.policy.url}
                    className={styles['policy-button']}
                  >
                    <Button size="s" hierarchy="tertiary">
                      {service.policy.label}
                    </Button>
                  </Link>
                </div>
              );
            })}
          </div>

          <div className={styles['coupon-section']}>
            <div>
              <BsMegaphone size={40} />
            </div>
            <div>
              <p>
                For a limited time, get your first email for{' '}
                <b>
                  <u>$1 USD</u>
                </b>
                . <br />
                Use the coupon code <b>CANNOLI</b> at checkout.
              </p>
              <p className="paragraph-r">
                Only available for the Email Coding service, and for only one
                (1) email.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingAndFeaturesSection;
