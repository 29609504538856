import { useUserQuery } from 'hooks/api/users';

import DashboardBilling from 'containers/dashboard-billing';
import PersonalBillingPricing from 'containers/personal-billing-pricing';

function Billing() {
  const { data: user, isLoading } = useUserQuery();

  if (isLoading) return 'Loading...';
  if (!user.subscriptions) return <PersonalBillingPricing />;

  return <DashboardBilling subscription={user.subscriptions} />;
}

export default Billing;
