import messages from '../messages';

export function getAlignment({ counterAxisAlignItems, layoutMode }) {
  let verticalAlign;

  if (layoutMode === 'HORIZONTAL') {
    if (counterAxisAlignItems === 'CENTER') {
      verticalAlign = 'middle';
    }
  } else if (layoutMode === 'VERTICAL') {
    if (counterAxisAlignItems === 'CENTER') {
      verticalAlign = 'middle';
    }
  }

  if (verticalAlign) {
    return {
      verticalAlign,
    };
  }

  return null;
}

export function isAutoLayout({ layoutMode }) {
  if (
    layoutMode &&
    (layoutMode === 'VERTICAL' || layoutMode === 'HORIZONTAL')
  ) {
    return true;
  }

  return false;
}

export function getErrors(node) {
  const errors = [];

  if (!isAutoLayout(node)) {
    errors.push(messages.error(messages.MSG.NO_AUTO_LAYOUT, node));
  }

  return errors;
}
