import { useFetch } from 'context/fetch';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  closeRegenCompareMutation,
  initRegenCompareMutation,
  refreshRegenCompareMutation,
  saveRegenCompareMutation,
} from './mutations';
import { regenCompareQuery } from './queries';
import { REGEN_COMPARE_QUERY_KEY } from '../constants';

export const useRegenCompareInvalidateQuery = (props = {}) => {
  const { key = REGEN_COMPARE_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
};

export const useRegenCompareQuery = (props) => {
  const { config, fileId, key = REGEN_COMPARE_QUERY_KEY } = props;
  const { client } = useFetch();

  return useQuery(
    [key, fileId],
    () => regenCompareQuery({ client, fileId }),
    config
  );
};

export const useInitRegenCompareMutation = (props) => {
  const { config, fileId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => initRegenCompareMutation({ client, fileId, values }),
    config
  );
};

export const useSaveRegenCompareMutation = (props) => {
  const { config, fileId, regenId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => saveRegenCompareMutation({ client, fileId, values, regenId }),
    config
  );
};

export const useCloseRegenCompareMutation = (props) => {
  const { config, fileId, regenId } = props;
  const { client } = useFetch();

  return useMutation(
    () => closeRegenCompareMutation({ client, fileId, regenId }),
    config
  );
};

export const useRefeshRegenCompareMutation = (props) => {
  const { config, fileId, regenId } = props;
  const { client } = useFetch();

  return useMutation(
    () => refreshRegenCompareMutation({ client, fileId, regenId }),
    config
  );
};
