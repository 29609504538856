import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';

function WorkflowStep({ step, name, children, image, link }) {
  const linkClassname = classnames('paragraph-sl', styles['step-link']);

  return (
    <div className={styles['step-container']}>
      <div className={styles['step-image']}>
        <img src={image.src} alt={image.alt} />
      </div>
      <div className={styles['step-description']}>
        <span className="paragraph-sl">Step {step}</span>
        <h3>{name}</h3>
        <p className="paragraph-sl">{children}</p>
        {link && (
          <Link to={link.path} className={linkClassname}>
            {link.name}
          </Link>
        )}
      </div>
    </div>
  );
}

WorkflowStep.defaultProps = {
  link: null,
  children: null,
};

WorkflowStep.propTypes = {
  step: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }),
};

export default WorkflowStep;
