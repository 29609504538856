import React from 'react';

import Button from '@scalero-inc/forno-button';

import { FiCheckCircle } from 'react-icons/fi';
import { RiArrowLeftSLine } from '@hacknug/react-icons/ri';
import { MdLockOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import PublicPage from 'containers/public-page';

import PreFooterCta from 'components/pre-footer-cta';

import divider from 'images/home/divider.png';
import CheckoutForm from './checkout-form';

import styles from './styles.module.css';

function Checkout() {
  const navigate = useNavigate();
  const { orderType } = useParams();

  return (
    <PublicPage>
      <article className={styles['checkout-container']}>
        <section className={styles['checkout-header']}>
          <Button
            hierarchy="secondary"
            icon={RiArrowLeftSLine}
            onClick={() => navigate(-1)}
          >
            return to Cannoli services
          </Button>
          <header className={styles['checkout-container-title']}>
            <MdLockOutline size={22} />
            <h3>Secure Checkout</h3>
          </header>
        </section>
        <section className={styles['checkout-section']}>
          <header>
            <h2>Details of your project</h2>
            <p className="paragraph-sl">
              Please provide the necessary details for us to help you build your
              project the right way.
            </p>
          </header>
          <CheckoutForm orderType={orderType} />
        </section>
        <section className={styles['what-you-get-section']}>
          <header>
            <h2>What you get:</h2>
            <p className="paragraph-sl">
              Please note that the timeline and cost may differ based on the
              project&apos;s complexity.
            </p>
          </header>
          <div className={styles['what-you-get-container']}>
            <span className={styles['what-you-get-item']}>
              <FiCheckCircle size={32} />
              <h4>
                HTML file
                <br />
                Figma file
                <br />
                EOA previews
              </h4>
            </span>
            <span className={styles['what-you-get-item']}>
              <FiCheckCircle size={32} />
              <h4>Responsive email layout</h4>
            </span>
            <span className={styles['what-you-get-item']}>
              <FiCheckCircle size={32} />
              <h4>
                Tested on 80+ email clients and devices (mobile and desktop)
              </h4>
            </span>
            <span className={styles['what-you-get-item']}>
              <FiCheckCircle size={32} />
              <h4>Cannoli link to see your HTML and MJML code live</h4>
            </span>
          </div>
        </section>
        <img src={divider} alt="Divider" className={styles.divider} />
      </article>
      <PreFooterCta />
    </PublicPage>
  );
}

export default Checkout;
