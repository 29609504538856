import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FiLink2, FiRepeat } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';

import { ReactComponent as CannoliModalLogo } from 'svg/integrations/modal-cannoli-logo.svg';

import { useEspCreateMutation, useEspsInvalidateQuery } from 'hooks/api/esps';

import styles from './style.module.css';

function IntegrationAddModal({
  isOpen,
  close,
  name,
  esp,
  docsLink,
  img: Image,
}) {
  const customClassname = classNames(styles['integration-add-modal']);
  const [key, setKey] = useState('');
  const invalidate = useEspsInvalidateQuery();
  const { mutate, isLoading } = useEspCreateMutation({
    esp,
    config: {
      onSuccess: () => {
        toast.success('ESP added successfully');
        close();
        invalidate();
      },
      onError: () => {
        toast.error('Error adding ESP');
      },
    },
  });

  const onSubmit = () => {
    mutate({ key });
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="add integration modal"
      theme="light"
      dangerouslyBypassFocusLock
      className={customClassname}
    >
      <Modal.Header title="New integration" onDismiss={close} />
      <div className={styles['integration-add-modal-content']}>
        <div className={styles['integration-image-container']}>
          <CannoliModalLogo className={styles['logo-image']} />
          <FiRepeat className={styles['modal-icon']} />
          <Image className={styles['logo-image']} />
        </div>
        <p>
          Cannoli will be able to create email templates in your {name} account.
        </p>
        <div className={styles['integration-add-modal-form']}>
          <Input
            id="key"
            label="Key:"
            name="key"
            placeholder="API key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
      </div>
      <Modal.Footer>
        {docsLink ? (
          <Button
            hierarchy="tertiary"
            rel="noreferrer noopener"
            target="_blank"
            href={docsLink}
            icon={FiLink2}
          >
            Obtain API key
          </Button>
        ) : null}
        <Button
          loading={isLoading}
          disabled={isLoading || key.trim() === ''}
          onClick={onSubmit}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

IntegrationAddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  name: PropTypes.string,
  esp: PropTypes.string.isRequired,
  docsLink: PropTypes.string,
  img: PropTypes.shape({}),
};

IntegrationAddModal.defaultProps = {
  name: '',
  docsLink: null,
  img: null,
};

export default IntegrationAddModal;
