import PolicyContainer from 'containers/policy-container';

function ServicePolicyText() {
  return (
    <PolicyContainer>
      <section id="services-policies">
        <h3>Services Policies</h3>
        <p>
          These policies apply to all orders made through our Services page and
          do not pertain to the use of the Cannoli App.
        </p>
      </section>

      <section id="services-overview">
        <h3>Services Overview</h3>
        <h4>
          <em>Coding Services</em>
        </h4>
        <p>
          This service involves converting designs into email-ready code.
          Customers must provide their designs in the form of a Figma URL. The
          coding is done in MJML, which is then converted to HTML using the{' '}
          <a target="_blank" rel="noreferrer" href="https://mjml.io/">
            MJML
          </a>{' '}
          official library. This service provides customers with high-quality,
          email-ready code without the need to learn the skill or hire someone
          for regular coding.
        </p>
        <h4>
          <em>Design and coding Services</em>
        </h4>
        <p>
          This service involves creating an email design and later converting it
          into email-ready code. The coding is done in MJML, which is then
          converted to HTML using the{' '}
          <a target="_blank" rel="noreferrer" href="https://mjml.io/">
            MJML
          </a>{' '}
          official library. This service provides customers with high-quality,
          email-ready Figma designs and code without the need to learn the skill
          or hire someone for regular coding.
        </p>
      </section>

      <section id="placing-orders">
        <h3>Placing Orders</h3>
        <p>
          To place an order, customers need to provide the following
          information:
        </p>
        <ul>
          <li>First and last name</li>
          <li>Email address</li>
          <li>
            Project description, including Figma files with email designs for
            coding (with view access) and specifications for frames to be coded
            (if applicable). This description should also cover details like
            expected code variations, included URLs, GIFs, countdown timers,
            etc. All final styles, such as fonts, colors, and images, should be
            in the Figma files. In case the customer is ordering a design as
            well, all brand assets should be provided in addition to the
            information specified above, as well as a description of what the
            email should contain, and the email’s copy. If copy isn’t provided
            it will be generated through an AI, using any information provided,
            if available. If there is no enough information to complete the copy
            and design, generic placeholder text and design blocks will be used.
          </li>
          <li>Number of emails (HTML files) required for delivery.</li>
        </ul>
        <p>
          This information will only be used to contact the customer and provide
          the email-coding or email design and coding service. Orders lacking
          any of the above information will not be fulfilled and no charge will
          be done the the customer’s card.
          <br />
          Upon completing the order form, customers need to make payment through
          our payment provider, Stripe. Stripe will handle payment information,
          and Cannoli will not have access to it.
          <br />
          After successful payment, Cannoli will review the provided information
          and commence work on the project. If any information is missing, the
          customer will be notified. Once the necessary information is provided,
          the Cannoli team will begin work, and the delivery date will start
          from the completion of all information.
          <br />
          <br />
          <em>For email code orders:</em>
          <br />
          Your order will be delivered via email within two (2) business days
          after receiving all required project information.
          <br />
          <br />
          <em>For email design and code orders:</em>
          <br />
          Your order will be delivered via email within three (3) business days
          after receiving all required project information. Take into
          consideration that there is also an extra business day needed in order
          to validate the email’s design, making it possible that the order
          could take up to four (4) business days to be completed, depending on
          the time the customer takes to approve the design. If no approval is
          received within one business day, the team will proceed with the
          coding phase.
        </p>
      </section>

      <section id="scope-of-service">
        <h3>Scope of Service</h3>
        <p>
          All orders and the requested emails will be completed with the
          following specifications:
        </p>
        <ul>
          <li>Responsive email code based on MJML library and standards</li>
          <li>HTML file</li>
          <li>Email on Acid email previews</li>
          <li>Optimized Figma file(s) for further Cannoli usage</li>
          <li>
            Validation for customer-provided links and images (alt text and
            valid URLs)
          </li>
          <li>Built-in AI-generated font stacks</li>
          <li>Dark mode asset optimization</li>
          <li>Outlook 120 DPI optimization</li>
          <li>
            Link to the email(s) within Cannoli, allowing access to both MJML
            and HTML code for further testing or changes.
          </li>
        </ul>
        <br />
        <br />
        If the order includes a design, the customer will also get:
        <ul>
          <li>1 design concept</li>
          <li>Unlimited revisions within 1 business day</li>
        </ul>
        <br />
        <br />
        <p>
          Please note that the Cannoli team is not responsible for any changes
          made to delivered emails after they are received.
        </p>
      </section>

      <section id="limitations">
        <h3>Limitations</h3>
        <p>
          Ensure you provide the final design for your coding project, as
          modifications or revisions to styles and content cannot be
          accommodated once code delivery has occurred. Currently, the Cannoli
          Services team does not offer ESP-specific optimization. For such
          requirements, contact us at{' '}
          <a href="mailto:hello@cannoli.app">hello@cannoli.app</a>.
        </p>
      </section>

      <section id="pricing">
        <h3>Pricing</h3>
        <p>
          Coding service: Each email, delivered with the same specifications, is
          priced at $75 USD. Design and coding service: Each email, delivered
          with the same specifications, is priced at $150 USD.
        </p>
      </section>

      <section id="payments">
        <h3>Payments</h3>
        <p>
          Payments are processed via Stripe and can only be made using credit or
          debit cards. Payment completion is necessary to place an order.
        </p>
      </section>

      <section id="turnaround-times">
        <h3>Turnaround Times</h3>
        <p>
          Turnaround time is two (2) business days per order or less for our
          coding service. Turnaround time is three (3) business days per order
          or less for our design and coding service.Take into account that after
          design is completed we will request for your approval. This could
          potentially increase the turnaround time by one (1) business day if
          approval isn’t provided. In this case, we will begin working on the
          code portion of the order. Delays may occur if crucial information is
          missing, such as a valid Figma file with view access or specifications
          for emails within a file with multiple emails.
        </p>
      </section>

      <section id="cancellation-and-refunds">
        <h3>Cancellation and Refunds</h3>
        <p>
          Orders can be canceled at any time before delivery, with a full
          refund. Refunds can be requested within the first 14 days after
          project delivery, no questions asked. Refunds will not be provided
          after this period.
        </p>
      </section>

      <section id="confidentiality-and-security">
        <h3>Confidentiality and Security</h3>
        <p>
          Information provided through our website or shared files will be kept
          confidential. For further information, review our{' '}
          <a target="_blank" rel="noreferrer" href="./privacy">
            privacy policy
          </a>
          .
        </p>
      </section>

      <section id="termination-of-service">
        <h3>Termination of Service</h3>
        <p>
          If project specifications are insufficient for completion, we will
          contact you. If no information is provided within 5 days of placing an
          order, it will be terminated with a full refund.
        </p>
      </section>

      <section id="policy-changes">
        <h3>Policy Changes</h3>
        <p>
          We reserve the right to modify our policies at any time. In such
          cases, you will be notified via email.
        </p>
      </section>

      <section id="contact-information">
        <h3>Contact Information</h3>
        <p>You can reach us through:</p>
        <ul>
          <li>
            Email: <a href="mailto:hello@cannoli.app">hello@cannoli.app</a>
          </li>
          <li>Chatbot on cannoli.app</li>
        </ul>
        <p>
          Business hours: Monday to Friday, 8:00 am to 5:00 pm PST (Pacific
          Standard Time). We typically respond within 1 business day.
        </p>
      </section>
    </PolicyContainer>
  );
}

export default ServicePolicyText;
