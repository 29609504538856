import json2mjml from 'json2mjml';
import mjml2html from 'mjml-browser';
import { GENERIC_JSON } from './constants';

/**
 * Converts a snake_case string to titleCase.
 *
 * @param  {string} str the string to convert
 * @return {string}
 */
export function SnakeCaseToTitleCase(str) {
  return str
    .toLowerCase()
    .split('_')
    .map((item) => {
      return item.charAt(0).toUpperCase() + item.substring(1);
    })
    .join(' ');
}

/**
 * async function to wait for seconds
 *
 * @param  {string} seconds the amount of seconds to wait
 * @return {Promise}
 */
export function waitForSeconds(seconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

/**
 * A string hashing function based on Daniel J. Bernstein's popular 'times 33' hash algorithm.
 * https://github.com/darkskyapp/string-hash/blob/master/index.js
 *
 * @param {string} text - String to hash
 * @returns {number} Resulting number.
 */
export function stringToHash(text) {
  let hash = 5381;
  let index = text.length;

  while (index) {
    // eslint-disable-next-line no-bitwise, no-plusplus
    hash = (hash * 33) ^ text.charCodeAt(--index);
  }

  // eslint-disable-next-line no-bitwise
  return hash >>> 0;
}

/**
 * Replacing css class names with those generated by style modules
 *
 * @param {JSON} styleModule - JSON object with css classes ({className: className + hash})
 * @param {string} rawCss - CSS as String
 * @returns {string} - stylesheet with proper css class names
 */
export function getStylesheetFromRawCss(styleModule = {}, rawCss = '') {
  let stylesheet = rawCss;

  Object.entries(styleModule).forEach(([key, value]) => {
    stylesheet = stylesheet.replaceAll(`.${key}`, `.${value}`);
  });

  return stylesheet;
}

/**
 *
 * @param {string} numBytes - Number of bytes
 * @param {*} decimals - The number of digits to appear after the decimal point
 * @returns {string} - Bytes converted to the proper unit (30 bytes = 30 bytes, 1024 bytes = 1 KB, etc.)
 */
export function formatBytes(numBytes, decimals = 1) {
  let bytes = Number(numBytes);
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  let counter = 0;
  for (counter; bytes > 1024; counter += 1) {
    bytes /= 1024;
  }

  const formattedBytes = `${parseFloat(bytes.toFixed(decimals))} ${
    units[counter]
  }`;

  return formattedBytes;
}

export const generateHtml = (content, head = []) => {
  const newJson = JSON.parse(JSON.stringify(GENERIC_JSON));
  if (head && head.length > 0) newJson.children[0].children = head;
  newJson.children[1].children = content;

  const mjml = json2mjml(newJson);
  const { html } = mjml2html(mjml);
  return html;
};

export const getHtmlTemplate = (templateComponents) => {
  const head = templateComponents.reduce((accumulator, currentValue) => {
    if (currentValue?.component?.virtual_mj_head)
      accumulator.push(...currentValue.component.virtual_mj_head);
    return accumulator;
  }, []);

  const content = templateComponents.reduce((accumulator, currentValue) => {
    if (currentValue?.component?.content?.blockArray)
      accumulator.push(...currentValue.component.content.blockArray);
    return accumulator;
  }, []);

  return generateHtml(content, head);
};
