import { useSearchParams, useNavigate, Link, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import {
  USER_SUBSCRIPTION_STATUS,
  USER_SUBSCRIPTION_TIER,
} from 'hooks/api/users/constants';

import { plans } from 'containers/dashboard-billing/constants';
import { PageOnboarding } from 'components/layout';
import LoadingFullPage from 'components/loading-full-page';

import { useAuth } from 'context/auth';

import styles from './style.module.css';

function Welcome() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const subscriptionType = searchParams.get('type');
  const to = searchParams.get('to');
  const { user: userData } = useAuth();
  const {
    is_elegible_for_trial: isElegibleForTrial,
    has_valid_trial: hasValidTrial,
  } = userData.user;
  const subscription = userData.user.subscriptions || null;
  const subscriptionTier = subscription?.tier;

  // This page redirects the user where they need to be,
  // or shows them a series of actions to take
  // depending on their subscription status.
  // They get here after clicking "create account" or "log in" on the home page
  useEffect(() => {
    if (subscription && !subscriptionType && to) {
      navigate(to);
    } else if (
      // NOTE: The starter subscription is created on the backend by default
      subscriptionType === plans.starter.slug
    ) {
      navigate('/dashboard/settings/billing');
    } else if (
      subscriptionTier === USER_SUBSCRIPTION_TIER.FREE &&
      subscriptionType === plans.proTrial.slug &&
      isElegibleForTrial
    ) {
      navigate(`/create-account?type=${subscriptionType}`);
    }
  }, [
    subscription,
    subscriptionTier,
    subscriptionType,
    navigate,
    to,
    isElegibleForTrial,
  ]);

  if (
    subscriptionType === plans.proTrial.slug &&
    !isElegibleForTrial &&
    !hasValidTrial
  ) {
    return (
      <PageOnboarding className={styles['create-account-page']}>
        <h1>You have already used your Free Pro Trial period</h1>
        <div className={styles['create-account-page-cta']}>
          <Link
            to="/dashboard/settings/billing"
            className="forno-button font-block-l-sb"
            data-size="l"
            data-hierarchy="primary"
            data-theme="light"
          >
            See account details
          </Link>
          <Link
            to="/"
            className="forno-button font-block-l-sb"
            data-size="l"
            data-hierarchy="primary"
            data-theme="light"
          >
            Go to dashboard
          </Link>
        </div>
      </PageOnboarding>
    );
  }

  if (
    subscription?.tier === USER_SUBSCRIPTION_TIER.PRO &&
    subscription?.status === USER_SUBSCRIPTION_STATUS.PENDING
  )
    return (
      <PageOnboarding className={styles['create-account-page']}>
        <h1>You have a pending account</h1>
        <div className={styles['create-account-page-cta']}>
          <Link
            to="/dashboard/settings/billing"
            className="forno-button font-block-l-sb"
            data-size="l"
            data-hierarchy="primary"
            data-theme="light"
          >
            See account details
          </Link>
        </div>
      </PageOnboarding>
    );

  if (
    subscription?.status === USER_SUBSCRIPTION_STATUS.ACTIVE ||
    subscription?.status === USER_SUBSCRIPTION_STATUS.OVERDUE
  )
    return (
      <PageOnboarding className={styles['create-account-page']}>
        <h1>You already have an account</h1>
        <div className={styles['create-account-page-cta']}>
          <Link
            to="/dashboard/settings/billing"
            className="forno-button font-block-l-sb"
            data-size="l"
            data-hierarchy="primary"
            data-theme="light"
          >
            See account details
          </Link>
          <Link
            to="/"
            className="forno-button font-block-l-sb"
            data-size="l"
            data-hierarchy="primary"
            data-theme="light"
          >
            Go to dashboard
          </Link>
        </div>
      </PageOnboarding>
    );

  if (!userData) {
    return (
      <PageOnboarding className={styles['create-account-page']}>
        <LoadingFullPage>
          <h1>Welcome</h1>
        </LoadingFullPage>
      </PageOnboarding>
    );
  }

  return <Navigate to="/dashboard" />;
}

export default Welcome;
