import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PublicRedirect from 'components/public-redirect';

import FigmaCallback from 'pages/figma-callback';
import Design from 'pages/design';
import Login from 'pages/login';
import LoginWithPassword from 'pages/login-with-password';
import Home from 'pages/home';
import PrivacyPolicy from 'pages/privacy-policy';
import { ConnectAccount } from 'pages/plugin';
import CannoliServices from 'pages/cannoli-services';
import Checkout from 'pages/cannoli-services/checkout';
import CheckoutSuccess from 'pages/cannoli-services/checkout/checkout-success';
import CheckoutCancel from 'pages/cannoli-services/checkout/checkout-cancel';
import CannoliApp from 'pages/cannoli-app';
import ReadOnlyView from 'pages/cannoli-services/read-only-view';

function Public() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/oauth/callback" element={<FigmaCallback />} />
      <Route path="/design" element={<Design />} />
      <Route path="/platform" element={<CannoliApp />} />
      <Route path="/services" element={<CannoliServices />} />
      <Route path="/services/files/:fileId" element={<ReadOnlyView />} />
      <Route path="/services/checkout" element={<Checkout />} />
      <Route path="/services/checkout/:orderType" element={<Checkout />} />
      <Route path="/services/checkout/success" element={<CheckoutSuccess />} />
      <Route path="/services/checkout/cancel" element={<CheckoutCancel />} />
      <Route path="/policies" element={<PrivacyPolicy />} />
      <Route path="/policies/:policy" element={<PrivacyPolicy />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/_" element={<LoginWithPassword />} />
      <Route path="/connect-account" element={<ConnectAccount />} />
      <Route path="*" element={<PublicRedirect />} />
    </Routes>
  );
}

export default Public;
