import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import classnames from 'classnames';
import Beautify from 'ace-builds/src-noconflict/ext-beautify';
import ace from 'ace-builds/src-min-noconflict/ace';
import { buildTagId, getTagInfoFromId } from 'utils/mjml/util';
import { MJML_CONTENT_TAGS } from 'utils/mjml';
import styles from './style.module.css';
import './setup';
import './themes/monokai-cannoli.css';

const { TokenIterator } = ace.require('ace/token_iterator');

const CodeEditor = React.forwardRef(
  (
    {
      className,
      value,
      onChange,
      name,
      mode,
      setTagSelected,
      tagSelected,
      ...rest
    },
    ref
  ) => {
    const customClassName = classnames(
      'code-editor-wrapper',
      styles['code-editor-wrapper'],
      className
    );

    /**
     * It is possible to have different mj-body tags in the same document (as css selector, mj-attribute or container, etc.),
     * Find all positions of mj-body tags
     *
     * @returns {Array} - Array with positions (start, end) of each mj-body tag
     */
    const findBodyTagPositions = useCallback(() => {
      const bodyTagPositions = [];
      let allBodyTagsFound = false;

      while (!allBodyTagsFound) {
        const bodyTagFound = ref.current.editor.find('<mj-body');
        const bodyTagDuplicate = bodyTagFound
          ? bodyTagPositions.find((tag) => {
              return (
                tag.start.row === bodyTagFound.start.row &&
                tag.start.column === bodyTagFound.start.column
              );
            })
          : null;

        if (!bodyTagDuplicate) {
          bodyTagPositions.push(bodyTagFound);
        }

        if (bodyTagDuplicate || !bodyTagFound) {
          allBodyTagsFound = true;
        }
      }

      return bodyTagPositions;
    }, [ref]);

    /**
     * Based on all mj-body tags found, determinate which tag has content
     *
     * @param {Array} bodyTagPositions - Array with positions (start, end) of each mj-body tag
     * @returns {JSON} - JSON object with 'start' and 'end' attributes
     */
    const findBodyTagWithContent = useCallback(
      (bodyTagPositions = []) => {
        for (
          let tagIndex = 0;
          tagIndex < bodyTagPositions.length;
          tagIndex += 1
        ) {
          let isBodyTag = true;
          let isBodyTagClosed = false;
          const bodyTagPosition = bodyTagPositions[tagIndex];
          const { row, column } = bodyTagPosition.end;

          const iterator = new TokenIterator(
            ref.current.editor.selection.session,
            row,
            column
          );
          let token = iterator.getCurrentToken();
          let nextToken = iterator.stepForward();

          while (token && nextToken && !isBodyTagClosed) {
            // Determinate if mj-body tag is closed
            if (
              nextToken.value === 'mj-body' || // Closed as: </mj-body>
              (isBodyTag && nextToken.value === '/>') // Closed as: <mj-body />
            ) {
              isBodyTagClosed = true;
            }

            // New tag found, since a new tag was found the current tag is not mj-body
            if (nextToken.type === 'meta.tag.tag-name.xml') {
              isBodyTag = false;
            }

            // The current mj-body has content (tags like mj-image, mj-text, etc.)
            if (MJML_CONTENT_TAGS[token.value]) {
              return bodyTagPosition;
            }

            token = nextToken;
            nextToken = iterator.stepForward();
          }
        }

        // No valid mj-body tag was found, return a default position
        return {
          start: {
            row: 1,
            column: 1,
          },
        };
      },
      [ref]
    );

    const scrollToTag = useCallback(
      (tagId) => {
        const { tagName, tagIndentifier } = getTagInfoFromId(tagId);
        let counter = 0;

        const bodyTagPositions = findBodyTagPositions();
        const bodyTag = findBodyTagWithContent(bodyTagPositions);
        const { row, column } = bodyTag.start;

        const iterator = new TokenIterator(
          ref.current.editor.selection.session,
          row,
          column
        );
        let token = iterator.getCurrentToken();
        let nextToken = iterator.stepForward();

        // Try to find the tag name with id
        while (counter !== tagIndentifier) {
          token = nextToken;
          nextToken = iterator.stepForward();
          if (
            token.type === 'meta.tag.punctuation.tag-open.xml' &&
            nextToken.value === tagName
          ) {
            counter += 1;
          }
        }

        // Move the cursor to the specified position
        ref.current.editor.selection.moveTo(
          iterator.getCurrentTokenRow(),
          iterator.getCurrentTokenColumn()
        );
        ref.current.editor.renderer.showCursor();
        ref.current.editor.scrollToLine(
          iterator.getCurrentTokenRow(),
          true, // Centers the editor the to indicated line
          true // Animates scrolling
        );
      },
      [findBodyTagPositions, findBodyTagWithContent, ref]
    );

    React.useLayoutEffect(() => {
      if (
        !ref ||
        !tagSelected ||
        !tagSelected.from ||
        tagSelected.from === 'code' ||
        tagSelected.from === 'panel'
      ) {
        return;
      }

      // In case of unexpected error
      try {
        scrollToTag(tagSelected.id);
      } catch (error) {
        console.error('Code editor: ', error);
      }
    }, [ref, scrollToTag, tagSelected]);

    const handleCursorChange = useCallback(() => {
      let tagName = '';
      let tagOccurrences = 0;
      let isBodyOpened = false;
      let isBodyClosed = false;

      const cursor = ref.current.editor.selection.getCursor();
      const iterator = new TokenIterator(
        ref.current.editor.selection.session,
        cursor.row,
        cursor.column
      );
      let token = iterator.getCurrentToken();
      let prevToken = token || iterator.stepBackward();

      // Handle the cursor when is between '<' and tag name (<(cursor)mj-image>)
      if (
        prevToken &&
        token &&
        prevToken.type === 'meta.tag.punctuation.tag-open.xml'
      ) {
        token = iterator.stepForward();
        prevToken = iterator.stepBackward();
      }

      // Iterating from cursor (click by the user), until an open body tag is found (<mj-body>)
      // In other words reading from bottom (cursor) to top (body)
      while (prevToken && token && !isBodyOpened) {
        // mj-body tag opened (<mj-body)
        if (
          prevToken.type === 'meta.tag.punctuation.tag-open.xml' &&
          token.value === 'mj-body'
        ) {
          isBodyOpened = true;
        }

        // mj-body tag closed (</mj-body)
        if (
          prevToken.type === 'meta.tag.punctuation.end-tag-open.xml' &&
          token.value === 'mj-body'
        ) {
          isBodyClosed = true;
        }

        // Find content (tags like mj-text, mj-image, etc.)
        if (
          tagName === '' &&
          token.type === 'meta.tag.tag-name.xml' &&
          MJML_CONTENT_TAGS[token.value]
        ) {
          tagName = token.value;
          if (prevToken.type === 'meta.tag.punctuation.tag-open.xml') {
            tagOccurrences = 1;
          }
        } else if (
          prevToken.type === 'meta.tag.punctuation.tag-open.xml' &&
          token.value === tagName
        ) {
          tagOccurrences += 1;
        }

        token = prevToken;
        prevToken = iterator.stepBackward();
      }

      // The tag is between <mj-body> and </mj-body>
      if (isBodyOpened && !isBodyClosed) {
        const id = tagName ? buildTagId(tagName, tagOccurrences) : null;
        if (id !== tagSelected.id) {
          setTagSelected({ id, from: 'code' });
        }
      }
    }, [ref, setTagSelected, tagSelected.id]);

    const onCursorChange = () => {
      const selectedtText = ref?.current?.editor?.getSelectedText();
      // ToDO: Need a better solution for the unexpected events that are fired
      if (selectedtText !== '' || tagSelected.from === 'panel') {
        return;
      }

      try {
        handleCursorChange();
      } catch (error) {
        console.error('Code editor: ', error);
      }
    };

    // TODO: FIX ISSUE (Maximum update depth exceeded)
    // const [syntaxErrors, setSyntaxErrors] = useState([]);

    // const { setCodeIsValid } = useEditorDispatch();

    // const validateCode = useCallback(() => {
    //   if (ref?.current?.editor) {
    //     ref.current.editor.getSession().on('changeAnnotation', () => {
    //       const annotations = ref?.current?.editor
    //         ?.getSession()
    //         .getAnnotations();
    //       const filteredAnnotations = annotations?.filter(
    //         (annotation) =>
    //           !annotation.text.includes('Attribute name is illegal') &&
    //           !annotation.text.includes('entity not found:')
    //       );
    //       setSyntaxErrors(filteredAnnotations || []);
    //     });
    //   }
    // }, [ref]);

    // useEffect(() => {
    //   validateCode();
    //   setCodeIsValid(syntaxErrors.length === 0);
    // }, [syntaxErrors, ref, validateCode, setCodeIsValid]);

    return (
      <div className={customClassName}>
        <AceEditor
          ref={ref}
          width="100%"
          height="100%"
          style={{ fontFamily: 'Fira Code' }}
          editorProps={{ $blockScrolling: true }}
          className="ace-monokai-cannoli"
          fontSize={12}
          keyboardHandler="vscode"
          mode={mode}
          name={name}
          value={value}
          onChange={onChange}
          showPrintMargin={false}
          enableSnippets
          enableBasicAutocompletion
          enableLiveAutocompletion
          onCursorChange={() => onCursorChange()}
          commands={Beautify.commands}
          setOptions={{
            wrap: true,
            autoScrollEditorIntoView: true,
          }}
          {...rest}
        />
      </div>
    );
  }
);

CodeEditor.displayName = 'CodeEditor';
CodeEditor.propTypes = {
  tagSelected: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.string,
  }),
  setTagSelected: PropTypes.func,
  mode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

CodeEditor.defaultProps = {
  tagSelected: {},
  setTagSelected: () => {},
  className: null,
  value: null,
  onChange: () => {},
};

export default CodeEditor;
