import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  FiSend,
  FiDownload,
  FiCopy,
  FiUpload,
  FiShare,
  FiMail,
  FiHelpCircle,
  FiEdit,
  FiCode,
} from 'react-icons/fi';
import { useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';
import Tooltip from '@scalero-inc/forno-tooltip';

import { INTEGRATIONS } from 'pages/settings/integrations/data';
import FileSendTestModal from 'containers/file-send-test-modal';
import FileShareModal from 'containers/file-share-modal';

import { useFileExportToEspMutation } from 'hooks/api/files';
import { useEspsQuery } from 'hooks/api/esps';

import { useUserQuery } from 'hooks/api/users';
import {
  hasActiveProAccount,
  hasActiveTeamAccount,
  isAllAccess,
} from 'utils/permissions';
import ButtonGroup from '@scalero-inc/forno-button-group';
import usePanel from 'hooks/utils/use-panel';
import { editorOptions } from 'utils/editor';
import { useEditor } from '../editor-context';

import styles from './style.module.css';
import EditorSaveIndicator from '../editor-save-indicator';

function EditorHeaderContents({ editorView, setEditorView }) {
  const panel = usePanel();
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const [sendTestIsOpen, setSendTestIsOpen] = useState(false);
  const openSendTest = useCallback(
    () => setSendTestIsOpen(true),
    [setSendTestIsOpen]
  );
  const closeSendTest = useCallback(
    () => setSendTestIsOpen(false),
    [setSendTestIsOpen]
  );
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const openShare = useCallback(() => setShareIsOpen(true), [setShareIsOpen]);
  const closeShare = useCallback(() => setShareIsOpen(false), [setShareIsOpen]);

  const { name, html, mjml, teamId, fileId, componentId } = useEditor();
  const isEditingFile = !!fileId;
  const { data: esps, isLoading: espsLoading } = useEspsQuery();
  const { mutate, isLoading } = useFileExportToEspMutation({
    fileId,
    name,
    config: {
      onSuccess: () => {
        toast.success('Exported file successfully');
        close();
      },
      onError: () => {
        toast.error('There was an error exporting your file');
      },
    },
  });

  const { data: user } = useUserQuery();

  const permission = user
    ? isAllAccess({ user }) ||
      hasActiveTeamAccount({ user }) ||
      hasActiveProAccount({ user })
    : false;

  const copyHtmlToClipboard = () => {
    copy(html, {
      format: 'text/plain',
      onCopy: () => toast.success('HTML copied to clipboard'),
    });
  };

  const copyMjmlToClipboard = () => {
    copy(mjml, {
      format: 'text/plain',
      onCopy: () => toast.success('MJML copied to clipboard'),
    });
  };

  const downloadMjml = () => {
    const blob = new Blob([mjml], { type: 'text/mjml;charset=utf-8' });
    saveAs(blob, `${name}.mjml`);
  };

  const downloadHtml = () => {
    const blob = new Blob([html], { type: 'text/html;charset=utf-8' });
    saveAs(blob, `${name}.html`);
  };

  const exportToESP = (espId) => {
    mutate(espId);
  };

  const tooltipClassName = 'font-paragraph-s-sb';

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={close}
        theme="dark"
        aria-label="export code modal"
      >
        <Modal.Header
          onDismiss={close}
          title="Export code"
          icon={FiSend}
          theme="dark"
        />
        <div className={styles['export-option-title']}>
          <p className="font-block-m-sb">Download Code</p>
          <Tooltip
            theme="dark"
            content={
              <p className={tooltipClassName}>Download your email’s code</p>
            }
          >
            <FiHelpCircle />
          </Tooltip>
        </div>
        <div className={styles['export-button-section']}>
          <Button
            icon={FiDownload}
            onClick={downloadHtml}
            theme="dark"
            hierarchy="secondary"
          >
            HTML
          </Button>
          <Button
            icon={FiDownload}
            onClick={downloadMjml}
            theme="dark"
            hierarchy="secondary"
          >
            MJML
          </Button>
        </div>
        <div className={styles['export-option-title']}>
          <p className="font-block-m-sb">Copy to clipboard</p>
          <Tooltip
            theme="dark"
            content={
              <p className={tooltipClassName}>
                Copy your choice of code directly to your clipboard for quick
                pasting to other services.
              </p>
            }
          >
            <FiHelpCircle />
          </Tooltip>
        </div>
        <div className={styles['export-button-section']}>
          <Button
            icon={FiCopy}
            onClick={copyHtmlToClipboard}
            theme="dark"
            hierarchy="secondary"
          >
            HTML
          </Button>
          <Button
            icon={FiCopy}
            onClick={copyMjmlToClipboard}
            theme="dark"
            hierarchy="secondary"
          >
            MJML
          </Button>
        </div>
        <div className={styles['export-option-title']}>
          <p className="font-block-m-sb">Export to ESP</p>
          <Tooltip
            theme="dark"
            content={
              <p className={tooltipClassName}>
                {permission
                  ? 'Export to your ESP service especific features'
                  : 'This feature is blocked for Starter Accounts. Upgrade to access it'}
              </p>
            }
          >
            <FiHelpCircle />
          </Tooltip>
        </div>
        {esps?.length > 0 && !espsLoading ? (
          <div className={styles['export-button-section']}>
            {esps?.map((esp) => {
              const espName =
                INTEGRATIONS.find(
                  (integration) =>
                    integration.esp === esp.email_service_provider
                )?.name || 'Other';

              return (
                <Button
                  key={esp.id}
                  icon={FiUpload}
                  onClick={() => exportToESP(esp.id)}
                  disabled={isLoading || !permission}
                  loading={isLoading}
                  theme="dark"
                  hierarchy="secondary"
                >
                  {espName}
                </Button>
              );
            })}
          </div>
        ) : (
          <p className={styles['esp-error-message']}>
            You don&apos;t have any ESP configured yet. <br />
            <NavLink to="/dashboard/settings/integrations">
              Go to settings
            </NavLink>{' '}
            to add an ESP.
          </p>
        )}
        <Modal.Footer>
          <Button hierarchy="tertiary" onClick={close} theme="dark">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles['editor-header-contents']}>
        <div className={styles['editor-header-title-wrapper']}>
          <Tooltip
            theme="dark"
            content={
              <p className={tooltipClassName}>
                Switch between visual and code editor
              </p>
            }
          >
            <ButtonGroup
              size="s"
              type="radio"
              theme="dark"
              onChange={(e) => setEditorView(e[0])}
              initialValue={[editorView]}
            >
              <ButtonGroup.Button
                value="visual"
                key="visual"
                onClick={() =>
                  panel.open(
                    editorOptions.visualEditor.name,
                    editorOptions.visualEditor.title
                  )
                }
              >
                <FiEdit /> Visual
              </ButtonGroup.Button>
              <ButtonGroup.Button
                value="code"
                key="code"
                onClick={() => panel.close()}
              >
                <FiCode />
                Code
              </ButtonGroup.Button>
            </ButtonGroup>
          </Tooltip>
          <div className={styles['editor-header-name-wrapper']}>
            <Tooltip
              theme="dark"
              content={<p className={tooltipClassName}>Your file’s name</p>}
            >
              <h2 className="font-paragraph-m-sb">{name}</h2>
            </Tooltip>
            <EditorSaveIndicator
              teamId={teamId}
              fileId={fileId}
              componentId={componentId}
            />
          </div>
        </div>
        <div className={styles['editor-header-actions']}>
          {sendTestIsOpen && (
            <FileSendTestModal
              close={closeSendTest}
              isOpen={sendTestIsOpen}
              fileId={fileId}
            />
          )}
          {shareIsOpen && (
            <FileShareModal
              isOpen={shareIsOpen}
              close={closeShare}
              fileId={+fileId}
            />
          )}

          {isEditingFile && (
            <>
              <Tooltip
                theme="dark"
                content={
                  <p className={tooltipClassName}>
                    {permission
                      ? 'Send a test email with your current code'
                      : 'This feature is blocked for Starter Accounts. Upgrade to access it'}
                  </p>
                }
              >
                <Button
                  icon={FiMail}
                  size="xs"
                  hierarchy="tertiary"
                  theme="dark"
                  onClick={openSendTest}
                  disabled={!permission}
                >
                  Send test email
                </Button>
              </Tooltip>
              <Tooltip
                theme="dark"
                content={
                  <p className={tooltipClassName}>
                    Share your email to anyone by creating a public link
                  </p>
                }
              >
                <Button
                  icon={FiShare}
                  size="xs"
                  hierarchy="tertiary"
                  theme="dark"
                  onClick={openShare}
                >
                  Share
                </Button>
              </Tooltip>
              <Tooltip
                theme="dark"
                placement="bottom-end"
                content={
                  <p className={tooltipClassName}>
                    Copy, download or export your email code to your favorite
                    ESP
                  </p>
                }
              >
                <Button icon={FiSend} size="xs" onClick={open} theme="dark">
                  Export
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </>
  );
}

EditorHeaderContents.propTypes = {
  editorView: PropTypes.string.isRequired,
  setEditorView: PropTypes.func.isRequired,
};

export default EditorHeaderContents;
