import { getFillColor } from '../../color';
import { getBorderRadiusAbsolute, getBorder } from '../../border';
import { getWidth, getPadding, getHeight } from '../../bounding-box';
import { getAlignment } from '../../utils';
import { breakPointWidth } from '../dictionaries';
import { getErrors, getInnerPadding } from './utils';

function MjButton({
  parent,
  attributes,
  children,
  rectangleCornerRadii,
  fills,
  tagName,
  ...rest
}) {
  // If there is no background color, MJML will add a default
  const background = getFillColor({ fills });
  const borderRadius = getBorderRadiusAbsolute(rest);
  const border = getBorder({ ...rest });
  const mjText = children[0];
  const buttonText = mjText ? mjText.content : '';
  const buttonTextStyles = mjText ? mjText.attributes : {};
  const { align, 'css-class': css, ...textStyles } = buttonTextStyles;
  const width = getWidth(rest);
  const height = getHeight(rest);
  const alignment = getAlignment(parent);
  const padding = getPadding(parent, rest, tagName);
  const innerPadding = getInnerPadding(height, textStyles);
  const cssClass = width >= breakPointWidth ? { cssClass: 'button' } : null;
  const errorList = getErrors(background, mjText, rest);

  return [
    {
      tagName: 'mj-button',
      errors: errorList,
      children: [],
      content: buttonText,
      attributes: {
        ...attributes,
        align: alignment,
        href: '#',
        width: `${width}px`,
        ...(textStyles && textStyles),
        ...(cssClass && cssClass),
        ...(padding || { padding: '0px' }),
        ...(background && background),
        ...borderRadius,
        ...innerPadding,
        ...border,
      },
    },
  ];
}

export default MjButton;
