import React from 'react';
import propTypes from 'prop-types';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'react-headless-accordion';
import {
  FiChevronDown,
  FiChevronUp,
  FiFigma,
  FiMenu,
  FiX,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Button from '@scalero-inc/forno-button';

import styles from './style.module.css';

function AccordionServices({ isFloating }) {
  return (
    <Accordion className={styles.accordion}>
      <AccordionItem className={styles['accordion-item']}>
        {({ open: servicesOpen }) => (
          <>
            <AccordionHeader className={styles['accordion-header']}>
              Pricing
              {servicesOpen ? (
                <FiChevronUp size={20} />
              ) : (
                <FiChevronDown size={20} />
              )}
            </AccordionHeader>
            <AccordionBody
              className={
                isFloating
                  ? styles['accordion-body-floating']
                  : styles['accordion-body']
              }
            >
              <div className={styles['accordion-body-children']}>
                <Link
                  to="/platform#pricing"
                  className={styles['accordion-body-item']}
                >
                  Platform
                </Link>
                <Link
                  to="/services#services-pricing"
                  className={styles['accordion-body-item']}
                >
                  Services
                </Link>
              </div>
            </AccordionBody>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

function AccordionMenu() {
  return (
    <Accordion className={styles.menuAccordion}>
      <AccordionItem className={styles['menu-accordion-item']}>
        {({ open: servicesOpen }) => (
          <>
            <AccordionHeader className={styles['menu-accordion-header']}>
              {servicesOpen ? <FiX size={20} /> : <FiMenu size={20} />}
            </AccordionHeader>
            <AccordionBody className={styles['menu-accordion-body']}>
              <div className={styles['menu-accordion-body-children']}>
                <Link to="/platform" className={styles['accordion-body-item']}>
                  Platform
                </Link>
                <Link to="/services" className={styles['accordion-body-item']}>
                  Services
                </Link>
                <AccordionServices />
                <Button
                  hierarchy="primary"
                  href="/login"
                  icon={FiFigma}
                  theme="light"
                >
                  Login
                </Button>
              </div>
            </AccordionBody>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

AccordionServices.propTypes = {
  isFloating: propTypes.bool,
};

AccordionServices.defaultProps = {
  isFloating: false,
};

export { AccordionMenu, AccordionServices };
