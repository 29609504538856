import { toast } from 'react-toastify';

import UserForm from 'containers/user-form';
import UserAvatarForm from 'containers/user-avatar-form';

import styles from './style.module.css';

function Profile() {
  const onSuccess = () => {
    toast.success('User settings saved');
  };

  return (
    <div className={styles['profile-page']}>
      <UserAvatarForm />
      <UserForm onSuccess={onSuccess} />
    </div>
  );
}
export default Profile;
