import { useEditor } from 'pages/editor/editor-context';
import useInterval from 'hooks/utils/use-interval';
import { useFilePreviewMutation } from 'hooks/api/files';

function UpdateFilePreview() {
  const { fileId, html } = useEditor();
  const previewMutation = useFilePreviewMutation({
    fileId,
    html,
  });

  const updatePreview = () => {
    previewMutation.mutate();
  };

  useInterval(updatePreview);

  return null;
}

export default UpdateFilePreview;
