export async function espCreateMutation({ client, esp, values }) {
  const { data } = await client.post(`esps/`, {
    ...values,
    email_service_provider: esp,
  });
  return data;
}

export async function espUpdateMutation({ client, id, values }) {
  const { data } = await client.patch(`esps/${id}/`, values);
  return data;
}

export async function espRemoveMutation({ client, id }) {
  await client.delete(`esps/${id}/`);
}
