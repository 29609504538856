import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import Button from '@scalero-inc/forno-button';
import Dropdown from '@scalero-inc/forno-dropdown';

import { getPages, NEXT_PAGE, PREV_PAGE } from './get-pages';
import styles from './styles.module.css';

function Pagination({
  current,
  total,
  pageSize,
  neighbours,
  onPageChange,
  onPageSizeChange,
}) {
  const totalPages = Math.ceil(total / pageSize);
  const options = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
  ];

  const handlePageChange = (i) => {
    onPageChange(i);
  };

  const handlePageSizeChange = (event) => {
    const newValue = event.value;
    onPageSizeChange(newValue);
  };

  const pages = getPages({ totalPages, current, neighbours });

  return (
    <div className={styles.container}>
      <div className={styles['pagination-page-size']}>
        <span>Items per page:</span>
        <Dropdown
          size="s"
          look="text"
          options={options}
          onChange={(event) => handlePageSizeChange(event)}
          defaultValue={{ label: pageSize, value: pageSize }}
        />
      </div>

      <div className={styles['pagination-container']}>
        <nav>
          <ul className={styles['pagination-list']}>
            {pages.map((page, index) => {
              const active = current === page;

              const isNext = page === NEXT_PAGE;
              const isPrev = page === PREV_PAGE;

              if (isPrev)
                return (
                  <li className={styles['pagination-item']} key={index}>
                    <Button
                      size="s"
                      hierarchy="secondary"
                      onClick={() => {
                        handlePageChange(current - 1);
                      }}
                    >
                      <FiChevronLeft />
                    </Button>
                  </li>
                );

              if (isNext)
                return (
                  <li className={styles['pagination-item']} key={index}>
                    <Button
                      size="s"
                      hierarchy="secondary"
                      onClick={() => {
                        handlePageChange(current + 1);
                      }}
                    >
                      <FiChevronRight />
                    </Button>
                  </li>
                );

              return (
                <li key={index} className={styles['pagination-item']}>
                  <Button
                    size="s"
                    hierarchy={active ? 'primary' : 'secondary'}
                    onClick={() => {
                      handlePageChange(page);
                    }}
                  >
                    {page}
                  </Button>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>

      <div />
    </div>
  );
}

Pagination.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  neighbours: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

Pagination.defaultProps = {
  current: 1,
  pageSize: 20,
  neighbours: 1,
  onPageChange: () => {},
  onPageSizeChange: () => {},
  total: 0,
};

export default Pagination;
