import React from 'react';

import PreFooterCta from 'components/pre-footer-cta';
import PublicPage from 'containers/public-page';

import HeroSection from './hero-section';
import BrandsSection from './brands-section';
import QuoteSection from './quote-section';
import OurOffering from './our-offering';

export default function Home() {
  return (
    <PublicPage>
      <HeroSection />
      <BrandsSection />
      <OurOffering />
      <QuoteSection />
      <PreFooterCta />
    </PublicPage>
  );
}
