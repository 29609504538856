import React, { useContext, createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { viewCodeOptions } from './constants';

const ReadOnlyViewStateContext = createContext();
const ReadOnlyViewDispatchContext = createContext();

export const initialViewColumns = '1fr 16px 1fr';

const initialCodeView = viewCodeOptions[0].value;
const initialPreviewView = 'iframe';

function ReadOnlyViewProvider({ children }) {
  const [viewColumns, setViewColumns] = useState(initialViewColumns);
  const [viewCode, setViewCode] = useState(initialCodeView);
  const [viewPreviewSize, setViewPreviewSize] = useState();
  const [viewPreview, setViewPreview] = useState(initialPreviewView);

  const editorViewState = useMemo(() => {
    return {
      viewColumns,
      viewCode,
      viewPreview,
      viewPreviewSize,
    };
  }, [viewColumns, viewCode, viewPreview, viewPreviewSize]);

  const editorViewDispatch = useMemo(() => {
    return {
      setViewColumns,
      setViewCode,
      setViewPreview,
      setViewPreviewSize,
    };
  }, [setViewColumns, setViewCode, setViewPreview, setViewPreviewSize]);

  return (
    <ReadOnlyViewStateContext.Provider value={editorViewState}>
      <ReadOnlyViewDispatchContext.Provider value={editorViewDispatch}>
        {children}
      </ReadOnlyViewDispatchContext.Provider>
    </ReadOnlyViewStateContext.Provider>
  );
}

ReadOnlyViewProvider.propTypes = {
  children: PropTypes.node,
};

ReadOnlyViewProvider.defaultProps = {
  children: null,
};

function useReadOnlyView() {
  const context = useContext(ReadOnlyViewStateContext);

  if (context === undefined) {
    throw new Error(
      'ReadOnly State must be used within a ReadOnlyViewProvider'
    );
  }

  return context;
}

function useReadOnlyViewDispatch() {
  const dispatch = useContext(ReadOnlyViewDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      'useReadOnlyDispatch must be used within a ReadOnlyViewProvider'
    );
  }

  return dispatch;
}

export { ReadOnlyViewProvider, useReadOnlyView, useReadOnlyViewDispatch };
