import PropTypes from 'prop-types';
import { useLayoutEffect, useState, useRef } from 'react';
import OverlayNode, { STATUS } from 'components/overlay-node';
import { useEditor } from 'pages/editor/editor-context';

function HighlightNodeById({ document, id, origin, action, status }) {
  const { html } = useEditor();
  const [element, setElement] = useState();
  const overlayRef = useRef(null);

  useLayoutEffect(() => {
    if (document && document.body.hasChildNodes() && id) {
      const node = document.querySelector(`[data-id='${id}']`);
      setElement(node.firstElementChild);
    }
  }, [document, id, html]);

  if (!element || !id) return null;

  return (
    <OverlayNode
      key={`${id}_overlay`}
      ref={overlayRef}
      document={document}
      element={element}
      type="dashed"
      origin={origin}
      interactive
      action={action}
      status={status}
      id={id}
    />
  );
}

HighlightNodeById.propTypes = {
  document: PropTypes.shape({
    body: PropTypes.shape({
      hasChildNodes: PropTypes.func,
    }),
    querySelector: PropTypes.func,
  }),
  status: PropTypes.oneOf([STATUS.SUCCESS, STATUS.ERROR]),
  id: PropTypes.string,
  origin: PropTypes.string,
  action: PropTypes.func,
};

HighlightNodeById.defaultProps = {
  document: null,
  status: STATUS.SUCCESS,
  id: '',
  origin: '',
  action: () => {},
};

export default HighlightNodeById;
