import classNames from 'classnames';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';

import IconButton from '@scalero-inc/forno-icon-button';

import HeaderPrivate from 'containers/header-private';
import PricingCardsPrivate from 'containers/pricing-cards-private';

import styles from './style.module.css';

function ChoosePlan() {
  const customClassname = classNames(styles['change-plan-page']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('team');

  return (
    <>
      <HeaderPrivate />
      <main className={customClassname}>
        <h2 className={styles['change-plan-title']}>
          <IconButton size="l" onClick={() => navigate(-1)}>
            <FiChevronLeft />
          </IconButton>
          Choose plan
        </h2>
        <div className={styles['change-plan-content']}>
          <PricingCardsPrivate teamId={teamId} type="create" />
        </div>
      </main>
    </>
  );
}

export default ChoosePlan;
