import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useRef } from 'react';

import { useAuth } from 'context/auth';

const { REACT_APP_HEAP_CODE } = process.env;

function HeapTracker({ heap }) {
  const heapLoaded = useRef(false);
  const heapIdentified = useRef(false);
  const auth = useAuth();

  const { user } = auth?.user || {};

  useEffect(() => {
    if (heapLoaded.current) return;
    if (!heap) return;
    if (!heap.load) return;

    heap.load(REACT_APP_HEAP_CODE);
    heapLoaded.current = true;
  }, [heap]);

  useLayoutEffect(() => {
    if (heapIdentified.current) return;
    if (!heapLoaded.current) return;
    if (!heap) return;
    if (!heap.identify) return;

    if (!user) return;
    if (process.env.REACT_APP_ENV !== 'prod') return;

    try {
      heap.identify(user.id);
      heap.addUserProperties({
        Username: user.email,
        Name: `${user.first_name} ${user.last_name}`,
      });
      heapIdentified.current = true;
    } catch (e) {
      console.error(e);
    }
  }, [user, heap]);

  return null;
}

HeapTracker.propTypes = {
  heap: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      load: PropTypes.func,
      identify: PropTypes.func,
      addUserProperties: PropTypes.func,
    }),
    PropTypes.shape({
      load: PropTypes.func,
    }),
  ]),
};

HeapTracker.defaultProps = {
  heap: null,
};

export default HeapTracker;
