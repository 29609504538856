/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import json2mjml from 'json2mjml';
import { useState, useCallback } from 'react';
import { useEditor, useEditorDispatch } from 'pages/editor/editor-context';
import Button from '@scalero-inc/forno-button';
import Spinner from '@scalero-inc/forno-spinner';
import FontsDropdownMenu from 'components/fonts-dropdown-menu';
import FallbackFontModal from 'containers/fallback-font-modal';
import CustomFontModal from 'containers/custom-font-modal';
import GoogleFontsAdminModal from 'containers/google-fonts-admin-modal';
import CustomFontsAdminModal from 'containers/custom-fonts-admin-modal';
import { getMjmlComponentsByAttribute } from 'utils/mjml/util';
import {
  replaceFontInChildren,
  replaceHtmlEntities,
  replaceRGBColors,
} from './utils';

import styles from './styles.module.css';

function InputFont({
  isError,
  isLoading,
  googleFonts,
  webSafeFonts,
  customFonts,
  fontStacks,
  defaultFontFamily,
  isMissingFont,
  mjmlNodeRef,
}) {
  const {
    fileId,
    componentId,
    teamId,
    json,
    missingFonts,
    nodesWithIds = [],
    mjHead,
  } = useEditor();
  const { editor, setMissingFonts } = useEditorDispatch(
    teamId,
    fileId,
    componentId
  );

  const saveChanges = useCallback(() => {
    const mjml = json2mjml(json);
    editor.save(mjml);
  }, [editor, json]);

  const [isGoogleFontsOpen, setIsGoogleFontsOpen] = useState(false);
  const openGoogleFontsWindow = useCallback(() => {
    setIsGoogleFontsOpen(true);
  }, []);
  const closeGoogleFontsWindow = useCallback(() => {
    setIsGoogleFontsOpen(false);
  }, []);

  const [isCustomFontsOpen, setIsCustomFontsOpen] = useState(false);
  const openCustomFontsWindow = useCallback(() => {
    setIsCustomFontsOpen(true);
  }, []);
  const closeCustomFontsWindow = useCallback(() => {
    setIsCustomFontsOpen(false);
  }, []);

  const [isAddCustomFontOpen, setIsAddCustomFontOpen] = useState(false);
  const openAddCustomFontWindow = useCallback(() => {
    setIsAddCustomFontOpen(true);
  }, []);
  const closeAddCustomFontWindow = useCallback(() => {
    setIsAddCustomFontOpen(false);
  }, []);

  const [isFallbackOpen, setIsFallbackOpen] = useState(false);
  const openFallbackWindow = useCallback(() => {
    setIsFallbackOpen(true);
  }, []);
  const closeFallbackWindow = useCallback(() => {
    setIsFallbackOpen(false);
  }, []);

  const fallbackStackOptions = fontStacks.filter(
    (stack) =>
      stack.value.baseFont === defaultFontFamily.family ||
      stack.value.baseFont === ''
  );
  const allFontoptions = [...googleFonts, ...customFonts, ...webSafeFonts];
  const defaultFontId = allFontoptions.find(
    (font) => font.value.content === defaultFontFamily.family
  );
  const defaultWeight = defaultFontFamily.weight
    ? ` (${defaultFontFamily.weight})`
    : '';
  const defaultFont = {
    label: defaultFontFamily.family + defaultWeight,
    value: { id: null, content: defaultFontFamily.family },
  };
  const fallbackStack = defaultFontFamily.fallback.join(', ');
  const defaultFallback = {
    label: fallbackStack,
    value: { id: null, content: fallbackStack },
  };

  const fontTabs = [
    {
      name: 'All',
      options: allFontoptions,
      addItem: {
        label: 'Add Google font',
        callback: () => openGoogleFontsWindow(),
      },
    },
    {
      name: 'Custom',
      options: customFonts,
      addItem: {
        label: 'Add Custom font',
        callback: () => openCustomFontsWindow(),
      },
    },
    {
      name: 'Web safe',
      options: webSafeFonts,
    },
  ];
  const newStackButton = {
    label: 'New stack',
    callback: () => openFallbackWindow(),
  };

  const replaceFontInline = (nodeRef, newFontFamily) => {
    try {
      const parser = new DOMParser();
      const htmlString = `<div>${nodeRef.content}</div>`;
      // Using "application/xml" to avoid changes in tags
      const htmlDocument = parser.parseFromString(
        htmlString,
        'application/xml'
      );
      const htmlElement = htmlDocument.body.children[0];
      replaceFontInChildren(htmlElement.children, newFontFamily);

      const newContent = replaceRGBColors(htmlElement.innerHTML);
      nodeRef.content = replaceHtmlEntities(newContent);
    } catch (error) {
      console.warn('Error replacing font: ', error);
    }
  };

  const replaceAllFonts = useCallback(
    (oldFontFamily, oldFontWeight, newFontFamily) => {
      const mjmlComponents = getMjmlComponentsByAttribute(
        nodesWithIds,
        'font-family'
      );

      mjmlComponents.forEach((textRef) => {
        const fontStack = textRef.tag.attributes['font-family']
          .split(',')
          .map((font) => font.trim());
        const mainFont = fontStack[0] ?? '';

        if (
          mainFont.toLowerCase() === oldFontFamily.toLowerCase() &&
          textRef.tag.attributes['font-weight'] === oldFontWeight
        ) {
          textRef.tag.attributes['font-family'] = newFontFamily;
          replaceFontInline(textRef.tag, newFontFamily);
        }
      });
    },
    [nodesWithIds]
  );

  const saveFont = useCallback(
    (newFontFamily) => {
      if (mjmlNodeRef) {
        // Replace just one mj-text element
        mjmlNodeRef.attributes['font-family'] = newFontFamily;
        replaceFontInline(mjmlNodeRef, newFontFamily);
      } else {
        replaceAllFonts(
          defaultFontFamily.family,
          defaultFontFamily.weight,
          newFontFamily
        );
      }

      saveChanges();
    },
    [
      defaultFontFamily.family,
      defaultFontFamily.weight,
      mjmlNodeRef,
      replaceAllFonts,
      saveChanges,
    ]
  );

  const onFontChange = useCallback(
    (newFont) => {
      const newFontFamily =
        fallbackStack === '' ? newFont : `${newFont}, ${fallbackStack}`;
      saveFont(newFontFamily);
    },
    [fallbackStack, saveFont]
  );

  const onFallbackChange = useCallback(
    (newFallback) => {
      const newFontFamily = `${defaultFontFamily.family}, ${newFallback}`;
      saveFont(newFontFamily);
    },
    [defaultFontFamily.family, saveFont]
  );

  const onLoadMissingFontURL = useCallback(
    ({ family, weight, url, stack }) => {
      // Import custom font
      const styleWithFont = {
        tagName: 'mj-style',
        content: `@font-face {
          font-family: '${family}';
          font-weight: ${weight};
          font-display: swap;
          src: url(${url})
        }`,
      };
      mjHead.children.push(styleWithFont);

      // Update missing fonts in context
      const missingFontsUpdated = missingFonts.map((font) => {
        if (font.family === family && font.variant === weight) {
          const newFont = { ...font, url };
          return newFont;
        }
        return font;
      });
      setMissingFonts(missingFontsUpdated);

      // Add font with stack to mjml components with "font-family" attribute
      const newStack = stack.map((element) => element.family).join(', ');
      const newFontFamily = `${family}, ${newStack}`;

      saveFont(newFontFamily);
    },
    [missingFonts, mjHead.children, saveFont, setMissingFonts]
  );

  if (isError) {
    return (
      <div className={styles['error-message']}>
        Can not load your fonts, try again later.
      </div>
    );
  }

  return (
    <>
      <div className={styles['font-container']}>
        <div>
          <span>Font name</span>
          <div>
            {isLoading && <Spinner />}
            {!isLoading && (
              <FontsDropdownMenu
                defaultValue={defaultFont}
                tabs={fontTabs}
                onChange={({ value }) => {
                  onFontChange(value.content);
                }}
              />
            )}
          </div>
        </div>

        {isMissingFont ? (
          <div className={styles['font-fallback']}>
            <Button
              size="s"
              theme="dark"
              hierarchy="secondary"
              onClick={() => openAddCustomFontWindow()}
            >
              Add missing font
            </Button>
          </div>
        ) : (
          <div>
            <span>Fallback stack</span>
            <div>
              {isLoading && <Spinner />}
              {!isLoading && (
                <FontsDropdownMenu
                  defaultValue={defaultFallback}
                  options={fallbackStackOptions}
                  addItem={newStackButton}
                  onChange={({ value }) => {
                    onFallbackChange(value.content);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {isGoogleFontsOpen && (
        <GoogleFontsAdminModal theme="dark" close={closeGoogleFontsWindow} />
      )}

      {isCustomFontsOpen && (
        <CustomFontsAdminModal
          teamId={teamId}
          close={closeCustomFontsWindow}
          theme="dark"
        />
      )}

      {isFallbackOpen && (
        <FallbackFontModal
          close={closeFallbackWindow}
          isOpen={isFallbackOpen}
          font={{ ...defaultFontFamily, baseFontId: defaultFontId?.value?.id }}
          teamId={teamId}
          onFallbackCreated={onFallbackChange}
        />
      )}

      <CustomFontModal
        isOpen={isAddCustomFontOpen}
        close={closeAddCustomFontWindow}
        font={{
          ...defaultFontFamily,
          fallback: { name: '', stack: [] },
        }}
        teamId={teamId}
        onLoadFont={onLoadMissingFontURL}
        theme="dark"
      />
    </>
  );
}

InputFont.propTypes = {
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  googleFonts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  webSafeFonts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFonts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fontStacks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultFontFamily: PropTypes.shape({
    family: PropTypes.string,
    weight: PropTypes.string,
    fallback: PropTypes.arrayOf(PropTypes.string),
  }),
  isMissingFont: PropTypes.bool,
  mjmlNodeRef: PropTypes.shape({
    tagName: PropTypes.string,
    content: PropTypes.string,
    attributes: PropTypes.shape(),
  }),
};

InputFont.defaultProps = {
  defaultFontFamily: {},
  isMissingFont: false,
  mjmlNodeRef: null,
};

export default InputFont;
