import { getBorderAttributes } from '../border';
import { getPadding } from '../bounding-box';
import { getAlignment } from '../utils';

function MjDivider({
  parent,
  attributes,
  strokes,
  strokeWeight,
  strokeAlign,
  strokeCap,
  strokeDashes,
  tagName,
  ...rest
}) {
  const alignment = getAlignment(parent);
  const padding = getPadding(parent, rest, tagName);
  const cssClass = { 'css-class': 'divider' };

  return [
    {
      tagName: 'mj-divider',
      errors: [],
      children: [],
      attributes: {
        ...attributes,
        ...cssClass,
        align: alignment,
        ...(padding && padding),
        ...getBorderAttributes({
          strokes,
          strokeWeight,
          strokeAlign,
          strokeCap,
          strokeDashes,
        }),
      },
    },
  ];
}

export default MjDivider;
