import classNames from 'classnames';
import Button from '@scalero-inc/forno-button';
import { plans } from 'containers/dashboard-billing/constants';
import VideoPlayer from 'components/video-player';
import { useAuth } from 'context/auth';
import heroVideoPoster from './cannoli_intro_hero_poster.png';
import heroVideo from './cannoli_intro_hero.mp4';

import styles from './style.module.css';

function HeroSection() {
  const linkClassName = classNames('paragraph-sl', styles.link);
  const { user } = useAuth();
  const tryCannoliURL = user
    ? '/dashboard'
    : `/login?create-account=${plans.proTrial.slug}`;

  return (
    <section className={styles['hero-section']}>
      <div className={styles['']}>
        <div className={styles['hero-section-video-mobile']}>
          <VideoPlayer src={heroVideo} poster={heroVideoPoster} />
        </div>

        <div className={styles['hero-section-container']}>
          <h1>Turn Figma designs into email HTML</h1>
          <p className="paragraph-l">
            Love designing in Figma and want to code emails fast? Cannoli is the
            answer.
          </p>

          <div className={styles['hero-section-video-desktop']}>
            <VideoPlayer src={heroVideo} poster={heroVideoPoster} />
          </div>

          <div className={styles['try-cannoli-container']}>
            <Button hierarchy="primary" size="l" href={tryCannoliURL}>
              Try Cannoli for free
            </Button>
            <a className={linkClassName} href="#pricing">
              See pricing
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
