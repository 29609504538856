import React from 'react';
import { BsCheckCircle } from 'react-icons/bs';

import PublicPage from 'containers/public-page';

import styles from './styles.module.css';

function CheckoutSuccess() {
  return (
    <PublicPage>
      <section className={styles.section}>
        <BsCheckCircle size={100} />
        <h2>Thanks for your order!</h2>
        <p className="paragraph-r">
          Your order is under review. We will notify you via email when it has
          been approved.
          <br />
          Note that we may not be able to start working on your order if vital
          information is missing.
          <br />
          If that’s the case, you will be notified via email.
        </p>
        <p className="paragraph-r">
          To see what we need to work on your order{' '}
          <a href="/policies/services" target="_blank">
            see our policies
          </a>
          .
        </p>
      </section>
    </PublicPage>
  );
}

export default CheckoutSuccess;
