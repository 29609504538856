import heroImg from 'images/home/hero-asset.png';

import styles from './style.module.css';

function HeroSection() {
  return (
    <>
      <div className={styles['hero-section-image-mobile']}>
        <img src={heroImg} alt="Cannoli" />
      </div>

      <section className={styles['hero-section']}>
        <h1>Code your Figma designs faster</h1>
        <p className="paragraph-l">
          Cannoli translates your Figma designs into email code. Seamlessly
          transform your Figma frames into email-ready code and easily get your
          email to the finish line, without having to write a single line of
          code.
        </p>

        <div className={styles['hero-section-image-desktop']}>
          <img src={heroImg} alt="Cannoli" />
        </div>

        <div className={styles['hero-section-upvote-us']}>
          <p className="paragraph-sl">
            Your support could make a huge difference!
            <br />
            Make sure to upvote us on Product Hunt
          </p>
          <a
            href="https://www.producthunt.com/posts/cannoli?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cannoli"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=411398&theme=light"
              alt="Cannoli - Turn&#0032;Figma&#0032;designs&#0032;into&#0032;email&#0032;HTML | Product Hunt"
              width="220"
              height="47"
              className={styles['upvote-us-asset']}
            />
          </a>
        </div>
      </section>
    </>
  );
}

export default HeroSection;
