export async function userEditMutation({ client, values }) {
  const bodyFormData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    bodyFormData.append(key, value);
  });
  return client.patch('/user/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function userEditAvatarMutation({ client, file }) {
  const { name } = file || {};
  const bodyFormData = new FormData();
  bodyFormData.append('avatar', file || '');
  return client.patch('/user/', bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': `attachment; filename=${name}`,
    },
  });
}
