import PropTypes from 'prop-types';
import { FiPhone } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import Spinner from '@scalero-inc/forno-spinner';
import Button from '@scalero-inc/forno-button';

import { useSubscriptionQuery } from 'hooks/api/subscriptions';
import { useUserQuery } from 'hooks/api/users';
import {
  USER_SUBSCRIPTION_STATUS,
  USER_SUBSCRIPTION_TIER,
} from 'hooks/api/users/constants';

import { canCreateEditDeleteTeamSubscription } from 'utils/permissions';

import BillingPlanSection from './billing-plan-section';
import { plans, status } from './constants';
import styles from './style.module.css';
import BillingDetail from './billing-detail';

function getCanViewBillingDetails(subscription) {
  if (subscription.tier === USER_SUBSCRIPTION_TIER.FREE) return false;
  return true;
}

function DashboardBilling({ subscription }) {
  const getPlan = (tier) => {
    if (tier === USER_SUBSCRIPTION_TIER.TEAM) {
      return plans.team;
    }
    if (tier === USER_SUBSCRIPTION_TIER.PRO) {
      return plans.pro;
    }
    if (tier === USER_SUBSCRIPTION_TIER.FREE) {
      return plans.starter;
    }
    return plans.starter;
  };

  // TODO: provably a better way to do this, with a dict maybe
  const getStatus = (state) => {
    if (state === USER_SUBSCRIPTION_STATUS.ACTIVE) return status.active;
    if (state === USER_SUBSCRIPTION_STATUS.OVERDUE) return status.overdue;
    if (state === USER_SUBSCRIPTION_STATUS.PENDING) return status.pending;
    if (state === USER_SUBSCRIPTION_STATUS.CANCELED) return status.canceled;
    return status.unknown;
  };

  const { data: user } = useUserQuery();
  const { teamId } = useParams();

  const isAdmin = teamId
    ? canCreateEditDeleteTeamSubscription({ user, teamId })
    : true;

  const { data: information, isLoading } = useSubscriptionQuery({
    subscriptionId: subscription.id,
  });

  if (isLoading) return <Spinner />;

  const showBillingDetails = getCanViewBillingDetails(subscription);

  return (
    <div className={styles['dashboard-billing']}>
      <h2>Billing</h2>
      <div className={styles['dashboard-billing-content']}>
        <BillingPlanSection
          plan={getPlan(subscription.tier)}
          status={getStatus(subscription.status)}
          subscriptionId={subscription.id}
          isAdmin={isAdmin}
          nextBilling={subscription.current_period_end}
          information={information}
          hasValidTrial={user?.has_valid_trial}
          trialDaysLeft={user?.trial_days_left}
        />
        {showBillingDetails && (
          <BillingDetail
            subscriptionId={subscription.id}
            information={information}
            isAdmin={isAdmin}
            status={getStatus(subscription.status)}
          />
        )}
      </div>
      <hr />
      <Button
        href="https://calendly.com/will-scalero/30min"
        icon={FiPhone}
        rel="noreferrer noopener"
        target="_blank"
      >
        Custom Plan
      </Button>
    </div>
  );
}

DashboardBilling.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.number,
    seats: PropTypes.number,
    tier: PropTypes.string,
    status: PropTypes.string,
    current_period_start: PropTypes.string,
    current_period_end: PropTypes.string,
    team: PropTypes.number,
    user: PropTypes.number,
  }).isRequired,
};

export default DashboardBilling;
