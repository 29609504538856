import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

function PolicyContainer({ children }) {
  const customClassName = classNames(
    'paragraph-sl',
    styles['privacy-policy-text']
  );

  return (
    <div className={styles['privacy-policy-text-wrapper']}>
      <div className={customClassName}>{children}</div>
    </div>
  );
}

PolicyContainer.propTypes = {
  children: PropTypes.node,
};

PolicyContainer.defaultProps = {
  children: null,
};

export default PolicyContainer;
