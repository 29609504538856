import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import InputShorthand from '../input-shorthand';

import styles from './styles.module.css';

const borderStyles = ['none', 'dotted', 'dashed', 'solid', 'double'];

const parseBorderAttribs = (border = '') => {
  const borderAttribs = {
    width: '',
    style: '',
    color: '',
  };

  const regex = /\s+/g;
  const borderAttrib = border.replace(regex, ' ');
  const attribs = borderAttrib.split(' ');

  attribs.forEach((element) => {
    if (element.includes('px')) {
      borderAttribs.width = element;
    } else if (borderStyles.includes(element)) {
      borderAttribs.style = element;
    } else {
      borderAttribs.color = element;
    }
  });

  return borderAttribs;
};

function WidgetBorder({ defaultValue, attributes, handleChange }) {
  const [borderValue, setBorderValue] = useState(
    parseBorderAttribs(defaultValue)
  );
  const topAttrib = parseBorderAttribs(attributes.top);
  const rightAttrib = parseBorderAttribs(attributes.right);
  const bottomAttrib = parseBorderAttribs(attributes.bottom);
  const leftAttrib = parseBorderAttribs(attributes.left);

  const [border, setBorder] = useState({
    width: {
      top: topAttrib.width,
      right: rightAttrib.width,
      bottom: bottomAttrib.width,
      left: leftAttrib.width,
    },
    style: {
      top: topAttrib.style,
      right: rightAttrib.style,
      bottom: bottomAttrib.style,
      left: leftAttrib.style,
    },
    color: {
      top: topAttrib.color,
      right: rightAttrib.color,
      bottom: bottomAttrib.color,
      left: leftAttrib.color,
    },
  });

  const getBorderOptions = () => {
    const options = borderStyles.map((style) => {
      return { label: style, value: style };
    });

    return options;
  };

  const onValueChange = useCallback(
    (newValue, attribute) => {
      const newAttribValues = newValue.split(' ');
      const newBorder = { ...borderValue, [attribute]: newValue };
      const newBorders = { ...border };

      if (newAttribValues.length === 1) {
        setBorderValue(newBorder);
      } else {
        const [top, right, bottom, left] = newAttribValues;
        const attribValues = {
          top,
          right,
          bottom,
          left,
        };
        newBorders[attribute] = attribValues;

        setBorder(newBorders);
      }

      const borderTop = `${newBorders.width.top || newBorder.width} ${
        newBorders.style.top || newBorder.style
      } ${newBorders.color.top || newBorder.color}`
        .replaceAll('-', '')
        .trim();
      const borderRight = `${newBorders.width.right || newBorder.width} ${
        newBorders.style.right || newBorder.style
      } ${newBorders.color.right || newBorder.color}`
        .replaceAll('-', '')
        .trim();
      const borderBottom = `${newBorders.width.bottom || newBorder.width} ${
        newBorders.style.bottom || newBorder.style
      } ${newBorders.color.bottom || newBorder.color}`
        .replaceAll('-', '')
        .trim();
      const borderLeft = `${newBorders.width.left || newBorder.width} ${
        newBorders.style.left || newBorder.style
      } ${newBorders.color.left || newBorder.color}`
        .replaceAll('-', '')
        .trim();

      const borderAttribs = {};
      if (
        borderTop === borderRight &&
        borderRight === borderBottom &&
        borderBottom === borderLeft
      ) {
        borderAttribs.border =
          `${newBorder.width} ${newBorder.style} ${newBorder.color}`.trim();
        borderAttribs['border-top'] = '';
        borderAttribs['border-right'] = '';
        borderAttribs['border-bottom'] = '';
        borderAttribs['border-left'] = '';
        return handleChange(borderAttribs);
      }

      borderAttribs.border = '';
      borderAttribs['border-top'] = borderTop;
      borderAttribs['border-right'] = borderRight;
      borderAttribs['border-bottom'] = borderBottom;
      borderAttribs['border-left'] = borderLeft;
      return handleChange(borderAttribs);
    },
    [border, borderValue, handleChange]
  );

  return (
    <div className={styles.container}>
      <InputShorthand
        label="Color"
        attribName="border-color"
        defaultValue={borderValue.color}
        attributes={border.color}
        type="text"
        handleChange={(newValue) => {
          onValueChange(newValue, 'color');
        }}
      />

      <InputShorthand
        label="Width"
        attribName="border-width"
        defaultValue={borderValue.width}
        attributes={border.width}
        type="size"
        handleChange={(newValue) => {
          onValueChange(newValue, 'width');
        }}
      />

      <InputShorthand
        label="Stroke style"
        attribName="border-style"
        defaultValue={borderValue.style}
        attributes={border.style}
        type="list"
        options={getBorderOptions()}
        handleChange={(newValue) => {
          onValueChange(newValue, 'style');
        }}
      />
    </div>
  );
}

WidgetBorder.propTypes = {
  defaultValue: PropTypes.string,
  attributes: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

WidgetBorder.defaultProps = {
  defaultValue: '',
};

export default WidgetBorder;
