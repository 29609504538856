import PropTypes from 'prop-types';
import { useState } from 'react';

function SectionContainer({ children, setActiveTag }) {
  const [isDragging, setIsDragging] = useState(false);

  const onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveTag();
    setIsDragging(true);
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const onClick = () => {
    setIsDragging(false);
  };

  return (
    <div
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      {children({ isDragging })}
    </div>
  );
}

SectionContainer.propTypes = {
  children: PropTypes.func,
  setActiveTag: PropTypes.func,
};

SectionContainer.defaultProps = {
  children: () => {},
  setActiveTag: () => {},
};

export default SectionContainer;
