import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFigma } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';

import companyLogo from 'images/scalero-logo.png';
import SOCBadge from 'images/SOC-badge.png';
import { ReactComponent as CannoliLogo } from 'svg/cannoli-white-logo.svg';
import { ReactComponent as KlaviyoLogo } from 'svg/icons/klaviyo.svg';
import { ReactComponent as MailchimpLogo } from 'svg/icons/mailchimp.svg';

import styles from './style.module.css';

function FooterPublic({ vertical }) {
  const customClassName = classnames(styles['footer-public'], {
    [styles.vertical]: vertical,
  });

  const year = new Date().getFullYear();

  return (
    <footer className={customClassName}>
      <div className={styles['footer-main-content']}>
        <section className={styles['company-logos']}>
          <CannoliLogo className={styles['footer-cannoli-logo']} />
          <div>
            <p>Created by</p>
            <a href="https://scalero.io/" alt="Scalero Site">
              <img
                className={styles['company-logo']}
                src={companyLogo}
                alt="Company logo"
              />
            </a>
          </div>

          <div className={styles['footer-badges-container']}>
            <a
              href="https://www.producthunt.com/posts/cannoli?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-cannoli"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=411398&theme=light"
                alt="Cannoli - Turn&#0032;Figma&#0032;designs&#0032;into&#0032;email&#0032;HTML | Product Hunt"
                width="220"
                height="47"
              />
            </a>
            <img width={120} src={SOCBadge} alt="AICPA SOC" />
          </div>
        </section>
        <section className={styles['footer-section']}>
          <h3>Integrations</h3>
          <div>
            <a target="_blank" rel="noreferrer" href="https://www.figma.com/">
              <FiFigma />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.klaviyo.com/">
              <KlaviyoLogo />
            </a>
            <a target="_blank" rel="noreferrer" href="https://mailchimp.com/">
              <MailchimpLogo />
            </a>
          </div>
        </section>
        <section className={styles['footer-section']}>
          <h3>Resources</h3>
          <Button
            hierarchy="tertiary"
            theme="dark"
            href="https://www.figma.com/community/plugin/1053770884164943496/Cannoli---Figma-to-email-code%2C-including-HTML-and-MJML"
          >
            Figma Plugin
          </Button>
          <Button
            hierarchy="tertiary"
            theme="dark"
            href="https://scalero.notion.site/Cannoli-Documentation-f80cb2baac8a41eea40949d549465fe4"
          >
            Documentation
          </Button>
          <Button
            hierarchy="tertiary"
            theme="dark"
            href="https://www.figma.com/community/file/1042861645873665842"
          >
            Email kit
          </Button>
          <Button
            hierarchy="tertiary"
            theme="dark"
            href="https://www.youtube.com/channel/UCnSTDEAE6MRSPzSJ-y85Pyg"
          >
            Video Tutorials
          </Button>
        </section>
        <section className={styles['company-information']}>
          <div className={styles['details-address']}>
            <p>Two Embarcadero Center, 8th floor</p>
            <p>San Francisco, CA 94111</p>
            <p>United States</p>
          </div>
          <div className={styles['details-contact']}>
            <p>Contact us</p>
            <a href="mailto:hello@cannoli.app">
              <strong>hello@cannoli.app</strong>
            </a>
          </div>
        </section>
      </div>
      <div className={styles['footer-legals']}>
        <p>
          @{year} All Rights Reserved · Scalero ·{' '}
          <a href="/policies/privacy">Privacy Policy</a>
        </p>
      </div>
    </footer>
  );
}

FooterPublic.propTypes = {
  vertical: PropTypes.bool,
};

FooterPublic.defaultProps = {
  vertical: false,
};

export default FooterPublic;
