export const WEB_SAFE_FONTS = [
  { family: 'Arial', fallback: 'sans-serif' },
  { family: 'Verdana', fallback: 'sans-serif' },
  { family: 'Helvetica', fallback: 'sans-serif' },
  { family: 'Tahoma', fallback: 'sans-serif' },
  { family: 'Trebuchet MS', fallback: 'sans-serif' },
  { family: 'Times New Roman', fallback: 'serif' },
  { family: 'Georgia', fallback: 'serif' },
];

export const DEFAULT_FALLBACKS = [
  { family: 'sans-serif' },
  { family: 'serif' },
];
