import PropTypes from 'prop-types';

import { dollarUSFormat } from 'utils/currency';

function TeamSubscriptionUpdateBreakdown({ extra }) {
  return (
    <table>
      <tbody>
        <tr>
          <td>Approximate extra amount for the total of seats:</td>
          <td>{dollarUSFormat.format(extra)} USD</td>
        </tr>
      </tbody>
    </table>
  );
}

TeamSubscriptionUpdateBreakdown.propTypes = {
  extra: PropTypes.number.isRequired,
};

export default TeamSubscriptionUpdateBreakdown;
