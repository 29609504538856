import { waitForSeconds } from 'utils';

const IMAGE_RESOLVE_WAIT_TIME = 3;
const BACKEND_PARSING_WAIT_TIME = 3;

export async function filesQuery({ client, queryOrder }) {
  const { data } = await client.get(`figma/files/?ordering=${queryOrder}`);
  return data;
}

export async function filesPaginatedQuery({
  client,
  queryOrder,
  queryPage,
  pageSize,
  teamId,
  projectId,
}) {
  const queryTeam = teamId ? `&team=${teamId}` : '';
  const queryProject = projectId ? `&project=${projectId}` : '';
  const queryPageSize = pageSize ? `&page_size=${pageSize}` : '';
  const { data } = await client.get(
    `figma/files_paginated/?ordering=${queryOrder}&page=${queryPage}${queryPageSize}${queryTeam}${queryProject}`
  );
  return data;
}

export async function fileQuery({ client, fileId, sh }) {
  const url = sh ? `figma/files/${fileId}/?sh=${sh}` : `figma/files/${fileId}/`;

  const { data } = await client.get(url);
  return data;
}

export async function fileWaitForFileProcessedQuery({ client, fileId }) {
  let fetched = false;
  let res = null;

  while (!fetched) {
    // eslint-disable-next-line no-await-in-loop
    res = await fileQuery({ client, fileId });
    fetched = res.fetched_all_images;
    // eslint-disable-next-line no-await-in-loop
    await waitForSeconds(IMAGE_RESOLVE_WAIT_TIME);
  }
  return res;
}

export async function filesByTeamProjectQuery({ client }) {
  const { data } = await client.get(`figma/files_by_team_and_project/`);
  return data;
}

export async function fileWaitForFileBackendProcessedQuery({ client, fileId }) {
  let processed = null;
  let res = null;

  while (processed === null) {
    // eslint-disable-next-line no-await-in-loop
    res = await fileQuery({ client, fileId });
    processed = res.successfully_processed;
    // eslint-disable-next-line no-await-in-loop
    await waitForSeconds(BACKEND_PARSING_WAIT_TIME);
  }
  return res;
}

export async function fileShareHashQuery({ client, fileId }) {
  const { data } = await client.get(`figma/files/${fileId}/share_hash/`);
  return data;
}
