import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';
import { CgTrash } from 'react-icons/cg';
import {
  useTeamComponentDeleteMutation,
  useUserComponentsPaginatedInvalidateQuery,
  useTeamComponentsPaginatedInvalidateQuery,
  useUserComponentDeleteMutation,
} from 'hooks/api/components';

function ComponentDeleteModal({
  id,
  name,
  teamId,
  numComponents,
  page,
  setPage,
  isOpen,
  onDismiss,
}) {
  const invalidateUserComponents = useUserComponentsPaginatedInvalidateQuery();
  const invalidateTeamComponents = useTeamComponentsPaginatedInvalidateQuery({
    teamId,
  });
  const invalidateComponents = teamId
    ? invalidateTeamComponents
    : invalidateUserComponents;

  const config = {
    onSuccess: () => {
      onDismiss();
      // In case the component is the last of the page, go back one page
      if (page !== 1 && numComponents === 1) {
        setPage(page - 1);
      } else {
        invalidateComponents();
      }
      toast.success('Block deleted successfully');
    },
    onError: () => {
      toast.error('Error deleting block');
    },
  };
  const userDeleteMutation = useUserComponentDeleteMutation({
    config,
  });
  const teamDeleteMutation = useTeamComponentDeleteMutation({
    teamId,
    config,
  });
  const deleteMutation = teamId ? teamDeleteMutation : userDeleteMutation;

  const deleteBlock = () => {
    deleteMutation.mutate(id);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      status="danger"
      aria-label="delete block warning"
      theme="light"
      dangerouslyBypassFocusLock
    >
      <Modal.Header
        title={`Delete ${name}`}
        onDismiss={onDismiss}
        icon={CgTrash}
      />
      <p>
        Are you sure you want to delete the block <strong>{name}</strong>?
      </p>
      <p>
        This action <strong>cannot</strong> be undone.
      </p>
      <Modal.Footer>
        <Button theme="light" hierarchy="tertiary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          status="danger"
          loading={deleteMutation.isLoading}
          disabled={deleteMutation.isLoading}
          onClick={() => deleteBlock()}
        >
          <CgTrash />
          <span>Delete</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ComponentDeleteModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  numComponents: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

ComponentDeleteModal.defaultProps = {
  teamId: null,
  isOpen: false,
};

export default ComponentDeleteModal;
