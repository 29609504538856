export const MOCK_COMPONENT_NAME = 'Client Footer';
export const MOCK_COMPONENT_TAGS = ['footer', 'mjml', 'blue'];
export const MOCK_COMPONENT_DATA = `
 <mj-section background-color="#2372b2" css-class="section" padding="64px 198px 64px 199px">
      <mj-column css-class="column" vertical-align="middle" width="323px">
        <mj-image align="center" css-class="image" href="#" name="IMAGE" padding="0px 77px 20px 76px" src="https://media-dev.cannoli.app/media/figma/39b73b8a9c99d94ebe4329d361710c4f_ExrahfO.png" width="170px">

        </mj-image>
        <mj-divider border-color="#b4dfec" border-style="solid" border-width="0.5px" css-class="divider" padding="0px 0px 20px 0px">

        </mj-divider>
        <mj-text align="left" color="#ffffff" css-class="text" font-family="Gill Sans" font-size="15px" font-weight="400" line-height="20px" padding="0px 0px 20px 0px">
          800 Nicollet Mall, Minneapolis, MN 55402
        </mj-text>
        <mj-text align="left" color="#ffffff" css-class="text" font-family="Gill Sans" font-size="14px" font-weight="700" line-height="24px" padding="0px 0px 20px 0px" text-decoration="underline">
          Contact Us
        </mj-text>
        <mj-text align="left" color="#ffffff" css-class="text" font-family="Roboto" font-size="14px" font-weight="700" line-height="24px" padding="0px 0px 20px 0px" text-decoration="underline">
          <a href="#" style="font-family:Gill Sans; font-size:14px; line-height:22px; font-weight:400; text-decoration:underline; color:#ffffff">Manage your email preferences</a><span style="font-family:Gill Sans; font-size:14px; line-height:22px; font-weight:400; color:#ffffff">  |  </span><a href="#" style="font-family:Gill Sans; font-size:14px; line-height:22px; font-weight:400; text-decoration:underline; color:#ffffff">Unsubscribe</a>e
        </mj-text>
        <mj-text align="left" color="#ffffff" css-class="text" font-family="Gill Sans" font-size="14px" font-weight="400" line-height="22px" padding="0px 0px 0px 0px">
          © 2021 femotors
        </mj-text>
      </mj-column>
    </mj-section>
`;
export const MOCK_COMPONENT_DATA_IN_JSON = {
  file: '.',
  absoluteFilePath: '.',
  line: 72,
  includedIn: [],
  tagName: 'mj-section',
  attributes: {
    'background-color': '#fcf9f2',
    border: '1px solid #000000',
    'css-class': 'section',
    padding: '31px 167px 31px 167px',
  },
  children: [
    {
      file: '.',
      absoluteFilePath: '.',
      line: 73,
      includedIn: [],
      tagName: 'mj-column',
      attributes: {
        'css-class': 'column',
        padding: '0px 0px 0px 0px',
        'vertical-align': 'middle',
        width: '16.58%',
      },
      children: [
        {
          file: '.',
          absoluteFilePath: '.',
          line: 74,
          includedIn: [],
          tagName: 'mj-image',
          attributes: {
            align: 'center',
            alt: 'Image',
            'css-class': 'image',
            height: '64px',
            href: '#',
            src: 'https://media-dev.cannoli.app/media/figma/92e3b63a44f1c85698536aaef88ddc53.png',
            width: '64px',
          },
        },
      ],
    },
    {
      file: '.',
      absoluteFilePath: '.',
      line: 76,
      includedIn: [],
      tagName: 'mj-column',
      attributes: { 'css-class': 'padB40', width: '8.55%' },
      children: [
        {
          file: '.',
          absoluteFilePath: '.',
          line: 77,
          includedIn: [],
          tagName: 'mj-spacer',
          attributes: { height: '0px' },
        },
      ],
    },
    {
      file: '.',
      absoluteFilePath: '.',
      line: 79,
      includedIn: [],
      tagName: 'mj-column',
      attributes: {
        'css-class': 'column',
        'vertical-align': 'middle',
        width: '74.87%',
      },
      children: [
        {
          file: '.',
          absoluteFilePath: '.',
          line: 80,
          includedIn: [],
          tagName: 'mj-text',
          attributes: {
            align: 'center',
            color: '#000000',
            'css-class': 'text',
            'font-family': 'Courier',
            'font-size': '32px',
            'font-weight': '400',
            'letter-spacing': '0px',
            'line-height': '32px',
          },
          content: 'The Daily Daily\n<br/>',
        },
      ],
    },
  ],
};
