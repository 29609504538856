export const breakPointWidth = 480;

export const tagNameRegex = /([ A-Za-z0-9]+[-|_+][ A-Za-z0-9]+)/;
export const socialIconNameRegex =
  /([ A-Za-z0-9]+[-|_+][ A-Za-z0-9]+[-|_+][ A-Za-z0-9]+)?/;

export const safeFonts = [
  'arial',
  'courier',
  'verdana',
  'helvetica',
  'times new roman',
  'georgia',
  'tahoma',
  'lucida',
  'trebuchet',
];

export const tagsDicts = [
  {
    tag: 'mj-wrapper',
    css: [
      '.wrapper > table > tbody > tr > td { padding-left: 0px !important; padding-right: 0px !important; }',
    ],
    dict: [
      'mj-wrapper',
      'j-wrapper',
      'm-wrapper',
      'mjwrapper',
      'mj-rapper',
      'mj-wapper',
      'mj-wrpper',
      'mj-wraper',
      'mj-wrappr',
      'mj-wrappe',
      'jm-wrapper',
      'm-jwrapper',
      'mjw-rapper',
      'mj-rwapper',
      'mj-warpper',
      'mj-wrpaper',
      'mj-wrapepr',
      'mj-wrappre',
      'mmj-wrapper',
      'mjj-wrapper',
      'mj--wrapper',
      'mj-wwrapper',
      'mj-wrrapper',
      'mj-wraapper',
      'mj-wrappper',
      'mj-wrappeer',
      'mj-wrapperr',
      'nj-wrapper',
      ',j-wrapper',
      'mh-wrapper',
      'mk-wrapper',
      'mj_wrapper',
      'mj-qrapper',
      'mj-erapper',
      'mj-weapper',
      'mj-wtapper',
      'mj-wrspper',
      'mj-wraoper',
      'mj-wra[per',
      'mj-wrapoer',
      'mj-wrap[er',
      'mj-wrappwr',
      'mj-wrapprr',
      'mj-wrappee',
      'mj-wrappet',
      'mnj-wrapper',
      'nmj-wrapper',
      'm,j-wrapper',
      ',mj-wrapper',
      'mjh-wrapper',
      'mhj-wrapper',
      'mjk-wrapper',
      'mkj-wrapper',
      'mj-_wrapper',
      'mj_-wrapper',
      'mj-wqrapper',
      'mj-qwrapper',
      'mj-werapper',
      'mj-ewrapper',
      'mj-wreapper',
      'mj-wrtapper',
      'mj-wtrapper',
      'mj-wraspper',
      'mj-wrsapper',
      'mj-wrapoper',
      'mj-wraopper',
      'mj-wrap[per',
      'mj-wra[pper',
      'mj-wrappoer',
      'mj-wrapp[er',
      'mj-wrappewr',
      'mj-wrappwer',
      'mj-wrapprer',
      'mj-wrappere',
      'mj-wrappert',
      'mj-wrappetr',
    ],
  },
  {
    tag: 'mj-section',
    css: [
      '.section > table > tbody > tr > td { padding-left: 20px !important; padding-right: 20px !important; }',
    ],
    dict: [
      'mj-section',
      'j-section',
      'm-section',
      'mjsection',
      'mj-ection',
      'mj-sction',
      'mj-setion',
      'mj-secion',
      'mj-secton',
      'mj-sectin',
      'mj-sectio',
      'jm-section',
      'm-jsection',
      'mjs-ection',
      'mj-esction',
      'mj-scetion',
      'mj-setcion',
      'mj-seciton',
      'mj-sectoin',
      'mj-sectino',
      'mmj-section',
      'mjj-section',
      'mj--section',
      'mj-ssection',
      'mj-seection',
      'mj-secction',
      'mj-secttion',
      'mj-sectiion',
      'mj-sectioon',
      'mj-sectionn',
      'nj-section',
      ',j-section',
      'mh-section',
      'mk-section',
      'mj_section',
      'mj-aection',
      'mj-dection',
      'mj-swction',
      'mj-srction',
      'mj-sextion',
      'mj-sevtion',
      'mj-secrion',
      'mj-secyion',
      'mj-sectuon',
      'mj-sectoon',
      'mj-sectiin',
      'mj-sectipn',
      'mj-sectiob',
      'mj-sectiom',
      'mnj-section',
      'nmj-section',
      'm,j-section',
      ',mj-section',
      'mjh-section',
      'mhj-section',
      'mjk-section',
      'mkj-section',
      'mj-_section',
      'mj_-section',
      'mj-saection',
      'mj-asection',
      'mj-sdection',
      'mj-dsection',
      'mj-sewction',
      'mj-swection',
      'mj-serction',
      'mj-srection',
      'mj-secxtion',
      'mj-sexction',
      'mj-secvtion',
      'mj-sevction',
      'mj-sectrion',
      'mj-secrtion',
      'mj-sectyion',
      'mj-secytion',
      'mj-sectiuon',
      'mj-sectuion',
      'mj-sectoion',
      'mj-sectioin',
      'mj-sectiopn',
      'mj-sectipon',
      'mj-sectionb',
      'mj-sectiobn',
      'mj-sectionm',
      'mj-sectiomn',
      '(row)',
    ],
  },
  {
    tag: 'mj-column',
    css: [
      '.column > table > tbody > tr > td { padding-left: 0px !important; padding-right: 0px !important; }',
      '.padB40 { padding-bottom: 40px !important; }',
    ],
    dict: [
      'mj-column',
      'j-column',
      'm-column',
      'mjcolumn',
      'mj-olumn',
      'mj-clumn',
      'mj-coumn',
      'mj-colmn',
      'mj-colun',
      'mj-colum',
      'jm-column',
      'm-jcolumn',
      'mjc-olumn',
      'mj-oclumn',
      'mj-cloumn',
      'mj-coulmn',
      'mj-colmun',
      'mj-colunm',
      'mmj-column',
      'mjj-column',
      'mj--column',
      'mj-ccolumn',
      'mj-coolumn',
      'mj-collumn',
      'mj-coluumn',
      'mj-colummn',
      'mj-columnn',
      'nj-column',
      ',j-column',
      'mh-column',
      'mk-column',
      'mj_column',
      'mj-xolumn',
      'mj-volumn',
      'mj-cilumn',
      'mj-cplumn',
      'mj-cokumn',
      'mj-co;umn',
      'mj-colymn',
      'mj-colimn',
      'mj-colunn',
      'mj-colu,n',
      'mj-columb',
      'mj-columm',
      'mnj-column',
      'nmj-column',
      'm,j-column',
      ',mj-column',
      'mjh-column',
      'mhj-column',
      'mjk-column',
      'mkj-column',
      'mj-_column',
      'mj_-column',
      'mj-cxolumn',
      'mj-xcolumn',
      'mj-cvolumn',
      'mj-vcolumn',
      'mj-coilumn',
      'mj-ciolumn',
      'mj-coplumn',
      'mj-cpolumn',
      'mj-colkumn',
      'mj-coklumn',
      'mj-col;umn',
      'mj-co;lumn',
      'mj-coluymn',
      'mj-colyumn',
      'mj-coluimn',
      'mj-coliumn',
      'mj-colunmn',
      'mj-colum,n',
      'mj-colu,mn',
      'mj-columnb',
      'mj-columbn',
      'mj-columnm',
      '(column)',
    ],
  },
  {
    tag: 'mj-social-element',
    dict: [
      'mj-social-element',
      'j-social-element',
      'm-social-element',
      'mjsocial-element',
      'mj-ocial-element',
      'mj-scial-element',
      'mj-soial-element',
      'mj-socal-element',
      'mj-socil-element',
      'mj-socia-element',
      'mj-socialelement',
      'mj-social-lement',
      'mj-social-eement',
      'mj-social-elment',
      'mj-social-eleent',
      'mj-social-elemnt',
      'mj-social-elemet',
      'mj-social-elemen',
      'jm-social-element',
      'm-jsocial-element',
      'mjs-ocial-element',
      'mj-oscial-element',
      'mj-scoial-element',
      'mj-soical-element',
      'mj-socail-element',
      'mj-socila-element',
      'mj-socia-lelement',
      'mj-sociale-lement',
      'mj-social-leement',
      'mj-social-eelment',
      'mj-social-elmeent',
      'mj-social-eleemnt',
      'mj-social-elemnet',
      'mj-social-elemetn',
      'mmj-social-element',
      'mjj-social-element',
      'mj--social-element',
      'mj-ssocial-element',
      'mj-soocial-element',
      'mj-soccial-element',
      'mj-sociial-element',
      'mj-sociaal-element',
      'mj-sociall-element',
      'mj-social--element',
      'mj-social-eelement',
      'mj-social-ellement',
      'mj-social-eleement',
      'mj-social-elemment',
      'mj-social-elemeent',
      'mj-social-elemennt',
      'mj-social-elementt',
      'nj-social-element',
      ',j-social-element',
      'mh-social-element',
      'mk-social-element',
      'mj_social-element',
      'mj-aocial-element',
      'mj-docial-element',
      'mj-sicial-element',
      'mj-spcial-element',
      'mj-soxial-element',
      'mj-sovial-element',
      'mj-socual-element',
      'mj-socoal-element',
      'mj-socisl-element',
      'mj-sociak-element',
      'mj-socia;-element',
      'mj-social_element',
      'mj-social-wlement',
      'mj-social-rlement',
      'mj-social-ekement',
      'mj-social-e;ement',
      'mj-social-elwment',
      'mj-social-elrment',
      'mj-social-elenent',
      'mj-social-ele,ent',
      'mj-social-elemwnt',
      'mj-social-elemrnt',
      'mj-social-elemebt',
      'mj-social-elememt',
      'mj-social-elemenr',
      'mj-social-elemeny',
      'mnj-social-element',
      'nmj-social-element',
      'm,j-social-element',
      ',mj-social-element',
      'mjh-social-element',
      'mhj-social-element',
      'mjk-social-element',
      'mkj-social-element',
      'mj-_social-element',
      'mj_-social-element',
      'mj-saocial-element',
      'mj-asocial-element',
      'mj-sdocial-element',
      'mj-dsocial-element',
      'mj-soicial-element',
      'mj-siocial-element',
      'mj-sopcial-element',
      'mj-spocial-element',
      'mj-socxial-element',
      'mj-soxcial-element',
      'mj-socvial-element',
      'mj-sovcial-element',
      'mj-sociual-element',
      'mj-socuial-element',
      'mj-socioal-element',
      'mj-socoial-element',
      'mj-sociasl-element',
      'mj-socisal-element',
      'mj-socialk-element',
      'mj-sociakl-element',
      'mj-social;-element',
      'mj-socia;l-element',
      'mj-social-_element',
      'mj-social_-element',
      'mj-social-ewlement',
      'mj-social-welement',
      'mj-social-erlement',
      'mj-social-relement',
      'mj-social-elkement',
      'mj-social-eklement',
      'mj-social-el;ement',
      'mj-social-e;lement',
      'mj-social-elewment',
      'mj-social-elwement',
      'mj-social-elerment',
      'mj-social-elrement',
      'mj-social-elemnent',
      'mj-social-elenment',
      'mj-social-elem,ent',
      'mj-social-ele,ment',
      'mj-social-elemewnt',
      'mj-social-elemwent',
      'mj-social-elemernt',
      'mj-social-elemrent',
      'mj-social-elemenbt',
      'mj-social-elemebnt',
      'mj-social-elemenmt',
      'mj-social-elememnt',
      'mj-social-elementr',
      'mj-social-elemenrt',
      'mj-social-elementy',
      'mj-social-elemenyt',
      'social icon link',
    ],
  },
  {
    tag: 'mj-social',
    dict: [
      'mj-social',
      'j-social',
      'm-social',
      'mjsocial',
      'mj-ocial',
      'mj-scial',
      'mj-soial',
      'mj-socal',
      'mj-socil',
      'mj-socia',
      'jm-social',
      'm-jsocial',
      'mjs-ocial',
      'mj-oscial',
      'mj-scoial',
      'mj-soical',
      'mj-socail',
      'mj-socila',
      'mmj-social',
      'mjj-social',
      'mj--social',
      'mj-ssocial',
      'mj-soocial',
      'mj-soccial',
      'mj-sociial',
      'mj-sociaal',
      'mj-sociall',
      'nj-social',
      ',j-social',
      'mh-social',
      'mk-social',
      'mj_social',
      'mj-aocial',
      'mj-docial',
      'mj-sicial',
      'mj-spcial',
      'mj-soxial',
      'mj-sovial',
      'mj-socual',
      'mj-socoal',
      'mj-socisl',
      'mj-sociak',
      'mj-socia;',
      'mnj-social',
      'nmj-social',
      'm,j-social',
      ',mj-social',
      'mjh-social',
      'mhj-social',
      'mjk-social',
      'mkj-social',
      'mj-_social',
      'mj_-social',
      'mj-saocial',
      'mj-asocial',
      'mj-sdocial',
      'mj-dsocial',
      'mj-soicial',
      'mj-siocial',
      'mj-sopcial',
      'mj-spocial',
      'mj-socxial',
      'mj-soxcial',
      'mj-socvial',
      'mj-sovcial',
      'mj-sociual',
      'mj-socuial',
      'mj-socioal',
      'mj-socoial',
      'mj-sociasl',
      'mj-socisal',
      'mj-socialk',
      'mj-sociakl',
      'mj-social;',
      'mj-socia;l',
      'social',
    ],
  },
  {
    tag: 'mj-image',
    css: [
      '.image { padding-left: 0px !important; padding-right: 0px !important; }',
      '.image > table { text-align: center !important; margin: 0 auto !important; }',
    ],
    check: (node) => node?.fills[0]?.type === 'IMAGE',
    dict: [
      'mj-image',
      'j-image',
      'm-image',
      'mjimage',
      'mj-mage',
      'mj-iage',
      'mj-imge',
      'mj-imae',
      'mj-imag',
      'jm-image',
      'm-jimage',
      'mji-mage',
      'mj-miage',
      'mj-iamge',
      'mj-imgae',
      'mj-imaeg',
      'mmj-image',
      'mjj-image',
      'mj--image',
      'mj-iimage',
      'mj-immage',
      'mj-imaage',
      'mj-imagge',
      'mj-imagee',
      'nj-image',
      ',j-image',
      'mh-image',
      'mk-image',
      'mj_image',
      'mj-umage',
      'mj-omage',
      'mj-inage',
      'mj-i,age',
      'mj-imsge',
      'mj-imafe',
      'mj-imahe',
      'mj-imagw',
      'mj-imagr',
      'mnj-image',
      'nmj-image',
      'm,j-image',
      ',mj-image',
      'mjh-image',
      'mhj-image',
      'mjk-image',
      'mkj-image',
      'mj-_image',
      'mj_-image',
      'mj-iumage',
      'mj-uimage',
      'mj-iomage',
      'mj-oimage',
      'mj-imnage',
      'mj-inmage',
      'mj-im,age',
      'mj-i,mage',
      'mj-imasge',
      'mj-imsage',
      'mj-imagfe',
      'mj-imafge',
      'mj-imaghe',
      'mj-imahge',
      'mj-imagew',
      'mj-imagwe',
      'mj-imager',
      'mj-imagre',
    ],
  },
  {
    tag: 'mj-text',
    check: (node) => node?.type === 'TEXT',
    dict: [
      'mj-text',
      'j-text',
      'm-text',
      'mjtext',
      'mj-ext',
      'mj-txt',
      'mj-tet',
      'mj-tex',
      'jm-text',
      'm-jtext',
      'mjt-ext',
      'mj-etxt',
      'mj-txet',
      'mj-tetx',
      'mmj-text',
      'mjj-text',
      'mj--text',
      'mj-ttext',
      'mj-teext',
      'mj-texxt',
      'mj-textt',
      'nj-text',
      ',j-text',
      'mh-text',
      'mk-text',
      'mj_text',
      'mj-rext',
      'mj-yext',
      'mj-twxt',
      'mj-trxt',
      'mj-tezt',
      'mj-tect',
      'mj-texr',
      'mj-texy',
      'mnj-text',
      'nmj-text',
      'm,j-text',
      ',mj-text',
      'mjh-text',
      'mhj-text',
      'mjk-text',
      'mkj-text',
      'mj-_text',
      'mj_-text',
      'mj-trext',
      'mj-rtext',
      'mj-tyext',
      'mj-ytext',
      'mj-tewxt',
      'mj-twext',
      'mj-terxt',
      'mj-texzt',
      'mj-tezxt',
      'mj-texct',
      'mj-tecxt',
      'mj-textr',
      'mj-texrt',
      'mj-texty',
      'mj-texyt',
    ],
  },
  {
    tag: 'mj-hero',
    dict: [
      'mj-hero',
      'j-hero',
      'm-hero',
      'mjhero',
      'mj-ero',
      'mj-hro',
      'mj-heo',
      'mj-her',
      'jm-hero',
      'm-jhero',
      'mjh-ero',
      'mj-ehro',
      'mj-hreo',
      'mj-heor',
      'mmj-hero',
      'mjj-hero',
      'mj--hero',
      'mj-hhero',
      'mj-heero',
      'mj-herro',
      'mj-heroo',
      'nj-hero',
      ',j-hero',
      'mh-hero',
      'mk-hero',
      'mj_hero',
      'mj-gero',
      'mj-jero',
      'mj-hwro',
      'mj-hrro',
      'mj-heeo',
      'mj-heto',
      'mj-heri',
      'mj-herp',
      'mnj-hero',
      'nmj-hero',
      'm,j-hero',
      ',mj-hero',
      'mjh-hero',
      'mhj-hero',
      'mjk-hero',
      'mkj-hero',
      'mj-_hero',
      'mj_-hero',
      'mj-hgero',
      'mj-ghero',
      'mj-hjero',
      'mj-jhero',
      'mj-hewro',
      'mj-hwero',
      'mj-hrero',
      'mj-hereo',
      'mj-herto',
      'mj-hetro',
      'mj-heroi',
      'mj-herio',
      'mj-herop',
      'mj-herpo',
    ],
  },
  {
    tag: 'mj-divider',
    css: [
      '.divider { padding-left: 0px !important; padding-right: 0px !important; }',
    ],
    dict: [
      'mj-divider',
      'j-divider',
      'm-divider',
      'mjdivider',
      'mj-ivider',
      'mj-dvider',
      'mj-diider',
      'mj-divder',
      'mj-divier',
      'mj-dividr',
      'mj-divide',
      'jm-divider',
      'm-jdivider',
      'mjd-ivider',
      'mj-idvider',
      'mj-dviider',
      'mj-diivder',
      'mj-divdier',
      'mj-diviedr',
      'mj-dividre',
      'mmj-divider',
      'mjj-divider',
      'mj--divider',
      'mj-ddivider',
      'mj-diivider',
      'mj-divvider',
      'mj-diviider',
      'mj-dividder',
      'mj-divideer',
      'mj-dividerr',
      'nj-divider',
      ',j-divider',
      'mh-divider',
      'mk-divider',
      'mj_divider',
      'mj-sivider',
      'mj-fivider',
      'mj-duvider',
      'mj-dovider',
      'mj-dicider',
      'mj-dibider',
      'mj-divuder',
      'mj-divoder',
      'mj-diviser',
      'mj-divifer',
      'mj-dividwr',
      'mj-dividrr',
      'mj-dividee',
      'mj-dividet',
      'mnj-divider',
      'nmj-divider',
      'm,j-divider',
      ',mj-divider',
      'mjh-divider',
      'mhj-divider',
      'mjk-divider',
      'mkj-divider',
      'mj-_divider',
      'mj_-divider',
      'mj-dsivider',
      'mj-sdivider',
      'mj-dfivider',
      'mj-fdivider',
      'mj-diuvider',
      'mj-duivider',
      'mj-diovider',
      'mj-doivider',
      'mj-divcider',
      'mj-dicvider',
      'mj-divbider',
      'mj-dibvider',
      'mj-diviuder',
      'mj-divuider',
      'mj-divioder',
      'mj-divoider',
      'mj-dividser',
      'mj-divisder',
      'mj-dividfer',
      'mj-divifder',
      'mj-dividewr',
      'mj-dividwer',
      'mj-dividrer',
      'mj-dividere',
      'mj-dividert',
      'mj-dividetr',
    ],
  },
  {
    tag: 'mj-button',
    css: [
      '.button > table{ width: 100% !important; }',
      '.button > table > tbody > tr > td > a{ width: 80% !important; }',
    ],
    dict: [
      'mj-button',
      'j-butto',
      'm-butto',
      'mjbutto',
      'mj-utto',
      'mj-btto',
      'mj-buto',
      'mj-butt',
      'jm-butto',
      'm-jbutto',
      'mjb-utto',
      'mj-ubtto',
      'mj-btuto',
      'mj-butot',
      'mmj-butto',
      'mjj-butto',
      'mj--butto',
      'mj-bbutto',
      'mj-buutto',
      'mj-buttto',
      'mj-buttoo',
      'nj-butto',
      ',j-butto',
      'mh-butto',
      'mk-butto',
      'mj_butto',
      'mj-vutto',
      'mj-nutto',
      'mj-bytto',
      'mj-bitto',
      'mj-burto',
      'mj-buyto',
      'mj-butro',
      'mj-butyo',
      'mj-butti',
      'mj-buttp',
      'mnj-butto',
      'nmj-butto',
      'm,j-butto',
      ',mj-butto',
      'mjh-butto',
      'mhj-butto',
      'mjk-butto',
      'mkj-butto',
      'mj-_butto',
      'mj_-butto',
      'mj-bvutto',
      'mj-vbutto',
      'mj-bnutto',
      'mj-nbutto',
      'mj-buytto',
      'mj-byutto',
      'mj-buitto',
      'mj-biutto',
      'mj-butrto',
      'mj-burtto',
      'mj-butyto',
      'mj-buttro',
      'mj-buttyo',
      'mj-buttoi',
      'mj-buttio',
      'mj-buttop',
      'mj-buttpo',
      'button',
    ],
  },
  {
    tag: 'mj-spacer',
    dict: [
      'mj-spacer',
      'j-spacer',
      'm-spacer',
      'mjspacer',
      'mj-pacer',
      'mj-sacer',
      'mj-spcer',
      'mj-spaer',
      'mj-spacr',
      'mj-space',
      'jm-spacer',
      'm-jspacer',
      'mjs-pacer',
      'mj-psacer',
      'mj-sapcer',
      'mj-spcaer',
      'mj-spaecr',
      'mj-spacre',
      'mmj-spacer',
      'mjj-spacer',
      'mj--spacer',
      'mj-sspacer',
      'mj-sppacer',
      'mj-spaacer',
      'mj-spaccer',
      'mj-spaceer',
      'mj-spacerr',
      'nj-spacer',
      ',j-spacer',
      'mh-spacer',
      'mk-spacer',
      'mj_spacer',
      'mj-apacer',
      'mj-dpacer',
      'mj-soacer',
      'mj-s[acer',
      'mj-spscer',
      'mj-spaxer',
      'mj-spaver',
      'mj-spacwr',
      'mj-spacrr',
      'mj-spacee',
      'mj-spacet',
      'mnj-spacer',
      'nmj-spacer',
      'm,j-spacer',
      ',mj-spacer',
      'mjh-spacer',
      'mhj-spacer',
      'mjk-spacer',
      'mkj-spacer',
      'mj-_spacer',
      'mj_-spacer',
      'mj-sapacer',
      'mj-aspacer',
      'mj-sdpacer',
      'mj-dspacer',
      'mj-spoacer',
      'mj-sopacer',
      'mj-sp[acer',
      'mj-s[pacer',
      'mj-spascer',
      'mj-spsacer',
      'mj-spacxer',
      'mj-spaxcer',
      'mj-spacver',
      'mj-spavcer',
      'mj-spacewr',
      'mj-spacwer',
      'mj-spacrer',
      'mj-spacere',
      'mj-spacert',
      'mj-spacetr',
      'spacer',
    ],
  },
  {
    tag: 'mj-group',
    dict: [
      'mj-group',
      'j-group',
      'm-group',
      'mjgroup',
      'mj-roup',
      'mj-goup',
      'mj-grup',
      'mj-grop',
      'mj-grou',
      'jm-group',
      'm-jgroup',
      'mjg-roup',
      'mj-rgoup',
      'mj-gorup',
      'mj-gruop',
      'mj-gropu',
      'mmj-group',
      'mjj-group',
      'mj--group',
      'mj-ggroup',
      'mj-grroup',
      'mj-grooup',
      'mj-grouup',
      'mj-groupp',
      'nj-group',
      ',j-group',
      'mh-group',
      'mk-group',
      'mj_group',
      'mj-froup',
      'mj-hroup',
      'mj-geoup',
      'mj-gtoup',
      'mj-griup',
      'mj-grpup',
      'mj-groyp',
      'mj-groip',
      'mj-grouo',
      'mj-grou[',
      'mnj-group',
      'nmj-group',
      'm,j-group',
      ',mj-group',
      'mjh-group',
      'mhj-group',
      'mjk-group',
      'mkj-group',
      'mj-_group',
      'mj_-group',
      'mj-gfroup',
      'mj-fgroup',
      'mj-ghroup',
      'mj-hgroup',
      'mj-greoup',
      'mj-geroup',
      'mj-grtoup',
      'mj-gtroup',
      'mj-groiup',
      'mj-grioup',
      'mj-gropup',
      'mj-grpoup',
      'mj-grouyp',
      'mj-groyup',
      'mj-grouip',
      'mj-groupo',
      'mj-grouop',
      'mj-group[',
      'mj-grou[p',
      'j-section-group',
      'm-section-group',
      'mjsection-group',
      'mj-ection-group',
      'mj-sction-group',
      'mj-setion-group',
      'mj-secion-group',
      'mj-secton-group',
      'mj-sectin-group',
      'mj-sectio-group',
      'mj-sectiongroup',
      'mj-section-roup',
      'mj-section-goup',
      'mj-section-grup',
      'mj-section-grop',
      'mj-section-grou',
      'jm-section-group',
      'm-jsection-group',
      'mjs-ection-group',
      'mj-esction-group',
      'mj-scetion-group',
      'mj-setcion-group',
      'mj-seciton-group',
      'mj-sectoin-group',
      'mj-sectino-group',
      'mj-sectio-ngroup',
      'mj-sectiong-roup',
      'mj-section-rgoup',
      'mj-section-gorup',
      'mj-section-gruop',
      'mj-section-gropu',
      'mmj-section-group',
      'mjj-section-group',
      'mj--section-group',
      'mj-ssection-group',
      'mj-seection-group',
      'mj-secction-group',
      'mj-secttion-group',
      'mj-sectiion-group',
      'mj-sectioon-group',
      'mj-sectionn-group',
      'mj-section--group',
      'mj-section-ggroup',
      'mj-section-grroup',
      'mj-section-grooup',
      'mj-section-grouup',
      'mj-section-groupp',
      'nj-section-group',
      ',j-section-group',
      'mh-section-group',
      'mk-section-group',
      'mj_section-group',
      'mj-aection-group',
      'mj-dection-group',
      'mj-swction-group',
      'mj-srction-group',
      'mj-sextion-group',
      'mj-sevtion-group',
      'mj-secrion-group',
      'mj-secyion-group',
      'mj-sectuon-group',
      'mj-sectoon-group',
      'mj-sectiin-group',
      'mj-sectipn-group',
      'mj-sectiob-group',
      'mj-sectiom-group',
      'mj-section_group',
      'mj-section-froup',
      'mj-section-hroup',
      'mj-section-geoup',
      'mj-section-gtoup',
      'mj-section-griup',
      'mj-section-grpup',
      'mj-section-groyp',
      'mj-section-groip',
      'mj-section-grouo',
      'mj-section-grou[',
      'mnj-section-group',
      'nmj-section-group',
      'm,j-section-group',
      ',mj-section-group',
      'mjh-section-group',
      'mhj-section-group',
      'mjk-section-group',
      'mkj-section-group',
      'mj-_section-group',
      'mj_-section-group',
      'mj-saection-group',
      'mj-asection-group',
      'mj-sdection-group',
      'mj-dsection-group',
      'mj-sewction-group',
      'mj-swection-group',
      'mj-serction-group',
      'mj-srection-group',
      'mj-secxtion-group',
      'mj-sexction-group',
      'mj-secvtion-group',
      'mj-sevction-group',
      'mj-sectrion-group',
      'mj-secrtion-group',
      'mj-sectyion-group',
      'mj-secytion-group',
      'mj-sectiuon-group',
      'mj-sectuion-group',
      'mj-sectoion-group',
      'mj-sectioin-group',
      'mj-sectiopn-group',
      'mj-sectipon-group',
      'mj-sectionb-group',
      'mj-sectiobn-group',
      'mj-sectionm-group',
      'mj-sectiomn-group',
      'mj-section-_group',
      'mj-section_-group',
      'mj-section-gfroup',
      'mj-section-fgroup',
      'mj-section-ghroup',
      'mj-section-hgroup',
      'mj-section-greoup',
      'mj-section-geroup',
      'mj-section-grtoup',
      'mj-section-gtroup',
      'mj-section-groiup',
      'mj-section-grioup',
      'mj-section-gropup',
      'mj-section-grpoup',
      'mj-section-grouyp',
      'mj-section-groyup',
      'mj-section-grouip',
      'mj-section-groupo',
      'mj-section-grouop',
      'mj-section-group[',
      'mj-section-grou[p',
    ],
  },
  {
    tag: 'mj-table',
    dict: [
      'mj-table',
      'j-table',
      'm-table',
      'mjtable',
      'mj-able',
      'mj-tble',
      'mj-tale',
      'mj-tabe',
      'mj-tabl',
      'jm-table',
      'm-jtable',
      'mjt-able',
      'mj-atble',
      'mj-tbale',
      'mj-talbe',
      'mj-tabel',
      'mmj-table',
      'mjj-table',
      'mj--table',
      'mj-ttable',
      'mj-taable',
      'mj-tabble',
      'mj-tablle',
      'mj-tablee',
      'nj-table',
      ',j-table',
      'mh-table',
      'mk-table',
      'mj_table',
      'mj-rable',
      'mj-yable',
      'mj-tsble',
      'mj-tavle',
      'mj-tanle',
      'mj-tabke',
      'mj-tab;e',
      'mj-tablw',
      'mj-tablr',
      'mnj-table',
      'nmj-table',
      'm,j-table',
      ',mj-table',
      'mjh-table',
      'mhj-table',
      'mjk-table',
      'mkj-table',
      'mj-_table',
      'mj_-table',
      'mj-trable',
      'mj-rtable',
      'mj-tyable',
      'mj-ytable',
      'mj-tasble',
      'mj-tsable',
      'mj-tabvle',
      'mj-tavble',
      'mj-tabnle',
      'mj-tanble',
      'mj-tablke',
      'mj-tabkle',
      'mj-tabl;e',
      'mj-tab;le',
      'mj-tablew',
      'mj-tablwe',
      'mj-tabler',
      'mj-tablre',
    ],
  },
];
