import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '@scalero-inc/forno-tooltip';
import Button from '@scalero-inc/forno-button';

import Tag from 'components/tag';
import { plans } from 'containers/dashboard-billing/constants';

import styles from './style.module.css';

function PricingCardBase({
  plan,
  cta,
  activePlan,
  className,
  onClick,
  highlight,
  ctaLook,
  hasValidTrial,
  trialDaysLeft,
  ...rest
}) {
  const customClassname = classNames(styles['pricing-card'], className, {
    [styles['-highlight']]: !!highlight,
  });
  const Icon = plan.icon;
  return (
    <article className={customClassname}>
      {highlight && (
        <div className={styles['pricing-card-highlight']}>{highlight}</div>
      )}
      <div className={styles['pricing-card-content']}>
        <div>
          <div className={styles['pricing-card-header']}>
            <div className={styles['pricing-card-name']}>
              <span>
                <Icon /> {plan.name}
                {!activePlan ||
                (hasValidTrial && plan.name === plans.starter.name) ? null : (
                  <Tag className={styles.tag}>Current Plan</Tag>
                )}
                {plan.badge && !activePlan && (
                  <Tag className={styles.badge}>{plan.badge}</Tag>
                )}
              </span>
              <p>{plan.description}</p>
            </div>
            <div className={styles['pricing-card-pricing']}>
              <span>{plan.pricing}</span>
              <p>
                {plan.pricingLegend}{' '}
                {hasValidTrial && plan.name === plans.pro.name && (
                  <span>({trialDaysLeft} days left)</span>
                )}
              </p>
              {plan.pricingNotes && <p>{plan.pricingNotes}</p>}
            </div>
          </div>

          <ul className={styles['pricing-card-features']}>
            {plan.features.map(
              (feature, index) =>
                feature.longDescription && (
                  <Tooltip
                    key={index}
                    placement="right"
                    content={
                      <p className="font-paragraph-s-sb">{feature.tooltip}</p>
                    }
                  >
                    <li>{feature.longDescription}</li>
                  </Tooltip>
                )
            )}
          </ul>
        </div>

        {plan.note && <span className={styles.note}>{plan.note}</span>}

        <Button
          size="l"
          onClick={onClick}
          look={ctaLook}
          disabled={
            activePlan || (hasValidTrial && plan.name === plans.starter.name)
          }
          {...rest}
        >
          {cta}
        </Button>
      </div>
    </article>
  );
}

PricingCardBase.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.func,
    pricing: PropTypes.string,
    pricingLegend: PropTypes.string,
    pricingNotes: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.shape({})),
    badge: PropTypes.string,
    note: PropTypes.string,
  }).isRequired,
  cta: PropTypes.node.isRequired,
  activePlan: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  highlight: PropTypes.string,
  ctaLook: PropTypes.string,
  hasValidTrial: PropTypes.bool,
  trialDaysLeft: PropTypes.number,
};

PricingCardBase.defaultProps = {
  activePlan: false,
  className: null,
  onClick: () => {},
  highlight: null,
  ctaLook: null,
  hasValidTrial: false,
  trialDaysLeft: 0,
};

export default PricingCardBase;
