export async function projectCreateMutation({ client, values, team }) {
  const { name } = values;
  const res = await client.post(`teams/${team}/projects/`, { name });

  return res;
}

export async function projectUpdateMutation({ client, team, project, values }) {
  const { data } = await client.patch(
    `teams/${team}/projects/${project}/`,
    values
  );
  return data;
}

export async function projectDeleteMutation({ client, team, project }) {
  const { data } = await client.delete(`teams/${team}/projects/${project}/`);
  return data;
}
