import { getHeight, getPadding } from '../bounding-box';

function MjSpacer({ parent, attributes, tagName, ...rest }) {
  const padding = getPadding(parent, rest, tagName);
  const height = getHeight(rest);

  return [
    {
      tagName: 'mj-spacer',
      children: [],
      errors: [],
      attributes: {
        ...attributes,
        height: `${height}px`,
        ...(padding && padding),
      },
    },
  ];
}

export default MjSpacer;
