import chroma from 'chroma-js';

export function parseColor(color, opacity = 1) {
  if (!color) return null;
  const { r, g, b, a } = color;
  return chroma(r * 255, g * 255, b * 255, a * opacity);
}

export function getCssGradinetPrefix(type) {
  switch (type) {
    case 'GRADIENT_LINEAR':
      return 'linear-gradient(';
    case 'GRADIENT_RADIAL':
      return 'radial-gradient(circle closest-side,';
    default:
      return '(';
  }
}

export function genCssGradient({ type, gradientStops, opacity }) {
  const prefix = getCssGradinetPrefix(type);
  const css = `${prefix}${gradientStops
    .map((stop) => {
      return `${parseColor(stop.color, opacity).css()} ${stop.position * 100}%`;
    })
    .join()})`;
  return css;
}

export function getSimpleColor(colorsArr) {
  if (colorsArr.length === 0) return null;
  const colors = colorsArr.map((fill) => {
    const { color, opacity, type } = fill;
    const parsedColor = parseColor(color, opacity);

    switch (type) {
      case 'SOLID':
        return parsedColor.hex();
      case 'GRADIENT_LINEAR':
      case 'GRADIENT_RADIAL':
        console.warn('Gradients are not supported for text color');
        return null;
      default:
        return null;
    }
  });

  return colors[colors.length - 1];
}

export function getFillColor({ fills = [] }) {
  if (fills.length === 0) return null;
  const colors = fills.map((fill) => {
    const { color, opacity, type, gradientStops } = fill;
    const parsedColor = parseColor(color, opacity);

    switch (type) {
      case 'SOLID':
        return {
          backgroundColor: `${parsedColor.hex()}`,
        };
      case 'GRADIENT_LINEAR':
      case 'GRADIENT_RADIAL':
        return {
          backgroundColor: 'white',
          backgroundImage: genCssGradient({ type, gradientStops, opacity }),
        };
      default:
        return null;
    }
  });

  return colors[0];
}
