export function getFileIdfromFigmaURL(value) {
  try {
    const url = new URL(value);
    const { pathname, hostname } = url;
    if (hostname.toLowerCase() !== 'www.figma.com') return null;
    const paths = pathname.split('/');
    const fileId = paths[2];
    return fileId;
  } catch (e) {
    return null;
  }
}
