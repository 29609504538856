import PropTypes from 'prop-types';
import { useLayoutEffect } from 'react';

export function useScrollToNodeById({ document, id }) {
  useLayoutEffect(() => {
    if (document && document.body.hasChildNodes() && id) {
      const node = document.querySelector(`[data-id='${id}']`);
      if (node) {
        node.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [document, id]);
}

useScrollToNodeById.propTypes = {
  document: PropTypes.shape({}),
  id: PropTypes.string,
};

useScrollToNodeById.defaultProps = {
  document: null,
  id: null,
};

function ScrollToNodeById({ document, id }) {
  useScrollToNodeById({ document, id });
  return null;
}

ScrollToNodeById.propTypes = {
  document: PropTypes.shape({}),
  id: PropTypes.string,
};

ScrollToNodeById.defaultProps = {
  document: null,
  id: '',
};

export default ScrollToNodeById;
