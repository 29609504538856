import { extractFontsFromNodes } from '../../fonts';
import { breakPointWidth, tagNameRegex } from '../dictionaries';
import {
  getContentComponentsImpl,
  getDefaultPadding,
  getMobileStyles,
  getErrors,
} from './utils';

function MjHead(nodes, parsed) {
  const artboardName = nodes[0]?.name
    ? nodes[0]?.name
        .toUpperCase()
        .replace(tagNameRegex, '')
        .replace(/[-|_]/g, ' ')
        .trim()
    : '-- ADD YOU PREVIEW TEXT HERE --';
  const emailWidth = parsed.attributes ? parsed.attributes.width : '600px';

  const contentComponents = getContentComponentsImpl(parsed, []);
  const headChildren = [
    {
      tagName: 'mj-preview',
      content: artboardName,
    },
    {
      tagName: 'mj-breakpoint',
      attributes: {
        width: `${breakPointWidth}px`,
      },
    },
    {
      tagName: 'mj-attributes',
      children: getDefaultPadding(contentComponents),
    },
    {
      tagName: 'mj-style',
      content: `@media only screen and (max-width:${emailWidth}){ \n
        ${getMobileStyles(contentComponents).join('\n')}
        \n}`,
    },
  ];

  const fontList = extractFontsFromNodes(nodes);
  const fonts = Object.entries(fontList).map(([name, weights]) => {
    return {
      tagName: 'mj-font',
      attributes: {
        name,
        href: `https://fonts.googleapis.com/css2?family=${name.replaceAll(
          ' ',
          '+'
        )}:wght@${weights.join(';')}&display=swap`,
      },
    };
  });

  return {
    tagName: 'mj-head',
    errors: getErrors(),
    children: [...headChildren, ...fonts],
  };
}
export default MjHead;
