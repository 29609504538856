/* eslint-disable import/no-unresolved */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getStylesheetFromRawCss } from 'utils';

import { BsArrowsMove } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';

import IconButton from '@scalero-inc/forno-icon-button';

import { getTagInfoFromId } from 'utils/mjml/util';
import {
  REBASE_COLUMN_ORIGIN_FIGMA,
  REBASE_COLUMN_ORIGIN_CANNOLI,
  REBASE_COLUMN_ORIGIN_NEW_VERSION,
} from 'utils/constants';

import useDangerousDimensions from './use-dangerous-dimensions';

// eslint-disable-next-line import/no-unresolved
import rawCss from '!!raw-loader!./style.module.css';
import style from './style.module.css';

export const STATUS = {
  SUCCESS: 'normal',
  ERROR: 'danger',
};

const OverlayNode = forwardRef(function Overlay(
  {
    className,
    type,
    children,
    element,
    document,
    interactive,
    origin,
    action,
    status,
    id,
    ...rest
  },
  ref
) {
  const { tagName } = getTagInfoFromId(id);
  const customClassName = classNames(
    style['overlay-node'],
    className,
    style[`-${type}`]
  );

  const { width, height, top, left } = useDangerousDimensions({
    element,
    document,
  });

  const stylesObj = {
    width: `${width}px`,
    height: `${height}px`,
    top: `${top}px`,
    left: `${left}px`,
  };

  const stylesheet = getStylesheetFromRawCss(style, rawCss);

  if (interactive)
    return (
      <div
        ref={ref}
        style={stylesObj}
        className={style[`dashed-${status}`]}
        {...rest}
        data-interactive={interactive}
        data-theme={status === STATUS.SUCCESS ? 'dark' : 'light'}
        draggable="true"
      >
        <style>{stylesheet}</style>
        <div className={style['button-container']}>
          {(origin === REBASE_COLUMN_ORIGIN_CANNOLI ||
            origin === REBASE_COLUMN_ORIGIN_FIGMA) && (
            <div className={style['dnd-indicator']}>
              <IconButton
                size="l"
                onClick={() => action(id)}
                hierarchy="primary"
                status={status}
              >
                <BsArrowsMove />
              </IconButton>
              <p>Drag & drop under the New Version </p>
            </div>
          )}

          {origin === REBASE_COLUMN_ORIGIN_NEW_VERSION && (
            <div className={style['delete-indicator']}>
              <IconButton
                size="l"
                onClick={() => action(id)}
                hierarchy="primary"
                status={status}
              >
                <FiTrash2 />
              </IconButton>
            </div>
          )}
        </div>
        <div>
          {tagName && (
            <span className={style[`label-${status}`]}>{tagName}</span>
          )}
          {children}
        </div>
      </div>
    );

  return (
    <div ref={ref} style={stylesObj} className={customClassName} {...rest}>
      <style>{stylesheet}</style>
      <div>
        {tagName && <span>{tagName}</span>}
        {children}
      </div>
    </div>
  );
});

OverlayNode.propTypes = {
  type: PropTypes.oneOf(['highlight', 'dashed']),
  element: PropTypes.shape({}).isRequired,
  document: PropTypes.shape({}),
  children: PropTypes.node,
  className: PropTypes.string,
  interactive: PropTypes.bool,
  origin: PropTypes.string,
  action: PropTypes.func,
  status: PropTypes.oneOf([STATUS.SUCCESS, STATUS.ERROR]),
  id: PropTypes.string,
};

OverlayNode.defaultProps = {
  document,
  type: 'dashed',
  children: null,
  className: null,
  interactive: false,
  origin: null,
  action: () => {},
  status: STATUS.SUCCESS,
  id: '',
};

export default OverlayNode;
