import { useEffect, useRef } from 'react';

export default function useInterval(callback) {
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedCallback = useRef();
  const minutes = 1;
  const time = 1000 * 60 * minutes;

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, time);
    return () => clearInterval(id);
  }, [time]);
}
