import PropTypes from 'prop-types';
import IconButton from '@scalero-inc/forno-icon-button';
import Input from '@scalero-inc/forno-input';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { parseAttributeValue } from 'utils/mjml/util';

import styles from './style.module.css';

function InputIncremental({
  defaultValue,
  placeholder,
  handleChange,
  disabled,
}) {
  const attribValue = parseAttributeValue(defaultValue);
  const numericValue = attribValue.value ? Number(attribValue.value) : 0;

  const decrease = () => {
    handleChange(numericValue - 1);
  };

  const increase = () => {
    handleChange(numericValue + 1);
  };

  return (
    <div className={styles['input-increment-container']}>
      <IconButton
        disabled={disabled}
        hierarchy="tertiary"
        size="m"
        theme="dark"
        onClick={decrease}
      >
        <FiMinus />
      </IconButton>

      <Input
        disabled={disabled}
        value={numericValue}
        placeholder={placeholder}
        size="s"
        theme="dark"
        variant="subtle"
        onChange={(event) => {
          const newValue = parseAttributeValue(event.target.value.trim());
          handleChange(Number(newValue.value));
        }}
        className={styles['input-incremental']}
      />

      <IconButton
        disabled={disabled}
        hierarchy="tertiary"
        size="m"
        theme="dark"
        onClick={increase}
      >
        <FiPlus />
      </IconButton>
    </div>
  );
}

InputIncremental.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

InputIncremental.defaultProps = {
  placeholder: '',
  disabled: false,
};

export default InputIncremental;
