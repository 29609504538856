import PropTypes from 'prop-types';

import { useUserQuery } from 'hooks/api/users';

function BillingPlanSeats({ teamId }) {
  const { data: user } = useUserQuery();

  const subscriptions = user.team_subscriptions;
  const currentSubscription = subscriptions.find((sub) => sub.team === teamId);

  return currentSubscription?.seats || 'not available';
}

BillingPlanSeats.propTypes = {
  teamId: PropTypes.number.isRequired,
};

export default BillingPlanSeats;
