import PropTypes from 'prop-types';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import Spinner from '@scalero-inc/forno-spinner';
import { toast } from 'react-toastify';
import FallbackStackDesigner from 'components/fallback-stack-designer';
import { Form, Formik } from 'formik';
import { WEB_SAFE_FONTS } from 'components/fallback-stack-designer/utils';
import {
  useFontsQuery,
  useTeamFontStackCreateMutation,
  useTeamFontStacksInvalidateQuery,
  useUserFontStackCreateMutation,
  useUserFontStacksInvalidateQuery,
} from 'hooks/api/fonts';

import styles from './styles.module.css';
import schema from './schema';

function FallbackFontModal({ close, isOpen, font, teamId, onFallbackCreated }) {
  const { baseFontId } = font;
  const defaultStackName = `${font.family} stack`;
  const initialValues = {
    fallback: {
      name: defaultStackName,
      stack: [{ family: WEB_SAFE_FONTS[0].family }],
    },
  };

  const userFontsInvalidate = useUserFontStacksInvalidateQuery();
  const teamFontsInvalidate = useTeamFontStacksInvalidateQuery();
  const fontStacksInvalidate = teamId
    ? teamFontsInvalidate
    : userFontsInvalidate;

  const config = {
    onSuccess: (data) => {
      const fallback = data.stack.map((element) => element.family);
      onFallbackCreated(fallback.join(', '));

      close();
      fontStacksInvalidate();
      toast.success(`Fallback stack created successfully`);
    },
    onError: () => {
      toast.error(`Failed to create the fallback stack, try again later`);
    },
  };
  const userStackMutation = useUserFontStackCreateMutation({ config });
  const teamStackMutation = useTeamFontStackCreateMutation({ teamId, config });
  const createStackMutation = teamId ? teamStackMutation : userStackMutation;

  const { data, isLoading, isFetching, error } = useFontsQuery({
    family: font.family,
    config: {
      enabled: !baseFontId,
    },
  });

  const fontDetail = data?.results.find(
    (element) => element.family === font.family
  );
  const fontId = fontDetail?.id || baseFontId;

  const saveFallback = ({ fallback }) => {
    createStackMutation.mutate({
      font: fontId,
      name: fallback.name,
      stack: fallback.stack,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="regular modal"
      theme="dark"
    >
      <Modal.Header onDismiss={close} theme="dark" title="New fallback stack" />

      <div>
        {(isLoading || isFetching) && (
          <Modal.Content>
            <Spinner />
          </Modal.Content>
        )}

        {!isLoading && !isFetching && error && (
          <>
            <Modal.Content>
              <p>Unexpected error occurred, please try again later.</p>
            </Modal.Content>
            <Modal.Footer>
              <Button onClick={close} theme="dark" hierarchy="tertiary">
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {!isLoading && !isFetching && !error && !fontId && (
          <>
            <Modal.Content>
              <p>
                Base font ({font.family}) not found, please try with a different
                font family.
              </p>
            </Modal.Content>
            <Modal.Footer>
              <Button onClick={close} theme="dark" hierarchy="tertiary">
                Close
              </Button>
            </Modal.Footer>
          </>
        )}

        {!isLoading && !isFetching && !error && fontId && (
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={saveFallback}
          >
            {({ isValid, values }) => (
              <Form>
                <Modal.Content>
                  <div className={styles['fallback-container']}>
                    <div className={styles['font-name']}>
                      <span>Base Font Name</span>
                      <strong>{font.family}</strong>
                    </div>

                    <FallbackStackDesigner
                      id="fallback"
                      fallback={values.fallback}
                      theme="dark"
                    />
                  </div>
                </Modal.Content>
                <Modal.Footer>
                  <Button onClick={close} theme="dark" hierarchy="tertiary">
                    Cancel
                  </Button>
                  <Button theme="dark" type="submit" disabled={!isValid}>
                    Create fallback stack
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
}

FallbackFontModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  font: PropTypes.shape({
    baseFontId: PropTypes.number,
    family: PropTypes.string,
  }).isRequired,
  teamId: PropTypes.number,
  onFallbackCreated: PropTypes.func,
};

FallbackFontModal.defaultProps = {
  teamId: null,
  onFallbackCreated: () => {},
};

export default FallbackFontModal;
