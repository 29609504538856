export async function initRegenCompareMutation({ client, fileId, values }) {
  const { data } = await client.post(
    `figma/files/${fileId}/regen_compare/`,
    values
  );
  return data;
}

export async function saveRegenCompareMutation({
  client,
  fileId,
  values,
  regenId,
}) {
  const { data } = await client.put(
    `figma/files/${fileId}/regen_compare/${regenId}/`,
    values
  );
  return data;
}

export async function closeRegenCompareMutation({ client, fileId, regenId }) {
  const { data } = await client.post(
    `figma/files/${fileId}/regen_compare/${regenId}/close/`
  );
  return data;
}

export async function refreshRegenCompareMutation({ client, fileId, regenId }) {
  const { data } = await client.post(
    `figma/files/${fileId}/regen_compare/${regenId}/refresh/`
  );
  return data;
}
