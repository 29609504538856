import React, { useContext, createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { editorOptions } from 'utils/editor';
import { viewCodeOptions } from './constants';

const EditorViewStateContext = createContext();
const EditorViewDispatchContext = createContext();

export const initialViewColumns = '300px 16px 1fr 16px 340px';
const initialCodeView = viewCodeOptions[0].value;
const initialPreviewView = 'iframe';
export const initialViewPanel = {
  name: editorOptions.visualEditor.value,
  title: editorOptions.visualEditor.title,
};

function EditorViewProvider({ children }) {
  const [viewColumns, setViewColumns] = useState(initialViewColumns);
  const [viewCode, setViewCode] = useState(initialCodeView);
  const [viewPreviewSize, setViewPreviewSize] = useState();
  const [viewPreview, setViewPreview] = useState(initialPreviewView);
  const [viewPanel, setViewPanel] = useState(initialViewPanel);

  const editorViewState = useMemo(() => {
    return {
      viewColumns,
      viewCode,
      viewPreview,
      viewPreviewSize,
      viewPanel,
    };
  }, [viewColumns, viewCode, viewPreview, viewPreviewSize, viewPanel]);

  const editorViewDispatch = useMemo(() => {
    return {
      setViewColumns,
      setViewCode,
      setViewPreview,
      setViewPreviewSize,
      setViewPanel,
    };
  }, [
    setViewColumns,
    setViewCode,
    setViewPreview,
    setViewPreviewSize,
    setViewPanel,
  ]);

  return (
    <EditorViewStateContext.Provider value={editorViewState}>
      <EditorViewDispatchContext.Provider value={editorViewDispatch}>
        {children}
      </EditorViewDispatchContext.Provider>
    </EditorViewStateContext.Provider>
  );
}

EditorViewProvider.propTypes = {
  children: PropTypes.node,
};

EditorViewProvider.defaultProps = {
  children: null,
};

function useEditorView() {
  const context = useContext(EditorViewStateContext);

  if (context === undefined) {
    throw new Error('Editor State must be used within a EditorViewProvider');
  }

  return context;
}

function useEditorViewDispatch() {
  const dispatch = useContext(EditorViewDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      'useEditorDispatch must be used within a EditorViewProvider'
    );
  }

  return dispatch;
}

export { EditorViewProvider, useEditorView, useEditorViewDispatch };
