import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';

import { useCompleteSubscription } from 'hooks/api/subscriptions';
import { useUserQuery } from 'hooks/api/users';

import { canCreateEditDeleteTeamSubscription } from 'utils/permissions';

import { FiAlertTriangle } from 'react-icons/fi';
import styles from './style.module.css';

function PendingPaymentAlert({ subscriptionId }) {
  const { mutate: completeSubscription } = useCompleteSubscription({
    subscriptionId,
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      onError: () =>
        toast.error(
          'There was an error with your request, please contact support'
        ),
    },
  });
  const { data: user } = useUserQuery();
  const teamSubscription = user.team_subscriptions?.find(
    (sub) => sub.id === subscriptionId
  );
  const teamId = teamSubscription?.team;

  const blocked = teamSubscription
    ? !canCreateEditDeleteTeamSubscription({ user, teamId })
    : false;

  return (
    <div className={styles['subscription-alert-banner-content']}>
      <FiAlertTriangle />
      <p>Finish setting up your payment to access all features</p>
      <Button
        size="s"
        hierarchy="tertiary"
        onClick={() => completeSubscription()}
        disabled={blocked}
      >
        Finish now
      </Button>
    </div>
  );
}

PendingPaymentAlert.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
};

export default PendingPaymentAlert;
