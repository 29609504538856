import PropTypes from 'prop-types';
import { FiExternalLink } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Field, Formik, Form } from 'formik';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';

import { useFileSendTestEmailMutation } from 'hooks/api/files/index';

import schema from './schema';
import styles from './style.module.css';

function FileSendTestModal({ close, isOpen, fileId }) {
  const { mutate, isLoading } = useFileSendTestEmailMutation({
    fileId,
    config: {
      onSuccess: () => {
        toast.success('Email test sent successfully');
      },
      onError: () => {
        toast.error('There was an error sending your test email');
      },
    },
  });

  const sendTestEmail = (values) => {
    mutate(values.email);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="send test email modal"
      theme="dark"
      dangerouslyBypassFocusLock
    >
      <Modal.Header
        title="Send test email"
        onDismiss={close}
        icon={FiExternalLink}
        theme="dark"
      />
      <Formik
        initialValues={{ email: '' }}
        validationSchema={schema}
        onSubmit={sendTestEmail}
      >
        {({ isValid }) => (
          <Form className={styles['form-wrapper']}>
            <Field id="email" name="email">
              {({ field }) => (
                <Input
                  {...field}
                  label="Email recipient"
                  placeholder="Email recipient"
                  size="s"
                  theme="dark"
                />
              )}
            </Field>
            <hr className={styles['form-divider']} />
            <Button
              type="submit"
              disabled={!isValid || isLoading}
              loading={isLoading}
              theme="dark"
            >
              Send
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

FileSendTestModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  fileId: PropTypes.string.isRequired,
};

export default FileSendTestModal;
