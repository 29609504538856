import React from 'react';
import Button from '@scalero-inc/forno-button';
import HeaderPublic from 'components/header-public';
import { useAuth } from 'context/auth';
import cannoli from 'images/connect/cannoli.png';
import { isScalero } from 'utils/permissions';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

function ConnectAccount() {
  const { loginWithFigma } = useAuth();

  return (
    <>
      <HeaderPublic showLogin={false} />
      <main className={styles['connect-page']}>
        <section className={styles['connect-page-container']}>
          <img src={cannoli} width={250} alt="Cannoli" />
          <h1>
            We need to connect your Cannoli account with your Figma account
          </h1>

          <Button className="primary-button" size="l" onClick={loginWithFigma}>
            Connect accounts
          </Button>

          <div>
            Don’t have an account? <a href="/login">Sign up</a>
          </div>
          <div>
            Creating an account means you agree to our Privacy Policy and Terms
            of Service.
          </div>
        </section>
      </main>
    </>
  );
}

function PairedAccount() {
  const {
    user: { user },
  } = useAuth();
  const { email, subscriptions } = user;
  const navigate = useNavigate();
  const timerRef = React.useRef(null);

  React.useEffect(() => {
    // Redirect to billing if the user does not have a subscription
    if (!subscriptions && !isScalero({ user })) {
      // Delay to display the success message before the redirect
      timerRef.current = setTimeout(() => {
        navigate('/dashboard/settings/billing');
      }, 3000);
    }

    return () => clearTimeout(timerRef.current);
  }, [navigate, subscriptions, user]);

  const openFigmaApp = () => {
    window.location.href = 'figma://';
  };

  return (
    <>
      <HeaderPublic showLogin={false} />
      <main className={styles['connect-page']}>
        <section className={styles['paired-page-container']}>
          <img src={cannoli} width={250} alt="Cannoli" />
          <h1>
            We successfully paired <span>{email}</span> with Figma! <br /> Now
            you can sync your frames.
          </h1>

          <Button className="primary-button" size="l" onClick={openFigmaApp}>
            Back to Cannoli Plug-in
          </Button>

          <div>
            Creating an account means you agree to our Privacy Policy and Terms
            of Service.
          </div>
        </section>
      </main>
    </>
  );
}

export { ConnectAccount, PairedAccount };
