import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import IconButton from '@scalero-inc/forno-icon-button';
import Spinner from '@scalero-inc/forno-spinner';
import Button from '@scalero-inc/forno-button';
import GoogleFontsFilter from 'components/google-fonts-filter';
import {
  useTeamFontStacksQuery,
  useUserFontStacksQuery,
} from 'hooks/api/fonts';

import styles from './styles.module.css';

function GoogleFontsTable({
  teamId,
  googleFonts,
  onAddFont,
  onAddFallback,
  onDeleteFont,
  theme,
}) {
  const {
    data: userFonts,
    isLoading: isUserFontsLoading,
    isFetching: isUserFontsFetching,
    isError: isUserFontsError,
  } = useUserFontStacksQuery({
    config: {
      enabled: !teamId,
    },
  });

  const {
    data: teamFonts,
    isLoading: isTeamFontsLoading,
    isFetching: isTeamFontsFetching,
    isError: isTeamFontsError,
  } = useTeamFontStacksQuery({
    teamId,
    config: {
      enabled: !!teamId,
    },
  });

  const data = teamId ? teamFonts : userFonts;
  const isLoading = teamId ? isTeamFontsLoading : isUserFontsLoading;
  const isFetching = teamId ? isTeamFontsFetching : isUserFontsFetching;
  const isError = teamId ? isTeamFontsError : isUserFontsError;
  const googleFontsList = useMemo(() => {
    if (!data?.results) {
      return googleFonts;
    }

    return googleFonts.map((font) => {
      const fallback = data?.results.filter(
        (stack) => stack.font_family === font.family
      );
      return { ...font, fallback };
    });
  }, [data?.results, googleFonts]);

  const addFont = useCallback(
    (font) => {
      const hasFallback =
        data?.results &&
        data?.results.find((stack) => stack.font_family === font.family);
      onAddFont(hasFallback, font);
    },
    [data?.results, onAddFont]
  );

  if (isLoading || isFetching) {
    <Spinner />;
  }

  if (isError) {
    return (
      <div className={styles['error-message']}>
        Can not load your font stacks, try again later
      </div>
    );
  }

  return (
    <>
      <GoogleFontsFilter onSelectFont={addFont} theme={theme} />
      <div className={styles['fonts-container']}>
        <table className={styles['fonts-table']}>
          <tbody>
            <tr>
              <th>Font family</th>
              <th className={styles['font-weight']}>Weights</th>
              <th>Fallback name</th>
              <th>Fallback stack</th>
              <th />
            </tr>

            {googleFontsList.length === 0 && (
              <tr>
                <td colSpan={5} className={styles.center}>
                  There are no Google fonts loaded in your file.
                </td>
              </tr>
            )}
            {googleFontsList.map((font, fontIndex) => {
              return (
                <tr key={`google_font_${fontIndex}`}>
                  <td>{font.family}</td>
                  <td>{font.weight}</td>
                  <td>
                    {!font?.fallback || font.fallback.length === 0 ? (
                      '- -'
                    ) : (
                      <>
                        {font.fallback.map((fallback, fallbackIndex) => (
                          <div key={`fallback_${fontIndex}_${fallbackIndex}`}>
                            {fallback.name}
                          </div>
                        ))}
                      </>
                    )}
                  </td>
                  <td>
                    {!font?.fallback || font.fallback.length === 0 ? (
                      <Button
                        size="s"
                        theme={theme}
                        hierarchy="secondary"
                        onClick={() => onAddFallback(font)}
                      >
                        Add fallback
                      </Button>
                    ) : (
                      <>
                        {font.fallback.map((fallback, fallbackIndex) => (
                          <div key={`fallback_${fontIndex}_${fallbackIndex}`}>
                            {fallback.stack
                              .map((element) => element.family)
                              .join(', ')}
                          </div>
                        ))}
                      </>
                    )}
                  </td>
                  <td>
                    <IconButton
                      theme={theme}
                      hierarchy="tertiary"
                      onClick={() => onDeleteFont(font)}
                    >
                      <FiTrash2 />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

GoogleFontsTable.propTypes = {
  teamId: PropTypes.number,
  googleFonts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddFont: PropTypes.func.isRequired,
  onAddFallback: PropTypes.func.isRequired,
  onDeleteFont: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
};

GoogleFontsTable.defaultProps = {
  teamId: null,
  theme: 'light',
};

export default GoogleFontsTable;
