export async function templateCreateMutation({
  client,
  values,
  teamId,
  projectId,
}) {
  const { data } = await client.post(
    teamId
      ? `/teams/${teamId}/projects/${projectId}/templates/`
      : `/templates/`,
    {
      ...values,
    }
  );
  return data;
}

export async function templateComponentCreateMutation({
  client,
  values,
  teamId,
  projectId,
}) {
  const { templateId } = values;
  const { data } = await client.post(
    teamId
      ? `/teams/${teamId}/projects/${projectId}/templates/${templateId}/component_templates/`
      : `/templates/${templateId}/component_templates/`,
    {
      ...values,
    }
  );
  return data;
}

export async function templateUpdateMutation({
  client,
  teamId,
  projectId,
  templateId,
  values,
}) {
  const { data } = await client.patch(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}/`
      : `templates/${templateId}/`,
    values
  );
  return data;
}

export async function templateComponentUpdateMutation({
  client,
  teamId,
  projectId,
  templateId,
  values,
}) {
  const { templateComponentId, queryValues, position } = values;
  const { data } = await client.patch(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}/component_templates/${templateComponentId}/`
      : `templates/${templateId}/component_templates/${templateComponentId}/`,
    {
      ...queryValues,
      position,
    }
  );
  return data;
}

export async function templateDeleteMutation({
  client,
  teamId,
  projectId,
  templateId,
}) {
  const { data } = await client.delete(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}`
      : `templates/${templateId}/`
  );
  return data;
}

export async function templateComponentDeleteMutation({
  client,
  teamId,
  projectId,
  templateId,
  values,
}) {
  const { templateComponentId } = values;
  const { data } = await client.delete(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}/component_templates/${templateComponentId}/`
      : `templates/${templateId}/component_templates/${templateComponentId}/`
  );
  return data;
}
