import React, { useContext, createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

import { getStateFromMjml } from './functions';
import { ReadOnlyViewProvider } from '../read-only-view-context';

const ReadOnlyStateContext = createContext();
const ReadOnlyDispatchContext = createContext();

const SET_MJML = 'SET_MJML';

function editorReducer(state, action) {
  switch (action.type) {
    case SET_MJML:
      return {
        ...state,
        ...getStateFromMjml(action.payload.mjml),
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ReadOnlyProvider({ mjml, name, children }) {
  const mjmlState = useMemo(() => {
    return getStateFromMjml(mjml);
  }, [mjml]);

  const [state, dispatch] = useReducer(editorReducer, {
    ...mjmlState,
    name,
  });

  return (
    <ReadOnlyStateContext.Provider value={state}>
      <ReadOnlyDispatchContext.Provider value={dispatch}>
        <ReadOnlyViewProvider>{children}</ReadOnlyViewProvider>
      </ReadOnlyDispatchContext.Provider>
    </ReadOnlyStateContext.Provider>
  );
}

ReadOnlyProvider.propTypes = {
  mjml: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
};

ReadOnlyProvider.defaultProps = {
  mjml: '',
  children: null,
  name: '',
};

function useReadOnly() {
  const context = useContext(ReadOnlyStateContext);

  if (context === undefined) {
    throw new Error('ReadOnly State must be used within a ReadOnlyProvider');
  }

  return context;
}

function useReadOnlyDispatch() {
  const dispatch = useContext(ReadOnlyDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      'useReadOnlyDispatch must be used within a ReadOnlyProvider'
    );
  }

  return {
    dispatch,
  };
}

export { ReadOnlyProvider, useReadOnly, useReadOnlyDispatch };
