import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@scalero-inc/forno-modal';
import AddBlockToSections from './add-block-to-sections';
import CreateCustomBlock from './create-custom-block';

export default function BlocksModal({ newBlock, isOpen, close }) {
  const [createIsOpen, setCreateIsOpen] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => {
        setCreateIsOpen(false);
        close();
      }}
      theme="dark"
      aria-label="add custom block modal"
    >
      <Modal.Header
        theme="dark"
        title={createIsOpen ? 'New Custom Block' : 'Custom Blocks'}
        onDismiss={close}
      />
      <Modal.Content>
        {newBlock || createIsOpen ? (
          <CreateCustomBlock close={close} setCreateIsOpen={setCreateIsOpen} />
        ) : (
          <AddBlockToSections setCreateIsOpen={setCreateIsOpen} />
        )}
      </Modal.Content>
    </Modal>
  );
}

BlocksModal.propTypes = {
  newBlock: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

BlocksModal.defaultProps = {
  newBlock: false,
};
