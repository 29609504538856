import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';
import { FaPlay } from 'react-icons/fa';

import styles from './styles.module.css';

function VideoPlayer({ src, poster, controlBar }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const newProgress =
      (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(newProgress);
  };

  const handleProgressInput = (e) => {
    const selectedTime = (e.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = selectedTime;
  };

  return (
    <div className={styles.videoWrapper}>
      <video
        width="100%"
        ref={videoRef}
        onTimeUpdate={handleTimeUpdate}
        onClick={handlePlayPause}
        poster={poster}
      >
        <source src={src} type="video/mp4" />
        <track default kind="captions" srcLang="en" />
        Sorry, your browser does not support embedded videos.
      </video>

      <div className={styles.playButtonWrapper}>
        {!isPlaying && (
          <button
            className={styles.playButton}
            onClick={handlePlayPause}
            type="button"
          >
            <FaPlay size={40} className={styles.playIconOffset} />
          </button>
        )}
      </div>

      {controlBar && (
        <div className={styles.videoControls}>
          <input
            type="range"
            min="0"
            max="100"
            step="1"
            value={progress}
            onChange={handleProgressInput}
            className={styles.rangeInput}
          />
        </div>
      )}
    </div>
  );
}

VideoPlayer.propTypes = {
  src: propTypes.string.isRequired,
  poster: propTypes.string.isRequired,
  controlBar: propTypes.bool,
};

VideoPlayer.defaultProps = {
  controlBar: false,
};

export default VideoPlayer;
