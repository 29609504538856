import * as yup from 'yup';

export default yup.object().shape({
  name: yup.string().required(),
  seats: yup
    .number()
    .integer()
    .min(2, 'Your team must have at least 2 seats')
    .required(),
});
