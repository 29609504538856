import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';

import styles from './styles.module.css';

function ErrorMessage({ content }) {
  return (
    <div data-theme="dark" className={styles.container}>
      <FiAlertTriangle />
      <span>{content}</span>
    </div>
  );
}

ErrorMessage.propTypes = {
  content: PropTypes.string.isRequired,
};

ErrorMessage.defaultProps = {};

export default ErrorMessage;
