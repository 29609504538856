import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.css';

const classPrefix = 'forno-resize-gutter';

function ResizeGutter({ className, ...rest }) {
  const customClassName = classNames(classPrefix, className);
  return <div className={customClassName} {...rest} />;
}

ResizeGutter.propTypes = {
  className: PropTypes.string,
};

ResizeGutter.defaultProps = {
  className: null,
};

export default ResizeGutter;
