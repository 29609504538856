import { useFetch } from 'context/fetch';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  FONTS_QUERY_KEY,
  FONT_VARIANTS_KEY,
  TEAM_FONT_STACKS_KEY,
  USER_FONT_STACKS_KEY,
  WEB_SAFE_FONTS_KEY,
} from '../constants';
import {
  fontVariantUodateMutation,
  teamFontStackCreateMutation,
  teamFontStackDeleteMutation,
  teamFontStackUpdateMutation,
  userFontStackCreateMutation,
  userFontStackDeleteMutation,
  userFontStackUpdateMutation,
} from './mutations';
import {
  fontsQuery,
  teamFontStacksQuery,
  userFontStacksQuery,
  fontVariantsQuery,
  webSafeFontsQuery,
} from './queries';

export function useFontsQuery(props = {}) {
  const { config, family, key = FONTS_QUERY_KEY } = props;
  const { client } = useFetch();

  return useQuery([key, family], () => fontsQuery({ client, family }), config);
}

export function useUserFontStacksQuery(props = {}) {
  const { config, key = USER_FONT_STACKS_KEY } = props;
  const { client } = useFetch();

  return useQuery([key], () => userFontStacksQuery({ client }), config);
}

export function useTeamFontStacksQuery(props = {}) {
  const { config, teamId, key = TEAM_FONT_STACKS_KEY } = props;
  const { client } = useFetch();

  return useQuery([key], () => teamFontStacksQuery({ client, teamId }), config);
}

export function useFontVariantsQuery(props = {}) {
  const { config, teamId, key = FONT_VARIANTS_KEY } = props;
  const { client } = useFetch();

  return useQuery([key], () => fontVariantsQuery({ client, teamId }), config);
}

export function useWebSafeFontsQuery(props = {}) {
  const { config, key = WEB_SAFE_FONTS_KEY } = props;
  const { client } = useFetch();

  return useQuery([key], () => webSafeFontsQuery({ client }), config);
}

export function useFontVariantsInvalidateQuery(props = {}) {
  const { key = FONT_VARIANTS_KEY } = props;
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useUserFontStacksInvalidateQuery(props = {}) {
  const { key = USER_FONT_STACKS_KEY } = props;
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useTeamFontStacksInvalidateQuery(props = {}) {
  const { key = TEAM_FONT_STACKS_KEY } = props;
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useUserFontStackCreateMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => userFontStackCreateMutation({ client, values }),
    config
  );
}

export function useTeamFontStackCreateMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => teamFontStackCreateMutation({ client, teamId, values }),
    config
  );
}

export function useUserFontStackUpdateMutation(props = {}) {
  const { config, fontStackId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => userFontStackUpdateMutation({ client, fontStackId, values }),
    config
  );
}

export function useTeamFontStackUpdateMutation(props = {}) {
  const { config, teamId, fontStackId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) =>
      teamFontStackUpdateMutation({ client, teamId, fontStackId, values }),
    config
  );
}

export function useUserFontStackDeleteMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation(
    (fontStackId) => userFontStackDeleteMutation({ client, fontStackId }),
    config
  );
}

export function useTeamFontStackDeleteMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(
    (fontStackId) =>
      teamFontStackDeleteMutation({ client, teamId, fontStackId }),
    config
  );
}

export function useFontVariantUpdateMutation(props = {}) {
  const { config, fontVariantId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => fontVariantUodateMutation({ client, fontVariantId, values }),
    config
  );
}
