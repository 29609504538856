import React from 'react';
import propTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

// Higher order component to prevent Droppable from rendering before the
// animation frame has been requested. This prevents an error in the
// react-beautiful-dnd library because the strict mode is enabled.

function StrictModeDroppable({ children, ...props }) {
  const [enabled, setEnabled] = React.useState(false);

  React.useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
}

StrictModeDroppable.propTypes = {
  children: propTypes.func.isRequired,
};

export default StrictModeDroppable;
