import { Splide, SplideSlide } from '@splidejs/react-splide';
import FeatureBlock from './feature-block';

import { features } from './data';

import '@splidejs/splide/dist/css/splide.min.css';
import styles from './style.module.css';

function FeaturesSection() {
  return (
    <section className={styles['features-section']}>
      <div className={styles['features-container']}>
        <h2>Features</h2>

        <div className={styles['features-grid-desktop']}>
          {features.map((feature, index) => {
            return (
              <FeatureBlock
                key={`feature_desktop_${index}`}
                image={feature.image.src}
                alt={feature.image.alt}
                title={feature.title}
              >
                {feature.description}
              </FeatureBlock>
            );
          })}
        </div>

        <div className={styles['features-grid-mobile']}>
          <Splide
            options={{
              arrows: false,
            }}
          >
            {features.map((feature, index) => {
              return (
                <SplideSlide key={`feature_mobile_${index}`}>
                  <FeatureBlock
                    image={feature.image.src}
                    alt={feature.image.alt}
                    title={feature.title}
                  >
                    {feature.description}
                  </FeatureBlock>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
