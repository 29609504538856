// import 'ace-builds/src-noconflict/mode-xml';

// export default class MjmlMode extends window.ace.acequire('ace/mode/xml').Mode {
//   constructor() {
//     super();
//   }
// }

export const mjmlTags = [
  {
    value: 'sc-img',
    meta: 'Scalero image',
  },
  {
    value: 'sc-link',
    meta: 'Scalero Link',
  },
  {
    value: 'mj-accordion',
  },
  {
    value: 'mj-accordion-element',
  },
  {
    value: 'mj-accordion-title',
  },
  {
    value: 'mj-accordion-text',
  },
  {
    value: 'mj-button',
  },
  {
    value: 'mj-carousel',
  },
  {
    value: 'mj-carousel-image',
  },
  {
    value: 'mj-class',
  },
  {
    value: 'mj-column',
  },
  {
    value: 'mj-divider',
  },
  {
    value: 'mj-group',
  },
  {
    value: 'mj-font',
  },
  {
    value: 'mj-hero',
  },
  {
    value: 'mj-image',
  },
  {
    value: 'mj-invoice',
  },
  {
    value: 'mj-invoice-item',
  },
  {
    value: 'mj-list',
  },
  {
    value: 'mj-location',
  },
  {
    value: 'mj-navbar',
  },
  {
    value: 'mj-navbar-link',
  },
  {
    value: 'mj-section',
  },
  {
    value: 'mj-social',
  },
  {
    value: 'mj-social-element',
  },
  {
    value: 'mj-spacer',
  },
  {
    value: 'mj-table',
  },
  {
    value: 'mj-text',
  },
  {
    value: 'mj-wrapper',
  },
];
