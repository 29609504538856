import { getBorderRadiusAttributes } from '../border';
import { getPadding, getWidth } from '../bounding-box';
import { getName, getSource } from '../image';
import { getAlignment } from '../utils';
import messages from '../messages';

function getErrors(node) {
  const errors = [];
  errors.push(messages.warning(messages.MSG.URL_LINK, node));
  errors.push(messages.warning(messages.MSG.ALTERNATE_TEXT, node));

  return errors;
}

function MjImage({
  parent,
  attributes,
  fills,
  images,
  nodeImages,
  rectangleCornerRadii,
  tagName,
  ...rest
}) {
  const width = getWidth(rest);
  const image = getSource(fills, images, nodeImages, rest);
  const alt = getName(rest, 'IMAGE');
  const cssClass = { 'css-class': 'image' };
  const alignment = getAlignment(parent);
  const padding = getPadding(parent, rest, tagName);
  const errorList = getErrors(rest);

  return [
    {
      tagName: 'mj-image',
      errors: errorList,
      children: [],
      attributes: {
        ...attributes,
        ...alt,
        ...cssClass,
        align: alignment,
        href: '#',
        width: `${width}px`,
        ...(padding && padding),
        ...getBorderRadiusAttributes({ rectangleCornerRadii }),
        ...(image && image),
      },
    },
  ];
}

export default MjImage;
