/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import json2mjml from 'json2mjml';
import { useEditor, useEditorDispatch } from 'pages/editor/editor-context';
import useDebounce from 'hooks/utils/use-debounce';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ATTRIB_DESCRIPTION } from 'utils/mjml';
import ImagePreview from 'components/image-preview';
import InputText from './input-text';
import InputTextarea from './input-textarea';
import InputUrl from './input-url';
import InputSize from './input-size';
import InputShorthand from './input-shorthand';
import WidgetBorder from './widget-border';

import styles from './style.module.css';

function DynamicInput({ nodeId, attribute, value, mjmlNodeRef }) {
  const attribDesc = ATTRIB_DESCRIPTION[attribute];
  const type = attribDesc?.type;
  const label = attribDesc?.label || attribute;
  const placeholder = attribDesc?.placeholder || '';
  const validator = attribDesc?.validator;

  const { teamId, fileId, componentId, json } = useEditor();
  const { editor } = useEditorDispatch(teamId, fileId, componentId);

  const update = (attribValue) => {
    // Update attributes by reference
    if (attribute === 'content') {
      mjmlNodeRef.content = attribValue;
    } else if (type === 'shorthand') {
      // Remove individual declarations (padding-top, padding-right, etc) then use the shorthand version
      delete mjmlNodeRef.attributes[`${attribute}-top`];
      delete mjmlNodeRef.attributes[`${attribute}-right`];
      delete mjmlNodeRef.attributes[`${attribute}-bottom`];
      delete mjmlNodeRef.attributes[`${attribute}-left`];
      mjmlNodeRef.attributes[attribute] = attribValue;
    } else if (type === 'border') {
      const borderEntries = Object.entries(attribValue);
      borderEntries.forEach(([key, val]) => {
        if (val.trim() === '' || val.includes('none')) {
          delete mjmlNodeRef.attributes[key];
        } else {
          mjmlNodeRef.attributes[key] = val;
        }
      });
    } else if (attribValue.trim() === '') {
      delete mjmlNodeRef.attributes[attribute];
    } else {
      mjmlNodeRef.attributes[attribute] = attribValue;
    }

    // Regenerate mjml code from json and save it
    const mjml = json2mjml(json);
    editor.save(mjml);
  };
  const debouncedOnUpdate = useDebounce(update, 300);

  if (type === 'border') {
    return (
      <WidgetBorder
        defaultValue={value}
        attributes={{
          top: mjmlNodeRef.attributes[`${attribute}-top`],
          right: mjmlNodeRef.attributes[`${attribute}-right`],
          bottom: mjmlNodeRef.attributes[`${attribute}-bottom`],
          left: mjmlNodeRef.attributes[`${attribute}-left`],
        }}
        handleChange={debouncedOnUpdate}
      />
    );
  }

  return (
    <Formik
      initialValues={{ attribInfo: value }}
      validationSchema={Yup.object().shape({
        attribInfo: validator,
      })}
    >
      {({ setFieldValue, isValid }) => {
        const onValueChange = (attribValue) => {
          setFieldValue('attribInfo', attribValue);
          debouncedOnUpdate(attribValue);
        };

        return (
          <Form>
            <Field id="attribInfo" name="attribInfo">
              {({ field }) => (
                <div key={attribute} className={styles['input-item-container']}>
                  <div className={styles['input-item']}>
                    <div>
                      {type === 'text' && (
                        <InputText
                          label={label}
                          field={field}
                          placeholder={placeholder}
                          handleChange={onValueChange}
                        />
                      )}

                      {type === 'textarea' && (
                        <InputTextarea
                          label={label}
                          field={field}
                          placeholder={placeholder}
                          handleChange={onValueChange}
                        />
                      )}

                      {attribute === 'src' && (
                        <ImagePreview id={nodeId} src={value} />
                      )}

                      {type === 'url' && (
                        <InputUrl
                          label={label}
                          field={field}
                          isValid={isValid}
                          placeholder={placeholder}
                          handleChange={onValueChange}
                          attribute={attribute}
                        />
                      )}

                      {type === 'size' && (
                        <InputSize
                          label={label}
                          field={field}
                          tagName={mjmlNodeRef.tagName}
                          attribName={attribute}
                          placeholder={placeholder}
                          handleChange={onValueChange}
                        />
                      )}

                      {type === 'shorthand' && (
                        <InputShorthand
                          label={label}
                          attribName={attribute}
                          defaultValue={field.value}
                          attributes={{
                            top: mjmlNodeRef.attributes[`${attribute}-top`],
                            right: mjmlNodeRef.attributes[`${attribute}-right`],
                            bottom:
                              mjmlNodeRef.attributes[`${attribute}-bottom`],
                            left: mjmlNodeRef.attributes[`${attribute}-left`],
                          }}
                          type="size"
                          handleChange={onValueChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
}

DynamicInput.propTypes = {
  nodeId: PropTypes.string.isRequired,
  attribute: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  mjmlNodeRef: PropTypes.shape({
    tagName: PropTypes.string,
    content: PropTypes.string,
    attributes: PropTypes.shape(),
  }).isRequired,
};

DynamicInput.defaultProps = {};

export default DynamicInput;
