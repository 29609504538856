export const TEXT_ATTRIBUTES = [
  'color',
  'font-family',
  'font-size',
  'font-style',
  'font-weight',
  'line-height',
  'letter-spacing',
  'height',
  'text-decoration',
  'text-transform',
  'align',
  'container-background-color',
  'padding',
  'padding-top',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'css-class',
];
