import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useCallback, useState } from 'react';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import styles from './style.module.css';

function getColor(type) {
  switch (type) {
    case 'WARNING':
      return 'yellow';
    case 'ERROR':
      return 'red';
    case 'INFO':
      return 'blue';
    default:
      return 'blue';
  }
}

function FileEditorDebugErrors({ errors }) {
  const [isOpen, setIsOpen] = useState();
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <>
      <Button onClick={open}>Show errors</Button>
      <Modal
        isOpen={isOpen}
        onDismiss={close}
        aria-label="parsing errors modal"
      >
        <Modal.Header title="Parsing errors" onDismiss={close} />
        <div className={styles['file-parsing-messages-wrapper']}>
          {errors.map((error, i) => {
            const className = classnames(styles['file-parsing-message']);
            const style = {
              borderLeftColor: getColor(error.type),
            };
            return (
              <article key={i} className={className} style={style}>
                <div>
                  <p>ID: {error.id}</p>
                  <p>Name: {error.name}</p>
                </div>
                <footer>
                  <p>{error.message}</p>
                </footer>
              </article>
            );
          })}
        </div>
        <Modal.Footer>
          <Button hierarchy="tertiary" onClick={close}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

FileEditorDebugErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({})),
};

FileEditorDebugErrors.defaultProps = {
  errors: [],
};

export default FileEditorDebugErrors;
