import { useNavigate } from 'react-router-dom';
import Button from '@scalero-inc/forno-button';

import styles from './styles.module.css';

function HelpSection() {
  const navigate = useNavigate();

  return (
    <section className={styles['help-section']}>
      <div className={styles['help-section-container']}>
        <h2>Need help? We can take it from here.</h2>
        <Button
          hierarchy="primary"
          size="l"
          onClick={() => {
            navigate('/services');
          }}
        >
          Explore Cannoli Services
        </Button>
      </div>
    </section>
  );
}

export default HelpSection;
