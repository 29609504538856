import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './style.module.css';

function CompactCard({
  className,
  href,
  description,
  title,
  image,
  icon: Icon,
}) {
  const customClassName = classnames(styles['compact-card'], className, {});
  return (
    <a className={customClassName} href={href} target="_blank" rel="noreferrer">
      <aside className={styles['compact-card-thumbnail']}>
        {image ? <img src={image} alt={title} /> : null}
        {Icon ? <Icon /> : null}
      </aside>
      <div className={styles['compact-card-content']}>
        <p className={styles['compact-card-title']}>{title}</p>
        <p className={styles['compact-card-description']}>{description}</p>
      </div>
    </a>
  );
}

CompactCard.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.func,
};

CompactCard.defaultProps = {
  className: null,
  description: '',
  title: '',
  image: null,
  icon: null,
};

export default CompactCard;
