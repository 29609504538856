import classNames from 'classnames';
import PropTypes from 'prop-types';

import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import styles from './style.module.css';

function NavItem({ label, linkTo }) {
  const resolved = useResolvedPath(linkTo);
  const match = useMatch({ path: resolved.pathname, end: true });
  const customClassName = classNames(styles['navbar-item'], {
    [styles.active]: !!match,
  });
  return (
    <li className={customClassName}>
      <NavLink to={linkTo}>{label}</NavLink>
    </li>
  );
}

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default NavItem;
