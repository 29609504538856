import PropTypes from 'prop-types';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import {
  useTeamFontStackCreateMutation,
  useTeamFontStacksInvalidateQuery,
  useTeamFontStackUpdateMutation,
  useUserFontStackCreateMutation,
  useUserFontStacksInvalidateQuery,
  useUserFontStackUpdateMutation,
} from 'hooks/api/fonts';
import FallbackStackDesigner from 'components/fallback-stack-designer';
import { WEB_SAFE_FONTS } from 'components/fallback-stack-designer/utils';

import schema from './schema';
import styles from './styles.module.css';

function FontStackModal({
  theme,
  isOpen,
  close,
  isEditing,
  font,
  teamId,
  onStackCreated,
}) {
  const action = isEditing
    ? {
        present: 'Update',
        past: 'Updated',
      }
    : {
        present: 'Add',
        past: 'Added',
      };

  const initialValues = isEditing
    ? { fallback: font.fallback }
    : {
        fallback: {
          name: `${font.family} stack`,
          stack: [{ family: WEB_SAFE_FONTS[0].family }],
        },
      };

  const userFontsInvalidate = useUserFontStacksInvalidateQuery();
  const teamFontsInvalidate = useTeamFontStacksInvalidateQuery();
  const fontsInvalidate = teamId ? teamFontsInvalidate : userFontsInvalidate;

  const config = {
    onSuccess: () => {
      close();
      fontsInvalidate();
      onStackCreated(font);
      toast.success(`Fallback stack ${action.past.toLowerCase()} successfully`);
    },
    onError: () => {
      toast.error(
        `Failed to ${action.present.toLowerCase()} the fallback stack, try again later`
      );
    },
  };

  const userCreateMutation = useUserFontStackCreateMutation({
    config,
  });
  const teamCreateMutation = useTeamFontStackCreateMutation({ teamId, config });
  const createMutation = teamId ? teamCreateMutation : userCreateMutation;

  const userUpdateMutation = useUserFontStackUpdateMutation({
    fontStackId: font.id,
    config,
  });
  const teamUpdateMutation = useTeamFontStackUpdateMutation({
    teamId,
    fontStackId: font.id,
    config,
  });
  const updateMutation = teamId ? teamUpdateMutation : userUpdateMutation;

  const saveStack = ({ fallback }) => {
    if (isEditing) {
      updateMutation.mutate({ name: fallback.name, stack: fallback.stack });
    } else {
      createMutation.mutate({
        font: font.id,
        name: fallback.name,
        stack: fallback.stack,
      });
    }
  };

  return (
    <Modal
      theme={theme}
      isOpen={isOpen}
      onDismiss={close}
      aria-label="regular modal"
    >
      <Modal.Header
        onDismiss={close}
        title={`${action.present} fallback stack`}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={saveStack}
      >
        {({ isValid, values }) => (
          <Form>
            <Modal.Content>
              <div className={styles['font-container']}>
                <div className={styles.font}>
                  <div>
                    <span>Font Name</span>
                    <strong>{font.family}</strong>
                  </div>
                  <div>
                    <span>Weights</span>
                    <strong>{font.weights}</strong>
                  </div>
                </div>

                <FallbackStackDesigner
                  id="fallback"
                  theme={theme}
                  fallback={values.fallback}
                />
              </div>
            </Modal.Content>
            <Modal.Footer>
              <Button theme={theme} hierarchy="tertiary" onClick={close}>
                Cancel
              </Button>
              <Button
                theme={theme}
                hierarchy="primary"
                type="submit"
                disabled={
                  !isValid ||
                  createMutation.isLoading ||
                  updateMutation.isLoading
                }
                loading={createMutation.isLoading || updateMutation.isLoading}
              >
                {action.present} fallback stack
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

FontStackModal.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  font: PropTypes.shape({
    id: PropTypes.number,
    family: PropTypes.string,
    weights: PropTypes.string,
    fallback: PropTypes.shape({
      name: PropTypes.string,
      stack: PropTypes.arrayOf(PropTypes.shape({ family: PropTypes.string })),
    }),
  }),
  teamId: PropTypes.number,
  onStackCreated: PropTypes.func,
};

FontStackModal.defaultProps = {
  theme: 'light',
  font: { family: '', weights: '' },
  teamId: null,
  onStackCreated: () => {},
};

export default FontStackModal;
