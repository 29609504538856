import React from 'react';
import { FiFilePlus } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';

import Tabs from '@scalero-inc/forno-tab';

import LoadingFullPage from 'components/loading-full-page';

import FileCreateForm from 'containers/file-create-form';
import TemplatesFileCreateForm from 'containers/templates-file-create-form';

import { useUserQuery } from 'hooks/api/users';

import {
  canCreatePersonalFile,
  canCreateEditDeleteTeamFile,
  hasAccessToProFeatures,
} from 'utils/permissions';

import styles from './style.module.css';

function DashboardImportFile() {
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted] = React.useState(false);
  const [, setValues] = React.useState();
  const { teamId, projectId } = useParams();
  const { data: user } = useUserQuery();

  const teamBlocked = !canCreateEditDeleteTeamFile({ user, teamId });
  const personalBlocked = !canCreatePersonalFile({ user });

  const blocked = teamId ? teamBlocked : personalBlocked;

  if (isSubmitted)
    return (
      <LoadingFullPage>
        <h1>Creating your file</h1>
      </LoadingFullPage>
    );

  const onChange = (e) => {
    setValues(e);
  };

  const onSubmit = () => {
    setSubmitted(true);
  };

  const onSuccess = ({ id }) => {
    navigate(`/process/${id}`);
  };

  return (
    <div className={styles['dashboard-import-file']}>
      <div className={styles['dashboard-import-file-header']}>
        <FiFilePlus />
        <p>New File</p>
      </div>
      <div className={styles['dashboard-import-file-content']}>
        {blocked ? (
          <p>
            Check your subscription status and permissions to access this
            feature.
          </p>
        ) : (
          <Tabs
            id="new-file-tabs"
            disabledKeys={
              !hasAccessToProFeatures({ user }) ? ['templates-form'] : []
            }
          >
            <Tabs.Item title="From Figma" key="figma-form">
              <FileCreateForm
                className={styles['file-create-page-form']}
                onChange={onChange}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                team={teamId}
                project={projectId}
              />
            </Tabs.Item>
            <Tabs.Item title="From Template" key="templates-form">
              <TemplatesFileCreateForm
                className={styles['file-create-page-form']}
                onChange={onChange}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                team={teamId}
                project={projectId}
              />
            </Tabs.Item>
          </Tabs>
        )}
      </div>
    </div>
  );
}

export default DashboardImportFile;
