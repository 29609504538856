import PropTypes from 'prop-types';
import { NavLink, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import Tooltip from '@scalero-inc/forno-tooltip';

import styles from './styles.module.css';

function SidebarLabelItem({
  isCollapsed,
  url,
  title,
  icon,
  action,
  disabled,
  isSelected,
  tooltipConfig,
}) {
  const path = url || 'not-found';
  const match = useMatch({
    path,
    end: false,
  });
  const customClassname = classNames(styles['sidebar-label-item-container'], {
    [styles.active]: !!match || isSelected,
  });

  if (tooltipConfig)
    return (
      <Tooltip {...tooltipConfig}>
        <div
          className={customClassname}
          data-disabled={disabled}
          data-theme="light"
        >
          {url ? (
            <NavLink to={url}>
              <div className={styles['sidebar-label-item-title']}>
                {icon}
                {isCollapsed ? null : <p>{title}</p>}
              </div>
            </NavLink>
          ) : (
            <NavLink onClick={action}>
              <div className={styles['sidebar-label-item-title']}>
                {icon}
                {isCollapsed ? null : <p>{title}</p>}
              </div>
            </NavLink>
          )}
        </div>
      </Tooltip>
    );

  return (
    <div
      className={customClassname}
      data-disabled={disabled}
      data-theme="light"
    >
      {url ? (
        <NavLink to={url}>
          <div className={styles['sidebar-label-item-title']}>
            {icon}
            {isCollapsed ? null : <p>{title}</p>}
          </div>
        </NavLink>
      ) : (
        <NavLink onClick={action}>
          <div className={styles['sidebar-label-item-title']}>
            {icon}
            {isCollapsed ? null : <p>{title}</p>}
          </div>
        </NavLink>
      )}
    </div>
  );
}

SidebarLabelItem.propTypes = {
  isCollapsed: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  tooltipConfig: PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.shape().isRequired,
    placement: PropTypes.string,
    theme: PropTypes.string,
  }),
};

SidebarLabelItem.defaultProps = {
  isCollapsed: false,
  url: '',
  action: () => {},
  disabled: false,
  isSelected: false,
  tooltipConfig: null,
};

export default SidebarLabelItem;
