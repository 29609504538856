import PropTypes from 'prop-types';
import useDebounce from 'hooks/utils/use-debounce';
import MjmlEditor from 'components/mjml-editor';
import CodeEditor from 'components/code-editor';
import UpdateFilePreview from 'containers/update-file-preview';
import { useEditor, useEditorDispatch } from '../editor-context';
import { useEditorView } from '../editor-view-context';

import styles from './style.module.css';

function EditorCode({ visible }) {
  const {
    fileId,
    componentId,
    mjml,
    html,
    errors = [],
    tagSelected,
  } = useEditor();
  const isEditingFile = !!fileId;
  const { teamId } = useEditor();
  const { setTagSelected, editor } = useEditorDispatch(
    teamId,
    fileId,
    componentId
  );
  const { viewCode } = useEditorView();

  const updateState = (code) => {
    editor.save(code);
  };
  const debouncedOnChange = useDebounce(updateState, 300);

  return (
    <>
      {isEditingFile && <UpdateFilePreview />}
      <section
        className={styles['editor-code-container']}
        data-visible={visible}
      >
        {viewCode === 'mjml' ? (
          <MjmlEditor
            name="main-editor-mjml"
            setTagSelected={setTagSelected}
            tagSelected={tagSelected}
            value={mjml}
            onChange={debouncedOnChange}
            errors={errors}
          />
        ) : null}
        {viewCode === 'html' ? (
          <CodeEditor
            name="main-editor-html"
            value={html}
            mode="html"
            readOnly
          />
        ) : null}
      </section>
    </>
  );
}

EditorCode.propTypes = {
  visible: PropTypes.bool,
};

EditorCode.defaultProps = {
  visible: false,
};

export default EditorCode;
