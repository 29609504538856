import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import classNames from 'classnames';

import Spinner from '@scalero-inc/forno-spinner';

import ProfileContainer from 'containers/profile-container';
import SidebarBottom from 'containers/sidebar-bottom';
import PrivacyPolicyFooter from 'containers/privacy-policy-footer';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'react-headless-accordion';
import { LuFiles, LuFolder } from 'react-icons/lu';
import { HiOutlinePuzzle } from 'react-icons/hi';
import {
  BsFileEarmarkFont,
  BsFileEarmarkText,
  BsFonts,
  BsInfoSquare,
} from 'react-icons/bs';
import { RiPencilRulerLine } from '@hacknug/react-icons/ri';
import { MdOutlineFolderOff } from 'react-icons/md';

import Logo from 'components/logo';
import SidebarLabelItem from 'components/sidebar-label-item';

import { useAuth } from 'context/auth';
import { useTeamProjectsQuery } from 'hooks/api/projects';
import { useParams } from 'react-router-dom';

import { CURRENT_PROFILE_STORAGE_KEY } from 'utils/constants';

import styles from './style.module.css';

function DashboardSidebar({ isLoading, isCollapsed, user, team }) {
  const customClassName = classNames(styles['dashboard-sidebar'], {
    [styles.collapsed]: isCollapsed,
  });

  const [personalView, setPersonalView] = React.useState(true);
  const { currentProfile, setCurrentProfile } = useAuth();
  const { teamId } = useParams();

  React.useEffect(() => {
    setPersonalView(!currentProfile?.projects || !teamId || !team.id);
  }, [currentProfile, teamId, team]);

  React.useEffect(() => {
    if (teamId && team?.id) {
      setCurrentProfile(team);
      localStorage.setItem(
        CURRENT_PROFILE_STORAGE_KEY,
        JSON.stringify({
          id: team.id,
          name: team.name,
          projects: team.projects,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, team]);

  const { data: projects } = useTeamProjectsQuery({
    team: teamId,
    config: { enabled: !personalView },
  });

  if (isLoading) return <Spinner />;

  return (
    <aside className={customClassName}>
      <div className={styles['dashboard-sidebar-top']}>
        <header className={styles['dashboard-sidebar-header']}>
          <Logo collapsed={isCollapsed} />
        </header>
        <ProfileContainer isCollapsed={isCollapsed} user={user} />
      </div>
      <hr className={styles.divider} />
      <div className={styles['dashboard-sidebar-body']}>
        <div className={styles['dashboard-sidebar-body-content']}>
          {personalView ? (
            <SidebarLabelItem
              isCollapsed={isCollapsed}
              title="Files"
              icon={<LuFiles size={16} />}
              url="/dashboard/personal/files"
            />
          ) : (
            <Accordion>
              {projects?.map((project) => (
                <AccordionItem key={project.id}>
                  {({ open }) => (
                    <>
                      <AccordionHeader className={styles['accordion-header']}>
                        <SidebarLabelItem
                          isCollapsed={isCollapsed}
                          title={project.name}
                          icon={<LuFolder size={16} />}
                        />
                        {open ? (
                          <FiChevronDown
                            className={styles['accordion-header-svg']}
                          />
                        ) : (
                          <FiChevronRight
                            className={styles['accordion-header-svg']}
                          />
                        )}
                      </AccordionHeader>
                      <AccordionBody className={styles['accordion-body']}>
                        <SidebarLabelItem
                          isCollapsed={isCollapsed}
                          title="Files"
                          icon={<LuFiles size={16} />}
                          url={`/dashboard/teams/${currentProfile.id}/projects/${project.id}/files`}
                        />
                        <SidebarLabelItem
                          isCollapsed={isCollapsed}
                          title="Templates"
                          icon={<BsFileEarmarkText size={16} />}
                          url={`/dashboard/teams/${currentProfile.id}/projects/${project.id}/templates`}
                        />
                        <SidebarLabelItem
                          isCollapsed={isCollapsed}
                          title="Info"
                          icon={<BsInfoSquare size={16} />}
                          url={`/dashboard/teams/${currentProfile.id}/projects/${project.id}/info`}
                        />
                      </AccordionBody>
                    </>
                  )}
                </AccordionItem>
              ))}
              {projects?.length === 0 && (
                <SidebarLabelItem
                  isCollapsed={isCollapsed}
                  title="No projects found"
                  icon={<MdOutlineFolderOff size={16} />}
                  disabled
                />
              )}
              <hr className={styles.divider} />
            </Accordion>
          )}
          <SidebarLabelItem
            isCollapsed={isCollapsed}
            title="Blocks"
            icon={<HiOutlinePuzzle size={16} />}
            url={
              currentProfile?.projects
                ? `/dashboard/teams/${currentProfile.id}/blocks`
                : '/dashboard/personal/blocks'
            }
          />
          {personalView && (
            <SidebarLabelItem
              isCollapsed={isCollapsed}
              title="Templates"
              icon={<BsFileEarmarkText size={16} />}
              url="/dashboard/personal/templates"
            />
          )}
          <Accordion>
            <AccordionItem>
              {({ open }) => (
                <>
                  <AccordionHeader className={styles['accordion-header']}>
                    <SidebarLabelItem
                      isCollapsed={isCollapsed}
                      title="Resources"
                      icon={<RiPencilRulerLine size={16} />}
                    />
                    {open ? (
                      <FiChevronDown
                        className={styles['accordion-header-svg']}
                      />
                    ) : (
                      <FiChevronRight
                        className={styles['accordion-header-svg']}
                      />
                    )}
                  </AccordionHeader>
                  <AccordionBody className={styles['accordion-body']}>
                    <SidebarLabelItem
                      isCollapsed={isCollapsed}
                      title="Font Stacks"
                      icon={<BsFileEarmarkFont size={16} />}
                      url={
                        currentProfile?.projects
                          ? `/dashboard/teams/${currentProfile.id}/resources/font-stacks`
                          : '/dashboard/personal/resources/font-stacks'
                      }
                    />
                    <SidebarLabelItem
                      isCollapsed={isCollapsed}
                      title="Custom Fonts"
                      icon={<BsFonts size={16} />}
                      url={
                        currentProfile?.projects
                          ? `/dashboard/teams/${currentProfile.id}/resources/custom-fonts`
                          : '/dashboard/personal/resources/custom-fonts'
                      }
                    />
                  </AccordionBody>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <div className={styles['dashboard-sidebar-body-footer']}>
        <hr className={styles.divider} />
        <SidebarBottom isCollapsed={isCollapsed} user={user} />
      </div>
      <PrivacyPolicyFooter />
    </aside>
  );
}

DashboardSidebar.propTypes = {
  isLoading: PropTypes.bool,
  isCollapsed: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        projects: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          })
        ),
      })
    ),
  }),
  team: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
};

DashboardSidebar.defaultProps = {
  isLoading: true,
  user: {
    first_name: '',
    last_name: '',
    avatar: '',
  },
  team: {
    id: null,
    name: '',
    projects: [],
  },
};

export default DashboardSidebar;
