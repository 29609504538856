import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useFetch } from 'context/fetch';

import { USER_QUERY_KEY } from 'hooks/api/constants';

import { userQuery } from './queries';
import { userEditMutation, userEditAvatarMutation } from './mutations';

export function useUserQuery({ config = {} } = {}) {
  const { client } = useFetch();
  return useQuery([USER_QUERY_KEY], () => userQuery({ client }), config);
}

export function useUserEditMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation((values) => userEditMutation({ client, values }), config);
}

export function useUserEditAvatarMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();
  return useMutation(
    (file) => userEditAvatarMutation({ client, file }),
    config
  );
}

export function useUserInvalidateQuery(props = {}) {
  const { key = USER_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}
