import { getTextContentWithStyles, getTextStyles } from '../text';
import { getSimpleColor } from '../color';
import { getPadding } from '../bounding-box';

function MjText({
  parent,
  attributes,
  fills,
  characters,
  styleOverrideTable,
  style,
  characterStyleOverrides,
  tagName,
  ...rest
}) {
  const padding = getPadding(parent, rest, tagName);
  const cssClass = { 'css-class': 'text' };
  const { textStyles, errorList } = getTextStyles(style, characters);
  const alignment = style.textAlignHorizontal
    ? style.textAlignHorizontal.toLowerCase()
    : 'left';

  return [
    {
      tagName: 'mj-text',
      errors: errorList,
      children: [],
      content: getTextContentWithStyles(
        characters,
        styleOverrideTable,
        characterStyleOverrides
      ).replace(/[\n]/g, '<br/>'),
      attributes: {
        ...attributes,
        ...cssClass,
        color: getSimpleColor(fills),
        align: alignment,
        ...(padding && padding),
        ...textStyles,
      },
    },
  ];
}

export default MjText;
