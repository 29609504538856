import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  FiAlertCircle,
  FiArrowLeft,
  FiCheckCircle,
  FiSettings,
} from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

function PaymentStatusModal({ close, isOpen, team, status }) {
  const navigate = useNavigate();
  const settingsUrl =
    team !== 'null'
      ? `/dashboard/teams/${team}/billing`
      : '/dashboard/settings/billing';
  const dashboardUrl =
    team !== 'null'
      ? `/dashboard/teams/${team}/projects`
      : '/dashboard/personal/files';
  const modalStatus = status === 'canceled' ? 'danger' : 'normal';

  return (
    <Modal
      onDismiss={close}
      isOpen={isOpen}
      aria-label="payment status modal"
      status={modalStatus}
    >
      <Modal.Header
        title={`Payment ${status}`}
        icon={status === 'successful' ? FiCheckCircle : FiAlertCircle}
        onDismiss={close}
      />
      {status === 'successful' ? (
        <p>
          Go to your Dashboard to start using Cannoli, or go to Settings if you
          want to change or update any billing information.
        </p>
      ) : (
        <p>
          You have canceled your payment. Go to settings to finish setup, or go
          to your Dashboard to continue using Cannoli with your previous plan.
        </p>
      )}
      <Modal.Footer>
        <Button
          icon={FiArrowLeft}
          hierarchy="tertiary"
          onClick={() => {
            close();
            navigate(dashboardUrl);
          }}
        >
          Back to dashboard
        </Button>
        <Button
          icon={FiSettings}
          hierarchy="primary"
          onClick={() => {
            close();
            navigate(settingsUrl);
          }}
        >
          Go to settings
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

PaymentStatusModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  team: PropTypes.string,
  status: PropTypes.string,
};

PaymentStatusModal.defaultProps = {
  status: null,
  team: null,
};

export default PaymentStatusModal;
