import PropTypes from 'prop-types';
import classnames from 'classnames';
import Split from 'react-split-grid';

import ResizeGutter from 'components/resize-gutter';

import {
  useReadOnlyView,
  useReadOnlyViewDispatch,
} from '../read-only-view-context';

import styles from './style.module.css';
import ReadOnlyCode from '../read-only-code';
import ReadOnlyPreview from '../read-only-preview';

function ReadOnlyContent({ className }) {
  const { viewColumns } = useReadOnlyView();
  const { setViewColumns } = useReadOnlyViewDispatch();

  const customClassName = classnames(
    styles['editor-content-container'],
    className
  );

  const handleDrag = (direction, track, gridTemplateStyle) => {
    setViewColumns(gridTemplateStyle);
  };

  const handleDragEnd = () => {
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <div className={styles['editor-content']}>
      <Split
        minSize={0}
        dragInterval={10}
        snapOffset={30}
        cursor="col-resize"
        gridTemplateRows="1fr"
        gridTemplateColumns={viewColumns}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        render={({ getGridProps, getGutterProps }) => {
          return (
            <div className={customClassName} {...getGridProps()}>
              <ReadOnlyCode visible />
              <ResizeGutter {...getGutterProps('column', 1)} />
              <ReadOnlyPreview />
            </div>
          );
        }}
      />
    </div>
  );
}

ReadOnlyContent.propTypes = {
  className: PropTypes.string,
};

ReadOnlyContent.defaultProps = {
  className: null,
};

export default ReadOnlyContent;
