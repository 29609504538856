import messages from '../../messages';
import { tagsDicts } from '../dictionaries';

export function getErrors() {
  const node = { id: '-', name: 'mj-head' };
  const errors = [];
  errors.push(messages.warning(messages.MSG.PREVIEW_TEXT, node));
  errors.push(messages.warning(messages.MSG.FONT_FALLBACK, node));
  // We need to determinate from backend if the design implements a custom font
  // errors.push(messages.warning(messages.MSG.FONT_LINK, node));

  return errors;
}

export function getContentComponentsImpl(parentComponent, componentsList) {
  const { children, tagName } = parentComponent;

  if (tagName !== 'mj-body' && tagName !== 'mj-group' && tagName !== 'mj-raw') {
    componentsList.push(tagName);
  }

  if (children) {
    children.map((child) => getContentComponentsImpl(child, componentsList));
  }

  const uniqueComponents = [...new Set(componentsList)];
  return uniqueComponents;
}

export function getDefaultPadding(implementedComponents = []) {
  const components = [];

  implementedComponents.forEach((component) => {
    if (!components.some((comp) => comp.tagName === component)) {
      components.push({
        tagName: component,
        attributes: {
          padding: 0,
        },
      });
    }
  });

  return components;
}

export function getMobileStyles(components) {
  const cssClasses = [];
  components.forEach((component) => {
    const tag = tagsDicts.find((element) => element.tag === component);
    if (tag && tag.css) {
      cssClasses.push(...tag.css);
    }
  });

  return cssClasses;
}
