import PropTypes from 'prop-types';
import Tooltip from '@scalero-inc/forno-tooltip';
import Input from '@scalero-inc/forno-input';
import Button from '@scalero-inc/forno-button';
import { FiHelpCircle, FiPlus } from 'react-icons/fi';
import {
  DEFAULT_FALLBACKS,
  WEB_SAFE_FONTS,
} from 'components/fallback-stack-designer/utils';
import { FieldArray, useField } from 'formik';

import styles from './styles.module.css';
import FallbackDropdown from './fallback-dropdown';

const NUM_MAX_FALLBACKS = 3;

function FallbackStackDesigner({ id, theme, fallback }) {
  const [field, meta, helpers] = useField({ name: `${id}.name` });

  const fonts = [...WEB_SAFE_FONTS, ...DEFAULT_FALLBACKS];
  const fontOptions = fonts.map((font) => {
    return { label: font.family, value: font.family };
  });

  const canAddFallback = fallback.stack.length < NUM_MAX_FALLBACKS;
  const canRemoveFallback = fallback.stack.length > 1;

  return (
    <div className={styles['fallback-container']}>
      <div className={styles['intro-text']}>
        <span>Fallback stack designer</span>
        <Tooltip
          placement="top"
          theme={theme}
          status="warning"
          content={
            <p className="font-paragraph-s-sb">
              When your font can&#39;t be loaded by an email app, these fonts
              will be loaded starting with the 1st fallback.
            </p>
          }
        >
          <FiHelpCircle />
        </Tooltip>
      </div>

      <div className={styles['stack-name']}>
        <Input
          {...field}
          size="s"
          theme={theme}
          label="Stack name"
          placeholder="My fallback stack"
          onChange={(event) => {
            helpers.setValue(event.target.value);
          }}
          warningText={meta.error}
        />
      </div>

      <div className={styles['fallback-stack']}>
        <FieldArray
          name="fallback.stack"
          render={(arrayHelpers) => {
            const onAddFallback = () => {
              arrayHelpers.push({ family: WEB_SAFE_FONTS[0].family });
            };

            const onRemoveFallback = (fallbackIndex) => {
              arrayHelpers.remove(fallbackIndex);
            };

            return (
              <>
                {fallback.stack &&
                  fallback.stack.map((item, index) => {
                    return (
                      <FallbackDropdown
                        key={`stack_intem_${index}`}
                        id={id}
                        index={index}
                        fontOptions={fontOptions}
                        isRemovable={canRemoveFallback}
                        onRemove={() => onRemoveFallback(index)}
                      />
                    );
                  })}

                {fallback.stack.length !== NUM_MAX_FALLBACKS && (
                  <div>
                    <Button
                      theme={theme}
                      hierarchy="tertiary"
                      size="s"
                      icon={FiPlus}
                      onClick={() => onAddFallback()}
                      disabled={!canAddFallback}
                    >
                      Add fallback
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

FallbackStackDesigner.propTypes = {
  id: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  fallback: PropTypes.shape({
    name: PropTypes.string,
    stack: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

FallbackStackDesigner.defaultProps = {
  theme: 'light',
  fallback: { name: '', stack: [{ family: WEB_SAFE_FONTS[0].family }] },
};

export default FallbackStackDesigner;
