import PropTypes from 'prop-types';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';
import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiFile } from 'react-icons/fi';
import { useFileCreateFromTemplateMutation } from 'hooks/api/files';

import schema from './schema';
import styles from './styles.module.css';

function FileFromTemplateModal({ team, project, template, isOpen, onDismiss }) {
  const navigate = useNavigate();
  const createFileMutation = useFileCreateFromTemplateMutation({
    config: {
      onSuccess: (data) => {
        const { id } = data;
        navigate(`/editor/${id}`);
        toast.success('File created successfully');
        onDismiss();
      },
      onError: () => {
        toast.error('Error creating file');
      },
    },
    teamId: team,
    projectId: project,
    templateId: template,
  });
  const createCannoliFile = (values) => {
    createFileMutation.mutate({ ...values });
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      theme="light"
      aria-label="create file from template modal"
    >
      <Modal.Header
        title="Create Cannoli file"
        onDismiss={onDismiss}
        icon={FiFile}
      />

      <Formik
        initialValues={{ name: '', description: '' }}
        validationSchema={schema}
        onSubmit={createCannoliFile}
      >
        {({ isValid, dirty, errors }) => (
          <Form>
            <div className={styles['form-wrapper']}>
              <Field id="name" name="name">
                {({ field }) => (
                  <Input
                    id="name"
                    size="s"
                    label="File Name"
                    placeholder="Name of your file"
                    theme="light"
                    warningText={errors.name}
                    {...field}
                  />
                )}
              </Field>
            </div>

            <Modal.Footer>
              <Button theme="light" hierarchy="tertiary" onClick={onDismiss}>
                Cancel
              </Button>
              <Button
                theme="light"
                type="submit"
                loading={createFileMutation.isLoading}
                disabled={!isValid || !dirty || createFileMutation.isLoading}
              >
                Create Cannoli file
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

FileFromTemplateModal.propTypes = {
  team: PropTypes.string,
  project: PropTypes.string,
  template: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

FileFromTemplateModal.defaultProps = {
  team: null,
  project: null,
};

export default FileFromTemplateModal;
