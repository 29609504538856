import { FiSmartphone, FiMonitor, FiTablet } from 'react-icons/fi';

export const viewWithPanel = '300px 16px 1fr 16px 340px';
export const viewWithoutPanel = '1fr 16px 1fr';

export const visualEditorDevicesOptions = [
  {
    icon: FiSmartphone,
    value: '1fr 16px 413px 16px 1fr',
    tooltipText: 'Quickly switch to mobile width',
  },
  {
    icon: FiTablet,
    value: '1fr 16px 768px 16px 1fr',
    tooltipText: 'Quickly switch to tablet width',
  },
  {
    icon: FiMonitor,
    value: '1fr 16px 1025px 16px 1fr',
    tooltipText: 'Quickly switch to desktop width',
  },
];

export const codeEditorDevicesOptions = [
  {
    icon: FiSmartphone,
    value: '1fr 16px 413px',
    tooltipText: 'Quickly switch to mobile width',
  },
  {
    icon: FiTablet,
    value: '1fr 16px 768px',
    tooltipText: 'Quickly switch to tablet width',
  },
  {
    icon: FiMonitor,
    value: '1fr 16px 1025px',
    tooltipText: 'Quickly switch to desktop width',
  },
];

export const viewCodeOptions = [
  {
    value: 'mjml',
    label: 'MJML',
  },
  {
    value: 'html',
    label: 'HTML',
  },
];

export const viewPreviewOptions = [
  {
    value: 'iframe',
    label: 'iframe',
  },
  {
    value: 'image',
    label: 'image',
  },
  {
    value: 'compare',
    label: 'compare',
  },
];
