import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FiChevronLeft, FiImage, FiPlusCircle } from 'react-icons/fi';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  useFilesInvalidateQuery,
  useFileCreateMutation,
} from 'hooks/api/files';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';

import FormikEffect from 'components/formik-effect';

import PageSelectInput from 'containers/page-select-input';
import FrameSelectInput from 'containers/frame-select-input';
import NodePreview from 'containers/node-preview';

import { getFileIdfromFigmaURL } from 'utils/figma';

import UrlHelpMessage from './url-help-message';
import schema from './schema';
import styles from './style.module.css';

const isLocal = process.env.REACT_APP_ENV === 'dev';
const url = isLocal
  ? 'https://www.figma.com/file/NSlWNBymdBDDkVLOTqMZdp/Cannoli-Community-File?node-id=189%3A2318'
  : '';
const defaultInitialValues = {
  url,
  name: '',
  page: '',
  frame: '',
};

function FileCreateForm({
  onSuccess,
  onSubmit,
  onError,
  onChange,
  className,
  initialValues,
  team,
  project,
}) {
  const customClassName = classnames(
    'file-create-form',
    className,
    styles['file-create-form']
  );

  const navigate = useNavigate();

  const invalidate = useFilesInvalidateQuery();

  const mutation = useFileCreateMutation({
    team,
    project,
    config: {
      onSuccess: (data) => {
        invalidate();
        onSuccess(data);
      },
      onError: (data) => {
        onError(data);
      },
    },
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSubmit(values);
    mutation.mutate(
      { url: values.url, node: values.frame, name: values.name },
      { onSettled: () => setSubmitting(false) }
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting, values }) => {
        const fileId = getFileIdfromFigmaURL(values.url);
        const canSubmit = values.url && values.page && values.frame;
        return (
          <Form className={customClassName}>
            <FormikEffect onChange={onChange} />
            <div className={styles['form-container']}>
              <div className={styles['field-wrapper']}>
                <label htmlFor="name">File Name</label>
                <ErrorMessage name="name" component="div" />
                <Field id="name" name="name">
                  {({ field }) => (
                    <Input
                      {...field}
                      theme="light"
                      className={styles.field}
                      placeholder="Name of your file"
                    />
                  )}
                </Field>
              </div>
              <div className={styles['field-wrapper']}>
                <label htmlFor="url">Figma Link</label>
                <Field id="url" name="url" type="url">
                  {({ field, meta }) => (
                    <Input
                      {...field}
                      theme="light"
                      className={styles.field}
                      placeholder="Figma file URL"
                      warningText={
                        meta.touched && meta.error ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </div>
              <div className={styles['frame-selection-dropdowns']}>
                {fileId ? (
                  <PageSelectInput name="page" fileId={fileId} />
                ) : null}
                {values.page ? (
                  <FrameSelectInput
                    name="frame"
                    fileId={fileId}
                    pageId={values.page}
                  />
                ) : null}
              </div>

              <UrlHelpMessage showPluginHelp={!!fileId} />

              <div className={styles['file-create-form-footer']}>
                <Button
                  onClick={goBack}
                  icon={FiChevronLeft}
                  hierarchy="tertiary"
                  theme="light"
                  size="m"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || !canSubmit}
                  loading={isSubmitting}
                  icon={FiPlusCircle}
                  size="m"
                >
                  Create File
                </Button>
              </div>
            </div>

            <div className={styles['preview-container']}>
              <p>Figma Preview</p>
              <div className={styles['frame-node-preview-container']}>
                <div>
                  {values.frame ? (
                    <NodePreview file={fileId} node={values.frame} />
                  ) : (
                    <div className={styles['preview-placeholder-icon']}>
                      <FiImage size={50} />
                      <p className="font-paragraph-l-sb">File Preview</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

FileCreateForm.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.shape({
    url: PropTypes.string,
  }),
  team: PropTypes.string,
  project: PropTypes.string,
};

FileCreateForm.defaultProps = {
  initialValues: defaultInitialValues,
  onError: () => {},
  onSubmit: () => {},
  onSuccess: () => {},
  onChange: () => {},
  className: null,
  team: null,
  project: null,
};

export default FileCreateForm;
