function log(message, { type, id, name, tagName }) {
  return {
    type,
    message,
    id,
    name,
    tagName,
  };
}

function error(message, props) {
  return log(message, {
    ...props,
    type: 'ERROR',
  });
}

function warning(message, props) {
  return log(message, {
    ...props,
    type: 'WARNING',
  });
}

function info(message, props) {
  return log(message, {
    ...props,
    type: 'INFO',
  });
}

const MSG = {
  PREVIEW_TEXT: 'Preview text needed',
  FONT_FALLBACK: 'Missing font fallback configuration',
  FONT_LINK: 'Specify your custom font link',
  EMAIL_WIDTH: 'The safest width to design your emails is 550 – 650px',
  BACKGROUND_COLOR: 'The element does not have background color.',
  URL_LINK: 'The URL link is required',
  ALTERNATE_TEXT: 'Provide an alternate text',
  NOT_SUPPORTED_YET: 'Component not supported yet',
  UNABLE_TO_CONVERT: 'Unable to convert the element',
  NO_AUTO_LAYOUT: 'The element does not implement auto layout',
  NO_TEXT_CONTENT: 'The element does not contain text',
  LINE_HEIGHT_FIXED:
    'Line height has been fixed (it should be equals or bigger than font size)',
};

export default {
  warning,
  error,
  info,
  MSG,
};
