import PropTypes from 'prop-types';
import Button from '@scalero-inc/forno-button';
import styles from './style.module.css';

function UrlHelpMessage({ showPluginHelp }) {
  const { REACT_APP_FIGMA_COMMUNITY_FILE, REACT_APP_PLUGIN_URL } = process.env;

  if (showPluginHelp) {
    return (
      <div>
        Want to sync faster? Try out our{' '}
        <a
          className={styles['url-help-message-anchor']}
          rel="noreferrer"
          target="_blank"
          href={REACT_APP_PLUGIN_URL}
        >
          plug in
        </a>
        .
      </div>
    );
  }

  return (
    <div className={styles['url-help-message']}>
      <p>
        Copy your figma file link and be sure to follow our community file
        guidelines to ensure the best output from Cannoli. If you have any
        questions or find any bugs contact us at{' '}
        <a
          className={styles['url-help-message-anchor']}
          href="mailto:hello@cannoli.app"
        >
          hello@cannoli.app
        </a>
      </p>
      <p>
        Check out our community file with the instructions to design for
        Cannoli.
      </p>
      <Button
        href={REACT_APP_FIGMA_COMMUNITY_FILE}
        target="_blank"
        rel="noreferrer"
        hierarchy="secondary"
      >
        Figma Community File
      </Button>
    </div>
  );
}

UrlHelpMessage.propTypes = {
  showPluginHelp: PropTypes.bool,
};

UrlHelpMessage.defaultProps = {
  showPluginHelp: false,
};

export default UrlHelpMessage;
