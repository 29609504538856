/* eslint-disable react/no-danger */
import { useState } from 'react';
import PropTypes from 'prop-types';
import json2mjml from 'json2mjml';
import Frame from 'react-frame-component';
import HeaderPrivate from 'containers/header-private';

import ButtonGroup from '@scalero-inc/forno-button-group';

import figmaToMjml from 'lib/figma-to-mjml';
import { extractParseMessages } from 'lib/figma-to-mjml/parser';

import { useNodesQuery } from 'hooks/api/figma/node';
import { useFileImagesQuery } from 'hooks/api/figma/image';

import { getStateFromMjml } from 'pages/editor/editor-context/functions';

import CodeEditor from 'components/code-editor';
import MjmlEditor from 'components/mjml-editor';

import FileEditorDebugErrors from './file-editor-debug-errors';

import styles from './style.module.css';

const options = [
  {
    label: 'MJML',
    value: 'mjml',
  },
  {
    label: 'MJML JSON',
    value: 'mjmlJson',
  },
  {
    label: 'JSON',
    value: 'json',
  },
];

function FileEditorDebug({ id, node }) {
  const [view, setView] = useState('mjml');
  const fileQuery = useNodesQuery({ file: id, nodes: [node] });
  const imagesQuery = useFileImagesQuery({ file: id });
  if (fileQuery.isLoading || imagesQuery.isLoading) {
    return 'Loading...';
  }

  const { images } = imagesQuery.data.meta;
  const doc = fileQuery.data.nodes[node].document;

  const mjmlJson = figmaToMjml([doc], images, []);
  const mjml = json2mjml(mjmlJson);
  const { html, errors: editorErrors } = getStateFromMjml(mjml);
  const errors = extractParseMessages(mjmlJson);

  return (
    <div className={styles['file-editor-debug-wrapper']}>
      <HeaderPrivate>
        <h2>Parse test</h2>
      </HeaderPrivate>
      <header>
        <ButtonGroup
          size="s"
          type="radio"
          theme="dark"
          onChange={(e) => setView(e[0])}
          initialValue={[view]}
        >
          {options.map((item) => {
            return (
              <ButtonGroup.Button
                value={item.value}
                key={item.value}
                disabled={item.value === 'option2'}
              >
                {item.label}
              </ButtonGroup.Button>
            );
          })}
        </ButtonGroup>
        <FileEditorDebugErrors errors={errors} />
      </header>
      <div className={styles['file-editor-debug-code']}>
        {view === 'mjml' ? (
          <MjmlEditor
            name="main-editor-mjml"
            value={mjml}
            errors={editorErrors}
            readOnly
          />
        ) : null}
        {view === 'json' ? (
          <CodeEditor
            name="main-editor-html"
            value={JSON.stringify(doc, null, 2)}
            mode="json"
            readOnly
          />
        ) : null}
        {view === 'mjmlJson' ? (
          <CodeEditor
            name="main-editor-json"
            value={JSON.stringify(mjmlJson, null, 2)}
            mode="json"
            readOnly
          />
        ) : null}
        <Frame className={styles['iframe-wrapper']}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Frame>
      </div>
    </div>
  );
}

FileEditorDebug.propTypes = {
  id: PropTypes.string.isRequired,
  node: PropTypes.string.isRequired,
};

export default FileEditorDebug;
