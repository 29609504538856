import PropTypes from 'prop-types';
import classNames from 'classnames';

import FileRow from 'containers/file-row';
import ComponentRow from 'containers/component-row';
import ProjectRow from 'containers/project-row';
import TemplateRow from 'containers/template-row';

import styles from './style.module.css';

function FileTable({
  content,
  type,
  teamId,
  projectId,
  page,
  setPage,
  blocked,
}) {
  const customClassName = classNames('files-table', styles['files-table']);
  return (
    <div className={customClassName}>
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <th>Preview</th>
            <th>Name</th>
            <th>Last updated</th>
            {type === 'files' || type === 'blocks' || type === 'templates' ? (
              <>
                <th>Created at</th>
                <th>Delete</th>
              </>
            ) : null}
          </tr>
          {content?.map((item) => {
            if (type === 'files') {
              return (
                <FileRow
                  key={item.id}
                  {...item}
                  numFiles={content.length}
                  page={page}
                  setPage={setPage}
                  disabled={blocked}
                />
              );
            }

            if (type === 'blocks') {
              return (
                <ComponentRow
                  key={item.id}
                  {...item}
                  teamId={teamId}
                  numComponents={content.length}
                  page={page}
                  setPage={setPage}
                  disabled={blocked}
                />
              );
            }

            if (type === 'templates') {
              return (
                <TemplateRow
                  key={item.id}
                  {...item}
                  teamId={teamId}
                  projectId={projectId}
                  numTemplates={content.length}
                  page={page}
                  setPage={setPage}
                  disabled={blocked}
                />
              );
            }

            return <ProjectRow key={item.id} {...item} />;
          })}
        </tbody>
      </table>
    </div>
  );
}

FileTable.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  userPermission: PropTypes.string,
  blocked: PropTypes.bool,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

FileTable.defaultProps = {
  teamId: null,
  projectId: null,
  content: [],
  userPermission: null,
  blocked: null,
};

export default FileTable;
