import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFigma } from 'react-icons/fi';

import styles from './style.module.css';

function CompareSliderHandle({ className, isFocused }) {
  const customClassName = classnames(
    'compare-slider-handle',
    styles['compare-slider-handle'],
    className,
    {}
  );

  return (
    <span className={customClassName} data-focused={isFocused ? 'true' : false}>
      <FiFigma />
    </span>
  );
}

CompareSliderHandle.propTypes = {
  className: PropTypes.string,
  isFocused: PropTypes.bool,
};

CompareSliderHandle.defaultProps = {
  className: null,
  isFocused: false,
};

export default CompareSliderHandle;
