import {
  FiColumns,
  FiImage,
  FiInstagram,
  FiMousePointer,
  FiSquare,
  FiTrello,
  FiType,
} from 'react-icons/fi';
import * as Yup from 'yup';
import { BUTTON_ATTRIBUTES } from './tags/button';
import { COLUMN_ATTRIBUTES } from './tags/column';
import { IMAGE_ATTRIBUTES } from './tags/image';
import { SECTION_ATTRIBUTES } from './tags/section';
import { SOCIAL_ELEMENT_ATTRIBUTES } from './tags/social';
import { WRAPPER_ATTRIBUTES } from './tags/wrapper';
import { TEXT_ATTRIBUTES } from './tags/text';

export const validateUrl = Yup.string()
  .trim()
  .lowercase()
  .matches(/(mailto:.+|https?:\/\/.+)/);

export const validateSrc = Yup.string()
  .trim()
  .lowercase()
  .matches(/https?:\/\/.+.(jpg|gif|png)/)
  .required();

export const ATTRIB_DESCRIPTION = {
  alt: {
    type: 'text',
    label: 'Alt Text',
    validator: null,
    placeholder: 'Describe the appearance of the image',
  },
  href: {
    type: 'url',
    label: 'Link to',
    validator: validateUrl,
    placeholder: 'https://example.com',
  },
  src: {
    type: 'url',
    label: 'Image url',
    validator: validateSrc,
    placeholder: 'https://example.com/image.png',
  },
  width: {
    type: 'size',
    label: 'Width',
    validator: null,
    placeholder: '',
  },
  height: {
    type: 'size',
    label: 'Height',
    validator: null,
    placeholder: '',
  },
  'icon-size': {
    type: 'size',
    label: 'Icon Size',
    validator: null,
    placeholder: '',
  },
  'icon-height': {
    type: 'size',
    label: 'Icon Height',
    validator: null,
    placeholder: '',
  },
  padding: {
    type: 'shorthand',
    label: 'Padding',
    validator: null,
    placeholder: '',
  },
  border: {
    type: 'border',
    label: 'Border',
    validator: null,
    placeholder: '0',
  },
  'border-radius': {
    type: 'shorthand',
    label: 'Radius',
    validator: null,
    placeholder: '0',
  },
  'font-family': {
    type: 'font',
    label: 'Font family',
    validator: null,
    placeholder: '0',
  },
  'font-size': {
    type: 'size',
    label: 'Font size',
    validator: null,
    placeholder: '0',
  },
  'font-weight': {
    type: 'size',
    label: 'Font weight',
    validator: null,
    placeholder: '',
  },
  'line-height': {
    type: 'size',
    label: 'Line height',
    validator: null,
    placeholder: '0',
  },
  content: {
    type: 'textarea',
    label: 'Content',
    validator: null,
    placeholder: 'Write your text',
  },
};

export const MJML_CONTENT_TAGS = {
  'mj-body': {
    attributes: [],
  },
  'mj-accordion': {
    attributes: [],
  },
  'mj-button': {
    attributes: BUTTON_ATTRIBUTES,
    content: true,
    icon: <FiMousePointer />,
    units: {
      width: ['px'],
      height: ['px'],
      'font-size': ['px'],
      'font-weight': [],
      'line-height': ['px'],
    },
  },
  'mj-carousel': {
    attributes: [],
  },
  'mj-column': {
    attributes: COLUMN_ATTRIBUTES,
    icon: <FiColumns />,
    units: {
      width: ['px', '%'],
    },
  },
  'mj-divider': {
    attributes: [],
  },
  'mj-group': {
    attributes: [],
  },
  'mj-hero': {
    attributes: [],
  },
  'mj-image': {
    attributes: IMAGE_ATTRIBUTES,
    icon: <FiImage />,
    units: {
      width: ['px'],
      height: ['px'],
    },
  },
  'mj-navbar': {
    attributes: [],
  },
  'mj-raw': {
    attributes: [],
    content: true,
  },
  'mj-section': {
    attributes: SECTION_ATTRIBUTES,
    icon: <FiTrello />,
  },
  'mj-social': {
    attributes: [],
  },
  'mj-social-element': {
    attributes: SOCIAL_ELEMENT_ATTRIBUTES,
    icon: <FiInstagram />,
    units: {
      'icon-size': ['px', '%'],
      'icon-height': ['px', '%'],
    },
  },
  'mj-spacer': {
    attributes: [],
  },
  'mj-table': {
    attributes: [],
  },
  'mj-text': {
    attributes: TEXT_ATTRIBUTES,
    content: true,
    icon: <FiType />,
    units: {
      'font-size': ['px'],
      'font-weight': [],
      'line-height': ['px'],
      height: ['px'],
    },
  },
  'mj-wrapper': {
    attributes: WRAPPER_ATTRIBUTES,
    icon: <FiSquare />,
  },
};

// Configure the elements you want to edit
export const editableAttributes = [
  'href',
  'src',
  'alt',
  'width',
  'height',
  'padding',
  'border-radius',
  'border',
  'icon-size',
  'icon-height',
  'font-family',
  'font-size',
  'font-weight',
  'line-height',
];
export const isContentEditingAllowed = true;

export const isTagEditable = (tagName = '') => {
  const allowedTagAttribs = MJML_CONTENT_TAGS[tagName]?.attributes;

  if (!allowedTagAttribs) {
    return false;
  }

  const hasEditableAttribs = editableAttributes.some((attrib) =>
    allowedTagAttribs.includes(attrib)
  );
  const isContentEditable =
    MJML_CONTENT_TAGS[tagName]?.content === isContentEditingAllowed;

  return hasEditableAttribs || isContentEditable;
};
