import PropTypes from 'prop-types';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import { FiTrash2 } from 'react-icons/fi';

function GoogleFontDeleteModal({ font, theme, isOpen, close, onDelete }) {
  return (
    <Modal
      theme={theme}
      isOpen={isOpen}
      onDismiss={close}
      aria-label="delete google font warning"
    >
      <Modal.Header onDismiss={close} title="Delete Google Font" />
      <p>
        Are you sure you want to delete the font <strong>{font.family}</strong>{' '}
        from your file?
      </p>
      <Modal.Footer>
        <Button theme={theme} hierarchy="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button
          theme={theme}
          hierarchy="primary"
          icon={FiTrash2}
          status="danger"
          onClick={() => onDelete(font.index)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

GoogleFontDeleteModal.propTypes = {
  font: PropTypes.shape({
    family: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

GoogleFontDeleteModal.defaultProps = {
  theme: 'light',
};

export default GoogleFontDeleteModal;
