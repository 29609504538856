import PropTypes from 'prop-types';
import classnames from 'classnames';

import Spinner from '@scalero-inc/forno-spinner';
import { useImagesQuery } from 'hooks/api/figma/image';

import styles from './style.module.css';

function NodePreview({ file, node }) {
  const { data, isLoading, error } = useImagesQuery({
    file,
    nodes: [node],
  });
  const customClassName = classnames('node-preview', styles['node-preview']);

  if (isLoading) return <Spinner size="l" />;
  if (error) return <pre>{error}</pre>;

  const { images } = data;
  const preview = images[node];

  return <img src={preview} alt="" className={customClassName} />;
}

NodePreview.propTypes = {
  file: PropTypes.string.isRequired,
  node: PropTypes.string.isRequired,
};

export default NodePreview;
