import PropTypes from 'prop-types';
import { FiLink2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import Spinner from '@scalero-inc/forno-spinner';

import {
  useFileShareHashCreateMutation,
  useFileShareHashDeleteMutation,
  useFileShareHashInvalidateQuery,
  useFileShareHashQuery,
} from 'hooks/api/files';

import Toggle from '@scalero-inc/forno-toggle';
import styles from './style.module.css';

function FileShareModal({ close, fileId, isOpen }) {
  const {
    data,
    isLoading: isHashLoading,
    isError: isHashError,
  } = useFileShareHashQuery({
    fileId,
  });
  const hash = data?.share_hash || '';
  const shareLink = hash ? `${window.location.href}?sh=${hash}` : '';

  const invalidate = useFileShareHashInvalidateQuery({ fileId });
  const { mutate: createHash, isLoading: createLoading } =
    useFileShareHashCreateMutation({
      fileId,
      config: {
        onSuccess: () => invalidate(),
        onError: () => toast.error('Error creating hash. Try again later.'),
      },
    });
  const { mutate: deleteHash, isLoading: deleteLoading } =
    useFileShareHashDeleteMutation({
      fileId,
      config: {
        onSuccess: () => invalidate(),
        onError: () => toast.error('Error deleting hash. Try again later.'),
      },
    });

  const handleToggle = () => {
    if (hash) {
      deleteHash();
    } else {
      createHash();
    }
  };

  const handleCopyHash = () => {
    copy(shareLink);
    toast('Sharing link copied to clipboard');
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      theme="dark"
      aria-label="share file modal"
      className={styles['file-share-modal']}
    >
      <Modal.Header title="Share" onDismiss={close} theme="dark" />
      {(() => {
        if (isHashLoading) {
          return <Spinner />;
        }

        if (isHashError) {
          return <div>Unexpected error occurred, please try again later.</div>;
        }

        return (
          <>
            <div className={styles['file-sharing-toggle']}>
              <div className={styles['file-sharing-message']}>
                <h4>File sharing</h4>
                {hash ? (
                  <p>File is public and can be shared</p>
                ) : (
                  <p>File is not public and cannot be shared</p>
                )}
              </div>
              <Toggle
                id="share-file"
                className={styles['file-sharing-toggle-button']}
                defaultChecked={!!hash}
                onChange={handleToggle}
                disabled={deleteLoading || createLoading}
                theme="dark"
              />
            </div>
            {hash ? <Input theme="dark" value={shareLink} readOnly /> : null}
            <Modal.Footer>
              <Button hierarchy="tertiary" theme="dark" onClick={close}>
                Cancel
              </Button>
              <Button theme="dark" disabled={!hash} onClick={handleCopyHash}>
                <FiLink2 />
                Copy share link
              </Button>
            </Modal.Footer>
          </>
        );
      })()}
    </Modal>
  );
}

FileShareModal.propTypes = {
  fileId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

FileShareModal.defaultProps = {
  isOpen: false,
};

export default FileShareModal;
