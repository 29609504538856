import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import { useTeamSubscriptionCreateMutation } from 'hooks/api/subscriptions';

import { dollarUSFormat } from 'utils/currency';

function SubscriptionTeamCreateModal({ teamId, close, isOpen, seats }) {
  const subscriptionSeats = seats < 2 ? 2 : seats;
  const dueAmount = 25 + (subscriptionSeats - 2) * 12;
  const navigate = useNavigate();
  const { mutate } = useTeamSubscriptionCreateMutation({
    team: teamId,
    seats: subscriptionSeats,
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      onError: () =>
        toast.error(
          'There was an error with your request, please try again later'
        ),
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      aria-label="Update team subscription modal"
      onDismiss={close}
      status="danger"
    >
      <Modal.Header
        title="Subscription update"
        onDismiss={close}
        icon={FiAlertTriangle}
      />
      {seats < 2 ? (
        <p>
          Your team has to have at least 2 seats, but currently you just have{' '}
          <strong>1 member</strong>. Create team with <strong>2 seats</strong>{' '}
          and invite team member later?
        </p>
      ) : (
        <p>
          Currently your team has <strong>{seats} member(s)</strong>. Create
          subscription with this number of seats?
        </p>
      )}
      <table>
        <tbody>
          <tr>
            <td>
              <p>{subscriptionSeats} seats</p>
            </td>
            <td>
              <p>$25 (2 base users) + $12 per extra user</p>
            </td>
          </tr>
          <tr>
            <td>
              <h3>Due today:</h3>
            </td>
            <td>
              <h3>{dollarUSFormat.format(dueAmount)} USD</h3>
            </td>
          </tr>
        </tbody>
      </table>
      <Modal.Footer>
        <Button
          hierarchy="tertiary"
          onClick={() => navigate(`/dashboard/teams/${teamId}/members/`)}
        >
          Update team members
        </Button>
        <Button onClick={mutate}>Continue to checkout</Button>
      </Modal.Footer>
    </Modal>
  );
}

SubscriptionTeamCreateModal.propTypes = {
  teamId: PropTypes.number,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  seats: PropTypes.number.isRequired,
};

SubscriptionTeamCreateModal.defaultProps = {
  teamId: null,
};

export default SubscriptionTeamCreateModal;
