import { Link } from 'react-router-dom';

import { usePaginatedComponentsQuery } from 'hooks/api/components';

import ComponentCreateButton from 'containers/component-create-button';

// TODO: This is a placeholder component to showcase the usage of the API hooks
function DashboardPersonalComponents() {
  const query = usePaginatedComponentsQuery();

  if (query.isLoading) return 'Loading ...';

  return (
    <section>
      <header className="forno-layout-cluster">
        <h2>Components</h2>
        <ComponentCreateButton />
      </header>
      <ul>
        {query.data.map((item) => {
          return (
            <li key={item.id}>
              <Link to={`./${item.id}`} style={{ color: `var(--color-fg-01)` }}>
                {item.name} | {item.tags?.join()}
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default DashboardPersonalComponents;
