import PropTypes from 'prop-types';
import { FiArrowLeft, FiCheckCircle, FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

function AccountStatusModal({ close, isOpen }) {
  const navigate = useNavigate();

  return (
    <Modal
      onDismiss={close}
      isOpen={isOpen}
      aria-label="account creation status modal"
    >
      <Modal.Header
        title="Account created successfully"
        icon={FiCheckCircle}
        onDismiss={close}
      />
      <p>
        Welcome to Cannoli! You can start creating projects on your dashboard,
        or go to settings to change your subscription
      </p>
      <Modal.Footer>
        <Button
          icon={FiSettings}
          hierarchy="tertiary"
          onClick={() => {
            close();
            navigate('/dashboard/settings/billing');
          }}
        >
          Go to Settings
        </Button>
        <Button icon={FiArrowLeft} onClick={close}>
          Go to dashboard
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AccountStatusModal.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AccountStatusModal;
