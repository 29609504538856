export const SECTION_ATTRIBUTES = [
  'padding',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'padding-top',
  'background-color',
  'background-position',
  'background-position-x',
  'background-position-y',
  'background-repeat',
  'background-size',
  'background-url',
  'border',
  'border-bottom',
  'border-left',
  'border-radius',
  'border-right',
  'border-top',
  'css-class',
  'direction',
  'full-width',
  'text-align',
];
