import PropTypes from 'prop-types';
import { FiAlertTriangle, FiPlus } from 'react-icons/fi';
import { useState, useCallback } from 'react';

import Button from '@scalero-inc/forno-button';
import Spinner from '@scalero-inc/forno-spinner';

import TeamMembersTable from 'containers/team-members-table';
import TeamMemberForm from 'containers/team-member-form';

import SubscriptionUpdateSeatsModal from 'containers/subscription-update-seats-modal';

import { useTeamMembersQuery } from 'hooks/api/teams';

import { canAddEditDeleteTeamMembers } from 'utils/permissions';

import styles from './style.module.css';
import TeamMembersNoSubscription from './team-members-no-subscription';

function DashboardTeamMembers({ teamId, user, userLoading }) {
  const [showForm, setShowForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const { data: members, isLoading: membersLoading } = useTeamMembersQuery({
    teamId,
  });

  if (membersLoading || userLoading) return <Spinner />;

  const subscription = user.team_subscriptions.find(
    (sub) => sub.team === teamId
  );
  const teamMembers = members?.length || 0;
  const { seats } = subscription || { seats: 0 };
  const difference = seats - teamMembers;

  const blocked = !canAddEditDeleteTeamMembers({ user, teamId });

  const handleClick = () => {
    if (difference > 0) {
      setShowForm(true);
    } else {
      open();
    }
  };

  if (!subscription) {
    return (
      <TeamMembersNoSubscription
        teamMembers={teamMembers}
        members={members}
        teamId={teamId}
      />
    );
  }

  return (
    <div className={styles['dashboard-team-members']}>
      <div className={styles['team-members-header-container']}>
        <h2>Members ({teamMembers})</h2>
        <Button icon={FiPlus} onClick={handleClick} disabled={blocked}>
          Add team member
        </Button>
        {seats > teamMembers ? (
          <p>
            <FiAlertTriangle />
            You have{' '}
            <strong>
              {difference} empty seat
              {difference > 1 ? 's' : null}
            </strong>{' '}
            in your team.{' '}
            {difference > 1
              ? 'Add team members to fill them.'
              : 'Add a team member to fill it.'}
          </p>
        ) : null}
      </div>
      <SubscriptionUpdateSeatsModal
        isOpen={isOpen}
        close={close}
        subscriptionId={subscription.id}
        onSuccess={() => setShowForm(true)}
        onClickCTA={close}
      />
      {showForm ? (
        <section className={styles['team-members-add']}>
          <TeamMemberForm
            teamId={teamId}
            onSuccess={() => {
              if (difference <= 1) setShowForm(false);
            }}
          />
        </section>
      ) : null}
      <section className={styles['team-members-list']}>
        <TeamMembersTable teamId={teamId} members={members} />
      </section>
    </div>
  );
}

DashboardTeamMembers.propTypes = {
  teamId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    teams: PropTypes.arrayOf(PropTypes.shape({})),
    team_subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  userLoading: PropTypes.bool.isRequired,
};

export default DashboardTeamMembers;
