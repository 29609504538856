import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import GoogleFontDeleteModal from 'containers/google-font-delete-modal';
import GoogleFontsTable from 'containers/google-fonts-table';
import FontStackModal from 'containers/font-stack-modal';
import FallbackFontModal from 'containers/fallback-font-modal';
import { useEditor, useEditorDispatch } from 'pages/editor/editor-context';
import json2mjml from 'json2mjml';
import { getGoogleFonts } from 'utils/mjml/util';
import { buildFontWeights } from './utils';

import styles from './styles.module.css';

function GoogleFontsAdminModal({ theme, close }) {
  const { fileId, componentId, teamId, mjHead, json } = useEditor();
  const { editor } = useEditorDispatch(teamId, fileId, componentId);
  const [fontSelected, setFontSelected] = useState(null);
  const [isFontsTableOpen, setFontsTableOpen] = useState(true);
  const googleFonts = getGoogleFonts(mjHead.children || []);

  const saveChanges = useCallback(() => {
    const mjml = json2mjml(json);
    return editor.save(mjml);
  }, [editor, json]);

  const [isAddFallbackOpen, setIsAddFallbackOpen] = useState(false);
  const openFallbackWindow = useCallback((font) => {
    setFontSelected(font);
    setFontsTableOpen(false);
    setIsAddFallbackOpen(true);
  }, []);
  const closeFallbackWindow = useCallback(() => {
    setIsAddFallbackOpen(false);
    setFontsTableOpen(true);
  }, []);

  const addFontToFile = useCallback(
    (font) => {
      const fontName = font.family.replaceAll(' ', '+');
      const fontWeights = buildFontWeights(font.variants);
      const fontURL = `https://fonts.googleapis.com/css2?family=${fontName}${fontWeights}&display=swap`;

      mjHead.children.push({
        tagName: 'mj-font',
        attributes: {
          name: font.family,
          href: fontURL,
        },
      });
      saveChanges();
    },
    [mjHead.children, saveChanges]
  );
  const [isAddFontOpen, setIsAddFontOpen] = useState(false);
  const openAddFontWindow = useCallback(
    (hasFallback, googleFont) => {
      if (hasFallback) {
        addFontToFile(googleFont);
      } else {
        const weights = googleFont.variants
          .map((font) => font.variant)
          .join(', ');
        const fontToAdd = { ...googleFont, weights };
        setFontSelected(fontToAdd);
        setIsAddFontOpen(true);
        setFontsTableOpen(false);
      }
    },
    [addFontToFile]
  );
  const closeAddFontWindow = useCallback(() => {
    setIsAddFontOpen(false);
    setFontsTableOpen(true);
  }, []);

  const [isFontDeleteOpen, setFontDeleteOpen] = useState(false);
  const closeFontDeleteWindow = useCallback(() => {
    setFontDeleteOpen(false);
    setFontsTableOpen(true);
  }, []);
  const onDeleteFont = useCallback((font) => {
    setFontSelected({
      family: font.family,
      index: font.index,
    });
    setFontsTableOpen(false);
    setFontDeleteOpen(true);
  }, []);
  const deleteFont = useCallback(
    (fontIndex) => {
      mjHead.children = mjHead.children.filter(
        (element, index) => index !== fontIndex
      );
      saveChanges();
      closeFontDeleteWindow();
    },
    [closeFontDeleteWindow, mjHead, saveChanges]
  );

  return (
    <>
      <Modal
        theme={theme}
        isOpen={isFontsTableOpen}
        onDismiss={close}
        aria-label="google fonts administration"
        data-modal-type="google-fonts-admin"
        className={styles['google-fonts-modal']}
      >
        <Modal.Header onDismiss={close} title="Google fonts" />
        <Modal.Content>
          <GoogleFontsTable
            teamId={teamId}
            googleFonts={googleFonts}
            onAddFont={openAddFontWindow}
            onAddFallback={openFallbackWindow}
            onDeleteFont={onDeleteFont}
            theme={theme}
          />
        </Modal.Content>
        <Modal.Footer>
          <Button onClick={close} theme={theme} hierarchy="tertiary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {fontSelected && (
        <GoogleFontDeleteModal
          font={fontSelected}
          theme={theme}
          isOpen={isFontDeleteOpen}
          close={closeFontDeleteWindow}
          onDelete={deleteFont}
        />
      )}

      {fontSelected && isAddFontOpen && (
        <FontStackModal
          theme="dark"
          isOpen={isAddFontOpen}
          close={closeAddFontWindow}
          isEditing={false}
          font={fontSelected}
          teamId={teamId}
          onStackCreated={addFontToFile}
        />
      )}

      {fontSelected && isAddFallbackOpen && (
        <FallbackFontModal
          isOpen={isAddFallbackOpen}
          close={closeFallbackWindow}
          font={fontSelected}
          teamId={teamId}
        />
      )}
    </>
  );
}

GoogleFontsAdminModal.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  close: PropTypes.func,
};

GoogleFontsAdminModal.defaultProps = {
  theme: 'light',
  close: () => {},
};

export default GoogleFontsAdminModal;
