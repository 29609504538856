/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';

import alertStyles from 'components/alert/style.module.css';
import { getStylesheetFromRawCss } from 'utils';

// eslint-disable-next-line import/no-unresolved
import alertRawCss from '!!raw-loader!components/alert/style.module.css';

function HtmlToReactErrorFallback({ html }) {
  const alertStylesheet = getStylesheetFromRawCss(alertStyles, alertRawCss);

  return (
    <>
      <div>
        <style>{alertStylesheet}</style>
        <Alert hierarchy="danger" title="MJML errors detected." />
      </div>

      <div dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
}

HtmlToReactErrorFallback.propTypes = {
  html: PropTypes.string,
};

HtmlToReactErrorFallback.defaultProps = {
  html: null,
};

export default HtmlToReactErrorFallback;
