import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TagContent from './tag-content';
import styles from '../styles.module.css';

export default function ChildrenTag({
  section,
  itemIndex,
  draggable,
  hasChildren,
  selectedId,
  parents,
}) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(parents.has(section));
  }, [parents, section, selectedId]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={styles['body-children-tag-container']}>
      <TagContent
        isExpanded={expanded}
        handleExpand={handleExpand}
        section={section}
        itemIndex={itemIndex}
        id={section.id}
        draggable={draggable}
        hasChildren={hasChildren}
      />
      <div className={styles['body-children-acordeon']}>
        {expanded &&
          section.children?.map((item) => {
            return (
              <div key={item.id} className={styles['node-children']}>
                <ChildrenTag
                  section={item}
                  itemId={item.id}
                  draggable={false}
                  hasChildren={!!item.children}
                  selectedId={selectedId}
                  parents={parents}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

ChildrenTag.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    tagName: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  itemIndex: PropTypes.number,
  draggable: PropTypes.bool,
  hasChildren: PropTypes.bool,
  selectedId: PropTypes.string,
  parents: PropTypes.instanceOf(Set).isRequired,
};

ChildrenTag.defaultProps = {
  draggable: true,
  itemIndex: 0,
  hasChildren: true,
  selectedId: '',
};
