import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';

import { Field, Form, Formik } from 'formik';
import { FiCheck } from 'react-icons/fi';
import { toast } from 'react-toastify';

import {
  useCreateComponentMutation,
  useCreateTeamComponentMutation,
  useTeamComponentsInvalidateQuery,
  useUserComponentsInvalidateQuery,
} from 'hooks/api/components';

import { EditorPanelLeftContext } from 'containers/editor-panel-left/editor-panel-context';

import BlockPreviewIframe from 'components/block-preview-iframe/block-preview-iframe';
import HtmlToReact from 'components/html-to-react';

import { generateHtml } from 'utils';

import styles from './styles.module.css';

export default function CreateCustomBlock({ close, setCreateIsOpen }) {
  const { jsonState, removeIds, bodyIndex, teamId, fileId } = useContext(
    EditorPanelLeftContext
  );

  const bodyChildrenWithoutId =
    removeIds(jsonState).children[bodyIndex].children;

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (values.block.length === 0) {
      errors.block = 'Required';
    }
    return errors;
  };

  const invalidateUser = useUserComponentsInvalidateQuery();
  const invalidateTeam = useTeamComponentsInvalidateQuery({
    teamId,
  });

  const { mutate: mutateUser } = useCreateComponentMutation({
    config: {
      onSuccess: (data) => {
        toast.success(`Component ${data.name} Created`);
        invalidateUser();
      },
      onError: () => {
        toast.error('Failed to create component');
      },
    },
  });

  const { mutate: mutateTeam } = useCreateTeamComponentMutation({
    teamId,
    config: {
      onSuccess: (data) => {
        toast.success(`Component ${data.name} Created`);
        invalidateTeam();
      },
      onError: () => {
        toast.error('Failed to create component');
      },
    },
  });

  const handleSubmit = (values) => {
    const blockArray = values.block.map((item) => JSON.parse(item));
    if (teamId !== null && teamId !== undefined) {
      mutateTeam({
        name: values.name,
        content: { blockArray },
        file: fileId,
      });
    } else {
      mutateUser({
        name: values.name,
        content: { blockArray },
        file: fileId,
      });
    }
  };

  const handleClick = (index) => {
    document.querySelector(`input[type="checkbox"][id="${index}"]`).click();
  };

  const [selected, setSelected] = useState([]);

  const handleChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const checked = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const jsonItem = JSON.parse(checkbox.value);
        checked.push(jsonItem);
      }
    });
    setSelected(checked);
  };

  const [html, setHtml] = useState('');

  useEffect(() => {
    setHtml(generateHtml(selected));
  }, [selected]);

  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          block: [],
        }}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnChange
      >
        {({ values }) => {
          return (
            <Form onChange={handleChange}>
              <section className={styles['create-custom-block-container']}>
                <div
                  className={styles['create-custom-block-sections-container']}
                >
                  <Field
                    as={Input}
                    label="Custom block name"
                    theme="dark"
                    name="name"
                    className={styles['create-custom-block-input']}
                  />
                  <section>
                    <p className="font-block-s">Block section(s)</p>
                    <div
                      className={styles['create-custom-block-sections-list']}
                    >
                      {bodyChildrenWithoutId.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={styles['create-custom-block-section']}
                            data-checked={values.block.includes(
                              JSON.stringify(item)
                            )}
                            onClick={() => handleClick(index)}
                            role="button"
                            tabIndex={0}
                          >
                            <Field
                              id={index}
                              type="checkbox"
                              name="block"
                              value={JSON.stringify(item)}
                              className={styles['create-custom-block-checkbox']}
                            />
                            <FiCheck
                              className={styles['create-custom-block-check']}
                              size={10}
                            />
                            <p className="font-block-s-sb">
                              {item.attributes.name ?? item.tagName}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                </div>
                <div
                  className={styles['create-custom-block-preview-container']}
                >
                  <div className={styles['custom-block-item-iframe']}>
                    {selected.length > 0 && (
                      <BlockPreviewIframe disableLinks>
                        <HtmlToReact html={html} />
                      </BlockPreviewIframe>
                    )}
                  </div>
                </div>
              </section>

              <Modal.Footer>
                <Button
                  theme="dark"
                  hierarchy="tertiary"
                  onClick={() => {
                    close();
                    setCreateIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  theme="dark"
                  onClick={() => {
                    handleSubmit(values);
                    close();
                    setCreateIsOpen(false);
                  }}
                  type="submit"
                  disabled={values.block.length === 0 || values.name === ''}
                >
                  Create
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

CreateCustomBlock.propTypes = {
  close: PropTypes.func.isRequired,
  setCreateIsOpen: PropTypes.func.isRequired,
};
