export function getElementCoords(elem, root = document) {
  // crossbrowser version
  const rect = elem.getBoundingClientRect();
  const { height, width } = rect;

  const { body } = root;
  const docEl = root.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = rect.top + scrollTop - clientTop;
  const left = rect.left + scrollLeft - clientLeft;

  return { top, left, width, height };
}
