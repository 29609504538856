import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@scalero-inc/forno-card';
import { FiFile } from 'react-icons/fi';
import Time from 'components/time';
import CardPreviewIframe from 'components/card-preview-iframe';
import HtmlToReact from 'components/html-to-react';
import { getHtmlTemplate } from 'utils';
import TemplateCardMenu from './template-card-menu';

import styles from './styles.module.css';

function TemplateCard({
  id,
  teamId,
  projectId,
  name,
  description,
  template_components: templateComponents,
  updated_at: updatedAt,
  numTemplates,
  page,
  setPage,
  disabled,
  isEditable,
  onlyView,
}) {
  const url =
    teamId && projectId
      ? `/dashboard/teams/${teamId}/projects/${projectId}/templates/${id}`
      : `/dashboard/personal/templates/${id}`;
  const customClassName = classnames(
    'forno-card-wrapper',
    styles['template-card']
  );

  const actions = !onlyView && (
    <TemplateCardMenu
      id={id}
      name={name}
      description={description}
      templateComponents={templateComponents}
      url={url}
      teamId={teamId}
      projectId={projectId}
      isEditable={isEditable}
      numTemplates={numTemplates}
      page={page}
      setPage={setPage}
    />
  );

  return (
    <Card
      className={customClassName}
      url={!onlyView ? url : undefined}
      actions={actions}
      placeholderIcon={<FiFile />}
      data-disabled={disabled}
    >
      <CardPreviewIframe>
        <HtmlToReact html={getHtmlTemplate(templateComponents)} />
      </CardPreviewIframe>
      <h3>{name}</h3>
      <p className={styles['template-description']}>{description}</p>
      <p>
        Last updated: <Time>{updatedAt}</Time>
      </p>
    </Card>
  );
}

TemplateCard.propTypes = {
  id: PropTypes.number.isRequired,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  template_components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updated_at: PropTypes.string,
  numTemplates: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  onlyView: PropTypes.bool,
};

TemplateCard.defaultProps = {
  teamId: null,
  projectId: null,
  description: '',
  updated_at: new Date().toISOString(),
  numTemplates: null,
  page: null,
  setPage: null,
  disabled: null,
  isEditable: false,
  onlyView: false,
};

export default TemplateCard;
