import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import TagManager from 'react-gtm-module';
import reportWebVitals from './reportWebVitals';
import App from './app';

import 'styles/index';

const {
  REACT_APP_ENV,
  REACT_APP_VERSION,
  REACT_APP_GOOGLE_ANALYTICS_CODE,
  REACT_APP_GTM_CODE,
} = process.env;

if (REACT_APP_ENV === 'prod' || REACT_APP_ENV === 'dev') {
  const tagManagerArgs = {
    gtmId: REACT_APP_GTM_CODE,
  };
  TagManager.initialize(tagManagerArgs);

  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_CODE);
  ReactGA.pageview(window.location.pathname + window.location.search);

  Sentry.init({
    dsn: 'https://fd7fbbe95f1d400e9a3dc27f07041453@o469260.ingest.sentry.io/6387350',
    integrations: [new BrowserTracing()],
    release: `cannoli-frontend@${REACT_APP_VERSION}`,
    tracesSampleRate: 1.0,
    environment: `${REACT_APP_ENV}`,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.REACT_APP_ENV === 'local') {
  const {
    worker,
  } = require('./mocks/browser'); /* eslint-disable-line global-require */

  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
