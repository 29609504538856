import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CodeEditor from 'components/code-editor';

import { mjmlTags } from './helpers';

function MJMLEditor(props) {
  const { value, onChange, errors, setTagSelected, tagSelected, readonly } =
    props;
  const [state, setState] = useState(value);
  const ref = React.useRef();
  const { current } = ref;

  React.useEffect(() => {
    // Don't update state if changes come from "code" itself
    if (tagSelected.from !== 'code') {
      setState(value);
    }
  }, [tagSelected.from, value]);

  React.useEffect(() => {
    if (!current) return;
    const { editor } = current;
    const session = editor.getSession();
    session.setAnnotations(errors);
  }, [errors, current]);

  React.useEffect(() => {
    if (!current) return;
    const { editor } = ref.current;
    // https://github.com/securingsincity/react-ace/blob/master/docs/FAQ.md#how-do-i-add-the-search-box
    // https://stackoverflow.com/questions/53622096/how-to-specify-a-list-of-custom-tokens-to-list-for-autocompletion-in-ace-editor
    editor.completers.push({
      getCompletions(_, session, pos, prefix, callback) {
        const completions = [];
        // we can use session and pos here to decide what we are going to show
        mjmlTags.forEach((item) => {
          completions.push({
            value: item.value,
            meta: item.value,
          });
        });
        callback(null, completions);
      },
    });
  }, [current]);

  return (
    <CodeEditor
      ref={ref}
      mode="xml"
      {...props}
      value={state}
      onChange={(e) => {
        setState(e);
        onChange(e);
      }}
      tagSelected={tagSelected}
      setTagSelected={setTagSelected}
      readonly={readonly}
    />
  );
}

MJMLEditor.propTypes = {
  tagSelected: PropTypes.shape({
    id: PropTypes.string,
    from: PropTypes.string,
  }),
  setTagSelected: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number,
      column: PropTypes.number,
      text: PropTypes.string,
      type: PropTypes.oneOf(['error', 'warning', 'information']),
    })
  ),
  readonly: PropTypes.bool,
};

MJMLEditor.defaultProps = {
  tagSelected: {},
  setTagSelected: () => {},
  value: '',
  onChange: () => {},
  errors: [],
  readonly: false,
};

export default MJMLEditor;
