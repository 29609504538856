import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { FiPlus } from 'react-icons/fi';

import { useNavigate } from 'react-router-dom';

import Avatar from '@scalero-inc/forno-avatar';
import IconButton from '@scalero-inc/forno-icon-button';

import { useAuth } from 'context/auth';
import { useTeamsQuery } from 'hooks/api/teams';
import { CURRENT_PROFILE_STORAGE_KEY } from 'utils/constants';

import styles from './style.module.css';

export default function ProfileMenu({ user }) {
  const { setCurrentProfile } = useAuth();
  const navigate = useNavigate();

  const { data: teams } = useTeamsQuery();

  const currentProfileStorage = localStorage.getItem(
    CURRENT_PROFILE_STORAGE_KEY
  );
  const currentProfileStorageParsed = JSON.parse(currentProfileStorage) || null;

  return (
    <div className={styles['profile-menu']}>
      <Menu>
        <MenuButton
          as={IconButton}
          theme="dark"
          hierarchy="tertiary"
          disabled={!user?.teams?.length}
        >
          <HiOutlineSwitchHorizontal />
        </MenuButton>
        <MenuList className={styles['menu-popover']} data-theme="dark">
          <MenuItem
            key={user.id}
            className={styles['menu-item']}
            data-item-selected={!currentProfileStorageParsed}
            onSelect={() => {
              setCurrentProfile(user);
              localStorage.removeItem(CURRENT_PROFILE_STORAGE_KEY);
              navigate('/dashboard');
            }}
          >
            <Avatar
              size="m"
              firstName={user?.first_name}
              lastName={user?.last_name}
              src={user?.avatar}
              className={styles['profile-avatar']}
              theme="light"
            />
            {user.first_name} {user.last_name}
          </MenuItem>
          {teams?.map((team) => (
            <MenuItem
              key={team.id}
              className={styles['menu-item']}
              data-item-selected={currentProfileStorageParsed?.id === team.id}
              onSelect={() => {
                navigate(`/dashboard/teams/${team.id}/projects`);
              }}
            >
              <Avatar
                size="m"
                firstName={team.name}
                lastName=" "
                src={team.logo}
                className={styles['profile-avatar']}
                theme="light"
              />
              <p>{team.name}</p>
            </MenuItem>
          ))}
          <MenuItem
            className={styles[('menu-item', 'menu-item-new')]}
            onSelect={() => {
              navigate('/new-team');
            }}
          >
            <div>
              <FiPlus />
              <p>New Team</p>
            </div>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

ProfileMenu.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
};

ProfileMenu.defaultProps = {
  user: {
    teams: [],
  },
};
