import { FiLayout } from 'react-icons/fi';

export const editorOptions = {
  visualEditor: {
    value: 'visual editor',
    icon: FiLayout,
    isAvailableInSidebar: true,
    title: 'Visual Editor',
  },
};
