import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useEditor, useEditorDispatch } from 'pages/editor/editor-context';
import json2mjml from 'json2mjml';
import { addIdsToJson, buildTagId, getMjHeadIndex } from 'utils/mjml/util';

export function EditorPanelLeftContextProvider({ children: Children }) {
  const { fileId, componentId, teamId, tagSelected, json } = useEditor();
  const { editor, setTagSelected } = useEditorDispatch(
    teamId,
    fileId,
    componentId
  );

  const [jsonState, setJsonState] = useState(json);

  const bodyIndex = jsonState?.children.findIndex(
    (mjmlChildren) => mjmlChildren.tagName === 'mj-body'
  );

  const bodyArray = useMemo(() => {
    return jsonState?.children[bodyIndex]?.children || [];
  }, [jsonState, bodyIndex]);

  const [bodyChildren, setBodyChildren] = useState(bodyArray);
  const bodyArrayWithIds = addIdsToJson(
    jsonState?.children[bodyIndex]
  )?.children;

  const [bodyChildrenWithIds, setBodyChildrenWithIds] =
    useState(bodyArrayWithIds);

  useEffect(() => {
    if (json) {
      setJsonState(json);
    }
    if (jsonState) {
      setBodyChildren(jsonState?.children[bodyIndex]?.children || []);
      setBodyChildrenWithIds(
        addIdsToJson(jsonState?.children[bodyIndex]).children
      );
    }
  }, [json, jsonState, bodyIndex, setJsonState]);

  // remove the ids to the json to convert it to mjml
  const removeIds = useCallback((jsonElement) => {
    const { id, children, ...rest } = jsonElement;
    if (children) {
      return {
        ...rest,
        children: children.map(removeIds),
      };
    }
    return rest;
  }, []);

  const updateMjmlState = useCallback(
    async (jsonElement, id) => {
      const jsonElementWithoutId = removeIds(jsonElement);
      const mjml = json2mjml(jsonElementWithoutId);
      await editor.save(mjml);

      if (id === null) return;

      setTagSelected({
        id,
        from: 'panel',
      });
    },
    [removeIds, editor, setTagSelected]
  );

  const findParents = useCallback((node, selectedId, parents) => {
    if (node?.id === selectedId) {
      return true;
    }
    if (node?.children) {
      return node?.children.some((child) => {
        const found = findParents(child, selectedId, parents);
        if (found && node.id !== selectedId) {
          parents.add(node);
        }
        return found;
      });
    }
    return false;
  }, []);

  const mergeCss = (fileStyles, blockStyles) => {
    try {
      const styles = fileStyles + blockStyles;
      // Parse the CSS
      const parser = new DOMParser();
      const htmlDocument = parser.parseFromString('<html></html>', 'text/html');
      const styleElement = htmlDocument.createElement('style');
      styleElement.innerHTML = styles;
      htmlDocument.head.appendChild(styleElement);
      const { sheet } = styleElement;

      // Object to hold media queries and their rules
      const mediaQueries = {};
      const newSheetRules = [];

      Array.from(sheet.cssRules).forEach((rule) => {
        if (rule.type === CSSRule.STYLE_RULE) {
          // Direct style rules can be added directly if not duplicates
          if (!newSheetRules.includes(rule.cssText)) {
            newSheetRules.push(rule.cssText);
          }
        } else if (rule.type === CSSRule.MEDIA_RULE) {
          const { mediaText } = rule.media;
          if (!mediaQueries[mediaText]) {
            mediaQueries[mediaText] = [];
          }

          Array.from(rule.cssRules).forEach((subRule) => {
            if (!mediaQueries[mediaText].includes(subRule.cssText)) {
              mediaQueries[mediaText].push(subRule.cssText);
            }
          });
        }
      });

      const css = newSheetRules
        .join('\n\t')
        .replaceAll('{', '{\n\t\t')
        .replaceAll('}', '\n\t}\n');
      const mediaCss = Object.entries(mediaQueries)
        .map(([media, rules]) => {
          const mediaRules = rules
            .join('\n\t\t')
            .replaceAll('{', '{\n\t\t\t')
            .replaceAll('}', '\n\t\t}\n');
          return `\n\t@media ${media} {\n\t\t${mediaRules}\n\t}\n`;
        })
        .join('');

      return css + mediaCss;
    } catch (error) {
      console.error('Error adding custom css: ', error);
      return fileStyles;
    }
  };

  /**
   * It adds a new block to the body of the email
   * @param tag - the tag that you want to add to the body
   */
  const handleAddBlock = useCallback(
    (block) => {
      const newBodyChildren = [...bodyChildren];
      const newJson = { ...jsonState };

      // Add the css related to the new block
      const blockStyles = block?.css;
      if (blockStyles && blockStyles !== '') {
        const headIndex = getMjHeadIndex(newJson);
        const headChildren = [];
        const styleSheets = [];
        newJson.children[headIndex].children.forEach((child) => {
          if (child.tagName === 'mj-style' && !child?.attributes?.inline) {
            styleSheets.push(child);
          } else {
            headChildren.push(child);
          }
        });
        const fileStyles = styleSheets.map((sheet) => sheet.content).join(' ');
        const mergedStyles = mergeCss(fileStyles, blockStyles);
        newJson.children[headIndex].children = [
          ...headChildren,
          { tagName: 'mj-style', content: mergedStyles },
        ];
      }

      // Add block content
      const items = block.content.blockArray;
      items.forEach((item) => {
        const newItem = { ...item };
        newItem.id = buildTagId(item.tagName);
        newItem.attributes.name = block.name;
        newBodyChildren.push(newItem);
      });

      newJson.children[bodyIndex].children = newBodyChildren;

      const lastIndex = newBodyChildren.length - 1;

      setJsonState(newJson);
      setBodyChildren(newBodyChildren);
      setBodyChildrenWithIds(
        addIdsToJson(newJson.children[bodyIndex]).children
      );

      updateMjmlState(jsonState, newBodyChildren[lastIndex].id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bodyChildren, jsonState]
  );

  const sharedData = useMemo(() => {
    return {
      setBodyChildren,
      setBodyChildrenWithIds,
      bodyIndex,
      bodyChildren,
      bodyChildrenWithIds,
      jsonState,
      updateMjmlState,
      setTagSelected,
      tagSelected,
      setJsonState,
      removeIds,
      handleAddBlock,
      teamId,
      fileId,
      findParents,
    };
  }, [
    bodyIndex,
    bodyChildren,
    jsonState,
    updateMjmlState,
    setTagSelected,
    tagSelected,
    bodyChildrenWithIds,
    removeIds,
    handleAddBlock,
    teamId,
    fileId,
    findParents,
  ]);

  return (
    <EditorPanelLeftContext.Provider value={sharedData}>
      {Children}
    </EditorPanelLeftContext.Provider>
  );
}

EditorPanelLeftContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const EditorPanelLeftContext = createContext();
