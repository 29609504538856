import PropTypes from 'prop-types';
import FooterPublic from 'components/footer-public';
import HeaderPublic from 'components/header-public';

import styles from './styles.module.css';

function PublicPage({ children, displayFooter, ...rest }) {
  return (
    <>
      <HeaderPublic {...rest} />
      <main className={styles['cannoli-page']}>
        {children}
        {displayFooter && <FooterPublic />}
      </main>
    </>
  );
}

PublicPage.defaultProps = {
  children: null,
  displayFooter: true,
};

PublicPage.propTypes = {
  children: PropTypes.node,
  displayFooter: PropTypes.bool,
};

export default PublicPage;
