export const BUTTON_ATTRIBUTES = [
  'href',
  'width',
  'height',
  'align',
  'background-color',
  'color',
  'container-background-color',
  'css-class',
  'font-family',
  'font-size',
  'font-style',
  'font-weight',
  'inner-padding',
  'letter-spacing',
  'line-height',
  'padding',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'padding-top',
  'border',
  'border-bottom',
  'border-left',
  'border-right',
  'border-top',
  'border-radius',
  'rel',
  'target',
  'text-align',
  'text-decoration',
  'text-transform',
  'title',
  'vertical-align',
];
