import PublicPage from 'containers/public-page';
import ResourcesSection from 'pages/cannoli-app/resources-section';
import divider from 'images/home/divider.png';
import WorkflowSection from './workflow-section';
import PricingSection from './pricing-section';
import SuggestFeatureSection from './suggest-feature-section';
import HelpSection from './help-section';
import FeaturesSection from './features-section';
import HeroSection from './hero-section';

function CannoliApp() {
  return (
    <PublicPage>
      <HeroSection />
      <img src={divider} alt="Divider" width="100%" />
      <FeaturesSection />
      <HelpSection />
      <PricingSection />
      <WorkflowSection />
      <ResourcesSection />
      <SuggestFeatureSection />
    </PublicPage>
  );
}

export default CannoliApp;
