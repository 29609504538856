import PropTypes from 'prop-types';

import TeamMemberForm from 'containers/team-member-form';
import TeamMembersTable from 'containers/team-members-table';

import styles from './style.module.css';

function TeamMembersNoSubscription({ teamMembers, teamId, members }) {
  return (
    <div className={styles['dashboard-team-members']}>
      <div className={styles['team-members-header-container']}>
        <h2>Members ({teamMembers})</h2>
      </div>
      <TeamMemberForm teamId={teamId} />
      <section className={styles['team-members-list']}>
        <TeamMembersTable teamId={teamId} members={members} />
      </section>
    </div>
  );
}

TeamMembersNoSubscription.propTypes = {
  teamMembers: PropTypes.number.isRequired,
  teamId: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})),
};

TeamMembersNoSubscription.defaultProps = {
  members: [],
};

export default TeamMembersNoSubscription;
