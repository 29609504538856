import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  FiAlertCircle,
  FiAlertTriangle,
  FiCheckCircle,
  FiX,
} from 'react-icons/fi';

import styles from './style.module.css';

const getHierarchyIcon = (hierarchy) => {
  switch (hierarchy) {
    case 'success':
      return FiCheckCircle;
    case 'warning':
      return FiAlertTriangle;
    case 'danger':
    case 'alert':
    default:
      return FiAlertCircle;
  }
};

export default function Alert({ className, hierarchy, children, title }) {
  const [show, setShow] = useState(true);
  const customClassName = classnames(
    styles.container,
    styles.alert,
    styles[`-${hierarchy}`],
    className
  );

  const Icon = getHierarchyIcon(hierarchy);

  if (!show) return null;

  return (
    <div className={customClassName}>
      <header className={styles.header}>
        <Icon className={styles.icon} />
        <div className={styles.title}>{title}</div>

        <FiX
          className={styles['close-button']}
          onClick={() => setShow(false)}
        />
      </header>
      <div className="content">{children}</div>
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  hierarchy: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

Alert.defaultProps = {
  className: null,
  hierarchy: null,
  children: null,
};
