import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './style.module.css';

function PrivacyPolicyFooter({ className }) {
  const customClassName = classnames(
    styles['privacy-policy-footer'],
    className
  );
  return (
    <footer className={customClassName}>
      <Link className="font-block-s-sb" to="/policies/privacy">
        Privacy Policy
      </Link>
    </footer>
  );
}

PrivacyPolicyFooter.propTypes = {
  className: PropTypes.string,
};

PrivacyPolicyFooter.defaultProps = {
  className: '',
};

export default PrivacyPolicyFooter;
