import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import styles from '../styles.module.css';
import ChildrenTag from './children-tag';

export default function DraggableChildrenTag({ index, section, ...rest }) {
  return (
    <Draggable draggableId={index.toString()} index={index}>
      {(provided) => (
        <div
          className={styles['body-children']}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <ChildrenTag section={section} itemIndex={index} {...rest} />
        </div>
      )}
    </Draggable>
  );
}

DraggableChildrenTag.propTypes = {
  index: PropTypes.number.isRequired,
  section: PropTypes.shape({}).isRequired,
};
