/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { getStylesheetFromRawCss } from 'utils';

/* eslint-disable import/no-unresolved */
import typoTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.typography.css';
import colorTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.colors.css';
import colorTokensDark from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/dark-theme/design-tokens.dark-colors.css';
import spacingTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.spacing.css';
import borderTokens from '!!raw-loader!@scalero-inc/forno-design-tokens/styles/design-tokens.border.css';
import buttonSizesRawCss from '!!raw-loader!@scalero-inc/forno-icon-button/styles/icon-button.sizes.css';
import buttonPrimaryRawCss from '!!raw-loader!@scalero-inc/forno-icon-button/styles/icon-button.primary.css';
import buttonTertiaryRawCss from '!!raw-loader!@scalero-inc/forno-icon-button/styles/icon-button.tertiary.css';
import buttonRawCss from '!!raw-loader!@scalero-inc/forno-icon-button/styles/icon-button.css';
import buttonDangerRawCss from '!!raw-loader!@scalero-inc/forno-icon-button/styles/icon-button.danger.css';
import rawInsertSectionCss from '!!raw-loader!../insert-section/styles.module.css';
import insertSectionStyles from '../insert-section/styles.module.css';
import rawReplaceSectionCss from '!!raw-loader!../replace-section/styles.module.css';
import replaceSectionStyles from '../replace-section/styles.module.css';
import rawErrorMessageCss from '!!raw-loader!../error-message/styles.module.css';
import errorMessageStyles from '../error-message/styles.module.css';
import rawHighlightBodyCss from '!!raw-loader!../highlight-body/styles.module.css';
import highlightBodyStyles from '../highlight-body/styles.module.css';
/* eslint-enable import/no-unresolved */

import styles from './style.module.css';

function RebasePreviewIframe({ children, disableLinks }) {
  function DisableAnchorTags({ document }) {
    const anchors = document.querySelectorAll('a');

    anchors.forEach((item) => {
      item.removeAttribute('href');
    });

    return null;
  }

  DisableAnchorTags.propTypes = {
    document: PropTypes.shape({
      querySelectorAll: PropTypes.func,
    }).isRequired,
  };

  const insertSectionStylesheet = getStylesheetFromRawCss(
    insertSectionStyles,
    rawInsertSectionCss
  );
  const replaceSectionStylesheet = getStylesheetFromRawCss(
    replaceSectionStyles,
    rawReplaceSectionCss
  );
  const errorMessageStylesheet = getStylesheetFromRawCss(
    errorMessageStyles,
    rawErrorMessageCss
  );
  const highlightBodyStylesheet = getStylesheetFromRawCss(
    highlightBodyStyles,
    rawHighlightBodyCss
  );

  return (
    <Frame className={styles['iframe-wrapper']} name="rebase preview">
      <style>{typoTokens}</style>
      <style>{colorTokens}</style>
      <style>{colorTokensDark}</style>
      <style>{spacingTokens}</style>
      <style>{borderTokens}</style>
      <style>{buttonSizesRawCss}</style>
      <style>{buttonPrimaryRawCss}</style>
      <style>{buttonTertiaryRawCss}</style>
      <style>{buttonRawCss}</style>
      <style>{buttonDangerRawCss}</style>
      <style>{errorMessageStylesheet}</style>
      <style>{insertSectionStylesheet}</style>
      <style>{replaceSectionStylesheet}</style>
      <style>{highlightBodyStylesheet}</style>
      <FrameContextConsumer>
        {({ window, document }) => {
          return (
            <>
              {React.Children.map(children, (child) => {
                if (child) {
                  return React.cloneElement(child, {
                    window,
                    document,
                  });
                }
                return null;
              })}
              {disableLinks ? <DisableAnchorTags document={document} /> : null}
            </>
          );
        }}
      </FrameContextConsumer>
    </Frame>
  );
}

RebasePreviewIframe.propTypes = {
  children: PropTypes.node,
  disableLinks: PropTypes.bool,
};

RebasePreviewIframe.defaultProps = {
  children: null,
  disableLinks: true,
};

export default RebasePreviewIframe;
