import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import WelcomeModal from 'containers/welcome-modal';
import HeaderPrivate from 'containers/header-private';

import SEO from 'components/seo';
import LoadingFullPage from 'components/loading-full-page';
import Alert from 'components/alert';

import NotFound from 'pages/not-found';

import { useFileQuery } from 'hooks/api/files';
import { useComponentQuery, useTeamComponentQuery } from 'hooks/api/components';

import { generateMjmlFromComponents } from 'utils/mjml/util';

import EditorHeaderContents from './editor-header-contents';
import EditorHeader from './editor-header';
import { EditorProvider } from './editor-context';
import styles from './style.module.css';
import EditorSidebar from './editor-sidebar';
import VisualEditorContent from './visual-editor-content';
import CodeEditorContent from './code-editor-content';
import RebaseContent from './rebase-content';
import { RebaseProvider } from './rebase-context';

function Editor() {
  const [editorView, setEditorView] = React.useState('visual');
  const [feature, setFeature] = React.useState('layout');
  const { fileId, blockId, teamId } = useParams();
  const isEditingFile = !!fileId;
  const [searchParams] = useSearchParams();
  const sh = searchParams.get('sh');
  const fileQuery = useFileQuery({
    fileId,
    sh,
    config: {
      refetchOnWindowFocus: false,
      enabled: !!fileId,
    },
  });
  const userComponentQuery = useComponentQuery({
    componentId: blockId,
    config: {
      refetchOnWindowFocus: false,
      enabled: !!blockId && teamId === 'personal',
    },
  });
  const teamComponentQuery = useTeamComponentQuery({
    teamId,
    componentId: blockId,
    config: {
      refetchOnWindowFocus: false,
      enabled: !!blockId && teamId !== 'personal',
    },
  });
  const componentQuery =
    teamId === 'personal' ? userComponentQuery : teamComponentQuery;
  const query = fileId ? fileQuery : componentQuery;

  if (query.isError) {
    return <NotFound />;
  }

  if (
    query.isLoading ||
    query.isFetching ||
    (query.data?.successfully_processed === null && fileId > 1344) // The file id condition will be removed once the backend modifies the data for old files
  )
    return (
      <LoadingFullPage>
        <SEO name="Editor" />
        <h1>Loading the editor</h1>
      </LoadingFullPage>
    );

  const getData = () => {
    // Loading cannoli file
    if (fileId) {
      return {
        name: query.data?.name,
        templateId: query.data?.template,
        teamId: query.data?.project
          ? query.data.project.team_id
          : query.data?.team,
        fileId,
        figmaId: query.data?.figmaId,
        url: query.data?.url,
        node: query.data?.node,
        frame: query.data?.node,
        mjml: query.data?.mjml || query.data?.parsed_mjml,
        missingFonts: query.data?.font_variants,
        isCopy: query.data?.name.startsWith('Copy of'),
      };
    }

    // Loading component
    const { file, name, content, virtual_mj_head: head } = query.data;
    const mjml = generateMjmlFromComponents(content?.blockArray || [], head);
    const team = teamId === 'personal' ? null : +teamId;

    return {
      sourceFileId: file.toString(),
      name,
      teamId: team,
      componentId: blockId,
      mjml,
    };
  };

  const data = getData();

  const editorViewsOptions = {
    visual: (
      <VisualEditorContent
        editorView={editorView}
        feature={feature}
        setFeature={setFeature}
      />
    ),
    code: <CodeEditorContent />,
    rebase: (
      <RebaseProvider>
        <RebaseContent setEditorView={setEditorView} />
      </RebaseProvider>
    ),
  };

  return (
    <EditorProvider {...data}>
      <SEO name={data.name} />
      {isEditingFile && <WelcomeModal />}
      <div className={styles['editor-container']}>
        <EditorSidebar
          setEditorView={setEditorView}
          setFeature={setFeature}
          feature={feature}
          editorView={editorView}
        />
        <div className={styles['editor-page-container']}>
          <HeaderPrivate withLogo={false}>
            <EditorHeaderContents
              editorView={editorView}
              setEditorView={setEditorView}
            />
          </HeaderPrivate>
          <div className={styles['alert-container']}>
            {!isEditingFile && editorView === 'code' && (
              <Alert
                title="While editing a block, be sure to edit only the mj-body element."
                hierarchy="warning"
              />
            )}
            {!query.data?.successfully_processed && isEditingFile && (
              <Alert
                title="There was an error parsing your file."
                hierarchy="danger"
              />
            )}
          </div>

          {blockId && editorView === 'visual' ? null : (
            <EditorHeader editorView={editorView} />
          )}

          <div className={styles['editor-page-content']}>
            {editorViewsOptions[editorView]}
          </div>
        </div>
      </div>
    </EditorProvider>
  );
}

Editor.propTypes = {};

Editor.defaultProps = {};

export default Editor;
