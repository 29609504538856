import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useState, useCallback } from 'react';

import { plans } from 'containers/dashboard-billing/constants';
import SubscriptionStarterToProModal from 'containers/subscription-starter-to-pro-modal';

import { useProSubscriptionCreateMutation } from 'hooks/api/subscriptions';

import PricingCardBase from '../pricing-card-base';

function PricingCardPro({ activePlan, type }) {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const Icon = plans.pro.icon;
  let message;
  if (type === 'update') message = 'Change to pro';
  if (type === 'create') message = 'Choose pro';
  if (activePlan) message = 'Current Plan';

  const cta = (
    <>
      <Icon /> {message}
    </>
  );

  const { mutate: createProSub } = useProSubscriptionCreateMutation({
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      OnError: () =>
        toast.error(
          'There was an error creating your subscription. Please try again later.'
        ),
    },
  });

  // Depending on where the card renders, the button actions will be different:
  // you can either update or create a subscription, and if there is a team associated,
  // we make a point of directing them to that specific plan
  const handleClick = () => {
    if (type === 'update') {
      open();
    } else {
      createProSub();
    }
  };

  return (
    <>
      <SubscriptionStarterToProModal isOpen={isOpen} close={close} />
      <PricingCardBase
        plan={plans.pro}
        activePlan={activePlan}
        cta={cta}
        onClick={handleClick}
        ctaLook="outline"
      />
    </>
  );
}

PricingCardPro.propTypes = {
  activePlan: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

PricingCardPro.defaultProps = {
  activePlan: false,
};

export default PricingCardPro;
