import React, { useState } from 'react';

import PropTypes from 'prop-types';

import axios from 'axios';
import Button from '@scalero-inc/forno-button';
import { Field, Form, Formik } from 'formik';
import { FiCheckCircle } from 'react-icons/fi';

import styles from './style.module.css';

function InputWithButton({ buttonLabel, buttonSubmittedLabel, placeholder }) {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const payload = {
        ...values,
        date: new Date().toJSON(),
        source: window?.location?.href,
      };
      axios
        // Remove Content-Type to make Zapier work
        .create({
          transformRequest: [(data) => JSON.stringify(data)],
        })
        .post(
          'https://hooks.zapier.com/hooks/catch/10373033/b68c3ss/',
          payload
        );
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
        resetForm();
      }, 3000);
    } catch (e) {
      console.error(e);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
      {({ isSubmitting, handleChange, handleBlur, values }) => (
        <Form className={styles.form}>
          <div className={styles['input-container']}>
            <Field
              type="email"
              name="email"
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.email}
              className={styles.input}
            />
            <Button
              type={submitted ? 'text-button' : 'submit'}
              disabled={isSubmitting}
              className={styles['input-button']}
              icon={submitted ? FiCheckCircle : null}
            >
              {submitted ? buttonSubmittedLabel : buttonLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

InputWithButton.propTypes = {
  buttonLabel: PropTypes.string,
  buttonSubmittedLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

InputWithButton.defaultProps = {
  buttonLabel: 'Subscribe',
  buttonSubmittedLabel: 'Subscribed',
  placeholder: 'Email Address',
};

export default InputWithButton;
