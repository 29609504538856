import PropTypes from 'prop-types';
import { CgTrash } from 'react-icons/cg';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';

import {
  useFileDeleteMutation,
  useFilesPaginatedInvalidateQuery,
} from 'hooks/api/files';

function FileDeleteModal({
  id,
  name,
  numFiles,
  page,
  setPage,
  isOpen,
  onDismiss,
}) {
  const invalidateFiles = useFilesPaginatedInvalidateQuery();
  const { mutate, isLoading } = useFileDeleteMutation({
    id,
    config: {
      onSuccess: () => {
        onDismiss();
        // In case the file is the last of the page, go back one page
        if (page !== 1 && numFiles === 1) {
          setPage(page - 1);
        } else {
          invalidateFiles();
        }
        toast.success('File deleted successfully');
      },
      onError: () => {
        toast.error('Error deleting file');
      },
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      status="danger"
      aria-label="delete file warning"
      theme="light"
      dangerouslyBypassFocusLock
    >
      <Modal.Header
        title={`Delete ${name}`}
        onDismiss={onDismiss}
        icon={CgTrash}
      />
      <p>
        Are you sure you want to delete the file <strong>{name}</strong>?
      </p>
      <p>
        This action <strong>cannot</strong> be undone.
      </p>
      <Modal.Footer>
        <Button theme="light" hierarchy="tertiary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          status="danger"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            mutate(id);
          }}
        >
          <CgTrash />
          <span>Delete</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

FileDeleteModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  numFiles: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

FileDeleteModal.defaultProps = {
  isOpen: false,
};
export default FileDeleteModal;
