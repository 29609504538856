import figmaIcon from 'images/cannoli-app/icons/figma.png';
import visualEditingIcon from 'images/cannoli-app/icons/visual-editing.png';
import fontsIcon from 'images/cannoli-app/icons/fonts.png';
import espIcon from 'images/cannoli-app/icons/esp.png';
import sendTestIcon from 'images/cannoli-app/icons/send-test.png';
import imageHosting from 'images/cannoli-app/icons/image-hosting.png';

export const features = [
  {
    title: 'Figma to Code',
    description: 'Design on Figma, let Cannoli do the rest.',
    image: {
      src: figmaIcon,
      alt: 'Figma to Code',
    },
  },
  {
    title: 'Visual Editing',
    description: 'Edit in Cannoli without coding. Update content effortlessly.',
    image: {
      src: visualEditingIcon,
      alt: 'Visual Editing',
    },
  },
  {
    title: 'AI Font Stacks',
    description:
      'Forget font rendering issues. Cannoli ensures web-safe fallback fonts.',
    image: {
      src: fontsIcon,
      alt: 'AI Font Stacks',
    },
  },
  {
    title: 'ESP Integrations',
    description:
      'No copy/pasting. Export your email-ready code to Mailchimp or Klaviyo.',
    image: {
      src: espIcon,
      alt: 'ESP Integrations',
    },
  },
  {
    title: 'Send Test Emails',
    description:
      'Edit and send tests in one spot without going back into your ESP.',
    image: {
      src: sendTestIcon,
      alt: 'Send Test Emails',
    },
  },
  {
    title: 'Image Hosting',
    description:
      'Directly exported from your Figma file into a stable cloud network at 2x the original size.',
    image: {
      src: imageHosting,
      alt: 'Image Hosting',
    },
  },
];
