export async function templatesPaginatedQuery({
  client,
  teamId,
  projectId,
  queryOrder,
  queryPage,
  pageSize,
}) {
  const queryPageSize = pageSize ? `&page_size=${pageSize}` : '';
  const { data } = await client.get(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/?ordering=${queryOrder}&page=${queryPage}${queryPageSize}`
      : `templates/?ordering=${queryOrder}&page=${queryPage}${queryPageSize}`
  );
  return data;
}

export async function templatesQuery({ client, teamId, projectId }) {
  const { data } = await client.get(
    teamId ? `teams/${teamId}/projects/${projectId}/templates/` : `templates/`
  );
  const { results } = data;
  return results;
}

export async function templateQuery({ client, teamId, projectId, templateId }) {
  const { data } = await client.get(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}/`
      : `templates/${templateId}/`
  );
  return data;
}

export async function templateComponentsQuery({
  client,
  teamId,
  projectId,
  templateId,
}) {
  const { data } = await client.get(
    teamId
      ? `teams/${teamId}/projects/${projectId}/templates/${templateId}/component_templates/`
      : `templates/${templateId}/component_templates/`
  );
  return data;
}
