import PropTypes from 'prop-types';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import Button from '@scalero-inc/forno-button';

import {
  useUserQuery,
  useUserEditAvatarMutation,
  useUserInvalidateQuery,
} from 'hooks/api/users';

import UserAvatar from 'containers/user-avatar';
import FileUpload from 'components/file-upload';

import styles from './style.module.css';

function UserAvatarForm({ className }) {
  const customClassName = classnames(
    'user-avatar-form',
    styles['user-avatar-form'],
    className,
    {}
  );
  const userQuery = useUserQuery();
  const invalidate = useUserInvalidateQuery();
  const { mutate, isLoading } = useUserEditAvatarMutation({
    config: {
      onSuccess: () => {
        toast.success('User Avatar saved');
        invalidate();
      },
    },
  });

  if (userQuery.isLoading) return null;
  const { first_name: firstName, last_name: lastName } = userQuery.data;

  return (
    <section className={customClassName}>
      <UserAvatar round size="6" user={userQuery.data} />
      <div className={styles['user-avatar-info']}>
        <h2>
          {firstName} {lastName}
        </h2>
        <FileUpload
          onFile={mutate}
          disabled={isLoading}
          hierarchy="primary"
          size="m"
        >
          Upload Photo
        </FileUpload>
        <Button
          theme="light"
          size="xs"
          hierarchy="tertiary"
          onClick={() => {
            mutate();
          }}
        >
          Remove Photo
        </Button>
      </div>
    </section>
  );
}

UserAvatarForm.propTypes = {
  className: PropTypes.string,
};

UserAvatarForm.defaultProps = {
  className: null,
};

export default UserAvatarForm;
