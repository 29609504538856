import { useCallback, useEffect, useState } from 'react';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import { FiCheck } from 'react-icons/fi';
import supportTeam from 'images/support-team.png';

import styles from './styles.module.css';

const WELCOME_TO_CANNOLI_KEY = 'WELCOME_TO_CANNOLI';

function WelcomeModal() {
  const [skipMessage, setSkipMessage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => {
    if (skipMessage) {
      localStorage.setItem(WELCOME_TO_CANNOLI_KEY, 'FALSE');
    }
    setIsOpen(false);
  }, [skipMessage]);

  const goToHelpCenter = () => {
    const redirectUrl = `/dashboard/help-center/resources`;
    window.open(redirectUrl, '_blank');
  };

  const goToCannoliServices = () => {
    const redirectUrl = `/services`;
    window.open(redirectUrl, '_blank');
  };

  useEffect(() => {
    const showMessage = localStorage.getItem(WELCOME_TO_CANNOLI_KEY);
    if (!showMessage) {
      setIsOpen(true);
    }
  }, []);

  return (
    <Modal
      theme="dark"
      isOpen={isOpen}
      onDismiss={close}
      aria-label="welcome modal"
    >
      <Modal.Header theme="dark" onDismiss={close} title="Welcome ✨" />

      <Modal.Content>
        <div className={styles['message-container']}>
          <div className={styles['support-team']}>
            <img alt="Support Team" src={supportTeam} width="220" />
            <span>We are here to help you!</span>
          </div>
          <p>
            Stuck with your file conversion? Visit the help center, or join our{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://join.slack.com/share/enQtNTg3OTk0MDExMjYzMC1jYmIzYWRjM2EzOTE0MzM3OThiYjlkZmZmZGI1NmJjNjRmN2UxYjg5NjFkYTNkYzVkMmFmYTVjZGE5ZDE0N2Y3"
            >
              Slack channel
            </a>{' '}
            to get direct assistance.
            <br />
            <br />
            Or if you want us to get your email to the finish line, check out
            our services.
          </p>
        </div>
      </Modal.Content>

      <Modal.Footer>
        <div className={styles['footer-container']}>
          <div
            className={styles['checkbox-container']}
            data-checked={skipMessage}
          >
            <input
              id="help_center"
              type="checkbox"
              className={styles.checkbox}
              onChange={() => setSkipMessage((prevValue) => !prevValue)}
            />
            <FiCheck className={styles['checkbox-check']} size={10} />
            <label htmlFor="help_center">Don&#39;t show this again</label>
          </div>

          <div className={styles['options-container']}>
            <Button theme="dark" hierarchy="tertiary" onClick={goToHelpCenter}>
              Help center
            </Button>
            <Button
              theme="dark"
              hierarchy="primary"
              onClick={goToCannoliServices}
            >
              Explore Services
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default WelcomeModal;
