import PropTypes from 'prop-types';
import { useCallback } from 'react';
import {
  useTeamFontStacksQuery,
  useUserFontStacksQuery,
} from 'hooks/api/fonts';
import Spinner from '@scalero-inc/forno-spinner';
import Button from '@scalero-inc/forno-button';
import IconButton from '@scalero-inc/forno-icon-button';
import { FiGlobe, FiTrash2, FiUser } from 'react-icons/fi';
import { ReactComponent as GoogleIcon } from 'svg/icons/google.svg';
import GoogleFontsFilter from '../../components/google-fonts-filter';

import styles from './styles.module.css';

function FontsTable({
  teamId,
  theme,
  onDeleteFont,
  onAddGoogleFont,
  onEditStack,
}) {
  const {
    data: userFonts,
    isLoading: isUserFontsLoading,
    isFetching: isUserFontsFetching,
    isError: isUserFontsError,
  } = useUserFontStacksQuery({
    config: {
      refetchOnWindowFocus: false,
      enabled: !teamId,
    },
  });

  const {
    data: teamFonts,
    isLoading: isTeamFontsLoading,
    isFetching: isTeamFontsFetching,
    isError: isTeamFontsError,
  } = useTeamFontStacksQuery({
    teamId,
    config: {
      refetchOnWindowFocus: false,
      enabled: !!teamId,
    },
  });

  const data = teamId ? teamFonts : userFonts;
  const isLoading = teamId ? isTeamFontsLoading : isUserFontsLoading;
  const isFetching = teamId ? isTeamFontsFetching : isUserFontsFetching;
  const isError = teamId ? isTeamFontsError : isUserFontsError;

  const addGoogleFont = useCallback(
    (font) => {
      const weights = font.variants
        .map((fontVariant) => fontVariant.variant)
        .join(', ');
      const googleFont = { ...font, weights };
      onAddGoogleFont(googleFont);
    },
    [onAddGoogleFont]
  );

  const editStack = (fontStack) => {
    const weights = fontStack.font_variants
      .map((variant) => variant)
      .join(', ');
    const fontToEdit = {
      id: fontStack.id,
      family: fontStack.font_family,
      weights,
      fallback: {
        name: fontStack.name,
        stack: fontStack.stack,
      },
    };

    onEditStack(fontToEdit);
  };

  return (
    <div className={styles['dashboard-fonts-container']}>
      <GoogleFontsFilter onSelectFont={addGoogleFont} theme={theme} />

      <div className={styles['fonts-table-container']}>
        <table className={styles['fonts-table']}>
          <tbody>
            <tr>
              <th />
              <th>Font family</th>
              <th>Weights</th>
              <th>Fallback name</th>
              <th>Fallback stack</th>
              <th className={styles.center}>Edit</th>
              <th />
            </tr>

            {isLoading ||
              (isFetching && (
                <tr>
                  <td className={styles.center} colSpan={6}>
                    <Spinner />
                  </td>
                </tr>
              ))}

            {!isLoading && !isFetching && isError && (
              <tr>
                <td className={styles.center} colSpan={6}>
                  Something went wrong, try again later.
                </td>
              </tr>
            )}

            {!isLoading &&
              !isFetching &&
              data?.results &&
              data?.results.length === 0 && (
                <tr>
                  <td className={styles.center} colSpan={6}>
                    You don&rsquo;t have any fallback stack added yet.
                  </td>
                </tr>
              )}

            {!isLoading &&
              !isFetching &&
              !isError &&
              data?.results &&
              data?.results.map((fontStack, fontStackIndex) => {
                const weights =
                  fontStack.font_variants.length > 0
                    ? fontStack.font_variants
                        .map((variant) => variant)
                        .join(', ')
                    : ' - - ';

                return (
                  <tr key={`fontStack_${fontStackIndex}`}>
                    <td>
                      {fontStack.google_font && (
                        <GoogleIcon className={styles['google-icon']} />
                      )}
                      {fontStack.safe_font && <FiGlobe size={16} />}
                      {!fontStack.google_font && !fontStack.safe_font && (
                        <FiUser size={16} />
                      )}
                    </td>
                    <td>{fontStack.font_family}</td>
                    <td className={styles['font-weight']}>{weights}</td>
                    <td>{fontStack.name}</td>
                    <td>
                      {fontStack.stack.map((font) => font.family).join(', ')}
                    </td>
                    <td className={styles['center-button']}>
                      <Button
                        theme={theme}
                        hierarchy="tertiary"
                        size="s"
                        onClick={() => editStack(fontStack)}
                      >
                        Edit stack
                      </Button>
                    </td>
                    <td>
                      <IconButton
                        theme={theme}
                        hierarchy="tertiary"
                        onClick={() => onDeleteFont(fontStack)}
                      >
                        <FiTrash2 />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

FontsTable.propTypes = {
  teamId: PropTypes.number,
  theme: PropTypes.oneOf(['light', 'dark']),
  onDeleteFont: PropTypes.func.isRequired,
  onAddGoogleFont: PropTypes.func.isRequired,
  onEditStack: PropTypes.func.isRequired,
};

FontsTable.defaultProps = {
  theme: 'light',
  teamId: null,
};

export default FontsTable;
