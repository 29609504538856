import { useQuery } from 'react-query';
import { useFetch } from 'context/fetch';

import { FILE_QUERY_KEY } from 'hooks/api/figma/constants';
import { fileQuery } from './queries';

export function useFileQuery(props = {}) {
  const { file, config = {}, key = FILE_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery([key, file], () => fileQuery({ client, file }), config);
}
