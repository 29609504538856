export async function fontsQuery({ client, family }) {
  const { data } = await client.get(`fonts/google_fonts/?family=${family}`);
  return data;
}

export async function userFontStacksQuery({ client }) {
  const { data } = await client.get('user/font_stack/');
  return data;
}

export async function teamFontStacksQuery({ client, teamId }) {
  const { data } = await client.get(`teams/${teamId}/font_stack`);
  return data;
}

export async function fontVariantsQuery({ client, teamId }) {
  const teamQuery = teamId ? `?team_id=${teamId}` : '';
  const { data } = await client.get(`fonts/variants/${teamQuery}`);
  return data;
}

export async function webSafeFontsQuery({ client }) {
  const { data } = await client.get('fonts/safe_fonts/');
  return data;
}
