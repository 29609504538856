import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'context/fetch';
import { toast } from 'react-toastify';

import {
  FILES_QUERY_KEY,
  FILES_PROCESSED_QUERY_KEY,
  FILE_QUERY_KEY,
  FILES_BY_TEAM_PROJECT_QUERY_KEY,
  FILE_SHARE_HASH_QUERY_KEY,
  FILES_PAGINATED_QUERY_KEY,
} from 'hooks/api/constants';
import {
  filesQuery,
  fileQuery,
  fileWaitForFileProcessedQuery,
  filesByTeamProjectQuery,
  fileShareHashQuery,
  filesPaginatedQuery,
} from './queries';
import {
  fileBackendProcessMutation,
  fileCreateMutation,
  fileDeleteMutation,
  fileProcessMutation,
  fileRefreshMutation,
  fileUpdateMutation,
  updatePreviewMutation,
  fileDuplicateMutation,
  fileExportToEspMutation,
  fileSendTestEmailMutation,
  fileShareHashCreateMutation,
  fileShareHashDeleteMutation,
  uploadImageMutation,
  fileCreateFromTemplateMutation,
} from './mutations';

export function useFilesQuery(props = {}) {
  const { config = {}, key = FILES_QUERY_KEY, queryOrder } = props;
  const { client } = useFetch();
  return useQuery(
    [key, queryOrder],
    () => filesQuery({ client, queryOrder }),
    config
  );
}

export function useFilesPaginatedQuery(props = {}) {
  const {
    config = {},
    key = FILES_PAGINATED_QUERY_KEY,
    queryOrder,
    queryPage,
    pageSize,
    teamId,
    projectId,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, queryOrder, queryPage, pageSize, teamId, projectId],
    () =>
      filesPaginatedQuery({
        client,
        queryOrder,
        queryPage,
        pageSize,
        teamId,
        projectId,
      }),
    config
  );
}

export function useFileQuery(props = {}) {
  const { fileId, sh, key = FILE_QUERY_KEY, config = {} } = props;
  const { client } = useFetch();
  return useQuery(
    [key, fileId],
    () => fileQuery({ client, fileId, sh }),
    config
  );
}

export function useWaitForFileProcessedQuery(props = {}) {
  const { fileId, key = FILES_PROCESSED_QUERY_KEY, config = {} } = props;
  const { client } = useFetch();
  return useQuery(
    [key, fileId],
    () => fileWaitForFileProcessedQuery({ client, fileId }),
    config
  );
}

export function useFileCreateMutation(props = {}) {
  const { config, team, project } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => fileCreateMutation({ client, values, team, project }),
    config
  );
}

export function useFileCreateFromTemplateMutation(props = {}) {
  const { config, teamId, projectId, templateId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) =>
      fileCreateFromTemplateMutation({
        client,
        values,
        templateId,
        teamId,
        projectId,
      }),
    config
  );
}

export function useFileProcessMutation(props = {}) {
  const { config, fileId } = props;
  const { client } = useFetch();
  return useMutation(() => fileProcessMutation({ client, fileId }), config);
}

export function useFileBackendProcessMutation(props = {}) {
  const { config, fileId } = props;
  const { client } = useFetch();
  return useMutation(
    () => fileBackendProcessMutation({ client, fileId }),
    config
  );
}

export function useFileRefreshMutation(props = {}) {
  const { config, fileId } = props;
  const { client } = useFetch();
  return useMutation(() => fileRefreshMutation({ client, fileId }), config);
}

export function useFileUpdateMutation(props = {}) {
  const { config, fileId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => fileUpdateMutation({ client, fileId, values }),
    config
  );
}

export function useFileDeleteMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation(
    (fileId) => fileDeleteMutation({ client, fileId }),
    config
  );
}

export function useFilesInvalidateQuery(props = {}) {
  const { key = FILES_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useFilesPaginatedInvalidateQuery(props = {}) {
  const { key = FILES_PAGINATED_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useFileInvalidateQuery(props = {}) {
  const { key = FILE_QUERY_KEY, fileId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key, fileId]);
  };
}

export function useFilePreviewMutation(props = {}) {
  const { config } = props;
  const { fileId, html } = props;
  const { client } = useFetch();

  return useMutation(
    () => updatePreviewMutation({ client, fileId, html }),
    config
  );
}

export function useFilesByTeamProjectQuery(props = {}) {
  const { config = {}, key = FILES_BY_TEAM_PROJECT_QUERY_KEY } = props;
  const { client } = useFetch();
  return useQuery([key], () => filesByTeamProjectQuery({ client }), config);
}

export function useFilesByTeamProjectInvalidateQuery(props = {}) {
  const { key = FILES_BY_TEAM_PROJECT_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useFileDuplicateMutation(props = {}) {
  const { config = {} } = props;
  const { client } = useFetch();
  return useMutation(
    (fileId) =>
      toast.promise(fileDuplicateMutation({ client, fileId }), {
        pending: 'Duplicating your file',
        success: 'File duplicated successfully',
        error: 'There was an error duplicating your file',
      }),
    config
  );
}

export function useFileExportToEspMutation(props = {}) {
  const { config = {}, fileId, name } = props;
  const { client } = useFetch();
  return useMutation(
    (espId) => fileExportToEspMutation({ client, espId, fileId, name }),
    config
  );
}

export function useFileSendTestEmailMutation(props = {}) {
  const { config = {}, fileId } = props;
  const { client } = useFetch();
  return useMutation(
    (email) => fileSendTestEmailMutation({ client, fileId, email }),
    config
  );
}

export function useFileShareHashQuery(props = {}) {
  const { config = {}, key = FILE_SHARE_HASH_QUERY_KEY, fileId } = props;
  const { client } = useFetch();
  return useQuery([key], () => fileShareHashQuery({ client, fileId }), config);
}

export function useFileShareHashCreateMutation(props = {}) {
  const { config = {}, fileId } = props;
  const { client } = useFetch();
  return useMutation(
    () => fileShareHashCreateMutation({ client, fileId }),
    config
  );
}

export function useFileShareHashDeleteMutation(props = {}) {
  const { config = {}, fileId } = props;
  const { client } = useFetch();
  return useMutation(
    () => fileShareHashDeleteMutation({ client, fileId }),
    config
  );
}

export function useFileShareHashInvalidateQuery(props = {}) {
  const { key = FILE_SHARE_HASH_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useUploadImageMutation(props = {}) {
  const { config, fileId } = props;
  const { client } = useFetch();
  return useMutation(
    (file) => uploadImageMutation({ client, file, fileId }),
    config
  );
}
