import * as yup from 'yup';
import { getFileIdfromFigmaURL } from 'utils/figma';

function isFigmaURL(value) {
  const fileId = getFileIdfromFigmaURL(value);
  return fileId != null;
}

export default yup.object().shape({
  url: yup
    .string()
    .url()
    .test('is-figma', `The URL needs to be a Figma file URL`, isFigmaURL)
    .required(),
  page: yup.string().required(),
  frame: yup.string().required(),
});
