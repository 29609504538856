import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';

function getDataId(element) {
  let id = element.dataset?.id;
  // If id doesn't exist, try to get it from parent node
  if (!id && element.parentNode) {
    id = getDataId(element.parentNode);
  }

  return id || '';
}

function ClickToNode({ document, setNodeId }) {
  const onClickListener = useCallback(
    (e) => {
      const event = e || window.event;
      const target = event.target || event.srcElement;
      const id = getDataId(target);

      if (id !== '') {
        setNodeId({
          id,
          from: 'preview',
        });
      }
    },
    [setNodeId]
  );

  useEffect(() => {
    document.addEventListener('click', onClickListener);
    return () => {
      document.removeEventListener('click', onClickListener);
    };
  }, [document, onClickListener]);

  return null;
}

ClickToNode.propTypes = {
  document: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
  setNodeId: PropTypes.func,
};

ClickToNode.defaultProps = {
  document: null,
  setNodeId: () => {},
};

export default ClickToNode;
