import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FiChevronLeft, FiImage, FiPlus, FiSave } from 'react-icons/fi';
import classnames from 'classnames';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';

import TemplatesModal from 'containers/templates-modal';
import TemplateBuilder from 'containers/template-builder';
import HelpInfo from 'containers/template-builder/help-info';

import TemplatesPreviewIframe from 'components/templates-preview-iframe';
import FormikEffect from 'components/formik-effect';
import HtmlToReact from 'components/html-to-react';

import { generateHtml } from 'utils';
import schema from './schema';

import styles from './style.module.css';
import { useTemplates } from './templates';

function TemplateForm({
  onSuccess,
  onSubmit,
  onError,
  onChange,
  className,
  team,
  project,
  template,
  initialValues,
}) {
  const customClassName = classnames(
    'template-form',
    className,
    styles['template-form']
  );

  const { blocks, setBlocks, handleSubmit, goBack, isOpen, open, close } =
    useTemplates({
      team,
      project,
      template,
      className,
      onSubmit,
      onSuccess,
      onError,
    });

  const getHtmlTemplate = (templateBlocks) => {
    const head = templateBlocks.reduce((accumulator, currentValue) => {
      if (currentValue?.virtual_mj_head)
        accumulator.push(...currentValue.virtual_mj_head);
      return accumulator;
    }, []);

    const templateContent = templateBlocks.reduce(
      (accumulator, currentValue) => {
        accumulator.push(...currentValue.content.blockArray);
        return accumulator;
      },
      []
    );

    return generateHtml(templateContent, head);
  };

  return (
    <>
      <TemplatesModal
        isOpen={isOpen}
        close={close}
        setBlocks={setBlocks}
        blocks={blocks}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        enableReinitialize
      >
        {({ isSubmitting, values }) => {
          const canSubmit = !!values.name && blocks?.length;
          return (
            <Form className={customClassName}>
              <FormikEffect onChange={onChange} />
              <div className={styles['form-container']}>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="name">Template Name</label>
                  <Field id="name" name="name">
                    {({ field, meta }) => (
                      <Input
                        {...field}
                        theme="light"
                        className={styles.field}
                        placeholder="Name of your template"
                        warningText={
                          meta.touched && meta.error ? meta.error : null
                        }
                        value={values.name}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles['field-wrapper']}>
                  <label htmlFor="description">Description</label>
                  <Field id="description" name="description" type="text">
                    {({ field }) => (
                      <Input
                        {...field}
                        theme="light"
                        className={styles.field}
                        placeholder="Template Description"
                        helperText="Optional"
                      />
                    )}
                  </Field>
                </div>

                <HelpInfo />
                <div className={styles['section-divider']} />

                {blocks?.length > 0 && (
                  <TemplateBuilder blocks={blocks} setBlocks={setBlocks} />
                )}

                <div className={styles['template-form-footer']}>
                  <Button
                    onClick={goBack}
                    icon={FiChevronLeft}
                    hierarchy="tertiary"
                    theme="light"
                    size="m"
                  >
                    Cancel
                  </Button>
                  <Button
                    icon={FiPlus}
                    hierarchy="secondary"
                    theme="light"
                    size="m"
                    onClick={open}
                  >
                    Add Blocks
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !canSubmit}
                    loading={isSubmitting}
                    icon={FiSave}
                    size="m"
                  >
                    Save Template
                  </Button>
                </div>
              </div>

              <div className={styles['preview-container']}>
                <p>Template Preview</p>
                <div className={styles['template-node-preview-container']}>
                  {blocks?.length > 0 ? (
                    <TemplatesPreviewIframe disableLinks isStatic>
                      <HtmlToReact html={getHtmlTemplate(blocks)} />
                    </TemplatesPreviewIframe>
                  ) : (
                    <div className={styles['preview-placeholder-icon']}>
                      <FiImage size={50} />
                      <p className="font-paragraph-l-sb">Template Preview</p>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

TemplateForm.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.shape({
    url: PropTypes.string,
  }),
  team: PropTypes.string,
  project: PropTypes.string,
  template: PropTypes.string,
  usedBlocks: PropTypes.arrayOf(PropTypes.number),
};

TemplateForm.defaultProps = {
  initialValues: {
    name: '',
  },
  onError: () => {},
  onSubmit: () => {},
  onSuccess: () => {},
  onChange: () => {},
  className: null,
  team: null,
  project: null,
  template: null,
  usedBlocks: [],
};

export default TemplateForm;
