import { Menu, MenuLink, MenuList, MenuButton } from '@reach/menu-button';
import PropTypes from 'prop-types';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { useCallback, useState } from 'react';
import classNames from 'classnames';

import IconButton from '@scalero-inc/forno-icon-button';

import ProjectEditModal from 'containers/project-edit-modal';
import ProjectDeleteModal from 'containers/project-delete-modal';

import styles from './style.module.css';

function ProjectActionsMenu({ name, id, team, className, ...rest }) {
  const customClassName = classNames(
    'project-card-menu',
    styles['project-card-menu'],
    className
  );
  const [editIsOpen, setEditIsOpen] = useState();
  const [deleteIsOpen, setDeleteIsOpen] = useState();
  const openEdit = useCallback(() => setEditIsOpen(true), [setEditIsOpen]);
  const closeEdit = useCallback(() => setEditIsOpen(false), [setEditIsOpen]);
  const openDelete = useCallback(
    () => setDeleteIsOpen(true),
    [setDeleteIsOpen]
  );
  const closeDelete = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );

  return (
    <div className={customClassName}>
      <ProjectEditModal
        name={name}
        id={id}
        close={closeEdit}
        isOpen={editIsOpen}
        team={team}
      />
      <ProjectDeleteModal
        name={name}
        id={id}
        onDismiss={closeDelete}
        isOpen={deleteIsOpen}
        team={team}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          theme="light"
          size="l"
          hierarchy="tertiary"
          className={styles['project-card-menu-button']}
          {...rest}
        >
          <BiDotsVerticalRounded />
        </MenuButton>
        <MenuList className={styles['project-card-menu-list']}>
          <MenuLink
            className={styles['project-card-menu-item']}
            onSelect={openEdit}
          >
            <FiEdit />
            Edit
          </MenuLink>
          <MenuLink
            className={styles['project-card-menu-item']}
            onSelect={openDelete}
          >
            <FiTrash />
            Delete
          </MenuLink>
        </MenuList>
      </Menu>
    </div>
  );
}

ProjectActionsMenu.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  team: PropTypes.number.isRequired,
  className: PropTypes.string,
  icon: PropTypes.shape({}),
};

ProjectActionsMenu.defaultProps = {
  className: null,
  icon: <BiDotsVerticalRounded />,
};

export default ProjectActionsMenu;
