import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@scalero-inc/forno-button';

import cannoliServicesPageAsset from 'images/home/cannoli-services-page-asset.png';

import styles from './styles.module.css';

function ExpertlyCodedTemplatesSection() {
  return (
    <section className={styles['services-section-wrapper']}>
      <div className={styles['services-description']}>
        <h1>Expertly created emails for your campaigns</h1>
        <p className="paragraph-l">
          Let us bring your vision into pixel-perfect reality. Our team of email
          experts will deliver production-ready email designs and/or code,
          tested on the most current devices and email clients.
        </p>
        <Link to="checkout/">
          <Button size="l">Order now</Button>
        </Link>
      </div>
      <div className={styles['services-asset-container']}>
        <div className={styles['image-wrapper']}>
          <img
            className={styles['services-image']}
            src={cannoliServicesPageAsset}
            alt="Cannoli templates"
          />
        </div>
      </div>
    </section>
  );
}

export default ExpertlyCodedTemplatesSection;
