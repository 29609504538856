import { useFetch } from 'context/fetch';
import { useMutation, useQuery } from 'react-query';
import { createOrderMutation } from './mutations';
import { readOnlyFileQuery } from './queries';

import { READ_ONLY_FILE_KEY } from '../constants';

export const useCreateOrderMutation = (props = {}) => {
  const { publicClient } = useFetch();
  const { config } = props;
  return useMutation(
    (values) => createOrderMutation({ publicClient, values }),
    config
  );
};

export const useReadOnlyFileQuery = (props = {}) => {
  const { config, key = READ_ONLY_FILE_KEY, id } = props;
  const { publicClient } = useFetch();
  return useQuery(
    [key, id],
    () => readOnlyFileQuery({ publicClient, id }),
    config
  );
};
