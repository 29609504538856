import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import InsertSection, {
  POSITION_TYPE,
} from 'pages/editor/rebase-preview/insert-section';
import ReplaceSection from 'pages/editor/rebase-preview/replace-section';
import SectionContainer from 'pages/editor/rebase-preview/section-container';
import HighlightBody from '../highlight-body';

function AddDragAndDropContainers({
  parsedHtml,
  selectedElement,
  setSelectedElement,
  onInsertElement,
  onReplaceElement,
}) {
  const [activeTag, setActiveTag] = useState(null);
  const [activeOrigin, setActiveOrigin] = useState(null);
  const [activeOriginId, setActiveOriginId] = useState(null);

  const updateActiveTag = useCallback(
    (tag, parentTag, origin) => {
      // Avoid too many renders, when dragging the same elements
      const { id, from } = selectedElement[origin];
      if (
        tag === activeTag &&
        origin === activeOrigin &&
        id === activeOriginId
      ) {
        return;
      }

      const isValid = !(
        id.includes('mj-wrapper') && parentTag.includes('mj-wrapper')
      );
      setSelectedElement({ [origin]: { id, from, isValid } });
      setActiveTag(tag);
      setActiveOrigin(origin);
      setActiveOriginId(id);
    },
    [
      activeOrigin,
      activeOriginId,
      activeTag,
      selectedElement,
      setSelectedElement,
    ]
  );

  const wrapMjmlContainers = (element, parentTagId) => {
    if (!React.isValidElement(element)) {
      return element;
    }

    const isBodyEmpty =
      element.props &&
      element.props['data-id'] &&
      element.props['data-id'].includes('mj-body') &&
      typeof element.props.children === 'string';
    if (isBodyEmpty) {
      return (
        <InsertSection
          isValid
          parentTagId="sc_mj-body_1"
          action={onInsertElement}
        />
      );
    }

    const isMjmlContainer =
      element.props &&
      element.props['data-id'] &&
      (element.props['data-id'].includes('mj-section') ||
        element.props['data-id'].includes('mj-wrapper'));
    if (isMjmlContainer) {
      const tagId = element.props['data-id'];
      const origin = Object.keys(selectedElement)[0];
      const isValid =
        selectedElement[origin]?.isValid === undefined ||
        selectedElement[origin]?.isValid;

      return (
        <SectionContainer
          setActiveTag={() => updateActiveTag(tagId, parentTagId, origin)}
        >
          {({ isDragging }) => {
            return (
              <>
                {isDragging && activeTag === tagId && (
                  <InsertSection
                    isValid={isValid}
                    tagId={tagId}
                    parentTagId={parentTagId}
                    positionType={POSITION_TYPE.BEFORE}
                    action={onInsertElement}
                  />
                )}

                {isDragging ? (
                  <ReplaceSection
                    isValid={isValid}
                    tagId={tagId}
                    action={onReplaceElement}
                  >
                    {React.cloneElement(
                      element,
                      {},
                      React.Children.map(
                        element.props.children,
                        (childElement) =>
                          wrapMjmlContainers(childElement, tagId)
                      )
                    )}
                  </ReplaceSection>
                ) : (
                  <div>
                    {React.cloneElement(
                      element,
                      {},
                      React.Children.map(
                        element.props.children,
                        (childElement) =>
                          wrapMjmlContainers(childElement, tagId)
                      )
                    )}
                  </div>
                )}

                {isDragging && activeTag === tagId && (
                  <InsertSection
                    isValid={isValid}
                    tagId={tagId}
                    parentTagId={parentTagId}
                    positionType={POSITION_TYPE.AFTER}
                    action={onInsertElement}
                  />
                )}
              </>
            );
          }}
        </SectionContainer>
      );
    }

    // Keep the element without changes
    return React.cloneElement(
      element,
      {},
      React.Children.map(element.props.children, (childElement) =>
        wrapMjmlContainers(childElement, parentTagId)
      )
    );
  };

  const html = HighlightBody({ parsedHtml });
  // Wrap mjml containers (mj-section and mj-wrapper) with an element to allow drag and drop
  const htmlWithDragAndDrop = React.Children.map(html, (element) =>
    wrapMjmlContainers(element, 'sc_mj-body_1')
  );

  return htmlWithDragAndDrop;
}

AddDragAndDropContainers.propTypes = {
  parsedHtml: PropTypes.shape({}),
  selectedElement: PropTypes.shape({
    id: PropTypes.string,
  }),
  setSelectedElement: PropTypes.func,
  onInsertElement: PropTypes.func,
  onReplaceElement: PropTypes.func,
};

AddDragAndDropContainers.defaultProps = {
  parsedHtml: null,
  interactive: false,
  selectedElement: {},
  setSelectedElement: () => {},
  onInsertElement: () => {},
  onReplaceElement: () => {},
};

export default AddDragAndDropContainers;
