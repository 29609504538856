import { DEFAULT_FALLBACKS } from 'components/fallback-stack-designer/utils';
import {
  useFontVariantsQuery,
  useTeamFontStacksQuery,
  useUserFontStacksQuery,
  useWebSafeFontsQuery,
} from 'hooks/api/fonts';
import { useEditor } from 'pages/editor/editor-context';
import { useCallback } from 'react';
import { getCustomFonts, getGoogleFonts } from 'utils/mjml/util';

function FontsDataProvider({ children }) {
  const { teamId, mjHead } = useEditor();

  // Google fonts
  const buildGoogleFontOptions = useCallback((googleFonts) => {
    return googleFonts.reduce((accumulator, font) => {
      const fontExists = accumulator.some((element) => {
        return element.label === font.family;
      });

      if (fontExists) {
        return accumulator;
      }

      return [
        ...accumulator,
        { label: font.family, value: { id: null, content: font.family } },
      ];
    }, []);
  }, []);
  const googleFonts = getGoogleFonts(mjHead.children || []);
  const googleFontOptions = buildGoogleFontOptions(googleFonts);

  // Custom fonts
  const {
    data: customFontsData,
    isLoading: isCustomFontsLoading,
    error: customFontsError,
  } = useFontVariantsQuery({
    teamId,
  });
  const buildCustomFontOptions = useCallback((fonts, fontsData) => {
    return fonts.map((font) => {
      const fontVariant = fontsData.find(
        (fontData) => fontData.family === font.family
      );
      const fontId = fontVariant ? fontVariant.font_id : null;
      return {
        label: `${font.family} (${font.weight})`,
        value: { id: fontId, content: font.family },
      };
    });
  }, []);
  const customFonts = getCustomFonts(mjHead.children || []);
  const customFontOptions = buildCustomFontOptions(
    customFonts || [],
    customFontsData?.results || []
  );

  // Web safe fonts
  const {
    data: webFontsData,
    isLoading: isWebFontsLoading,
    error: webSafeFontsError,
  } = useWebSafeFontsQuery({
    config: {
      refetchOnWindowFocus: false,
    },
  });
  const buildWebSafeOptions = useCallback((webFonts) => {
    return webFonts.map((font) => {
      return {
        label: font.family,
        value: { id: font.id, content: font.family },
      };
    });
  }, []);
  const webSafeFontOptions = buildWebSafeOptions(webFontsData?.results || []);

  // Font stacks
  const buildFontStackOptions = useCallback((fontStacks = []) => {
    const stackOptions = fontStacks.map((fontStack) => {
      const stack = fontStack.stack.map((font) => font.family).join(', ');
      const label = `${stack} (${fontStack.name})`;
      return {
        label,
        value: { id: null, content: stack, baseFont: fontStack.font_family },
      };
    });
    DEFAULT_FALLBACKS.forEach((font) =>
      stackOptions.push({
        label: font.family,
        value: { id: null, content: font.family, baseFont: '' },
      })
    );

    return stackOptions;
  }, []);
  const {
    data: userStacks,
    isLoading: isUserStacksLoading,
    error: userStacksError,
  } = useUserFontStacksQuery({
    config: {
      refetchOnWindowFocus: false,
      enabled: !teamId,
    },
  });
  const {
    data: teamStacks,
    isLoading: isTeamStacksLoading,
    error: teamStacksError,
  } = useTeamFontStacksQuery({
    teamId,
    config: {
      refetchOnWindowFocus: false,
      enabled: !!teamId,
    },
  });
  const fontStacks = teamId ? teamStacks : userStacks;
  const fontStackOptions = buildFontStackOptions(fontStacks?.results || []);
  const isStacksLoading = teamId ? isUserStacksLoading : isTeamStacksLoading;
  const stacksError = teamId ? teamStacksError : userStacksError;

  const error = webSafeFontsError || customFontsError || stacksError;
  const isLoading =
    isCustomFontsLoading || isWebFontsLoading || isStacksLoading;

  return children(
    error,
    isLoading,
    googleFontOptions,
    customFontOptions,
    webSafeFontOptions,
    fontStackOptions
  );
}

export default FontsDataProvider;
