// ! Disabled rule
/* eslint-disable no-param-reassign */

import { v4 as uuidv4 } from 'uuid';
import json2mjml from 'json2mjml';
import { GENERIC_JSON } from 'utils/constants';

export const buildTagId = (category, identifier) => {
  return `sc_${category}_${identifier}`;
};

export const getTagInfoFromId = (tagId) => {
  const idData = tagId.split('_');
  const tagName = idData[1];
  const tagIndentifier = parseInt(idData[2], 10);
  return { tagName, tagIndentifier };
};

export const getTagWithId = (mjml, tags = [], identifiers = {}) => {
  const mjmlTags = mjml.children || [];
  let localIndex = 0;
  mjmlTags.forEach((tag) => {
    const category = tag.tagName;

    if (!identifiers[category]) {
      identifiers[category] = 1;
    } else {
      identifiers[category] += 1;
    }

    tags.push({
      id: buildTagId(category, identifiers[category]),
      tag,
      parentTag: mjml,
      localIndex,
    });
    localIndex += 1;

    if (tag.children) {
      getTagWithId(tag, tags, identifiers);
    }
  });

  return tags;
};

export const addIdsToJson = (json, tags = [], identifiers = {}) => {
  if (!json?.children) return json;

  json?.children.forEach((tag) => {
    const category = tag.tagName;

    if (!identifiers[category]) {
      identifiers[category] = 1;
    } else {
      identifiers[category] += 1;
    }
    const id = buildTagId(category, identifiers[category]);
    tag.id = id;
    tags.push({
      id,
      tag,
    });

    if (tag.children) {
      addIdsToJson(tag, tags, identifiers);
    }
  });

  return json;
};

export const getContentNodesWithIds = (mjml) => {
  const body = mjml?.children.find((child) => child.tagName === 'mj-body');
  const contentTags = getTagWithId(body);
  return contentTags;
};

export const addIdsToMjml = (mjml, tags = {}) => {
  // Remove unnecessary properties (file, absoluteFilePath, id, etc...)
  const {
    file,
    line,
    parentId,
    parentType,
    depth,
    includedIn,
    absoluteFilePath,
    attributes: { id, ...attrib },
    ...tag
  } = mjml;
  const mjmlWithIds = { ...tag };
  mjmlWithIds.attributes = { ...attrib };

  const { tagName } = tag;

  if (!tags[tagName]) {
    // eslint-disable-next-line no-param-reassign
    tags[tagName] = 1;
  } else {
    // eslint-disable-next-line no-param-reassign
    tags[tagName] += 1;
  }
  const tagId = buildTagId(tagName, tags[tagName]);

  const cssClass = mjmlWithIds.attributes['css-class'];
  mjmlWithIds.attributes['css-class'] = cssClass
    ? `${tagId} ${cssClass}`
    : tagId;

  if (mjmlWithIds.children) {
    mjmlWithIds.children.forEach((childTag, childIndex) => {
      const { mjmlWithIds: newTag } = addIdsToMjml(childTag, tags);
      mjmlWithIds.children[childIndex] = newTag;
    });
  }

  return { mjmlWithIds, tags };
};

export const processMjmlSelectors = (tags = {}) => {
  const selectors = [];

  Object.keys(tags).forEach((tagName) => {
    const occurrences = tags[tagName];
    for (let counter = 1; counter <= occurrences; counter += 1) {
      const identifier = buildTagId(tagName, counter);
      selectors.push({
        tagName: 'mj-selector',
        attributes: {
          path: `.${identifier}`,
        },
        children: [
          {
            tagName: 'mj-html-attribute',
            attributes: {
              name: 'data-id',
            },
            content: identifier,
          },
        ],
      });
    }
  });

  return selectors;
};

export const processIds = (mjml) => {
  if (!mjml || !mjml.children) {
    return mjml;
  }

  const bodyIndex = mjml.children.findIndex((tag) => tag.tagName === 'mj-body');

  if (bodyIndex === -1) {
    return mjml;
  }

  const mjmlProcessed = JSON.parse(JSON.stringify(mjml));
  const mjmlBody = mjmlProcessed.children[bodyIndex];
  const { mjmlWithIds, tags } = addIdsToMjml(mjmlBody);
  mjmlProcessed.children[bodyIndex] = mjmlWithIds;

  const selectors = processMjmlSelectors(tags);
  const htmlAttributes = {
    tagName: 'mj-html-attributes',
    children: selectors,
  };
  const headIndex = mjmlProcessed.children.findIndex(
    (child) => child.tagName === 'mj-head'
  );

  // mj-head component does not exist in the current mjml code, add it (mj-head with mj-html-attributes as children)
  if (headIndex === -1) {
    const head = {
      tagName: 'mj-head',
      children: [htmlAttributes],
    };
    mjmlProcessed.children.unshift(head);
    return mjmlProcessed;
  }

  const headChildren = mjmlProcessed.children[headIndex].children;
  if (!headChildren) mjmlProcessed.children[headIndex].children = [];
  const htmlAttributesIndex = mjmlProcessed.children[
    headIndex
  ].children.findIndex((child) => child.tagName === 'mj-html-attributes');

  // mj-html-attributes does not exist in mj-head tag, add it.
  if (htmlAttributesIndex === -1) {
    mjmlProcessed.children[headIndex].children.unshift(htmlAttributes);
    return mjmlProcessed;
  }

  // mj-html-attributes exists in mj-head tag, just add the selectors.
  mjmlProcessed.children[headIndex].children[htmlAttributesIndex].children.push(
    ...selectors
  );
  return mjmlProcessed;
};

export const getMjHeadIndex = (mjml) => {
  const headIndex = mjml?.children.findIndex(
    (child) => child.tagName === 'mj-head'
  );

  if (headIndex !== -1) {
    if (!mjml.children[headIndex]?.children) {
      mjml.children[headIndex] = [];
    }
    return headIndex;
  }

  // The mj-head does not exist, add it.
  const head = {
    tagName: 'mj-head',
    children: [],
  };
  mjml.children.unshift(head);
  return 0;
};

export const getMjmlComponentsByAttribute = (nodes, attribute) => {
  return nodes.filter((node) =>
    Object.keys(node.tag.attributes).includes(attribute)
  );
};

export const getCustomFonts = (mjHeadElements) => {
  if (!Array.isArray(mjHeadElements)) {
    console.error('Error: mjHeadElements should be an array');
    return [];
  }

  return mjHeadElements.reduce((accumulator, element, index) => {
    try {
      if (!element || !element.content) return accumulator;

      const styleContent = element.content.trim();

      if (
        element.tagName === 'mj-style' &&
        styleContent.startsWith('@font-face')
      ) {
        const familyMatch = /font-family: '([^']+)';/.exec(styleContent);
        const weightMatch = /font-weight: (\d+);/.exec(styleContent);
        const srcMatch = /src: url\((.*?)\)/.exec(styleContent);

        if (familyMatch && weightMatch && srcMatch) {
          const family = familyMatch[1];
          const weight = weightMatch[1];
          const src = srcMatch[1];

          if (src) {
            accumulator.push({
              family,
              weight,
              index,
            });
          }
        }
      }
    } catch (error) {
      console.error(`Error processing the element at index ${index}:`, error);
    }

    return accumulator;
  }, []);
};

const getWeightsFromURL = (url) => {
  const startIndex = url.search('wght@');
  const endIndex = url.search('&display=swap');

  if (startIndex !== -1 && endIndex !== -1) {
    const weightsString = url.substring(startIndex + 5, endIndex);
    const weights = weightsString
      .split(';')
      .map((element) => {
        const weight = element.split(',');
        if (weight.length === 1) {
          return weight;
        }

        const type = weight[0] === '1' ? 'Italic' : '';
        return `${weight[1]} ${type}`;
      })
      .join(', ');

    return weights;
  }

  return 'regular';
};

export const getGoogleFonts = (mjHeadElements) => {
  return mjHeadElements.reduce((accumulator, element, index) => {
    if (element.tagName === 'mj-font') {
      const { href } = element.attributes;
      const weights = getWeightsFromURL(href);

      accumulator.push({
        index,
        family: element?.attributes.name,
        weight: weights,
      });
    }

    return accumulator;
  }, []);
};

export const getFontsFromComponents = (mjmlComponents) => {
  return mjmlComponents.reduce((accumulator, component) => {
    const { tag } = component;
    const fontFamily = tag.attributes['font-family'];
    const fontWeight = tag.attributes['font-weight'];

    const fontStack = fontFamily.split(',');
    const mainFont = fontStack[0] ?? '';

    const fontExists = accumulator.some((font) => {
      return font.family === mainFont && font.weight === fontWeight;
    });

    if (fontExists) {
      return accumulator;
    }

    const fallback = fontStack.map((font) => font.trim());
    return [
      ...accumulator,
      {
        family: mainFont,
        weight: fontWeight,
        fallback: fallback.slice(1, fallback.length),
      },
    ];
  }, []);
};

export const getObjectsWithKey = (key, object, result) => {
  if (Object.prototype.hasOwnProperty.call(object, key)) {
    result.push(object);
  }

  for (let i = 0; i < Object.keys(object).length; i += 1) {
    if (typeof object[Object.keys(object)[i]] === 'object') {
      getObjectsWithKey(key, object[Object.keys(object)[i]], result);
    }
  }
  return result;
};

export const getMjmlNodesFromJson = (json) => {
  const nodes = [];
  getObjectsWithKey('tagName', json, nodes);

  nodes.forEach((node) => {
    const { attributes, children, tagName, depth } = node || {};
    if (!attributes) node.attributes = {};
    const { id } = attributes || {};
    const auxId = uuidv4();

    if (!id) {
      node.attributes.id = auxId;
    }

    if (!depth) {
      node.depth = 0;
    }

    children?.forEach((child) => {
      child.parentId = node.attributes.id;
      child.parentType = tagName;
      child.depth = node.depth + 1;
    });
  });
  return nodes;
};

export const removeOutputIds = (string, language = 'html') => {
  const idsRegex = {
    html: /data-id="(?!(sc_))\S*"/gm,
    mjml: /id="\S*"/gm,
  };
  const regex = idsRegex[`${language}`];
  return string?.replace(regex, '') || '';
};

export const parseAttributeValue = (value = '') => {
  if (value.toLowerCase() === 'auto') {
    return {
      value: '-',
      unit: 'auto',
    };
  }

  const result = value.match(/(-?[\d.]+)([a-z%]*)/);

  if (result) {
    return {
      value: result[1],
      unit: result[2].toLowerCase(),
    };
  }

  return {
    value: '',
    unit: '',
  };
};

export const generateMjmlFromComponents = (components, head = []) => {
  const newJson = JSON.parse(JSON.stringify(GENERIC_JSON));
  if (head.length > 0) newJson.children[0].children = head;
  newJson.children[1].children = components;

  const mjml = json2mjml(newJson);
  return mjml;
};
