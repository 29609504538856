import { FiEye, FiStar, FiUsers } from 'react-icons/fi';

// TODO: Validate features with product and dev teams

const proDescription = {
  name: 'Pro',
  pricing: '$15',
  pricingLegend: 'Billed monthly',
  description: 'For one-person marketing geek.',
  features: [
    {
      plan: 'Unlimited',
      label: 'ingested emails',
      longDescription: 'Unlimited file conversions',
      tooltip:
        'The number of files that you will be able to put into Cannoli each month. The counter restarts at the beginnig of each month.',
    },
    {
      plan: 'Unlimited',
      label: 'seats included',
      longDescription: '1 seat',
      tooltip: 'One active account',
    },
    {
      plan: 'Unlimited',
      label: 'premium support',
      longDescription: 'Premium support',
      tooltip:
        'Even if your file is not processed correctly at first, we will be taking a look and we will fix it. Ask us anything, we are here to help.',
    },
    {
      plan: 'Unlimited',
      label: 'image hosting',
      longDescription: 'Image hosting at 2x',
      tooltip:
        'All images exported from Figma have a 2x resolution, and it is completely free to store them in Cannoli.',
    },
    {
      plan: 'Unlimited',
      label: 'test emails sends',
      longDescription: 'Send unlimited test emails',
      tooltip:
        'When your file is ready, send a test email to your account to see how it renders in a real email client.',
    },
    {
      plan: 'Unlimited',
      label: 'esp integrations',
      longDescription: 'Download, copy or export your code',
      tooltip: 'Connect to Klaviyo and create templates directly from Cannoli.',
    },
  ],
  icon: FiStar,
};

export const plans = {
  team: {
    name: 'Team',
    slug: 'team',
    description: 'For larger marketing teams.',
    pricing: '$25 includes 2 seats',
    pricingLegend: 'Billed monthly',
    pricingNotes: '+ $12 per extra user',
    link: '/login?create-account=team',
    features: [
      {
        plan: 'Unlimited',
        label: 'ingested emails',
        longDescription: 'Unlimited file conversions',
        tooltip:
          'The number of files that you will be able to put into Cannoli each month. The counter restarts at the beginnig of each month.',
      },
      {
        plan: 'Unlimited',
        label: 'seats included',
        longDescription: '2 seats included',
        tooltip: 'Two active accounts with full access to Cannoli',
      },
      {
        plan: 'Unlimited',
        label: 'file fixes',
        longDescription: 'Premium Support',
        tooltip:
          'Even if your file is not processed correctly at first, we will be taking a look and we will fix it. Ask us anything, we are here to help.',
      },
      {
        plan: 'Unlimited',
        label: 'image hosting',
        longDescription: 'Image hosting at 2x',
        tooltip:
          'All images exported from Figma have a 2x resolution, and it is completely free to store them in Cannoli.',
      },
      {
        plan: 'Unlimited',
        label: 'test emails',
        longDescription: 'Send unlimited test emails',
        tooltip:
          'When your file is ready, send a test email to your account to see how it renders in a real email client.',
      },
      {
        plan: 'Unlimited',
        label: 'esp integrations',
        longDescription: 'Download, copy or export your code',
        tooltip:
          'Connect to Klaviyo and create templates directly from Cannoli',
      },
      {
        plan: 'Unlimited',
        label: 'projects',
        longDescription: 'Projects and file sharing',
        tooltip: 'Organize your work using projects within your team.',
      },
    ],
    cta: 'Choose Team',
    icon: FiUsers,
  },
  pro: {
    ...proDescription,
    slug: 'pro',
  },
  proTrial: {
    ...proDescription,
    slug: 'free-pro-trial',
    badge: '(7 days free trial)*',
    note: '*No credit card required',
  },
  starter: {
    name: 'Starter',
    slug: 'starter',
    description:
      'For individuals who don’t send many emails, test out Cannoli.',
    pricing: 'FREE',
    pricingLegend: 'Forever',
    features: [
      {
        plan: '3',
        label: 'ingested emails',
        longDescription: '3 file conversions per month',
        tooltip:
          'The number of files that you will be able to put into Cannoli each month. The counter restarts at the beginnig of each month.',
      },
      {
        plan: '3',
        label: 'test emails',
        longDescription: '3 test emails per month',
        tooltip:
          'When your file is ready, send a test email to your account to see how it renders in a real email client.',
      },
      {
        plan: 'Unlimited',
        label: 'seats included',
        longDescription: '1 seat',
        tooltip: 'One active account',
      },
      {
        plan: 'Unlimited',
        label: 'image hosting',
        longDescription: 'Image hosting at 2x',
        tooltip:
          'All images exported from Figma have a 2x resolution, and it is completely free to store them in Cannoli.',
      },
    ],
    icon: FiEye,
  },
};

export const status = {
  active: { status: 'success', label: 'Active' },
  pending: { status: 'warning', label: 'Pending' },
  overdue: { status: 'danger', label: 'Overdue' },
  canceled: { status: 'danger', label: 'Canceled' },
  unknown: { status: null, label: 'Refresh' },
};
