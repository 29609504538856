import { useContext } from 'react';
import IconButton from '@scalero-inc/forno-icon-button';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { MdDragIndicator } from 'react-icons/md';
import { editorOptions } from 'utils/editor';
import { EditorPanelLeftContext } from 'containers/editor-panel-left/editor-panel-context';
import usePanel from 'hooks/utils/use-panel';
import LayoutChildrenMenu from './layout-children-menu';
import styles from '../styles.module.css';

export default function TagContent({
  isExpanded,
  handleExpand,
  itemIndex,
  section,
  id,
  draggable,
  hasChildren,
}) {
  const { setTagSelected, tagSelected } = useContext(EditorPanelLeftContext);

  const panel = usePanel();

  return (
    <div
      className={styles['body-children-tag']}
      data-selected={tagSelected.id === id}
      role="button"
      tabIndex={0}
      onClick={() => {
        setTagSelected({
          id,
          from: 'panel',
        });
        const { value, title } = editorOptions.visualEditor;
        panel.open(value, title);
      }}
    >
      <div className={styles['body-children-tag-name']}>
        {hasChildren ? (
          <IconButton
            size="s"
            theme="dark"
            hierarchy="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              handleExpand();
            }}
          >
            {!isExpanded ? <FaChevronRight /> : <FaChevronDown />}
          </IconButton>
        ) : (
          <span className={styles['spacer-span']} />
        )}
        <div className="font-block-s-sb">
          <p className={styles['body-children-tag-name-text']}>
            {section.attributes.name ?? section.tagName}
          </p>
        </div>
        <div className={styles['children-tag-menu']}>
          {draggable && <LayoutChildrenMenu index={itemIndex} />}
        </div>
      </div>
      <div className={styles['body-children-buttons']}>
        {draggable && (
          <MdDragIndicator size={20} className={styles['drag-indicator']} />
        )}
      </div>
    </div>
  );
}

TagContent.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tagName: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
  draggable: PropTypes.bool,
  hasChildren: PropTypes.bool.isRequired,
};

TagContent.defaultProps = {
  draggable: true,
};
