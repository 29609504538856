import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFontsQuery } from 'hooks/api/fonts';
import { FiSearch } from 'react-icons/fi';
import Spinner from '@scalero-inc/forno-spinner';
import Input from '@scalero-inc/forno-input';
import useDebounce from 'hooks/utils/use-debounce';
import useOutsideClick from 'hooks/utils/use-outside-click';

import styles from './styles.module.css';

function GoogleFontsFilter({ onSelectFont, theme }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);
  const dropdownRef = useOutsideClick({ callback: closeDropdown });

  const [fontsFilter, setFontsFilter] = useState('');
  const { data, isFetching, error, refetch } = useFontsQuery({
    family: fontsFilter,
    config: {
      enabled: false,
    },
  });

  const debouncedOnFilter = useDebounce(refetch, 300);

  return (
    <div ref={dropdownRef}>
      <div className={styles['search-fonts']}>
        <Input
          size="s"
          theme={theme}
          prefix={FiSearch}
          label="Search and select a Google font"
          placeholder="Search Google fonts"
          value={fontsFilter}
          onFocus={() => {
            openDropdown();
          }}
          onChange={(event) => {
            const newFilter = event.target.value;
            setFontsFilter(newFilter);
            if (newFilter !== '') {
              debouncedOnFilter();
            }
          }}
        />
      </div>

      {isDropdownOpen && fontsFilter !== '' && (
        <div className={styles['dropdown-content']}>
          {isFetching && (
            <div className={styles['dropdown-option']}>
              <Spinner />
            </div>
          )}

          {!isFetching && !error && data?.results.length > 0 && (
            <div className={styles['dropdown-options-container']}>
              {data.results.map((font, fontIndex) => {
                return (
                  <button
                    key={`font_${fontIndex}`}
                    type="button"
                    className={styles['dropdown-option']}
                    onClick={() => {
                      const fontSelected = { ...font };
                      fontSelected.fallback = {
                        name: '',
                        stack: [],
                      };
                      onSelectFont(fontSelected);
                      setFontsFilter('');
                    }}
                  >
                    {font.family}
                  </button>
                );
              })}
            </div>
          )}

          {!isFetching && !error && data?.results.length === 0 && (
            <div className={styles['dropdown-option']}>No results found</div>
          )}

          {!isFetching && error && (
            <div className={styles['dropdown-option']}>Try again later</div>
          )}
        </div>
      )}
    </div>
  );
}

GoogleFontsFilter.propTypes = {
  onSelectFont: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark']),
};

GoogleFontsFilter.defaultProps = {
  onSelectFont: () => {},
  theme: 'light',
};

export default GoogleFontsFilter;
