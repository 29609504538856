import PropTypes from 'prop-types';
import DynamicInput from 'components/dynamic-input';
import { useEditor } from 'pages/editor/editor-context';
import {
  editableAttributes,
  isContentEditingAllowed,
  isTagEditable,
  MJML_CONTENT_TAGS,
} from 'utils/mjml';
import { FiSquare } from 'react-icons/fi';
import FontsDataProvider from 'containers/fonts-data-provider';
import MissingFontMessage from 'components/missing-font-message';
import InputFont from 'components/dynamic-input/input-font';
import FontFamilyDataProvider from 'components/dynamic-input/font-family-data-provider';

import styles from './style.module.css';

const tagEditorOptions = {
  layout: 'Select a layer in the left panel and start editing your',
  blocks: 'By clicking a block in the left panel it will be added to your',
  fonts: 'Manage the font stacks and add your own fonts to the',
};

function TagEditorPanel({ tagId, feature }) {
  const { nodesWithIds = [], fileId } = useEditor();
  const fileType = fileId ? 'email' : 'block';
  const tagsToEdit =
    tagId === '*'
      ? nodesWithIds.filter((node) => isTagEditable(node.tag.tagName))
      : nodesWithIds.filter(
          (node) => node.id === tagId && isTagEditable(node.tag.tagName)
        );

  const titles = {
    layout: 'Layers',
    blocks: 'Blocks library',
    fonts: 'Fonts settings',
  };

  return (
    <div>
      <header className={styles['editor-panel-header']}>
        <div>{titles[feature]}</div>
      </header>

      <div>
        {(tagsToEdit.length === 0 || feature !== 'layout') && (
          <div className={styles['no-layer-selected']}>
            {tagEditorOptions[feature]} {fileType}!<span>🙌</span>
          </div>
        )}

        {tagsToEdit.length > 0 && feature === 'layout' && (
          <div className={styles.container}>
            {tagsToEdit.map((tagToEdit) => {
              const { id, tag } = tagToEdit;
              const { attributes: tagAttribs = {}, tagName } = tag;
              const tagProps = MJML_CONTENT_TAGS[tagName];
              const tagIcon = tagProps.icon || <FiSquare />;

              // Create an object with all attributes supported by the tag, with an empty string as default value ({href: '', ...})
              const allAttribsAllowed = {};
              tagProps.attributes.forEach((attrib) => {
                allAttribsAllowed[attrib] = '';
              });
              // Mix attributes supported and attributes specified in the design
              const nodeAttribs = {
                ...allAttribsAllowed,
                ...tagAttribs,
              };

              return (
                <div key={id} className={styles['component-container']}>
                  <div className={styles['component-header']}>
                    {tagIcon}
                    <span>{tagName}</span>
                  </div>

                  {/* Generate input for content */}
                  {tagProps?.content === isContentEditingAllowed && (
                    <DynamicInput
                      key={`${id}_content`}
                      nodeId={id}
                      attribute="content"
                      value={tag.content}
                      mjmlNodeRef={tag}
                    />
                  )}

                  <FontsDataProvider>
                    {(
                      error,
                      isLoading,
                      googleFontOptions,
                      customFontOptions,
                      webSafeFontOptions,
                      fontStackOptions
                    ) => {
                      return (
                        <>
                          {/* Generate inputs for tag attributes */}
                          {Object.entries(nodeAttribs).map(
                            ([attribute, attribValue]) => {
                              if (!editableAttributes.includes(attribute)) {
                                return null;
                              }

                              return (
                                <div key={`${id}_${attribute}`}>
                                  {attribute === 'width' && (
                                    <div className={styles['component-header']}>
                                      Sizing
                                    </div>
                                  )}

                                  {attribute === 'border' && (
                                    <div className={styles['component-header']}>
                                      Border
                                    </div>
                                  )}

                                  {attribute === 'font-family' ? (
                                    <FontFamilyDataProvider
                                      value={attribValue}
                                      mjmlNodeRef={tag}
                                    >
                                      {({ defaultFont, isMissingFont }) => {
                                        return (
                                          <>
                                            {isMissingFont && (
                                              <MissingFontMessage />
                                            )}
                                            <InputFont
                                              isError={!!error}
                                              isLoading={isLoading}
                                              googleFonts={googleFontOptions}
                                              customFonts={customFontOptions}
                                              webSafeFonts={webSafeFontOptions}
                                              fontStacks={fontStackOptions}
                                              isMissingFont={isMissingFont}
                                              defaultFontFamily={defaultFont}
                                              mjmlNodeRef={tag}
                                            />
                                          </>
                                        );
                                      }}
                                    </FontFamilyDataProvider>
                                  ) : (
                                    <DynamicInput
                                      key={`${id}_${attribute}`}
                                      nodeId={id}
                                      attribute={attribute}
                                      value={attribValue}
                                      mjmlNodeRef={tag}
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                        </>
                      );
                    }}
                  </FontsDataProvider>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

TagEditorPanel.propTypes = {
  tagId: PropTypes.string,
  feature: PropTypes.string.isRequired,
};

TagEditorPanel.defaultProps = {
  tagId: '*',
};

export default TagEditorPanel;
