import { useParams } from 'react-router-dom';

import { useComponentQuery } from 'hooks/api/components';

// TODO: This is a placeholder component to showcase the usage of the API hooks
function DashboardPersonalComponentDetail() {
  const { componentId } = useParams();
  const query = useComponentQuery({ componentId });

  if (query.isLoading) return 'Loading ...';

  return (
    <section>
      <h2>{query.data.name}</h2>
      <p>{query.data.tags?.join()}</p>
      <pre>{query.data.code}</pre>
    </section>
  );
}

export default DashboardPersonalComponentDetail;
