import { ReactComponent as KlaviyoLogo } from 'svg/integrations/klaviyo-logo.svg';
import { ReactComponent as FigmaLogo } from 'svg/integrations/figma-logo.svg';
import { ReactComponent as MailchimpLogo } from 'svg/integrations/mailchimp-logo.svg';
import { ReactComponent as KlaviyoModalLogo } from 'svg/integrations/modal-klaviyo-logo.svg';
import { ReactComponent as MailchimpModalLogo } from 'svg/integrations/modal-mailchimp-logo.svg';

export const INTEGRATIONS = [
  {
    image: KlaviyoLogo,
    name: 'Klaviyo',
    description:
      'Klaviyo is a growth marketing platform that helps you deliver more personalized experiences across owned marketing channels like email, SMS, and web.',
    esp: 'KL',
    docsLink: 'https://www.klaviyo.com/account#api-keys-tab',
    link: 'https://www.klaviyo.com',
    modalImg: KlaviyoModalLogo,
  },
  {
    image: MailchimpLogo,
    name: 'Mailchimp',
    description:
      'Mailchimp is an all-in-one marketing platform that allows businesses to manage their email marketing campaigns, create landing pages, build online stores, and more.',
    esp: 'MC',
    docsLink: 'https://us1.admin.mailchimp.com/account/api/',
    link: 'https://mailchimp.com',
    modalImg: MailchimpModalLogo,
  },
];

export const FIGMA_INTEGRATION = {
  image: FigmaLogo,
  name: 'Figma',
  description:
    'Figma is a vector graphics editor and prototyping tool which is primarily web-based, with additional offline features enabled by desktop applications for macOS and Windows.',
  esp: 'Figma',
  docsLink: null,
  link: 'https://www.figma.com/',
  modalImg: FigmaLogo,
};
