import CompareSlider from 'components/compare-slider';

import { useReadOnly } from '../../read-only-context';
import ReadOnlyPreviewIframe from '../read-only-preview-iframe';
import ReadOnlyPreviewImage from '../read-only-preview-image';

function ReadOnlyPreviewCompare() {
  const { doc } = useReadOnly();
  const {
    absoluteBoundingBox: { height, width },
  } = doc;
  return (
    <CompareSlider
      aspectRatio={width / height}
      itemOne={<ReadOnlyPreviewIframe />}
      itemTwo={<ReadOnlyPreviewImage />}
    />
  );
}

export default ReadOnlyPreviewCompare;
