export function getAlignment({ counterAxisAlignItems }) {
  const alignments = ['left', 'right', 'center'];
  const align = counterAxisAlignItems
    ? counterAxisAlignItems.toLowerCase()
    : null;

  if (alignments.includes(align)) {
    return align;
  }

  return 'left';
}

export function getPercentage(totalValue, partialValue) {
  const percentage = (parseFloat(partialValue) * 100) / parseFloat(totalValue);

  return Math.round(percentage, 2);
}
