import classnames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';

import {
  useTeamComponentsQuery,
  useUserComponentsQuery,
} from 'hooks/api/components';

import { EditorPanelLeftContext } from 'containers/editor-panel-left/editor-panel-context';

import BlocksModal from 'components/blocks-modal';
import LoadingPlaceholder from 'components/loading-placeholder';
import BlockItem from 'components/block-item';

import styles from './styles.module.css';

export default function BlocksContainer() {
  const [addBlockIsOpen, setAddBlockIsOpen] = useState(false);
  const openAddBlockWindow = useCallback(() => {
    setAddBlockIsOpen(true);
  }, [setAddBlockIsOpen]);
  const closeAddBlockWindow = useCallback(() => {
    setAddBlockIsOpen(false);
  }, [setAddBlockIsOpen]);
  const addBlockClassName = classnames(
    styles['block-item-container'],
    styles['new-block']
  );

  const { handleAddBlock, teamId } = useContext(EditorPanelLeftContext);

  const teamBlocks = useTeamComponentsQuery({
    teamId,
    config: {
      enabled: teamId !== null,
      reFetchOnWindowFocus: false,
    },
  });
  const userBlocks = useUserComponentsQuery({
    config: { enabled: teamId === null, reFetchOnWindowFocus: false },
  });

  if (teamBlocks.isLoading || userBlocks.isLoading)
    return <LoadingPlaceholder size="l" theme="dark" hierarchy="secondary" />;

  return (
    <div className={styles['blocks-container']}>
      <p className="font-paragraph-s">
        Click on a custom component to add it to your email
      </p>
      <div className={styles['blocks-grid']} data-theme="dark">
        <div className={addBlockClassName}>
          <BlocksModal
            newBlock
            isOpen={addBlockIsOpen}
            close={closeAddBlockWindow}
          />
          <Button
            theme="dark"
            hierarchy="secondary"
            size="s"
            onClick={openAddBlockWindow}
            icon={FiPlus}
          >
            New block
          </Button>
        </div>

        {teamBlocks.data !== null && teamBlocks.data !== undefined
          ? teamBlocks.data.map((block) => {
              const { id, name } = block;
              return (
                <BlockItem
                  key={`${id}_${name}`}
                  block={block}
                  teamId={teamId}
                  onClick={() => handleAddBlock(block)}
                  theme="dark"
                />
              );
            })
          : null}
        {userBlocks.data !== null && userBlocks.data !== undefined
          ? userBlocks.data.map((block) => {
              const { id, name } = block;
              return (
                <BlockItem
                  key={`${id}_${name}`}
                  block={block}
                  teamId={teamId}
                  onClick={() => handleAddBlock(block)}
                  theme="dark"
                />
              );
            })
          : null}
      </div>
    </div>
  );
}
