import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FiFigma, FiRefreshCw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import ButtonGroup from '@scalero-inc/forno-button-group';
import Button from '@scalero-inc/forno-button';
import Modal from '@scalero-inc/forno-modal';
import Tooltip from '@scalero-inc/forno-tooltip';

import {
  useFilePreviewMutation,
  useFileRefreshMutation,
  useFilesByTeamProjectInvalidateQuery,
  useFilesInvalidateQuery,
} from 'hooks/api/files';

import { viewCodeOptions } from '../editor-view-context/constants';
import {
  initialViewPanel,
  useEditorView,
  useEditorViewDispatch,
} from '../editor-view-context';

import EditorHeaderDevicesButtons from './editor-header-devices-buttons';
import EditorHeaderLayoutButtons, {
  viewColumnsOptions,
} from './editor-header-layout-buttons';

import styles from './style.module.css';
import { useEditor } from '../editor-context';

function EditorHeader({ className, editorView }) {
  const customClassName = classnames(
    'editor-header',
    styles['editor-header'],
    className,
    {}
  );

  const { fileId, html, url, node, templateId } = useEditor();
  const isEditingFile = !!fileId;
  const { viewCode, viewPanel } = useEditorView();
  const { setViewCode, setViewPanel, setViewColumns } = useEditorViewDispatch();
  const navigate = useNavigate();
  const onCodeViewChange = (value) => {
    if (viewPanel.name !== '') {
      setViewPanel(initialViewPanel);
      setViewColumns(viewColumnsOptions[1].value);
    }
    setViewCode(value[0]);
  };

  const invalidateFiles = useFilesInvalidateQuery();
  const invalidateProjectFiles = useFilesByTeamProjectInvalidateQuery();

  const previewMutation = useFilePreviewMutation({
    fileId,
    html,
    config: {
      onSuccess: () => {
        invalidateFiles();
        invalidateProjectFiles();
      },
    },
  });

  const mutation = useFileRefreshMutation({
    fileId,
    config: {
      onSuccess: () => {
        navigate(`/process/${fileId}`);
        previewMutation.mutate();
      },
    },
  });

  const openFigmaFile = () => {
    const nodeUrl = `${url.split('?')[0]}?node-id=${encodeURIComponent(node)}`;
    window.open(nodeUrl);
  };

  const tooltipClassName = 'font-paragraph-s-sb';

  const regenerate = () => {
    mutation.mutate();
  };

  if (editorView === 'rebase') return null;

  return (
    <header className={customClassName} data-theme="dark">
      <div className={`${styles.wrapper}`}>
        <section className={styles['right-wrapper']}>
          <div className={styles['options-wrapper']}>
            <Modal
              className={styles['editor-menu-dialog']}
              isOpen={mutation.isLoading}
              aria-label="Regenerating file"
              theme="dark"
              onDismiss={() => {}}
            >
              <h1 className={styles['editor-menu-dialog-wrapper']}>
                <FiRefreshCw className="-spin" />
                <span>Regenerating file</span>
              </h1>
            </Modal>
            <Tooltip
              theme="dark"
              status="danger"
              content={
                <p className={tooltipClassName}>
                  Warning: Any changes made to your code will be deleted. Reload
                  this file to see updates made in your figma file.
                </p>
              }
            >
              <Button
                size="s"
                theme="dark"
                hierarchy="tertiary"
                icon={FiRefreshCw}
                onClick={() => regenerate()}
              >
                Regenerate File
              </Button>
            </Tooltip>
            {isEditingFile && !templateId && (
              <Tooltip
                theme="dark"
                content={
                  <p className={tooltipClassName}>
                    Open the Figma file linked to this Cannoli file
                  </p>
                }
              >
                <Button
                  size="s"
                  theme="dark"
                  hierarchy="tertiary"
                  icon={FiFigma}
                  onClick={() => openFigmaFile()}
                >
                  Open in Figma
                </Button>
              </Tooltip>
            )}
            {editorView === 'code' && (
              <Tooltip
                theme="dark"
                content={
                  <p className={tooltipClassName}>
                    Change the code you’re viewing, you can edit MJML but can
                    only view HTML.
                  </p>
                }
              >
                <ButtonGroup
                  size="s"
                  type="radio"
                  theme="dark"
                  variant="subtle"
                  onChange={onCodeViewChange}
                  initialValue={[viewCode]}
                  className="cannoli-button-group"
                >
                  {viewCodeOptions.map((item) => {
                    return (
                      <ButtonGroup.Button
                        value={item.value}
                        key={item.value}
                        disabled={item.value === 'option2'}
                        className="cannoli-button-group-item"
                      >
                        {item.label}
                      </ButtonGroup.Button>
                    );
                  })}
                </ButtonGroup>
              </Tooltip>
            )}
          </div>
        </section>
        <section className={styles['left-wrapper']}>
          {editorView === 'code' && <EditorHeaderLayoutButtons />}
          <EditorHeaderDevicesButtons editorView={editorView} />
        </section>
      </div>
    </header>
  );
}

EditorHeader.propTypes = {
  className: PropTypes.string,
  editorView: PropTypes.string.isRequired,
};

EditorHeader.defaultProps = {
  className: null,
};

export default EditorHeader;
