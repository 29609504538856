import React from 'react';
import { Link } from 'react-router-dom';
import { FiFigma } from 'react-icons/fi';
import PropTypes from 'prop-types';

import Button from '@scalero-inc/forno-button';

import { useAuth } from 'context/auth';

import { ReactComponent as CannoliLogo } from 'svg/cannoli-white-logo.svg';

import styles from './style.module.css';
import { AccordionMenu, AccordionServices } from './accordions';

const routes = [
  { id: 'platform', label: 'Platform' },
  { id: 'services', label: 'Services' },
];

function HeaderPublic({ showLogin, withMenu }) {
  const { user } = useAuth();
  return (
    <header className={styles['header-public']}>
      <Link to="/" className={styles['header-public-logo-wrapper']}>
        <CannoliLogo className={styles['header-cannoli-logo']} />
      </Link>

      <div className={styles['header-menu-navigation']}>
        <div className={styles['header-menu-mobile']}>
          <AccordionMenu />
        </div>

        {withMenu ? (
          <ul className={styles['header-menu-desktop']}>
            {routes.map((route) => (
              <li key={route.id} className={styles['header-menu-desktop-item']}>
                <Link to={`/${route.id}`}>{route.label}</Link>
              </li>
            ))}
            <AccordionServices isFloating />
            {showLogin && !user && (
              <Link to="/login">
                <Button hierarchy="primary" icon={FiFigma} theme="light">
                  Login
                </Button>
              </Link>
            )}
          </ul>
        ) : null}
      </div>
    </header>
  );
}

HeaderPublic.propTypes = {
  showLogin: PropTypes.bool,
  withMenu: PropTypes.bool,
};

HeaderPublic.defaultProps = {
  showLogin: true,
  withMenu: true,
};

export default HeaderPublic;
