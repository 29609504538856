import { plans } from 'containers/dashboard-billing/constants';
import syncFigmaFrameSrc from 'images/cannoli-app/sync-figma-frame-step.png';
import editYourContentSrc from 'images/cannoli-app/edit-your-content-step.png';
import useFinalCodeSrc from 'images/cannoli-app/use-final-code-step.png';

export const steps = [
  {
    name: 'Sync Figma frame',
    description:
      'Once your email design is ready, log in into Cannoli and sync your Figma file. Your code will be ready in seconds.',
    image: {
      src: syncFigmaFrameSrc,
      alt: 'Sync Figma frame',
    },
  },
  {
    name: 'Edit your content',
    description:
      'Easily modify links, fonts, texts, images, and more. Use the visual editor or dive into code – whatever suits you best.',
    image: {
      src: editYourContentSrc,
      alt: 'Edit your content',
    },
  },
  {
    name: 'Use final code',
    description:
      'Download or copy your code into your favorite email service provider, or export it directly into Mailchimp or Klaviyo with our custom integrations.',
    image: {
      src: useFinalCodeSrc,
      alt: 'Use final code',
    },
    link: {
      name: 'Try Cannoli',
      path: `/login?create-account=${plans.proTrial.slug}`,
    },
  },
];
