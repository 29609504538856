import { useParams } from 'react-router-dom';

import { useUserQuery } from 'hooks/api/users';

import DashboardBilling from 'containers/dashboard-billing';
import TeamBillingPricing from 'containers/team-billing-pricing';

function DashboardTeamBilling() {
  const { data: user, isLoading } = useUserQuery();
  const teamId = parseInt(useParams().teamId, 10);

  if (isLoading) return 'Loading...';

  const currentSubscription = user.team_subscriptions.find(
    (subscription) => subscription.team === teamId
  );

  if (!currentSubscription)
    return <TeamBillingPricing teamId={teamId} type="create" />;

  return <DashboardBilling subscription={currentSubscription} />;
}

export default DashboardTeamBilling;
