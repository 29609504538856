import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import IconButton from '@scalero-inc/forno-icon-button';
import ComponentDeleteModal from 'containers/component-delete-modal';
import Time from 'components/time';
import BlockPreviewIframe from 'components/block-preview-iframe/block-preview-iframe';
import HtmlToReact from 'components/html-to-react';
import { generateHtml } from 'utils';

import styles from './styles.module.css';

function ComponentRow({
  id,
  name,
  teamId,
  updated_at: updatedAt,
  created_at: createdAt,
  virtual_mj_head: head,
  content,
  numComponents,
  page,
  setPage,
  disabled,
}) {
  const customClassName = classnames('block-row', styles['block-row'], {});
  const team = teamId || 'personal';
  const url = `/team/${team}/block/${id}/`;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url);
  };

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const openDeleteWindow = useCallback(
    (e) => {
      e.stopPropagation();
      setDeleteIsOpen(true);
    },
    [setDeleteIsOpen]
  );
  const closeDeleteWindow = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );
  const blocked = false;

  return (
    <tr
      className={customClassName}
      data-disabled={disabled}
      onClick={handleClick}
    >
      <td className={styles['block-preview-cell']} valign="middle">
        <div className={styles['block-preview']}>
          <BlockPreviewIframe disableLinks isStatic>
            <HtmlToReact html={generateHtml(content.blockArray, head)} />
          </BlockPreviewIframe>
        </div>
      </td>
      <td className={styles['block-name-cell']}>{name}</td>
      <td className={styles['block-date-updated-cell']}>
        {updatedAt ? <Time>{updatedAt}</Time> : 'NA'}
      </td>
      <td className={styles['block-created-at-cell']}>
        {createdAt ? <Time>{createdAt}</Time> : 'NA'}
      </td>
      <td className={styles['block-delete-cell']}>
        <IconButton
          onClick={openDeleteWindow}
          disabled={blocked}
          status="danger"
        >
          <FiTrash2 />
        </IconButton>
        <ComponentDeleteModal
          id={id}
          name={name}
          teamId={teamId}
          numComponents={numComponents}
          page={page}
          setPage={setPage}
          onDismiss={closeDeleteWindow}
          isOpen={deleteIsOpen}
        />
      </td>
    </tr>
  );
}

ComponentRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  updated_at: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  virtual_mj_head: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  content: PropTypes.shape({
    blockArray: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  numComponents: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ComponentRow.defaultProps = {
  teamId: null,
  updated_at: new Date().toISOString(),
  disabled: null,
};

export default ComponentRow;
