import React from 'react';

import { useLocation } from 'react-router-dom';

import cannoliLogoGif from 'images/home/cannoli-logo.gif';
import timerGif from 'images/home/timer.gif';

import styles from './styles.module.css';

function LookingForMoreSection() {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === '#looking-for-more') {
      const element = document.getElementById('looking-for-more');
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <section className={styles.twoColumnSection} id="looking-for-more">
      <h2>Looking for more?</h2>
      <p className="paragraph-l">
        We can also add extra features to your emails upon request. Some of the
        things we can add include:
      </p>

      <div className={styles.columns}>
        <div className={styles.column}>
          <img
            src={timerGif}
            alt="A countdown timer"
            className={styles.gifImage}
          />
          <h3>A Countdown Timer</h3>
        </div>

        <div className={styles.column}>
          <img src={cannoliLogoGif} alt="GIFs" className={styles.gifImage} />
          <h3>GIFs</h3>
        </div>
      </div>
    </section>
  );
}

export default LookingForMoreSection;
