import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';

export const EVENT_TYPES = {
  CLICK: 'click',
  MOUSE_OVER: 'mouseover',
};

function getDataId(element) {
  if (!element || !element.dataset) return '';

  let current = element;
  while (current && current.dataset) {
    const { id } = current.dataset;

    const isSectionOrWrapper =
      id?.includes('mj-section') || id?.includes('mj-wrapper');

    if (isSectionOrWrapper) {
      return id;
    }

    if (current.parentNode === document.body || !current.parentNode) break;

    current = current.parentNode;
  }

  return '';
}

function SelectNode({ document, setSelectedElement, origin, eventType }) {
  const onClickListener = useCallback(
    (e) => {
      const event = e || window.event;
      const target = event.target || event.srcElement;
      const id = getDataId(target);

      if (id !== '') {
        setSelectedElement({
          [origin]: {
            id,
            from: 'RebasePreview',
          },
        });
      }
    },
    [setSelectedElement, origin]
  );

  useEffect(() => {
    document.addEventListener(eventType, onClickListener);
    return () => {
      document.removeEventListener(eventType, onClickListener);
    };
  }, [document, eventType, onClickListener]);

  return null;
}

SelectNode.propTypes = {
  document: PropTypes.shape({
    addEventListener: PropTypes.func,
    removeEventListener: PropTypes.func,
  }),
  setSelectedElement: PropTypes.func,
  origin: PropTypes.string,
  selectedElement: PropTypes.shape({}),
  eventType: PropTypes.oneOf([EVENT_TYPES.CLICK, EVENT_TYPES.MOUSE_OVER]),
};

SelectNode.defaultProps = {
  document: null,
  setSelectedElement: () => {},
  origin: '',
  selectedElement: {},
  eventType: EVENT_TYPES.CLICK,
};

export default SelectNode;
