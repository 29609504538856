import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFetch } from 'context/fetch';

import {
  TEAMS_QUERY_KEY,
  TEAM_MEMBERS_QUERY_KEY,
  TEAM_QUERY_KEY,
} from 'hooks/api/constants';
import {
  teamCreateMutation,
  teamMemberAddMutation,
  teamMemberDeleteMutation,
  teamMemberUpdateMutation,
  teamUpdateMutation,
  teamUpdateLogoMutation,
  teamDeleteMutation,
} from './mutations';
import { teamsQuery, teamMembersQuery, teamQuery } from './queries';

export function useTeamCreateMutation(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => teamCreateMutation({ client, values }),
    config
  );
}

export function useTeamsQuery(props = {}) {
  const { config = {}, key = TEAMS_QUERY_KEY } = props;
  const { client } = useFetch();

  return useQuery([key], () => teamsQuery({ client }), config);
}

export function useTeamsInvalidateQuery(props = {}) {
  const { key = TEAMS_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useTeamMemberAddMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => teamMemberAddMutation({ client, teamId, values }),
    config
  );
}

export function useTeamMembersQuery(props = {}) {
  const { teamId } = props;
  const { config = {}, key = [TEAM_MEMBERS_QUERY_KEY, teamId] } = props;
  const { client } = useFetch();

  return useQuery([key], () => teamMembersQuery({ client, teamId }), config);
}

export function useTeamMembersInvalidateQuery(props = {}) {
  const { teamId } = props;
  const { key = [TEAM_MEMBERS_QUERY_KEY, teamId] } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useTeamMemberDelete(props = {}) {
  const { config } = props;
  const { client } = useFetch();

  return useMutation((id) => {
    const idVariable = id.split('-');
    const teamId = idVariable[0];
    const memberId = idVariable[1];
    return teamMemberDeleteMutation({ client, teamId, memberId });
  }, config);
}

export function useTeamMemberUpdate(props = {}) {
  const { config, teamId, memberId } = props;
  const { client } = useFetch();

  return useMutation((values) => {
    teamMemberUpdateMutation({ client, teamId, memberId, values });
  }, config);
}

export function useTeamUpdateMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) => teamUpdateMutation({ client, teamId, values }),
    config
  );
}

export function useTeamLogoUpdate(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(
    (file) => teamUpdateLogoMutation({ client, teamId, file }),
    config
  );
}

export function useTeamDeleteMutation(props = {}) {
  const { config, teamId } = props;
  const { client } = useFetch();

  return useMutation(() => teamDeleteMutation({ client, teamId }), config);
}

export function useTeamQuery(props = {}) {
  const { teamId, config, key = [TEAM_QUERY_KEY, teamId] } = props;
  const { client } = useFetch();
  return useQuery([key], () => teamQuery({ client, teamId }), config);
}

export function useTeamInvalidateQuery(props = {}) {
  const { teamId } = props;
  const { key = [TEAM_QUERY_KEY, teamId] } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}
