import { useFetch } from 'context/fetch';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  templateComponentCreateMutation,
  templateComponentDeleteMutation,
  templateComponentUpdateMutation,
  templateCreateMutation,
  templateDeleteMutation,
  templateUpdateMutation,
} from './mutations';
import {
  TEMPLATES_QUERY_KEY,
  TEMPLATE_COMPONENTS_QUERY_KEY,
} from '../constants';
import {
  templateComponentsQuery,
  templateQuery,
  templatesPaginatedQuery,
  templatesQuery,
} from './queries';

export function useTemplatesPaginatedQuery(props = {}) {
  const {
    key = TEMPLATES_QUERY_KEY,
    config = {},
    teamId,
    projectId,
    queryOrder,
    queryPage,
    pageSize,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, queryOrder, queryPage, pageSize],
    () =>
      templatesPaginatedQuery({
        client,
        teamId,
        projectId,
        queryOrder,
        queryPage,
        pageSize,
      }),
    config
  );
}

export function useTemplatesQuery(props = {}) {
  const { key = TEMPLATES_QUERY_KEY, config = {}, teamId, projectId } = props;
  const { client } = useFetch();
  return useQuery(
    [key],
    () =>
      templatesQuery({
        client,
        teamId,
        projectId,
      }),
    config
  );
}

export function useTemplateQuery(props = {}) {
  const {
    config = {},
    key = TEMPLATES_QUERY_KEY,
    teamId,
    projectId,
    templateId,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, templateId],
    () => templateQuery({ client, teamId, projectId, templateId }),
    config
  );
}

export function useTemplatesInvalidateQuery(props = {}) {
  const { key = TEMPLATES_QUERY_KEY } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key]);
  };
}

export function useTemplateComponentsInvalidateQuery(props = {}) {
  const { key = TEMPLATE_COMPONENTS_QUERY_KEY, templateId } = props;
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([key, templateId]);
  };
}

export function useTemplateCreateMutation(props = {}) {
  const { config, teamId, projectId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) => templateCreateMutation({ client, values, teamId, projectId }),
    config
  );
}

export function useTemplateComponentCreateMutation(props = {}) {
  const { config, teamId, projectId, templateId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) =>
      templateComponentCreateMutation({
        client,
        values,
        teamId,
        projectId,
        templateId,
      }),
    config
  );
}

export function useTemplateUpdateMutation(props = {}) {
  const { config, teamId, projectId, templateId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) =>
      templateUpdateMutation({
        client,
        teamId,
        projectId,
        templateId,
        values,
      }),
    config
  );
}

export function useTemplateComponentsQuery(props = {}) {
  const {
    config = {},
    key = TEMPLATE_COMPONENTS_QUERY_KEY,
    teamId,
    projectId,
    templateId,
  } = props;
  const { client } = useFetch();
  return useQuery(
    [key, templateId],
    () => templateComponentsQuery({ client, teamId, projectId, templateId }),
    config
  );
}

export function useTemplateComponentUpdateMutation(props = {}) {
  const { config, teamId, projectId, templateId } = props;
  const { client } = useFetch();
  return useMutation(
    (values) =>
      templateComponentUpdateMutation({
        client,
        teamId,
        projectId,
        templateId,
        values,
      }),
    config
  );
}

export function useTemplateDeleteMutation(props = {}) {
  const { config, teamId, projectId } = props;
  const { client } = useFetch();

  return useMutation(
    (templateId) =>
      templateDeleteMutation({ client, teamId, projectId, templateId }),
    config
  );
}

export function useTemplateComponentDeleteMutation(props = {}) {
  const { config, teamId, projectId, templateId } = props;
  const { client } = useFetch();

  return useMutation(
    (values) =>
      templateComponentDeleteMutation({
        client,
        teamId,
        projectId,
        templateId,
        values,
      }),
    config
  );
}

export function useSaveTemplateComponentsMutation(props = {}) {
  const { config, teamId, projectId, templateId } = props;
  const { client } = useFetch();

  return useMutation(({ values, transaction }) => {
    if (transaction === 'delete') {
      return templateComponentDeleteMutation({
        client,
        teamId,
        projectId,
        templateId,
        values,
      });
    }

    if (transaction === 'update') {
      return templateComponentUpdateMutation({
        client,
        teamId,
        projectId,
        templateId,
        values,
      });
    }

    return templateComponentCreateMutation({
      client,
      values,
      teamId,
      projectId,
      templateId,
    });
  }, config);
}
