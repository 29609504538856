import { useCallback, useRef, useEffect } from 'react';
import debounce from 'just-debounce-it';

export default function useDebounce(callback, delay) {
  // https://divyanshu013.dev/blog/react-debounce-throttle-hooks/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, []);
  const debouncedFn = useRef(debounce(memoizedCallback, delay));

  useEffect(() => {
    debouncedFn.current = debounce(memoizedCallback, delay);
  }, [memoizedCallback, debouncedFn, delay]);

  return debouncedFn.current;
}
