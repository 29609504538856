import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useField } from 'formik';

import Spinner from '@scalero-inc/forno-spinner';
import Dropdown from '@scalero-inc/forno-dropdown';

import { useNodesQuery } from 'hooks/api/figma/node';

import { getFramesFromFile } from './utils';

import styles from './style.module.css';

function FrameSelectInput({ className, fileId, pageId, ...rest }) {
  const { data, isLoading, error } = useNodesQuery({
    file: fileId,
    nodes: [pageId],
  });
  const [field, , helpers] = useField(rest);
  const customClassName = classnames(
    'frame-select-input',
    styles['frame-select-input'],
    className
  );

  if (error) return null;

  const frames = getFramesFromFile(data, pageId);
  const frameOptions = frames.map((frame) => ({
    label: frame.name,
    value: frame.id,
  }));

  return (
    <div className={customClassName}>
      <label htmlFor={rest.name}>Frames</label>
      {isLoading ? <Spinner /> : null}
      {!isLoading ? (
        <div className={styles['frame-select-options']}>
          <Dropdown
            {...field}
            id={rest.name}
            theme="light"
            size="s"
            className={styles['page-select-input-dropdown']}
            options={frameOptions}
            onChange={(e) => {
              helpers.setValue(e.value);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

FrameSelectInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

FrameSelectInput.defaultProps = {
  className: null,
};

export default FrameSelectInput;
