export async function teamsQuery({ client }) {
  const { data } = await client.get(`teams/`);
  return data;
}

export async function teamMembersQuery({ client, teamId }) {
  const { data } = await client.get(`teams/${teamId}/members/`);
  return data;
}

export async function teamQuery({ client, teamId }) {
  const { data } = await client.get(`teams/${teamId}/`);
  return data;
}
