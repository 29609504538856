import { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button';
import { FiCopy, FiTrash, FiMoreVertical } from 'react-icons/fi';
import IconButton from '@scalero-inc/forno-icon-button';
import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';
import { EditorPanelLeftContext } from 'containers/editor-panel-left/editor-panel-context';
import { addIdsToJson, buildTagId } from 'utils/mjml/util';
import styles from './styles.module.css';

export default function LayoutChildrenMenu({ index }) {
  /* Creating a state for the delete modal. */
  const [deleteIsOpen, setDeleteIsOpen] = useState();
  const openDelete = useCallback(
    () => setDeleteIsOpen(true),
    [setDeleteIsOpen]
  );
  const closeDelete = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );

  const {
    bodyChildren,
    setBodyChildren,
    bodyIndex,
    updateMjmlState,
    jsonState,
    setJsonState,
    setBodyChildrenWithIds,
  } = useContext(EditorPanelLeftContext);

  /**
   * It duplicates the selected section and adds it to the end of the bodyChildren array
   * then it updates the mjmml in the state and in the database
   */
  const handleDuplicate = () => {
    const newBodyChildren = [...bodyChildren];
    const newJson = JSON.parse(JSON.stringify(jsonState));
    const element = { ...newBodyChildren[index] };
    const duplicatedElement = JSON.parse(JSON.stringify(element));

    duplicatedElement.id = buildTagId(duplicatedElement.tagName);
    if (duplicatedElement.attributes.name !== undefined) {
      duplicatedElement.attributes.name = `${duplicatedElement.attributes.name} (copy)`;
    } else {
      duplicatedElement.attributes.name = `${duplicatedElement.tagName} (copy)`;
    }

    newBodyChildren.push(duplicatedElement);
    newJson.children[bodyIndex].children = newBodyChildren;
    const lastIndex = newBodyChildren.length - 1;

    setBodyChildren(newBodyChildren);
    setJsonState(newJson);
    setBodyChildrenWithIds(addIdsToJson(newJson.children[bodyIndex]).children);

    updateMjmlState(newJson, newBodyChildren[lastIndex].id);
  };

  /**
   * It removes the section from the body, updates the MJML, and then updates the MJML
   * in the database
   */
  const handleDelete = () => {
    const newBodyChildren = [...bodyChildren];
    const newJson = JSON.parse(JSON.stringify(jsonState));

    newBodyChildren.splice(index, 1);
    newJson.children[bodyIndex].children = newBodyChildren;

    setBodyChildren(newBodyChildren);
    setJsonState(newJson);
    setBodyChildrenWithIds(addIdsToJson(newJson.children[bodyIndex]).children);

    updateMjmlState(newJson);
  };

  return (
    <>
      <DeleteSectionModal
        isOpen={deleteIsOpen}
        close={closeDelete}
        action={handleDelete}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          theme="dark"
          size="m"
          hierarchy="tertiary"
          className={styles['layout-children-menu-button']}
        >
          <FiMoreVertical className={styles['layout-children-menu-icon']} />
        </MenuButton>
        <MenuList
          className={styles['layout-children-menu-list']}
          data-theme="dark"
        >
          <MenuLink
            className={styles['layout-children-menu-item']}
            onSelect={handleDuplicate}
          >
            <FiCopy />
            Duplicate
          </MenuLink>
          <MenuLink
            className={styles['layout-children-menu-item-delete']}
            onSelect={openDelete}
          >
            <FiTrash />
            Delete
          </MenuLink>
        </MenuList>
      </Menu>
    </>
  );
}

LayoutChildrenMenu.propTypes = {
  index: PropTypes.number.isRequired,
};

function DeleteSectionModal({ isOpen, close, action }) {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      theme="dark"
      aria-label="edit file modal"
      status="danger"
    >
      <Modal.Header theme="dark" title="Delete Section" onDismiss={close} />
      <Modal.Content>
        <p className="font-paragraph-m-sb">
          Are you sure you want to delete this section? <br />
          This action cannot be undone.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button theme="dark" hierarchy="tertiary" onClick={close}>
          Cancel
        </Button>
        <Button
          theme="dark"
          status="danger"
          onClick={() => {
            action();
            close();
          }}
        >
          <FiTrash />
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DeleteSectionModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

DeleteSectionModal.defaultProps = {
  isOpen: false,
};
