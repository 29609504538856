import PropTypes from 'prop-types';

import FileCard from 'containers/file-card';
import ProjectCard from 'containers/project-card';
import ComponentCard from 'containers/component-card';
import TemplateCard from 'containers/template-card';

import styles from './style.module.css';

function CardGrid({
  content,
  type,
  teamId,
  projectId,
  page,
  setPage,
  blocked,
  isEditable,
}) {
  return (
    <div className={styles['file-grid']}>
      {content?.map((item) => {
        const { id } = item;

        return (() => {
          if (type === 'files') {
            return (
              <FileCard
                key={id}
                {...item}
                numFiles={content.length}
                page={page}
                setPage={setPage}
                disabled={blocked}
              />
            );
          }

          if (type === 'blocks') {
            return (
              <ComponentCard
                key={id}
                {...item}
                teamId={teamId}
                numComponents={content.length}
                page={page}
                setPage={setPage}
                disabled={blocked}
                isEditable={isEditable}
              />
            );
          }

          if (type === 'templates') {
            return (
              <TemplateCard
                key={id}
                {...item}
                teamId={teamId}
                projectId={projectId}
                numTemplates={content.length}
                page={page}
                setPage={setPage}
                disabled={blocked}
                isEditable={isEditable}
              />
            );
          }

          return <ProjectCard key={id} {...item} blocked={blocked} />;
        })();
      })}
    </div>
  );
}

CardGrid.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  page: PropTypes.number,
  setPage: PropTypes.func,
  blocked: PropTypes.bool,
  isEditable: PropTypes.bool,
};

CardGrid.defaultProps = {
  content: [],
  teamId: null,
  projectId: null,
  page: 1,
  setPage: () => {},
  blocked: null,
  isEditable: false,
};

export default CardGrid;
