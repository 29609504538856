import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { FiLink2 } from 'react-icons/fi';

import Button from '@scalero-inc/forno-button';
import Input from '@scalero-inc/forno-input';
import Modal from '@scalero-inc/forno-modal';

import { useEspUpdateMutation } from 'hooks/api/esps';

import styles from './style.module.css';

function IntegrationUpdateModal({ isOpen, close, name, docsLink, apiKey, id }) {
  const customClassname = classNames(styles['integration-update-modal']);

  const [key, setKey] = useState(apiKey);
  const { mutate, isLoading } = useEspUpdateMutation({
    id,
    config: {
      onSuccess: () => {
        toast.success('ESP updated successfully');
        close();
      },
      onError: () => {
        toast.error('Error updating ESP');
      },
    },
  });

  const onSubmit = () => {
    mutate({ key });
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      aria-label="update integration modal"
      theme="light"
      dangerouslyBypassFocusLock
      className={customClassname}
    >
      <Modal.Header title={`Edit ${name} integration`} onDismiss={close} />
      <div className={styles['integration-update-modal-content']}>
        <div className={styles['integration-update-modal-form']}>
          <Input
            id="key"
            label="API Key"
            name="key"
            placeholder="API key"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
      </div>
      <Modal.Footer>
        {docsLink ? (
          <Button
            hierarchy="tertiary"
            rel="noreferrer noopener"
            target="_blank"
            href={docsLink}
            icon={FiLink2}
          >
            Obtain API key
          </Button>
        ) : null}
        <Button
          loading={isLoading}
          disabled={isLoading || key.trim() === ''}
          onClick={onSubmit}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

IntegrationUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  name: PropTypes.string,
  docsLink: PropTypes.string,
  apiKey: PropTypes.string,
  id: PropTypes.number,
};

IntegrationUpdateModal.defaultProps = {
  name: '',
  docsLink: null,
  apiKey: '',
  id: null,
};

export default IntegrationUpdateModal;
