import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.css';

function DropdownOption({ label, value, selectedValue, onSelect }) {
  const isSelected = selectedValue.content === value.content;
  const optionClassName = classNames(
    styles.option,
    isSelected && styles['option-selected']
  );

  return (
    <div
      data-theme="dark"
      className={optionClassName}
      role="button"
      tabIndex="0"
      onClick={() => onSelect({ label, value })}
    >
      {label}
    </div>
  );
}

DropdownOption.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape(),
  selectedValue: PropTypes.shape(),
  onSelect: PropTypes.func,
};

DropdownOption.defaultProps = {
  label: '',
  value: {},
  selectedValue: {},
  onSelect: () => {},
};

export default DropdownOption;
