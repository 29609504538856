import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { Menu, MenuLink, MenuList, MenuButton } from '@reach/menu-button';
import IconButton from '@scalero-inc/forno-icon-button';
import { FiCopy, FiEdit, FiLink, FiTrash } from 'react-icons/fi';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import ComponentEditModal from 'containers/component-edit-modal';
import ComponentDeleteModal from 'containers/component-delete-modal';
import {
  useCreateComponentMutation,
  useCreateTeamComponentMutation,
  useTeamComponentsPaginatedInvalidateQuery,
  useUserComponentsPaginatedInvalidateQuery,
} from 'hooks/api/components';

import styles from './styles.module.css';

function ComponentCardMenu({
  id,
  name,
  content,
  file,
  teamId,
  numComponents,
  page,
  setPage,
  isEditable,
}) {
  const copyLink = () => {
    const team = teamId || 'personal';
    copy(`${window.location.origin}/team/${team}/block/${id}/`);
  };

  const invalidateUserComponents = useUserComponentsPaginatedInvalidateQuery();
  const invalidateTeamComponents = useTeamComponentsPaginatedInvalidateQuery({
    teamId,
  });
  const invalidateComponents =
    teamId !== null ? invalidateTeamComponents : invalidateUserComponents;
  const config = {
    onSuccess: () => {
      toast.success(`Block duplicated successfully`);
      invalidateComponents();
    },
    onError: () => {
      toast.error('Failed to duplicate block');
    },
  };
  const userCreateMutation = useCreateComponentMutation({
    config,
  });
  const teamCreateMutation = useCreateTeamComponentMutation({
    teamId,
    config,
  });
  const createComponentMutation =
    teamId !== null ? teamCreateMutation : userCreateMutation;
  const duplicateBlock = () => {
    createComponentMutation.mutate({
      name: `Copy of ${name}`,
      content,
      file,
    });
  };

  const [editIsOpen, setEditIsOpen] = useState(false);
  const openEditWindow = useCallback(
    () => setEditIsOpen(true),
    [setEditIsOpen]
  );
  const closeEditWindow = useCallback(
    () => setEditIsOpen(false),
    [setEditIsOpen]
  );

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const openDeleteWindow = useCallback(
    () => setDeleteIsOpen(true),
    [setDeleteIsOpen]
  );
  const closeDeleteWindow = useCallback(
    () => setDeleteIsOpen(false),
    [setDeleteIsOpen]
  );

  return (
    <div>
      <ComponentEditModal
        id={id}
        name={name}
        teamId={teamId}
        close={closeEditWindow}
        isOpen={editIsOpen}
      />
      <ComponentDeleteModal
        id={id}
        name={name}
        teamId={teamId}
        numComponents={numComponents}
        page={page}
        setPage={setPage}
        onDismiss={closeDeleteWindow}
        isOpen={deleteIsOpen}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          theme="light"
          size="l"
          hierarchy="tertiary"
          className={styles['block-card-menu-button']}
        >
          <BiDotsVerticalRounded />
        </MenuButton>
        <MenuList className={styles['block-card-menu-list']}>
          {isEditable && (
            <MenuLink
              className={styles['block-card-menu-item']}
              onSelect={duplicateBlock}
              disabled={createComponentMutation.isLoading}
            >
              <FiCopy />
              Duplicate
            </MenuLink>
          )}
          <MenuLink
            className={styles['block-card-menu-item']}
            onSelect={copyLink}
          >
            <FiLink />
            Copy link
          </MenuLink>
          {isEditable && (
            <>
              <MenuLink
                className={styles['block-card-menu-item']}
                onSelect={openEditWindow}
              >
                <FiEdit />
                Edit
              </MenuLink>
              <MenuLink
                className={styles['block-card-menu-item']}
                onSelect={openDeleteWindow}
              >
                <FiTrash />
                Delete
              </MenuLink>
            </>
          )}
        </MenuList>
      </Menu>
    </div>
  );
}

ComponentCardMenu.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.shape({
    blockArray: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  file: PropTypes.number.isRequired,
  teamId: PropTypes.string,
  numComponents: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

ComponentCardMenu.defaultProps = {
  teamId: null,
  isEditable: false,
};

export default ComponentCardMenu;
