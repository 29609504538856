export const validateFontFormat = (url) => {
  const fontFormats = ['ttf', 'otf', 'woff', 'woff2'];
  const isFormatValid = fontFormats.some((format) => {
    return url.trim().toLowerCase().endsWith(format);
  });

  return isFormatValid;
};

export const validateFontAccesibility = (url) => {
  return fetch(url)
    .then((response) => {
      if (response.status !== 200) {
        return {
          url: `There is an error with your link: ${response.status}`,
        };
      }
      return null;
    })
    .catch(() => {
      return { url: 'Cannot access the font with this link' };
    });
};
