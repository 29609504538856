import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import ButtonGroup from '@scalero-inc/forno-button-group';
import Spinner from '@scalero-inc/forno-spinner';
import Dropdown from '@scalero-inc/forno-dropdown';
import { useUserQuery } from 'hooks/api/users';
import { Link } from 'react-router-dom';
import { FiDollarSign } from 'react-icons/fi';

import {
  useTeamComponentsPaginatedQuery,
  useUserComponentsPaginatedQuery,
} from 'hooks/api/components';
import Pagination from 'components/pagination';
import FilesContainer from 'containers/files-container';
import {
  canEditOnProFeatures,
  hasAccessToProFeatures,
} from 'utils/permissions';

import { sortingOptions, viewOptions } from './constants';
import styles from './styles.module.css';

function DashboardComponents({ teamId }) {
  const customClassname = classNames(styles['dashboard-blocks']);
  const [view, setView] = useState('grid');
  const onViewChange = useCallback(
    (event) => {
      const newView = event[0];
      setView(newView);
    },
    [setView]
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalBlocks, setTotalBlocks] = useState(0);
  const defaultOrder = sortingOptions[0];
  const [order, setOrder] = useState(defaultOrder.value);
  const queryOrder =
    order === defaultOrder.value ? '-created_at' : 'created_at';
  const userComponentsQuery = useUserComponentsPaginatedQuery({
    config: { reFetchOnWindowFocus: false, enabled: teamId === null },
    queryOrder,
    queryPage: page,
    pageSize,
  });
  const teamComponentsQuery = useTeamComponentsPaginatedQuery({
    config: { reFetchOnWindowFocus: false, enabled: teamId !== null },
    queryOrder,
    queryPage: page,
    pageSize,
    teamId,
  });
  const {
    isLoading: isComponentsLoading,
    isFetching: isComponentsFetching,
    data: componentsData,
  } = teamId !== null ? teamComponentsQuery : userComponentsQuery;

  const onPageChange = useCallback(
    (pageNumber) => {
      setPage(pageNumber);
    },
    [setPage]
  );
  const onPageSizeChange = useCallback(
    (size) => {
      setPageSize(size);
      setPage(1);
    },
    [setPageSize, setPage]
  );

  useEffect(() => {
    if (componentsData) setTotalBlocks(componentsData.count);
  }, [componentsData]);

  const { isLoading: isUserLoading, data: user } = useUserQuery();
  if (isUserLoading) return <Spinner />;

  const isEditable = canEditOnProFeatures(user, teamId);
  const isBlocked = !hasAccessToProFeatures({ user });
  const changePlanUrl = `/change-plan`;

  if (isBlocked) {
    return (
      <>
        <p>
          Only Pro and Team subscriptions have access to Blocks. By upgrading
          your plan, you can unlock the full potential of Blocks for use within
          Templates. Please consider upgrading your subscription.
        </p>
        <Link
          to={changePlanUrl}
          className="forno-button font-block-m-sb"
          data-size="m"
          data-hierarchy="primary"
          data-theme="light"
        >
          <FiDollarSign />
          Change plan
        </Link>
      </>
    );
  }

  return (
    <div className={customClassname}>
      <div className={styles['dashboard-blocks-actions']}>
        <div className={styles['dashboard-blocks-right-actions']}>
          <div className={styles['dashboard-blocks-action-wrapper']}>
            <span>Sort By:</span>
            <Dropdown
              size="s"
              theme="light"
              options={sortingOptions}
              defaultValue={defaultOrder}
              className={styles['dashboard-blocks-sort-dropdown']}
              onChange={(e) => {
                setOrder(e.value);
              }}
              value={order}
              look="text"
            />
          </div>
          <div className={styles['dashboard-blocks-action-wrapper']}>
            <span>View: </span>
            <ButtonGroup
              type="radio"
              theme="light"
              options={viewOptions}
              onChange={onViewChange}
              initialValue={[view]}
              className={styles['dashboard-blocks-view-buttons']}
            >
              {viewOptions.map(({ value, icon: Icon }) => {
                return (
                  <ButtonGroup.Button key={value} value={value}>
                    <Icon />
                  </ButtonGroup.Button>
                );
              })}
            </ButtonGroup>
          </div>
        </div>
      </div>

      <div className={styles['dashboard-blocks-container']}>
        <FilesContainer
          type="blocks"
          teamId={teamId}
          view={view}
          files={componentsData?.results || []}
          page={page}
          setPage={onPageChange}
          isBlocked={isBlocked}
          isEditable={isEditable}
          isLoading={isComponentsLoading || isComponentsFetching}
        />
      </div>

      <Pagination
        total={totalBlocks}
        current={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
}

DashboardComponents.propTypes = {
  teamId: PropTypes.string,
};

DashboardComponents.defaultProps = {
  teamId: null,
};

export default DashboardComponents;
