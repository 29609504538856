import messages from '../../messages';

export function getErrors(background, children, rest) {
  const errors = [];
  errors.push(messages.warning(messages.MSG.URL_LINK, rest));

  if (!background) {
    errors.push(messages.warning(messages.MSG.BACKGROUND_COLOR, rest));
  }

  if (!children || children.tagName !== 'mj-text') {
    errors.push(messages.error(messages.MSG.NO_TEXT_CONTENT, rest));
  } else {
    errors.push(...children.errors);
  }

  return errors;
}

export function getInnerPadding(height, textStyles) {
  if (!textStyles['line-height']) {
    return { innerPadding: '10px' };
  }

  const lineHeight = textStyles['line-height'].replace('px', '');
  const padding = Math.round((height - lineHeight) / 2);
  const innerPad = `${padding}px 0px ${padding}px 0px`;

  return { innerPadding: innerPad };
}
