import React from 'react';
import headshot from 'images/home/priya-quote.png';

import styles from './style.module.css';

function QuoteSection() {
  return (
    <>
      <div className={styles.divider} />
      <section className={styles['quote-section']}>
        <div className={styles['quote-section-wrapper']}>
          <img
            className={styles['speaker-image']}
            src={headshot}
            alt="Speaker"
          />
          <div className={styles['text-wrapper']}>
            <p className={styles.quote}>
              “Cannoli is so fast and so easy, I have all of my clients using
              it. We are saving hours in creative and development work by
              leveraging Cannoli, and our designs have never looked better.”
            </p>
            <p className={styles['speaker-text']}>
              Priya Prasad, Founder, Point Blank Digital
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuoteSection;
