import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from '@scalero-inc/forno-avatar';
import ProfileMenu from 'components/profile-menu';

import { useAuth } from 'context/auth';

import styles from './styles.module.css';

function ProfileContainer({ isCollapsed, user }) {
  const customClassName = classNames(styles['profile-container'], {
    [styles.collapsed]: isCollapsed,
  });

  const { currentProfile } = useAuth();

  const profile = React.useMemo(() => {
    if (currentProfile?.id) {
      return currentProfile;
    }

    return user;
  }, [currentProfile, user]);

  return (
    <div key={profile?.id} className={customClassName}>
      <Avatar
        size="m"
        firstName={profile?.first_name || profile?.name}
        lastName={profile?.last_name || ' '}
        src={profile?.avatar || profile?.logo}
        className={styles['profile-avatar']}
      />
      {isCollapsed ? null : (
        <p>
          {profile?.last_name
            ? `${profile?.first_name} ${profile?.last_name}`
            : profile?.name}
        </p>
      )}
      <ProfileMenu user={user} />
    </div>
  );
}

ProfileContainer.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    logo: PropTypes.string,
  }),
  isCollapsed: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

ProfileContainer.defaultProps = {
  profile: {},
  user: {},
};

export default ProfileContainer;
