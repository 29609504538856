import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RadioButton from 'components/radio-button';

import styles from './style.module.css';

export default function RadioButtonGroup({
  className,
  name,
  options,
  onChange,
  defaultValueIndex,
  disabled,
}) {
  const [state, setState] = useState(options[defaultValueIndex].value);
  const customClassName = classnames(
    'radio-button-group',
    styles['radio-button-group'],
    className
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setState(value);
    onChange(value);
  };

  return (
    <div className={customClassName} onChange={handleChange}>
      {options.map(({ label, value }) => {
        const isChecked = state === value;
        return (
          <RadioButton
            key={value}
            name={name}
            value={value}
            label={label}
            checked={isChecked}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
}

RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string }))
    .isRequired,
  onChange: PropTypes.func,
  defaultValueIndex: PropTypes.number,
  disabled: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  className: null,
  onChange: () => {},
  defaultValueIndex: 0,
  disabled: false,
};
