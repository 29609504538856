import emailKitSrc from 'images/cannoli-app/email-kit-resource.png';
import figmaPluginSrc from 'images/cannoli-app/figma-plugin-resource.png';
import docsAndTutorialsSrc from 'images/cannoli-app/docs-and-tutorials-resource.png';

export const resourceCards = [
  {
    id: 0,
    title: 'Email kit with examples',
    description:
      'Examples to get you going fast. From basic components to complete emails, everything you need to build a Cannoli-ready design is here.',
    image: {
      src: emailKitSrc,
      alt: 'Email kit resource',
    },
    link: 'https://www.figma.com/community/file/1042861645873665842',
  },
  {
    id: 1,
    title: 'Cannoli Figma plugin',
    description:
      'Learn to use Cannoli on the fly. Use the layout builder to setup your artboards correctly, or use the pre-built components to quickly build great emails.',
    image: {
      src: figmaPluginSrc,
      alt: 'Figma plugin resource',
    },
    link: 'https://www.figma.com/community/plugin/1053770884164943496/Cannoli---Figma-to-email-code%2C-including-HTML-and-MJML',
  },
  {
    id: 2,
    title: 'Docs and tutorials',
    description:
      'Step by step instructions on how to get started, build your Figma file, export to Cannoli and do your last mile edits.',
    image: {
      src: docsAndTutorialsSrc,
      alt: 'Docs and tutorials resource',
    },
    link: 'https://scalero.notion.site/Get-started-on-Cannoli-a5995621dc924dfb9600f67e5182691a',
  },
];
