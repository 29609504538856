import useDimensions from 'react-cool-dimensions';

import HtmlToReact from 'components/html-to-react';

import EditorPreviewIframe from './read-only-preview-iframe';
import EditorPreviewImage from './read-only-preview-image';
import EditorPreviewCompare from './read-only-preview-compare';

import { useReadOnly } from '../read-only-context';
import {
  useReadOnlyView,
  useReadOnlyViewDispatch,
} from '../read-only-view-context';

import styles from './style.module.css';

function ReadOnlyPreview() {
  const { htmlWithIds } = useReadOnly();
  const { viewPreview } = useReadOnlyView();
  const { setViewPreviewSize } = useReadOnlyViewDispatch();
  const { observe } = useDimensions({
    onResize: ({ width }) => {
      setViewPreviewSize(width);
    },
  });
  return (
    <section className={styles['preview-wrapper']} ref={observe}>
      {viewPreview === 'iframe' ? (
        <EditorPreviewIframe>
          <HtmlToReact html={htmlWithIds} />
        </EditorPreviewIframe>
      ) : null}
      {viewPreview === 'image' ? <EditorPreviewImage /> : null}
      {viewPreview === 'compare' ? <EditorPreviewCompare /> : null}
    </section>
  );
}

ReadOnlyPreview.propTypes = {};

ReadOnlyPreview.defaultProps = {};

export default ReadOnlyPreview;
