import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';

import Textarea from '@scalero-inc/forno-textarea';
import Input from '@scalero-inc/forno-input';
import ButtonGroup from '@scalero-inc/forno-button-group';

import { CANNOLI_SERVICES } from 'pages/cannoli-services/data';
import { useCreateOrderMutation } from 'hooks/api/cannoli-services';

import { InputField } from './utils';

import styles from './styles.module.css';
import OrderSummaryCard from '../order-summary-card';

function CheckoutForm({ orderType }) {
  const location = useLocation();

  React.useEffect(() => {
    const root = document.getElementById('root');
    if (root) root.scrollIntoView();
  }, [location]);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    brief: '',
    number_of_tasks: 0,
    isTermsAccepted: false,
    wants_newsletter: false,
    order_type: orderType,
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Required').min(1),
    last_name: Yup.string().required('Required').min(1),
    email: Yup.string()
      .required('Required')
      .email('Invalid email address')
      .min(1),
    brief: Yup.string().required('Required').min(1),
    number_of_tasks: Yup.number().min(1, 'Invalid amount').required('Required'),
    isTermsAccepted: Yup.bool().oneOf([true], 'Policies must be accepted.'),
    wants_newsletter: Yup.bool(),
    order_type: Yup.string()
      .oneOf([CANNOLI_SERVICES.WOD.id, CANNOLI_SERVICES.WD.id])
      .required('Required'),
    figma_url: Yup.string().when('order_type', {
      is: CANNOLI_SERVICES.WD.id,
      then: Yup.string()
        .matches(/^https:\/\/www\.figma\.com\//, 'Figma URL is required')
        .required('Figma URL is required'),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const mutation = useCreateOrderMutation({
    config: {
      onSuccess: (data) => {
        setIsSubmitting(false);
        window.location.assign(data.checkout);
      },
      onError: () => {
        setIsSubmitting(false);
        console.error('Error creating order');
      },
    },
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const updatedValues = { ...values };

        if (
          updatedValues.figma_url &&
          updatedValues.order_type === CANNOLI_SERVICES.WD.id
        ) {
          updatedValues.brief = `${updatedValues.brief}\n\nFigma URL: ${updatedValues.figma_url}`;
        }

        mutation.mutate(updatedValues);
        setIsSubmitting(true);
      }}
    >
      {(formik) => {
        return (
          <Form>
            <div className={styles['form-container']}>
              <div className={styles['form-inputs']}>
                <div className={styles['form-paired-inputs']}>
                  <Field
                    id="first_name"
                    name="first_name"
                    type="text"
                    as={Input}
                    label={<p className="paragraph-s">First Name*</p>}
                    warningText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                  <Field
                    id="last_name"
                    name="last_name"
                    type="text"
                    as={Input}
                    label={<p className="paragraph-s">Last Name*</p>}
                    warningText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                </div>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  as={Input}
                  label={<p className="paragraph-s">Email*</p>}
                  warningText={formik.touched.email && formik.errors.email}
                />
                <div>
                  <p className="paragraph-s">Choose your order type:</p>
                  <ButtonGroup
                    type="radio"
                    theme="light"
                    initialValue={[orderType]}
                    onChange={(e) => {
                      const orderTypeValue = e[0];
                      formik.setFieldValue('order_type', orderTypeValue);
                    }}
                  >
                    <ButtonGroup.Button
                      value={CANNOLI_SERVICES.WD.id}
                      key="only-code"
                    >
                      Only code (${CANNOLI_SERVICES.WD.price})
                    </ButtonGroup.Button>
                    <ButtonGroup.Button
                      value={CANNOLI_SERVICES.WOD.id}
                      key="design-plus-code"
                    >
                      Design + Code (${CANNOLI_SERVICES.WOD.price})
                    </ButtonGroup.Button>
                  </ButtonGroup>
                </div>
                {formik.values.order_type !== '' ? (
                  <>
                    {(() => {
                      const description =
                        formik.values.order_type === CANNOLI_SERVICES.WD.id
                          ? 'Make sure to add a description, Figma URLs, custom font URLs and any other necessary information for us to complete your project.'
                          : 'We take care of the design phase, be sure to provide all your email specifications, such as copy, logos, images, colors, fonts and font URLs and any links you’d like to include. Read more about our policies here.';

                      return (
                        <InputField
                          name="brief"
                          label={
                            <span className={styles['description-label']}>
                              <p className="paragraph-s">
                                Project Description*
                              </p>
                              <p className="paragraph-s">{description}</p>
                            </span>
                          }
                          as={Textarea}
                        />
                      );
                    })()}

                    {formik.values.order_type === CANNOLI_SERVICES.WD.id ? (
                      <InputField
                        name="figma_url"
                        label={<p className="paragraph-s">Figma URL*</p>}
                      />
                    ) : null}

                    <InputField
                      name="number_of_tasks"
                      label={<p className="paragraph-s"># Number of Emails*</p>}
                      type="number"
                    />
                  </>
                ) : null}
              </div>

              <OrderSummaryCard
                isDisabled={
                  !formik.isValid ||
                  isSubmitting ||
                  formik.values === initialValues
                }
                loading={isSubmitting}
                numberOfTasks={formik.values.number_of_tasks}
                formik={formik}
                unitPrice={
                  CANNOLI_SERVICES[formik.values.order_type]
                    ? CANNOLI_SERVICES[formik.values.order_type].price
                    : 0
                }
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

CheckoutForm.propTypes = {
  orderType: PropTypes.string,
};

CheckoutForm.defaultProps = {
  orderType: '',
};

export default CheckoutForm;
