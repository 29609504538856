import React from 'react';
import PropTypes from 'prop-types';

import { Field, ErrorMessage } from 'formik';

import Input from '@scalero-inc/forno-input';

import styles from './styles.module.css';

const getOrdinalSuffix = (number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = number % 100;
  return number + (s[(v - 20) % 10] || s[v] || s[0]);
};

const formatDeliveryDate = (date) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dayName = days[date.getDay()];
  const monthName = months[date.getMonth()];
  const dayOfMonth = getOrdinalSuffix(date.getDate());
  const year = date.getFullYear();

  const hours = date.getHours() % 12 || 12;
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'pm' : 'am';

  return `${dayName}, ${dayOfMonth} ${monthName} ${year}, ${hours}:${minutes} ${ampm}`;
};

// delivery date is 48 hours from now
const deliveryDateObj = new Date(Date.now() + 48 * 60 * 60 * 1000);
export const deliveryDate = formatDeliveryDate(deliveryDateObj);

export function InputField({ name, label, type = 'text', as = Input }) {
  return (
    <div
      className={`${styles['form-input-field']} ${
        type === 'number' ? styles.number : ''
      }`}
    >
      <label htmlFor={name} className={styles['form-label']}>
        <p className="font-block-s">{label}</p>
      </label>
      <Field id={name} name={name} type={type} as={as} min="1" placeholder="" />
      <span className={`font-block-s ${styles['error-message']}`}>
        <ErrorMessage name={name} />
      </span>
    </div>
  );
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  as: PropTypes.elementType,
};

InputField.defaultProps = {
  type: 'text',
  as: Input,
};

export function CheckboxField({ name, label, formik }) {
  return (
    <>
      <span
        className={`${styles['terms-and-signup-check']} ${
          formik.values[name] && styles.checked
        }`}
      >
        <Field type="checkbox" name={name} id={name} />
        <label htmlFor={name} className="paragraph-s" id={name}>
          {label}
        </label>
      </span>
      {name === 'isTermsAccepted' && (
        <span className={`font-block-s ${styles['error-message']}`}>
          <ErrorMessage name={name} />
        </span>
      )}
    </>
  );
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
  }).isRequired,
};
