import { getBorderAttributes } from './border';
import { tagsDicts } from './parser/dictionaries';

export function getWidth({ absoluteBoundingBox }) {
  const { width } = absoluteBoundingBox;
  return Math.round(width);
}

export function getHeight({ absoluteBoundingBox }) {
  const { height } = absoluteBoundingBox;
  return Math.round(height);
}

export function getX2(element) {
  return Math.round(
    element.absoluteBoundingBox.x + element.absoluteBoundingBox.width
  );
}

export function getY2(element) {
  return Math.round(
    element.absoluteBoundingBox.y + element.absoluteBoundingBox.height
  );
}

export function getPaddingTop(parent, child) {
  return Math.round(child.absoluteBoundingBox.y - parent.absoluteBoundingBox.y);
}

export function getPaddingRight(parent, child) {
  return Math.round(getX2(parent) - getX2(child));
}

export function getPaddingBottom(parent, child) {
  return Math.round(getY2(parent) - getY2(child));
}

export function getPaddingLeft(parent, child) {
  return Math.round(child.absoluteBoundingBox.x - parent.absoluteBoundingBox.x);
}

export function getPaddingBySiblings(parent, nodeId) {
  const currentElementIndex = parent.children.findIndex((child) => {
    return child.id === nodeId;
  });
  const currentElement = parent.children[currentElementIndex];
  const previousElement = parent.children[currentElementIndex - 1];
  const nextElement = parent.children[currentElementIndex + 1];

  const paddingT = !previousElement ? getPaddingTop(parent, currentElement) : 0;
  const paddingR = getPaddingRight(parent, currentElement);
  const paddingB = nextElement
    ? Math.round(nextElement.absoluteBoundingBox.y - getY2(currentElement))
    : getPaddingBottom(parent, currentElement);
  const paddingL = getPaddingLeft(parent, currentElement);

  return {
    paddingTop: paddingT <= 0 ? 0 : paddingT,
    paddingRight: paddingR <= 0 ? 0 : paddingR,
    paddingBottom: paddingB <= 0 ? 0 : paddingB,
    paddingLeft: paddingL <= 0 ? 0 : paddingL,
  };
}

export function getPaddingByClosestChild(parent, nodeId) {
  const isParentWrapper = tagsDicts
    .find((element) => element.tag === 'mj-wrapper')
    .dict.some((element) => parent.name.includes(element));
  const isParentSection = tagsDicts
    .find((element) => element.tag === 'mj-section')
    .dict.some((element) => parent.name.includes(element));

  const paddingAround =
    !isParentWrapper && !isParentSection
      ? getPaddingBySiblings(parent, nodeId)
      : { paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 };

  const currentElement = parent.children.find((child) => {
    return child.id === nodeId;
  });
  const closestChildTop = currentElement.children.sort((a, b) => {
    return a.absoluteBoundingBox.y > b.absoluteBoundingBox.y ? 1 : -1;
  })[0];
  const closestChildRight = currentElement.children.sort((a, b) => {
    return getX2(a) < getX2(b) ? 1 : -1;
  })[0];
  const closestChildBottom = currentElement.children.sort((a, b) => {
    return getY2(a) < getY2(b) ? 1 : -1;
  })[0];
  const closestChildLeft = currentElement.children.sort((a, b) => {
    return a.absoluteBoundingBox.x > b.absoluteBoundingBox.x ? 1 : -1;
  })[0];

  const { borderWidth, borderColor } = getBorderAttributes({
    ...currentElement,
  });
  const border = borderColor ? borderWidth.replace('px', '') : 0;

  const paddingT = closestChildTop
    ? paddingAround.paddingTop +
      getPaddingTop(currentElement, closestChildTop) -
      border
    : 0;
  const paddingR = closestChildRight
    ? paddingAround.paddingRight +
      getPaddingRight(currentElement, closestChildRight) -
      border
    : 0;
  const paddingB = closestChildBottom
    ? paddingAround.paddingBottom +
      getPaddingBottom(currentElement, closestChildBottom) -
      border
    : 0;
  const paddingL = closestChildLeft
    ? paddingAround.paddingLeft +
      getPaddingLeft(currentElement, closestChildLeft) -
      border
    : 0;

  return {
    paddingTop: paddingT <= 0 ? 0 : paddingT,
    paddingRight: paddingR <= 0 ? 0 : paddingR,
    paddingBottom: paddingB <= 0 ? 0 : paddingB,
    paddingLeft: paddingL <= 0 ? 0 : paddingL,
  };
}

export function getPadding(parent, node, nodeType) {
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } =
    nodeType === 'mj-wrapper' ||
    nodeType === 'mj-section' ||
    nodeType === 'mj-column'
      ? getPaddingByClosestChild(parent, node.id)
      : getPaddingBySiblings(parent, node.id);

  // It will not add padding property
  if (
    paddingTop === 0 &&
    paddingRight === 0 &&
    paddingBottom === 0 &&
    paddingLeft === 0
  ) {
    return null;
  }

  // It will not add padding left/right
  if (
    nodeType === 'mj-button' ||
    nodeType === 'mj-image' ||
    nodeType === 'mj-social' ||
    nodeType === 'mj-text'
  ) {
    return { padding: `${paddingTop}px 0px ${paddingBottom}px 0px` };
  }

  // It will not add padding top/bottom
  if (nodeType === 'mj-column') {
    return { padding: `0px ${paddingRight}px 0px ${paddingLeft}px` };
  }

  return {
    padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
  };
}

export function getRightSpacingBySibling(parent, nodeId) {
  const currentColumnIndex = parent.children.findIndex((child) => {
    return child.id === nodeId;
  });
  const currentElement = parent.children[currentColumnIndex];
  const nextElement = parent.children[currentColumnIndex + 1];

  if (!nextElement) {
    return 0;
  }

  return Math.round(nextElement.absoluteBoundingBox.x - getX2(currentElement));
}
