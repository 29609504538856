import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiDollarSign, FiArrowRight } from 'react-icons/fi';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@scalero-inc/forno-button';

import SubscriptionCancelModal from 'containers/subscription-cancel-modal';
import SubscriptionUpdateSeatsModal from 'containers/subscription-update-seats-modal';

import { useCompleteSubscription } from 'hooks/api/subscriptions';

import styles from './style.module.css';

function BillingPlanCta({
  plan,
  subscriptionId,
  status,
  subscriptionCanceled,
  hasValidTrial,
}) {
  const [cancelIsOpen, setCancelIsOpen] = useState(false);
  const openCancel = useCallback(
    () => setCancelIsOpen(true),
    [setCancelIsOpen]
  );
  const closeCancel = useCallback(
    () => setCancelIsOpen(false),
    [setCancelIsOpen]
  );
  const [teamSeatsIsOpen, setTeamSeatsIsOpen] = useState(false);
  const openTeamSeats = useCallback(
    () => setTeamSeatsIsOpen(true),
    [setTeamSeatsIsOpen]
  );
  const closeTeamSeats = useCallback(
    () => setTeamSeatsIsOpen(false),
    [setTeamSeatsIsOpen]
  );

  const { mutate: completeSubscription } = useCompleteSubscription({
    subscriptionId,
    config: {
      onSuccess: (data) => window.location.replace(data.checkout),
      onError: () =>
        toast.error(
          'There was an error with your request, please contact support'
        ),
    },
  });

  const changePlanUrl = `/change-plan?from=${plan.name.toLowerCase()}`;

  // depending on what the user's plan is, and depending on its status, we render different actions

  if (subscriptionCanceled) return null;

  if (plan.name !== 'Starter' && status.label === 'Pending') {
    return (
      <div className={styles['billing-plan-cta']}>
        <Button status="danger" onClick={() => completeSubscription()}>
          Finish set up <FiArrowRight />
        </Button>
      </div>
    );
  }

  if (plan.name === 'Starter' || hasValidTrial) {
    return (
      <div className={styles['billing-plan-cta']}>
        <Link
          to={changePlanUrl}
          className="forno-button font-block-m-sb"
          data-size="m"
          data-hierarchy="primary"
          data-theme="light"
        >
          <FiDollarSign />
          Change plan
        </Link>
      </div>
    );
  }

  if (plan.name === 'Team') {
    return (
      <div className={styles['billing-plan-cta']}>
        <SubscriptionUpdateSeatsModal
          isOpen={teamSeatsIsOpen}
          close={closeTeamSeats}
          subscriptionId={subscriptionId}
        />
        <SubscriptionCancelModal
          isOpen={cancelIsOpen}
          close={closeCancel}
          subscriptionId={subscriptionId}
          planName="Team"
        />
        <Button onClick={openTeamSeats}>Update team seats</Button>
        <Button hierarchy="tertiary" onClick={openCancel}>
          Cancel plan
        </Button>
      </div>
    );
  }

  if (plan.name === 'Pro') {
    return (
      <div className={styles['billing-plan-cta']}>
        <SubscriptionCancelModal
          isOpen={cancelIsOpen}
          close={closeCancel}
          subscriptionId={subscriptionId}
          planName="Pro"
        />
        <Link
          to={changePlanUrl}
          className="forno-button font-block-m-sb"
          data-size="m"
          data-hierarchy="primary"
          data-theme="light"
        >
          <FiDollarSign />
          Change plan
        </Link>
        <Button status="danger" onClick={openCancel}>
          Cancel plan
        </Button>
      </div>
    );
  }
}

BillingPlanCta.propTypes = {
  plan: PropTypes.shape({ name: PropTypes.string }).isRequired,
  subscriptionId: PropTypes.number.isRequired,
  status: PropTypes.shape({ label: PropTypes.string }).isRequired,
  subscriptionCanceled: PropTypes.bool.isRequired,
  hasValidTrial: PropTypes.bool,
};

BillingPlanCta.defaultProps = {
  hasValidTrial: false,
};

export default BillingPlanCta;
