import PropTypes from 'prop-types';
import { CgTrash } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Modal from '@scalero-inc/forno-modal';
import Button from '@scalero-inc/forno-button';

import {
  useProjectDeleteMutation,
  useTeamProjectsInvalidateQuery,
} from 'hooks/api/projects';
import { useFilesByTeamProjectInvalidateQuery } from 'hooks/api/files';

function ProjectDeleteModal({ onDismiss, isOpen, id, name, team }) {
  const invalidateFiles = useFilesByTeamProjectInvalidateQuery();
  const invalidateProjects = useTeamProjectsInvalidateQuery();
  const navigate = useNavigate();

  const { mutate, isLoading } = useProjectDeleteMutation({
    team,
    project: id,
    config: {
      onSuccess: () => {
        toast.success('Deleted project successfully');
        onDismiss();
        invalidateFiles();
        invalidateProjects();
        navigate(`/dashboard/teams/${team}/`);
      },
      onError: () => {
        toast.error('Failed to delete project');
      },
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      status="danger"
      aria-label="delete file warning"
      theme="light"
      dangerouslyBypassFocusLock
    >
      <Modal.Header
        title={`Delete ${name}`}
        onDismiss={onDismiss}
        icon={CgTrash}
      />
      <p>
        Are you sure you want to delete the project <strong>{name}</strong>?
      </p>
      <p>
        This action <strong>cannot</strong> be undone.
      </p>
      <Modal.Footer>
        <Button theme="light" hierarchy="tertiary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          status="danger"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => {
            mutate(id);
          }}
        >
          <CgTrash />
          <span>Delete</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ProjectDeleteModal.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  team: PropTypes.number.isRequired,
};

ProjectDeleteModal.defaultProps = {
  isOpen: false,
};

export default ProjectDeleteModal;
