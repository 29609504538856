import React, {
  useContext,
  createContext,
  useReducer,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import {
  useCloseRegenCompareMutation,
  useInitRegenCompareMutation,
  useRefeshRegenCompareMutation,
  useRegenCompareInvalidateQuery,
  useRegenCompareQuery,
  useSaveRegenCompareMutation,
} from 'hooks/api/regen-compare';

import { getStateFromMjml } from './functions';
import { EMPTY_MJML_CODE } from './constants';
import { useEditor, useEditorDispatch } from '../editor-context';

const RebaseStateContext = createContext();
const RebaseDispatchContext = createContext();

const SET_CODE_IS_VALID = 'SET_CODE_IS_VALID';
const UPDATE_REBASE_CODE = 'UPDATE_REBASE_CODE';
const SET_REBASE_SOURCE = 'SET_REBASE_SOURCE';
const UPDATE_FIGMA_CODE = 'UPDATE_FIGMA_CODE';
const UPDATE_CANNOLI_CODE = 'UPDATE_CANNOLI_CODE';
const INIT_LOADING = 'INIT_LOADING';
const END_LOADING = 'END_LOADING';
const RESET_FIGMA_CODE = 'RESET_FIGMA_CODE';

const actions = {
  [UPDATE_FIGMA_CODE]: (state, action) => ({
    ...state,
    figmaCode: getStateFromMjml(action.payload.mjml),
  }),
  [UPDATE_CANNOLI_CODE]: (state, action) => ({
    ...state,
    cannoliCode: getStateFromMjml(action.payload.mjml),
  }),
  [UPDATE_REBASE_CODE]: (state, action) => ({
    ...state,
    rebaseCode: getStateFromMjml(action.payload.mjml),
  }),
  [SET_CODE_IS_VALID]: (state, action) => ({
    ...state,
    codeIsValid: action.payload.codeIsValid,
  }),
  [SET_REBASE_SOURCE]: (state, action) => ({
    ...state,
    rebaseCode: action.payload.sourceCode,
  }),
  [INIT_LOADING]: (state) => ({ ...state, isLoading: true }),
  [END_LOADING]: (state) => ({ ...state, isLoading: false }),
  [RESET_FIGMA_CODE]: (state) => ({
    ...state,
    figmaCode: null,
  }),
};

function rebaseReducer(state, action) {
  const handler = actions[action.type];
  if (!handler) throw new Error(`Unhandled action type: ${action.type}`);
  return handler(state, action);
}

const DynamicSourcesContext = createContext();

function DynamicSourcesProvider({ children, sources }) {
  return (
    <DynamicSourcesContext.Provider value={sources}>
      {children}
    </DynamicSourcesContext.Provider>
  );
}

DynamicSourcesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sources: PropTypes.shape({}).isRequired,
};

function RebaseProvider({ children }) {
  const { fileId } = useParams();

  const [sessionState, setSessionState] = useState({
    firstSession: false,
    newSession: false,
  });

  const [workerBusy, setWorkerBusy] = useState(false);

  const query = useRegenCompareQuery({ fileId });
  const mutation = useInitRegenCompareMutation({ fileId });

  const { mjml } = useEditor();

  const mjmlStateCannoli = useMemo(() => getStateFromMjml(mjml), [mjml]);

  const [mjmlStateFigma, setMjmlStateFigma] = useState(() =>
    getStateFromMjml('')
  );

  const mjmlStateRebase = useMemo(() => getStateFromMjml(''), []);

  const [state, dispatch] = useReducer(rebaseReducer, {
    figmaCode: mjmlStateFigma,
    cannoliCode: mjmlStateCannoli,
    rebaseCode: mjmlStateRebase,
  });

  const [session, setSession] = useState(null);

  const handleMutation = () => {
    dispatch({ type: INIT_LOADING });

    mutation.mutate(
      {},
      {
        onSuccess: (data) => {
          setSession(data);
          setWorkerBusy(false);
        },
        onError: () => {
          toast.error('Worker is busy, please try again later');
          setWorkerBusy(true);
          dispatch({ type: END_LOADING });
        },
      }
    );
  };

  useEffect(() => {
    if (
      query.isIdle ||
      (query.data && query.data.length === 0 && !sessionState.firstSession)
    ) {
      handleMutation();
      setSessionState({ ...sessionState, firstSession: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isIdle, query.data, mutation, fileId, sessionState.firstSession]);

  useEffect(() => {
    if (workerBusy) {
      return;
    }

    if (session && session.closed_at !== null && !sessionState.newSession) {
      handleMutation();
      setSessionState({ ...sessionState, newSession: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation, sessionState.newSession, session]);

  useEffect(() => {
    if (query.data && query.data.length > 0) {
      const lastElement = query.data[query.data.length - 1];
      setSession(lastElement);
    }
  }, [query.data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        session &&
        (session.processing_errors !== null ||
          session.successfully_processed !== null)
      ) {
        clearInterval(intervalId);
        dispatch({ type: END_LOADING });

        const newFigmaState = getStateFromMjml(session.figma_mjml);

        setMjmlStateFigma(newFigmaState);
        setSessionState({ firstSession: false, newSession: workerBusy });
      } else {
        query.refetch();
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [session, query, workerBusy]);

  const dynamicSources = {
    mjmlCannoli: mjml,
    mjmlFigma: session?.figma_mjml,
    regenId: session?.id,
    refetch: query.refetch,
  };

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIGMA_CODE',
      payload: { mjml: mjmlStateFigma?.mjml },
    });
  }, [mjmlStateFigma, dispatch]);

  useEffect(() => {
    if (state.cannoliCode && !state.rebaseCode) {
      dispatch({
        type: 'UPDATE_REBASE_CODE',
        payload: { mjml: state.cannoliCode.mjml },
      });
    }
  }, [state.cannoliCode, state.rebaseCode, dispatch]);

  const contextValue = useMemo(() => {
    return { state, dispatch, setSession };
  }, [state, dispatch]);

  return (
    <DynamicSourcesProvider sources={dynamicSources}>
      <RebaseStateContext.Provider value={state}>
        <RebaseDispatchContext.Provider value={contextValue}>
          {children}
        </RebaseDispatchContext.Provider>
      </RebaseStateContext.Provider>
    </DynamicSourcesProvider>
  );
}

RebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useRebase() {
  const context = useContext(RebaseStateContext);
  if (!context)
    throw new Error('Rebase State must be used within a RebaseProvider');
  return context;
}

function useRebaseDispatch() {
  const { dispatch } = useContext(RebaseDispatchContext);
  const dynamicSources = useContext(DynamicSourcesContext);

  const { regenId, refetch } = dynamicSources;

  const { fileId, teamId } = useParams();

  const { editor } = useEditorDispatch(teamId, fileId);

  const saveMutation = useSaveRegenCompareMutation({ fileId, regenId });
  const closeMutation = useCloseRegenCompareMutation({ fileId, regenId });
  const refreshMutation = useRefeshRegenCompareMutation({ fileId, regenId });

  const invalidate = useRegenCompareInvalidateQuery();

  if (!dispatch)
    throw new Error('useRebaseDispatch must be used within a RebaseProvider');

  const sources = useMemo(
    () => ({
      mjmlCannoli: dynamicSources.mjmlCannoli,
      mjmlFigma: dynamicSources.mjmlFigma,
      mjmlEmpty: EMPTY_MJML_CODE,
    }),
    [dynamicSources]
  );

  const setRebaseSource = useCallback(
    (sourceName) => {
      const sourceCode = getStateFromMjml(sources[sourceName]);

      dispatch({ type: SET_REBASE_SOURCE, payload: { sourceCode } });
    },
    [dispatch, sources]
  );

  const updateRebaseCode = (mjml) => {
    dispatch({ type: UPDATE_REBASE_CODE, payload: { mjml } });
  };

  const setCodeIsValid = useCallback(
    (value) => {
      dispatch({ type: SET_CODE_IS_VALID, payload: { codeIsValid: value } });
    },
    [dispatch]
  );

  const updateMjml = (mjml) => {
    return updateRebaseCode(mjml);
  };

  const save = (mjml, config = {}) => {
    return saveMutation.mutate(
      {
        new_mjml: mjml,
      },
      {
        onSuccess: (data) => {
          const { new_mjml: newMjml } = data;
          editor.save(newMjml);
          closeMutation.mutate();

          if (config.afterSave && typeof config.afterSave === 'function') {
            config.afterSave(data);
          }
        },
        onError: () => {
          if (config.onFailure && typeof config.onFailure === 'function') {
            config.onFailure();
          }
        },
      }
    );
  };

  const refresh = () => {
    dispatch({ type: RESET_FIGMA_CODE });
    return refreshMutation.mutate(
      {},
      {
        onSuccess: () => {
          invalidate();
          refetch();
        },
      }
    );
  };

  return {
    dispatch,
    updateRebaseCode,
    setCodeIsValid,
    setRebaseSource,
    updateMjml,
    save,
    refresh,
  };
}

export { RebaseProvider, useRebase, useRebaseDispatch };
