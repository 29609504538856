import { FiColumns, FiCode, FiImage } from 'react-icons/fi';
import ButtonGroup from '@scalero-inc/forno-button-group';
import Tooltip from '@scalero-inc/forno-tooltip';

import { useReadOnlyViewDispatch } from '../../read-only-view-context';

import styles from './style.module.css';

export const viewColumnsOptions = [
  {
    icon: FiCode,
    value: `1fr 16px 0px`,
    tooltipText: 'Code only view',
  },
  {
    icon: FiColumns,
    value: `1fr 16px 1fr`,
    tooltipText: 'Split view showing code and visual preview',
  },
  {
    icon: FiImage,
    value: `1px 16px 1fr`,
    tooltipText: 'Full width preview',
  },
];

const tooltipClassName = 'font-paragraph-s-sb';

function ReadOnlyHeaderLayoutButtons() {
  const { setViewColumns } = useReadOnlyViewDispatch();
  const onViewChange = (value) => {
    setViewColumns(value[0]);
  };

  return (
    <div className={styles['options-wrapper']}>
      <ButtonGroup
        size="s"
        theme="dark"
        onChange={onViewChange}
        type="radio"
        variant="subtle"
        initialValue={[viewColumnsOptions[1].value]}
      >
        {viewColumnsOptions.map((item) => {
          const Icon = item.icon;
          return (
            <Tooltip
              key={item.value}
              theme="dark"
              content={<p className={tooltipClassName}>{item.tooltipText}</p>}
            >
              <ButtonGroup.Button
                value={item.value}
                className="cannoli-button-group-item"
              >
                <Icon />
              </ButtonGroup.Button>
            </Tooltip>
          );
        })}
      </ButtonGroup>
    </div>
  );
}

export default ReadOnlyHeaderLayoutButtons;
