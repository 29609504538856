import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import json2mjml from 'json2mjml';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Split from 'react-split-grid';

import ButtonGroup from '@scalero-inc/forno-button-group';
import Button from '@scalero-inc/forno-button';

import {
  FiFigma,
  FiFile,
  FiFilePlus,
  FiRefreshCcw,
  FiSave,
} from 'react-icons/fi';
import { BiLogOut } from 'react-icons/bi';

import {
  REBASE_COLUMN_ORIGIN_CANNOLI,
  REBASE_COLUMN_ORIGIN_FIGMA,
  REBASE_COLUMN_ORIGIN_NEW_VERSION,
} from 'utils/constants';

import usePanel from 'hooks/utils/use-panel';
import { ReactComponent as BaseLogoCollapsed } from 'svg/cannoli-white-logo-short.svg';
import { editorOptions } from 'utils/editor';
import RebasePreview from '../rebase-preview';

import { useRebase, useRebaseDispatch } from '../rebase-context';
import {
  MJML_CANNOLI,
  MJML_EMPTY,
  MJML_FIGMA,
} from '../rebase-context/constants';
import { useEditor } from '../editor-context';
import { POSITION_TYPE } from '../rebase-preview/insert-section';

import styles from './style.module.css';

function RebaseContent({ className, setEditorView }) {
  const customClassName = classnames(
    styles['editor-content-container'],
    className
  );

  const panel = usePanel();

  const { url, node: urlNode } = useEditor();

  const openFigmaFile = () => {
    const nodeUrl = `${url.split('?')[0]}?node-id=${encodeURIComponent(
      urlNode
    )}`;
    window.open(nodeUrl);
  };

  const [selectedElement, setSelectedElement] = React.useState({});
  const [foundNode, setFoundNode] = React.useState({});

  const { figmaCode, cannoliCode, rebaseCode, isLoading } = useRebase();

  const { updateMjml, setRebaseSource, save, refresh } = useRebaseDispatch();

  const figmaHtmlWithIds = figmaCode?.htmlWithIds || '';
  const figmaNodesWithIds = figmaCode?.nodesWithIds || {};

  const cannoliHtmlWithIds = cannoliCode?.htmlWithIds || '';
  const cannoliNodesWithIds = cannoliCode?.nodesWithIds || {};

  const rebaseHtmlWithIds = rebaseCode?.htmlWithIds || '';
  const rebaseJson = React.useMemo(() => {
    return rebaseCode?.json || {};
  }, [rebaseCode]);
  const rebaseNodesWithIds = React.useMemo(() => {
    return rebaseCode?.nodesWithIds || {};
  }, [rebaseCode]);

  React.useEffect(() => {
    if (!isLoading) {
      const origin = Object.keys(selectedElement)[0];

      if (!selectedElement[origin]) return;

      const { id: nodeId, tag: currentTag } = selectedElement[origin];

      const config = {
        cannoli: {
          nodes: cannoliNodesWithIds,
          errorMessage: 'Node not found in cannoli',
        },
        figma: {
          nodes: figmaNodesWithIds,
          errorMessage: 'Node not found in figma',
        },
      };

      const { nodes, errorMessage } = config[origin] || {};

      if (Array.isArray(nodes)) {
        const matchingNode = nodes.find((node) => node.id === nodeId);
        if (matchingNode && matchingNode.tag !== currentTag) {
          setFoundNode(matchingNode.tag);
        } else if (!matchingNode) {
          console.error(errorMessage);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElement, cannoliNodesWithIds, figmaNodesWithIds]);

  const replaceElement = useCallback(
    (targetId) => {
      if (
        foundNode &&
        targetId &&
        (selectedElement[REBASE_COLUMN_ORIGIN_CANNOLI] ||
          selectedElement[REBASE_COLUMN_ORIGIN_FIGMA])
      ) {
        const element = rebaseNodesWithIds.find(
          ({ id }) => id === targetId
        ).tag;
        element.tagName = foundNode.tagName;
        element.attributes = foundNode.attributes;
        element.children = foundNode.children;
        const newMjml = json2mjml(rebaseJson);
        updateMjml(newMjml);
      }
    },
    [foundNode, rebaseJson, rebaseNodesWithIds, updateMjml, selectedElement]
  );

  const insertElement = useCallback(
    (positionType, targetId = null) => {
      if (
        foundNode &&
        (selectedElement[REBASE_COLUMN_ORIGIN_CANNOLI] ||
          selectedElement[REBASE_COLUMN_ORIGIN_FIGMA])
      ) {
        let parentElement = {};
        let position = 0;

        // Insert into the parent element
        if (targetId) {
          const element = rebaseNodesWithIds.find(({ id }) => id === targetId);
          const { parentTag, localIndex } = element;
          parentElement = parentTag;
          position =
            positionType === POSITION_TYPE.BEFORE ? localIndex : localIndex + 1;
        } else {
          // Insert directly into mj-body
          parentElement =
            rebaseJson.children.find((child) => child.tagName === 'mj-body') ||
            {};
          position = 0;
        }

        parentElement.children = parentElement.children || [];
        const beforePosition = parentElement.children.slice(0, position);
        const afterPosition = parentElement.children.slice(position);
        parentElement.children = [
          ...beforePosition,
          foundNode,
          ...afterPosition,
        ];

        const newMjml = json2mjml(rebaseJson);
        updateMjml(newMjml);
      }
    },
    [foundNode, rebaseJson, rebaseNodesWithIds, updateMjml, selectedElement]
  );

  const deleteElement = useCallback(
    (targetId) => {
      // Clear id to hide the overlay
      setSelectedElement({
        [REBASE_COLUMN_ORIGIN_NEW_VERSION]: {
          id: '',
          from: 'RebasePreview',
        },
      });

      const element = rebaseNodesWithIds.find(({ id }) => id === targetId);
      const { parentTag, localIndex } = element;
      parentTag.children.splice(localIndex, 1);

      const newMjml = json2mjml(rebaseJson);
      updateMjml(newMjml);
    },
    [rebaseJson, rebaseNodesWithIds, updateMjml]
  );

  const handleSave = useCallback(() => {
    save(rebaseCode?.mjml, {
      afterSave: () => {
        setEditorView('visual');
        panel.open(
          editorOptions.visualEditor.name,
          editorOptions.visualEditor.title
        );
        toast.success('Changes saved');
      },
      onFailure: () => {
        toast.error('Error saving code');
      },
    });
  }, [panel, rebaseCode?.mjml, save, setEditorView]);

  return (
    <div className={styles['editor-content']}>
      <Split
        minSize={0}
        dragInterval={10}
        snapOffset={30}
        cursor="col-resize"
        gridTemplateRows="1fr"
        gridTemplateColumns="1fr 1fr 1fr"
        render={({ getGridProps }) => {
          return (
            <div
              className={customClassName}
              {...getGridProps()}
              data-theme="dark"
            >
              <div className={styles['preview-column-container']}>
                <div className={styles['column-header']}>
                  <div className={styles['column-header-title']}>
                    <BaseLogoCollapsed className={styles['cannoli-logo']} />
                    <h3>Current Cannoli file</h3>
                  </div>
                  <p className="font-paragraph-s">
                    From the preview below, drag & drop mj-wrappers or
                    mj-sections into the New Version. ➡️
                  </p>
                </div>
                <RebasePreview
                  htmlWithIds={cannoliHtmlWithIds}
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  origin={REBASE_COLUMN_ORIGIN_CANNOLI}
                />
              </div>
              <div
                className={`${styles['preview-column-container']} ${styles['preview-column-container-variant']}`}
              >
                <div className={styles['column-header']}>
                  <div className={styles['column-header-title']}>
                    <FiFilePlus size={34} />
                    <h3>New Version</h3>
                  </div>
                  <p className="font-paragraph-s">
                    Setting up a New Version? Choose a base: your Cannoli file,
                    your import from Figma, or start from scratch.
                  </p>
                  <ButtonGroup
                    type="radio"
                    size="s"
                    theme="dark"
                    initialValue={[MJML_CANNOLI]}
                    onChange={(e) => {
                      setRebaseSource(e[0]);
                      setSelectedElement({});
                    }}
                  >
                    <ButtonGroup.Button value={MJML_CANNOLI}>
                      <FiFile />
                      Current Cannoli file
                    </ButtonGroup.Button>
                    <ButtonGroup.Button value={MJML_FIGMA}>
                      <FiFigma />
                      Current Figma file
                    </ButtonGroup.Button>
                    <ButtonGroup.Button value={MJML_EMPTY}>
                      Empty area
                    </ButtonGroup.Button>
                  </ButtonGroup>
                  <div className={styles['buttons-container']}>
                    <Button
                      size="xs"
                      theme="dark"
                      hierarchy="primary"
                      status="danger"
                      icon={BiLogOut}
                      onClick={() => {
                        setEditorView('visual');
                        panel.open(
                          editorOptions.visualEditor.name,
                          editorOptions.visualEditor.title
                        );
                      }}
                    >
                      I&apos;ve changed my mind
                    </Button>
                    <Button
                      size="xs"
                      theme="dark"
                      onClick={handleSave}
                      icon={FiSave}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
                <RebasePreview
                  htmlWithIds={rebaseHtmlWithIds}
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  origin={REBASE_COLUMN_ORIGIN_NEW_VERSION}
                  replaceElement={replaceElement}
                  insertNewElement={insertElement}
                  deleteElement={deleteElement}
                />
              </div>
              <div className={styles['preview-column-container']}>
                <div className={styles['column-header']}>
                  <div className={styles['column-header-title']}>
                    <FiFigma size={34} />
                    <h3>Current Figma file</h3>
                  </div>
                  <div className={styles['description-container']}>
                    <p className="font-paragraph-s">
                      From the preview below, drag & drop mj-wrappers or
                      mj-sections into the New Version. Reload Figma file
                      anytime you do edit in Figma. ⬅️
                    </p>
                    <div className={styles['buttons-container']}>
                      <Button
                        size="xs"
                        theme="dark"
                        hierarchy="tertiary"
                        icon={FiFigma}
                        onClick={() => openFigmaFile()}
                      >
                        Open in Figma
                      </Button>
                      <Button
                        size="xs"
                        theme="dark"
                        hierarchy="tertiary"
                        icon={FiRefreshCcw}
                        onClick={refresh}
                      >
                        Reload Figma file
                      </Button>
                    </div>
                  </div>
                </div>
                <RebasePreview
                  htmlWithIds={figmaHtmlWithIds}
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  isLoading={isLoading}
                  origin={REBASE_COLUMN_ORIGIN_FIGMA}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

RebaseContent.propTypes = {
  className: PropTypes.string,
  setEditorView: PropTypes.func,
};

RebaseContent.defaultProps = {
  className: null,
  setEditorView: () => {},
};

export default RebaseContent;
