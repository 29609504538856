import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Dropdown from '@scalero-inc/forno-dropdown';

import { useTeamMemberUpdate } from 'hooks/api/teams';

import styles from './style.module.css';

const premissionOptions = [
  { label: 'Admin', value: 'A' },
  { label: 'Read', value: 'R' },
  { label: 'Write', value: 'W' },
];

function TeamMembersPermissions({ teamId, memberId, permission, blocked }) {
  const customClassname = classNames(
    styles['permissions-select'],
    blocked ? styles.disabled : null
  );
  const permissionObject = premissionOptions.find(
    (el) => el.label === permission
  );

  const { mutate, isLoading } = useTeamMemberUpdate({
    config: {
      onError: () => {
        toast.error(`Error updateing user from team, try again later`);
      },
    },
    teamId,
    memberId,
  });

  const handleChange = (e) => {
    mutate({ permission: e.value });
  };

  return (
    <Dropdown
      options={premissionOptions}
      size="s"
      theme="light"
      value={permission}
      defaultValue={permissionObject}
      className={customClassname}
      onChange={handleChange}
      disabled={blocked}
      isLoading={isLoading}
    />
  );
}

TeamMembersPermissions.propTypes = {
  permission: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
  blocked: PropTypes.bool.isRequired,
  memberId: PropTypes.number.isRequired,
};

export default TeamMembersPermissions;
