import LoginWithPasswordForm from 'containers/login-with-password-form';

import FooterPublic from 'components/footer-public';
import HeaderPublic from 'components/header-public';

import styles from './styles.module.css';

export default function LoginWithPassword() {
  return (
    <>
      <HeaderPublic />
      <main className={styles['login-with-password-page']}>
        <LoginWithPasswordForm
          className={styles['login-with-password-page-form']}
        />
      </main>
      <FooterPublic />
    </>
  );
}
